import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TechnicianCommentsComponent } from "@app/technician-comments/technician-comments.component";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { CKEditor4 } from "ckeditor4-angular/ckeditor";
import {
  FormDefault,
  Model,
  StaticService,
  SysService,
  View,
  ViewFactory,
  noWhitespaceValidator,
} from "@app/_common";
import { ConfirmationService, MenuItem } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { environment } from "@environments/environment";
import { ViewerTabComponent } from "@app/viewer-Tab/viewer-tab.component";
import { ReportService } from "@app/_common/services/report.service";
import { Panel } from "primeng/panel";
declare const CKEDITOR;
@Component({
  selector: "eclarity-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"],
})
export class ReportComponent implements OnInit, OnDestroy {
  items: MenuItem[];
  heightTable: any;
  modalityLookup: any[]; // Lookup array for modalities
  bodyPartLookup: any[]; // Lookup array for body parts
  reportTemplateLookup: any[]; // Lookup array for report templates
  hideIconReport = false; // Flag for hiding report icon
  helpDigoluebox: boolean; // Flag for displaying keyboard shortcuts dialog box
  rejectReason: boolean;
  viewerHide = false;
  messageReason: any;
  viewSize: any = [70, 30];
  reportHistoryList: any[];
  reportConfirm: boolean;
  reportLog: boolean = false;
  hasSignature: boolean = false;
  hasStamp: boolean = false;
  hasHeader: boolean = false;
  hasFooter: boolean = false;
  footer: any;
  header: any
  signature: any;
  stamp: any;

  codeShortList: any;
  imageUrl: string;
  header1 = 10;
  dynamicDialogRef: any;
  reportHislog: any;
  reportDate: any;
  rateDiagloue: boolean = false
  reportLogData: any;
  customRate1: any
  reportLogDetailsView: boolean = false;
  editSave: boolean = false;
  refReport: DynamicDialogRef | undefined;
  refViewer: DynamicDialogRef | undefined;
  @Input() err: string;
  @Input() errFlag: boolean;
  flag: boolean;
  @Input() rightEditor: boolean;
  @Input() studyViewer: boolean;
  @Input() siuid: string;
  @Input() splitter: any;
  @Input() reportData: any;
  @Input() errFlag1: boolean;
  @Input() reportRadiologist: string;
  @ViewChild('panelRef') panelRef: Panel;
  private reportView: View = this.viewFactory.newView();
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private router: Router,
    private viewFactory: ViewFactory,
    public dialogService: DialogService,
    private sanitizer: DomSanitizer,
    private reportService: ReportService,
    private confirmationService: ConfirmationService
  ) {


  }
  get report() {
    return this.reportView.model;
  }
  // Define ngOnInit method
  ngOnInit() {
    // Call reportListmodel() and set reportView with the returned Model object
    this.reportView.of(this.reportListmodel(), false);
    // Subscribe to query parameter changes and set siuid property accordingly
    this.route.queryParams.subscribe((params) => {
      const parasiuid = params.siuid;
      this.siuid = atob(parasiuid);
      const paraReport = params.i;
      this.reportRadiologist = atob(paraReport);
      // Call edit() method with report parameter
      this.edit(this.report);
      this.scrollHeight()
      if (this.rightEditor) {
        this.detectZoomChange()
      }
      window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 200));




    });
    this.items = [
      {
        label: 'Preview Itext',
        icon: "pi pi-file",
        command: () => {
          this.reportDownloadItextPreview(true);
        },
        visible: this.sysService.getUser().userSettings.isDownloadPdf
      },
      { separator: true },
      {
        label: 'Print',
        icon: "pi pi-print",
        command: () => {
          this.printReportRad(true);
        },
        visible: this.sysService.getUser().userSettings.isDownloadPdf
      },
      { separator: true },
      {
        label: 'Preview ',
        icon: "pi pi-file-pdf",
        command: () => {
          this.printReport(true);
        },
        visible: this.sysService.getUser().userSettings.isDownloadPdf
      },


    ];
  }

  reportTab(_report: any, max: boolean = true) {
    this.viewerSize = true;
    if (max) {
      this.splitter.viewerSize = [-60, 160];
      document.documentElement.style.setProperty("--app-spliter-x", "100%");
    } else {
      this.splitter.viewerSize = [70, 30];
      document.documentElement.style.setProperty("--app-spliter-x", "30%");
    }
  }
  reEdit(report: any): void {
    this.confirmationService.confirm({
      rejectLabel: "Cancel",
      acceptLabel: "Edit Report",
      header: "Confirm Report",
      message: "Do you want to re-edit the report?",
      accept: () => {
        this.report.set("status", 1);
        this.reportView.toSave("/reports", "id").then((data) => {
          this.report.set("showConfirm", true);
          this.report.set("showEdit", false);
          this.report.view.showEdit = false;
          this.report.view.showDownload = false;
          this.report.view.status = 1;
          this.sysService.success("Report re-edit confirmed.");
        });
        setTimeout(() => {
          this.sysService.clear();
        }, 1000);
      },
      reject: () => { },
    });
  }
  reportClose() {
    this.sysService.clear();
    this.reportHislog = null;
    this.reportLogDetailsView = false;
  }
  closeEditor(boolean) {
    this.sysService.clear();
    this.reportLogDetailsView = boolean;
  }
  adminForSave(boolean) {
    this.editSave = boolean;
    this.report.set("showSaveForAdmin", false);
    this.sysService.info("Edit mode enable successfully ");
  }
  rateDiaglouePop() {
    this.rateDiagloue = true
  }
  edit(report: any) {
    if (this.reportRadiologist || location.pathname === "/reports") {
      this.reportView
        .toEditFetch(`/reports/${this.siuid}`, report?.id)
        .then((data) => {
          this.sysService.loadUserName();
          this.sysService.loadWalletBalance();
          this.viewerHide = data?.isEditable;
          if (data.status === 3 && this.sysService.isCenter()) { // Given content not displaying in the report taken from centre for approving. 
            this.report.set("reportContent", this.report.view.reportContent)
          }

          if (data?.reportingRadUserId?.id !== undefined) {
            if (data?.isSignaturePresent)
              this.reportView
                .image(
                  `/users/rad-signature?radId=${data?.reportingRadUserId?.id}&isUserId=true`
                )
                .subscribe((byte) => {
                  this.hasSignature = byte.byteLength > 0;
                  this.signature =
                    this.sysService.arrayBufferToBase64ImageSource(
                      byte,
                      data?.signatureExt
                    );
                });
            if (data?.isStampPresent)
              this.reportView
                .image(
                  `/users/rad-stamp?radId=${data?.reportingRadUserId?.id}&isUserId=true`
                )
                .subscribe((byte) => {
                  this.hasStamp = byte.byteLength > 0;
                  this.stamp = this.sysService.arrayBufferToBase64ImageSource(
                    byte,
                    data?.stampExt
                  );
                });
          }
          if (data?.isShowHeaderFooter) {
            if (data?.isHeaderPresent) {
              this.reportView.image(`/users/cen-header?cenId=${data?.centreId?.id}&isUserId=false`)
                .subscribe(byte => {
                  this.hasHeader = byte.byteLength > 0;
                  this.header = this.sysService.arrayBufferToBase64ImageSource(byte, data.headerExt);

                });
            }
            if (data?.isFooterPresent) {
              this.reportView.image(`/users/cen-footer?cenId=${data?.centreId?.id}&isUserId=false`)
                .subscribe(byte => {
                  this.hasFooter = byte.byteLength > 0;
                  this.footer = this.sysService.arrayBufferToBase64ImageSource(byte, data.footerExt);

                });

            }
          }
          if (!this.rightEditor) {
            data.report = this.reportData;
          }
        });

    }
  }
  capitalizeFirstLetter(value: string): string {
    if (value) {
      const words = value.split(" ");
      const capitalizedWords = words.map((word) => {
        if (word.length > 0) {
          const firstLetter = word.charAt(0).toUpperCase();
          const restOfWord = word.slice(1).toLowerCase();
          return firstLetter + restOfWord;
        }
        return word; // Return empty string or original word depending on your preference for empty words
      });
      return capitalizedWords.join(" ");
    }
    return "";
  }
  confirmRiport() {
    if (this.reportView.isValid()) {
      this.reportConfirm = true;
    }
  }
  // Assuming the required imports and class definition are present
  saveReport(study: any, status: number = 1) {


    if (!this.report.get("customRate")) {
      this.report.set("charge", this.report.view.charge)

    }
    this.report.set("primaryCharge", this.report.view.primaryCharge)
    if (this.reportView.isValid()) {
      switch (status) {
        case 1:
          // Save as draft
          if (this.reportView.isValid()) {
            if (this.report.get('customRate') && this.report.isEmpty('paymentNote')) {
              this.errFlag1 = true;
            } else {
              this.errFlag1 = false;
              this.reportView.toSave("/reports", "id");
            }
          } else {
            this.report.set("status", 1);
            this.reportView.toSave("/reports", "id");
          }


          break;

        case 2:
          // Confirm and approval
          if (this.reportView.isValid()) {
            if (this.report.get('customRate') && this.report.isEmpty('paymentNote')) {
              this.errFlag1 = true;
            } else {
              this.errFlag1 = false;

              this.confirmationService.confirm({
                message: "Are you sure you want to confirm the report?",
                accept: () => {
                  this.report.set("status", 2);
                  this.report.set("secondaryPayStatus", 0);
                  this.report.set("primaryPayStatus", 0);
                  this.reportView
                    .toSave("/reports", "id", "Your report has been confirmed.")
                    .then((data) => {
                      setTimeout(() => {
                        window.close();
                      }, 1000);
                    });
                },
                acceptLabel: "Confirm",
                rejectLabel: "Continue editing",
                header: "Confirm Report",
                reject: () => {
                  this.sysService.clear();
                },
              });
            }
          } else {
            this.report.set("status", 2);
            this.reportView
              .toSave("/reports", "id", "Your report has been confirmed.")
              .then((data) => {
                setTimeout(() => {
                  window.close();
                }, 1000);
              });
          }
          break;

        case 6:
          this.confirmationService.confirm({
            message: "Are you sure you want to approve the report?",
            accept: () => {
              this.report.set("status", 2);
              this.reportView
                .toSave("/reports", "id", "Report has been approved.")
                .then((data) => {
                  setTimeout(() => {
                    window.close();
                  }, 1000);
                });
            },
            acceptLabel: "Approve",
            rejectLabel: "Cancel",
            header: "Confirm approval",
            reject: () => {
              this.sysService.clear();
            },
          });
          break;
        case 0:
          // Save as reject
          if (this.report.isEmpty("rejectionReason")) {
            this.messageReason = " Reason is Required ";
          } else {
            this.report.set("status", 0);
            if (this.report.view.status === 2 && this.report.view.studyStatus === 4) {
              this.confirmationService.confirm({
                message: `Are you sure you want to reject the completed report for ${this.report.view.patName}?`,
                header: 'Confirmation',
                accept: () => {
                  this.reportView
                    .toSave('/reports', 'id', 'Report has been Rejected')
                    .then(() => {
                      setTimeout(() => {
                        this.router.navigateByUrl('/studies');
                      }, 1000);
                    });
                },

              });

            } else {
              this.reportView
                .toSave("/reports", "id", " Report has been Rejected")
                .then((data) => {
                  setTimeout(() => {
                    this.router.navigateByUrl("/studies");
                  }, 1000);
                });

            }
          }



          break;
        default:
          // Handle other cases if needed
          this.sysService.error("Report not able to save. Please  try again.");

          break;
      }
    } else {
      this.sysService.error("Report is required")
    }

  }
  data1: string = '';

  logEditorData() {

  }

  // Lookup function for filtering Modality data
  filterModality(event: any) {
    this.reportView
      .getData(`/modality/lookup?filter=${event.query}`)
      .then((data) => {
        this.modalityLookup = data;
      });
  }
  // Lookup function for filtering BodyPart data
  filterBodyPart(event: any) {
    this.reportView
      .getData(`/bodypart/lookup?filter=${event.query}`)
      .then((data) => {
        this.bodyPartLookup = data;
      });
  }
  // Lookup function for filtering ReportTemplate data
  filterReport(event: any) {
    this.reportView
      .getData(
        `/reporttemplate/lookup?filter=${event.query}&studyIuid=${this.siuid}&insideReport=true`
      )
      .then((data) => {
        this.reportTemplateLookup = data;
      });
  }
  // Load a report template using its ID
  loadTemplate(id) {
    this.ckeditorLoading = false
    this.reportView.getData(`/reporttemplate/${id}`).then((data) => {
      this.report.set("reportContent", data.report);
      this.ckeditorLoading = true
    });
  }

  // Handle the selection of a template ID
  templateId(event: any) {
    this.loadTemplate(event.id);
  }
  // Open a dialog box for adding technician comments to a study
  technicianComment(study: any) {
    this.dynamicDialogRef = this.dialogService.open(
      TechnicianCommentsComponent,
      {
        header: "Technician Comments",
        width: "90%",
        maximizable: true,
        contentStyle: { maxHeight: "500px", overflow: "auto" },
        data: {
          studyIuid: this.siuid,
          view: 1,
        },
        dismissableMask: true,
      }
    );
  }
  // Save the current report as approved
  saveApproval(report: any) {
    const id = this.report?.view?.id;
    this.reportView.toSave(`/reports/approve/reportId=${id}`, "id");
  }
  rejectBack() {
    this.rejectReason = false;
    this.report.set("rejectionReason", "");
    this.messageReason = "";
    this.sysService.clear();
  }
  rejectDialog() {
    this.rejectReason = true;
  }
  // Open a dialog box displaying keyboard shortcuts for the ckeditor
  keyboardShortcuts() {
    this.helpDigoluebox = true;
  }
  /**
   * Open a new tab to view the report for the current study
   * by setting hideIconReport to true and opening the reports URL.
   *
   * @param report The current report being viewed
   */
  viewerSize: boolean = true;
  toggleViewer(_report: any) {
    if (this.splitter.viewerSize[0] === 70) {
      this.splitter.viewerSize = [99, 1];
      document.documentElement.style.setProperty("--app-spliter-x", "100%");
      this.viewerSize = false;
    } else {
      this.splitter.viewerSize = [70, 30];
      document.documentElement.style.setProperty("--app-spliter-x", "30%");
      this.viewerSize = true;
    }
  }
  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  viewerUrl() {
    const url = `${environment.viewer.ohif}/viewer?StudyInstanceUIDs=${this.siuid}`;
    return url;
  }
  viewerTab(report: any) {
    this.refViewer = this.dialogService.open(ViewerTabComponent, {
      header: "Viewer",
      width: "100%",
      height: "90%",
      maximizable: true,
      data: {
        siuid: this.siuid,
      },
    });
    this.refViewer.onClose.subscribe(() => {
      this.sysService.clear();
    });
  }
  ngOnDestroy() {
    if (this.refReport) {
      this.refReport.close();
    }
    if (this.refViewer) {
      this.refViewer.close();
    }
  }
  reportNewTab(report: any) {
    if (this.sysService.isRad()) {
      const url = `/ecscribeviewer?siuid=${this.siuid}&Report=false`;
      window.open(url);
    }
  }
  /**
   * Open a new tab to view the viewer for the current study
   * with the Report parameter set to true.
   */
  viewerNewTab() {
    if (this.sysService.isRad()) {
      const url = `/ecscribeviewer?siuid=${this.siuid}&Report=true`;
      window.open(url);
    } else {
      const url = `/ecscribeviewer?siuid=${this.siuid}&Report=true`;
      window.open(url);
    }
  }
  printReport(editor: any) {
    let reportContentValue;
    if (this.sysService.isRad() || this.sysService.isRadSub()) {
      reportContentValue = editor._data;
    } else {
      reportContentValue = this.report.view.reportContent;
    }
    this.report.setParam("isPreview", true);
    this.reportView.toSave("/reports", "id", "").then((data) => {
      // this.reportService.downloadJspdf(this.report?.view?.reportContent, this.report?.view, this.signature, this.stamp, this.reportDownload,this.header,this.footer)

      window.open(
        `/report-download?siuid=${this.report.view.studyIuid}&reportDownload=false`
      );
      this.report.setParam("isPreview", false);
    });
  }
  printReportRad(editor: any) {
    let reportContentValue;
    if (this.sysService.isRad() || this.sysService.isRadSub()) {
      reportContentValue = this.report.get("reportContent");
    } else {
      reportContentValue = this.report.view.reportContent;
    }
    this.reportService.printReport(
      reportContentValue,
      this.report?.view,
      this.signature,
      this.stamp, this.header, this.footer
    );
  }
  reportDownloadItext(report: any) {
    this.report.setParam("isPreview", true);
    let studyId = this.report.view.studyId;
    const pdfFilename = `${report.patName}/${report.patId
      }/${this.sysService.toDate1(report.studyDate)}.pdf`;
    this.reportView.toSave(`/reports`, "id", "").then((data) => {
      this.reportView.downloadOpen(
        `/reports/report-pdf-download?studyId=${studyId}`,
        pdfFilename
      );
    });
    this.report.setParam("isPreview", false);
  }
  reportDownloadItextPreview(report: any) {
    if (this.report.isEmpty("reportContent")) {
      this.sysService.error("Report content is empty. Please enter report data.");
      return;
    }
    // let studyId = this.report.view.studyId
    let reportId = this.report.get("id");
    let studyId = this.report.get("studyId");
    let studyIuid = this.report.get("studyIuid");
    let repRadId = this.report.get("reportingRadUserId")?.id;
    // this.report.setParam("reportId",this.report.view.id)
    this.report.setParam("isPreview", true);
    const pdfFilename = `${this.report?.view?.patName}/${this.report?.view?.patId}/${this.sysService.toDate1(this.report?.view?.studyDate)}.pdf`;
    this.reportView.toSave(`/reports`, "id", "").then((data) => {
      this.report.setParam("studyIuid", studyIuid);
      this.reportView.getData("/study/report-download").then((data) => {
        this.report.setParam("studyId", studyId);
        this.report.setParam("repRadId", repRadId);
        this.reportView.download(
          `/reports/report-pdf-download`,
          pdfFilename, false
        );
      });
    });
    this.report.setParam("isPreview", false);
  }
  reportDownload(report) {
    if (this.report.view.showDownload === true) {
      if (this.report?.get("status") === 2) {
        this.report.setParam("studyIuid", this.report.view.studyIuid);
        this.reportView.getData("/study/report-download").then((data) => {
          window.open(
            `/report-download?siuid=${this.report.view.studyIuid}&reportDownload=true`
          );
        });
      } else {
        window.open(
          `/report-download?siuid=${this.report.view.studyIuid}&reportDownload=true`
        );
      }
    }
  }
  wordDownloadBe(report: any) {
    if (this.report.view.showDownload === true) {
      let fileName = `${this.report?.view?.patName}/${this.report?.view?.patId ?? ""
        }/${this.sysService.toDate1(this.report?.view?.studyDate) ?? ""}.docx`;

      if (this.report?.get("status") === 2) {
        this.report.setParam("studyIuid", this.report.view.studyIuid);
        this.reportView.getData("/study/report-download").then((data) => {
          this.reportView.download(
            `/reports/report-doc-download?reportId=${this.report.view.id}`,
            fileName
          );
        });
      } else {
        this.reportView.download(
          `/reports/report-doc-download?reportId=${this.report.view.id}`,
          fileName
        );
      }
    }
  }

  wordDownload(editor: any) {
    if (this.report?.get("status") === 2) {
      this.report.setParam("studyIuid", this.report.view.studyIuid);
      this.reportView.getData("/study/report-download").then((data) => {
        this.reportService.exportCKEditorContentToWord(
          this?.report?.view.reportContent,
          this.report?.view,
          this.signature,
          this.stamp
        );
      });
    } else {
      this.reportService.exportCKEditorContentToWord(
        this?.report?.view.reportContent,
        this.report?.view,
        this.signature,
        this.stamp
      );
    }
  }
  wordDownloadForPhone(editor: any) {
    if (this.report?.get("status") === 2) {
      this.report.setParam("studyIuid", this.report.view.studyIuid);
      this.reportView.getData("/study/report-download").then((data) => {
        this.reportService.exportCKEditorContentToWordForPhone(
          this?.report?.view.reportContent,
          this.report?.view,
          this.signature,
          this.stamp
        );
      });
    } else {
      this.reportService.exportCKEditorContentToWordForPhone(
        this?.report?.view.reportContent,
        this.report?.view,
        this.signature,
        this.stamp
      );
    }
  }
  ckeditorLoading: boolean = false
  onNamespaceLoaded(event: CKEditor4.EventInfo) {
    this.ckeditorLoading = true
    if (this.sysService.isRad()) {
      this.reportView.getData("/shortcode/report-code").then((data) => {
        this.loadAutoComplete(event, data);
      });
    }
  }
  loadAutoComplete(evt, placeHolderObj) {
    const itemTemplate =
      '<li data-id="{id}" style="color: #000000;">' +
      "<div><strong >{code}</strong> </div>" +
      "<div>{codeDescription}</div>" +
      "</li>";
    const outputTemplate = "{codeDescription} <span>&nbsp;</span>";
    function textTestCallback(range) {
      if (!range.collapsed) {
        return null;
      }
      return CKEDITOR.plugins.textMatch.match(range, matchCallback);
    }
    function matchCallback(text, offset) {
      const pattern = /\#{1}([A-z]|)*$/;
      const match = text.slice(0, offset).match(pattern);
      if (!match) {
        return null;
      }
      return { start: match.index, end: offset };
    }
    function dataCallback(matchInfo, callback) {
      const query = matchInfo.query.substring(1).toLowerCase();
      const data = placeHolderObj.filter(function (item) {
        const title = item.code.toLowerCase();
        if (title.startsWith(query)) {
          return true;
        } else {
          const desc = item.codeDescription.toLowerCase();
          return title.includes(query) || desc.includes(query);
        }
      });
      callback(data);
    }
    const autocomplete = new CKEDITOR.plugins.autocomplete(evt.editor, {
      textTestCallback,
      dataCallback,
      itemTemplate,
      outputTemplate,
    });
    autocomplete.getHtmlToInsert = function (item) {
      return this.outputTemplate.output(item);
    };
  }
  private reportListmodel() {
    const formDefault = new FormDefault(["studyIuid"], [this.siuid]);
    return new Model(
      this.formBuilder.group({
        id: [],
        name: [""],
        reportingRadUserId: [],
        reportContent: ["", [Validators.required, noWhitespaceValidator]],
        status: [],
        bodyPartId: [],
        patName: [""],
        rateconfrimed: [],
        reportTemplateId: [],
        charge: [],
        primaryPayStatus: [],
        secondaryPayStatus: [],
        isReportLog: [],
        primaryCharge: [],
        reportHislog: [""],
        studyIuid: [""],
        radiologistId: [],
        qualifications: [""],
        registrationNo: [""],
        reportId: [],
        studyDesc: [""],
        studyDescModified: [""],
        modality: [""],
        patId: [""],
        isPreview: [],
        repRadId: [""],
        customRate: [],
        paymentNote: [""],
        isEditable: [],
        showApprovalBtn: [],
        showConfirm: [],
        showSaveForAdmin: [],
        technicianCount: [],
        rejectionReason: [""],
        forwardedGroupId: [],
        showReject: [],
        reportingreportingRadUserId: [],
        primaryreportingRadUserId: [],
        reportDate: [],
        patSex: [],
        // studyDate	:[],
        editableByAdmin: [],
        patAge: [],
        countryId: [],
        timezone: [],
        roles: [],
        centreId: [],
        signature: [""],
        shownDownload: [],
        studyId: [],
      }),
      this.sysService,
      formDefault,
      ""
    );
  }


  // --------------------------------------------------CKEditor4 config---------------------------------------------------------?
  public editorData;

  configuration = {
    // delayIfDetached: true,
    // toolbarGroups: [
    //   { name: 'clipboard' },
    //   // { name: "editing", }, //'spellchecker'
    //   { name: "insert" },
    //   { name: "tools" },
    //   { name: "document" },
    //   { name: "basicstyles" },
    //   { name: "paragraph" },
    //   { name: "styles" },
    //   { name: "colors" },

    // ],

    toolbarGroups: [{ name: 'clipboard', groups: ['undo', 'clipboard'] },
    { name: 'editing', groups: ['find', 'selection'] },
    { name: 'links' }, { name: 'insert' },
    { name: 'document', groups: ['mode', 'document', 'doctools'] },
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },

    { name: 'styles' },
    { name: 'colors' },
    { name: "tools", groups: ["maximize"] },],
    readOnly: this.viewerHide,
    resize_enabled: true,
    versionCheck: false,
    pasteFromWord: false,
    disableNativeSpellChecker: false,


    // Set readOnly to true if this.reportTemplate.view.editable is true
    removePlugins: "elementspath,save,magicline,image,sourcearea",
    // extraPlugins: 'divarea,smiley,justify,indentblock,easyimage,maximize,uploadwidget,image2,link,placeholder,colordialog,clipboard,widget,dialog,font,preview ,autogrow,colorbutton,font,tableresize,liststyle,pagebreak,pastefromgdocs'  ,
    extraPlugins:
      "image2,dialogui,dialog,a11yhelp,basicstyles,bidi,blockquote,clipboard,placeholder,widget,lineutils," +
      "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
      "dialogadvtab,enterkey,entities,popup," +
      "filebrowser,contextmenu,find,fakeobjects,floatingspace,listblock,richcombo," +
      "font,format,horizontalrule,htmlwriter,indent," +
      "indentblock,indentlist,justify,list,liststyle,magicline," +
      "maximize,pagebreak," +
      "removeformat,resize,save,menubutton,selectall,showblocks," +
      "showborders,specialchar,stylescombo,tab,table," +
      "tabletools,toolbar,undo,wysiwygarea," +
      "autocomplete,textmatch,link,smiley",

    pasteFromWordRemoveFontStyles: true,
    allowedContent: true,
    forcePasteAsPlainText: false,
    //   disallowedContent: 'img{width,height,float}',
    // extraAllowedContent: 'img[width,height,align]',
    // customConfig: '',
    // scayt_autoStartup: true, scayt
    height: this.sysService.scrollHeightCkeditor(400),
    skin: 'kama',
    removeDialogTabs: "image:advanced;link:advanced",
    format_tags: "p;h1;h2;h3;pre;div",
    bodyClass: "document-editor",
    contentsCss: ["./assets/ckeditor.css"],
    stylesSet: [
      /* Inline Styles */
      { name: "Marker", element: "span", attributes: { class: "marker" } },
      { name: "Cited Work", element: "cite" },
      { name: "Inline Quotation", element: "q" },
      /* Object Styles */
      {
        name: "Special Container",
        element: "div",
        styles: {
          padding: "5px 10px",
          background: "#eee",
          border: "1px solid #ccc",
        },
      },
      {
        name: "Compact table",
        element: "table",
        attributes: {
          cellpadding: "5",
          cellspacing: "0",
          border: "1",
          bordercolor: "#ccc",
        },
        styles: {
          "border-collapse": "collapse",
        },
      },
      {
        name: "Borderless Table",
        element: "table",
        styles: { "border-style": "hidden", "background-color": "#E6E6FA" },
      },
      {
        name: "Square Bulleted List",
        element: "ul",
        styles: { "list-style-type": "square" },
      },
    ],
  };

  reportHist(id: any) {
    this.reportView
      .getData(`/reports/report-history?reportId=${this.report.get("id")}`)
      .then((data) => {
        this.reportHistoryList = data;
        this.reportLog = true;
      });
  }
  viewData: any
  editReportlog(reportHistory: any) {
    this.reportHislog = reportHistory?.report;
    this.reportDate = reportHistory.createdByDate;
    this.reportLogData = reportHistory;
    this.reportLogDetailsView = true;
    this.viewData = true
    this.sysService.clear();
  }
  hasReport() {
    return this.report.isEmpty("report");
  }

  setRate() {
    this.rateDiagloue = false;
  }
  resetRate() {
    this.report.set("charge", null)
    this.report.set("customRate", false)
    this.report.set("paymentNote", null)

  }


  debounceTimeout: any;
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 287) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 264) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  detectZoomChange() {

    this.scrollHeight();
    this.sysService.scrollHeightCkeditor(400)


  }

  customreset() {

    this.report.set('paymentNote', null)

  }

}
