import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model } from '@app/_common';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-upi-redirect',
  templateUrl: './upi-redirect.component.html',
  styleUrls: ['./upi-redirect.component.scss']
})
export class UpiComponent implements OnInit {
  private UpiReRedirectionView: View = this.viewFactory.newView();
  countryLookup: any[];
  transactionId: any; 
  message: string; 
  paymentStatus: boolean
  paymentStatusValue : boolean = false;

  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private router: Router,
    private viewFactory: ViewFactory
  ) {}

  ngOnInit() {
    this.UpiReRedirectionView.of(this.upiModel(), false);
    
    this.route.queryParams.subscribe((params) => {
      this.transactionId = params.transactionId;  
      this.UpiReRedirectionView.getData(`/v2/payment/orders/${this.transactionId}`)
        .then((response) => {
          this.sysService.loadWalletBalance();          
          if (response.order_status === 'PAID') {
            this.handlePaymentSuccess();
          } else {
            this.handlePaymentFailure();
          } 
        })
        .catch((error) => {
          this.handleUnknownError();
        });
    });
  }
  
  handlePaymentSuccess() {
    this.message = 'Your payment has been successful.';
    this.paymentStatusValue = true;
    this.paymentStatus = true;
  }
  
  handlePaymentFailure() {
    this.message = 'Payment failed. Please try again.';
    this.paymentStatusValue = false;
    this.paymentStatus = true;
  }
  
  handleUnknownError() {
    this.message = 'Unknown payment status.';
    this.paymentStatusValue = false;
    this.paymentStatus = true;
  }
  
  
  get upi() {
    return this.UpiReRedirectionView.model;
  }

  private upiModel() {
    const formDefault = new FormDefault(['status'], [1]);
    return new Model(this.formBuilder.group({
      id: [],
      upi: [],
      validFrom: [],
      amountPaid: [],
      payStatus: [],
      transactionType: [],
      transactionId: [],
    }), this.sysService, formDefault, '');
  }




  redirectPayment() {
    if (this.sysService.isCenter()) {
      this.router.navigateByUrl('/billing-dashboard?activeIndex=4')
    } else {
      this.router.navigateByUrl('/billing-dashboard?activeIndex=5')
    }
  }

  backToRecharge() {
    this.router.navigateByUrl('/billing-dashboard?activeIndex=0')
  }
}




