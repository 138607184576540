import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model, passwordMatch } from '@app/_common';
import { AppConstants } from '@app/_config/app.constants';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent implements OnInit {
  private newpasswordView: View = this.viewFactory.newView();
regexPassword: any;

  constructor(public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: UntypedFormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory,
   ) { }
  


    ngOnInit(): void {     
    this.newpasswordView.of(this.newPassModel())  
    this.route.queryParams.subscribe(params => {
      const uniqueId = params.uniqueId;  
      this.newpassword.set('uniqueId',uniqueId);
  });
}
  get newpassword() {
    return this.newpasswordView.model;
  }

  private newPassModel() {
    const formDefault = new FormDefault([], []);

    return new Model(this.formBuilder.group({
      uniqueId: [''],
      password: ['', [Validators.required, Validators.minLength(8),Validators.maxLength(15),Validators.pattern(AppConstants.regexPassword)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8),Validators.maxLength(15),Validators.pattern(AppConstants.regexPassword)]],
    },
    { validators: [passwordMatch] }), this.sysService, formDefault, '');

  }

  submit() {
    if (this.newpasswordView.isValid()) {
      this.newpasswordView.toSaveNav('/mail/resetpwd', "","/login", "Your Password has been changed." );
    }
  }
  cancel(){
    this.sysService.navigate(['/']);
  }
  navHome() {
    this.sysService.navigate(['/']);
  }
}