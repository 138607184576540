<div *ngIf="!paymentStatus==true">
  <p class="p-redirection">You will be redirected in a few seconds....</p>

</div>

<p-dialog header="Payment Status" [(visible)]="paymentStatus" [style]="{width: '300px'}" (onHide)="redirectPayment()"
  [modal]="true" [dismissableMask]="true">

  <div class="grid justify-content-center payment-pos ">
    <p-image src="../../assets/images/f.png" alt="Image" width="140"></p-image>
  </div>

  <h1 class="payment">{{ message }}</h1>

  <div class="p-col-fixed text-center">
    <button pButton type="button" label="Close" (click)="redirectPayment()"></button>
    <button pButton type="button" label="Try again" (click)="backToRecharge()"></button>
  </div>

</p-dialog>