import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model, noWhitespaceValidator } from '@app/_common';
import { AppConstants } from '@app/_config';
@Component({
  selector: 'app-centre-profile',
  templateUrl: './centre-profile.component.html',
  styleUrls: ['./centre-profile.component.css']
})
export class CentreProfileComponent {

  @ViewChild("fileInput") fileInput: ElementRef;
  sigRole: boolean;
  stampRole: boolean;
  private centerView: View = this.viewFactory.newView();
  isReportType: boolean = false;
  isPatientDetailsAllPage: boolean = false;
  isFooterHeaderAllPage: boolean = false;
  flag: boolean
  maxDate: Date;
  checkAet: boolean = false;
  message: string;
  hasHeader: boolean = false;
  hasFooter: boolean = false;
  footer: any
  header: any
  hasSignature: boolean = false;
  imageurl = "/assets/images/thumbnailnew.png";


  constructor(
    public sysService: SysService,
    public staticService: StaticService,

    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory) {
  }

  ngOnInit() {
    this.centerView.of(this.centerListModel());
    this.userDetails()


    this.maxDate = new Date();

  }
  countryLookup: any[];
  stateLookup: any[];
  stateLookupAll: any[];

  modalityLookup: any[];
  get center() {
    return this.centerView.model;
  }

  private centerListModel() {
    const formDefault = new FormDefault(['status',], [1,]);
    return new Model(this.formBuilder.group({
      id: [],
      name: ['', [Validators.required,]],
      email: ['', [Validators.required, Validators.pattern(AppConstants.regexEmail)]],
      address: ['', [Validators.required]],
      phoneNo: ['', [Validators.required, Validators.pattern(AppConstants.regexPhonenumber), Validators.minLength(7)]],
      stateId: [''],
      countryId: ['', [Validators.required]],
      aeTitle: ['', [noWhitespaceValidator]],
      status: [],
      docFile: [],
      docFile1: [],
      logo: [''],
      zip: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]*$/)]],
      marginTop: [, [Validators.required, Validators.pattern(AppConstants.regexZipCpde)]],
      marginLeft: [, [Validators.required, Validators.pattern(AppConstants.regexZipCpde)]],
      marginBottom: [, [Validators.required, Validators.pattern(AppConstants.regexZipCpde)]],
      marginRight: [, [Validators.required, Validators.pattern(AppConstants.regexZipCpde)]],
      isReportType: [],
      isFooterHeaderAllPage: [],
      isPatientDetailsAllPage: [],
      footer: [],
      header: [],

      startDate: ['', [Validators.required]]




    }), this.sysService, formDefault, '');
  }


  async saveCenter() {
    if (this.centerView.isValid()) {
      this.center.setMultipart();
      if (this.checkAet) {
        this.sysService.error(`${this.message}`)
      }
      else {
        if (this.center.get('isReportType') === true && this.center.get('isPatientDetailsAllPage') === true) {
          this.sysService.error("Please select any from the checkbox. ")
          this.center.set('isReportType', false)
        } else {


          await this.centerView.toSave("/users/centreupdate", "id", "Profile Successfully Updated.").then(data => {
            this.userDetails();

            this.center.clearMultipart();
            this.sysService.success('Profile Successfully Updated')

          });
          this.center.set("docFile", null);
          this.center.set("docFile1", null);
          this.sysService.loadUserName();
        }
      }
    }
  }



  getFilename(name) {
    if (name) {
      const filename = name.substring(name.indexOf("_") + 1, name.length);
      return filename.length > 15 ? filename.substring(0, 15) : filename;
    }
    return "";
  }

  closeheader(row: any) {

    this.center.set("header", null);
    this.header = null;
    this.resetFileInput()
    this.center.view.header = null;
    this.header = this.imageurl;
  }

  closefooter(row: any) {

    this.center.set("footer", null);
    this.footer = null;
    this.resetFileInput1()
    this.center.view.footer = null;
    this.footer = this.imageurl;
  }



  userDetails() {
    this.centerView
      .toEditFetch("/users/userprofile", "id")
      .then((data) => {
        this.centerView.image(`/users/cen-header`).subscribe((byte) => {
          this.hasHeader = byte.byteLength > 0;
          this.header = this.sysService.arrayBufferToBase64ImageSource(
            byte,
            data.signatureExt
          );
        });
        this.centerView.image(`/users/cen-footer`).subscribe((byte) => {
          this.hasFooter = byte.byteLength > 0;
          this.footer = this.sysService.arrayBufferToBase64ImageSource(
            byte,
            data.signatureExt
          );
        });
      });
  }



  modalitiesLookup() {
    this.centerView.getData(`/modality/lookup`).then(data => { this.modalityLookup = data; });
  }


  onCountryChange(event) {
  }

  filterCountry(event: any) {
    this.centerView.getData(`/country/lookup?filter=${event.query}`).then(data => { this.countryLookup = data; });
  }


  deteleCenter() {
    this.centerView.toDeleteNew('/centre/delete', "id", "id", {
      onSuccess: () => {
        this.sysService.signOut();
        window.alert("Your Account deleted Successfully.");
      },
      confirm: "Deleting your profile will permanently remove all of your data and content from our platform. Do you want to continue?"
    });
  }

  CenterDeactivte() {
    this.center.set("status", 0);
    this.centerView.toSave(`/users/centre-deactivate/${this.center?.view?.id}`, "id")
      .then(onSuccess => {
        this.sysService.signOut();
        window.alert("Account deactivated successfully");
      })
      .catch(onError => {
      });
  }



  filterState(event: any) {
    // check if stateLookupAll is already loaded
    if (this.stateLookupAll) {
      // if loaded, filter the results
      this.stateLookup = this.stateLookupAll.filter(x => x.name.toLowerCase().indexOf(event.query.toLowerCase()) === 0);
    } else {
      // if not loaded, get countryId from billing view and load states for that country
      let countryId = this.center?.view?.countryId?.id;
      this.centerView.getData(`/state/lookupbycountry?countryId=${countryId}`).then(data => {
        // store the loaded states and set the filtered results
        this.stateLookup = data;
        this.stateLookupAll = data;
      });
    }
  }

  loadState(countryId) {
    // load states for the given countryId
    this.centerView.getData(`/state/lookupbycountry?countryId=${countryId}`).then(data => {
      // store the loaded states and set the filtered results
      this.stateLookupAll = data;
      this.stateLookup = data;
    });
  }

  stateAreaOnChange(event: any) {
    // load states for the selected country
    this.loadState(event.id);
    // reset stateId
    this.center.set("stateId", null);
  }
  callApi(event: string) {
    this.centerView.getData(`/centre/title-checker?filter=${event}`)
      .then((response: any) => {

        if (response?.message.trim() === "not exists") {
          this.message = 'This AE Title is currently available.';
          this.checkAet = false
        } else {
          this.message = 'This AE Title already exists.';
          this.checkAet = true
        }


      });
  }
  reportMarginReset() {
    this.center.set('marginTop', 50)
    this.center.set('marginLeft', 50)
    this.center.set('marginRight', 50)
    this.center.set('marginBottom', 50)


  }



  updateCheckboxes(centreCheckbox: string) {
    if (centreCheckbox === 'isPatientDetailsAllPage') {
      this.isReportType = !this.isPatientDetailsAllPage;
    } else if (centreCheckbox === 'isReportType') {
      this.isPatientDetailsAllPage = !this.isReportType;
    }
  }
  reportupdateCheckboxes() {

  }
  selectFile(file, name) {
    this.sysService.clear();
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target) {
        const img = new Image();
        img.src = e.target.result as string;

        img.onload = () => {
          const width = img.width;
          const height = img.height;


          if (file[0].size <= 200 * 1024) {
            if (width >= 1200 && width <= 1699 && height >= 150 && height <= 250) {
              this.center.setFile(file, name);
              this.center.view.header = null;
              this.header = img.src;
              this.hasHeader = true
            } else {
              this.sysService.error(
                "Please choose a file with dimensions between 1200 x 150 and 1699 x 250."
              );
              this.resetFileInput();
              this.center.view.header = null;
            }
          } else {
            this.sysService.error(
              "File size exceeds 200kb limit or incorrect dimensions. Please choose a file with dimensions between 1500 x 150 and 1699 x 250."
            );
            this.resetFileInput();
            this.center.view.header = null;
          }
        };
      }
    };
    // Read the file as a data URL
    reader.readAsDataURL(file[0]);
  }


  selectFile1(file, name) {
    this.sysService.clear();
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target) {
        const img = new Image();
        img.src = e.target.result as string;

        img.onload = () => {
          const width = img.width;
          const height = img.height;


          if (file[0].size <= 200 * 1024) {
            if (width >= 1200 && width <= 1699 && height >= 120 && height <= 250) {
              this.center.setFile(file, name);
              this.center.view.footer = null;
              this.footer = img.src;
              this.hasFooter = true
            } else {
              this.sysService.error(
                "Please choose a file with dimensions between 1200 x 120 and 1699 x 250."
              );
              this.resetFileInput1();
              this.center.view.footer = null;
            }
          } else {
            this.sysService.error(
              "File size exceeds 200kb limit or incorrect dimensions. Please choose a file with dimensions between 1500 x 120 and 1699 x 250.."
            );
            this.resetFileInput1();
            this.center.view.header = null;
          }
        };
      }
    };
    // Read the file as a data URL
    reader.readAsDataURL(file[0]);
  }

  resetFileInput() {
    // Reset the value of the input file
    this.fileInput.nativeElement.value = "";
  }
  resetFileInput1() {
    // Reset the value of the input file
    this.fileInput.nativeElement.value = "";
  }
  sigRoles() {
    this.sigRole = true;
  }
  stampRoles() {
    this.stampRole = true;
  }

}



