<form [formGroup]="transactionList.form">
    <div *ngIf="transactionList.isform || transactionList.isView()" class="card grayCard ">
        <div class="grid fluid">
            <div class="field col-12 md:col-12 lg:col-6">
                <label for="Title">Transaction Id </label>
                <input type="text" pInputText id="Title" formControlName="transactionId" pAutoFocus [autofocus]="true"
                    maxlength="60" />
            </div>
            <div class="field col-12 md:col-6 lg:col-6">
                <label for="Title">Transaction Type <span class="validation-star">*</span> </label>
                <p-dropdown formControlName="transactionType" inputId="status" placeholder="Select"
                    [options]="staticService.TransactionType" required="true">
                </p-dropdown>
                <f-error [err]="transactionList.required('transactionType')" msg="Transaction Type  is
                required"></f-error>
            </div>
            <div class="field col-12 md:col-6 lg:col-6">
                <label for="Title"> Pay Status <span class="validation-star">*</span></label>
                <p-dropdown formControlName="payStatus" inputId="status" placeholder="Select"
                    [options]="staticService.payStatus" required="true">
                </p-dropdown>
                <f-error [err]="transactionList.required('payStatus')" msg="Pay Status is required"></f-error>
            </div>
            <div class="field col-12 md:col-12 lg:col-6">
                <label for="Title">Amount Paid <span class="validation-star">*</span></label>
                <p-inputNumber formControlName="amountPaid" maxlength="6" inputId="locale-indian" locale="en-IN"
                    required="true"></p-inputNumber>
                <f-error [err]="transactionList.required('amountPaid')" msg="Amount Paid is
                    required"></f-error>
            </div>
            <div class="field col-12 md:col-6  lg:col-6">
                <label>Valid From <span class="validation-star">*</span></label>
                <p-calendar [showIcon]="true" formControlName="validFrom" dateFormat="dd-mm-yy" dataType="string"
                    class="widthClass" required="true" [showIcon]="true"></p-calendar>
                <f-error [err]="transactionList.required('validFrom')" msg="Valid From is
                required"></f-error>
            </div>
            <div class="field col-12 md:col-6  lg:col-6">
                <label>Valid Till <span class="validation-star">*</span> </label>
                <p-calendar [showIcon]="true" formControlName="validTill" required="true" dateFormat="dd-mm-yy"
                    dataType="string" class="widthClass" [showIcon]="true"></p-calendar>
                <f-error [err]="transactionList.required('validTill')" msg="Valid Till is
                required"></f-error>
            </div>
            <div class="field col-12 md:col-6  lg:col-6">
                <label>Comment</label><textarea class="p-inputtextarea p-inputtext p-component p-element" [rows]="3"
                    [cols]="40" maxlength="480" formControlName="comments" pInputTextarea></textarea>
            </div>
        </div>
        <div class="formgrid text-center bc">
            <button pButton pRipple type="button" label="Cancel" icon="pi pi-times"
                class="p-button-outlined p-button-info" (click)="listView(transactionList.isEmptyList());"></button>
            <button pButton pRipple type="button" label=" Save" icon="pi pi-check"
                class="p-button-raised p-button-success" (click)="saveTransactionList()" [disabled]="sysService.loading"
                disabled="!transactionList.isNew()">
                <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
            </button>
        </div>
    </div>
</form>
<div class="table-responsive">


    <p-table #dt [value]="transactionList.list" class="study-list-class" [scrollable]="true"
        [scrollHeight]="heightTable" [totalRecords]="transactionList.total" [lazy]="true"
        (onLazyLoad)="listTransactionListLazy($event)" dataKey="studyId" [(selection)]="transactionList.selection"
        selectionMode="multiple" [loading]="sysService.loading" filterDelay="400"
        styleClass="p-datatable-sm p-datatable-gridlines"
        currentPageReportTemplate="{first} to {last} of {totalRecords}" [rowsPerPageOptions]="[25,50]" [rowHover]="true"
        [rows]="25" [paginator]="true" [showCurrentPageReport]="true" alwaysShow="false" paginatorPosition="bottom"
        [globalFilterFields]="['name','rate','description']">



        <ng-template pTemplate="caption">


            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" maxlength="150" [(ngModel)]="transactionList.searchTxt"
                    (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
            </span>
            &nbsp;&nbsp; <button pButton pRipple type="button" label="Export Excel" icon=" pi pi-download"
                (click)="downloadExcel(transactionList)" [disabled]="sysService.loading "
                class="p-button-raised p-button-secondary m-1">
                <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
            </button>



        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="t.transaction_id">Transaction ID
                    <p-sortIcon field="t.transaction_id"></p-sortIcon>
                </th>
                <th pSortableColumn="t.valid_from">Transaction Date
                    <p-sortIcon field="t.valid_from"></p-sortIcon>
                </th>
                <th pSortableColumn="t.transaction_type">Transaction Type
                    <p-sortIcon field="t.transaction_type"></p-sortIcon>
                </th>
                <th pSortableColumn="t.pay_status">Transaction Status<p-sortIcon field="t.pay_status"></p-sortIcon>
                </th>
                <th pSortableColumn="invoice">
                    Invoice Number
                    <p-sortIcon field="invoice"></p-sortIcon>
                </th>
                <th pSortableColumn="t.amount_paid" style="text-align: end;">Transaction Amount
                    <p-sortIcon field="t.amount_paid"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-transactionList>
            <tr>
                <td>{{transactionList.transactionId}}</td>
                <td>{{ transactionList.paymentDate | date: 'dd-MM-yyyy hh:mm a ' }}</td>
                <td><span *ngIf="transactionList.transactionType ==1">Recharge</span>
                    <span *ngIf="transactionList.transactionType ==2">Refund</span>
                    <span *ngIf="transactionList.transactionType ==3">Bonus</span>
                </td>
                <td><span *ngIf="transactionList.payStatus ==0" class="text-accent">Cancel</span>
                    <span *ngIf="transactionList.payStatus ==1" class="text-default">Pending</span>
                    <span *ngIf="transactionList.payStatus ==2" class="text-danger">Failed </span>
                    <span *ngIf="transactionList.payStatus ==3" class="text-success">Success</span>
                </td>
                <td *ngIf="transactionList.payStatus ==3" class="link" (click)="pdfDownload(transactionList)">
                    {{transactionList.invoice}}
                    <i class="pi pi-download link-study" pTooltip="Download invoice"
                        (click)="pdfDownload(transactionList)"></i>

                </td>
                <td *ngIf="transactionList.payStatus !==3"> {{transactionList.invoice}}
                </td>
                <td style="text-align: end">{{transactionList.amount}}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td class="norecordstudy" [attr.colspan]="6">No records found</td>
            </tr>
        </ng-template>
    </p-table>
</div>