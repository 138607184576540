<div *ngIf="!sysService.isPermission()">
    <not-found></not-found>
</div>
<div *ngIf="sysService.isPermission()">
    <p-toolbar>
        <div class="p-toolbar-group-start">
            <f-nav first="/user-role" label="Users" [model]="userRole"> </f-nav>
        </div>
        <div class="p-toolbar-group-end">
            <!-- <button pButton pRipple type="button" label="Delete" icon="pi pi-trash" class="p-button-outlined
      p-button-outlined p-button-danger" (click)="deleteuserRole(userRole.id)" *ngIf="userRole.isEdit()"></button> -->
        </div>
    </p-toolbar>
    <!-- ----------------------------------Add form -------------------- -->
    <form [formGroup]="userRole.form">
        <div *ngIf="userRole.isform || userRole.isView()" class="card">
            <div class="grid">

                <div class="field col-12 md:col-6 lg:col-6">
                    <label>Name <span class="validation-star">*</span></label>
                    <input pInputText maxlength="150" formControlName="name" *ngIf="sysService.isCenter()"
                        [readOnly]="flag" type="text" pAutoFocus autofocus="true" required>
                    <input pInputText maxlength="150" formControlName="name" *ngIf="sysService.isAdmin()" type="text"
                        pAutoFocus autofocus="true" required>
                    <f-error [err]="userRole.required('name')" msg=" Name is required"></f-error>
                    <f-error [err]="userRole.pattern('name')" msg=" white space not allowed on begining"></f-error>

                </div>

                <div class="field col-12 md:col-6 lg:col-6">

                    <label for="EmailAddress">Email Address <span class="validation-star">*</span></label>
                    <input id="EmailAddress" maxlength="100" type="text" *ngIf="sysService.isCenter()" pInputText
                        formControlName="email" required autocomplete="off" [readOnly]="flag" />
                    <input id="EmailAddress" maxlength="100" type="text" *ngIf="sysService.isAdmin()" pInputText
                        formControlName="email" required autocomplete="off" />
                    <f-error [err]="userRole.required('email')" msg="Email Address is required "></f-error>
                    <f-error [err]="userRole.pattern('email')"
                        msg="This e-mail address is not valid. Valid records include to user@somehost.somedomain. And emails only accept lowercase letters.">
                    </f-error>
                </div>

                <div class="field col-12 md:col-6 lg:col-6">
                    <label>User Role<span class="validation-star">*</span></label>

                    <p-dropdown *ngIf="sysService.isCenter()" [readonly]="flag" placeholder="Select"
                        inputId="userChildRole" formControlName="userChildRole" [options]="staticService.centerUser"
                        required="true"></p-dropdown>
                    <p-dropdown *ngIf="!sysService.isCenter()" inputId="userChildRole" placeholder="Select"
                        formControlName="userChildRole" [options]="staticService.userRole" required="true"></p-dropdown>
                    <f-error [err]="userRole.required('userChildRole')" msg="User Role is required"></f-error>

                </div>
                <div class="field col-12 md:col-6 lg:col-6">
                    <label>Phone Number <span class="validation-star">*</span></label>

                    <input pInputText maxlength="18" formControlName="phoneNo" type="text" *ngIf="sysService.isCenter()"
                        [readOnly]="flag">
                    <input pInputText maxlength="18" formControlName="phoneNo" *ngIf="sysService.isAdmin()" type="text">

                    <f-error [err]="userRole.required('phoneNo')" msg="Phone Number is required"></f-error>
                    <f-error [err]="userRole.pattern('phoneNo')" msg="Numbers only required"></f-error>
                    <f-error [err]="userRole.minlength('phoneNo')" msg="Enter a minimum of 7 digits"></f-error>

                </div>
                <div class="field col-12 md:col-6 lg:col-6">
                    <label>Status </label>
                    <p-dropdown *ngIf="sysService.isCenter()" [readonly]="flag" placeholder="Select"
                        formControlName="status" [options]="staticService.status" required="true"></p-dropdown>
                    <p-dropdown *ngIf="sysService.isAdmin()" formControlName="status" inputId="status"
                        placeholder="Select" [options]="staticService.status" required="true">
                    </p-dropdown>

                </div>
            </div>

            <div class="formgrid text-center bc">
                <button pButton pRipple type="button" label="Cancel" icon="pi pi-times"
                    class="p-button-outlined p-button-info" (click)="listView(userRole.isEmptyList());"></button>

                <button pButton pRipple type="button" [label]="saveButtonLabel " icon="pi pi-check"
                    class="p-button-raised p-button-success" (click)="getUserRole(userRole,false)"
                    [disabled]="sysService.loading"
                    *ngIf="userRole.isForm() && !userRole.isEdit()&& sysService.isCenter()">
                    <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                </button>
                <button pButton pRipple type="button" [label]="saveButtonLabel " icon="pi pi-check"
                    class="p-button-raised p-button-success" (click)="saveUserRole()" [disabled]="sysService.loading"
                    *ngIf="userRole.isForm() && sysService.isAdmin()">
                    <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                </button>

                <button pButton pRipple type="button" label=" Save & Add" icon="pi pi-plus"
                    class="p-button-raised p-button-info" (click)="getUserRole(userRole,true)"
                    [disabled]="sysService.loading" *ngIf="userRole.isNew()">
                    <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                </button>
            </div>

        </div>
    </form>
    <!---------------------------------------------- table list --------------------------------------------- -->
    <div class="table-responsive" [style.display]="userRole.islist? 'block':'none'">

        <p-table #dt [value]="userRole.list" [totalRecords]="userRole.total" [lazy]="true" class="study-list-class"
            [scrollHeight]="heightTable" (onLazyLoad)="listuserRoleLazy($event)" [scrollable]="true" scrollHeight="65vh"
            dataKey="id" [(selection)]="userRole.selection" selectionMode="multiple" [loading]="sysService.loading"
            filterDelay="400" currentPageReportTemplate="{first} to {last} of {totalRecords}"
            [rowsPerPageOptions]="[ 25, 50]" [rowHover]="true" styleClass="p-datatable-sm" [rows]="25"
            [paginator]="true" [showCurrentPageReport]="true" alwaysShow="false" paginatorPosition="bottom"
            styleClass="p-datatable-sm p-datatable-gridlines"
            [globalFilterFields]="['name','code','description','status']">
            <ng-template pTemplate="caption">
                <div class="grid">
                    <div class="ml-2">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" maxlength="150" [(ngModel)]="userRole.searchTxt" pAutoFocus
                                [autofocus]="true" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search..." />
                        </span>
                    </div>
                    <div class="ml-2 mt-2">
                        <span>
                            <button pButton pRipple type="button" label="Create User" icon="pi pi-plus"
                                class="p-button-raised" *ngIf="userRole.isList()" (click)="add()"></button>
                        </span>
                    </div>
                    <div class="mt-2">
                        <button pButton pRipple type="button" label="Delete" icon="pi pi-trash"
                            class="p-button-outlined p-button-danger" (click)="deleteuserRoles()"
                            *ngIf="!userRole.isEmptyList() && userRole.selection.length > 0 "></button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="paginatorleft">

            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem" *ngIf="!this.sysService.isAdmin()">
                        <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
                    </th>
                    <th style="width: 3rem" *ngIf="this.sysService.isAdmin()">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th pSortableColumn="name" style="min-width:117px;">
                        Name
                        <p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="email">
                        Email Address
                        <p-sortIcon field="email"></p-sortIcon>
                    </th>
                    <th pSortableColumn="phoneNo">
                        Phone Number
                        <p-sortIcon field="phoneNo"></p-sortIcon>
                    </th>

                    <th pSortableColumn="userChildRole">
                        User Role
                        <p-sortIcon field="userChildRole"></p-sortIcon>
                    </th>

                    <th pSortableColumn="status">
                        Status
                        <p-sortIcon field="status"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-userRole>
                <tr>
                    <td *ngIf="!this.sysService.isAdmin()">
                        <p-tableCheckbox [value]="userRole" *ngIf="userRole.userChildRole ===3"></p-tableCheckbox>
                    </td>
                    <td *ngIf="this.sysService.isAdmin()">
                        <p-tableCheckbox [value]="userRole"></p-tableCheckbox>
                    </td>

                    <td><i class="pi pi-file-edit link-study" (click)="edit(userRole.id)" title="edit"> </i>&nbsp;
                        {{userRole.name}}
                    </td>
                    <td class="bdy-part">{{userRole.email}}</td>

                    <td>{{userRole.phoneNo}}</td>

                    <td><span *ngIf="userRole.userChildRole ===1">Assigner</span>
                        <span *ngIf="userRole.userChildRole ===2">Approver </span>
                        <span *ngIf="userRole.userChildRole ===3">Technician </span>
                        <span *ngIf="userRole.userChildRole ===4">Physician </span>

                    </td>

                    <td><span *ngIf="userRole.status ===0" class="text-danger">Inactive </span>
                        <span *ngIf="userRole.status ===1" class="text-success">Active </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td class="norecordstudy" [attr.colspan]="6">No records found</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>