import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/_common';
import { MyProfileComponent } from './my-profile.component';
const routes: Routes = [
  {
    path: '',
    children: [
     { path: 'profileview', component: MyProfileComponent,data :{ activeIndex:0}, canActivate: [AuthGuard] },
     { path: '', component: MyProfileComponent, data :{ activeIndex:0}, canActivate: [AuthGuard] },
     { path: 'billing-information', component: MyProfileComponent, data :{ activeIndex:1},canActivate: [AuthGuard] },
     { path: 'invoice',component: MyProfileComponent, data :{ activeIndex:2},canActivate: [AuthGuard] },
     { path: 'Transaction-history', component: MyProfileComponent, data :{ activeIndex:3},canActivate: [AuthGuard] },
     { path: 'change-password', component: MyProfileComponent, data :{ activeIndex:3},canActivate: [AuthGuard] },
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyProfileRoutingModule { }
