<div class="grid ">
    <div class="col-12 md:col-12 lg:col-12">



        <h1>Recharge</h1>


    </div>
    <div class="col-12 md:col-offset-2 md:col-4 ">
        <div class="border-round border-white card flex align-items-center justify-content-center">
            <div class="numblue">

            </div>
            <div class="blue">
                <button (click)="changeNextpage(200)">Package : Rs-600</button>

            </div>

        </div>
    </div>
    <div class="col-12 md:col-4">
        <div class="border-round border-white card flex align-items-center justify-content-center">
            <div class="numblue">

            </div>
            <div class="blue">
                <h2>Package : Rs-600</h2>
            </div>

        </div>
    </div>
    <div class="col-12 md:col-offset-2 md:col-4">
        <div class="border-round border-white card flex align-items-center justify-content-center">
            <div class="numblue">

            </div>
            <div class="blue">
                <h2>Package : Rs-900</h2>
            </div>

        </div>
    </div>
    <div class="col-12 md:col-4">
        <div class="border-round border-white card flex align-items-center justify-content-center">
            <div class="numblue">

            </div>
            <div class="blue">
                <h2>Package : RS-1200</h2>
            </div>

        </div>
    </div>
    <div class="col-12 md:col-offset-2 md:col-4">
        <div class="border-round border-white card flex align-items-center justify-content-center">
            <div class="numblue">

            </div>
            <div class="blue">
                <h2>Package : Rs-1500</h2>
            </div>

        </div>
    </div>
    <div class="col-12 md:col-4">
        <div class="border-round border-white card flex align-items-center justify-content-center">
            <div class="numblue">

            </div>
            <div class="blue">
                <h2>Package : Rs-1800</h2>
            </div>

        </div>
    </div>

    <div class="grid">
        <div class="col-12 md:col-6 lg:col-6">
            <div class="card">

                <h1>Billing Information</h1>

                <form [formGroup]="billinginfo.form">
                    <div class="grid fluid">
                        <div class="field col-12 md:col-12 lg:col-4">
                            <label for="Title"> Billing Name </label>
                            <input type="text" pInputText id="Title" maxlength="50" formControlName="name" pAutoFocus
                                [autofocus]="true" autofocus />
                        </div>

                        <div class="field col-12 md:col-6  lg:col-4">
                            <label>Phone Number </label>
                            <input pInputText maxlength="18" formControlName="phone" type="text">

                        </div>

                        <div class="field col-12 md:col-12 lg:col-4">
                            <label for="Title">Company </label>
                            <input type="text" pInputText id="Title" maxlength="50" formControlName="company"
                                autofocus />
                        </div>
                        <div class="field col-12 md:col-12 lg:col-4">
                            <label for="Title">Tax No </label>
                            <input type="text" pInputText id="Title" maxlength="50" formControlName="taxNo" autofocus />
                        </div>

                        <div class="field col-12 md:col-12 lg:col-4">
                            <label for="Title">Country </label>
                            <input type="text" pInputText id="Title" maxlength="50" formControlName="countryId"
                                autofocus />
                        </div>
                        <div class="field col-12 md:col-12 lg:col-4">
                            <label for="Title">State </label>
                            <input type="text" pInputText id="Title" maxlength="50" formControlName="stateId"
                                autofocus />
                        </div>
                        <div class="field col-12 md:col-12 lg:col-4">
                            <label for="Title">City </label>
                            <input type="text" pInputText id="Title" maxlength="50" formControlName="city" autofocus />
                        </div>
                        <div class="field col-12 md:col-12 lg:col-4">
                            <label for="Title">Zip </label>
                            <input type="text" pInputText id="Title" maxlength="12" formControlName="zip" autofocus />
                        </div>

                        <div class="field col-12 md:col-6 lg:col-4">

                            <label for="email">Email Address </label>
                            <input id="email" type="text" maxlength="100" pInputText formControlName="email" required />

                        </div>

                        <div class="field col-12 md:col-12 lg:col-12">
                            <label for="Title">Address </label>
                            <textarea [rows]="3" [cols]="39" maxlength="500" class="p-inputtextarea p-inputtext
                         p-component p-element" formControlName="address" pInputTextarea></textarea>

                        </div>

                    </div>
                    <div class="formgrid text-center">

                    </div>
                </form>

            </div>

        </div>

        <div class="col-12 md:col-6 lg:col-6 ">
            <div class="card resol">
                <h1>Billing </h1>

                <form [formGroup]="billinginfo.form">
                    <div class="grid fluid justify-content-center ">
                        <div class="flex col-12 md:col-6 lg:col-12 ">
                            <label><b>Plan Amount :</b></label>
                            <span class="pl-2 "><b>Rs: 849</b></span>
                        </div>

                        <div class="flex col-12 md:col-6 lg:col-12 mt-3">
                            <label><b>Total Tax :</b></label>
                            <span class="pl-2"><b>Rs: 0 </b></span>
                        </div>

                        <div class="flex col-12 md:col-6 lg:col-12 mt-3">
                            <label><b>Plan Amount To Pay :</b></label>
                            <span class="pl-2"><b>Rs: 849</b> </span>
                        </div>
                        <div class="flex col-12 md:col-6 lg:col-11 ">
                            <p-checkbox name="val1" value="val1"></p-checkbox>

                            <span class="pl-3"> By continuing, you are agreeing to our Subscriber term.<br>
                                Your subscription will renew automatically by charging your payment method on file until
                                you
                                cancel.
                                <br>You may at any time prior to the next Billing</span>
                        </div>
                        <div class="flex col-12 md:col-6 lg:col-12 mt-3">
                            <label><i class="pi pi-exclamation-triangle ml-8"></i>&nbsp; Don't refresh your page on
                                payment
                                submit</label>

                        </div>
                        <div class="flex col-12 md:col-6 lg:col-12 justify-content-center mt-3">

                            <button pButton pRipple type="button" label="Cancel Payment" icon="pi pi-times"
                                class="p-button-outlined p-button-info"></button>
                            <button pButton pRipple type="button" label="Proceed To Pay" (click)="pay()"
                                class="p-button-raised p-button-success"></button>

                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>

</div>