<header class="header" *ngIf="!sysService.isLoggedIn()">
    <div class="wrap">
        <div class="right">
            <div class="rgtBdr ">
                <a (click)="navHome()"> <img class="web" src="../../../assets/images/eclogoo.png"></a>
            </div>
        </div>
    </div>
</header>
<div class="card">
    <div class="grid p-3">
        <div class=" field col-12 md:col-6 lg:col-12 text-xl">
            <h1>Cancellations & Refunds Policy</h1>
            <p>
                <li style="margin-left: 10px;"> Time frames for requesting a refund: <br></li>
                <li style="margin-left: 10px;"> Reasons for a refund: Inability to report study, errors or defects in
                    the shared study, application errors, non-compliance with the terms and conditions, or
                    dissatisfaction with the quality of the service. <br></li>
                <li style="margin-left: 10px;"> Documentation and evidence requirements: clients need to provide
                    documentation and/or evidence to support their request for a refund. <br></li>
                <li style="margin-left: 10px;"> Processing time and method: time to be decided, also method method of
                    payment, such as a credit to the client's account or a direct transfer to the client's bank account.
                    <br>
                </li>
            </p>
        </div>
    </div>
</div>