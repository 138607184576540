<div *ngIf="!sysService.isPermission()">
    <not-found></not-found>
</div>
<div *ngIf="sysService.isPermission()">

    <p-toolbar>
        <div class="p-toolbar-group-start">
            <f-nav first="/centres" label="Centres" [model]="center"> </f-nav>
        </div>
        <div class="p-toolbar-group-end">
            <button pButton pRipple type="button" label="Delete" icon="pi pi-trash" class="p-button-outlined
        p-button-outlined p-button-danger" (click)="deletecenter(center.id)"
                *ngIf="center.isEdit() && sysService.isAdmin() "></button>
        </div>
    </p-toolbar>
    <form [formGroup]="center.form">
        <div *ngIf="center.isform || center.isView()" class="card">
            <div class="grid">
                <div class="field col-12 md:col-6 lg:col-6">
                    <label>Centre Name <span class="validation-star">*</span></label>
                    <input pInputText maxlength="100" [readOnly]="flag" formControlName="name" type="text"
                        autocomplete="off" pAutoFocus [autofocus]="true">
                    <f-error [err]="center.required('name')" msg="Centre Name is required"></f-error>
                </div>
                <div *ngIf="sysService.isAdmin()" class="field col-12 md:col-6 lg:col-6">
                    <label>AE Title</label>
                    <input pInputText maxlength="48" formControlName="aeTitle" [readOnly]="!sysService.isAdmin()"
                        type="text" autocomplete="off" (input)="callApi($event.target.value)">
                    <f-error [err]="center.required('aeTitle')" msg="AE Title is required"></f-error>
                    <f-error [err]="center.pattern('aeTitle')" msg="AE Title should not contain white space"></f-error>
                    <small class="p-error">
                        {{ message }}
                    </small>
                </div>
                <div class="field col-12 md:col-6 lg:col-6">
                    <label for="email">Email Address <span class="validation-star">*</span></label>
                    <input id="email" type="text" [readOnly]="flag" maxlength="100" pInputText formControlName="email"
                        required />
                    <f-error [err]="center.required('email')" msg="Email Address is required "></f-error>
                    <f-error [err]="center.pattern('email')"
                        msg="This e-mail address is not valid. Valid records include to user@somehost.somedomain. And emails only accept lowercase letters.">
                    </f-error>
                </div>
                <div class="field col-12 md:col-6 lg:col-6">
                    <label>Start Date <span class="validation-star">*</span></label>
                    <p-calendar [showIcon]="true" [disabled]="flag" *ngIf="!flag" formControlName="startDate"
                        placeholder="DD-MM-YYYY" dateFormat="dd-mm-yy" dataType="string"
                        class="widthClass"></p-calendar>
                    <input pInputText maxlength="18" [readOnly]="true" *ngIf="flag" formControlName="startDate"
                        type="text">
                    <f-error [err]="center.required('startDate')" msg="Start Date is required"></f-error>
                </div>
                <div class="field col-12 md:col-6 lg:col-6">
                    <label>Zip Code <span class="validation-star">*</span> </label>
                    <input pInputText maxlength="12" [readOnly]="flag" formControlName="zip" type="text">
                    <f-error [err]="center.required('zip')" msg="Zip Code is required"></f-error>
                    <f-error [err]="center.pattern('zip')" msg=" Only alphanumeric characters are accepted"></f-error>
                </div>
                <div class="field col-12 md:col-6  lg:col-6">
                    <label>Phone Number <span class="validation-star">*</span></label>
                    <input pInputText maxlength="18" [readOnly]="flag" formControlName="phoneNo" type="text">
                    <f-error [err]="center.required('phoneNo')" msg="Phone Number is required"></f-error>
                    <f-error [err]="center.pattern('phoneNo')" msg="Only numerical values are accepted "></f-error>
                    <f-error [err]="center.minlength('phoneNo')" msg="Enter a minimum of 7 digits"></f-error>
                </div>
                <div class="field col-12 md:col-6 lg:col-6">
                    <label>Country <span class="validation-star">*</span></label>
                    <p-autoComplete formControlName="countryId" *ngIf="!flag" [suggestions]="countryLookup"
                        (onSelect)="stateAreaOnChange($event)" maxlength="60" forceSelection="true"
                        (completeMethod)="filterCountry($event)" field="name" ariaLabel="Country" [dropdown]="true"
                        [autoHighlight]="true" showEmptyMessage="true" delay="400">
                    </p-autoComplete>
                    <input pInputText maxlength="495" [readOnly]="flag" *ngIf="flag"
                        value="{{center?.get('countryId')?.name}}" type="text">
                    <f-error [err]="center.required('countryId')" msg="Country is required"></f-error>
                </div>
                <div class="field col-12 md:col-6 lg:col-6">
                    <label>State </label>
                    <p-autoComplete formControlName="stateId" *ngIf="!flag" [suggestions]="stateLookup"
                        (completeMethod)="filterState($event)" maxlength="60" forceSelection="true" field="name"
                        ariaLabel="State" [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400">
                    </p-autoComplete>
                    <input pInputText maxlength="495" [readOnly]="flag" *ngIf="flag"
                        value="{{center?.get('stateId')?.name}}" type="text">
                </div>
                <div class="field col-12 md:col-6 lg:col-6">
                    <label>Address <span class="validation-star">*</span></label>
                    <textarea class="p-inputtextarea p-inputtext p-component p-element" [rows]="3" [cols]="40"
                        maxlength="480" formControlName="address" pInputTextarea></textarea>
                    <f-error [err]="center.required('address')" msg="Address is required"></f-error>
                </div>
                <div *ngIf="sysService.isAdmin() && center.get('status') !== 2" class="field col-12 md:col-6 lg:col-6">
                    <label>Status </label>
                    <p-dropdown formControlName="status" inputId="status" placeholder="Select"
                        [options]="staticService.status" required="true">
                    </p-dropdown>
                    <f-error [err]="center.required('status')" msg="Status is required"></f-error>
                </div>


                <div class="field col-12 md:col-6 lg:col-6 mt-3" *ngIf="!center.isNew()">

                    <!-- <p>{{center.form.value | json}}</p> -->
                    <div class="grid stamp-pos">

                        <div class="col-12 lg:col-4">

                            <div class="py-3"> <label>Upload Your Header</label></div>
                            <div class="file-upload-sec">
                                <input type="file" #fileInput id="document" formControlName="docFile"
                                    (change)="selectFile($event.target.files,'header')" accept=".jpg,.jpeg,.png" />
                            </div>

                        </div>
                        <div class="col-6 lg:col-3 text-center ">
                            <div class="ml-2 stamp"> <p-image [src]="!hasHeader ? imageurl : header"></p-image>
                                <span class="close-icon btn-tr" *ngIf="hasHeader && this.center.get('header')"
                                    (click)="closeheader(center)">&#10006;</span>
                                <div class="pt-2 ">{{ getFilename(center?.view?.header)
                                    }}</div>


                            </div>



                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-6 lg:col-6 mt-3" *ngIf="!center.isNew()">
                    <div class="grid stamp-pos">

                        <div class="col-12 lg:col-4">

                            <div class="py-3"> <label>Upload Your Footer</label></div>
                            <div class="file-upload-sec">
                                <input type="file" #fileInput id="document" formControlName="docFile1"
                                    (change)="selectFile1($event.target.files,'footer')" accept=".jpg,.jpeg,.png" />
                            </div>

                        </div>
                        <div class="col-6 lg:col-3 text-center " *ngIf="!center.isNew()">
                            <div class="ml-2 stamp"> <p-image [src]="!hasFooter ? imageurl : footer"></p-image>
                                <span class="close-icon btn-tr" *ngIf="hasFooter && this.center.get('footer')"
                                    (click)="closefooter(center)">&#10006;</span>
                                <div class="pt-2 ">{{ getFilename(center?.view?.footer)
                                    }} </div>
                            </div>



                        </div>
                    </div>
                </div>


                <div *ngIf="sysService.isAdmin()" class="field col-12 md:col-6 lg:col-6">

                    <p-checkbox formControlName="strictDeduction" [binary]="true" id="strictDeduction"></p-checkbox>
                    &nbsp;
                    <label for="strictDeduction">Enable Strict deduction for study</label>
                </div>

            </div>
            <div class="formgrid text-center bc">
                <button pButton pRipple type="button" label="Cancel" icon="pi pi-times"
                    class="p-button-outlined p-button-info" (click)="listView(center.isEmptyList());"></button>
                <button pButton pRipple type="button" [label]="saveButtonLabel" icon="pi pi-check"
                    class="p-button-raised p-button-success" (click)="savecenter()" [disabled]="sysService.loading"
                    *ngIf="!sysService.isRad() || center.isNew()">
                    <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                </button>
                <button pButton pRipple type="button" label=" Save & Add" icon="pi pi-plus"
                    class="p-button-raised p-button-info" (click)="savecenter(true)" [disabled]="sysService.loading"
                    *ngIf="center.isNew()">
                    <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                </button>
            </div>
        </div>
    </form>
    <div [style.display]="center.islist ? 'block':'none'" class="table-responsive">


        <p-table #dt [value]="center.list" class="study-list-class" [scrollable]="true" [scrollHeight]="heightTable"
            [totalRecords]="center.total" [lazy]="true" (onLazyLoad)="listcenterLazy($event)" dataKey="id"
            [(selection)]="center.selection" selectionMode="multiple" [loading]="sysService.loading" filterDelay="400"
            styleClass="p-datatable-sm p-datatable-gridlines"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" [rowsPerPageOptions]="[25, 50]"
            [rowHover]="true" [rows]="25" [paginator]="true" [showCurrentPageReport]="true" alwaysShow="false"
            paginatorPosition="bottom" [globalFilterFields]="['name','email','phoneNo','status']">

            <ng-template pTemplate="caption">
                <div class="grid ">
                    <div class="ml-2">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" maxlength="150" [(ngModel)]="center.searchTxt"
                                (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..."
                                pAutoFocus [autofocus]="true" />
                        </span>
                    </div>
                    <div class="ml-2 mt-2">
                        <span *ngIf="sysService.isAdmin()">
                            <button pButton pRipple type="button" label="Add Centre" icon="pi pi-plus"
                                class="p-button-raised" *ngIf="center.isList()" (click)="add()"></button>
                        </span>
                    </div>
                    <div class="mt-2">
                        <button pButton pRipple type="button" label="Delete" icon="pi pi-trash"
                            class="p-button-outlined p-button-danger" (click)="deletecenters()"
                            *ngIf="!center.isEmptyList() && center.selection.length > 0 && sysService.isAdmin()"></button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="paginatorleft">
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th pSortableColumn="name" style="min-width:117px;">Centre Name <p-sortIcon
                            field="name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="email">Email Address <p-sortIcon field="email">
                        </p-sortIcon>
                    </th>
                    <th pSortableColumn="phone_no">Phone No<p-sortIcon field="phone_no">
                        </p-sortIcon>
                    </th>
                    <th pSortableColumn="createdDate">Registration Date <p-sortIcon field="createdDate"></p-sortIcon>
                    </th>
                    <th *ngIf="sysService.isAdmin()" pSortableColumn="walletBalance">Wallet Balance <p-sortIcon
                            field="walletBalance">
                        </p-sortIcon>
                    </th>
                    <th *ngIf="sysService.isAdmin()" pSortableColumn="status">Status<p-sortIcon field="status">
                        </p-sortIcon>
                    </th>
                    <th *ngIf="sysService.isAdmin()">Action </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-center>
                <tr [ngClass]="{'ui-state-highlight': chk.checked}">
                    <td>
                        <p-tableCheckbox #chk [value]="center"></p-tableCheckbox>
                    </td>
                    <td> <i class="pi pi-file-edit link-study" (click)="edit(center.id)" title="edit"></i>
                        {{center.name}}</td>
                    <td class="bdy-part"><span class="p-column-title"><b>Email</b></span> {{center.email}}</td>
                    <td><span class="p-column-title"><b>PhoneNo</b></span> {{center.phoneNo}} </td>
                    <td>{{ sysService.toDate( center?.createdDate ) | date: 'dd-MM-yyyy hh:mm a ' }}</td>
                    <td *ngIf="sysService.isAdmin()"><span class="p-column-title"><b>Wallet Balance</b></span>
                        {{center.walletBalance}}</td>
                    <td *ngIf="sysService.isAdmin()">
                        <span class="text-danger" *ngIf="center.status ===2 || center.status ===0 ">Inactive </span>
                        <span class="text-success" *ngIf=" center.status ===1"> Active</span>
                    </td>
                    <td *ngIf="sysService.isAdmin()">
                        <i class="pi pi-user-plus m-2" pTooltip="Approve"
                            style="color: rgb(135, 7, 133); font-size: x-large; cursor: pointer;"
                            *ngIf="center.status ===2 " (click)="activateButton(center)"></i>
                        <i class="pi pi-times-circle m-2" pTooltip="Deactivate"
                            style="color: rgb(204, 10, 10); font-size: x-large; cursor: pointer;"
                            *ngIf="center.status ===1 " (click)="activateButton(center)"></i>
                        <i class="pi pi-check-circle m-2" pTooltip="Activate"
                            style="color: rgb(17, 60, 7); font-size: x-large; cursor: pointer;"
                            *ngIf="center.status === 0 " (click)="activateButton(center)"></i>
                        <i class="pi pi-users m-2" pTooltip="Associate"
                            style="color: rgb(90, 8, 105); font-size: x-large; cursor: pointer;"
                            *ngIf="center.status === 1 " (click)="associateUser(center)"></i>
                        <i class="pi pi-wallet m-2" pTooltip="Wallet Update"
                            style="color: rgb(10, 26, 204); font-size: x-large; cursor: pointer;"
                            *ngIf="center.status === 1 " (click)="transactionAmount(center)"></i>
                    </td>

            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td class="norecordstudy" [attr.colspan]="10">No records found</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <p-dialog header="Associate User " [dismissableMask]="true" [(visible)]="associateUserList" (onHide)="rejectBack()"
        [modal]="true" [style]="{ width: '75vw' }" paginatorPosition="bottom"
        [breakpoints]="{ '991px': '75vw', '575px': '95vw' }" [draggable]="false" [resizable]="false">
        <div class="table-responsive">
            <p-table [value]="associateUsers" [scrollable]="true" scrollHeight="65vh" class="study-list-class"
                [scrollHeight]="heightTable" [loading]="sysService.loading" [showCurrentPageReport]="true"
                [paginator]="true" [rows]="10" styleClass="p-datatable-sm p-datatable-gridlines"
                currentPageReportTemplate="{first} to {last} of {totalRecords}" [totalRecords]="associateUsers?.length">
                <ng-template pTemplate="header">
                    <tr>
                        <th id="name" scope="col">Name</th>
                        <th id="email" scope="col">Email</th>
                        <th id="contactNo" scope="col">Contact No</th>
                        <th id="userRole" scope="col">User Role</th>
                        <th id="status" scope="col">Status</th>

                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-studyDetails>
                    <tr>
                        <td>{{ studyDetails.name }}</td>
                        <td>{{ studyDetails.email }}</td>
                        <td>{{ studyDetails.contactNo }}</td>
                        <td>{{ studyDetails.userRole }}</td>
                        <td> <span class="text-success" *ngIf="studyDetails.status ===3 ">Accept </span>
                            <span class="text-success" *ngIf="studyDetails.status ===2 ">Active </span>
                            <span class="text-success" *ngIf="studyDetails.status ===1 ">Pending </span>
                            <span class="text-danger" *ngIf=" studyDetails.status ===0"> Blocked</span>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td class="norecordstudy" colspan="5">No records found</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-dialog>
    <p-dialog header=" Wallet Update " [dismissableMask]="true" [(visible)]="transactionamount" [modal]="true"
        (onHide)="rejectBack()" [style]="{ width: '300px' }" [breakpoints]="{ '991px': '75vw', '575px': '95vw' }"
        [draggable]="false" [resizable]="false">
        <form [formGroup]="transactionAmountdetails.form">
            <div class="grid">
                <div class="col-12 ">
                    <p-dropdown class="rate" inputId="status" (onChange)="bonusTypeAmount($event)"
                        placeholder="Bonus Type " formControlName="bonusType" [options]="staticService.bonusType">
                    </p-dropdown>
                    <f-error [err]="transactionAmountdetails.required('bonusType')"
                        msg="Bonus Type is required"></f-error>
                </div>
                <div class="col-12 ">
                    <!-- <input pInputText maxlength="5" type="text"
                    pTooltip="{{ transactionAmountdetails.get('bonusType') === 1 ? '' : 'The maximum allowable amount for the update is thirty thousand.' }}"
                    formControlName="amountPaid" placeholder="Amount"
                    [readonly]="transactionAmountdetails.get('bonusType') === 1"> -->


                    <p-inputNumber formControlName="amountPaid" placeholder="Amount"
                        [readonly]="transactionAmountdetails.get('bonusType') === 1" class="inputwidth"
                        pTooltip="{{ transactionAmountdetails.get('bonusType') === 1 ? '' : 'The maximum allowable amount for the update is thirty thousand.' }}"
                        inputId="minmax" mode="decimal" [min]="1" [max]="30000">
                    </p-inputNumber>

                    <f-error [err]="transactionAmountdetails.required('amountPaid')" msg="Amount is required"></f-error>
                    <f-error [err]="transactionAmountdetails.pattern('amountPaid')"
                        msg="Only numerical values are accepted"></f-error>

                </div>
                <div class="col-12">
                    <textarea rows="4" cols="30" formControlName="comments"
                        class="p-inputtextarea p-inputtext p-component p-element" pInputTextarea
                        placeholder="Comments"></textarea>
                    <f-error [err]="transactionAmountdetails.required('comments')" msg="comments is required"></f-error>

                </div>
            </div>
            <div class="text-center mt-3">
                <button pButton pRipple type="button" label="Back" icon=" pi pi-arrow-left"
                    (click)="cancelTransactionAmount();dt.reset()"
                    class="p-button-outlined p-button-info"></button>&nbsp;
                <button pButton pRipple type="button" label="Save" icon=" pi pi-check" (click)="walletUpdate()"
                    class="p-button-outlined p-button-success"></button>&nbsp;
            </div>
        </form>
    </p-dialog>
</div>