import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { ExportService, FormDefault, StaticService, SysService, ViewAction, ViewFactory } from '@app/_common';
import { ActivatedRoute, Router, } from '@angular/router';
import { environment } from '@environments/environment';
@Component({
  selector: 'billing-dashboard',
  templateUrl: './billing-dashboard.component.html',
  styleUrls: ['./billing-dashboard.component.scss']
})
export class BillingDashboardComponent implements OnInit {
  activeIndex: number = 0;
  rechargeValue: boolean = false;
  constructor(
    public sysService: SysService,
    private router: Router, // inject the Router service
    private route: ActivatedRoute
  ) { }
  ngOnInit() {
    this.rechargeValue = environment.publicNetwork
    this.route.queryParams.subscribe(params => {
      this.activeIndex = +params['activeIndex'] || 0;
    });
    this.router.navigateByUrl('/billing-dashboard?activeIndex=' + this.activeIndex);
    this.sysService.loadWalletBalance()
  }
  handleChange(event) {
    this.sysService.clear();
    this.sysService.clearUnload();
    this.sysService.appRefresh();
    this.activeIndex = event.index;
    this.router.navigateByUrl('/billing-dashboard?activeIndex=' + this.activeIndex);
  }
}
