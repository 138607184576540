<p-splitter [panelSizes]="viewerSize" (onResizeStart)="resizeManagerMouseDown($event)" (onResizeEnd)="resizeManagerMouseUp($event)">
    <ng-template pTemplate (dblclick)="onSplitterDoubleClick()">
      <div class="col1">
        <iframe [src]="viewerUrl() | safe" id="ech-viewer" class="pointer-normal"
          style="border-style: none; border-color: inherit; border: 0px; border-width: 0px; height: 90vh; width: 100%" allow="cross-origin-isolated"></iframe>
      </div>
    </ng-template>
        <ng-template pTemplate *ngIf="sysService.isRad() || sysService.isRadSub()">       
      <div class="col2 viverPart" >      
        <eclarity-report [splitter]="this" [rightEditor]="true" [siuid]="siuid" [reportRadiologist]="reportRadiologist" [studyViewer]="false"></eclarity-report>
      </div>
    </ng-template>
    
  </p-splitter>