import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormDefault, Model, StaticService, SysService, View, ViewFactory, noWhitespaceValidator } from '@app/_common';
import { environment } from '@environments/environment';
import { LazyLoadEvent } from 'primeng/api';
@Component({
  selector: 'app-root',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.scss'],
})
export class EquipmentComponent implements OnInit {
  ratecardListExport = ['name', 'taxCode', 'code', 'countryId.name']; //for export header fields
  private equipmentView: View = this.viewFactory.newView();
  saveButtonLabel: string = 'Save';
  modalityLookup: any[];
  eventData: any
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory) {
  }
  ngOnInit() {
    this.scrollHeight()
    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 400));
    this.detectZoomChange()


    this.equipmentView.of(this.equipmentListModel()).switchAction('/equipment', this.route, this);
  }
  get equipment() {
    return this.equipmentView.model;
  }
  heightTable: any;
  debounceTimeout: any;
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 300) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 300) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  detectZoomChange() {
    this.scrollHeight();
  }
  private equipmentListModel() {
    const formDefault = new FormDefault(['status'], [1]);
    return new Model(this.formBuilder.group({
      id: [],
      name: ['', [Validators.required, Validators.pattern(/^(?! ).*/)]],
      description: [''],
      modalityId: [, [Validators.required]],
      stationName: [, [Validators.required, Validators.pattern(/^(?! ).*/)]],
      stationAet: ['', [Validators.required, Validators.pattern(/^\S*$/)]],
      status: [, [Validators.required]]
    }), this.sysService, formDefault, '');
  }
  add() {
    this.equipmentView.toNew();
    this.saveButtonLabel = "Save";
  }
  edit(id: any) {
    this.equipmentView.toEditFetch(`/equipment/${id}`, id);
    this.saveButtonLabel = "Update";
  }
  list(id: any) {
    this.listView(false);
  }

  async listequipmentLazy(event?: LazyLoadEvent, search: boolean = false) {
    this.eventData = event
    this.equipmentView.toListLazyFetch(`/equipment`, search, event);
  }
  listView(search: boolean = false) {
    this.equipmentView.toListLazyView(`/equipment`, search);
  }
  view(id: any) {
    this.equipmentView.toViewFetch(`/equipment/${id}`, id);
  }

  deleteEquipments() {
    this.equipmentView.toDeleteSelection(`/equipment`, {
      onSuccess: () => {
        this.equipmentView.toListLazyFetch(`/equipment`, true, this.eventData);
      }
    });
  }





  deleteEquipment(id: any) {
    this.equipmentView.toDeleteList(`/equipment/${id}`, "id", id);
  }
  saveEquipment(add: boolean = false) {
    if (add) {
      this.equipmentView.toSaveNewFetch("/equipment", "id");
    } else {
      this.equipmentView.toSaveListFetch("/equipment", "id", this.equipment.isEdit() ? "Equipment Successfully updated." : "Equipment Successfully saved.");

    }

  }
  filterModality(event: any) {
    this.equipmentView.getData(`/modality/lookup?filter=${event.query}`).then(data => { this.modalityLookup = data; });
  }
}
