import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model } from '@app/_common';
import { environment } from '@environments/environment';
import { LazyLoadEvent } from 'primeng/api';
@Component({
  selector: 'app-eclarity-revenue',
  templateUrl: './eclarity-revenue.component.html',
  styleUrls: ['./eclarity-revenue.component.scss'],
  providers: [DatePipe]
})
export class EclarityRevenueComponent implements OnInit {
  @ViewChild("calendarRef") calendar: any;

  private eclarityRevenueView: View = this.viewFactory.newView();
  eclarityRevenueLookup: any[];
  userTypeLookup: any[];
  selectedRange: Date[];
  totalAmount: any;
  search: boolean = false;
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory) { }
  ngOnInit() {
    this.scrollHeight()
    this.detectZoomChange()

    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 400));

    this.eclarityRevenueView.of(this.eclarityRevenueModel());
    this.eclarityRevenueYear();
  }
  get eclarityRevenue() {
    return this.eclarityRevenueView.model;
  }
  private eclarityRevenueModel() {
    const formDefault = new FormDefault([''], []);
    return new Model(this.formBuilder.group({
      id: [],
      month: new FormControl(new Date().getMonth() + 1),
      mon: new FormControl(new Date().getMonth() + 1),
      year: new FormControl(new Date().getFullYear()),
      userType: [],
      totalAmount: [],
      roleType: [],
      date: [],
      userId: [],
      roleId: [],
      walletIds: []
    }), this.sysService, formDefault, '');
  }
  isScrollTable = window.innerWidth > environment.devices.tablet ? true : false;
  heightDisplay = window.innerHeight - 420 + "px";
  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.isScrollTable =
      window.innerWidth > environment.devices.tablet ? true : false;
    this.heightDisplay = window.innerHeight - 420 + "px";
  }
  add() {
    this.eclarityRevenueView.toNew();
  }
  edit(id: any) {
    this.eclarityRevenueView.toNew();
  }
  totalCharge: any;
  selectedValue() {
    let totalCharge = 0;
    for (const item of this.eclarityRevenue.selection) {
      totalCharge += item.amount;
    }
    this.totalCharge = totalCharge;
  }
  onDataSelectionChange(selectedMonth: any) {
    this.eclarityRevenue.set('date', '')
  }
  onDateSelect(selectedDate: any) {
    this.eclarityRevenue.set('mon', '')
    this.eclarityRevenue.set('year', '')
  }
  searchEclarityRevenue() {
    this.totalCharge = "0.00"
    this.search = true;
  }
  list(id: any) {
    this.listView(false);
  }
  async listeclarityRevenueListLazy(event?: LazyLoadEvent, search: boolean = false) {
    this.eclarityRevenue.selection = []
    this.totalAmount = null;
    const rangeDate = String(this.eclarityRevenue.get("date"));
    const startDate = new Date(rangeDate.split(",")[0]);
    const endDate = new Date(rangeDate.split(",")[1]);
    const formattedStartDate = `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() +
      1).toString().padStart(2, '0')}/${startDate.getFullYear()}`;
    const formattedEndDate = `${endDate.getDate().toString().padStart(2, '0')}/${(endDate.getMonth() +
      1).toString().padStart(2, '0')}/${endDate.getFullYear()}`;
    const date = `${formattedStartDate}-${formattedEndDate}`;
    this.eclarityRevenue.setParam("date", date)
    this.eclarityRevenue.setParam("userId", this.eclarityRevenue.get("userType")?.id)
    this.eclarityRevenue.setParam("roleId", this.eclarityRevenue.get("roleType"))
    this.eclarityRevenue.setParam("month", this.eclarityRevenue.get("mon"))
    this.eclarityRevenue.setParam("year", this.eclarityRevenue.get("year"))
    this.eclarityRevenueView.toListLazyFetch(`/wallet/eclarity-revenue?`, this.search, event).then(data => {
      this.totalAmount = data.totalAmount
    })
    this.search = false;
  }
  listView(search: boolean = false) {
    this.eclarityRevenueView.toListLazyView(`/wallet/eclarity-revenue?`, search);
  }
  filterCustomerType(event: any) {
    this.eclarityRevenueView.getData(`/users/lookup?filter=${event.query}`).then(data => {
      this.userTypeLookup =
        data;
    });
  }
  eclarityRevenueYear() {
    this.eclarityRevenueView.getData(`/reports/year`).then(data => { this.eclarityRevenueLookup = data; })
  }
  resetEclarityRevenue() {
    this.eclarityRevenue.reset();
    this.search = true;
    this.totalCharge = "0.00"
    let mon = new Date().getMonth() + 1
    let year = new Date().getFullYear()
    this.eclarityRevenue.set('mon', mon);
    this.eclarityRevenue.set('year', year);
  }
  downloadExcel(eclarityRevenue: any) {
    // this.searchEclarityRevenue()
    const rangeDate = String(this.eclarityRevenue.get("date"));
    const startDate = new Date(rangeDate.split(",")[0]);
    const endDate = new Date(rangeDate.split(",")[1]);
    const formattedStartDate = `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() +
      1).toString().padStart(2, '0')}/${startDate.getFullYear()}`;
    const formattedEndDate = `${endDate.getDate().toString().padStart(2, '0')}/${(endDate.getMonth() +
      1).toString().padStart(2, '0')}/${endDate.getFullYear()}`;
    const date = `${formattedStartDate}-${formattedEndDate}`;
    this.eclarityRevenue.setParam("date", date)
    this.eclarityRevenue.setParam("userId", this.eclarityRevenue.get("userType")?.id)
    this.eclarityRevenue.setParam("roleId", this.eclarityRevenue.get("roleType"))
    this.eclarityRevenue.setParam("month", this.eclarityRevenue.get("mon"))
    this.eclarityRevenue.setParam("year", this.eclarityRevenue.get("year"))
    this.eclarityRevenue.setParam("walletIds", this.eclarityRevenue.get("id"))
    if (this.eclarityRevenue.selection.length > 0) {
      const ids = this.eclarityRevenue.selection.map(item => item.id);
      this.eclarityRevenue.setParam("walletIds", ids)
    } else {
      const ids = this.eclarityRevenue.selection = [];
      this.eclarityRevenue.setParam("walletIds", ids)
    }
    const pdfFilename = !this.eclarityRevenueView.isEmptyList('date') ? `ecscribe_revenue-${date}.xls` :
      'ecscribe_revenue.xls';
    this.eclarityRevenueView.download(`/wallet/excel-eclarity-revenue-download?`, pdfFilename);
  }
  changesCalendarClear(dt) {
    this.selectedRange = [];
    this.calendarSelected = false;
    this.eclarityRevenue.reset();
    this.sysService.clear();
    this.eclarityRevenue.set('isReportDate', false)
    this.search = false
    let month = new Date().getMonth() + 1
    let year = new Date().getFullYear()
    this.eclarityRevenue.set('mon', month);
    this.eclarityRevenue.set('year', year);
    this.eclarityRevenue.selection = [];
    this.totalCharge = ''
    this.calendar.hideOverlay();
  }
  dateRange;
  calendarSelected = false;
  calenedarChangeDate(event, dt) {
    this.dateRange = "";
    if (this.selectedRange) {

      const startDate = this.datePipe.transform(
        this.selectedRange[0],
        "dd/MM/yyyy"
      );
      const endDate = this.datePipe.transform(
        this.selectedRange[1],
        "dd/MM/yyyy"
      );
      this.dateRange = `${startDate}-${endDate}`;
      this.listeclarityRevenueListLazy(dt);
    }
    this.calendar.hideOverlay();
  }
  heightTable: any;
  debounceTimeout: any;
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 390) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 390) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  detectZoomChange() {
    this.scrollHeight();
  }
}