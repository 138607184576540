<div class="loading-container" *ngIf="!paymentStatus">
    <div class="loading-blur"></div>
    <div class="loading-spinner">
        <img src="../../assets/images/logo.png" alt="Logo" width="80" class="img1">
        <div class="center">
            <ul>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>

        </div>

    </div>
</div>

<p-dialog header="Payment Status" [(visible)]="paymentStatus" [style]="{width: '480px'}" (onHide)="redirectPayment()"
    [modal]="true" [dismissableMask]="true">

    <div *ngIf="paymentStatusValue" class="grid justify-content-center payment-pos">
        <p-image src="../../assets/images/success.png" alt="Image" width="100"></p-image>
    </div>
    <div *ngIf="!paymentStatusValue" class="grid justify-content-center payment-pos">
        <p-image src="../../assets/images/f.png" alt="Image" width="140"></p-image>
    </div>

    <h1 class="payment">{{ message }}</h1>

    <div class="p-col-fixed text-center">
        <button pButton type="button" [label]="paymentStatusValue ? 'Done' : 'close'" *ngIf="paymentStatusValue"
            (click)="redirectPayment()"></button>
        <button pButton type="button" label="Try again" *ngIf="!paymentStatusValue" (click)="backToRecharge()"></button>
    </div>

</p-dialog>