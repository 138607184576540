<div class="card grayCard ">
    <form [formGroup]="Payable.form" class="fullform">
        <div class="grid">

            <div class=" field col-12 md:col-3 lg:col-2 ">
                <div class="pt-3">
                    <span class="p-float-label ">
                        <p-dropdown formControlName="month" inputId="month" placeholder="Select"
                            (onChange)="onDataSelectionChange($event)" [options]="staticService.month">
                        </p-dropdown>
                        <label for="float-input">Month </label>
                    </span>
                    <f-error [err]="Payable.required('month')" msg="Month is required"></f-error>
                </div>

            </div>
            <div class="field col-12 md:col-3 lg:col-2">
                <div class="pt-3">
                    <span class="p-float-label">
                        <p-dropdown id="year" formControlName="year" inputId="year" [options]="PayableYearList"
                            (onChange)="onDataSelectionChange($event)" placeholder="Select" optionLabel="year"
                            optionValue="year">
                        </p-dropdown>
                        <label for="float-input">Year </label>
                    </span>
                    <f-error [err]="Payable.required('year')" msg="Year is required"></f-error>
                </div>

            </div>
            <div class="field col-12 md:col-3 lg:col-2">
                <div class="pt-3">
                    <span>
                        <span class="p-float-label">
                            <p-calendar #calendarRef [showIcon]="false" dateFormat="dd/mm/yy" formControlName="date"
                                placeholder="DD/MM/YYYY-DD/MM/YYYY" (onSelect)="onDateSelect($event)"
                                [numberOfMonths]="1" class="widthClass" selectionMode="range" [readonlyInput]="true"
                                inputId="range" [(ngModel)]="selectedRange" (onSelect)="calendarSelected = true">
                                <ng-template pTemplate="footer">
                                    <div class="grid justify-content-center">
                                        <p-button label="Clear" *ngIf="calendarSelected"
                                            (click)="changesCalendarClear(dt);dt.reset()"
                                            styleClass="p-button-raised p-button-text"></p-button>&nbsp;
                                        <button pButton pRipple type="button" *ngIf="calendarSelected"
                                            (click)="calenedarChangeDate($event,dt);dt.reset()" label=" Search"
                                            class="p-button-raised p-button-text"></button>
                                    </div>
                                </ng-template>
                            </p-calendar>
                            <label for="float-input">Date Range </label>
                        </span>
                    </span>
                </div>

            </div>

            <div class=" field col-12 md:col-3 lg:col-2">
                <div class="pt-3">
                    <span class="p-float-label">
                        <p-dropdown formControlName="isReportDate" inputId="month" placeholder="Select"
                            [options]="staticService.reportType">
                        </p-dropdown>
                        <label for="float-input">Date Types </label>
                    </span>
                </div>
            </div>
            <div class=" field col-12 md:col-3 lg:col-2">
                <div class="pt-3">
                    <span class="p-float-label">
                        <p-autoComplete forceSelection="true" formControlName="radId" field="name"
                            [suggestions]="radLookup" (completeMethod)="filterRad($event)" Label="id" [dropdown]="true"
                            [autoHighlight]="true" showEmptyMessage="true" delay="400" maxlength="50">
                        </p-autoComplete>
                        <label for="float-input">Payable To </label>
                    </span>
                </div>
            </div>
            <div class=" field col-12 md:col-3 lg:col-2" *ngIf="this.sysService.isAdmin()">
                <div class="pt-3">
                    <span class="p-float-label">
                        <p-autoComplete forceSelection="true" formControlName="radiologistName" field="name"
                            [suggestions]="radLookup" (completeMethod)="filterRad($event)" Label="id" [dropdown]="true"
                            [autoHighlight]="true" showEmptyMessage="true" delay="400" maxlength="50">
                        </p-autoComplete>
                        <label for="float-input">Radiologist</label>
                    </span>
                </div>
            </div>
            <div class=" field col-12 md:col-3 lg:col-2" *ngIf="this.sysService.isAdmin()">
                <div class="pt-3">
                    <span class="p-float-label">
                        <p-autoComplete forceSelection="true" formControlName="centreName" field="name"
                            [suggestions]="cenLookup" (completeMethod)="filterCen($event)" ariaLabel="bodyPart"
                            [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400" maxlength="50">
                        </p-autoComplete>
                        <label for="float-input">Centre</label>
                    </span>
                </div>
            </div>
            <div class=" field col-12 md:col-3 lg:col-2">
                <div class="pt-3">
                    <span class="p-float-label">
                        <p-autoComplete forceSelection="true" formControlName="modality" field="name" s
                            [suggestions]="modLookup" (completeMethod)="filterMod($event)" ariaLabel="bodyPart"
                            [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400" maxlength="50">
                            13
                        </p-autoComplete>
                        <label for="float-input">Modality</label>
                    </span>
                </div>
            </div>
            <div class=" field col-12 md:col-3 lg:col-2">
                <div class="pt-3">
                    <span class="p-float-label">
                        <p-dropdown formControlName="paymentStatus" inputId="month" placeholder="Select"
                            [options]="staticService.paymentStatus">
                        </p-dropdown>
                        <label for="float-input">Payment Status </label>
                    </span>
                    <f-error [err]="Payable.required('paymentStatus')" msg="Month is required"></f-error>
                </div>
            </div>
            <div class="field col-12 md:col-6 lg:col-2">
                <div class="pt-3">
                    <span class="p-float-label">

                        <input id="username" placeholder="hh:mm" name="nightChargeInput" type="text" maxlength="5"
                            formControlName="FromTime" aria-describedby="username1-help" pInputText pAutoFocus
                            (input)="formatTimeInput($event)">
                        <f-error [err]="Payable.pattern('FromTime')"
                            msg="From Time  must be a valid format of HH:MM.  "></f-error>
                        <label> Night Study From Time </label>
                    </span>
                </div>
            </div>

            <div class="field col-12 md:col-6 lg:col-2">
                <div class="pt-3">
                    <span class="p-float-label">

                        <input id="username" placeholder="hh:mm" name="nightChargeInput" type="text" maxlength="5"
                            formControlName="toTime" aria-describedby="username1-help" pInputText pAutoFocus
                            (input)="formatTimeInput($event)">
                        <f-error [err]="Payable.pattern('toTime')" msg="To time  must be a valid format of HH:MM.  ">
                        </f-error>
                        <label> Night Study To Time </label>
                    </span>
                </div>

            </div>
            <!-- <div class="field col-6 md:col-3 lg:col-2">
                <div class="pt-3">
                    <span class="p-float-label">
                        <p-calendar class="widthClass" timeOnly="true" inputId="range" formControlName="fromTime"
                            hourFormat="12"></p-calendar>

                        <label for="float-input"> Night Study - From Time</label>

                    </span>
                </div>
            </div>
            <div class="field col-6 md:col-3 lg:col-2">
                <div class="pt-3">
                    <span class="p-float-label">
                        <p-calendar class="widthClass" timeOnly="true" inputId="range" readOnly="true"
                            formControlName="toTime" hourFormat="12"></p-calendar>
                        <label for="float-input"> Night Study - To Time</label>

                    </span>
                </div>
            </div> -->
            <div class=" sm:col-12 md:col-4 lg:col-2 mt-3">

                <button pButton pRipple type="button" (click)="searchPayable();dt.reset()" label=" Search"
                    icon=" pi pi-search" class="p-button-raised p-button-info m-1" [disabled]="sysService.loading">
                    <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                </button>
                <button pButton pRipple type="button" (click)="resetPayable();dt.reset()" label=" Reset"
                    icon=" pi pi-undo" class="p-button-raised p-button-text m-1" [disabled]="sysService.loading">
                    <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                </button>
            </div>
        </div>

    </form>
</div>
<!-- // *********************************************This  payables for radiologist and more details check billing component ***************************************************
// ********************************************************************************************************************************************************************* -->
<div class="table-responsive">
    <p-table #dt [value]="Payable.list" [totalRecords]="Payable.total" [scrollable]="true" scrollHeight="65vh"
        class="study-list-class" [scrollHeight]="heightTable" dataKey="id" [lazy]="true"
        [(selection)]="Payable.selection" (onLazyLoad)="listratecardListLazy($event)" [loading]="sysService.loading"
        filterDelay="400" currentPageReportTemplate="{first} to {last} of{totalRecords}" [rowsPerPageOptions]="[25, 50]"
        [rowHover]="true" styleClass="p-datatable-sm" [rows]="25" [paginator]="true" [showCurrentPageReport]="true"
        alwaysShow="false" paginatorPosition="bottom" styleClass="p-datatable-sm p-datatable-gridlines"
        [globalFilterFields]="['radiologistName','centreName','patName','reportDate','modality','studyDesc','studyId','charge']">
        <ng-template pTemplate="caption">
            <div class="flex justify-content-between flex-wrap card-container purple-container pb-3">
                <div class="flex rec-btn-1 align-items-center justify-content-center ">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" maxlength="150" (input)="dt.filterGlobal($event.target.value,
                                          'contains')" placeholder="Search..." pAutoFocus [autofocus]="true" />
                    </span>
                    <div class="ml-2 rec-btn">
                        <button pButton pRipple type="button" label="Export PDF" icon=" pi pi-download"
                            (click)="export(Payable)" [disabled]="sysService.loading "
                            class="p-button-raised p-button-warning m-1">
                            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                        </button>
                        <button pButton pRipple type="button" label="Export Excel" icon=" pi pi-download"
                            (click)="downloadExcel(Payable)" [disabled]="sysService.loading "
                            class="p-button-raised p-button-secondary m-1">
                            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                        </button>
                        <button pButton pRipple type="button"
                            *ngIf="(sysService.isCenter() || sysService.isRad()) && Payable.selection.length > 0"
                            label="Mark as Duplicate" (click)="markAsDuplicate(Payable)"
                            [disabled]="sysService.loading " class="p-button-raised p-button-help m-1">
                            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                        </button>
                        <button pButton pRipple type="button"
                            *ngIf="(sysService.isCenter() || sysService.isRad()) && Payable.selection.length > 0"
                            label="Un-Mark as Duplicate" (click)="unMarkAsDuplicate(Payable)"
                            [disabled]="sysService.loading " class="p-button-raised p-button-help m-1">
                            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                        </button>
                    </div>
                </div>

                <div class="ml-auto pt-2"> <label class="text-2xl pt-2 "
                        *ngIf="Payable.selection.length > 0"><b>Selected Amount :</b> {{totalCharge || '0.00'}} </label>
                    <label class="text-2xl pl-3 pt-2"><b>Total Amount :</b> {{totalamount || '0.00'}} </label>
                </div>

            </div>
        </ng-template>
        <ng-template pTemplate="paginatorleft">
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox (click)="selectedValue()"></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="radiologistName">Payable To
                    <p-sortIcon field="radiologistName"></p-sortIcon>
                </th>
                <th pSortableColumn="studyId">Study Id
                    <p-sortIcon field="studyId"></p-sortIcon>
                </th>
                <th pSortableColumn="patName">Patient Name
                    <p-sortIcon field="patName"></p-sortIcon>
                </th>
                <th pSortableColumn="studyCreatedDate">
                    <span *ngIf="(sysService.isRad() || sysService.isRadSub())">Assigned Time</span>
                    <span *ngIf="!sysService.isRad() && !sysService.isRadSub()">Uploaded Time</span>
                    <p-sortIcon field="studyCreatedDate"></p-sortIcon>
                </th>
                <th pSortableColumn="reportDate">Report Date-Time<p-sortIcon field="reportDate"></p-sortIcon>
                </th>
                <th pSortableColumn="modality">Modality<p-sortIcon field="modality"></p-sortIcon>
                </th>
                <th pSortableColumn="tat">Tat<p-sortIcon field="tat"></p-sortIcon>
                </th>
                <th pSortableColumn="studyDesc">Study Desc<p-sortIcon field="studyDesc"></p-sortIcon>
                </th>
                <th pSortableColumn="studyDescModified">Study Modified<p-sortIcon field="studyDescModified">
                    </p-sortIcon>
                </th>
                <th pSortableColumn="paymentNote" *ngIf="!this.sysService.isCenter()">Note
                    <p-sortIcon field="paymentNote"></p-sortIcon>
                </th>
                <th pSortableColumn="paymentStatus">Payment Status<p-sortIcon field="paymentStatus"></p-sortIcon>
                </th>
                <th pSortableColumn="charge" style="text-align: end;">Charge<p-sortIcon field="charge"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-Payable>
            <tr>
                <!-- Checkbox column -->
                <td [ngClass]="{
    'redrow': Payable?.isDuplicate && !Payable?.isMarkedDuplicate && !Payable?.isNightStudy,
        'marked-duplicate-row': Payable?.isMarkedDuplicate && !Payable?.isDuplicate && !Payable?.isNightStudy,
    'marked-duplicate-gery': !Payable.isMarkedDuplicate && !Payable.isDuplicate && Payable.isNightStudy,
    'night-row': (Payable.isDuplicate && Payable.isMarkedDuplicate && !Payable.isNightStudy) || (Payable.isDuplicate && Payable.isMarkedDuplicate && Payable.isNightStudy) || (!Payable?.isDuplicate && Payable?.isMarkedDuplicate && Payable?.isNightStudy) || (Payable?.isDuplicate && !Payable?.isMarkedDuplicate && Payable?.isNightStudy)
}">
                    <p-tableCheckbox #chk [value]="Payable" (click)="selectedValue()"></p-tableCheckbox>
                </td>

                <!-- Other columns with conditional 'redrow' class -->
                <td [ngClass]="{'redrow': Payable?.isDuplicate && !Payable?.isMarkedDuplicate && !Payable?.isNightStudy,'marked-duplicate-row': Payable?.isMarkedDuplicate && !Payable?.isDuplicate && !Payable?.isNightStudy,
    'marked-duplicate-gery': !Payable.isMarkedDuplicate && !Payable.isDuplicate && Payable.isNightStudy,'night-row': (Payable.isDuplicate && Payable.isMarkedDuplicate && !Payable.isNightStudy) || (Payable.isDuplicate && Payable.isMarkedDuplicate && Payable.isNightStudy) || (!Payable?.isDuplicate && Payable?.isMarkedDuplicate && Payable?.isNightStudy) || (Payable?.isDuplicate && !Payable?.isMarkedDuplicate && Payable?.isNightStudy)
}">{{
                    Payable.radiologistName }}</td>
                <td [ngClass]="{
    'redrow': Payable?.isDuplicate && !Payable?.isMarkedDuplicate && !Payable?.isNightStudy,
        'marked-duplicate-row': Payable?.isMarkedDuplicate && !Payable?.isDuplicate && !Payable?.isNightStudy,
    'marked-duplicate-gery': !Payable.isMarkedDuplicate && !Payable.isDuplicate && Payable.isNightStudy,
    'night-row': (Payable.isDuplicate && Payable.isMarkedDuplicate && !Payable.isNightStudy) || (Payable.isDuplicate && Payable.isMarkedDuplicate && Payable.isNightStudy) || (!Payable?.isDuplicate && Payable?.isMarkedDuplicate && Payable?.isNightStudy) || (Payable?.isDuplicate && !Payable?.isMarkedDuplicate && Payable?.isNightStudy)
}">{{
                    Payable.studyId }}</td>
                <td [ngClass]="{'redrow': Payable?.isDuplicate && !Payable?.isMarkedDuplicate && !Payable?.isNightStudy,'marked-duplicate-row': Payable?.isMarkedDuplicate && !Payable?.isDuplicate && !Payable?.isNightStudy,
                'marked-duplicate-gery': !Payable.isMarkedDuplicate && !Payable.isDuplicate && Payable.isNightStudy,'night-row': (Payable.isDuplicate && Payable.isMarkedDuplicate && !Payable.isNightStudy) || (Payable.isDuplicate && Payable.isMarkedDuplicate && Payable.isNightStudy) || (!Payable?.isDuplicate && Payable?.isMarkedDuplicate && Payable?.isNightStudy) || (Payable?.isDuplicate && !Payable?.isMarkedDuplicate && Payable?.isNightStudy)
            , 'long-text': Payable?.patName?.length > 25}" pTooltip="{{ Payable?.patName }}">
                    {{ Payable?.patName?.slice(0, 25) + (Payable?.patName?.length > 25 ? '...' : '') }}
                </td>

                <td [ngClass]="{
    'redrow': Payable?.isDuplicate && !Payable?.isMarkedDuplicate && !Payable?.isNightStudy,
        'marked-duplicate-row': Payable?.isMarkedDuplicate && !Payable?.isDuplicate && !Payable?.isNightStudy,
    'marked-duplicate-gery': !Payable.isMarkedDuplicate && !Payable.isDuplicate && Payable.isNightStudy,
    'night-row': (Payable.isDuplicate && Payable.isMarkedDuplicate && !Payable.isNightStudy) || (Payable.isDuplicate && Payable.isMarkedDuplicate && Payable.isNightStudy) || (!Payable?.isDuplicate && Payable?.isMarkedDuplicate && Payable?.isNightStudy) || (Payable?.isDuplicate && !Payable?.isMarkedDuplicate && Payable?.isNightStudy)
}">
                    <span *ngIf="(sysService.isRad() || sysService.isRadSub())"> {{ Payable.assignedDateFormatted
                        }}</span>
                    <span *ngIf="!sysService.isRad() && !sysService.isRadSub()"> {{ Payable.studyCreatedDate }}</span>

                </td>
                <td [ngClass]="{
    'redrow': Payable?.isDuplicate && !Payable?.isMarkedDuplicate && !Payable?.isNightStudy,
        'marked-duplicate-row': Payable?.isMarkedDuplicate && !Payable?.isDuplicate && !Payable?.isNightStudy,
    'marked-duplicate-gery': !Payable.isMarkedDuplicate && !Payable.isDuplicate && Payable.isNightStudy,
    'night-row': (Payable.isDuplicate && Payable.isMarkedDuplicate && !Payable.isNightStudy) || (Payable.isDuplicate && Payable.isMarkedDuplicate && Payable.isNightStudy) || (!Payable?.isDuplicate && Payable?.isMarkedDuplicate && Payable?.isNightStudy) || (Payable?.isDuplicate && !Payable?.isMarkedDuplicate && Payable?.isNightStudy)
}"> {{ Payable.reportDate | date:'dd-MM-yyyy hh:mm a' }}</td>
                <td [ngClass]="{
    'redrow': Payable?.isDuplicate && !Payable?.isMarkedDuplicate && !Payable?.isNightStudy,
        'marked-duplicate-row': Payable?.isMarkedDuplicate && !Payable?.isDuplicate && !Payable?.isNightStudy,
    'marked-duplicate-gery': !Payable.isMarkedDuplicate && !Payable.isDuplicate && Payable.isNightStudy,
    'night-row': (Payable.isDuplicate && Payable.isMarkedDuplicate && !Payable.isNightStudy) || (Payable.isDuplicate && Payable.isMarkedDuplicate && Payable.isNightStudy) || (!Payable?.isDuplicate && Payable?.isMarkedDuplicate && Payable?.isNightStudy) || (Payable?.isDuplicate && !Payable?.isMarkedDuplicate && Payable?.isNightStudy)
}">{{
                    Payable.modality }}</td>
                <td [ngClass]="{
                        'redrow': Payable?.isDuplicate && !Payable?.isMarkedDuplicate && !Payable?.isNightStudy,
                            'marked-duplicate-row': Payable?.isMarkedDuplicate && !Payable?.isDuplicate && !Payable?.isNightStudy,
                        'marked-duplicate-gery': !Payable.isMarkedDuplicate && !Payable.isDuplicate && Payable.isNightStudy,
                        'night-row': (Payable.isDuplicate && Payable.isMarkedDuplicate && !Payable.isNightStudy) || (Payable.isDuplicate && Payable.isMarkedDuplicate && Payable.isNightStudy) || (!Payable?.isDuplicate && Payable?.isMarkedDuplicate && Payable?.isNightStudy) || (Payable?.isDuplicate && !Payable?.isMarkedDuplicate && Payable?.isNightStudy)
                    }">{{
                    Payable?.tat }}</td>
                <td [ngClass]="{
    'redrow': Payable?.isDuplicate && !Payable?.isMarkedDuplicate && !Payable?.isNightStudy,
        'marked-duplicate-row': Payable?.isMarkedDuplicate && !Payable?.isDuplicate && !Payable?.isNightStudy,
    'marked-duplicate-gery': !Payable.isMarkedDuplicate && !Payable.isDuplicate && Payable.isNightStudy,
    'night-row': (Payable.isDuplicate && Payable.isMarkedDuplicate && !Payable.isNightStudy) || (Payable.isDuplicate && Payable.isMarkedDuplicate && Payable.isNightStudy) || (!Payable?.isDuplicate && Payable?.isMarkedDuplicate && Payable?.isNightStudy) || (Payable?.isDuplicate && !Payable?.isMarkedDuplicate && Payable?.isNightStudy)
}">{{
                    Payable.studyDesc }}</td>
                <td [ngClass]="{
    'redrow': Payable?.isDuplicate && !Payable?.isMarkedDuplicate && !Payable?.isNightStudy,
        'marked-duplicate-row': Payable?.isMarkedDuplicate && !Payable?.isDuplicate && !Payable?.isNightStudy,
    'marked-duplicate-gery': !Payable.isMarkedDuplicate && !Payable.isDuplicate && Payable.isNightStudy,
    'night-row': (Payable.isDuplicate && Payable.isMarkedDuplicate && !Payable.isNightStudy) || (Payable.isDuplicate && Payable.isMarkedDuplicate && Payable.isNightStudy) || (!Payable?.isDuplicate && Payable?.isMarkedDuplicate && Payable?.isNightStudy) || (Payable?.isDuplicate && !Payable?.isMarkedDuplicate && Payable?.isNightStudy)
}">{{
                    Payable.studyDescModified }}</td>
                <td [ngClass]="{
    'redrow': Payable?.isDuplicate && !Payable?.isMarkedDuplicate && !Payable?.isNightStudy,
        'marked-duplicate-row': Payable?.isMarkedDuplicate && !Payable?.isDuplicate && !Payable?.isNightStudy,
    'marked-duplicate-gery': !Payable.isMarkedDuplicate && !Payable.isDuplicate && Payable.isNightStudy,
    'night-row': (Payable.isDuplicate && Payable.isMarkedDuplicate && !Payable.isNightStudy) || (Payable.isDuplicate && Payable.isMarkedDuplicate && Payable.isNightStudy) || (!Payable?.isDuplicate && Payable?.isMarkedDuplicate && Payable?.isNightStudy) || (Payable?.isDuplicate && !Payable?.isMarkedDuplicate && Payable?.isNightStudy)
}" *ngIf="!sysService.isCenter()">{{ Payable.paymentNote }}</td>
                <td [ngClass]="{
    'redrow': Payable?.isDuplicate && !Payable?.isMarkedDuplicate && !Payable?.isNightStudy,
        'marked-duplicate-row': Payable?.isMarkedDuplicate && !Payable?.isDuplicate && !Payable?.isNightStudy,
    'marked-duplicate-gery': !Payable.isMarkedDuplicate && !Payable.isDuplicate && Payable.isNightStudy,
    'night-row': (Payable.isDuplicate && Payable.isMarkedDuplicate && !Payable.isNightStudy) || (Payable.isDuplicate && Payable.isMarkedDuplicate && Payable.isNightStudy) || (!Payable?.isDuplicate && Payable?.isMarkedDuplicate && Payable?.isNightStudy) || (Payable?.isDuplicate && !Payable?.isMarkedDuplicate && Payable?.isNightStudy)
}" *ngIf="Payable.paymentStatus == 1" style="color: rgb(0, 97, 0);">Paid</td>
                <td [ngClass]="{
    'redrow': Payable?.isDuplicate && !Payable?.isMarkedDuplicate && !Payable?.isNightStudy,
        'marked-duplicate-row': Payable?.isMarkedDuplicate && !Payable?.isDuplicate && !Payable?.isNightStudy,
    'marked-duplicate-gery': !Payable.isMarkedDuplicate && !Payable.isDuplicate && Payable.isNightStudy,
    'night-row': (Payable.isDuplicate && Payable.isMarkedDuplicate && !Payable.isNightStudy) || (Payable.isDuplicate && Payable.isMarkedDuplicate && Payable.isNightStudy) || (!Payable?.isDuplicate && Payable?.isMarkedDuplicate && Payable?.isNightStudy) || (Payable?.isDuplicate && !Payable?.isMarkedDuplicate && Payable?.isNightStudy)
}" *ngIf="Payable.paymentStatus == 0" style="color: red;">Not Paid</td>
                <td [ngClass]="{
    'redrow': Payable?.isDuplicate && !Payable?.isMarkedDuplicate && !Payable?.isNightStudy,
        'marked-duplicate-row': Payable?.isMarkedDuplicate && !Payable?.isDuplicate && !Payable?.isNightStudy,
    'marked-duplicate-gery': !Payable.isMarkedDuplicate && !Payable.isDuplicate && Payable.isNightStudy,
    'night-row': (Payable.isDuplicate && Payable.isMarkedDuplicate && !Payable.isNightStudy) || (Payable.isDuplicate && Payable.isMarkedDuplicate && Payable.isNightStudy) || (!Payable?.isDuplicate && Payable?.isMarkedDuplicate && Payable?.isNightStudy) || (Payable?.isDuplicate && !Payable?.isMarkedDuplicate && Payable?.isNightStudy)
}" style="text-align: end;">{{ Payable.charge }}</td>
            </tr>

        </ng-template>

        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td class="norecordstudy" [attr.colspan]="13">No records found</td>
            </tr>
        </ng-template>
    </p-table>
</div>