import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { FormDefault, Model, StaticService, SysService, View, ViewFactory, noWhitespaceValidator } from "@app/_common";
import { AppConstants } from "@app/_config";
import { ConfirmationService, } from "primeng/api";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
@Component({
  selector: "app-technician-comments",
  templateUrl: "./technician-comments.component.html",
  styleUrls: ["./technician-comments.component.css"],
})
export class TechnicianCommentsComponent implements OnInit {
  @ViewChild('commentsSectionRef') commentsSectionRef!: ElementRef;
  siuid: any;
  reportView = '0';
  reportDetails: any;
  editUsers = true; // If editUser is true, only comments can be edited
  private technicianView: View = this.viewFactory.newView();
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: UntypedFormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory,
    public router: Router,
    public config: DynamicDialogConfig,
    private confirmationService: ConfirmationService
  ) { }
  ngOnInit() {
    this.scrollHeight()
    this.detectZoomChange()

    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 200));

    // Initialize variables on component load
    this.siuid = this.config.data.studyIuid;
    this.reportView = this.config.data.view || "0";
    this.technicianView.of(this.technicianModel(), false).toListView();
    this.edit(this.technician);
    this.commentDetails();
  }
  // Get technician model from view and form builder
  private technicianModel() {
    const formDefault = new FormDefault(["studyIuid"], [this.siuid]);
    return new Model(
      this.formBuilder.group({
        id: [],
        // document: [""],
        documents: this.formBuilder.array([]),
        studyIuid: [""],
        comments: ["", [Validators.required, noWhitespaceValidator]],
        docFile: [""],
        // docFile1: [""],
        userId: [],
        isEditable: [],

      }),
      this.sysService,
      formDefault,
      ""
    );
  }
  get technician() {
    return this.technicianView.model;
  }
  // Function to add a new comment
  add() {
    this.technicianView.toNew();
    this.editUsers = true;
    this.scrollToComments();
  }
  scrollToComments() {
    if (this.commentsSectionRef) {
      this.commentsSectionRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  // Function to edit an existing comment
  edit(technician: any) {
    if (this.sysService.getUser().userSettings?.isTechEditableByRad) {
      // Assign the value of technician.isEditable to a local variable
      const isEditable = technician.isEditable;
      // Set the value of editUsers property
      this.editUsers = isEditable;
      // Switch view to edit mode
      this.scrollToComments();
      this.technicianView.toEdit(technician, technician.id);
      // Rest of the code...

    } else {
      this.editUsers = false;
    }

  }
  editNon(technician: any) {
    this.editUsers = technician.isEditable;
  }
  // Function to delete a comment
  deletetechnician(id: any) {
    this.technicianView.toDeleteList(`/techniciancommments/${id}`, "id", id);
  }
  // Function to save a comment
  savetechnician() {
    this.technician.setMultipart();
    this.technician.set("studyIuid", this.siuid);
    if (this.technicianView.isValid()) {
      this.technicianView
        .toSaveList("/techniciancommments", "id", this.technician.isEdit() ? "Technician Comments Successfully updated." : "Technician Comments Successfully saved.").then((data) => {
          // this.commentDetails();
          this.technician.reset();
          this.technician.set('docFile', '');
          this.sysService.clear();
          this.editUsers = false;
        });
    }
  }
  // selectFile(files: FileList, name) {
  //   if(files > 200mb){
  //     this.sysService.error("hhhh")
  //   }
  //   this.technician.setFile(files, name);
  // }
  selectFile(files: FileList, name) {
    const maxFileSize = 10 * 1024 * 1024; // 200 MB in bytes
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (file && file.size > maxFileSize) {
        window.alert("File size should  not exceed 10MB.")
        this.technician.set('docFile', '')
        return;
      }
    }
    this.technician.setFile(files, name);
  }
  back(search: boolean = false) {
    this.sysService.navigate(["/studies"]);
  }
  removeFile(obj, technician: any, fileName) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete [' + this.getName(fileName) + ']?',
      header: AppConstants.confirm,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.technicianView.toRemove(`/techniciancommments/${technician}/${fileName}`, "", ``).then((data) => {
          obj.documents = obj.documents.filter(x => x !== fileName)
        }, error => {
          this.sysService.clear()
        },);
      }
    });
  }
  removeFile1(obj, technician: any, fileName) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete [' + this.getName(fileName) + ']?',
      header: AppConstants.confirm,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.technicianView.toRemove(`/techniciancommments/${technician}/${fileName}`, "", ``).then((data) => {
          data.documents = data.documents.filter(x => x !== fileName)
        }, error => {
          this.sysService.clear()
        },);
      }
    });
  }
  downloadFile(doc: any) {
    const extension = doc.split(".").pop();
    const name = this.getName(doc);
    if (
      extension === "pdf" ||
      extension === "jpg" ||
      extension === "jpeg" ||
      extension === "png"
    ) {
      this.technicianView.downloadOpen(
        `/techniciancommments/download/${doc}`,
        name
      );
    } else {
      this.technicianView.download(
        `/techniciancommments/download/${doc}`,
        name
      );
    }
  }
  getName(doc: any) {
    if (doc) {
      return doc.substring(doc.indexOf("_") + 1, doc.length);
    }
    return "";
  }
  commentDetails() {
    this.technicianView
      .getDataList(`/reports/info/${this.siuid}`)
      ?.then((data) => {
        this.reportDetails = data?.report;
      });
  }
  reset() {
    this.technicianView.toNew();
  }
  // removeFile(name) {
  //   this.technician.set(name, "");
  //   this.sysService.info("Save to remove the document");
  // }
  heightTable: any;
  debounceTimeout: any;
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 500) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 500) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }
  detectZoomChange() {
    this.scrollHeight();
  }
}
