<div class="grid">
    <div class="col-12 right-button">
        <p> Need help...? &nbsp;<a href="https://www.ecscribe.com" target="_blank" class="link">Click here
            </a>
        </p>
    </div>
</div>
<form [formGroup]="adminDetails.form">
    <div class="card">
        <div class="grid">
            <div class="field col-12 md:col-6 lg:col-6">
                <label>Name <span class="validation-star">*</span></label>
                <input pInputText maxlength="100" formControlName="name" type="text" autocomplete="off" pAutoFocus
                    [autofocus]="true" autofocus />
                <f-error [err]="adminDetails.required('name')" msg="Name is required"></f-error>
                <f-error [err]="adminDetails.pattern('name')"
                    msg="Special characters are not allowed in this field"></f-error>

            </div>
            <div class="field col-12 md:col-6 lg:col-6">
                <label for="email">Email Address <span class="validation-star">*</span></label>
                <input id="email" type="text" [readOnly]="true" maxlength="100" pInputText formControlName="email"
                    required />
                <f-error [err]="adminDetails.required('email')" msg="Email Address is required "></f-error>
                <f-error [err]="adminDetails.pattern('email')"
                    msg="This e-mail address is not valid. Valid records include to user@somehost.somedomain. And emails only accept lowercase letters.">
                </f-error>
            </div>
            <div class="field col-12 md:col-6  lg:col-6">
                <label>Phone Number <span class="validation-star">*</span></label>
                <input pInputText maxlength="18" formControlName="phoneNo" type="text">
                <f-error [err]="adminDetails.required('phoneNo')" msg="Phone Number is required"></f-error>
                <f-error [err]="adminDetails.pattern('phoneNo')" msg="Only numerical values are accepted "></f-error>
                <f-error [err]="adminDetails.minlength('phoneNo')" msg="Enter a minimum of 7 digits"></f-error>

            </div>
        </div>
        <div class="formgrid text-center bc">

            <button pButton pRipple type="button" label=" Update" icon="pi pi-check"
                class="p-button-raised p-button-success" (click)="saveAdmin()" [disabled]="sysService.loading">

                <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
            </button>
        </div>
    </div>
</form>