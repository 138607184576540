<div class="card grayCard">
    <form [formGroup]="eclarityRevenue.form" class="fullform mt-3">
        <div class="grid">
            <div class=" field col-12 md:col-3 lg:col-2">
                <span class="p-float-label">
                    <p-dropdown formControlName="mon" inputId="month" placeholder="Select"
                        [options]="staticService.month" (onChange)="onDataSelectionChange($event)">
                    </p-dropdown>
                    <label for="float-input">Month </label>
                </span>
                <f-error [err]="eclarityRevenue.required('mon')" msg="Month is required"></f-error>
            </div>
            <div class="field col-12 md:col-3 lg:col-2">
                <span class="p-float-label">
                    <p-dropdown id="year" formControlName="year" (onChange)="onDataSelectionChange($event)"
                        inputId="year" [options]="eclarityRevenueLookup" placeholder="Select" optionLabel="year"
                        optionValue="year">
                    </p-dropdown>
                    <label for="float-input">Year </label>
                </span>
                <f-error [err]="eclarityRevenue.required('year')" msg="Year is required"></f-error>
            </div>
            <div class="field col-12 md:col-3 lg:col-2">
                <span>
                    <span class="p-float-label">
                        <p-calendar #calendarRef [showIcon]="false" dateFormat="dd/mm/yy" formControlName="date"
                            placeholder="DD/MM/YYYY-DD/MM/YYYY" [numberOfMonths]="1" class="widthClass"
                            selectionMode="range" [readonlyInput]="true" inputId="range" [(ngModel)]="selectedRange"
                            (onSelect)="onDateSelect($event)" (onSelect)="calendarSelected = true">
                            <ng-template pTemplate="footer">
                                <div class="grid justify-content-center">
                                    <p-button label="Clear" *ngIf="calendarSelected"
                                        (click)="changesCalendarClear(dt);dt.reset()"
                                        styleClass="p-button-raised p-button-text"></p-button>&nbsp;
                                    <button pButton pRipple type="button" *ngIf="calendarSelected"
                                        (click)="calenedarChangeDate($event,dt);dt.reset()" label=" Search"
                                        class="p-button-raised p-button-text"></button>
                                </div>
                            </ng-template>
                        </p-calendar>
                        <label for="float-input">Date Range </label>
                    </span>
                </span>
            </div>
            <div class="field col-12 md:col-3 lg:col-2">
                <span class="p-float-label">
                    <p-autoComplete forceSelection="true" formControlName="userType" field="name"
                        [suggestions]="userTypeLookup" (completeMethod)="filterCustomerType($event)"
                        ariaLabel="bodyPart" [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true"
                        delay="400" maxlength="50">
                    </p-autoComplete>
                    <label for="float-input">Customer </label>
                </span>
            </div>
            <div class="field col-12 md:col-3 lg:col-2">
                <span class="p-float-label">
                    <p-dropdown id="roleType" formControlName="roleType" inputId="year"
                        [options]="staticService.roleType" placeholder="Select" optionLabel="label" optionValue="id">
                    </p-dropdown>
                    <label for="float-input">Role </label>
                </span>
            </div>
            <div class="field mt-3">
                <button pButton pRipple type="button" (click)="searchEclarityRevenue();dt.reset()" label=" Search"
                    class="p-button-raised p-button-info" [disabled]="sysService.loading">
                    <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                </button>
                <button pButton pRipple type="button" (click)="resetEclarityRevenue();dt.reset()" label=" Reset"
                    class="p-button-raised p-button-text" [disabled]="sysService.loading">
                    <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                </button>
            </div>
        </div>
    </form>
</div>
<div class="table-responsive">
    <p-table #dt [value]="eclarityRevenue.list" [totalRecords]="eclarityRevenue.total" dataKey="id" [lazy]="true"
        [(selection)]="eclarityRevenue.selection" [loading]="sysService.loading" filterDelay="400"
        (onLazyLoad)="listeclarityRevenueListLazy($event)" currentPageReportTemplate="{first} to {last} of
            {totalRecords}" [rowsPerPageOptions]="[25,50,]" [rowHover]="true" styleClass="p-datatable-sm"
        [rows]="sysService.rows" [paginator]="true" [showCurrentPageReport]="true" alwaysShow="false"
        class="study-list-class" [scrollHeight]="heightTable" paginatorPosition="bottom"
        styleClass="p-datatable-sm p-datatable-gridlines"
        [globalFilterFields]="['userName','userType','createdAt','amount']">
        <ng-template pTemplate="caption">
            <div class="flex justify-content-between flex-wrap card-container purple-container pb-3">
                <div class="flex align-items-center justify-content-center ">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" maxlength="150" (input)="dt.filterGlobal($event.target.value,
                                          'contains')" placeholder="Search..." pAutoFocus [autofocus]="true" />
                    </span>
                    &nbsp;&nbsp; <button pButton pRipple type="button" label="Export Excel" icon=" pi pi-download"
                        (click)="downloadExcel(eclarityRevenue)" [disabled]="sysService.loading "
                        class="p-button-raised p-button-secondary">
                        <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                    </button>
                </div>
                <label class="text-2xl"><b>Selected Amount :</b> {{totalCharge || '0.00'}} </label>
                <label class="text-2xl"><b>Total Amount :</b> {{totalAmount || '0.00'}}</label>
            </div>
        </ng-template>
        <ng-template pTemplate="paginatorleft">
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox (click)="selectedValue()"></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="userName" id="userName">Customer Name<p-sortIcon field="userName"></p-sortIcon>
                </th>
                <th pSortableColumn="userType" id="userType">Customer Type<p-sortIcon field="userType"></p-sortIcon>
                </th>
                <th pSortableColumn="createdAt" id="usertype">Transaction Date<p-sortIcon field="usertype"></p-sortIcon>
                </th>
                <th pSortableColumn="amount" class="text-right" id="amountColumn">Amount
                    <p-sortIcon field="amount"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-eclarityRevenue>
            <tr>
                <td>
                    <p-tableCheckbox #chk [value]="eclarityRevenue" (click)="selectedValue()"></p-tableCheckbox>
                </td>
                <td>{{eclarityRevenue.userName}} </td>
                <td>{{eclarityRevenue.userType}}</td>
                <td>{{ sysService.toDate(eclarityRevenue.createdAt ) | date: 'dd-MM-yyyy hh:mm a ' }}</td>
                <td class="text-right">{{eclarityRevenue.amount}} </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td class="norecord" [attr.colspan]="6">No records found</td>
            </tr>
        </ng-template>
    </p-table>
</div>