import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SysService } from '@app/_common';
@Component({
  selector: 'app-cancellation',
  templateUrl: './cancellation.component.html',
  styleUrls: ['./cancellation.component.css']
})
export class CancellationComponent {
  constructor(

    private router: Router,
    public sysService: SysService,
  ) { }
  navHome() {
    this.router.navigateByUrl('/');
  }
}
