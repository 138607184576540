import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyProfileRoutingModule } from './my-profile-routing.module';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MyProfileRoutingModule,
   
  ]
})
export class MyProfileModule { }
