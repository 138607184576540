import { OnInit, Component, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService, SysService } from '@app/_common';
import { BehaviorSubject } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private http: HttpClient,
    public sysService: SysService,
    private apiService: ApiService,
  ) {
    this.sysService.changeTheme(this.sysService.theme, this.sysService.logo);
  }

  ngOnInit(): void {
    this.sysService.appRefresh();
  }
}

