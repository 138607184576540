import { DatePipe } from "@angular/common";
import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { View, SysService, StaticService, ViewFactory, FormDefault, Model, } from "@app/_common";
import { environment } from "@environments/environment";
import { LazyLoadEvent } from "primeng/api";

@Component({
  selector: "app-wallet-details",
  templateUrl: "./wallet-details.component.html",
  styleUrls: ["./wallet-details.component.scss"],
  providers: [DatePipe],

})
export class WalletDetalisComponent implements OnInit {
  @ViewChild("calendarRef") calendar: any;

  private WalletDetalisView: View = this.viewFactory.newView();
  private dataView: View = this.viewFactory.newView();
  WalletDetailsLookup: any[];
  userTypeLookup: any[];
  selectedRange: Date[];
  WalletDetalisList: any[];
  closingBalance: any;
  openingBalance: any;
  search: boolean = false;
  userLookup: any[];
  modLookup: any[];
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private datePipe: DatePipe,

    private viewFactory: ViewFactory
  ) { }
  ngOnInit() {
    this.scrollHeight()
    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 400));
    this.detectZoomChange()

    this.WalletDetalisView.of(this.WalletDetalisModel()).switchView("/wallet/wallet-details",
      this.route,
      this
    );
    this.WalletDetailsYear();
  }
  get WalletDetalis() {
    return this.WalletDetalisView.model;
  }
  private WalletDetalisModel() {
    const formDefault = new FormDefault([], []);
    return new Model(
      this.formBuilder.group({
        id: [],
        transactionId: [""],
        createdAt: [""],
        debit: [],
        patName: [],
        modality: [],
        studyDesc: [],
        credit: [],
        narration: [""],
        balance: [],
        month: new FormControl(new Date().getMonth() + 1),
        mon: new FormControl(new Date().getMonth() + 1),
        year: new FormControl(new Date().getFullYear()),
        userId: [],
        totalAmount: [],
        date: [],
      }),
      this.sysService,
      formDefault,
      ""
    );
  }
  get dataForm() {
    return this.dataView.model;
  }
  private dataViewmodel() {
    const formDefault = new FormDefault([], []);
    return new Model(
      this.formBuilder.group({
        name: [""],
        radiologistName: [""],
        centreName: [""],
        modality: [""],
      }),
      this.sysService,
      formDefault,
      ""
    );
  }
  isScrollTable = window.innerWidth > environment.devices.tablet ? true : false;
  heightDisplay = window.innerHeight - 450 + "px";
  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.isScrollTable =
      window.innerWidth > environment.devices.tablet ? true : false;
    this.heightDisplay = window.innerHeight - 450 + "px";
  }
  filterUser(event: any) {
    this.WalletDetalisView.getData(`/users/associated-lookup?filter=${event.query}`).then(data => {
      this.userLookup =
        data;
    });
  }
  walletList() {
    this.WalletDetalisView.getData("/wallet/wallet-details").then(data => {
      this.closingBalance = data.closingBalance
      this.openingBalance = data.openingBalance
    });
  }
  onDataSelectionChange(selectedMonth: any) {
    this.WalletDetalis.set('date', '')
  }
  onDateSelect(selectedDate: any) {
    this.WalletDetalis.set('mon', '')
    this.WalletDetalis.set('year', '')
  }

  filterMod(event: any) {
    this.WalletDetalisView.getData(`/modality/modality-lookup?filter=${event.query}`).then(data => {
      this.modLookup =
        data;
    });
  }


  searchWalletDetails() {
    this.search = true;
  }
  list(id: any) {
    this.listView(false);
  }
  async listWalletDetailsListLazy(event?: LazyLoadEvent, search: boolean = false) {
    const rangeDate = String(this.WalletDetalis.get("date"));
    const startDate = new Date(rangeDate.split(",")[0]);
    const endDate = new Date(rangeDate.split(",")[1]);
    const formattedStartDate = `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() +
      1).toString().padStart(2, '0')}/${startDate.getFullYear()}`;
    const formattedEndDate = `${endDate.getDate().toString().padStart(2, '0')}/${(endDate.getMonth() +
      1).toString().padStart(2, '0')}/${endDate.getFullYear()}`;
    const date = `${formattedStartDate}-${formattedEndDate}`;
    this.WalletDetalis.setParam("date", date)
    this.WalletDetalis.setParam("userId", this.WalletDetalis.get("userId")?.id)
    this.WalletDetalis.setParam("month", this.WalletDetalis.get("mon"))
    this.WalletDetalis.setParam("modality", this.WalletDetalis.get("modality")?.name)

    this.WalletDetalis.setParam("year", this.WalletDetalis.get("year"))
    this.WalletDetalisView.toListLazyFetch(`/wallet/wallet-details?`, this.search, event).then(data => {
      this.closingBalance = data.closingBalance;
      this.openingBalance = data.openingBalance;
    });
    this.search = false;
  }

  listView(search: boolean = false) {
    this.WalletDetalisView.toListLazyView(`/wallet/wallet-details?`, search);
  }

  WalletDetailsYear() {
    this.WalletDetalisView.getData(`/reports/year`).then(data => { this.WalletDetailsLookup = data; })
  }
  resetWalletDetails() {
    this.WalletDetalis.reset();
    this.search = true;
    let mon = new Date().getMonth() + 1
    let year = new Date().getFullYear()
    this.WalletDetalis.set('mon', mon);
    this.WalletDetalis.set('year', year);
  }
  downloadExcel(WalletDetalis: any) {
    this.searchWalletDetails()
    const rangeDate = String(this.WalletDetalis?.get("date"));
    const startDate = new Date(rangeDate.split(",")[0]);
    const endDate = new Date(rangeDate.split(",")[1]);
    const formattedStartDate = `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() +
      1).toString().padStart(2, '0')}/${startDate.getFullYear()}`;
    const formattedEndDate = `${endDate.getDate().toString().padStart(2, '0')}/${(endDate.getMonth() +
      1).toString().padStart(2, '0')}/${endDate.getFullYear()}`;
    const date = `${formattedStartDate}-${formattedEndDate}`;
    this.WalletDetalis.setParam("date", date)
    this.WalletDetalis.setParam("userId", this.WalletDetalis.get("userId")?.id)
    this.WalletDetalis.setParam("month", this.WalletDetalis.get("mon"))
    this.WalletDetalis.setParam("year", this.WalletDetalis.get("year"))
    this.WalletDetalis.setParam("modality", this.WalletDetalis?.get("modality")?.name)

    const pdfFilename = !this.WalletDetalisView.isEmptyList('date') ? `WalletDetalis-${date}.xls` : 'WalletDetalis.xls';
    this.WalletDetalisView.download(`/wallet/download-wallet-excel?`, pdfFilename);

  }
  changesCalendarClear(dt) {
    this.selectedRange = [];
    this.calendarSelected = false;
    this.WalletDetalis.reset();
    this.sysService.clear();
    this.WalletDetalis.set('isReportDate', false)
    this.search = false
    let month = new Date().getMonth() + 1
    let year = new Date().getFullYear()
    this.WalletDetalis.set('mon', month);
    this.WalletDetalis.set('year', year);
    this.WalletDetalis.selection = [];
    this.calendar.hideOverlay();
  }
  dateRange;
  calendarSelected = false;
  calenedarChangeDate(event, dt) {
    this.dateRange = "";
    if (this.selectedRange) {

      const startDate = this.datePipe.transform(
        this.selectedRange[0],
        "dd/MM/yyyy"
      );
      const endDate = this.datePipe.transform(
        this.selectedRange[1],
        "dd/MM/yyyy"
      );
      this.dateRange = `${startDate}-${endDate}`;
      this.listWalletDetailsListLazy(dt);
    }
    this.calendar.hideOverlay();
  }
  heightTable: any;
  debounceTimeout: any;
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 450) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 450) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  detectZoomChange() {
    this.scrollHeight();
  }
}