<div *ngIf="!sysService.isPermission()">
    <not-found></not-found>
</div>
<div *ngIf="sysService.isPermission()">
    <p-toolbar>
        <div class="p-toolbar-group-start">
            <f-nav [first]="'/assign-reject'"
                [label]="sysService.isRadSub() || sysService.isRad() ? '2° Rejected Report' : 'Assigned Reject Report'"
                [model]="ReportReject">
            </f-nav>
        </div>
        <div class="p-toolbar-group-end"></div>
    </p-toolbar>
    <div class="card grayCard ">
        <form [formGroup]="ReportReject.form" class="fullform">
            <div class="grid">
                <div class="  md:col-12 ">
                    <div class="grid p-3">
                        <div class=" field col-12 md:col-3 lg:col-2 m-2">
                            <span class="p-float-label">
                                <p-autoComplete forceSelection="true" formControlName="rejUserId" field="name"
                                    [suggestions]="userLookup" (completeMethod)="filteruser($event)" Label="id"
                                    [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400"
                                    maxlength="50">
                                </p-autoComplete>
                                <label for="float-input">Rejected By</label>
                            </span>
                        </div>
                        <div class="field col-12 md:col-3 lg:col-2 m-2">
                            <span>
                                <span class="p-float-label">
                                    <p-calendar #calendarRef [showIcon]="false" dateFormat="dd/mm/yy"
                                        placeholder="DD/MM/YYYY-DD/MM/YYYY" formControlName="date"
                                        (onSelect)="onDateSelect($event)" [numberOfMonths]="1" class="widthClass"
                                        selectionMode="range" [readonlyInput]="true" inputId="range"
                                        [(ngModel)]="selectedRange" (onSelect)="calendarSelected = true">
                                        <ng-template pTemplate="footer">
                                            <div class="grid justify-content-center">
                                                <p-button label="Clear" *ngIf="calendarSelected"
                                                    (click)="changesCalendarClear(dt);dt.reset()"
                                                    styleClass="p-button-raised p-button-text"></p-button>&nbsp;
                                                <button pButton pRipple type="button" *ngIf="calendarSelected"
                                                    (click)="calenedarChangeDate($event,dt);dt.reset()" label=" Search"
                                                    class="p-button-raised p-button-text"></button>
                                            </div>
                                        </ng-template>
                                    </p-calendar>
                                    <label for="float-input">Date Range </label>
                                </span>
                            </span>
                        </div>
                        <div class=" field col-12 md:col-3 lg:col-2 m-2">
                            <span class="p-float-label">
                                <p-autoComplete forceSelection="true" formControlName="modality" field="name"
                                    [suggestions]="modLookup" (completeMethod)="filterMod($event)" ariaLabel="bodyPart"
                                    [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400"
                                    maxlength="50">13
                                </p-autoComplete>
                                <label for="float-input">Modality</label>
                            </span>
                        </div>
                        <div class=" field col-12 md:col-3 lg:col-2 m-2">
                            <span class="p-float-label">
                                <p-autoComplete forceSelection="true" formControlName="studyDesc" field="name"
                                    [suggestions]="studyDesc" (completeMethod)="filterStudyDesc($event)"
                                    ariaLabel="bodyPart" [dropdown]="true" [autoHighlight]="true"
                                    showEmptyMessage="true" delay="400" maxlength="120">
                                </p-autoComplete>
                                <label for="float-input">Study Description</label>
                            </span>
                        </div>

                        <div class="field mt-4">
                            <button pButton pRipple type="button" (click)="searchReport();dt.reset()" label=" Search"
                                icon=" pi pi-search" class="p-button-raised p-button-info"
                                [disabled]="sysService.loading">
                                <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                            </button>
                            <button pButton pRipple type="button" (click)="resetPayable();dt.reset();resetPayable();"
                                label=" Reset" icon=" pi pi-undo" class="p-button-raised p-button-text"
                                [disabled]="sysService.loading">
                                <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="table-responsive">
        <p-table #dt [value]="ReportReject.list" [scrollable]="true" scrollHeight="65vh" class="study-list-class"
            [totalRecords]="ReportReject.total" [lazy]="true" selectionMode="multiple" [scrollHeight]="heightTable"
            (onLazyLoad)="listReportRejectLazy($event)" dataKey="id" [(selection)]="ReportReject.selection"
            [loading]="sysService.loading" filterDelay="400"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" [rowsPerPageOptions]="[25,50,]"
            [rowHover]="true" styleClass="p-datatable-sm" [rows]="25" [paginator]="true" [showCurrentPageReport]="true"
            alwaysShow="false" paginatorPosition="bottom" styleClass="p-datatable-sm p-datatable-gridlines"
            [globalFilterFields]="['name','email','phoneNo','status','study_Date']">
            <ng-template pTemplate="caption">
                <div class="grid">
                    <div class="col-6 md:col-4 lg:col-2">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" maxlength="150" [(ngModel)]="ReportReject.searchTxt"
                                (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..."
                                pAutoFocus [autofocus]="true" />
                        </span>
                    </div>
                    <div class="col-6 md:col-4 lg:col-6 mt-2">
                        <button pButton pRipple type="button" label="Export Excel" icon=" pi pi-download"
                            (click)="downloadExcel(ReportReject)" [disabled]="sysService.loading "
                            class="p-button-raised p-button-secondary">
                            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                        </button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="paginatorleft">
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>

                    <th id="id" scope="col" pSortableColumn="id">
                        ID
                        <p-sortIcon field="studyId"></p-sortIcon>
                    </th>
                    <th id="pat_name" scope="col" pSortableColumn="pat_name">
                        Patient Name
                        <p-sortIcon field="pat_name"></p-sortIcon>
                    </th>
                    <th id="study_Desc" scope="col" pSortableColumn="study_Desc">
                        Study Desc
                        <p-sortIcon field="study_Desc"></p-sortIcon>
                    </th>
                    <th id="studyDescModified" scope="col" pSortableColumn="studyDescModified">
                        Study Desc Modified
                        <p-sortIcon field="studyDescModified"></p-sortIcon>
                    </th>
                    <th id="reportingRad" scope="col" pSortableColumn="reportingRad">
                        Assigned To
                        <p-sortIcon field="reportingRad"></p-sortIcon>
                    </th>
                    <th id="modality" scope="col" pSortableColumn="modality">
                        Modality
                        <p-sortIcon field="modality"></p-sortIcon>
                    </th>
                    <th *ngIf="!sysService.isCenter()" id="sourceName" scope="col" pSortableColumn="sourceName">
                        Source
                        <p-sortIcon field="sourceName"></p-sortIcon>
                    </th>
                    <th id="rejection_reason" scope="col" pSortableColumn="rejection_reason">
                        Reason
                        <p-sortIcon field="rejection_reason"></p-sortIcon>
                    </th>
                    <th id="u.name" scope="col" pSortableColumn="u.name">
                        Rejected By
                        <p-sortIcon field="u.name"></p-sortIcon>
                    </th>
                    <th id="study_Date" scope="col" pSortableColumn="study_Date">
                        Rejected Date
                        <p-sortIcon field="study_Date"></p-sortIcon>
                    </th>
                    <th>
                        Action

                    </th>

                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-ReportReject>
                <tr>
                    <td>
                        <p-tableCheckbox #chk [value]="ReportReject"></p-tableCheckbox>
                    </td>
                    <td>{{ ReportReject?.id }}</td>
                    <td pTooltip="{{ ReportReject.patName }} ">{{ ReportReject?.patName?.length > 25 ?
                        ReportReject?.patName?.slice(0, 25) + '...' : ReportReject?.patName }} </td>
                    <td>{{ ReportReject?.studyDesc }}</td>
                    <td>{{ ReportReject?.studyDescModified }}</td>
                    <td>{{ ReportReject?.repRad }}</td>

                    <td>{{ ReportReject?.modality }}</td>
                    <td *ngIf="!sysService.isCenter()">{{ ReportReject?.sourceName }}</td>

                    <td>{{ReportReject?.reason}}</td>
                    <td> {{ReportReject.rejectedBy}} </td>
                    <td>{{ ReportReject?.rejectedDate | date: 'dd-MM-yyyy hh:mm a ' }}</td>
                    <td><button pButton pRipple type="button" (click)="reportrejectex(ReportReject)"
                            icon="pi pi-file-pdf" title="Study Download"
                            class="p-button-rounded p-button-secondary p-button-text">
                            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td class="norecordstudy" [attr.colspan]="13">No records found</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>