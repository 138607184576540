<div *ngIf="sysService.isAdmin()">
    <div class="loading-container">
        <p class="loading-text">Please wait, checking user details from Datacenter...</p>
        <div class="spinner">
        </div>
    </div>
</div>


<div *ngIf="!sysService.isAdmin()">
<not-found></not-found>
</div>