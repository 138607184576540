<p-toolbar>
  <div class="p-toolbar-group-start">
    <f-nav first="/groups" label="Teams" [model]="group"> </f-nav>
  </div>
  <div class="p-toolbar-group-end">
    <button pButton pRipple type="button" label="Delete" icon="pi pi-trash" class="p-button-outlined
     p-button-outlined p-button-danger" (click)="deletegroup(group.id)"
      *ngIf="group.isEdit() && isGroupAdmin()"></button>
  </div>
</p-toolbar>
<!-- header -->
<!-- ---------------------form for group----------------------------------- -->
<div class="formgrid text-center bc" *ngIf="group.isEdit()">
  <div class="badges formgrid text-center ">
    <p-badge [value]="groupDetails?.pending || '00'" size="large" severity="warning"
      styleClass="mr-2 total-cont"></p-badge>
    &nbsp;
    <span class="text-2xl font-semibold total-cont ">Pending
    </span>&nbsp;
    <p-badge [value]="groupDetails?.completed || '00'" size="large" severity="success" styleClass="mr-2"
      class="total-cont"></p-badge>
    &nbsp;
    <span class="text-2xl font-semibold total-cont"> Completed
    </span>&nbsp;
    <p-badge [value]="groupDetails?.unAssigned || '00'" size="large" severity="primary" styleClass="mr-2"
      class="total-cont"></p-badge>
    &nbsp;
    <span class="text-2xl font-semibold total-cont"> UnAssigned
    </span>&nbsp;
  </div>
</div>
<form [formGroup]="group.form">
  <!-- Form group -->
  <!-- Show card only if form is displayed or view mode is active -->
  <div *ngIf="group.isform || group.isView()" class="card">
    <div class="grid">
      <!-- Group name field -->
      <div class="col-12 md:col-6 lg:col-6">
        <label>Team Name <span class="validation-star">*</span></label>
        <input id="name" maxlength="100" type="text" formControlName="name"
          [readonly]="group?.view?.isAdminLoggedIn === 0" pInputText pAutoFocus [autofocus]="true">
        <f-error [err]="group.required('name')" msg="Team Name is required"></f-error>
      </div>
      <!-- Status field, visible only to admins or in new group mode -->
      <div class="col-12 md:col-6 lg:col-6" *ngIf="group?.get('isAdminLoggedIn') ==1 || group.isNew()">
        <label>Status </label>
        <p-dropdown formControlName="status" inputId="status" placeholder="Select" [options]="staticService.status"
          required="true" [readonly]="group?.view?.isAdminLoggedIn === 0">
        </p-dropdown>
        <f-error [err]="group.required('status')" msg="Status is required"></f-error>
      </div>
      <!-- Group description field -->
      <div class="col-12 md:col-6 lg:col-6">
        <label>Team Description <span class="validation-star">*</span></label>
        <textarea class="p-inputtextarea p-inputtext p-component p-element" [rows]="2" [cols]="40"
          formControlName="description" maxlength="150" pInputTextarea
          [readonly]="group?.view?.isAdminLoggedIn === 0"></textarea>
        <f-error [err]="group.required('description')" msg="Team Description is required"></f-error>
      </div>
      <!-- Study assignment rules field, visible only to admins or in new group mode -->
      <div class="col-12 md:col-6 lg:col-6" *ngIf="group?.get('isAdminLoggedIn') ==1 || group.isNew()">
        <label>Study Assignment Rules </label>
        <p-dropdown formControlName="assignmentRule" [readonly]="group?.view?.isAdminLoggedIn === 0"
          inputId="assignmentRule" placeholder="Select" [options]="staticService.assignmentRule" required="true">
        </p-dropdown>
      </div>
      <!-- Report approval rules field, visible only to admins or in new group mode -->
      <div class="col-12 md:col-6 lg:col-6" *ngIf="group?.get('isAdminLoggedIn') ==1 || group.isNew()">
        <label>Report Approval Rules</label>
        <p-dropdown formControlName="approvalRule" [readonly]="group?.view?.isAdminLoggedIn === 0"
          inputId="approvalRule" placeholder="Select" [options]="staticService.approvalRule" required="true">
        </p-dropdown>
      </div>
      <!-- Visibility  rules field, visible only to admins or in new group mode -->
      <div class="col-12 md:col-6 lg:col-6" *ngIf=" group?.get('isAdminLoggedIn') ==1 || group.isNew()">
        <label>Visibility Rule <span class="validation-star">*</span></label>
        <p-dropdown formControlName="visibilityRule" [readonly]="group?.view?.isAdminLoggedIn ===0"
          inputId="visibilityRule" placeholder="Select" [options]="staticService.visibilityRule" required="true">
        </p-dropdown>
        <f-error [err]="group.required('visibilityRule')" msg="Visibility rule is required"></f-error>
      </div>
      <!-- Hide Centre field, visible only to admins or in new group mode -->
      <div class="col-12 md:col-6 lg:col-6" *ngIf=" group?.get('isAdminLoggedIn')==1 || group.isNew()">
        <label>Hide Centre <span class="validation-star">*</span></label>
        <p-dropdown formControlName="hideCentreName" [readonly]="group?.view?.isAdminLoggedIn ===0"
          inputId="approvalRule" placeholder="Select" [options]="staticService.hideCentreName" required="true">
        </p-dropdown>
        <f-error [err]="group.required('hideCentreName')" msg="Hide centre is required"></f-error>
      </div>
      <div class=" col-12 md:col-6 lg:col-6">
        <label>Template Sharing Scope <span class="validation-star">*</span></label>
        <p-dropdown formControlName="shareable" inputId="shareable" placeholder="Select"
          [readonly]="group?.view?.isAdminLoggedIn ===0" [options]="staticService.sharingScope"
          required="true"></p-dropdown>
        <f-error [err]="group.required('shareable')" msg="Template Sharing Scope is required"></f-error>
      </div>
    </div>
    <div class="formgrid text-center bc">
      <!-- Back or cancel button -->
      <button pButton pRipple type="button" [label]="group?.view?.isAdminLoggedIn === 0 ? 'Back' : 'Cancel'"
        [icon]="group?.view?.isAdminLoggedIn === 0 ? 'pi pi-arrow-left' : 'pi pi-times'"
        class="p-button-outlined p-button-info" (click)="listView(group.isEmptyList());dt2.reset()"></button>
      <!-- Save button (only visible if user is admin or creating a new group) -->
      <button pButton pRipple type="button" [label]="saveButtonLabel" icon="pi pi-check"
        class="p-button-raised p-button-success" (click)="savegroup()"
        *ngIf="group?.get('isAdminLoggedIn')==1 || dataGroup?.isAdminLoggedIn==1 || group.isNew()"
        [disabled]="sysService.loading">
        <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
      </button>
      <!-- Add Members button (only visible if user is admin and editing an existing group) -->
      <span>
        <button pButton pRipple type="button" label="Add Members" icon="pi pi-plus" class="p-button-raised"
          *ngIf="group.isEdit() && group?.get('isAdminLoggedIn')==1 || dataGroup?.isAdminLoggedIn==1"
          (click)="showMembers(group.id)"></button>
      </span>
    </div>
  </div>
</form>
<!-- <--------------------add contacts list table ------------------------------------>
<div class="table-responsive" *ngIf=" group.isEdit()">
  <div class="grid">
    <div class="col-12 md:col-12 lg:col-12">
      <div [style.display]="group.isEdit() ? 'block':'none'" class="card-list">
        <p-table #dt1 [value]="contactsSearch?.list" [scrollable]="true" scrollHeight="65vh" *ngIf=" group.isEdit()"
          [totalRecords]="contactsSearch?.total" dataKey="id" [(selection)]="contactsSearch.selection" filterDelay="400"
          currentPageReportTemplate="{first} to {last} of {totalRecords}" [rowHover]="true" styleClass="p-datatable-sm"
          [rows]="25" [paginator]="true" [showCurrentPageReport]="true" alwaysShow="false"
          [rowsPerPageOptions]="[25,50,]" paginatorPosition="bottom" styleClass="p-datatable-sm p-datatable-gridlines"
          [globalFilterFields]="['name','email','contactNo','status']">
          <ng-template pTemplate="caption">
            <div class="grid">
              <div class="col-4 md:col-4 lg:col-2">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" maxlength="150"
                    (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search..." pAutoFocus
                    [autofocus]="true" />
                </span>
              </div>
              <div class="col-4 md:col-4 lg:col-6 text-left">
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th> Name </th>
              <th>Email </th>
              <th>Contact Number </th>
              <th>Action </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-Contacts>
            <tr>
              <td> <span class="p-column-title"><b>Name</b></span>
                {{Contacts.name | slice:0:60 }}
              </td>
              <td class="bdy-part"><span class="p-column-title"><b>email</b></span>{{Contacts.email}}</td>
              <td><span class="p-column-title"><b>contactnumber</b></span>{{Contacts.contactNo}}</td>
              <td>
                <button pButton pRipple type="button" *ngIf="Contacts.isAdmin === 0 && isGroupAdmin()"
                  label="Remove Member" class="p-button-outlined p-button-danger"
                  (click)="deteleMember(Contacts)"></button>
                &nbsp;
                <button pButton pRipple type="button" icon="pi pi-user" disabled="true" *ngIf="Contacts.isAdmin===1 "
                  label="Admin" class="p-button-outlined p-button-success"></button>&nbsp;
                <button pButton pRipple type="button" icon="pi pi-arrow-right" (click)="leaveGroup()"
                  *ngIf="Contacts.isAdmin===0 && Contacts.isLeave ===1 " label="Leave"
                  class="p-button-outlined p-button-danger"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td [attr.colspan]="5"><b>No one belongs to this group. </b></td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
<!-- <----------------------------------------------dialogue all contact ---------------------------------->
<p-dialog [(visible)]="contactsListDialog" [style]="{width: '70vw'}" (onHide)="closeContactDiagloue()" [modal]="true"
  [dismissableMask]="false">
  <ng-template pTemplate="header">
    <h2>Contacts List</h2>
  </ng-template>
  <div class="table-responsive">
    <p-table #dt3 [value]="contactslist" [scrollable]="true" scrollHeight="65vh" [totalRecords]="contactslist?.length"
      dataKey="id" [(selection)]="group.selection" class="study-list-class" [loading]="sysService.loading"
      filterDelay="400" currentPageReportTemplate="{first} to {last} of {totalRecords}" [rowsPerPageOptions]="[25,50,]"
      [rowHover]="true" styleClass="p-datatable-sm" [rows]="25" [showCurrentPageReport]="true" alwaysShow="false"
      paginatorPosition="bottom" styleClass="p-datatable-sm p-datatable-gridlines"
      [globalFilterFields]="['name','email','contactNo']">
      <ng-template pTemplate="caption">
        <div class="grid">
          <div class="col-6 md:col-6 lg:col-6">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" maxlength="150" (input)="dt3.filterGlobal($event.target.value, 'contains')"
                placeholder="Search..." pAutoFocus [autofocus]="true">
            </span>
          </div>
          <div class="col-6 md:col-6 lg-6 text-right">
            <span class="total-cont">
              <span>Total Contacts : </span>
              <p-badge [value]="contactslist?.length" severity="success" size="large" styleClass="mr-5"></p-badge>
            </span>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th> Name </th>
          <th>Email </th>
          <th>Contact Number </th>
          <th style="min-width: 180px;">Action </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-Contacts>
        <tr>
          <td> <span class="p-column-title"><b>Name</b></span>
            {{Contacts.name | slice:0:60 }}
          </td>
          <td class="bdy-part"><span class="p-column-title"><b>email</b></span>{{Contacts.email}}</td>
          <td><span class="p-column-title"><b>contactnumber</b></span>{{Contacts.contactNo}}</td>
          <td>
            <button pButton pRipple type="button" type="button" icon="pi pi-plus" *ngIf="Contacts?.status === 0" label="Add To
            Team" class="p-button-raised p-button-warning" (click)="addMembers(Contacts)"></button>
            <button pButton pRipple type="button" type="button" icon="pi pi-user" disabled="true"
              *ngIf="Contacts?.status === 1" label="Added
           " class="p-button-raised p-button-secondary" (click)="addMembers(Contacts)"></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td class="norecordstudy" [attr.colspan]="5">No contact found</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <ng-template pTemplate="footer">
  </ng-template>
</p-dialog>
<!-- <--------------------------------------all group list ------------------------------------------------>
<div [style.display]="group.islist ? 'block':'none'" class="table-responsive">


  <p-table #dt2 [value]="group.list" class="study-list-class" [scrollable]="true" [scrollHeight]="heightTable"
    [totalRecords]="group.total" [lazy]="true" (onLazyLoad)="listgroupLazy($event)" dataKey="id"
    [(selection)]="group.selection" selectionMode="multiple" [loading]="sysService.loading" filterDelay="400"
    styleClass="p-datatable-sm p-datatable-gridlines" currentPageReportTemplate="{first} to {last} of {totalRecords}"
    [rowsPerPageOptions]="[25, 50]" [rowHover]="true" [rows]="25" [paginator]="true" [showCurrentPageReport]="true"
    alwaysShow="false" paginatorPosition="bottom"
    [globalFilterFields]="['name','description','createdBy    ','taxCode','countryId.name']">


    <ng-template pTemplate="caption">
      <div class="grid">
        <div class="ml-2">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input pInputText type="text" maxlength="150" [(ngModel)]="group.searchTxt"
              (input)="dt2.filterGlobal($event.target.value, 'contains')" placeholder="Search..." pAutoFocus
              [autofocus]="true" />
          </span>
        </div>
        <div class="ml-2 mt-2">
          <span>
            <button pButton pRipple type="button" label="Create Team" icon="pi pi-plus" class="p-button-raised"
              *ngIf="group.isList()" (click)="add()"></button>
          </span>
        </div>
        <div class="mt-2">
          <button pButton pRipple type="button" label="Delete" icon="pi pi-trash"
            class="p-button-outlined p-button-danger" (click)="deletegroups()"
            *ngIf="!group.isEmptyList() && group.selection.length > 0"></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem">
          <p-tableHeaderCheckbox *ngIf="sysService.isAdmin()"> </p-tableHeaderCheckbox>
        </th>
        <th pSortableColumn="g.name" style="min-width:117px;">
          Team Name
          <p-sortIcon field="g.name"></p-sortIcon>
        </th>
        <th pSortableColumn="g.description">
          Team Description
          <p-sortIcon field="g.groupDescription"></p-sortIcon>
        </th>
        <th pSortableColumn="u.name">
          Created By
          <p-sortIcon field="u.name"></p-sortIcon>
        </th>
        <th pSortableColumn="isAdmin">
          Role
          <p-sortIcon field="isAdmin"></p-sortIcon>
        </th>
        <th pSortableColumn="g.status">
          Status
          <p-sortIcon field="g.status"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-group>
      <tr [ngClass]="{'ui-state-highlight': chk.checked}">
        <td>
          <p-tableCheckbox [style.display]="group.isAdmin === 1 ? 'block' : 'none'" #chk
            [value]="group"></p-tableCheckbox>
        </td>
        <td><i class="pi pi-file-edit link-study" (click)="edit(group.id)" title="edit"> </i> &nbsp;{{group.name}}
        </td>
        <td class="bdy-part">{{group.description }}</td>
        <td>{{group.createdUser }}</td>
        <td><span *ngIf="group.isAdmin===0" class="text-danger">Member </span>
          <span *ngIf="group.isAdmin===1" class="text-success">Admin</span>
        </td>
        <td>
          <span *ngIf="group.status ===0" class="text-danger">Inactive</span>
          <span *ngIf="group.status ===1" class="text-success">Active</span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td class="norecordstudy" [attr.colspan]="6">No records found</td>
      </tr>
    </ng-template>
  </p-table>
</div>