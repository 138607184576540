<header class="header" *ngIf="!sysService.isLoggedIn()">
  <div class="wrap">
    <div class="right">
      <div class="rgtBdr">
        <a (click)="navHome()">
          <img class="web" src="../../../assets/images/eclogoo.png" alt="" /></a>
      </div>
    </div>
  </div>
</header>
<div class="card hrd">
  <div class="grid align-items-center justify-content-center">
    <div class="col-12  lg:col-4">
      <form [formGroup]="contactus.form">
        <div class="grid">
          <div class="col-12 md:col-6 lg:col-12">
            <label>Name <span class="validation-star">*</span></label>
            <input pInputText maxlength="50" *ngIf="sysService.isLoggedIn() && !sysService.isGuest()" readonly="true"
              [value]="userDetails?.name" type="text" />
            <input pInputText maxlength="50" *ngIf="!sysService.isLoggedIn() || sysService.isGuest()"
              formControlName="name" type="text" pAutoFocus [autofocus]="true" />
            <f-error [err]="contactus.required('name')" msg="Name is
              required"></f-error>
          </div>
          <div class="col-12 md:col-6 lg:col-12">
            <label for="EmailAddress">Email Address <span class="validation-star">*</span>
            </label>
            <input pInputText maxlength="50" *ngIf="sysService.isLoggedIn() && !sysService.isGuest()" readonly="true"
              [value]="userDetails?.email" type="text" />
            <input id="EmailAddress" maxlength="100" *ngIf="!sysService.isLoggedIn() || sysService.isGuest()"
              type="text" pInputText formControlName="email" required autocomplete="off" />
            <f-error [err]="contactus.required('email')" msg="Email Address is required "></f-error>
            <f-error [err]="contactus.pattern('email')"
              msg="This e-mail address is not valid. Valid records include to user@somehost.somedomain. And emails only accept lowercase letters.">
            </f-error>
          </div>
          <div class="col-12 md:col-6 lg:col-12">
            <label>Phone Number <span class="validation-star">*</span></label>
            <input pInputText maxlength="50" *ngIf="sysService.isLoggedIn() && !sysService.isGuest()" readonly="true"
              [value]="userDetails?.phoneNo" type="text" />
            <input pInputText maxlength="18" *ngIf="!sysService.isLoggedIn() || sysService.isGuest() "
              formControlName="contactNo" type="text" />
            <f-error [err]="contactus.required('contactNo')" msg="Phone Number is required"></f-error>
            <f-error [err]="contactus.pattern('contactNo')" msg="Numbers only required"></f-error>
          </div>
          <div class="col-12 md:col-6 lg:col-12">
            <label>Subject <span class="validation-star">*</span></label>
            <p-dropdown formControlName="subject" inputId="subject" placeholder="Select"
              [options]="staticService.subjectTicket">
            </p-dropdown>
            <f-error [err]="contactus.required('subject')" msg="Subject
              is required"></f-error>
          </div>
          <div class="col-12  lg:col-12">
            <label><b>Let us know how we can help..? </b><span class="validation-star">*</span></label>
            <textarea class="p-inputtextarea p-inputtext p-component" [rows]="2" [cols]="30" formControlName="help"
              maxlength="500" pInputTextarea></textarea>
            <f-error [err]="contactus.required('help')" msg="Description is required"></f-error>
          </div>
          <div class="col-12 lg:col-12 text-center">
            <a [routerLink]="['/home']"><button pButton pRipple label="Back" icon=" pi pi-arrow-left"
                class="p-button-outlined p-button-info"></button></a>&nbsp;
            <button pButton pRipple type="button" label="Submit" (click)="saveContacts()" icon=" pi pi-check"
              class="p-button-raised p-button-success" [disabled]="sysService.loading">
              <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-12  lg:col-3 ">
      <div class=" grid justify-content-center contact-img">
        <p-image src="../../assets/images/contactus.webp" alt="Image" width="400" class="img1"></p-image>
      </div>
    </div>
    <div class="col-12  lg:col-5 ">
      <div class="grid m-md-8 ">
        <div class="col-12 lg:col-6 mt-2">
          <a>
            <i class="pi pi-globe" style="font-size: 1rem">&nbsp; </i>India
          </a>
        </div>

        <div class="col-12 lg:col-6  mt-2">
          <a href="tel:+919946114448" class=""><i class="pi pi-phone" style="font-size: 1rem">&nbsp;
              +919946114448</i></a>
        </div>
        <div class="col-12 lg:col-6  mt-2">
          <a href="mailto:support@ecscribe.com"><i class="pi pi-inbox" style="font-size: 1rem">&nbsp;
              support@ecscribe.com</i></a>
        </div>
        <div class="col-12 lg:col-6 pos  mt-2">
          <a href="https://www.ecscribe.com/" target="_blank"><i class="pi pi-at" style="font-size: 1rem">&nbsp;
              www.ecscribe.com</i></a>
        </div>
        <div class="col-12 lg:col-10 md-center mt-2">

          Building No - 60/49E, Third Floor, JC CHAMBERS
          <br />PANAMPILLY NAGAR, ERNAKULAM <br /> KERALA - 682036

        </div>
      </div>
    </div>
  </div>
</div>