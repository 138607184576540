import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SysService } from '@app/_common';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent {
  constructor(
   
    private router: Router,
    public sysService: SysService,
  ) { }
  navHome(){
    this.router.navigateByUrl('/');

  }
}
