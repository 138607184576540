import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Hl7Service {

  constructor(private http: HttpClient) { }


sendPostRequest(servicePath ,data: any): Observable<any> {

const url = environment.appApi + servicePath;
  const headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  return this.http.post(url, data, { headers: headers })
    .pipe(
      catchError((error) => {
        console.error('Error occurred:', error);
        return throwError(error);
      })
    );
}
}
