<div class="card grayCard">
  <form [formGroup]="rateCardList.form" class="fullform mt-3">
    <div class="grid">
      <div class=" field col-12 md:col-3 lg:col-2 m-2" *ngIf="!sysService.isRad() && !popUp">
        <span class="p-float-label">
          <p-autoComplete forceSelection="true" formControlName="radiologistName" field="name" [suggestions]="radLookup"
            (completeMethod)="filterRad($event)" Label="id" [dropdown]="true" [autoHighlight]="true"
            showEmptyMessage="true" delay="400" maxlength="50">
          </p-autoComplete>
          <label for="float-input">Radiologist</label>
        </span>
      </div>
      <div class=" field col-12 md:col-4 lg:col-2 m-2" *ngIf="!popUp && !sysService.isCenter() || sysService.isAdmin()">
        <span class="p-float-label">
          <p-autoComplete forceSelection="true" formControlName="userId" field="name" [suggestions]="userLookup"
            (completeMethod)="filterUser($event)" ariaLabel="bodyPart" [dropdown]="true" [autoHighlight]="true"
            showEmptyMessage="true" delay="400" maxlength="50">
          </p-autoComplete>
          <label class="flo-lab" for="float-input">Centre/Primary Radiologist</label>
        </span>
      </div>
      <div class=" field col-12 md:col-4 lg:col-2 m-2">
        <span class="p-float-label">
          <p-autoComplete forceSelection="true" formControlName="studyDesc" field="name" [suggestions]="studyDesc"
            (completeMethod)="filterStudyDesc($event)" ariaLabel="bodyPart" [dropdown]="true" [autoHighlight]="true"
            showEmptyMessage="true" delay="400" maxlength="120">
          </p-autoComplete>
          <label for="float-input">Study Description</label>
        </span>
      </div>
      <div class=" field col-12 md:col-3 lg:col-2 m-2">
        <span class="p-float-label">
          <p-autoComplete forceSelection="true" formControlName="modality" field="name" [suggestions]="modLookup"
            (completeMethod)="filterMod($event)" ariaLabel="bodyPart" [dropdown]="true" [autoHighlight]="true"
            showEmptyMessage="true" delay="400" maxlength="50">
          </p-autoComplete>
          <label for="float-input">Modality</label>
        </span>
      </div>
      <div class="field mt-3 col-12 md:col-10 lg:col-3">
        <button pButton pRipple type="button" (click)="searchrateCardList();dt.reset()" label=" Search"
          icon=" pi pi-search" class="p-button-raised p-button-info" [disabled]="sysService.loading">
          <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
        </button>
        <button pButton pRipple type="button" (click)="resetRateCardList();dt.reset()" label=" Reset" icon=" pi pi-undo"
          class="p-button-raised p-button-text" [disabled]="sysService.loading">
          <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
        </button>
      </div>
    </div>
  </form>
</div>
<div class="table-responsive">
  <p-table #dt [value]="rateCardList.list" class="study-list-class" [scrollable]="true" [scrollHeight]="heightTable"
    [totalRecords]="rateCardList.total" [lazy]="true" (onLazyLoad)="listrateCardListLazy($event)" dataKey="studyId"
    [(selection)]="rateCardList.selection" selectionMode="multiple" [loading]="sysService.loading" filterDelay="400"
    styleClass="p-datatable-sm p-datatable-gridlines" currentPageReportTemplate="{first} to {last} of {totalRecords}"
    [rowsPerPageOptions]="[25,50]" [rowHover]="true" [rows]="25" [paginator]="true" [showCurrentPageReport]="true"
    alwaysShow="false" paginatorPosition="bottom" [globalFilterFields]="['name','rate','description']">
    <ng-template pTemplate="caption">
      <div class="grid">
        <div class="col-6 md:col-4 lg:col-2">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input pInputText type="text" maxlength="150" [(ngModel)]="rateCardList.searchTxt"
              (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." pAutoFocus
              [autofocus]="true" />
          </span>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
      <button pButton pRipple type="button" label="Delete" icon="pi pi-trash" class="p-button-outlined p-button-danger"
        (click)="deleterateCardLists()"
        *ngIf="!rateCardList.isEmptyList() && rateCardList.selection.length > 0"></button>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="u.name"
          *ngIf="(sysService.isCenter() || sysService.isCenterAdmin() || sysService.isAdmin() ) && !popUp">
          Radiologist <p-sortIcon field="u.name"></p-sortIcon>
        </th>
        <th pSortableColumn="c.name"
          *ngIf="(sysService.isRad() || sysService.isRadAdmin() || sysService.isAdmin()) && !popUp">
          Centre/Primary Radiologist <p-sortIcon field="c.name"></p-sortIcon>
        </th>
        <th pSortableColumn="modality">Modality <p-sortIcon field="modality"></p-sortIcon>
        </th>
        <th pSortableColumn=" o.study_desc" style="width: 15%;">Study Description<p-sortIcon field=" o.study_desc">
          </p-sortIcon>
        </th>
        <th pSortableColumn="rate" style="text-align: end;">Rate<p-sortIcon field="rate"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rateCardList>
      <tr>
        <td *ngIf="(sysService.isCenter() || sysService.isCenterAdmin() || sysService.isAdmin())&& !popUp">
          {{rateCardList.radiologistName}}
        </td>
        <td *ngIf="(sysService.isRad() || sysService.isRadAdmin() || sysService.isAdmin()) && !popUp">
          {{rateCardList.centreName}}</td>
        <td>{{rateCardList.modalityCode}}</td>
        <td>{{rateCardList.studyDesc}}</td>
        <td *ngIf="sysService.isRad() && !popUp" class="link" style="text-align: end;" (click)="edit(rateCardList.id)">
          <ng-container *ngIf="rateCardList.rate; else showIcon">
            {{ rateCardList.rate }}
          </ng-container>
          <ng-template #showIcon>
            <i class="pi pi-file-edit"></i>
          </ng-template>
        </td>
        <td *ngIf="sysService.isRad() && popUp" style="text-align: end;">{{rateCardList.rate}}</td>
        <td *ngIf="!sysService.isRad()" style="text-align: end;">{{rateCardList.rate}}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td class="norecord" [attr.colspan]="5">No records found</td>
      </tr>
    </ng-template>
  </p-table>
</div>
<p-dialog header="Change Rate" [(visible)]="changeRate" [style]="{width: '25vw'}"
  [breakpoints]="{ '991px': '75vw', '575px': '95vw' }">
  <form [formGroup]="rateCardList.form">
    <div class="grid">
      <div class="field col-12 md:col-12 lg:col-12">
        <input id="username" type="text" maxlength="5" formControlName="rate" aria-describedby="username1-help"
          pInputText required pAutoFocus [autofocus]="true">
        <f-error [err]="rateCardList.pattern('rate')" msg="Only Numbers are accepted"></f-error>
      </div>
    </div>
    <div class="  text-center">
      <button pButton pRipple type="button" (click)="saverateCardList()" label="Submit"
        class="p-button-raised p-button-success" [disabled]="!rateCardList.valid || sysService.loading">
        <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
      </button>
      <button pButton pRipple type="button" (click)="cancelRateChange()" label=" Cancel"
        class="p-button-raised p-button-text" [disabled]="sysService.loading">
        <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
      </button>
    </div>
  </form>
</p-dialog>