import { Component, HostListener } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model } from '@app/_common';
import { AppConstants } from '@app/_config';
import { environment } from '@environments/environment';
import { LazyLoadEvent } from 'primeng/api';
@Component({
  selector: 'app-my-study-rates',
  templateUrl: './my-study-rates.component.html',
  styleUrls: ['./my-study-rates.component.css']
})
export class MyStudyRatesComponent {

  studyRateListExport = ['name', 'taxCode', 'code', 'countryId.name']; //for export header fields
  private studyRateListView: View = this.viewFactory.newView();
  reportTemplateLookup: any[];
  countryLookup: any[];
  revenueYearList: any[];
  radLookup: any[];
  cenLookup: any[];
  modLookup: any[];
  studyDesc: any[];
  search: boolean;

  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory) {
  }
  ngOnInit() {
    this.scrollHeight()
    this.detectZoomChange()

    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 400));

    this.studyRateListView.of(this.studyRateListModel());
  }
  get studyRateList() {
    return this.studyRateListView.model;
  }
  get report() {
    return this.studyRateListView.model;
  }
  private studyRateListModel() {
    const formDefault = new FormDefault(['status'], [1]);
    return new Model(this.formBuilder.group({
      id: [],
      centreName: ['',],
      radiologistName: [,],
      studyDesc: ['',],
      modalityCode: [""],
      reportTemplateName: [''],
      reportTemplateId: [],
      name: [''],
      radName: [''],
      cenId: [''],
      modality: [''],
    }), this.sysService, formDefault, '');
  }
  add() {
    this.studyRateListView.toNew();
  }
  edit(ratecardList: any) {
    this.studyRateListView.toEditFetch(`/raddetails/${ratecardList.id}`, ratecardList.id);

  }
  list(id: any) {
    this.listView(false);
  }
  async listStudyRateListLazy(event?: LazyLoadEvent, search: boolean = false) {
    this.studyRateList.setParam('radId', this.studyRateList.get("radName")?.id)
    this.studyRateList.setParam("cenId", this.studyRateList.get("cenId")?.id)
    this.studyRateList.setParam("modality", this.studyRateList.get("modality")?.name)
    this.studyRateList.setParam("studyDesc", this.studyRateList.get("studyDesc")?.name)
    this.studyRateListView.toListLazyFetch(`/raddetails/radiologist-template`, this.search, event);
    this.search = false;
  }
  searchTemplate() {
    this.search = true;
  }

  isScrollTable = window.innerWidth > environment.devices.tablet ? true : false;
  heightDisplay = (window.innerHeight - 450) + 'px';
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isScrollTable = window.innerWidth > environment.devices.tablet ? true : false;
    this.heightDisplay = (window.innerHeight - 450) + 'px';
  }

  filterStudyDesc(event: any) {
    this.studyRateListView.getData(`/raddetails/studydesc-lookup?filter=${event.query}`).then(data => {
      this.studyDesc = data;
    });
  }
  listView(search: boolean = false) {
    this.studyRateListView.toListLazyView(`/raddetails`, search);
  }
  view(id: any) {
    this.studyRateListView.toViewFetch(`/raddetails/${id}`, id);
  }
  deleteStudyRateLists() {
    this.studyRateListView.toDeleteSelection(`/`).then((data) => {

      this.listView(false)

    })
  }
  filterReport(event: any) {
    this.studyRateListView.getData(`/reporttemplate/lookup?filter=${event.query}`).then(data => {
      this.reportTemplateLookup
        = data;
    });
  }
  loadTemplate(id) {
    this.studyRateListView.getData(`/reporttemplate/${id}`).then(data => { this.report.set("report", data.report) });
  }
  templateid(event: any) {
    this.loadTemplate(event.id)
  }
  saveRateCardList() {
    let template = this.studyRateList.get("reportTemplateId")?.name
    this.studyRateListView.toSaveList("/raddetails/update-template", "id", 'Template saved successfully')
      .then(data => {
        this.studyRateList.list.forEach(x => {
          if (data?.id === x.id) {
            x.reportTemplateName = template;

          }
        });
      })
      .catch(error => {
        console.error('Error saving template:', error);
      });
  }

  searchStudy() {
    this.studyRateListView.toSaveList("/raddetails/update-template", "id");
  }
  radiologistLookUp() {
    this.studyRateListView.getData('')
  }
  searchRevenue(revenue: any) {
    this.sysService.clear()
    if (this.studyRateListView.isValid()) {
      this.studyRateListView.getDataList(`/revenue`, AppConstants.noRecordFound);
    }
  }
  revenueYear() {
    this.studyRateListView.getData(`/reports/year`).then(data => { this.revenueYearList = data; })
  }
  filterRad(event: any) {
    this.studyRateListView.getData(`/radiologist/radiologist-lookup?filter=${event.query}`).then(data => {
      this.radLookup =
        data;
    });
  }
  filterCen(event: any) {
    this.studyRateListView.getData(`/centre/centre-lookup?filter=${event.query}`).then(data => {
      this.cenLookup = data;
    });
  }
  filterMod(event: any) {
    this.studyRateListView.getData(`/modality/modality-lookup?filter=${event.query}`).then(data => {
      this.modLookup = data;
    });
  }
  resetRateCardList() {
    this.studyRateList.reset()
    this.search = true;
  }


  heightTable: any;
  debounceTimeout: any;
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 400) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 400) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }
  detectZoomChange() {
    this.scrollHeight();
  }
}