import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model } from '@app/_common';
import { AppConstants } from '@app/_config';

@Component({
  selector: 'app-user-role-profile',
  templateUrl: './user-role-profile.component.html',
  styleUrls: ['./user-role-profile.component.css']
})
export class UserRoleProfileComponent {

  private UserRoleView: View = this.viewFactory.newView();
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory) {
  }
  ngOnInit() {
    this.UserRoleView.of(this.UserRoleListModel()); this.userDetails()
  }
  get UserRoleDetails() {
    return this.UserRoleView.model;
  }
  private UserRoleListModel() {
    const formDefault = new FormDefault([], []);
    return new Model(this.formBuilder.group({
      id: [],
      name: ['', [Validators.required, Validators.pattern(/^(?! ).*/)]],
      email: ['', [Validators.required, Validators.pattern(AppConstants.regexEmail)]],
      phoneNo: ['', [Validators.required, Validators.pattern(AppConstants.regexPhonenumber), Validators.minLength(7)]],
    }), this.sysService, formDefault, '');
  }
  async saveUserRole() {
    await this.UserRoleView.toSave("/users", "id", 'Profile  updated successfully');
    this.sysService.loadUserName();
  }
  userDetails() {
    this.UserRoleView.getFormData("/users/userprofile")
  }


}
