<!-- <p-toolbar>
    <div class="p-toolbar-group-start">
        <span *ngIf="sysService.isAdmin()">
            <f-nav first="/revenue" label="Radiologist Revenue" [model]="revenue" new=""> </f-nav>
        </span>
        <span *ngIf="sysService.isRad()">
            <f-nav first="/revenue" label="Receivables" [model]="revenue" new=""> </f-nav>
        </span>
        <span *ngIf="sysService.isCenter()">
            <f-nav first="/revenue" label="Payables" [model]="revenue" new=""> </f-nav>
        </span>
    </div>
</p-toolbar> -->
<div class="card grayCard p-4">
    <form [formGroup]="revenue.form" class="fullform mt-3">
        <div class="grid ">
            <div class=" field col-6 md:col-3 lg:col-2">
                <span class="p-float-label">
                    <p-dropdown formControlName="month" inputId="month" placeholder="Select"
                        [options]="staticService.month">
                    </p-dropdown>
                    <label for="float-input">Month </label>
                </span>
                <f-error [err]="revenue.required('month')" msg="Month is required"></f-error>
            </div>
            <div class="field col-6 md:col-3 lg:col-2">
                <span class="p-float-label">
                    <p-dropdown id="year" formControlName="year" inputId="year" [options]="revenueYearList"
                        placeholder="Select" optionLabel="year" optionValue="year">
                    </p-dropdown>
                    <label for="float-input">Year </label>
                </span>
                <f-error [err]="revenue.required('year')" msg="Year is required"></f-error>
            </div>
            <div class=" field col-6 md:col-3 lg:col-2" *ngIf="this.sysService.isAdmin()">
                <span class="p-float-label">
                    <p-autoComplete forceSelection="true" formControlName="radiologistName" field="name"
                        [suggestions]="radLookup" (completeMethod)="filterRad($event)" Label="id" [dropdown]="true"
                        [autoHighlight]="true" showEmptyMessage="true" delay="400" maxlength="50">
                    </p-autoComplete>
                    <label for="float-input">Radiologist</label>
                </span>
            </div>
            <div class=" field col-6 md:col-3 lg:col-2" *ngIf="this.sysService. isRad()||this.sysService.isAdmin()">
                <span class="p-float-label">
                    <p-autoComplete forceSelection="true" formControlName="centreName" field="name"
                        [suggestions]="cenLookup" (completeMethod)="filterCen($event)" ariaLabel="bodyPart"
                        [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400" maxlength="50">
                    </p-autoComplete>
                    <label for="float-input">Centre</label>
                </span>
            </div>
            <div class=" field col-6 md:col-3 lg:col-2">
                <span class="p-float-label">
                    <p-autoComplete forceSelection="true" formControlName="modality" field="name"
                        [suggestions]="modLookup" (completeMethod)="filterMod($event)" ariaLabel="bodyPart"
                        [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400" maxlength="50">
                    </p-autoComplete>
                    <label for="float-input">Modality</label>
                </span>
            </div>
            <div class=" field col-6 md:col-3 lg:col-2">
                <span class="p-float-label">
                    <p-dropdown formControlName="paymentStatus" inputId="month" placeholder="Select"
                        [options]="staticService.paymentStatus">
                    </p-dropdown>
                    <label for="float-input">Payment Status </label>
                </span>
            </div>
            <div class=" field col-6 md:col-3 lg:col-2">
                <span class="p-float-label">
                    <p-autoComplete forceSelection="true" formControlName="userId" field="name"
                        [suggestions]="userLookup" (completeMethod)="filteruser($event)" Label="id" [dropdown]="true"
                        [autoHighlight]="true" showEmptyMessage="true" delay="400" maxlength="50">
                    </p-autoComplete>
                    <label for="float-input">Payable To </label>
                </span>
            </div>
            <div class=" mt-3">
                <button pButton pRipple type="button" (click)="searchRevenue();dt.reset()" label=" Search"
                    icon=" pi pi-search" class="p-button-raised p-button-info" [disabled]="sysService.loading">
                    <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                </button>
                <button pButton pRipple type="button" (click)="resetRevenue();dt.reset()" label=" Reset"
                    icon=" pi pi-undo" class="p-button-raised p-button-text" [disabled]="sysService.loading">
                    <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                </button>
            </div>
        </div>
    </form>
</div>
<!-- 
// *********************************************This  payables for center for more details check billing component *************************************************
// *************************************************************************************************************************************************************** -->
<div class="table-responsive">
    <p-table #dt [value]="revenue.list" [scrollable]="true" scrollHeight="65vh" [totalRecords]="revenue.total"
        [lazy]="true" selectionMode="multiple" (onLazyLoad)="listratecardListLazy($event)" dataKey="id"
        [(selection)]="revenue.selection" [loading]="sysService.loading" filterDelay="400"
        currentPageReportTemplate="{first} to {last} of {totalRecords}" [rowsPerPageOptions]="[25,50,]"
        [rowHover]="true" styleClass="p-datatable-sm" [rows]="25" [paginator]="true" [showCurrentPageReport]="true"
        alwaysShow="false" paginatorPosition="bottom" styleClass="p-datatable-sm p-datatable-gridlines"
        [globalFilterFields]="['radiologistName','centreName','patName','reportDate','modality','studyDesc','charge']">
        <ng-template pTemplate="caption">
            <div class="flex justify-content-between flex-wrap card-container purple-container pb-3">
                <div class="flex align-items-center justify-content-center ">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" maxlength="150" (input)="dt.filterGlobal($event.target.value,
                                          'contains')" placeholder="Search..." pAutoFocus [autofocus]="true" />
                    </span>
                    <div class="ml-2">
                        <button pButton pRipple type="button" label="Export" icon=" pi pi-download"
                            (click)="export(revenue)" [disabled]="sysService.loading "
                            class="p-button-raised p-button-warning">
                            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                        </button>
                        <button pButton pRipple type="button" label="Export Excel" icon=" pi pi-download"
                            (click)="downloadExcel(revenue)" [disabled]="sysService.loading "
                            class="p-button-raised p-button-secondary">
                            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                        </button>
                    </div>
                </div>
                <label class="text-2xl"><b>Selected Amount :</b> {{totalCharge || '0.00'}}</label>
                <label class="text-2xl"><b>Total Amount :</b> {{totalAmount || '0.00'}}</label>
            </div>
        </ng-template>
        <ng-template pTemplate="paginatorleft">
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox (click)="selectedValue()"></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="radiologistName"
                    *ngIf="sysService.isCenter() || sysService.isCenterAdmin() || sysService.isAdmin()">Payable To
                    <p-sortIcon field="radiologistName"></p-sortIcon>
                </th>
                <th pSortableColumn="centreName"
                    *ngIf="sysService.isRad() || sysService.isRadAdmin() || sysService.isAdmin()">
                    Centre Name <p-sortIcon field="centreName"></p-sortIcon>
                </th>
                <th pSortableColumn="studyId">Study Id
                    <p-sortIcon field="studyId"></p-sortIcon>
                </th>
                <th pSortableColumn="patName">Patient Name
                    <p-sortIcon field="patName"></p-sortIcon>
                </th>
                <th pSortableColumn="reportDate">Report Date-Time<p-sortIcon field="reportDate"></p-sortIcon>
                </th>
                <th pSortableColumn="modality">Modality<p-sortIcon field="modality"></p-sortIcon>
                </th>
                <th pSortableColumn="studyDesc">Study Desc<p-sortIcon field="studyDesc"></p-sortIcon>
                </th>
                <th pSortableColumn="studyDescModified">Study Modified<p-sortIcon
                        field="studyDescModified"></p-sortIcon>
                </th>
                <th pSortableColumn="paymentStatus">Payment Status<p-sortIcon field="paymentStatus"></p-sortIcon>
                </th>
                <th pSortableColumn="charge" style="text-align: end;">Charge<p-sortIcon field="charge"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-revenue>
            <tr>
                <td>
                    <p-tableCheckbox #chk [value]="revenue" (click)="selectedValue()"></p-tableCheckbox>
                </td>
                <td *ngIf="sysService.isCenter() || sysService.isCenterAdmin() || sysService.isAdmin()">
                    {{revenue.radiologistName}}
                </td>
                <td *ngIf="sysService.isRad() || sysService.isRadAdmin() || sysService.isAdmin()">
                    {{revenue.centreName}}</td>
                <td>{{revenue.studyId}}</td>
                <td>{{revenue.patName}}</td>
                <td>{{ sysService.toDate( revenue.reportDate ) | date: 'dd-MM-yyyy hh:mm a ' }}</td>
                <td>{{revenue.modality}}</td>
                <td>{{revenue.studyDesc}} </td>
                <td>{{revenue.studyDescModified}} </td>
                <td><span *ngIf="revenue?.paymentStatus == 1" style="color: green;">Paid </span>
                    <span *ngIf="revenue?.paymentStatus == 0" style="color: red;">Not Paid </span>
                </td>
                <td style="text-align: end;">{{revenue.charge}} </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td class="norecord" [attr.colspan]="10">No records found</td>
            </tr>
        </ng-template>
    </p-table>
</div>