<div *ngIf="!sysService.isPermission()">
    <not-found></not-found>
</div>
<div *ngIf="sysService.isPermission()">
    <p-toolbar>
        <div class="p-toolbar-group-start">
            <f-nav first="/bodypart" label="Body Part" [model]="bodypart"> </f-nav>
        </div>
        <div class="p-toolbar-group-end">
            <button pButton pRipple type="button" label="Delete" icon="pi pi-trash" class="p-button-outlined
      p-button-outlined p-button-danger" (click)="deleteBodyPart(bodypart.id)" *ngIf="bodypart.isEdit()"></button>
        </div>
    </p-toolbar>
    <!-- ----------------------------------Add form -------------------- -->
    <form [formGroup]="bodypart.form">
        <div *ngIf="bodypart.isform || bodypart.isView()" class="card">
            <div class="grid">
                <div class="field col-12 md:col-6 lg:col-6">
                    <label>Name <span class="validation-star">*</span></label>
                    <input id="username" type="text" maxlength="25" formControlName="name"
                        aria-describedby="username1-help" pInputText required pAutoFocus [autofocus]="true">
                    <f-error [err]="bodypart.required('name')" msg="Name is required"></f-error>
                </div>
                <div class="field col-12 md:col-6 lg:col-6">
                    <label>Body Part <span class="validation-star">*</span></label>
                    <input id="username" type="text" maxlength="25" formControlName="bodypart"
                        aria-describedby="username1-help" pInputText required>
                    <f-error [err]="bodypart.required('bodypart')" msg="Body Part is required"></f-error>
                </div>
                <div class="field col-12 md:col-6 lg:col-6">
                    <label>Description <span class="validation-star">*</span></label>
                    <textarea [rows]="3" [cols]="39" maxlength="480"
                        class="p-inputtextarea p-inputtext p-component p-element" formControlName="description"
                        pInputTextarea></textarea>
                    <f-error [err]="bodypart.required('description')" msg="Description is required"></f-error>
                </div>
                <div class="field col-12 md:col-6 lg:col-6">
                    <label>Status</label>
                    <p-dropdown formControlName="status" inputId="status" placeholder="Select"
                        [options]="staticService.status" required="true">
                    </p-dropdown>
                </div>
            </div>
            <div class="formgrid text-center bc">
                <button pButton pRipple type="button" label="Cancel" icon="pi pi-times"
                    class="p-button-outlined p-button-info" (click)="listView(bodypart.isEmptyList());"></button>
                <button pButton pRipple type="button" [label]="saveButtonLabel " icon="pi pi-check"
                    class="p-button-raised p-button-success" (click)="saveBodyPart()" [disabled]="sysService.loading"
                    *ngIf="bodypart.isForm()">
                    <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                </button>
                <button pButton pRipple type="button" label=" Save & Add" icon="pi pi-plus"
                    class="p-button-raised p-button-info" (click)="saveBodyPart(true)" [disabled]="sysService.loading"
                    *ngIf="bodypart.isNew()">
                    <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                </button>
            </div>
        </div>
    </form>
    <!---------------------------------------------- table list --------------------------------------------- -->
    <div class="table-responsive" [style.display]="bodypart.islist? 'block':'none'">
        <p-table #dt [value]="bodypart.list" scrollHeight="65vh" [totalRecords]="bodypart.total" [lazy]="true"
            (onLazyLoad)="listBodyPartLazy($event)" dataKey="id" [(selection)]="bodypart.selection"
            selectionMode="multiple" [loading]="sysService.loading" filterDelay="400"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" [rowsPerPageOptions]="[25, 50]"
            [rowHover]="true" styleClass="p-datatable-sm" [rows]="25" [paginator]="true" [showCurrentPageReport]="true"
            alwaysShow="false" paginatorPosition="bottom" styleClass="p-datatable-sm p-datatable-gridlines"
            class="study-list-class" [scrollHeight]="heightTable"
            [globalFilterFields]="['name','code','bodypart','description','id','status']">
            <ng-template pTemplate="caption">
                <div class="grid">
                    <div class="ml-2">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" maxlength="150" [(ngModel)]="bodypart.searchTxt" pAutoFocus
                                [autofocus]="true" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search..." />
                        </span>
                    </div>
                    <div class=" mt-2 ml-2">
                        <span>
                            <button pButton pRipple type="button" label="Add Body Part" icon="pi pi-plus"
                                class="p-button-raised" *ngIf="bodypart.isList()" (click)="add()"></button>
                        </span>
                    </div>
                    <div class="mt-2">
                        <button pButton pRipple type="button" label="Delete" icon="pi pi-trash"
                            class="p-button-outlined p-button-danger" (click)="deleteBodyParts()"
                            *ngIf="!bodypart.isEmptyList() && bodypart.selection.length > 0"></button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="paginatorleft">
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th pSortableColumn="name" style="min-width:117px;">
                        Name
                        <p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="bodypart">
                        Body Part
                        <p-sortIcon field="bodypart"></p-sortIcon>
                    </th>
                    <th pSortableColumn="description">
                        Description
                        <p-sortIcon field="description"></p-sortIcon>
                    </th>
                    <th pSortableColumn="status">
                        Status
                        <p-sortIcon field="status"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-bodypart>
                <tr [ngClass]="{'ui-state-highlight': chk.checked}">
                    <td>
                        <p-tableCheckbox #chk [value]="bodypart"></p-tableCheckbox>
                    </td>
                    <td> <i class="pi pi-file-edit link-study" (click)="edit(bodypart.id)" title="edit"> </i>
                        {{bodypart.name}}

                    </td>
                    <td>{{bodypart.bodypart}} </td>
                    <td class="bdy-part">{{bodypart.description}}</td>
                    <td><span *ngIf="bodypart.status ===0" class="text-danger"> Inactive</span> <span
                            *ngIf="bodypart.status ===1" class="text-success "> Active</span></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td class="norecordstudy" [attr.colspan]="6">No records found</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>