<div class="col1">
  <iframe
    [src]="viewerUrl() | safe"
    id="ech-viewer"
    style="
      border-style: none;
      border-color: inherit;
      border: 0px;
      border-width: 0px;
      height: 90vh;
      width: 100%;
    "
    allow="cross-origin-isolated"
  ></iframe>
</div>

<!-- <ng-template pTemplate *ngIf="sysService.isRad() && !Report || !radi"> -->
