export const RolePermissions = {
  'ROLE_SYS_ADMIN': ['modality','sys-rad-login', 'home', 'profileview', 'equipment', 'user-role', 'bodypart', 'centre-user', 'ticket',
    'user-role', 'studies', 'template-dashboard', 'centres', 'Radiologistlist','studydescription', 'groups', 'contacts', 'install',
    'turnaround', 'billing-dashboard', 'desktop-clint',],
  'ROLE_CTR_ADMIN': ['center-admin', 'home', 'profileview', 'ticket', 'studies-reject','studydescription', 'contact-us', 'reject-report',
    'user-role', 'studies', 'Radiologistlist', 'groups', 'contacts', 'install', 'turnaround', 'billing-dashboard', 'hl7',
    'desktop-clint'],
  'ROLE_CTR_USER': ['center-admin', 'home', 'profileview', 'template-dashboard','studydescription', 'ticket', 'studies-reject', 'contact-us', 'reject-report',
    'user-role', 'studies', 'Radiologistlist', 'groups', 'contacts', 'install', 'turnaround', 'billing-dashboard', 'hl7',
    'desktop-clint'],
  'ROLE_RAD_ADMIN': ['rad-admin', 'sub-user','home', 'studies', 'template-dashboard','studydescription', 'centres', 'Radiologistlist', 'groups',
    'contacts', 'install', 'billing-dashboard', 'desktop-clint', 'profileview', 'ticket', 'turnaround', 'studies-reject',
    'contact-us', 'assign-reject', 'reject-report', 'short-code','radi-user'],
  'ROLE_RAD_USER': ['rad-user', 'sub-user','home', 'studies', 'template-dashboard','studydescription', 'centres', 'Radiologistlist', '  ',
    'contacts', 'install', 'billing-dashboard', 'desktop-clint', 'profileview', 'ticket', 'turnaround', 'studies-reject',
    'contact-us', 'assign-reject', 'reject-report', 'short-code','radi-user'],
  'ROLE_ASSIGNER_USER': ['assigner', 'home', 'profileview', 'ticket', 'studies-reject', 'studydescription','contact-us', 'studies', 'groups',
    'desktop-clint',],
  'ROLE_APPROVER_USER': ['approver', 'home', 'profileview', 'ticket', 'studies','studydescription', 'groups', 'desktop-clint',],
  'ROLE_CTR_TECHNICIAN': ['technical', 'home', 'profileview', 'studies', 'studydescription', 'desktop-clint', 'ticket'],
  'ROLE_CTR_PHYSICIAN': ['physician', 'home', 'profileview', 'ticket', 'studies', 'studydescription', 'desktop-clint', 'contacts'],
  'ROLE_RAD_SUB': ['home', 'studies', 'template-dashboard', 'studydescription',
  'contacts',  'desktop-clint', 'profileview', 'ticket',  'studies-reject',
  'contact-us', 'assign-reject', 'reject-report', 'short-code'],
  'ROLE_GUEST_ADMIN':['sharing-viewer','radiologistviewer']
};

