import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SysService } from '@app/_common';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent {
  constructor(
   
    private router: Router,
    public sysService: SysService,
  ) { }
  navHome(){
    this.router.navigateByUrl('/');

  }
}
