<div *ngIf="!sysService.isPermission()">
    <not-found></not-found>
</div>
<div *ngIf="sysService.isPermission()">
    <p-toolbar>
        <div class="p-toolbar-group-start">
            <f-nav first="/radi-user" label="Sub User" [model]="RadiRole"> </f-nav>
        </div>
        <div class="p-toolbar-group-end">
            <!-- <button pButton pRipple type="button" label="Delete" icon="pi pi-trash" class="p-button-outlined
      p-button-outlined p-button-danger" (click)="deleteRadiRole(RadiRole.id)" *ngIf="RadiRole.isEdit()"></button> -->
        </div>
    </p-toolbar>
    <!-- ----------------------------------Add form -------------------- -->
    <form [formGroup]="RadiRole.form">
        <div *ngIf="RadiRole.isform || RadiRole.isView()" class="card">
            <div class="grid">

                <div class="field col-12 md:col-6 lg:col-6">
                    <label>Name <span class="validation-star">*</span></label>

                    <input pInputText maxlength="150" formControlName="name" type="text" pAutoFocus autofocus="true"
                        required>
                    <f-error [err]="RadiRole.required('name')" msg=" Name is required"></f-error>
                    <f-error [err]="RadiRole.pattern('name')" msg=" white space not allowed on begining"></f-error>

                </div>

                <div class="field col-12 md:col-6 lg:col-6">

                    <label for="EmailAddress">Email Address <span class="validation-star">*</span></label>
                    <input id="EmailAddress" maxlength="100" type="text" pInputText formControlName="email" required
                        autocomplete="off" [readOnly]="flag" />

                    <f-error [err]="RadiRole.required('email')" msg="Email Address is required "></f-error>
                    <f-error [err]="RadiRole.pattern('email')"
                        msg="This e-mail address is not valid. Valid records include to user@somehost.somedomain. And emails only accept lowercase letters.">
                    </f-error>
                </div>

                <div class="field col-12 md:col-6 lg:col-6">
                    <label>Phone Number <span class="validation-star">*</span></label>


                    <input pInputText maxlength="18" formControlName="phoneNo" type="text">

                    <f-error [err]="RadiRole.required('phoneNo')" msg="Phone Number is required"></f-error>
                    <f-error [err]="RadiRole.pattern('phoneNo')" msg="Numbers only required"></f-error>
                    <f-error [err]="RadiRole.minlength('phoneNo')" msg="Enter a minimum of 7 digits"></f-error>

                </div>
                <div class="field col-12 md:col-6 lg:col-6">
                    <label>Status </label>

                    <p-dropdown formControlName="status" inputId="status" placeholder="Select"
                        [options]="staticService.status" required="true">
                    </p-dropdown>

                </div>

            </div>
            <div class="table-responsive">
                <div class="grid p-2">
                    <div class="col-12 md:col-4 lg:col-4">
                        <h2 class="text-center">Work List </h2>
                        <div class="grid">
                            <div class="col-6 md:col-6 lg:col-6 text-left">
                                Show Source
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="isSource"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-left">
                                <div>
                                    Show Assigned To </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="isAssigned"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-left ">
                                <div>
                                    Billed Rates
                                </div>
                            </div>

                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="isBilledRateList"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-left ">
                                <div>
                                    Radiologist Rates
                                </div>
                            </div>

                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="isRadRateList"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-left ">
                                <div>
                                    Show Reject
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="isRejectList"></p-inputSwitch>
                                </div>
                            </div>

                            <div class="col-6 md:col-6 lg:col-6 text-left ">
                                <div>
                                    Show Study Log
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="isStudyLog"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-left ">
                                <div>
                                    Show Rejected List
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="isRejectedList "></p-inputSwitch>
                                </div>
                            </div>


                            <div class="col-6 md:col-6 lg:col-6 text-left ">
                                <div>
                                    Edit Patient
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="isPatDetailsEditable"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-left ">
                                <div>
                                    Add & Edit Technician
                                </div>
                            </div>

                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="isTechEditableByRad"></p-inputSwitch>
                                </div>
                            </div>
                            <!-- <div class="col-6 md:col-6 lg:col-6 text-left ">
                                    <div>
                                        Show Demographic Log
                                    </div>
                                </div>
                                <div class="col-6 md:col-6 lg:col-6 text-center">
                                    <div>
                                        <p-inputSwitch formControlName="showStatus"></p-inputSwitch>
                                    </div>
                                </div> -->

                        </div>
                    </div>
                    <div class="col-12 md:col-4 lg:col-4">
                        <h2 class="text-center">Report Settings</h2>
                        <div class="grid">


                            <div class=" col-6 md:col-6 lg:col-6 text-align: left; ">
                                <div>
                                    Pdf Download </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="isDownloadPdf"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-left ">
                                <div>
                                    Word Download </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="isDownloadWord"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-left ">
                                <div>
                                    Report Log
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="isReportLog"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-left ">
                                <div>
                                    Apporve </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="isApproveReport"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-left">
                                Rate </div>
                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="isReportRate"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-left">
                                Confirm </div>
                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="isConfirmReport"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-left">
                                Reject </div>
                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="isRejectReport"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-left">
                                Template </div>
                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="isTemplateDropDown"></p-inputSwitch>
                                </div>
                            </div>

                        </div>


                    </div>

                    <div class="col-12 md:col-4 lg:col-4">
                        <h2 class="text-center">Functionality Settings</h2>
                        <div class="grid">

                            <div class="col-6 md:col-6 lg:col-6 text-left">
                                <div>
                                    Show Rejected Studies </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="isRejectStudies"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-left ">
                                <div>
                                    Show Assigned Reject Report </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="isAssignedRejectReport"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-left ">
                                <div>
                                    Show Rejected Report
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="isRejectReport"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-left ">
                                <div>
                                    Show Short Code </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="IshortCode"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-left ">
                                <div>
                                    Report Template </div>
                            </div>
                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="isTemplateEditable"></p-inputSwitch>
                                </div>
                            </div>

                            <div class="col-6 md:col-6 lg:col-6 text-left">
                                Show Balance </div>
                            <div class="col-6 md:col-6 lg:col-6 text-center">
                                <div>
                                    <p-inputSwitch formControlName="isBalanceDisplay"></p-inputSwitch>
                                </div>
                            </div>

                        </div>


                    </div>

                </div>



                <div class="col-12 text-right ">
                    <label id="note-label">(<b>Note:</b>Changes in user settings will take effect only after re-login.)
                    </label>
                </div>
            </div>



            <div class="formgrid text-center bc">
                <button pButton pRipple type="button" label="Cancel" icon="pi pi-times"
                    class="p-button-outlined p-button-info" (click)="listView(RadiRole.isEmptyList());"></button>


                <button pButton pRipple type="button" [label]="saveButtonLabel " icon="pi pi-check"
                    class="p-button-raised p-button-success" (click)="saveRadiRole()" [disabled]="sysService.loading">
                    <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                </button>


            </div>




        </div>


    </form>
    <!---------------------------------------------- table list --------------------------------------------- -->
    <div class="table-responsive" [style.display]="RadiRole.islist? 'block':'none'">

        <p-table #dt [value]="RadiRole.list" [totalRecords]="RadiRole.total" [lazy]="true"
            (onLazyLoad)="listRadiRoleLazy($event)" [scrollable]="true" scrollHeight="65vh" dataKey="id"
            [(selection)]="RadiRole.selection" selectionMode="multiple" [loading]="sysService.loading" filterDelay="400"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" [rowsPerPageOptions]="[25, 50]"
            [rowHover]="true" styleClass="p-datatable-sm" [rows]="sysService.rows" [paginator]="true"
            [showCurrentPageReport]="true" alwaysShow="false" paginatorPosition="bottom"
            styleClass="p-datatable-sm p-datatable-gridlines"
            [globalFilterFields]="['name','code','description','status']">
            <ng-template pTemplate="caption">
                <div class="grid">
                    <div class="ml-2">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" maxlength="150" [(ngModel)]="RadiRole.searchTxt" pAutoFocus
                                [autofocus]="true" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search..." />
                        </span>
                    </div>
                    <div class="ml-2 mt-2">
                        <span>
                            <button pButton pRipple type="button" label="Create Sub User" icon="pi pi-plus"
                                class="p-button-raised" *ngIf="RadiRole.isList()" (click)="add()"></button>
                        </span>
                    </div>
                    <div class="mt-2">
                        <button pButton pRipple type="button" label="Delete" icon="pi pi-trash"
                            class="p-button-outlined p-button-danger" (click)="deleteRadiRoles()"
                            *ngIf="!RadiRole.isEmptyList() && RadiRole.selection.length > 0 "></button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="paginatorleft">

            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem" *ngIf="!this.sysService.isAdmin()">
                        <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
                    </th>
                    <th style="width: 3rem" *ngIf="this.sysService.isAdmin()">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th pSortableColumn="name">
                        Name
                        <p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="email">
                        Email Address
                        <p-sortIcon field="email"></p-sortIcon>
                    </th>
                    <th pSortableColumn="phoneNo">
                        Phone Number
                        <p-sortIcon field="phoneNo"></p-sortIcon>
                    </th>



                    <th pSortableColumn="status">
                        Status
                        <p-sortIcon field="status"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-RadiRole>
                <tr>
                    <td *ngIf="!this.sysService.isAdmin()">
                        <p-tableCheckbox [value]="RadiRole" *ngIf="RadiRole.userChildRole ===3"></p-tableCheckbox>
                    </td>
                    <td *ngIf="this.sysService.isAdmin()">
                        <p-tableCheckbox [value]="RadiRole"></p-tableCheckbox>
                    </td>

                    <td class="link "> <a (click)="edit(RadiRole.id)"> {{RadiRole.name}}</a>
                    </td>
                    <td class="bdy-part">{{RadiRole.email}}</td>

                    <td>{{RadiRole.phoneNo}}</td>



                    <td><span *ngIf="RadiRole.status ===0" class="text-danger">Inactive </span>
                        <span *ngIf="RadiRole.status ===1" class="text-success">Active </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td class="norecord" [attr.colspan]="6">No records found</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>