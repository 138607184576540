import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { SysService, View } from '@app/_common';

@Component({
  selector: 'not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NonFoundComponent implements OnInit {
 
  roleValue:any
  @Input() userRole: any; 
  @Input() functionalityName: string; 

  countdown: number = 10; // Initial countdown value

  constructor(
    public sysService: SysService,
  ) { }

  ngOnInit(): void {
    this.roleValue = this.sysService.getUser().role; 
   
    this.startCountdown();
  }

  startCountdown() {
    const interval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(interval); 
        if(!this.sysService.isGuest())   {
          this.redirectToURL('/'); 
        }else{
         this.shareViewlink()
        }    
      }
    }, 1000); 
  }

  redirectToURL(url: string) {
    window.location.href = url;
  }

  shareViewlink() {
    let url = localStorage.getItem("shareView");
    if (url) {
        window.location.href = url;
    } else {
        this.sysService.error("Share viewer URL not found. Please try again.");
    }
  }
}
