<form [formGroup]="studyRateList.form">
  <div *ngIf="studyRateList.isform || studyRateList.isView()" class="card ">
    <div class="grid fluid">
      <div *ngIf="sysService.isRad() || sysService.isRadAdmin() || sysService.isAdmin()"
        class="field col-12 md:col-12 lg:col-6">
        <label for="Title">Centre </label>
        <input type="text" pInputText id="Title" maxlength="50" formControlName="centreName" pAutoFocus
          [autofocus]="true" readonly />
      </div>
      <div *ngIf="sysService.isCenter() || sysService.isCenterAdmin() || sysService.isAdmin()"
        class="field col-12 md:col-12 lg:col-6">
        <label for="Title">Radiologist </label>
        <input type="text" pInputText id="Title" maxlength="50" formControlName="radiologistName" readonly />
      </div>
      <div class="field col-12 md:col-12 lg:col-6">
        <label for="Title">Study description </label>
        <input type="text" pInputText id="Title" maxlength="50" formControlName="studyDesc" pAutoFocus
          [autofocus]="true" readonly />
      </div>
      <div class="field col-12 md:col-12 lg:col-6">
        <label for="Title">Modality</label>
        <input type="text" pInputText id="Title" maxlength="50" formControlName="modalityCode" pAutoFocus
          [autofocus]="true" readonly />
      </div>
      <div class="field col-12 md:col-6 lg:col-6 ">
        <label>My Template </label>
        <p-autoComplete forceSelection="true" formControlName="reportTemplateId" [suggestions]="reportTemplateLookup"
          (completeMethod)="filterReport($event)" (onSelect)="templateid($event)" field="name" ariaLabel="bodyPart"
          [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400" maxlength="50">
        </p-autoComplete>
      </div>
    </div>
    <div class="pb-3 formgrid text-center">
      <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" class="p-button-outlined p-button-info"
        (click)="listView(studyRateList.isEmptyList());"></button>
      <button pButton pRipple type="button" label=" Save" icon="pi pi-check" class="p-button-raised p-button-success"
        (click)="saveRateCardList()" [disabled]="sysService.loading" *ngIf="studyRateList.isForm()">
        <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
      </button>
    </div>
  </div>
</form>
<div class="card grayCard" *ngIf="!studyRateList.isEdit()">
  <form [formGroup]="studyRateList.form" class="fullform mt-3">
    <div class="grid ">
      <div class=" field col-12 md:col-3 lg:col-2 m-2" *ngIf="this.sysService. isCenter()||this.sysService.isAdmin()">
        <span class="p-float-label">
          <p-autoComplete forceSelection="true" formControlName="radName" field="name" [suggestions]="radLookup"
            (completeMethod)="filterRad($event)" Label="id" [dropdown]="true" [autoHighlight]="true"
            showEmptyMessage="true" delay="400" maxlength="50">
          </p-autoComplete>
          <label for="float-input">Radiologist</label>
        </span>
      </div>
      <div class=" field col-12 md:col-3 lg:col-2 m-2" *ngIf="this.sysService. isRad()||this.sysService.isAdmin()">
        <span class="p-float-label">
          <p-autoComplete forceSelection="true" formControlName="cenId" field="name" [suggestions]="cenLookup"
            (completeMethod)="filterCen($event)" ariaLabel="bodyPart" [dropdown]="true" [autoHighlight]="true"
            showEmptyMessage="true" delay="400" maxlength="50">
          </p-autoComplete>
          <label for="float-input">Centre</label>
        </span>
      </div>
      <div class=" field col-12 md:col-3 lg:col-2 m-2">
        <span class="p-float-label">
          <p-autoComplete forceSelection="true" formControlName="modality" field="name" [suggestions]="modLookup"
            (completeMethod)="filterMod($event)" ariaLabel="bodyPart" [dropdown]="true" [autoHighlight]="true"
            showEmptyMessage="true" delay="400" maxlength="50">
          </p-autoComplete>
          <label for="float-input">Modality</label>
        </span>
      </div>
      <div class=" field col-12 md:col-3 lg:col-2 m-2">
        <span class="p-float-label">
          <p-autoComplete forceSelection="true" formControlName="studyDesc" field="name" [suggestions]="studyDesc"
            (completeMethod)="filterStudyDesc($event)" ariaLabel="bodyPart" [dropdown]="true" [autoHighlight]="true"
            showEmptyMessage="true" delay="400" maxlength="120">
          </p-autoComplete>
          <label for="float-input">Study Description</label>
        </span>
      </div>
      <div class="field mt-4">
        <button pButton pRipple type="button" (click)="searchTemplate();dt.reset()" label=" Search"
          class="p-button-raised p-button-info" [disabled]="sysService.loading">
          <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
        </button>
        <button pButton pRipple type="button" (click)="resetRateCardList();dt.reset()" label=" Reset"
          class="p-button-raised p-button-text" [disabled]="sysService.loading">
          <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
        </button>
      </div>
    </div>
  </form>
</div>
<div class="table-responsive" [style.display]="studyRateList.islist ? 'block':'none'">
  <p-table #dt [value]="studyRateList.list" class="study-list-class" [scrollable]="true" [scrollHeight]="heightTable"
    [totalRecords]="studyRateList.total" [lazy]="true" (onLazyLoad)="listStudyRateListLazy($event)" dataKey="studyId"
    [(selection)]="studyRateList.selection" selectionMode="multiple" [loading]="sysService.loading" filterDelay="400"
    styleClass="p-datatable-sm p-datatable-gridlines" currentPageReportTemplate="{first} to {last} of {totalRecords}"
    [rowsPerPageOptions]="[25, 50]" [rowHover]="true" [rows]="25" [paginator]="true" [showCurrentPageReport]="true"
    alwaysShow="false" paginatorPosition="bottom"
    [globalFilterFields]="['name','radiologistName','rate','description']">
    <ng-template pTemplate="caption">
      <div class="ml">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input pInputText type="text" maxlength="150" (input)="dt.filterGlobal($event.target.value,
                                  'contains')" placeholder="Search..." pAutoFocus [autofocus]="true" />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
      <button pButton pRipple type="button" label="Delete" icon="pi pi-trash" class="p-button-outlined p-button-danger"
        (click)="deleteStudyRateLists()"
        *ngIf="!studyRateList.isEmptyList() && studyRateList.selection.length > 0"></button>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="u.name"
          *ngIf="sysService.isCenter() || sysService.isCenterAdmin() || sysService.isAdmin()">Radiologist <p-sortIcon
            field="u.name"></p-sortIcon>
        </th>
        <th pSortableColumn="c.name" *ngIf="sysService.isRad() || sysService.isRadAdmin() || sysService.isAdmin()">
          Centre <p-sortIcon field="c.name"></p-sortIcon>
        </th>
        <th pSortableColumn="modality">Modality <p-sortIcon field="modality"></p-sortIcon>
        </th>
        <th pSortableColumn="o.study_desc">Study Description<p-sortIcon field="o.study_desc"></p-sortIcon>
        </th>
        <th pSortableColumn="t.name">My Templates<p-sortIcon field="t.name"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-studyRateList>
      <tr>
        <td *ngIf="sysService.isCenter() || sysService.isCenterAdmin() || sysService.isAdmin()">
          {{studyRateList?.radiologistName}}
        </td>
        <td *ngIf="sysService.isRad() || sysService.isRadAdmin() || sysService.isAdmin()">
          {{studyRateList?.centreName}}</td>
        <td>{{studyRateList.modalityCode}}</td>
        <td>{{studyRateList.studyDesc}}</td>
        <td *ngIf="sysService.isRad()"><i class="pi pi-file-edit link-study" (click)="edit(studyRateList)" title="edit">
          </i> &nbsp;
          {{studyRateList.reportTemplateName}}
        </td>
        <td *ngIf="!sysService.isRad()">{{studyRateList.reportTemplateName}}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td class="norecord" [attr.colspan]="5">No records found</td>
      </tr>
    </ng-template>
  </p-table>
</div>