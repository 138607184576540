import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Validators, FormBuilder } from "@angular/forms";
import {
  StaticService,
  SysService,
  FormDefault,
  ViewFactory,
  ViewAction,
} from "@app/_common";
import { Model, View } from "@app/_common/";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationService, LazyLoadEvent, MenuItem } from "primeng/api";
import { AppConstants } from "@app/_config";
import { DatePipe } from "@angular/common";
import { Hl7Service } from "@app/_common/services/hl7.service";
import { CANCELLED } from "dns";
import { environment } from "@environments/environment";
@Component({
  selector: "app-hl7",
  templateUrl: "./hl7-interface.component.html",
  styleUrls: ["./hl7-inferface.component.scss"],
  providers: [DatePipe],
})
export class Hl7InterfaceComponent implements OnInit, ViewAction {
  private hl7View: View = this.viewFactory.newView();
  smartFilter: any;
  filterSearch = false;
  items: MenuItem[];
  hl7Data: any;
  dateRange;
  loadingData: boolean = false;
  filterByStudyStatusCode: any = 1;
  StudyCountDetails: any;
  calendarSelected = false;
  selectedRange: Date[];
  saveButtonLabel: string = "Save";
  countryLookup: any[];
  visible: boolean;
  hisDetails: boolean;
  hl7Show: any;
  modalityLookup: any[];
  accessionNo: string;
  patId: string;
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    public hl7Service: Hl7Service,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory,
    private datePipe: DatePipe,

    private confirmationService: ConfirmationService
  ) {}
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.accessionNo = params["accessionNo"];
      this.patId = params["patId"];
      this.hl7View.of(this.hl7Model());
      this.hl7Show = environment.Hl7;
    });
  }
  get hl7() {
    return this.hl7View.model;
  }

  private hl7Model() {
    const formDefault = new FormDefault(["status"], [1]);
    return new Model(
      this.formBuilder.group({
        id: [""],
        mrn: [""],
        patName: [""],
        age: [""],
        sex: [""],
        type: [""],
        procedureCode: [""],
        procedureName: [""],
        orderedDate: [""],
        status: [""],
        refPhysician: [""],
        actions: [""],
        dob: [""],
        visitNo: [""],
        visitDate: [""],
        consultingDoc: [""],
        refDoc: [""],
        speciality: [""],
        ward: [""],
        bedNo: [""],
        priority: [""],
        clinicalInfo: [""],
        diagnosis: [""],
        allergy: [""],
        orderId: [],
        equipmentId: [],
        modalityId: [],
        hisOrderId: [],
        ageSex: [],
        EquipmentId: [],
        smartFilter: [],
        modalityCode: [],
        cancelRemarks: [""],
        equipment: [""],
        accessionNo: [""],
      }),
      this.sysService,
      formDefault,
      ""
    );
  }

  add() {
    this.hl7View.toNew();
  }
  report(hl7Interface: any) {
    this.sysService.clear()
    const pdfFilename = `${hl7Interface?.patName}/${hl7Interface?.mrn}/${hl7Interface?.studyDate}.pdf`;

    this.hl7View.downloadOpen(
      `/his/report-pdf-download?studyId=${hl7Interface?.studyId}&repRadId=${hl7Interface?.repRadId}&accessionNo=${hl7Interface?.accessionNo}&studyIuid=${hl7Interface.studyIuid}`,
      pdfFilename
    );
  }
  ohifViewer(hl7Interface: any) {
    this.sysService.clear()
    if (hl7Interface?.studyId) {
      let url = `${environment.viewer.ohif}/viewer?StudyInstanceUIDs=${hl7Interface.studyIuid}`;
      window.open(url);
    } else {
      this.sysService.error(`${hl7Interface?.patName} study is not available`);
    }
  }

  openWeasis(hl7Interface: any) {
    this.sysService.clear()
    if (hl7Interface?.studyId) {
      location.href = `${environment.viewer.weasis}/weasis-pacs-connector/weasis?studyUID=${hl7Interface.studyIuid}&cdb&cdb-ext`;
    } else {
      this.sysService.error(`${hl7Interface?.patName} study is not available`);
    }
  }

  edit(id: any) {}

  list(id: any) {
    this.listView(false);
  }

  async listhl7Lazy(event?: LazyLoadEvent, search: boolean = false) {
    this.hl7View.toListLazyFetch(
      `/his/studies?accessionNo=${this.accessionNo}&patId=${this.patId}`,
      search,
      event
    );
  }
  listView(search: boolean = false) {}
  view(id: any) {}
}
