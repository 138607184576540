import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model, ViewAction } from '@app/_common';
import { AppConstants } from '@app/_config/app.constants';
import { environment } from '@environments/environment';
import { LazyLoadEvent } from 'primeng/api';
@Component({
  selector: 'app-assign-reject-report',
  templateUrl: './assign-reject-report.component.html',
  styleUrls: ['./assign-reject-report.component.css'],
  providers: [DatePipe]
})
export class AssignRejectReportComponent {
  @ViewChild("calendarRef") calendar: any;
  calendarSelected = false;


  ReportRejectListExport = ['name', 'taxCode', 'code', 'countryId.name']; //for export header fields
  private ReportRejectView: View = this.viewFactory.newView();
  flag: boolean
  maxDate: Date;
  eventData: any;
  countryLookup: any[];
  stateLookup: any[];
  stateLookupAll: any[];
  imageUrl: string = '/assets/images/rejected-stamp.png';
  currentDateTime: string;
  hasSignature: boolean = false;
  hasStamp: boolean = false;
  signature: any;
  stamp: any;
  modalityLookup: any[];
  search: boolean = false;
  selectedRange: Date[];
  modLookup: any[];
  studyDesc: any[];
  userLookup: any[];
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private datePipe: DatePipe,
    private viewFactory: ViewFactory) {
    const currentDate = new Date();
    this.currentDateTime = currentDate.toLocaleString();
  }
  ngOnInit() {
    this.scrollHeight()
    this.detectZoomChange()

    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 400));

    this.ReportRejectView
      .of(this.ReportRejectListModel());
    this.maxDate = new Date();
  }
  get ReportReject() {
    return this.ReportRejectView.model;
  }
  private ReportRejectListModel() {
    const formDefault = new FormDefault(['status'], [1,]);
    return new Model(this.formBuilder.group({
      seriesCount: [],
      instanceCount: [],
      accessionNo: [""],
      patName: [""],
      patSex: [""],
      studyDesc: [""],
      modality: [],
      studyIuid: [""],
      dateType: [""],
      date: [""],
      patId: [""],
      Institutionname: [""],
      ReportingRadName: [""],
      status: [""],
      srNo: [],
      studyId: [],
      emergency: [],
      showReport: [],
      showDownload: [],
      rejectedBy: [],
      rejectedDate: [],
      rejUserId: [],
      reportIds: [''],
    }), this.sysService, formDefault, '');
  }
  add() {
    this.ReportRejectView.toNew();
    this.flag = false;
  }
  edit(id: any) {
    this.ReportRejectView.toEditFetch(`/reports/rejected-reports/${id}`, id);
  }
  list(id: any) {
    this.listView(false);
  }
  async listReportRejectLazy(event?: LazyLoadEvent, search: boolean = false) {
    this.eventData = event
    this.ReportReject.selection = [];
    const rangeDate = String(this.ReportReject.get("date"));
    const startDate = new Date(rangeDate.split(",")[0]);
    const endDate = new Date(rangeDate.split(",")[1]);
    const formattedStartDate = `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() +
      1).toString().padStart(2, '0')}/${startDate.getFullYear()}`;
    const formattedEndDate = `${endDate.getDate().toString().padStart(2, '0')}/${(endDate.getMonth() +
      1).toString().padStart(2, '0')}/${endDate.getFullYear()}`;
    const date = `${formattedStartDate}-${formattedEndDate}`;
    this.ReportReject.setParam("modality", this.ReportReject.get("modality")?.name)
    this.ReportReject.setParam("studyDesc", this.ReportReject.get("studyDesc")?.name)

    this.ReportReject.setParam("rejUserId", this.ReportReject.get("rejUserId")?.id)
    this.ReportReject.setParam("date", date)
    this.ReportRejectView.toListLazyFetch(`/reports/rejected-reports?isAssignedRejected=true`, this.search, event);
    this.search = false;
  }
  listView(search: boolean = false) {
    this.ReportRejectView.toListLazyView(`/reports/rejected-reports?isAssignedRejected=true`, search);
  }
  filteruser(event: any) {
    this.ReportRejectView.getData(`/users/associated-lookup?filter=${event.query}`).then(data => {
      this.userLookup =
        data;
    });
  }
  view(id: any) {
    this.ReportRejectView.toViewFetch(`/reports/rejected-reports/${id}`, id);
  }
  deleteReportRejects() {
    this.ReportRejectView.toDeleteSelection(`/reports/rejected-reports`, {
      onSuccess: () => {
        this.ReportRejectView.toListLazyFetch(`/reports/rejected-reports?isAssignedRejected=true`, true, this.eventData);
      }
    });
  }
  deleteReportReject(id: any) {
    this.ReportRejectView.toDeleteList(`/reports/rejected-reports/${id}`, "id", id);
  }
  saveReportReject(add: boolean = false) {
    if (add) {
      this.ReportRejectView.toSaveNewFetch("/reports/rejected-reports", "id");
    } else {
      this.ReportRejectView.toSaveListFetch("/reports/rejected-reports", "id", this.ReportReject.isEdit() ? "Report  Updated Successfully." : "Report  Saved Successfully.");
    }
  }
  onDateSelect(selectedDate: any) {
    this.ReportReject.set('month', null)
    this.ReportReject.set('year', null)
  }
  viewer(ReportReject: any, type: number = 1) {
    if (type === 1) {
      //ohif viewer
      window.open(`/ecscribeviewer?siuid=${ReportReject.studyIuid}`);
    } else if (type === 2) {
      //weasis viewer
      window.open(
        `${environment.viewer.weasis}/weasis-pacs-connector/weasis?studyUID=${ReportReject.studyIuid}&cdb&cdb-ext`
      );
    }
  }
  filterMod(event: any) {
    this.ReportRejectView.getData(`/modality/modality-lookup?filter=${event.query}`).then(data => {
      this.modLookup =
        data;
    });
  }

  filterStudyDesc(event: any) {
    this.ReportRejectView.getData(`/raddetails/studydesc-lookup?filter=${event.query}`).then(data => {
      this.studyDesc =
        data;
    });
  }
  filterState(event: any) {
    this.stateLookup = this.stateLookupAll?.filter(x => x.name.toLowerCase().indexOf(event.query.toLowerCase()) === 0);
  }
  loadState(countryId) {
    this.ReportRejectView.getData(`/state/lookupbycountry?countryId=${countryId}`).then(data => {
      this.stateLookupAll = data;
      this.stateLookup = data;
    });
  }
  stateAreaOnChange(event: any) {
    this.loadState(event.id)
    this.ReportReject.set("stateId", null)
  }
  resetPayable() {
    this.ReportReject.reset();
    this.sysService.clear();
    this.search = false


  }
  onCountryChange(event) {
  }
  downloadExcel(ReportReject: any) {


    const rangeDate = String(this.ReportReject.get("date"));
    const startDate = new Date(rangeDate.split(",")[0]);
    const endDate = new Date(rangeDate.split(",")[1]);
    const formattedStartDate = `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() +
      1).toString().padStart(2, '0')}/${startDate.getFullYear()}`;
    const formattedEndDate = `${endDate.getDate().toString().padStart(2, '0')}/${(endDate.getMonth() +
      1).toString().padStart(2, '0')}/${endDate.getFullYear()}`;
    const date = `${formattedStartDate}-${formattedEndDate}`;
    this.ReportReject.setParam("modality", this.ReportReject.get("modality")?.name)
    this.ReportReject.setParam("studyDesc", this.ReportReject.get("studyDesc")?.name)

    this.ReportReject.setParam("rejUserId", this.ReportReject.get("rejUserId")?.id)
    this.ReportReject.setParam("date", date)

    if (this.ReportReject.selection.length > 0) {
      const ids = this.ReportReject.selection.map(item => item.id);
      this.ReportReject.setParam("reportIds", ids)
    }
    const excelName = 'Assigned_rejected_report.xls';
    this.ReportRejectView.download(`/reports/download-rejected-reports?isAssignedRejected=true `, excelName);
    this.ReportReject.setParam("reportIds", "");


  }
  searchReport() {
    this.search = true;
  }
  filterCountry(event: any) {
    this.ReportRejectView.getData(`/country/lookup?filter=${event.query}`).then(data => { this.countryLookup = data; });
  }
  Report(ReportReject: any) {
    window.open(`/ReportReject?-download?siuid=${ReportReject.studyIuid}`);
  }
  printReport(ReportReject: any) {
    ReportReject.exportPdf_options = {
      header_html: '<div class="styled">Header content</div>',
      footer_html:
        '<div class="styled-counter"><span class="pageNumber"></span></div>',
      header_and_footer_css:
        ".styled { font-weight: bold; padding: 10px; } .styled-counter { font-size: 1em; color: hsl(0, 0%, 60%); }",
      margin_top: "2cm",
      margin_bottom: "2cm",
    };
    const element =
      document.getElementById("cke_1_contents") ||
      document.createElement("div");
    var printContents = element.innerHTML.replace("Press ALT 0 for help", "");
    var popupWin = window.open();
    let status = ReportReject.status;
    let watermark = ''
    let fileName = `${ReportReject?.patName}/ ${ReportReject?.patId ?? ""}/${this.sysService.toDate1(ReportReject?.studyDate) ?? ""}`;
    fileName = fileName.replace(/\./g, "");
    if (status === 0 || status === null) {
      watermark = " Rejected Report";
    } else if (status === 1) {
      watermark = " Drafted Report";
    } else if (status === 3) {
      watermark = " Unapproved Report";
    }
    else if (status === 4) {
      watermark = " Unapproved";
    }
    else if (status === 100) {
      watermark = "  Report Template ";
    }
    const name = ReportReject.patName ?? "";
    let capitalizedFirstName;
    if (name) {
      capitalizedFirstName = name.charAt(0).toUpperCase() + name.slice(1);
    }
    let disclaimer = '';
    if (ReportReject?.disclaimer) {
      disclaimer = `<tr class="b-t" >
        <td colspan="4">
        <p ><i>${ReportReject?.disclaimer ? 'Disclaimer: ' + ReportReject?.disclaimer : ''}<i></p>
        </td>
      </tr>`
    }
    let footerPat = '';
    footerPat = `${ReportReject?.patName} / ${ReportReject?.patId ?? ""}`
    footerPat += ` (Printed: ${this.sysService.formatDateTime() ?? ""})`
    if (popupWin) {
      popupWin.document.write(
        `<html lang="en">        
        <head>
          <meta charset="UTF-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>${ReportReject?.patName}/ ${ReportReject?.patId ?? ""}/${this.sysService.toDate1(ReportReject?.studyDate) ?? ""}</title>
        </head>
        <style>
        @page {
          size: auto;
          counter-increment: page;         
          ${(ReportReject?.isReportType === true) ?
          `margin: ${ReportReject?.marginTop}px ${ReportReject?.marginLeft}px ${ReportReject?.marginBottom}px ${ReportReject?.marginRight}px;`
          :
          `margin: 100px ${ReportReject?.marginLeft}px ${ReportReject?.marginBottom}px ${ReportReject?.marginRight}px;`
        }
        }        
        body {
          margin: 0;
          padding: 0;
        }
        .mb-2{
            border-bottom:2px solid #000;
            font-size:14px;
            padding:8px 0px;
        }
          table {                  
            width: 98%;
            text-align: left;                  
          }  
          table img {
            max-width: 100%;
            height: auto !important;
          }  
          .watermark {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-40deg);
            opacity: 0.2;
            color: #808080;
            height: 50px;
            width: 100%;
            font-size: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }                  
          thead {
            display: table-header-group;
            margin-bottom: 20px;
          }
            thead.noRepeat{
              display: table-row-group;
          }
          ${(ReportReject.isPatientDetailsAllPage === true) ? `
          thead.noRepeat tr:first-child th{                       
            padding-top: ${ReportReject?.marginTop >= 100 ? ReportReject?.marginTop - 100 : ReportReject?.marginTop}px;                       
          }`
          : ''
        }
        .details{
          display:none;
        }                           
        .b-t {                  
          border-top: 2px solid gray;
        }                  
        thead tr th {
          padding-right: 10px;
          height: 20px;
          font-weight: normal;
          font-size:14px;
          padding:8px 0px;
        }
        .page-number{
          position: fixed;
          bottom: 0px;
          left: 0px;
          width: 100%;
          text-align: right;
        }
      .page-number:: after {
        position: absolute;
        right: 0px;
        left: 0px;
        margin:auto;
        bottom:20px;
        font-size:14px;
        content: counter(page);
      }
        tfoot {
          display: table-footer-group;
        }                  
        tfoot tr td {
          text-align: center;                      
        }                  
        table,
        td,
        th {
          border-collapse: collapse;                  
        }                  
        .text-right {
          text-align: right;
        }                         
        .stamp {
          width: 150px;
          max-width: 150px;
          height: auto;
        }
        .hthead {
          width:auto;
          border-top: 1px solid black;
          border-right: 1px solid black;
          border-left: 1px solid black;
          border-bottom: 1px solid black;           
        }       
        .phead{
          width:180px;
          padding:4px;
          font-weight: bold;
          white-space: nowrap;
        }  
        .phead2{
          width:98%;
          padding:4px;
        }   
        .phead3{
          width:160px;    
          padding:4px;     
          white-space: no-wrap; 
        }   
        .bold{
          font-weight: bold;
          white-space: nowrap;
        }  
      </style>
        
        <body>
          <div class="watermark">
            <b id="status">Rejected Report</b>
        </div>
          <div class="mainWrap">
            <table>
            
              <thead class="${ReportReject?.isReportType === true ? 'hthead' : 'hthead noRepeat'}" >              
              <tr>
              <td class="phead">PATIENT NAME</td><td class="phead2" colspan="3">: ${ReportReject?.patName ?? ""}</td>       
              </tr>
              <tr>
                <td class="phead">PATIENT ID</td><td class="phead2" >:${ReportReject?.patId ?? ""}</td>
                <td class="phead3 bold">AGE/SEX</td><td class="phead3" >:${ReportReject?.patAge ? ReportReject.patSex ? `${ReportReject.patAge}/${ReportReject.patSex}` : ReportReject.patAge : ReportReject.patSex || ""}</td>            
              </tr>
              <tr>
                <td class="phead">REFERRED BY</td><td class="phead2">: ${ReportReject?.refPhysicianName ?? ""}</td>
                <td class="phead3 bold">DATE</td><td class="phead3">:${this.sysService.toDate1(ReportReject?.studyDate) ?? ""}</td>             
              </tr>           
            </thead>               
            <tbody>
            <tr>                        
              <td colspan="4" >
              ${ReportReject?.report} <br />      
              </td>
            </tr>                  
            <tr>
              <td colspan="4" class="text-right">
                ${this.signature ? ` <img src="${this.signature}" alt="sign" class="stamp"/>` : ''}
                <p style="margin:0px;padding:0px;">${ReportReject.repRad ?? ""}  </p>
                <p style="margin:0px;padding:0px;">${ReportReject.qualification ?? ""}</p>
                <p style="margin:0px;padding:0px;">${ReportReject?.designation ?? ""}</p>
                <p style="margin:0px;padding:0px;">${ReportReject?.regNo ?? ""}</p>                  
                ${this.stamp ? ` <img src="${this.stamp}" alt="stamp" class="stamp"/>` : ''}
              </td>
            </tr>
              ${disclaimer}             
          </tbody>
          <tfoot > 
          <tr >
            <td colspan="4" style="text-align:left">
            <span  ${ReportReject.isPatientDetailsAllPage === true ? '' : 'class="deatils"'}>                            
            ${footerPat} </span>
            </td>                  
          </tr>
        </tfoot>           
            </table>        
          </div>        
        </body>        
        </html>
     <script>
               // const disclaimerContent = document.querySelector('#disclaimerRow p').textContent.trim();
          // if (disclaimerContent === '') {
          //   document.querySelector('#disclaimerRow').style.display = 'none';
          // }              
  
            const totalPages = document.querySelectorAll('.mainWrap').length;
            const pageNumbers = document.querySelectorAll('.page-num');
            const totalPageElements = document.querySelectorAll('.total-pages');
          
            for (let i = 0; i < pageNumbers.length; i++) {
              pageNumbers[i].textContent = i + 1;
              totalPageElements[i].textContent = totalPages;
            }
  
          var is_chrome = function () {
            return Boolean(window.chrome);
          };      
          if (is_chrome()) {
            setTimeout(function () {
              window.print();
            }, 100);
            setTimeout(function () {
              window.close();
            }, 200); // Give them 100 milliseconds (0.1 second) to print, then close
          } else {
            window.print();
            window.close();
          }
        </script>`
      );
      popupWin.document.close();
    }
  }
  reportrejectex(ReportReject: any) {

    this.ReportRejectView.setParam('radId', ReportReject.reportedRadId)
    this.ReportRejectView.setParam('isUserId', false)

    if (ReportReject?.isSignaturePresent && ReportReject?.isStampPresent) {
      this.ReportRejectView.image(`/users/rad-signature`).subscribe(byte => {
        this.hasSignature = byte.byteLength > 0;
        this.signature = this.sysService.arrayBufferToBase64ImageSource(byte, ReportReject?.signatureExt);
        this.ReportRejectView.image(`/users/rad-stamp`).subscribe(byte => {
          this.hasStamp = byte.byteLength > 0;
          this.stamp = this.sysService.arrayBufferToBase64ImageSource(byte, ReportReject?.stampExt);
          this.printReport(ReportReject);
        });
      });
    } else if (!ReportReject?.isSignaturePresent && ReportReject?.isStampPresent) {
      this.hasSignature = false;
      this.signature = null;
      this.ReportRejectView.image(`/users/rad-stamp`).subscribe(byte => {
        this.hasStamp = byte.byteLength > 0;
        this.stamp = this.sysService.arrayBufferToBase64ImageSource(byte, ReportReject?.stampExt);
        this.printReport(ReportReject);
      });
    } else if (ReportReject?.isSignaturePresent && !ReportReject?.isStampPresent) {
      this.ReportRejectView.image(`/users/rad-signature`).subscribe(byte => {
        this.hasSignature = byte.byteLength > 0;
        this.signature = this.sysService.arrayBufferToBase64ImageSource(byte, ReportReject?.signatureExt);
        this.hasStamp = false;
        this.stamp = null;
        this.printReport(ReportReject);
      });
    } else if (!ReportReject?.isSignaturePresent && !ReportReject?.isStampPresent) {
      this.hasSignature = false;
      this.signature = null;
      this.hasStamp = false;
      this.stamp = null;
      this.printReport(ReportReject);
    }
  }
  changesCalendarClear(dt) {
    this.calendarSelected = false;

    this.ReportReject.reset()
    this.search = true;
    this.calendar.hideOverlay();
  }
  dateRange;
  calenedarChangeDate(event, dt) {
    this.dateRange = "";
    if (this.selectedRange) {

      const startDate = this.datePipe.transform(
        this.selectedRange[0],
        "dd/MM/yyyy"
      );
      const endDate = this.datePipe.transform(
        this.selectedRange[1],
        "dd/MM/yyyy"
      );
      this.dateRange = `${startDate}-${endDate}`;
      this.listReportRejectLazy(dt);
    }
    this.calendar.hideOverlay();
  }
  heightTable: any;
  debounceTimeout: any;
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 390) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 390) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  detectZoomChange() {
    this.scrollHeight();
  }
}
