import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SysService } from '@app/_common';
import { AppConfig } from '@app/_config/';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    public sysService: SysService
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.sysService.isLoggedIn() !== true) {
      this.sysService.error('Access Denied, Login is Required to Access This Page!');
      this.sysService.navigate([AppConfig.login], false, {
        queryParams: { returnUrl: state.url }
      });
      //return false;
    }
    return true;
  }

}
