<div *ngIf="!sysService.isPermission() ">
    <not-found></not-found>
</div>
<div *ngIf="sysService.isPermission() ">
    <p-tabView [(activeIndex)]="activeTabIndex" (onChange)="onTabChange($event)">
        <p-tabPanel  header=" REPORT TEMPLATE">
            <app-report-template *ngIf="activeTabIndex ===0"></app-report-template>
        </p-tabPanel>
        <p-tabPanel *ngIf="!sysService.isRadSub()"  header=" USED TEMPLATE">
            <app-my-study-rates *ngIf="activeTabIndex ===1"></app-my-study-rates>
        </p-tabPanel>
    </p-tabView>
</div>