import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute, NavigationExtras } from "@angular/router";
import { MenuItem } from "primeng/api";
import { FormDefault, Model } from "../components/model.view";
import { View } from "../components/view.component";
import { ViewFactory } from "../components/view.factory.component";
import { SysService } from "../services/sys.service";
import { UserService } from "../services/user.service";
import { Router } from "@angular/router";
import { environment } from 'src/environments/environment';

type AppTheme = {
  theme: string;
  logo: string;
};
interface City {
  name: string;
  code: string;
}

@Component({
  selector: "f-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class AppHeaderComponent implements OnInit {
  cities: City[];
  menus: MenuItem[] = [];
  sideNav: Element | undefined;
  wrapper: Element | undefined;
  environment: any;

  // items: MenuItem[];
  items: any[] = [];
  assignedToList: any = [];
  fileToUpload: File;

  private headView: View = this.viewFactory.newView();

  private themes: {
    [x: string]: any;
    www: AppTheme;
    dark: AppTheme;
    tcdirect: AppTheme;
    bc: AppTheme;
    c2g: AppTheme;
    primepay: AppTheme;
  } = {
      www: { theme: "theme-blue", logo: "logo.png" },
      dark: { theme: "theme-dark", logo: "logo.png" },
      tcdirect: { theme: "theme-gray", logo: "TCLogo.jpg" },
      bc: { theme: "theme-default", logo: "logo.png" },
      c2g: { theme: "theme-green", logo: "c2g90.png" },
      primepay: { theme: "theme-blue", logo: "NBPlogo.png" },
    };
  showHeader: boolean = true;
  teleradiologyCoordinator: any;
  constructor(
    private route: ActivatedRoute,
    public sysService: SysService,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private viewFactory: ViewFactory,
    private router: Router
  ) { }
  currency: string = '';
  rechargeValue: boolean = false;
  rechargeDisplay: boolean = false;
  ngOnInit(): void {
    this.currency = environment.currency;
    this.rechargeValue = environment.recharge;
    this.rechargeDisplay = environment.rechargeDisplay;
    this.headView.of(this.headModel(), false);
    this.showHeader = true
    this.router.events.subscribe((val) => {
      if (location.pathname === '/ecscribeviewer' || location.pathname === '/radiologistviewer') {
        this.showHeader = false;
      }
    });


  }

  logout(): void {
    this.sysService.signOut();
    this.items = [];
    this.menus = [];
  }

  get profile() {
    return this.headView.model;
  }
  setMenu(isLoggedIn: boolean) {
    if (isLoggedIn && this.menus.length === 0) {
      if (!this.sysService.isGuest()) {
        this.loadMenu();
      }

    }
  }
  user() {
    return this.sysService.getUser();
  }

  redirectToRecharge() {
    if ((this.sysService.isCenter() || this.sysService.isRad()) && this.rechargeValue) {
      this.router.navigateByUrl('/billing-dashboard?activeIndex=0');
    }
  }

  loadMenu() {


    this.menus = [
      {
        label: 'My Profile',
        icon: 'pi pi-user',
        command: (event) => {
          this.link('/profileview');
        },
      },
   
      {
        separator: true ,
        visible: this.sysService.isAdmin() || this.sysService.isCenter() || this.sysService.isRad() || this.sysService.isRadSub() || this.sysService.isTechical()

      },
      {
        label: 'Users',
        icon: 'pi pi-users',
        command: (event) => {
          this.link('/user-role');
        },
        visible: this.sysService.isAdmin() || this.sysService.isCenter()
      },
 
      {
        label: 'Secondary Users',
        icon: 'pi pi-sitemap',
        command: (event) => {
          this.link('/centre-user');
        },
        visible: this.sysService.isAdmin()
      },
      {
        separator: true ,
        visible: this.sysService.isAdmin()

      },
      

      {
        label: 'Modality',
        icon: 'pi pi-sitemap',
        command: (event) => {
          this.link('/modality');
        },
        visible: this.sysService.isAdmin()
      },     
      {
        label: 'Body Part',
        icon: 'pi pi-heart',
        command: (event) => {
          this.link('/bodypart');
        },
        visible: this.sysService.isAdmin()
      },
      {
        label: 'Equipment',
        icon: 'pi pi-id-card',
        command: (event) => {
          this.link('/equipment');
        },
        visible: this.sysService.isAdmin()
      },
    
      {
        label: 'Sub User',
        icon: 'pi pi-user',
        command: (event) => {
          this.link('/sub-user');
        },
        visible: this.sysService.isRad()

      },
      {
        label: 'My Rate',
        icon: 'pi pi-money-bill',
        command: (event) => {
          this.billing('/billing-dashboard', { activeIndex: 3 });
        },
        visible: this.sysService.isRad()


      },
      {
        label: 'Study Description',
        icon: 'pi pi-book',
        command: (event) => {
          this.link('/studydescription');
        },
        visible: this.sysService.isAdmin() || this.sysService.isRad() || this.sysService.isCenter() || this.sysService.isTechical() || (this.sysService.isRadSub() && this.sysService.getUser().userSettings?.isStudyDesc)
      },
      {
        separator: true ,
        visible: !this.sysService.isAdmin()
      },



    

      {
        label:  this.sysService.isRadSub() || this.sysService.isRad() ?  'My Rejected Studies' : 'Rejected Studies' ,
        icon: 'pi pi-ban',
        command: (event) => {
          this.link('/studies-reject');
        },
        visible: (this.sysService.isRadSub() && this.sysService.getUser().userSettings?.isRejectedStudies) || this.sysService.isRad() || this.sysService.isCenter() 
      },
      {
        label: this.sysService.isRadSub() || this.sysService.isRad() ?  '2° Rejected Report ': 'Assigned Reject Report',
        icon: 'pi pi-ban',
        command: (event) => {
          this.link('/assign-reject');
        },
        visible: (this.sysService.isRadSub() && this.sysService.getUser().userSettings?.isAssignedRejReport) || this.sysService.isRad()
      },
      {
        label: this.sysService.isRadSub() || this.sysService.isRad() ?  'My Rejected Report': 'Rejected Report',
        icon: 'pi pi-ban',
        command: (event) => {
          this.link('/reject-report');
        },
        visible: (this.sysService.isRadSub() && this.sysService.getUser().userSettings?.isRejectedReport) || this.sysService.isRad() || this.sysService.isCenter()
      },

   
    
      {
        separator: true ,
        visible: this.sysService.isAdmin() || this.sysService.isCenter() || this.sysService.isRadSub() || this.sysService.isRad()

      },
    
   
     
     
      // {
      //   label: 'Contact Support',
      //   icon: 'pi pi-phone',
      //   command: (event) => {
      //     this.link('/contact-us');
      //   },
      //   visible: !this.sysService.isAdmin()
      // },
      {
        label: 'Desktop client',
        icon: 'pi pi-desktop',

        command: (event) => {
          this.link('/desktop-clint');
        }, 

        

      }
      , {
        label: 'Local viewer',
        icon: 'pi pi-globe',

        command: (event) => {
          this.ohvfViewer();
        }, 

        

      },
      {
        separator: true 
      },
      { label: "Logout", icon: "pi pi-sign-out", command: (event) => { this.logout(); }, }
    ];
    
    this.items = [

      {
        label: 'Pending Studies',
        icon: 'custom-icon-Pending-Studies',
        command: (event) => {
          this.link('/studies');
        },
      },
      {
        label: 'Templates',
        icon: 'custom-icon-Template',

        command: (event) => {
          this.link('/template-dashboard');
        },
        visible: this.sysService.isRad() || this.sysService.isAdmin() || this.sysService.isRadSub()
      },
      {
        label: 'Centers',
        icon: 'custom-icon-Center',
        command: (event) => {
          this.link('/centres');
        },
        visible: this.sysService.isRad() || this.sysService.isAdmin() 

      },
      {
        label: 'Radiologist',
        icon: 'custom-icon-rad',
        command: (event) => {
          this.link('/Radiologistlist');
        },
        visible: this.sysService.isRad() || this.sysService.isAdmin() || this.sysService.isCenter()

        

      },  {
        label: 'Teams',
        icon: 'custom-icon-group',
        command: (event) => {
          this.link('/groups');
        },
        visible: this.sysService.isRad() || this.sysService.isAdmin() || this.sysService.isCenter() || this.sysService.isApprover()

        

      }, {
        label: 'Contacts',
        icon: 'custom-icon-contact',
        command: (event) => {
          this.link('/contacts');
        },
        visible: this.sysService.isRad() || this.sysService.isAdmin() || this.sysService.isCenter() || this.sysService.isPhysician()

        

      },
      {
        label: 'Short Code',
        icon: 'custom-icon-code',
        command: (event) => {
          this.link('/short-code');
        },
        visible: this.sysService.isRad() || (this.sysService.isRadSub() && this.sysService.getUser().userSettings?.isShortCode)

      },
       {
        label: 'Installation',
        icon: 'custom-icon-Installation',

        command: (event) => {
          this.link('/install');
        },
        visible: this.sysService.isRad() || this.sysService.isAdmin() || this.sysService.isCenter()

        

      }, {
        label: 'Insight',
        icon: 'custom-icon-Insight',

        command: (event) => {
          this.link('/turnaround');
        },
        visible: this.sysService.isRad() || this.sysService.isAdmin() || this.sysService.isCenter()

        

      }, {
        label: 'Billing',
        icon: 'custom-icon-Bil',

        command: (event) => {
          this.link('/billing-dashboard');
        },
        visible: this.sysService.isRad() || this.sysService.isAdmin() || this.sysService.isCenter()

        

      }, {
        label: 'HIS Order',
        icon: 'custom-icon-Hl7',

        command: (event) => {
          this.link('/hl7');
        },
        visible: this.sysService.isCenter() && environment.Hl7

        

      },
      {
        label: 'Support & Ticket',
        icon: 'custom-icon-Support',

        command: (event) => {
          this.link('/ticket');
        },
       

        

      },
  
      
        
        
    ];
    this.sysService.loadWalletBalance();
    this.sysService.loadUserName();
  }

  ohvfViewer() {
    {
      // https://dev-meddream-viewer.eclarityhealth.com/view.html

      window.open(`${environment.viewer.ohif}/local`,'_blank')
      // window.open(`${environment.viewer.meddream}/view.html`,'_blank')
    //   let url = '/assets/ohif-viewer/ohif/index.html'
    // window.open(url,'_blank')
      // window.open(`${environment.viewer.ohif}/local`, '_blank')
    }
  }







  link(path) {

    SysService.scrollTo(0);
    this.sysService.clear();
    this.router
      .navigateByUrl("/", { skipLocationChange: false })
      .then(() => this.router.navigate([path]));
  }

  billing(path: string, queryParams?: any) {
    SysService.scrollTo(0);
    this.sysService.clear();

    const navigationExtras: NavigationExtras = {
      skipLocationChange: false,
      queryParams: queryParams || {} // If queryParams is not provided, use an empty object
    };

    this.router.navigateByUrl("/", navigationExtras).then(() => this.router.navigate([path], navigationExtras));
  }

  toggleMenu(): void {
    this.sideNav = document.querySelectorAll(".sideNav")[0];
    this.wrapper = document.querySelectorAll("#wrapper")[0];

    if (this.sideNav === undefined || this.wrapper === undefined) {
      return;
    }

    if (this.sideNav.classList.contains("opened")) {
      this.sideNav.classList.remove("opened");
      this.wrapper.classList.remove("menuOpened");
    } else {
      this.sideNav.classList.add("opened");
      this.wrapper.classList.add("menuOpened");
    }
  }

  redirectToHome() {
    if (!this.sysService.isGuest()) {
      this.sysService.navigate(["/home"]);
      this.sysService.clear();
    }
  }

  private headModel(): Model {
    const formDefault = new FormDefault();
    return new Model(
      this.formBuilder.group({
        FileID: [],
      }),
      this.sysService,
      formDefault,
      ""
    );
  }



  onChange(event): void {
    this.headView.setParam("sessioncontext", event.value);
    this.sysService.siteContext = event.value;
    this.headView.getData("/site/change").then((data) => {
      const sel = this.sysService.siteList.filter(
        (d) => d.OwnerPartyID === this.sysService.siteContext
      );
      this.userService.changeSite(data, this.route, this.sysService);
      if (!this.headView.isEmptyList(sel)) {
        const site: any = this.themes[sel[0]?.subDomain?.toLowerCase()];
        if (site) {
          this.sysService.changeTheme(site?.theme, site?.logo);
        }
      }
    });
  }
  selectFile(files: FileList) {
    this.profile.setFile(files, "UserFile");
  }
  async profilePicture() {
    let data = await this.headView.toSaveList("/profile", "");
    if (data) {
      this.sysService.getUser().ProfilePic = data.ProfilePic;
    }
  }

}