<header class="{{showHeader ? 'header' : ''}}" *ngIf="sysService.isLoggedIn() && showHeader">
  <app-messages></app-messages>
  <div class="wrap text-left">
    <div class="flex header_sidebtn">
      <button #btn type="button" pButton icon="pi pi-bars" label="" (click)="menu.toggle($event)" class=""></button>
      <p-tieredMenu #menu [model]="items" [popup]="true"></p-tieredMenu>
      <div class="right">
        {{setMenu(sysService.isLoggedIn())}}
        <div>
          <a (click)="redirectToHome()"><img class="web" src="../../../assets/images/eclogoo.png"></a>
        </div>
      </div>

    </div>


    <a (click)="redirectToHome()"><img class="mob" src="../../../assets/images/logo.png"></a>
    <div class="left">
      <span
        *ngIf="(sysService.isCenter() || sysService.isRad() || (sysService.getUser().userSettings?.isBalanceDisplay && sysService.isRadSub())) &&(rechargeValue|| rechargeDisplay ) ">
        <a (click)="redirectToRecharge()">
          <div class="rgtBdr" *ngIf="sysService?.walletBalance?.balance === '0.0' ">
            <div class="balence zr">

              <div class="bln">
                <div class="amt"> Recharge </div>
              </div>
            </div>
          </div>
        </a>

        <a (click)="redirectToRecharge()">
          <div class="rgtBdr" *ngIf="sysService?.walletBalance?.balance !== '0.0'  ">
            <div class="balence">
              <div class="icon">

              </div>
              <div class="bln">
                <div class="amt"> {{sysService?.walletBalance?.balance}} </div>
              </div>
            </div>
          </div>
        </a>
      </span>
      <span>
        <span *ngIf="!sysService.isGuest()" class="userStl text-center">{{ sysService?.currentUser?.name | slice:0:30 }}
        </span>
        <span *ngIf="sysService.isGuest()" class="userStl text-center">Guest </span>

        <br>
        <div class="welcome  text-center">
          <span *ngIf="sysService.isRad()&& this.sysService.getUser().teleradiologyCoordinator ">
            (Teleradiologist)</span>

          <span *ngIf="sysService.isRad()&& !this.sysService.getUser().teleradiologyCoordinator  ">
            (Radiologist)</span>
          <span *ngIf="sysService.isCenter()">(Centre)</span>
          <span *ngIf="sysService.isAdmin()">(Admin)</span>
          <span *ngIf="sysService.isApprover()">(Approver)</span>
          <span *ngIf="sysService.isAssigner()">(Assigner)</span>
          <span *ngIf="sysService.isTechical()">(Technician)</span>
          <span *ngIf="sysService.isPhysician()">(Physician)</span>
          <span *ngIf="sysService.isGuest()">(Guest)</span>
          <span title="Sub User of {{ sysService.getUser().parentUser | slice:0:10 }}" *ngIf="sysService.isRadSub()">
            (Sub User of {{sysService.getUser().parentUser | slice:0:10}})
          </span>

        </div>
      </span>
      <a (click)="menu1.toggle($event)">
        <!-- <span class="userStl" *ngIf="sysService.isRad()" >{{ sysService?.currentUser?.radiologistId.prefix | slice:0:30 }}{{ sysService?.currentUser?.radiologistId.name | slice:0:30 }} &nbsp;</span> -->
        <!-- <span class="userStl">{{ sysService?.currentUser?.name | slice:0:30 }} &nbsp;</span> -->
        <!-- <p-menu #menu [model]="menus" [popup]="true"></p-menu> -->

        <p-menu #menu1 [model]="menus" [popup]="true" [autoZIndex]="false" [appendTo]="'body'">
        </p-menu>


        <button pButton pRipple type="button" type="button" class="p-button-rounded p-button-outlined" pButton
          icon="pi pi-user"></button></a>
    </div>
  </div>
</header>