import { Component, HostListener } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormDefault, Model, StaticService, SysService, View, ViewFactory } from '@app/_common';
import { AppConstants } from '@app/_config';
import { environment } from '@environments/environment';
import { ConfirmationService } from 'primeng/api';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.css']
})

export class UserRoleComponent {
  private userRoleView: View = this.viewFactory.newView();
  saveButtonLabel: string = 'Save';
  eventData: any;
  constructor(
    public sysService: SysService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public staticService: StaticService,
    private viewFactory: ViewFactory,
    public config: DynamicDialogConfig,  // used for dynamical popup
    private confirmationService: ConfirmationService,


  ) {
  }
  flag: boolean

  ngOnInit() {
    this.scrollHeight()
    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 400));
    this.detectZoomChange()

    this.userRoleView.of(this.userRoleModel());
  }
  get userRole() {
    return this.userRoleView.model;
  }
  private userRoleModel() {
    const formDefault = new FormDefault(['status'], [1]);
    return new Model(this.formBuilder.group({
      id: [''],
      name: ['', [Validators.required, Validators.pattern(/^(?! ).*/)]],
      email: ['', [Validators.required, Validators.pattern(AppConstants.regexEmail)]],
      phoneNo: ['', [Validators.required, Validators.pattern(AppConstants.regexPhonenumber), Validators.minLength(7)]],
      userChildRole: [, [Validators.required]],
      status: ['']

    }), this.sysService, formDefault, '');
  }

  add() {

    this.userRoleView.toNew();
    this.flag = false;
    this.saveButtonLabel = "Save";


  }
  edit(id: any) {
    this.userRoleView.toEditFetch(`/users/${id}`, id);
    this.flag = true;
    this.saveButtonLabel = "Update";

  }
  isScrollTable = window.innerWidth > environment.devices.tablet ? true : false;
  heightDisplay = window.innerHeight - 20 + "px";
  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.isScrollTable =
      window.innerWidth > environment.devices.tablet ? true : false;
    this.heightDisplay = window.innerHeight - 280 + "px";
  }
  list(id: any) {
    this.listView(false);
  }
  async listuserRoleLazy(event?: LazyLoadEvent, search: boolean = false) {
    this.eventData = event
    this.userRoleView.toListLazyFetch(`/users`, search, event);
  }
  listView(search: boolean = false) {
    this.userRoleView.toListLazyView(`/users`, search);
  }
  view(id: any) {
    this.userRoleView.toViewFetch(`/users/${id}`, id);
  }
  deleteuserRoles() {

    this.userRoleView.toDeleteSelection(`/users`, {

      onSuccess: () => {
        this.userRoleView.toListLazyFetch(`/users`, false, this.eventData);
      }
    });

  }
  deleteuserRole(id: any) {
    this.userRoleView.toDeleteList(`/users/${id}`, "id", id);
  }

  getUserRole(userRole, add) {

    let email = userRole.get("email");
    let userChildRole = userRole.get("userChildRole");

    if (this.userRoleView.isValid()) {
      if (userChildRole === 4) {

        this.userRoleView.getData(`/users/centre-users-isexists?email=${email}&userChildRole=${userChildRole}`)
          .then((data) => {
            this.alreadyExistsUserChecker(data, add);
          });

      } else {
        this.saveUserRole(add);

      }
    }
  }

  alreadyExistsUserChecker(data, add) {
    if (data.userExists === true) {
      this.confirmationService.confirm({
        header: 'Add Physician',
        message: 'Physician already exists. Do you want to add the physician as a contact?',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => {
          this.saveUserRole(add);
        }
      });
    }
    else {
      this.saveUserRole(add);

    }
  }

  saveUserRole(add = false) {
    if (add) {
      this.userRoleView.toSaveNewFetch("/users", "id");
      this.userRole.searchTxt;
    } else {
      this.userRoleView.toSaveListFetch(
        "/users",
        "id",
        this.userRole.isEdit() ? "User Successfully updated." : "User Successfully saved."
      );

      this.userRole.searchTxt;
    }
  }
  heightTable: any;
  debounceTimeout: any;
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 280) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 280) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  detectZoomChange() {
    this.scrollHeight();
  }

}



