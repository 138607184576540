<div class="container">
  <img class="logo" src="../../assets/images/logo.png" alt="Logo">
  <h1>404</h1>
  <h2>The page could not be found or you don't have permission to view it.  </h2>
  <h3 class="redirectColor">Redirecting in {{ countdown }} seconds to the {{ sysService.isGuest() ? 'viewer Page' : 'home page' }}.</h3>
  
  <div class="underline"></div> 

  <p>The resource that you are attempting to access does not exist or you don't have the necessary permissions to view it.</p>
  <p>Please contact your eClarity administrator via email: <a class="underline-link" href="mailto:support@ecscribe.com">support@ecscribe.com</a></p>
  <p> <a *ngIf="!sysService.isGuest()" [routerLink]="'/'" class="underline-link">Click here to go back to home</a>
    <a *ngIf="sysService.isGuest()" (click)="shareViewlink()" class="underline-link">Click here to go to Share Viewer</a>
  </div>
