<header class="header" *ngIf="!sysService.isLoggedIn()">
    <div class="wrap">
        <div class="right">
            <div class="rgtBdr ">
                <a (click)="navHome()"> <img class="web" src="../../../assets/images/eclogoo.png"></a>
            </div>
        </div>
    </div>
</header>
<div class="card">
    <div class="grid text-xl">
        <div class="field col-12 md:col-6 lg:col-12">

            <h1>About Us</h1>
            <p>We are a software company rethinking how teleradiology software is structured. Our unique combination
                of medical expertise and software development experience has allowed us to create a platform that is
                vastly different from other solutions and tailormade for the teleradiologist.<br>

                At eClarity, we understand the challenges and frustrations that come with radiologists wanting to
                step into teleradiology reporting. That's why we have created a unique and radical platform that
                streamlines the radiology workflow and makes it easier for radiologists to run independent
                teleradiology practice. We are constantly updating and improving our platform to ensure that it
                meets the latest industry standards and provides the best possible experience for our users.<br>

                At eClarity Health, we are committed to providing exceptional service and support to our clients. We
                understand the importance of timely and accurate diagnoses, and we work tirelessly to ensure that
                our platform is reliable and accessible to radiologists 24x7.<br>

                Contact us at <a href="mailto:hello@ecscribe.com">hello@ecscribe.com</a> today to learn more
                about how our platform can help you streamline
                your teleradiology workflow. With our teleradiology software, you can focus on what you do best:
                providing on-time life-saving reports to patients.<br>
            </p>
        </div>
    </div>
</div>