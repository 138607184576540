
export const environment = {
  production: true,
  devices: {
    tablet: 960
  },
  app: 'https://localhost:4200/',
  appApi: 'https://dev-api.eclarityhealth.com/api',
  appHL7: 'https://mirth-dev.eclarityhealth.com/api',
  appApiSock: 'https://dev-api.eclarityhealth.com',
  logApi: 'https://dev-api.eclarityhealth.com/api/log',
  stripe: 'pk_test_51MqoEgSFTTrUxBce9BzoT6EcXyXZ7NUt8ZZmTV4fuwsVeDmzh56K9mqDTbqwSmW0uNveW291DixpUPsMcAH6uYi500ZWJcsLuX',

  viewer: {
    meddream: "https://dev-meddream-viewer.eclarityhealth.com",
    ohif: "https://dev-viewer.eclarityhealth.com",
    weasis: 'https://demo.eclarityhealth.com'
  },
  currency: 'INR', //indian

  Hl7: true,
  ohif: true,
  meddream: false,
  weasis: true,
  recharge: true,
  viewerSharing: true,
  rechargeDisplay: true,
  hl7Authless: true,
  cashFree: false,
  viewerCombination: true,
  showRange: 2,
  studyDetele: true,


  backend: { springjwt: true, spring: false, php: false, node: false }, //first true will be applied make spring false and then make node true to use node app
  auth: {
    login: 'https://dev-api.eclarityhealth.com/api/auth/signin',  //Node js '/signin/' // Spring Boot build in '/oauth/token/'
    logout: 'https://dev-api.eclarityhealth.com/api/auth/logout',
    register: 'https://dev-api.eclarityhealth.com/api/auth/signup',
    clientId: '',  //Required for Spring boot, left empty for node js php
    clientSecret: ''  //Required for Spring boot, left empty for node js php
  },
  pageSize: 20,
  captchaSiteKey: '6LdbqxMkAAAAAHOFqbkYpM9BhQ8JZvQEU-kKRN4i'
};
