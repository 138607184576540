import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormDefault, Model, StaticService, SysService, View, ViewFactory, noPlusSignValidator, noWhitespaceValidator } from '@app/_common';
import { environment } from '@environments/environment';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-study-description',
  templateUrl: './study-description.component.html',
  styleUrls: ['./study-description.component.css']
})
export class StudyDescriptionComponent implements OnInit {

  studyDescriptionExport = ['name', 'taxCode', 'code', 'countryId.name']; //for export header fields

  private studyDescriptionView: View = this.viewFactory.newView();
  modalityLookup: any[];
  eventData: any;

  constructor(
    public sysService: SysService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public staticService: StaticService,
    private viewFactory: ViewFactory) {
  }

  ngOnInit() {
    this.scrollHeight()
    this.detectZoomChange()

    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 400));

    this.studyDescriptionView.of(this.studyDescriptionModel()).switchAction('/studydescription', this.route, this);
  }

  get studyDescription() {
    return this.studyDescriptionView.model;
  }

  private studyDescriptionModel() {
    const formDefault = new FormDefault(['status'], [1]);
    
    // Ensure formBuilder is properly initialized
    const formGroup = this.formBuilder.group({
        id: [],
        name: ['', [
            Validators.required,
            noWhitespaceValidator, 
            Validators.minLength(3), 
            Validators.pattern('^(?!.*\\+).+$')
        ]],
        modalityId: ['', [Validators.required]],
        status: [null, [Validators.required]],
    });
    
    return new Model(formGroup, this.sysService, formDefault, '');
}

  add() {
    this.studyDescriptionView.toNew();
  }

  edit(id: any) {
    this.studyDescriptionView.toEditFetch(`/studydescription/${id}`, id);
  }

  list(id: any) {
    this.listView(false);
  }

  async listStudyDescriptionLazy(event?: LazyLoadEvent, search: boolean = false) {
    this.eventData = event
    this.studyDescriptionView.toListLazyFetch(`/studydescription`, search, event);
  }

  listView(search: boolean = false) {
    this.studyDescriptionView.toListLazyView(`/studydescription`, search);
  }

  view(id: any) {
    this.studyDescriptionView.toViewFetch(`/studydescription/${id}`, id);
  }

  deleteStudyDescriptions() {
    this.studyDescriptionView.toDeleteSelection(`/studydescription`, {
      onSuccess: () => {
        this.studyDescriptionView.toListLazyFetch(`/studydescription`, true, this.eventData);
      }
    });

  }

  deleteStudyDescription(id: any) {
    this.studyDescriptionView.toDeleteList(`/studydescription/${id}`, "id", id);
  }

  saveStudyDescription(add: boolean = false) {
    if (add) {
      this.studyDescriptionView.toSaveNewFetch("/studydescription", "id");
      this.studyDescription.searchTxt;
    } else {
      this.studyDescriptionView.toSaveListFetch("/studydescription", "id", this.studyDescription.isEdit() ? "Study Description Successfully updated." : "Study Description Successfully saved.");

      this.studyDescription.searchTxt;
    }
  }




  filterModality(event: any) {
    this.studyDescriptionView.getData(`/modality/lookup?filter=${event.query}`).then(data => { this.modalityLookup = data; });
  }
  heightTable: any;
  debounceTimeout: any;
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 300) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 300) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  detectZoomChange() {
    this.scrollHeight();
  }
}
