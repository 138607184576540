<p-toolbar>
    <ng-template pTemplate="left">
        <h2 class="p-m-2 ml-4 "><b>Centres</b></h2>
    </ng-template>
</p-toolbar>
<p-card>
    <div class="card">
        <p-table #dt [value]="primary.list"
            styleClass="p-datatable-sm p-datatable-gridlines" [globalFilterFields]="['name','CentreName.name','representative.name','status']" dataKey="id"
            class="defTable" [(selection)]="primary.selection" [scrollable]="true" scrollHeight="65vh" [loading]="sysService.loading" [rowHover]="true"
            styleClass="p-datatable-sm" [rows]="sysService.rows" [paginator]="true" [scrollable]="isScrollTable"
            [scrollHeight]="isScrollTable ? heightDisplay : ''" alwaysShow="false">

            <ng-template pTemplate="caption">
                <div class="wrap">

                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text"  maxlength="150"  (input)="dt.filterGlobal($event.target.value, 'contains')"
                            placeholder="Search..." />
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="paginatorleft">

            </ng-template>
            <ng-template pTemplate="header">

                <tr>
                    <th pSortableColumn="Primary">Primary <p-sortIcon field="Primary"></p-sortIcon>
                    </th>

                    <th pSortableColumn="Secondary">Secondary<p-sortIcon field="Secondary">
                        </p-sortIcon>
                    </th>

                    <th pSortableColumn="Action">Action<p-sortIcon field="Action">
                        </p-sortIcon>

                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-Primary>
                <tr>
                    <td><span class="p-column-title"><b>Primary</b></span> {{Primary.primary}}</td>
                    <td><span class="p-column-title"><b>Secondary</b></span> {{Primary.secondary}} </td>
                    <td><span class="p-column-title"><b>Action :</b> </span>
                        <button pButton type="button" class="p-button-rounded p-button-raised btnEclMin"
                            (click)="change()" label="Change"></button>

                    </td>

                </tr>
            </ng-template>

        </p-table>
    </div>
</p-card>