<div *ngIf="!sysService.isPermission()">
    <not-found></not-found>
</div>
<div *ngIf="sysService.isPermission()">
    <p-toolbar>
        <div class="p-toolbar-group-start">
            <f-nav first="/desktop-clint" label="Desktop Client" [model]="desktop" new=""> </f-nav>
        </div>
        <div class="p-toolbar-group-end">
        </div>
    </p-toolbar>
    <div class="table-responsive">
        <p-table #dt [value]="desktop.list" [scrollable]="true" class="study-list-class" scrollHeight="65vh"
            [totalRecords]="desktop.total" [scrollHeight]="heightTable" dataKey="id" [(selection)]="desktop.selection"
            [loading]="sysService.loading" filterDelay="400" class="study-list-class" [scrollHeight]="heightTable"
            currentPageReportTemplate="{first} to {last} of
    {totalRecords}" [rowHover]="true" styleClass="p-datatable-sm" [rows]="sysService.rows" [paginator]="true"
            [showCurrentPageReport]="true" alwaysShow="false" paginatorPosition="bottom"
            styleClass="p-datatable-sm p-datatable-gridlines"
            [globalFilterFields]="['system','architecture','package','size','installer','comments']">
            <ng-template pTemplate="caption">
                <div class="grid">
                    <div class="col-6 md:col-4 lg:col-2">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" maxlength="150" pAutoFocus [autofocus]="true"
                                (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                        </span>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="paginatorleft">
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>System </th>
                    <th>Architechture </th>
                    <th>package </th>
                    <th>Size </th>
                    <th>eClarity installer </th>
                    <th>Comments </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-desktop>
                <tr>
                    <td>{{desktop.system}}</td>
                    <td>{{desktop.architecture}}</td>
                    <td>{{desktop.package}}</td>
                    <td>{{desktop.size}}</td>
                    <td class="p-link">
                        <a href="{{desktop.url}}">{{desktop.installer}}</a>
                        <span class="ml-2"><i class="pi pi-download"></i></span>
                    </td>
                    <td>{{desktop.comments}} </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td class="norecordstudy" [attr.colspan]="7">No records found</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>