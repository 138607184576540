<div *ngIf="!sysService.isPermission()">
    <not-found></not-found>
</div>
<div *ngIf="sysService.isPermission()">
    <div class="table-responsive pl-2">
        <form [formGroup]="TurnAroundTime.form">
            <div class="grid ">
                <div class="col-12 md-col-6 lg:col-2 ">
                    <p-multiSelect [options]="modalityLookup" optionLabel="name" formControlName="modality"
                        (onLazyLoad)="filterMod($event)" optionValue="name" placeholder="Modality">
                        <ng-template let-option pTemplate="item">
                            <span style="width: 150px" class="ml-1">{{ option.name }}</span>
                        </ng-template>
                    </p-multiSelect>
                </div>
                <div class="col-12 md-col-6 lg:col-2">
                    <p-multiSelect [options]="radLookup" optionLabel="name" formControlName="repRadId" optionValue="id"
                        placeholder="Reporting Radiologist">
                        <ng-template let-option pTemplate="item">
                            <span style="width: 150px" class="ml-1">{{ option.name }}</span>
                        </ng-template>
                    </p-multiSelect>
                </div>
                <div class="col-12 md-col-6 lg:col-2" *ngIf="!sysService.isCenter()">
                    <p-multiSelect [options]="cenLookup" optionLabel="name" formControlName="sourceId"
                        (onLazyLoad)="filterCen($event)" optionValue="id" placeholder="Source">
                        <ng-template let-option pTemplate="item">
                            <span style="width: 150px" class="ml-1">{{ option.name }}</span>
                        </ng-template>
                    </p-multiSelect>
                </div>
                <div class="col-12 md-col-6 lg:col-2 mt-2">
                    <!-- <p-calendar #calendarRef [showIcon]="false" (onSelect)="onDateSelect($event)"
                        placeholder="DD/MM/YYYY-DD/MM/YYYY" formControlName="date" dateFormat="dd/mm/yy"
                        [numberOfMonths]="1" [maxDate]="maxDate " [minDate]="minDate"
                        class="widthClass daterange calinput" selectionMode="range" [readonlyInput]="true"
                        inputId="range" [(ngModel)]="selectedRange">
                    </p-calendar> -->

                    <p-calendar #calendarRef [showIcon]="false" (onSelect)="onDateSelect($event)"
                        placeholder="DD/MM/YYYY-DD/MM/YYYY" formControlName="date" dateFormat="dd/mm/yy"
                        [numberOfMonths]="1" class="widthClass daterange calinput" selectionMode="range"
                        [readonlyInput]="true" inputId="range" [(ngModel)]="selectedRange">
                    </p-calendar>

                </div>
                <div class="ml-2 mt-3">
                    <button pButton pRipple type="button" label="Search" (click)="turnTime()"
                        class="p-button-raised p-button-info" [disabled]="sysService.loading"> <i
                            *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i></button>

                    <button pButton pRipple type="button" label="Reset" icon=" pi pi-undo" (click)="reset()"
                        class="p-button-raised p-button-text" [disabled]="sysService.loading"> <i
                            *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i></button>
                </div>
            </div>
        </form>
    </div>
    <div class="graph-wrap ">
        <div class="graph-child">
            <div class="card mb-2 mt-5 ">
                <div class="ml-2">
                    <h3>ALL STUDIES</h3>
                </div>
                <p-chart type="bar" responsive="true" [data]="allStudiesData" [options]="allStudiesDataAxies"
                    style="height: 50%;width: 100%;max-width: 800px;">
                </p-chart>
            </div>
        </div>
        <div class="graph-child ">
            <div class="card mb-2 mt-5 ">
                <div class="ml-2">
                    <h3>AVERAGE TURN AROUND TIME </h3>
                </div>
                <p-chart type="bar" responsive="true" [data]="tatData" [options]="tatDataAxis"
                    style="height: 50%;width: 100%;max-width: 800px;">
                </p-chart>
            </div>
        </div>
    </div>
    <div class="graph-wrap ">

        <div class="graph-child " *ngIf="sysService.isRad() && rechargeHide">
            <div class="card mb-2 mt-5 ">
                <div class="ml-2">
                    <h3>TOTAL REVENUE</h3>
                </div>
                <p-chart type="bar" responsive="true" [data]="revenueData" [options]="revenueDataAxis"
                    style="height: 50%;width: 100%;max-width: 800px;">
                </p-chart>
            </div>
        </div>
        <div class="graph-child " *ngIf="sysService.isCenter()">
            <div class="card mb-2 mt-5 ">
                <div class="ml-2">
                    <h3>TOTAL REVENUE</h3>
                </div>
                <p-chart type="bar" responsive="true" [data]="revenueData" [options]="revenueDataAxis"
                    style="height: 50%;width: 100%;max-width: 800px;">
                </p-chart>
            </div>
        </div>
        <div class="graph-child ">
            <div class="card mb-2 mt-5 ">
                <div class="ml-2">
                    <h3>REJECTED REPORTS</h3>
                </div>
                <p-chart type="bar" responsive="true" [data]="rejectedStudies" [options]="rejectedStudiesAxis"
                    style="height: 50%;width: 100%;max-width: 800px;">
                </p-chart>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Completed studies  " [(visible)]="TATvisible" [modal]="true" (onHide)="onHide()"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [style]="{ width: '65vw' }" [draggable]="false"
    [resizable]="false">
    <div class="grid">
        <div class="col text-center">
            <div class="card mb-2 mt-5 ">

                <p-chart type="pie" class="chart-arrange" responsive="true" [data]="dataSet2"
                    style="height: 50%;width: 100%;">
                </p-chart>

                <!-- [options]="optionsSet" -->
                <div class="ml-2 text-center">
                    <h2 (click)="showTableList(1)" *ngIf="!sysService.isCenter() && !sysService.isRad()"> Centre</h2>
                    <h2 *ngIf="sysService.isRad()" (click)="showTableList(1)"> Source</h2>
                </div>
            </div>
        </div>
        <div class="col" *ngIf="!sysService.isCenter() && !sysService.isAdmin()">
            <div class="card mb-2 mt-5  ">
                <p-chart type="pie" class="chart-arrange" responsive="true" [data]="data"
                    style="height: 50%;width: 100%;">
                </p-chart>
                <!-- [options]="options" -->
                <div class="ml-2 text-center">
                    <h2 (click)="showTableList(2)" *ngIf="!this.sysService.isRad()"> Radiologist </h2>
                    <h2 (click)="showTableList(2)" *ngIf="this.sysService.isRad()"> 2° Reported Radiologist</h2>
                </div>
            </div>
        </div>


    </div>




</p-dialog>

<!---->

<p-dialog header="Revenue" [(visible)]="tatRevenueVisible" [modal]="true"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [style]="{ width: '75vw' }" [draggable]="false"
    [resizable]="false">

    <div class="grid">

        <div class="col-12">
            <div class="card mb-2 mt-5  ">

                <p-chart type="pie" class="chart-arrange" responsive="true" [data]="data"
                    style="height: 50%;width: 100%;max-width: 500px;">
                </p-chart>
                <div class="ml-2 text-center">
                    <h3>Revenue</h3>
                    <!-- [options]="revenueAxis" -->
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card mb-2 mt-5  ">
                <p-chart type="pie" class="chart-arrange" responsive="true" [data]="revenueReceivablesData"
                    style="height: 50%;width: 100%;max-width: 500px;">
                </p-chart>
                <!-- [options]="revenueAxis" -->
                <div class="ml-2 text-center">
                    <h3>Receivable</h3>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="card mb-2 mt-5  ">


                <p-chart type="pie" class="chart-arrange" responsive="true" [data]="revenuePayablesData"
                    style="height: 50%;width: 100%;max-width: 500px;">
                </p-chart>
                <!-- [options]="revenueAxis" -->
                <div class="ml-2 text-center">
                    <h3>Payables</h3>
                </div>
            </div>
        </div>


    </div>




</p-dialog>


<p-dialog header="REJECTED REPORTS" [(visible)]="rejectReportTat" [modal]="true"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [style]="{ width: '75vw' }" [draggable]="false"
    [resizable]="false">

    <div class="grid">
        <div class="col">
            <div class="card mb-2 mt-5  ">
                <p-chart type="pie" class="chart-arrange" responsive="true" [data]="rejectData"
                    style="height: 50%;width: 100%;">
                </p-chart>
                <!-- [options]="options" -->
                <div class="ml-2 text-center">
                    <h2 (click)="showTableList(4)"> Assign Rejected Report </h2>
                </div>
            </div>
        </div>
        <div class="col text-center">
            <div class="card mb-2 mt-5 ">

                <p-chart type="pie" class="chart-arrange" responsive="true" [data]="dataReject"
                    style="height: 50%;width: 100%;">
                </p-chart>
                <!-- [options]="options" -->
                <div class="ml-2 text-center">
                    <h2 (click)="showTableList(3)"> Rejected Report</h2>

                </div>
            </div>
        </div>

    </div>




</p-dialog>



<p-dialog header="AVERAGE TURN AROUND TIME" [(visible)]="TopupTat" [modal]="true"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [style]="{ width: '75vw' }" [draggable]="false"
    [resizable]="false">

    <div class="grid">
        <div class="col" *ngIf="!sysService.isCenter() && !sysService.isAdmin()">
            <div class="card mb-2 mt-5  ">
                <p-chart type="pie" class="chart-arrange" responsive="true" [data]="data"
                    style="height: 50%;width: 100%;">
                </p-chart>
                <!-- [options]="options" -->
                <div class="ml-2 text-center">
                    <h2 (click)="showTableList(6)" *ngIf="!sysService.isCenter() && !sysService.isRad()"> Centre</h2>
                    <h2 (click)="showTableList(6)" *ngIf="sysService.isRad()"> Source</h2>

                </div>
            </div>
        </div>
        <div class="col text-center">
            <div class="card mb-2 mt-5 ">

                <p-chart type="pie" class="chart-arrange" responsive="true" [data]="dataSet2"
                    style="height: 50%;width: 100%;">
                </p-chart>
                <!-- [options]="optionsSet" -->
                <div class="ml-2 text-center">
                    <h2 (click)="showTableList(5)" *ngIf="!this.sysService.isRad()"> Radiologist </h2>
                    <h2 (click)="showTableList(5)" *ngIf="this.sysService.isRad()"> 2° Reported Radiologist</h2>
                </div>
            </div>
        </div>

    </div>




</p-dialog>


<p-dialog header="Header" [(visible)]="visible" [modal]="true" [style]="{ width: '45rem' }">
    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <p-avatar image="../../assets/images/logo.png" shape="circle" />


            <span class="font-bold white-space-nowrap">
                Month Wise
            </span>
        </div>
    </ng-template>

    <p-table [value]="balanceData">
        <ng-template pTemplate="header">
            <tr>
                <th>User Name</th>
                <th> {{columeName}}</th>

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr>
                <td>{{ product.userName }}</td>
                <td>{{ product.value }}</td>

            </tr>
        </ng-template>
    </p-table>



    <ng-template pTemplate="footer">

    </ng-template>
</p-dialog>