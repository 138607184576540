import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model } from '@app/_common';
import { AppConstants } from '@app/_config';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent {

  private billingView: View = this.viewFactory.newView();

  countryLookup: any[];
  stateLookup: any[];
  stateLookupAll: any[];
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory) {
  }

  ngOnInit() {
    this.billingView.of(this.billingModel()); this.userDetails()

    this.userDetails()
  }

  get billing() {
    return this.billingView.model;
  }
  private billingModel() {
    const formDefault = new FormDefault([]);
    return new Model(this.formBuilder.group({
      id: [],
      name: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern(AppConstants.regexPhonenumber), Validators.minLength(7)]],
      address: [],
      company: [''],
      taxNo: [],
      region: [],
      city: [],
      zip: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]*$/)]],
      email: ['', [Validators.required, Validators.pattern(AppConstants.regexEmail)]],
      countryId: [, [Validators.required]],
      stateId: [],
    }), this.sysService, formDefault, '');
  }

  save() {
    this.billingView.toSave("/billing", "id", "Billing Information Updated Successfully ");
  }

  userDetails() { this.billingView.getFormData("/billing/info?") }

  filterState(event: any) {
    // check if stateLookupAll is already loaded
    if (this.stateLookupAll) {
      // if loaded, filter the results
      this.stateLookup = this.stateLookupAll.filter(x => x.name.toLowerCase().indexOf(event.query.toLowerCase()) === 0);
    } else {
      // if not loaded, get countryId from billing view and load states for that country
      let countryId = this.billing?.view?.countryId?.id;
      this.billingView.getData(`/state/lookupbycountry?countryId=${countryId}`).then(data => {
        // store the loaded states and set the filtered results
        this.stateLookup = data;
        this.stateLookupAll = data;
      });
    }
  }

  loadState(countryId) {
    // load states for the given countryId
    this.billingView.getData(`/state/lookupbycountry?countryId=${countryId}`).then(data => {
      // store the loaded states and set the filtered results
      this.stateLookupAll = data;
      this.stateLookup = data;
    });
  }

  stateAreaOnChange(event: any) {
    // load states for the selected country
    this.loadState(event.id);
    // reset stateId
    this.billing.set("stateId", null);
  }



  filterCountry(event: any) {
    this.billingView.getData(`/country/lookup?filter=${event.query}`).then(data => { this.countryLookup = data; });
  }

}