import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model } from '@app/_common';
import { AppConstants } from '@app/_config';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.css']
})
export class AdminProfileComponent {

  private adminView: View = this.viewFactory.newView();
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory) {
  }
  ngOnInit() {
    this.adminView.of(this.adminListModel()); this.userDetails()
  }
  get adminDetails() {
    return this.adminView.model;
  }
  private adminListModel() {
    const formDefault = new FormDefault([], []);
    return new Model(this.formBuilder.group({
      id: [],
      name: ['', [Validators.required, Validators.pattern(AppConstants.regexName)]],
      email: ['', [Validators.required, Validators.pattern(AppConstants.regexEmail)]],
      phoneNo: ['', [Validators.required, Validators.pattern(AppConstants.regexPhonenumber), Validators.minLength(7)]],

    }), this.sysService, formDefault, '');
  }
  async saveAdmin() {
    await this.adminView.toSave("/users", "id", "Profile Updateded Successfully");
    this.sysService.loadUserName();
  }
  userDetails() { this.adminView.getFormData("/users/userprofile") }
}
