<div class="grid">
    <div class="col-12 right-button">
        <p> Need help...? &nbsp;<a href="https://www.ecscribe.com" target="_blank" class="link">Click here
            </a>
        </p>
    </div>
</div>
<form [formGroup]="center.form">
    <div class="card">
        <div class="grid">
            <div class="field col-12 md:col-6  lg:col-4">
                <label>Centre Name <span class="validation-star">*</span> </label>
                <input pInputText maxlength="100" formControlName="name" type="text" pAutoFocus autofocus="true">
                <f-error [err]="center.required('name')" msg="Centre Name  is required"></f-error>


            </div>

            <div class="field col-12 md:col-6 lg:col-4">

                <label for="EmailAddress">Email Address<span class="validation-star">*</span> &nbsp;(This will also be
                    your Username) </label>
                <input id="EmailAddress" type="text" maxlength="100" [readOnly]="true" pInputText
                    formControlName="email" required />
                <f-error [err]="center.required('email')" msg="Email Address is required "></f-error>
                <f-error [err]="center.pattern('email')"
                    msg="This e-mail address is not valid. Valid records include to user@somehost.somedomain. And emails only accept lowercase letters.">
                </f-error>
            </div>
            <div class="field col-12 md:col-6  lg:col-4">
                <label>Phone Number <span class="validation-star">*</span></label>

                <input pInputText maxlength="18" formControlName="phoneNo" type="text">
                <f-error [err]="center.required('phoneNo')" msg="Phone Number is required"></f-error>
                <f-error [err]="center.pattern('phoneNo')" msg="Numbers only required"></f-error>
                <f-error [err]="center.minlength('phoneNo')" msg="Enter a minimum of 7 digits"></f-error>


            </div>
            <div class="field col-12 md:col-6 lg:col-4">
                <label>Start Date <span class="validation-star">*</span></label>
                <p-calendar [showIcon]="true" formControlName="startDate" placeholder="DD-MM-YYYY" dateFormat="dd-mm-yy"
                    dataType="string" class="widthClass"></p-calendar>
                <f-error [err]="center.required('startDate')" msg="Start Date is required"></f-error>
            </div>

            <div class="field col-12 md:col-6 lg:col-4">

                <label>Country <span class="validation-star">*</span></label>

                <p-autoComplete formControlName="countryId" maxlength="60" [suggestions]="countryLookup"
                    (onSelect)="stateAreaOnChange($event)" (completeMethod)="filterCountry($event)" field="name"
                    ariaLabel="Country" [dropdown]="true" forceSelection="true" [autoHighlight]="true"
                    showEmptyMessage="true" delay="400">
                </p-autoComplete>

                <f-error [err]="center.required('countryId')" msg="Country is required"></f-error>
            </div>
            <div class="field col-12 md:col-6 lg:col-4">
                <label>State </label>

                <p-autoComplete formControlName="stateId" maxlength="60" [suggestions]="stateLookup"
                    (completeMethod)="filterState($event)" field="name" ariaLabel="State" [dropdown]="true"
                    [autoHighlight]="true" forceSelection="true" showEmptyMessage="true" delay="400">
                </p-autoComplete>

            </div>


            <div class="field col-12 md:col-6 lg:col-4">
                <label>Zip Code <span class="validation-star">*</span> </label>
                <input pInputText maxlength="12" formControlName="zip" type="text">
                <f-error [err]="center.required('zip')" msg="Zip Code is required"></f-error>
                <f-error [err]="center.pattern('zip')" msg=" Only alphanumeric characters are accepted"></f-error>
            </div>
            <div class="field col-12 md:col-6 lg:col-4">
                <label>AE Title </label>
                <input pInputText maxlength="48" formControlName="aeTitle" type="text" [readOnly]="true"
                    autocomplete="off" (input)="callApi($event.target.value)">
                <f-error [err]="center.required('aeTitle')" msg="AE Title is required"></f-error>
                <f-error [err]="center.pattern('aeTitle')" msg="AE Title should not contain white space"></f-error>



                <small class="p-error">
                    {{ message }}
                </small>
            </div>
            <div class="field col-12 md:col-6  lg:col-4">
                <label>Address <span class="validation-star">*</span></label><textarea
                    class="p-inputtextarea p-inputtext p-component p-element" [rows]="3" [cols]="40" maxlength="480"
                    formControlName="address" pInputTextarea></textarea>
                <f-error [err]="center.required('address')" msg="Address is required"></f-error>
            </div>

        </div>
    </div>
    <div class="card alignsetting">
        <div class="text-center">
            <h2>Report Settings </h2>
        </div>
        <div class="grid">
            <div class="field col-12 md:col-6  lg:col-6">
                <label>Margin Top (px) <span class="validation-star">*</span> </label>
                <input pInputText maxlength="3" formControlName="marginTop" type="text">
                <f-error [err]="center.pattern('marginTop')" msg="only Numbers are accepted"></f-error>
                <f-error [err]="center.required('marginTop')" msg="Margin Top is required"></f-error>

            </div>
            <div class="field col-12 md:col-6  lg:col-6">
                <label>Margin Left (px) <span class="validation-star">*</span> </label>
                <input pInputText maxlength="3" formControlName="marginLeft" type="text">
                <f-error [err]="center.pattern('marginLeft')" msg="only Numbers are accepted"></f-error>
                <f-error [err]="center.required('marginLeft')" msg="Margin Left  is required"></f-error>

            </div>
            <div class="field col-12 md:col-6  lg:col-6">
                <label>Margin Bottom (px) <span class="validation-star">*</span> </label>
                <input pInputText maxlength="3" formControlName="marginBottom" type="text">
                <f-error [err]="center.pattern('marginBottom')" msg="only Numbers are accepted"></f-error>
                <f-error [err]="center.required('marginBottom')" msg="Margin Bottom is required"></f-error>

            </div>
            <div class="field col-12 md:col-6  lg:col-6">
                <label>Margin Right (px)<span class="validation-star">*</span></label>
                <input pInputText maxlength="3" formControlName="marginRight" type="text">
                <f-error [err]="center.pattern('marginRight')" msg="only Numbers are accepted"></f-error>
                <f-error [err]="center.required('marginRight')" msg="Margin Right  is required"></f-error>

            </div>

            <div class="field col-12 md:col-6 lg:col-6 mt-3">

                <!-- <p>{{center.form.value | json}}</p> -->
                <div class="grid stamp-pos">

                    <div class="col-12 lg:col-4">

                        <div class="py-3"> <label>Upload Your Header</label></div>
                        <div class="file-upload-sec">
                            <input type="file" #fileInput id="document" formControlName="docFile"
                                (change)="selectFile($event.target.files,'header')" accept=".jpg,.jpeg,.png" />
                        </div>

                    </div>
                    <div class="col-6 lg:col-3 text-center ">
                        <div class="ml-2 stamp"> <p-image [src]="!hasHeader ? imageurl : header"></p-image>
                            <span class="close-icon btn-tr" *ngIf="hasHeader && this.center.get('header')"
                                (click)="closeheader(center)">&#10006;</span>
                            <div class="pt-2 ">{{ getFilename(center?.view?.header)
                                }}</div>
                        </div>



                    </div>
                </div>
            </div>
            <div class="field col-12 md:col-6 lg:col-6 mt-3">
                <div class="grid stamp-pos">

                    <div class="col-12 lg:col-4">

                        <div class="py-3"> <label>Upload Your Footer</label></div>
                        <div class="file-upload-sec">
                            <input type="file" #fileInput id="document" formControlName="docFile1"
                                (change)="selectFile1($event.target.files,'footer')" accept=".jpg,.jpeg,.png" />
                        </div>

                    </div>
                    <div class="col-6 lg:col-3 text-center ">
                        <div class="ml-2 stamp"> <p-image [src]="!hasFooter ? imageurl : footer"></p-image>
                            <span class="close-icon btn-tr" *ngIf="hasFooter && this.center.get('footer')"
                                (click)="closefooter(center)">&#10006;</span>
                            <div class="pt-2 ">{{ getFilename(center?.view?.footer)
                                }}</div>
                        </div>



                    </div>
                </div>
            </div>

            <div class="field col-12 md:col-6  lg:col-4 ">
                <p-checkbox formControlName="isReportType" [(ngModel)]="isReportType"
                    (ngModelChange)="updateCheckboxes('isReportType')" [binary]="true" inputId="binary">
                </p-checkbox>
                &nbsp; Add Patient Details in All Page

            </div>
            <div class="field col-12 md:col-6  lg:col-4  ">
                <p-checkbox formControlName="isPatientDetailsAllPage" [(ngModel)]="isPatientDetailsAllPage"
                    (ngModelChange)="updateCheckboxes('isPatientDetailsAllPage')" [binary]="true" inputId="binary">
                </p-checkbox>
                &nbsp; Add Patient Details in First Page Only


            </div>
            <div class="field col-12 md:col-6  lg:col-4 ">
                <p-checkbox formControlName="isFooterHeaderAllPage" [(ngModel)]="isFooterHeaderAllPage"
                    (ngModelChange)="reportupdateCheckboxes()" [binary]="true" inputId="binary">
                </p-checkbox>
                &nbsp; Add Header and Footer In All Page


            </div>

            <div class="field col-12 md:col-6  lg:col-6 ">
                <p-button label="Reset As Default " styleClass="p-button-outlined p-button-warning"
                    (click)="reportMarginReset()"></p-button>
            </div>




        </div>

    </div>

    <div class="formgrid text-center bc">
        <button pButton pRipple type="button" label=" Deactivate" icon="pi pi-user-minus"
            class="p-button-raised p-button-secondary" (click)="CenterDeactivte()" [disabled]="sysService.loading">

            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
        </button>

        <button pButton pRipple type="button" label=" Delete My Account " icon="pi pi-trash"
            class="p-button-raised p-button-danger" (click)="deteleCenter()" [disabled]="sysService.loading">

            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
        </button>

        <button pButton pRipple type="button" label=" Update" icon="pi pi-check"
            class="p-button-raised p-button-success" (click)="saveCenter()" [disabled]="sysService.loading">

            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
        </button>
    </div>




</form>


<p-dialog header=" Instructions " [dismissableMask]="true" [(visible)]="sigRole" [modal]="true">
    <label>"Signature should be in 230x150 width and height,<br> and the size should be 200KB."</label>
</p-dialog>
<p-dialog header=" Instructions " [dismissableMask]="true" [(visible)]="stampRole" [modal]="true">
    <label>"Stamp should be in 230x150 width and height,<br> and the size should be 200KB."</label>
</p-dialog>