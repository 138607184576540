<div *ngIf="!sysService.isPermission() ">
    <not-found></not-found>
</div>

<div *ngIf="sysService.isPermission()&& !hl7Show ">
    <not-found></not-found>
</div>
<div *ngIf="sysService.isPermission() && hl7Show">
    <p-toolbar>
        <div class="p-toolbar-group-start">
            <f-nav first="/hl7" label="HIS Order" [model]="hl7"> </f-nav>
        </div>

    </p-toolbar>
    <div class="table-responsive">
        <div class="grid mobBtn p-2">

            <button pButton pRipple label="Today" (click)="dayByFilter(0,dt) "
                [disabled]="sysService.loading || loadingData"
                class="{{smartFilter===0 || smartFilter===undefined ? 'p-button-info ': ' p-button-outlined  ' }}sm-btn"></button>

            <button pButton pRipple label="Yesterday" (click)="dayByFilter(1,dt) "
                [disabled]="sysService.loading || loadingData"
                class="{{smartFilter===1 ? 'p-button-info ': ' p-button-outlined  ' }} sm-btn"></button>

            <button pButton pRipple label="Last 2 Days" (click)="dayByFilter(2,dt) "
                [disabled]="sysService.loading || loadingData"
                class="{{smartFilter===2 ? 'p-button-info ': ' p-button-outlined  ' }} sm-btn"></button>

            <button pButton pRipple label="Last 7 Days" (click)="dayByFilter(7,dt) "
                [disabled]="sysService.loading || loadingData"
                class="{{smartFilter===7 ? 'p-button-info ': ' p-button-outlined  ' }} sm-btn"></button>

            <button pButton pRipple label="Last 30 Days" (click)="dayByFilter(30,dt) "
                [disabled]="sysService.loading || loadingData"
                class="{{smartFilter===30 ? 'p-button-info ': ' p-button-outlined  ' }} sm-btn"></button>
            <p-dropdown [options]="staticService.studyFilter" optionLabel="label" [showClear]="true"
                class="dayss-filter2 " placeholder="Select"
                (onChange)="dayByFilter($event?.value?.value,dt)"></p-dropdown>


            <span>
                <span class="p-float-label floating">
                    <p-calendar #calendarRef [showIcon]="false" dateFormat="dd/mm/yy"
                        placeholder="DD/MM/YYYY-DD/MM/YYYY" [numberOfMonths]="1" class="widthClass inputclr"
                        selectionMode="range" [readonlyInput]="true" inputId="range" [(ngModel)]="selectedRange"
                        (onSelect)="calendarSelected = true">
                        <ng-template pTemplate="footer">
                            <div class="grid justify-content-center">
                                <p-button label="Clear" *ngIf="calendarSelected" (click)="changesCalendarClear(dt)"
                                    styleClass="p-button-raised p-button-text"></p-button>&nbsp;
                                <button pButton pRipple type="button" *ngIf="calendarSelected"
                                    (click)="calenedarChangeDate($event,dt);dt.reset()" label=" Search"
                                    class="p-button-raised p-button-text"></button>
                            </div>
                        </ng-template></p-calendar>
                    <label class="text-light" for="float-input">Date Range </label>
                </span>
            </span>
            <!-- <p-calendar #calendarRef [showIcon]="false" (onSelect)="calendarSelected = true"
                class="{{smartFilter === '' ? 'p-button-info' : 'p-button-outlined'}} calender-filter2"
                placeholder="DD/MM/YYYY-DD/MM/YYYY" dateFormat="dd/mm/yy" [numberOfMonths]="1"
                class="widthClass daterange calinput" selectionMode="range" inputId="range" [(ngModel)]="selectedRange">
                <ng-template pTemplate="footer">
                    <div class="grid justify-content-center">
                        <p-button label="Clear" *ngIf="calendarSelected" (click)="changesCalendarClear(dt)"
                            styleClass="p-button-raised p-button-text"></p-button>&nbsp;
                        <button pButton pRipple type="button" *ngIf="calendarSelected"
                            (click)="calenedarChangeDate($event,dt)" label="Search"
                            class="p-button-raised p-button-text"></button>
                    </div>
                </ng-template>
            </p-calendar> -->
            &nbsp;&nbsp;&nbsp;
            <p-menu #bulkMenu popup="true" appendTo="body" [model]="items"></p-menu>
            <button pButton pRipple type="button" label=""
                *ngIf="hl7.selection.length > 0 && !sysService.isAdmin()&& !sysService.isPhysician()" icon="pi pi-cog"
                class="p-button-outlined p-button-info" (click)="bulkMenu.toggle($event)"></button> &nbsp;
            <div class="mt-2">
                <p-button icon="pi pi-refresh" (click)="reload(dt)" [class.p-button-success]="true"
                    [class.p-button-text]="true" styleClass="p-button-rounded p-button-help"></p-button>
            </div>
            <div class="count-Left">
                <button pButton pRipple type="button" label=" Reset" (click)="hisReload(dt);dt.reset()"
                    class="p-button-raised p-button-text" [disabled]="sysService.loading">
                    <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                </button>
            </div>
        </div>
        <p-table #dt [value]="hl7.list" [totalRecords]="hl7.total" [scrollable]="true" [scrollHeight]="heightTable"
            class="study-list-class" [lazy]="true" (onLazyLoad)="listhl7Lazy($event)" dataKey="id"
            [(selection)]="hl7.selection" selectionMode="multiple" [loading]="sysService.loading" filterDelay="400"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" [rowsPerPageOptions]="[25, 50]"
            [rowHover]="true" styleClass="p-datatable-sm" [rows]="sysService.rows" [paginator]="true"
            [showCurrentPageReport]="true" alwaysShow="false" paginatorPosition="bottom"
            styleClass="p-datatable-sm p-datatable-gridlines"
            [globalFilterFields]="['system','architecture','package','size','installer','comments']">

            <ng-template pTemplate="paginatorleft">
            </ng-template>
            <ng-template pTemplate="header">
                <tr>

                    <th id="" scope="col" pSortableColumn="mrn" style="width: 8rem; ">
                        MRN
                        <p-sortIcon></p-sortIcon>
                    </th>
                    <th id="" scope="col" pSortableColumn="patName">
                        Patient Name
                        <p-sortIcon></p-sortIcon>
                    </th>
                    <th id="" scope="col" pSortableColumn="age">
                        Age
                        <p-sortIcon></p-sortIcon>
                    </th>
                    <th id="" scope="col" pSortableColumn="sex">
                        Sex
                        <p-sortIcon></p-sortIcon>
                    </th>
                    <th id="" scope="col" pSortableColumn="type" style="width: 8rem; ">
                        Type
                        <p-sortIcon></p-sortIcon>
                    </th>
                    <th id="" scope="col" pSortableColumn="procedureCode">
                        Procedure code
                        <p-sortIcon></p-sortIcon>
                    </th>
                    <th id="" scope="col" pSortableColumn="procedureName">
                        Procedure Name
                        <p-sortIcon></p-sortIcon>
                    </th>
                    <th id="" scope="col" pSortableColumn="orderedDate">
                        Ordered Date
                        <p-sortIcon></p-sortIcon>
                    </th>

                    <th id="" scope="col" pSortableColumn="refPhysician">
                        Ref Physician
                        <p-sortIcon></p-sortIcon>
                    </th>
                    <th id="" scope="col" pSortableColumn="refPhysician">
                        Accession no
                        <p-sortIcon></p-sortIcon>
                    </th>
                    <th id="" scope="col" pSortableColumn="status" style="width: 9rem; ">
                        Status
                        <p-sortIcon></p-sortIcon>
                    </th>
                    <th>
                        Action
                    </th>

                </tr>
                <tr class="text-center">
                    <th>
                        <p-columnFilter type="text" matchMode="contains" placeholder="MRN" field="mrn"
                            [showMenu]="false">
                        </p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" matchMode="contains" placeholder="Patient Name" field="patName"
                            [showMenu]="false"></p-columnFilter>
                    </th>
                    <th> </th>
                    <th>
                        <p-columnFilter field="sex" matchMode="contains" [showMenu]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown [ngModel]="value" [options]="['M', 'F']" (onChange)="filter($event.value)"
                                    placeholder="Any">
                                    <ng-template let-option pTemplate="item">
                                        <span>{{ option }}</span>
                                    </ng-template>
                                </p-dropdown>
                            </ng-template>
                        </p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter field="type" matchMode="contains" [showMenu]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown [ngModel]="value" [options]="['IP', 'OP']" (onChange)="filter($event.value)"
                                    placeholder="Any">
                                    <ng-template let-option pTemplate="item">
                                        <span>{{ option }}</span>
                                    </ng-template>
                                </p-dropdown>
                            </ng-template>
                        </p-columnFilter>
                    </th>
                    <th> </th>
                    <th> </th>
                    <th> </th>

                    <th>
                        <p-columnFilter type="text" matchMode="contains" placeholder="Ref Physician"
                            field="refPhysician" [showMenu]="false"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" matchMode="contains" placeholder="Accession no" field="accessionNo"
                            [showMenu]="false"></p-columnFilter>
                    </th>
                    <th style="width: 9rem;">

                        <p-columnFilter field="status" matchMode="contains" [showMenu]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown [ngModel]="value" [options]="staticService.hisStatus"
                                    (onChange)="filter($event.value)" placeholder="All/Any">
                                    <ng-template let-option pTemplate="item">
                                        <span>{{ option.label }}</span>
                                    </ng-template>
                                </p-dropdown>
                            </ng-template>
                        </p-columnFilter>
                    </th>
                    <th></th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-desktop>
                <tr>
                    <td> <i class="pi pi-file-edit link-study" (click)="edit(desktop.id)" title="edit"> </i> &nbsp;
                        {{ desktop?.mrn }}
                    </td>
                    <td>{{desktop?.patName}}</td>
                    <td>{{desktop?.age}}</td>
                    <td>{{desktop?.sex}}</td>
                    <td>{{desktop?.type}}</td>
                    <td>{{desktop?.procedureCode}}</td>
                    <td>{{desktop?.procedureName}}</td>
                    <td>{{desktop?.orderedDate| date: 'dd-MM-yyyy hh:mm a '}}</td>
                    <td>{{desktop?.refPhysician}}</td>
                    <td>{{desktop?.accessionNo}}</td>
                    <td><span *ngIf="desktop.status === 3" style="color: rgb(102, 82, 9);"> Ordered</span>
                        <span *ngIf="desktop.status === 4"> sent for approvel</span>
                        <span *ngIf="desktop.status === 5"> Registered</span>
                        <span *ngIf="desktop.status === 6" style="color: rgb(17, 17, 152);"> Scheduled</span>
                        <span *ngIf="desktop.status === 7"> Arrived</span>
                        <span *ngIf="desktop.status === 8"> In Progress</span>
                        <span *ngIf="desktop.status === 9"> Completed</span>
                        <span *ngIf="desktop.status === 10"> Discontinued</span>
                        <span *ngIf="desktop.status === 11"> Reported</span>
                        <span *ngIf="desktop.status === 12" style="color: rgb(170, 14, 14);"> Cancelled</span>

                    </td>
                    <td style="width: 9rem;"> <i class="pi pi-clock m-2" *ngIf="desktop.status ===3" pTooltip="Schedule"
                            (click)="schedulerData(desktop,true)"
                            style="color: rgb(10, 26, 204);font-size: x-large;  cursor: pointer;"></i>
                        <i class="pi pi-check-circle m-2" *ngIf="desktop.status === 6" pTooltip="Scheduled"
                            style="color: rgb(12, 219, 23); font-size: x-large; cursor: pointer;"></i>
                        <i class="pi pi-times-circle m-2" pTooltip="Cancel" *ngIf="desktop.status === 6"
                            (click)="schedulerData(desktop,false)"
                            style="color: rgb(204, 33, 10);font-size: x-large;  cursor: pointer;"></i>

                    </td>

                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td class="norecordstudy" [attr.colspan]="16">No records found</td>
                </tr>
            </ng-template>
        </p-table>

    </div>
    <p-dialog header=" HIS Order Details " [dismissableMask]="true" [(visible)]="visible" [modal]="true"
        [style]="{ width: '95vw' }" [draggable]="false" [resizable]="false">
        <form [formGroup]="hl7.form">

            <div class="grid ">

                <div class=" col-12 md:col-6 lg:col-4 ">
                    <label><b style="font-size: initial;">MRN :</b></label>
                    <input pInputText readonly="true" formControlName="mrn" type="text">

                </div>
                <div class="col-12 md:col-6 lg:col-4   ">
                    <label><b style="font-size: initial;">Patient Name :</b> </label>
                    <input pInputText readonly="true" formControlName="patName" type="text">

                </div>
                <div class="col-12 md:col-6 lg:col-4 ">
                    <label><b style="font-size: initial;">Age/Sex:</b></label>
                    <input pInputText readonly="true" formControlName="ageSex" type="text">

                </div>
                <div class="col-12 md:col-6 lg:col-4 ">
                    <label><b style="font-size: initial;">Bed No :</b> </label>
                    <input pInputText readonly="true" formControlName="bedNo" type="text">

                </div>
                <div class="col-12 md:col-6 lg:col-4 ">
                    <label><b style="font-size: initial;">DOB :</b> </label>
                    <input pInputText readonly="true" formControlName="dob" type="text">

                </div>

                <div class="col-12 md:col-6 lg:col-4 ">
                    <label><b style="font-size: initial;">Visit Type :</b> </label>
                    <input pInputText readonly="true" formControlName="type" type="text">

                </div>
                <div class="col-12 md:col-6 lg:col-4  ">
                    <label><b style="font-size: initial;">Visit Date :</b></label>
                    <input pInputText readonly="true" formControlName="visitDate" type="text">

                </div>
                <div class="col-12 md:col-6 lg:col-4 ">
                    <label><b style="font-size: initial;">Consulting Doc :</b> </label>
                    <input pInputText readonly="true" formControlName="consultingDoc" type="text">

                </div>
                <div class="col-12 md:col-6 lg:col-4 ">
                    <label><b style="font-size: initial;">Ref Doctor :</b> </label>
                    <input pInputText readonly="true" formControlName="refDoc" type="text">

                </div>
                <div class="col-12 md:col-6 lg:col-4 ">
                    <label><b style="font-size: initial;">Visit No:</b></label>
                    <input pInputText readonly="true" formControlName="visitNo" type="text">

                </div>
                <div class="col-12 md:col-6 lg:col-4 ">
                    <label><b style="font-size: initial;">Procedure :</b> </label>
                    <input pInputText readonly="true" formControlName="procedureName" type="text">

                </div>
                <div class="col-12 md:col-6 lg:col-4  ">
                    <label><b style="font-size: initial;">Speciality :</b></label>
                    <input pInputText readonly="true" formControlName="speciality" type="text">

                </div>
                <div class="col-12 md:col-6 lg:col-4 ">
                    <label><b style="font-size: initial;">Ward :</b> </label>
                    <input pInputText readonly="true" formControlName="ward" type="text">

                </div>

                <div class="col-12 md:col-6 lg:col-4 ">
                    <label><b style="font-size: initial;">Prority :</b> </label>
                    <input pInputText readonly="true" formControlName="priority" type="text">

                </div>
                <div class="col-12 md:col-6 lg:col-4 ">
                    <label><b style="font-size: initial;">Allergy :</b> </label>
                    <input pInputText readonly="true" formControlName="allergy" type="text">

                </div>
                <div class="col-12 md:col-6 lg:col-4 ">
                    <label><b style="font-size: initial;">Clinicial info :</b> </label>
                    <input pInputText readonly="true" formControlName="clinicalInfo" type="text">

                </div>



                <div class="col-12 md:col-6 lg:col-4" *ngIf="hl7.get('status') === 12">
                    <label><b style="font-size: initial;">Cancelled Remark :</b></label>
                    <input pInputText readonly="true" formControlName="cancelRemarks" type="text">

                </div>

                <div class="col-12 md:col-6 lg:col-4" *ngIf="hl7.get('status') !== 3 && hl7.get('status') !== null">
                    <label><b style="font-size: initial;">Scheduled Equipment :</b></label>
                    <input pInputText readonly="true" formControlName="equipment" type="text">

                </div>
                <div class="col-12 md:col-6 lg:col-12  ">
                    <label><b style="font-size: initial;">Diagnosis :</b> </label>
                    <textarea rows="4" cols="30" formControlName="diagnosis" readonly="true"
                        class="p-inputtextarea p-inputtext p-component p-element" pInputTextarea
                        placeholder="Comments"></textarea>
                </div>


            </div>
        </form>
    </p-dialog>
    <p-dialog [header]="statusSchedular ? 'Scheduler ' : 'Scheduler Cancellation'" [dismissableMask]="true"
        [(visible)]="hisDetails" [modal]="true" [style]="{ width: '90vw' }" [draggable]="false" [resizable]="false">
        <form [formGroup]="hl7.form">
            <div class="grid ">

                <div class="col-12 md:col-6 lg:col-4   ">
                    <label><b style="font-size: initial;">Patient Name:</b></label>

                    <input pInputText readonly="true" formControlName="patName" type="text">
                </div>
                <div class="col-12 md:col-6 lg:col-4 ">
                    <label><b style="font-size: initial;">Age/Sex:</b></label>

                    <input pInputText readonly="true" formControlName="ageSex" type="text">

                </div>
                <div class="col-12 md:col-6 lg:col-4  ">
                    <label><b style="font-size: initial;">Ordered Date :</b></label>
                    <input pInputText readonly="true" formControlName="orderedDate" type="text">

                </div>
                <div class="col-12 md:col-6 lg:col-4 ">
                    <label><b style="font-size: initial;">Ref Physician :</b> </label>
                    <input pInputText readonly="true" formControlName="refPhysician" type="text">

                </div>
                <div class="col-12 md:col-6 lg:col-4 ">
                    <label><b style="font-size: initial;">Procedure :</b> </label>
                    <input pInputText readonly="true" formControlName="procedureName" type="text">

                </div>
                <div *ngIf="statusSchedular" class="col-12 md:col-6 lg:col-4 ">
                    <label for="EquipmentId"><b style="font-size: initial;">Equipment </b> <span
                            class="validation-star">*</span><i>(Please choose an equipment for scheduling )</i> </label>



                    <p-autoComplete formControlName="EquipmentId" [suggestions]="modalityLookup"
                        (completeMethod)="filterModality($event)" maxlength="60" forceSelection="true" field="name"
                        ariaLabel="State" [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400">
                    </p-autoComplete>


                    <div *ngIf="errFlag">
                        <small class="p-error">Equipment is required</small>
                    </div>
                </div>

                <div class="col-12 md:col-6 lg:col-4 ">
                    <label><b style="font-size: initial;">Modality Code :</b> </label>
                    <input pInputText readonly="true" formControlName="modalityCode" type="text">

                </div>


                <div *ngIf="!statusSchedular" class="col-12 md:col-6 lg:col-4 ">
                    <label for="EquipmentId"><b style="font-size: initial;">Cancel Remark <span
                                class="validation-star">*</span> </b> </label>
                    <textarea [rows]="2" [cols]="39" maxlength="255"
                        class="p-inputtextarea p-inputtext p-component p-element" (click)="removeMessage($event)"
                        formControlName="cancelRemarks" pInputTextarea></textarea>
                    <div *ngIf="errFlag">
                        <small class="p-error">Cancel Remark is required</small>
                    </div>
                </div>
            </div>

        </form>

        <div class=" text-center">
            <button pButton pRipple type="button" label="Cancel" icon="pi pi-times"
                class="p-button-outlined p-button-info" (click)="hisCancel()" [disabled]="sysService.loading">
                <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
            </button>
            <button pButton pRipple type="button" label="{{ statusSchedular ? 'Schedule' : 'Cancel Order' }}"
                class="p-button-raised" [ngClass]="statusSchedular ? 'p-button-success' : 'p-button-danger'"
                (click)="hisSave()" [disabled]="sysService.loading">
                <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
            </button>


        </div>

    </p-dialog>
</div>