import { Injectable } from '@angular/core';
import { MessageService, PrimeNGConfig, MenuItem, Message } from 'primeng/api';
import { Router, NavigationExtras, ActivatedRouteSnapshot } from '@angular/router';
import { ApiService, RoutingService, UserService } from '@app/_common';
import { AppConfig, AppConstants } from '@app/_config';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogService } from 'primeng/dynamicdialog';
import { NotifyService } from '../notification/notify.service';
import { RolePermissions } from '../data/role-permission.data';
import { BlobOptions } from 'buffer';
@Injectable()
// @Injectable({  providedIn: 'root'})
export class SysService {
  get loading(): boolean {
    return this.load;
  }
  constructor(
    private messageService: MessageService,
    private apiService: ApiService,
    private userService: UserService,
    public notifyService: NotifyService,
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    public RoutingService: RoutingService,
    private router: Router
  ) {
    this.primengConfig.ripple = true;
  }
  popup = false; //transfered from view or viewpopup
  theme = 'theme-dark';
  logo = 'logo.png';
  siteContext: string;
  rows = 10;
  rowsPerPageOptions = [5, 10, 30, 50, 100, 200];
  menuItems: MenuItem[] = []; // Menus for the user
  username: string;
  role: string;
  heightTable: any
  walletBalance = 0;
  currentUser: any;
  siteList: any[];
  // Menus for the user
  // loading = false;
  public submitted = false;
  load = false;
  user: any;
  static scrollTo(v = 0): void {
    document.querySelector('body')?.scroll({
      top: v,
      left: 0,
      behavior: 'smooth'
    });
  }
  setLoading(loading: boolean): void {
    this.load = loading;
  }
  async appRefresh(): Promise<void> {
    if (this.menuItems.length === 0) {
      await this.appInit();
    }
  }
  async appInit(): Promise<void> {
    this.menuItems = [];
    this.username = '';
    this.siteList = [];
    this.siteContext = '';
    this.user = this.getUser();
  }
  private getKey(key: string): string {
    if (key === '') {
      return this.popup === true ? 'popmsgs' : 'msgs';
    } else {
      return key;
    }
  }
  success(summary: string, detail: string = '', key: string = ''): void {
    this.messageService.clear();
    this.successAdd(summary, detail, this.getKey(key));
  }
  successAdd(summary: string, detail: string = '', k: string = ''): void {
    // debugger
    this._mess('success', summary, detail, k);
  }
  _mess(severity2: string, summary2: string, detail2: string = '', k: string = '') {
    this.clearUnload();
    if (summary2 && summary2 !== '') {
      let key2: string = this.getKey(k);
      let m: Message = { severity: severity2, summary: summary2, detail: detail2, key: key2 };
      // // debugger
      // console.log(m)
      this.messageService.add(m);
      SysService.scrollTo();
    }
  }
  log(servicePath: string, err: HttpErrorResponse) {
    if (err) {
      let e = `${JSON.stringify(err)}`
      console.log(e);
      this.apiService.post(`${servicePath}`, `${e}`).then(data => { })
    }
  }
  error(summary: string, detail: string = '', key: string = '',): void {
    this.messageService.clear();
    this.errorAdd(summary, detail, this.getKey(key));
  }
  errorAdd(summary: string, detail: string = '', k: string = ''): void {
    this._mess('error', summary, detail, k);
  }
  info(summary: string, detail: string = '', key: string = ''): void {
    this.messageService.clear();
    this.infoAdd(summary, detail, this.getKey(key));
  }
  infoAdd(summary: string, detail: string = '', k: string = '') {
    this._mess('info', summary, detail, k);
  }
  warn(summary: string, detail: string = '', key: string = ''): void {
    this.messageService.clear();
    this.warnAdd(summary, detail, this.getKey(key));
  }
  warnAdd(summary: string, detail: string = '', k: string = ''): void {
    this._mess('warn', summary, detail, k);
  }
  clearUnload(): void {
    this.load = false;
  }
  clear(keep: boolean = false): void {
    this.clearUnload();
    if (keep === false) {
      this.messageService.clear();
    }
    this.submitted = false;
  }
  navigate(url: any[], keep: boolean = false, extras?: NavigationExtras): void {
    this.clear(keep);
    this.router.navigate(url, extras);
  }
  getUser(): any {
    if (this.user)
      return this.user;
    else
      this.user = this.userService.getUser();
    return this.user;
  }
  isLoggedIn(): boolean {
    return (this.user && this.user !== null);
  }
  async signOut(): Promise<void> {
    this.notifyService.stop();
    this.user = null;
    this.userService.signOut();
    this.navigate([AppConfig.logout]);
  }
  handleError(err: any, mess: string = 'DEFAULT'): void {
    this.load = false;
    if (err.status === 422 || err.status === 409 || err.status === 404 || err.status === 406) {
      if (err.error && err.error.length > 0) {
        if (err.error instanceof Array) {
          for (const element of err.error) {
            this.errorAdd(element);
          }
        } else {
          this.error(err.error);
        }
      }
      else if (err.error) {
        this.error(err.error.error);
      }
      else {
        this.error(AppConstants.validationFailed);
      }
    }
    else if ([400].includes(err.status)) {
      this.error(AppConstants.err400);
    }
    else {
      this.error(mess === 'DEFAULT' ? AppConstants.errUnknow : mess);
    }
  }
  isAdmin(): any {
    return this.hasRole('ROLE_SYS_ADMIN');
  }
  isCenter(): any {
    return (this.hasRole('ROLE_CTR_ADMIN') || this.hasRole('ROLE_CTR_USER'));
  }
  isCenterAdmin(): any {
    return this.hasRole('ROLE_CTR_ADMIN');
  }
  isRadAdmin(): any {
    return this.hasRole('ROLE_RAD_ADMIN');
  }
  isRad(): any {
    return (this.hasRole('ROLE_RAD_ADMIN') || this.hasRole('ROLE_RAD_USER'));
  }
  isAssigner(): any {
    return this.hasRole('ROLE_ASSIGNER_USER');
  }
  isApprover(): any {
    return this.hasRole('ROLE_APPROVER_USER');
  }
  isTechical(): any {
    return this.hasRole('ROLE_CTR_TECHNICIAN')
  }
  isPhysician(): any {
    return this.hasRole('ROLE_CTR_PHYSICIAN')
  }
  isRadSub(): any {
    return this.hasRole('ROLE_RAD_SUB')
  }
  isGuest(): any {
    return this.hasRole('ROLE_GUEST_ADMIN')
  }
  private hasRole(per: string): boolean {
    if (this.isLoggedIn()) {
      return this.user.roles.includes(per);
    }
    return false;
  }
  changeTheme(styleName: string, logo: string): void {
    this.theme = styleName;
    this.logo = logo;
    const head = document.getElementsByTagName('head')[0];
    const themeLink = document.getElementById('theme-style') as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = `${styleName}.css`;
    } else {
      const style = document.createElement('link');
      style.id = 'theme-style';
      style.rel = 'stylesheet';
      style.href = `${styleName}.css`;
      head.appendChild(style);
    }
  }
  link(path, router) {
    SysService.scrollTo(0);
    this.messageService.clear();
    router.navigateByUrl('/', { skipLocationChange: true }).then(() => router.navigate([path]));
  }
  timerflag = false;
  timerRun;
  timerStart(minute) {
    this.timerflag = true;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;
    const prefix = minute < 10 ? "0" : "";
    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;
      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;
      this.timerRun = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
      if (seconds == 0) {
        clearInterval(timer);
      }
      if (localStorage.getItem('isTimer') === 'false') {
        clearInterval(timer)
      }
    }, 1000);
  }
  arrayBufferToBase64ImageSource(buffer, type = 'png') {
    const base64String = btoa(new Uint8Array(buffer).reduce((data, byte) => {
      return data + String.fromCharCode(byte);
    }, ''));
    return 'data:image/' + type + ';base64,' + base64String;
  }
  loadWalletBalance() {
    if (this.isLoggedIn() && !this.isGuest()) {
      this.apiService.get("/wallet/wallet-balance").then((data) => {
        this.walletBalance = data;
      });
    }
  }
  loadUserName() {
    if (this.isLoggedIn() && !this.isGuest()) {
      this.apiService.get("/users/current-user").then((data) => {
        this.currentUser = data;
      });
    }
  }
  shownHeader: boolean = true;
  filename: string = ''; // Assuming filename is a string property

  viewerCondition(): boolean {
    if (this.isLoggedIn() && location.pathname === '/ecscribeviewer' || this.isLoggedIn() && location.pathname === '/radiologistviewer') {
      this.shownHeader = false;
      return this.shownHeader;
    } else {
      return this.shownHeader;
    }
  }
  capitalizeWords(sentence: string): string {
    return sentence?.replace(/\b\w+\b/g, word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  }
  toDate(v: any) {
    if (v == null) {
      return ''; // or any other default value you want to display for null/undefined
    }

    const isoDateString = v.replace(' ', 'T') + 'Z';


    return new Date(isoDateString).toUTCString();
  }
  // toDate1(v: any) {
  //   if (v == null) {
  //     return 'N/A'; // or any other default value you want to display for null/undefined
  //   }
  //   // Replace spaces with 'T' and add 'Z' to create an ISO date string
  //   const isoDateString = v.replace(' ', 'T') + 'Z';

  //   // Create a Date object from the ISO date string
  //   const dateObject = new Date(isoDateString);

  //   // Extract day, month, and year components from the Date object
  //   const day = dateObject.getUTCDate();
  //   const month = dateObject.getUTCMonth() + 1; // Months are zero-based
  //   const year = dateObject.getUTCFullYear();

  //   // Format the components into "dd/mm/yyyy" format
  //   // const formattedDate = `${day}/${month}/${year}`;
  //   const formattedDate = `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;

  //   return formattedDate;
  // }
  toDate1(v: any) {
    if (v == null) {
      return 'N/A';
    }
    const isoDateString = v.replace(' ', 'T') + 'Z';
    const dateObject = new Date(isoDateString);
    const day = dateObject.getUTCDate().toString(); // Convert to string
    const month = (dateObject.getUTCMonth() + 1).toString(); // Convert to string
    const year = dateObject.getUTCFullYear().toString(); // Convert to string
    // Use padStart on string values
    const formattedDate = `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;

    return formattedDate;
  }

  subRoleFunction(value: any) {
    if (this.isRadSub()) {
      return this.getUser().userSettings?.[value];
    }
    return false;

  }
  formatDateTime() {
    const dateObject = new Date();
    const day = dateObject.getUTCDate().toString().padStart(2, '0');
    const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getUTCFullYear().toString();
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedTime = `${(hours % 12 || 12).toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;

    const formattedDateTime = `${day}/${month}/${year} ${formattedTime}`;
    return formattedDateTime;
  }

  handleSuccessMessage(message: string, bonusType: number, userType) {
    const userDetails = userType === 1 ? 'Center' : 'Radiologist';
    if (bonusType === 3) {
      this.success(`${userDetails} wallet deducted successfully`)
    } else {
      this.success(`${userDetails} wallet credited successfully`);
    }
  }
  getFormattedTime(time: Date, format: string): string {
    const hours: number = time.getHours();
    const minutes: number = time.getMinutes();

    const formattedHours: string = hours.toString().padStart(2, '0');
    const formattedMinutes: string = minutes.toString().padStart(2, '0');

    return format.replace('hh', formattedHours).replace('mm', formattedMinutes);
  }

  getFormattedTimeHours(time: Date, format: string): string {
    const hours: number = time.getHours();
    const minutes: number = time.getMinutes();
    const amOrPm: string = hours >= 12 ? 'PM' : 'AM'; // Determine if it's AM or PM

    // Convert hours to 12-hour format
    const formattedHours: string = ((hours + 11) % 12 + 1).toString().padStart(2, '0');

    // Format minutes with leading zeros
    const formattedMinutes: string = minutes.toString().padStart(2, '0');

    // Replace 'hh' with formatted hours, 'mm' with formatted minutes, and 'a' with AM/PM
    return format.replace('hh', formattedHours).replace('mm', formattedMinutes).replace('a', amOrPm);
  }


  //  getFormattedTime(time: Date, format: string): string {
  //   const hours: number = time.getHours();
  //   const minutes: number = time.getMinutes();

  //   const meridiem: string = hours >= 12 ? 'pm' : 'am';

  //   const formattedHours: string = (hours % 12 || 12).toString().padStart(2, '0');
  //   const formattedMinutes: string = minutes.toString().padStart(2, '0');

  //   return format.replace('hh', formattedHours).replace('mm', formattedMinutes).replace('a', meridiem);
  // }

  // systervoce.ts
  isPermission(): any {
    const routingPath = this.RoutingService.currentRoute;
    const userRoles = this.getUser().roles;
    const userRole = userRoles.length > 0 ? userRoles[0] : null;
    const hasPermission = userRole && RolePermissions[userRole]?.includes(routingPath) || false;
    return hasPermission;
  }

  getAction(settingKey) {
    const userSettings = this.getUser().userSetting;
    if (settingKey.includes(settingKey)) {
      return userSettings[settingKey];
    } else {
      return false;
    }
  }


  scrollHeight(height: any, heightMin: any) {
    const bodyHeight = document.body.offsetHeight;

    if (bodyHeight < 732) {
      height = ((bodyHeight - (heightMin - 28))) + 'px'
    } else {
      height = ((bodyHeight - heightMin)) + 'px'
    }

    return height
  }

  scrollHeightCkeditor(height: any) {

    const bodyHeight = document.body.offsetHeight;

    if (bodyHeight < 732) {
      height = ((bodyHeight - (height))) + 'px'
    } else {
      height = ((bodyHeight - height)) + 'px'
    }

    return height
  }

  zoomOutIN() {
    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 200));
  }
  debounceTimeout: any;

  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };

  }
  detectZoomChange() {

  }
}


