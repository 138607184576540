<div *ngIf="!paymentStatus==true">
  <p class="p-redirection">You will be redirected in a few seconds....</p>

</div>






<p-dialog header="Payment Status" [(visible)]="paymentStatus" (onHide)="redirectPayment()" [style]="{width: '300px'}" [modal]="true"
  [dismissableMask]="true">




  <div class="grid justify-content-center payment-pos ">
    <p-image src="../../assets/images/success.png" alt="Image" width="100"></p-image>
  </div>


  <h1 class="payment">{{ message }}</h1>

  <div class="p-col-fixed text-center">
    <button pButton type="button" label="Done" (click)="redirectPayment()"></button>
  </div>

</p-dialog>