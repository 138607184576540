import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, ExtraOptions } from '@angular/router';
import { CentreRegistrationModule } from './centre-registration/centre-registration.module';
const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'state',
    loadChildren: () => import('./state/state.module').then(m => m.StateModule)
  },
  {
    path: '',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },

  {
    path: 'contacts',
    loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule)
  },
  {
    path: 'desktop-clint',
    loadChildren: () => import('./desktop-clint/desktop-clint.module').then(m => m.DesktopClintModule)
  },
  {
    path: 'modality',
    loadChildren: () => import('./modality/modality.module').then(m => m.ModalityModule)
  },
  {
    path: 'procedures',
    loadChildren: () => import('./procedures/procedures/procedures.module').then(m => m.ProceduresModule)
  },
  {
    path: 'stripe',
    loadChildren: () => import('./payment-info/checkout/checkout.module').then(m => m.CheckoutModule)
  },
  {
    path: 'payment-recharge',
    loadChildren: () => import('./Component-Dashboard/payment-recharge/payment-recharge.module').then(m => m.PaymentRechargeModule)
  },

  {
    path: 'mail-activate',
    loadChildren: () => import('./mail-activate/mail-activate.module').then(m => m.MailActivateModule)
  },
  {
    path: 'studydescription',
    loadChildren: () => import('./study-description/study-description/study-description.module').then(m =>
      m.StudyDescriptionModule)
  },
  {
    path: 'sys-rad-login',
    loadChildren: () => import('./sys-rad-login/sys-rad-login.module').then(m =>
      m.SysRadLoginModule)
  },
  {
    path: 'viewer-redirect',
    loadChildren: () => import('./viewer-redirect/viewer-redirect.module').then(m =>
      m.ViewerRedirectModule)
  },
  {
    path: 'equipment',
    loadChildren: () => import('./equipment/equipment.module').then(m => m.EquipmentModule)
  },
  {
    path: 'Report-template',
    loadChildren: () => import('./report-template/report-template.module').then(m => m.ReportTemplateModule)
  },

  {
    path: 'my-templates',
    loadChildren: () => import('./my-study-rates/my-study-rates.module').then(m => m.MyStudyRatesModule)
  },
  {
    path: 'billing-dashboard',
    loadChildren: () => import('./biiling-dashboard/billing-dashboard.module').then(m => m.BillingDashboardModule)
  },

  {
    path: 'report-template',
    loadChildren: () => import('./report-template/report-template.module').then(m => m.ReportTemplateModule)
  },
  {
    path: 'revenue',
    loadChildren: () => import('./Component-Dashboard/revenue/revenue.module').then(m => m.RevenueModule)
  },


  {
    path: 'groups',
    loadChildren: () => import('./group/group.module').then(m => m.GroupModule)
  },
  {
    path: 'registration-radiologist',
    loadChildren: () => import('./radiologist-registration/radiologist-registration.module').then(m =>
      m.RadiologistRegistrationModule)
  },

  {
    path: 'registration-center',
    loadChildren: () => import('./centre-registration/centre-registration.module').then(m => m.CentreRegistrationModule)
  },
  {
    path: 'registration',
    loadChildren: () => import('./join-eclearity/join-eclearity.module').then(m => m.JoinEclearityModule)
  },
  {
    path: 'centres',
    loadChildren: () => import('./center/center.module').then(m => m.CenterModule)
  },
  {
    path: 'forget-password',
    loadChildren: () => import('./forgot-password/forgetpassword.module').then(m => m.ForgetpasswordModule)
  },
  {
    path: 'resetpwd',
    loadChildren: () => import('./forgot-password/new-password/newpassword.module').then(m => m.NewpasswordModule)
  },
  {
    path: 'primary',
    loadChildren: () => import('./primary-secondary-management/primary-secondary-management.module').then(m =>
      m.PrimarySecondaryManagementModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contactus/contactus.module').then(m => m.ContactusModule)
  }, {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy/privacy.module').then(m => m.PrivacyModule)
  },

  {
    path: 'handle-success',
    loadChildren: () => import('./payment-redirect/payment-redirect.module').then(m => m.PaymentRedirectModule)
  },
  {
    path: 'handle-cancel',
    loadChildren: () => import('./payment-cancel/payment-cancel.module').then(m => m.PaymentCancelModule)
  },
  {
    path: 'payable',
    loadChildren: () => import('./Component-Dashboard/payable/payable.module').then(m => m.PayableModule)
  },
  {
    path: 'wallet-details',
    loadChildren: () => import('./wallet-details/wallet-details.module').then(m => m.WalletDetalisModule)
  },
  {
    path: 'based-upi',
    loadChildren: () => import('./upi-redirect/upi-redirect.module').then(m => m.InvoiceModule)
  },
  {
    path: 'recharge',
    loadChildren: () => import('./Component-Dashboard/recharge/recharge.module').then(m => m.RechargeModule)
  },

  {
    path: 'billing',
    loadChildren: () => import('./my-profile/billing/billing.module').then(m => m.BillingModule)
  },
  {
    path: 'profileview',
    loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfileModule)
  },
  {
    path: 'payment-recharge',
    loadChildren: () => import('./Component-Dashboard/payment-recharge/payment-recharge.module').then(m => m.PaymentRechargeModule)
  },
  {
    path: 'turnaround',
    loadChildren: () => import('./turn-around-time/turn-around-time.module').then(m => m.TurnAroundTimeModule)
  },
  {
    path: 'install',
    loadChildren: () => import('./install-request/install-request.module').then(m => m.InstallRequestModule)
  },
  {
    path: 'Radiologistlist',
    loadChildren: () => import('./radiologist-list/radiologist-list.module').then(m => m.RadiologistListModule)

  },
  {
    path: 'join-eclearity',
    loadChildren: () => import('./join-eclearity/join-eclearity.module').then(m => m.JoinEclearityModule)

  },
  {
    path: 'eclarity-revenue',
    loadChildren: () => import('./Component-Dashboard/eclarity-revenue/eclarity-revenue.module').then(m =>
      m.EclarityRevenueModule)
  },
  {
    path: 'transaction-history',
    loadChildren: () => import('./Component-Dashboard/transaction-history/transaction-history.module').then(m =>
      m.TransactionHistoryModule)
  },

  {
    path: 'ticket',
    loadChildren: () => import('./ticket/ticket.module').then(m => m.TicketModule)
  },
  {
    path: 'report-download',
    loadChildren: () => import('./report-download/report-download.module').then(m => m.ReportDownloadModule)
  },

  {
    path: 'reports',
    loadChildren: () => import('./report/report.module').then(m => m.ReportModule)
  },
  {
    path: 'ecscribeviewer',
    loadChildren: () => import('./viewer/viewer.module').then(m => m.ViewerModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule)

  },
  {
    path: 'cancellations-and-refunds-policy',
    loadChildren: () => import('./cancellation/cancellation.module').then(m => m.CancellationModule)

  },
  {
    path: 'payment-info',
    loadChildren: () => import('./payment-info/payment-info.module').then(m => m.PaymentInfoModule)

  },
  {
    path: 'user-role',
    loadChildren: () => import('./user-role/user-role.module').then(m => m.UserRoleModule)

  },
  {
    path: 'bodypart',
    loadChildren: () => import('./bodypart/bodypart.module').then(m => m.BodypartModule)

  },
  {
    path: 'centre-user',
    loadChildren: () => import('./centre-users/centre-users.module').then(m => m.CentreUsersModule)
  },
  {
    path: 'template-dashboard',
    loadChildren: () => import('./template-dashboard/template-dashboard.module').then(m => m.TemplateDashboard1Module)

  }, {
    path: 'report-viewer',
    loadChildren: () => import('./report-viewer/report-viewer.module').then(m => m.ReportViewerModule)
  },
  {
    path: 'short-code',
    loadChildren: () => import('./short-code/short-code.module').then(m => m.ShortCodeModule)
  },
  {
    path: 'sub-user',
    loadChildren: () => import('./sub-user/sub-user.module').then(m => m.SubUserModule)
  },
  {
    path: 'user-settings',
    loadChildren: () => import('./user-setting/user-setting.module').then(m => m.UserSettingModule)
  },{
    path:'radi-user',
    loadChildren: () => import('./rad-role/rad-role.module').then(m => m.RadiRoleModule)

  },
  {
    path:'sharing-viewer',
    loadChildren:() => import('./shared-viewer/shared-viewer.module').then(m=>m.SharedViewerModule)
  },
  {
    path:'his-interface',
    loadChildren:() => import('./hl7-inferface/hl7-inferface.module').then(m=>m.Hl7InterfaceModule)
  },
  {
    path: 'radiologistviewer',
    loadChildren: () => import('./report-radiologist-viewer/report-radiologist-viewer.module').then(m => m.ReportRadiologistViewerModule)
  },
  {
    path: 'assign-reject',
    loadChildren: () => import('./assign-reject-report/assign-reject-report.module').then(m => m.AssignRejectReportModule)
  },
  {
    path: 'hl7',
    loadChildren: () => import('./hl7/hl7.module').then(m => m.Hl7Module)
  },

  { path: '**', redirectTo: 'login' }
];

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  preloadingStrategy: PreloadAllModules,
  onSameUrlNavigation: 'reload'
};
@NgModule({

  imports: [RouterModule.forRoot(routes, routingConfiguration)],

  exports: [RouterModule]

})

export class AppRoutingModule { }