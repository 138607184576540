<header class="header" *ngIf="!sysService.isLoggedIn()">
    <div class="wrap">
        <div class="right">
            <div class="rgtBdr ">
                <a (click)="navHome()"> <img class="web" src="../../../assets/images/eclogoo.png"></a>
            </div>
        </div>
    </div>

</header>

<div class="grid justify-content-center">
    <div class="card">
        <form [formGroup]="newpassword.form">






            <div class="col-12 mt-2 ">

                <h2>Change Password &nbsp;&nbsp;&nbsp;<a href="/login" class="sig">Sign in</a> </h2>
                <p>Enter a new password below to change your password.</p>


                <div class=" py-1 login-password">
                    <label class="pt-3">New Password </label>
                    <p-password class="pt-3" [toggleMask]="true" formControlName="password" id="password" maxlength="15"
                        required autocomplete="password">
                        <ng-template pTemplate="footer">
                            <p class="p-mt-2">Suggestions</p>
                            <ul class="p-pl-1 p-ml-1 p-mt-0" style="line-height: 1.5">
                                <li>At least one lowercase</li>
                                <li>At least one uppercase</li>
                                <li>At least one numeric</li>
                                <!-- <li>At least one special character like !@$%&?* </li> -->
                                <li>Minimum 8 characters</li>
                            </ul>
                        </ng-template>
                    </p-password>
                    <f-error [err]="newpassword.required('password')" msg="New Password is required"></f-error>
                    <f-error [err]="newpassword.maxlength('password')"
                        msg=" Maximum 15 characters are allowed."></f-error>

                    <f-error [err]="newpassword.minlength('password')" msg="Minumum 8 characters are required.">
                    </f-error>
                    <f-error [err]="newpassword.pattern('password')"
                        msg="At least one lowercase, uppercase and numeric  are required.">
                    </f-error>



                </div>
                <div class="py-1 login-password">
                    <label class="pt-3">Confirm New Password</label>
                    <p-password class="pt-3" [toggleMask]="true" formControlName="confirmPassword" maxlength="15"
                        id="confirmPassword" required autocomplete="off">
                        <ng-template pTemplate="footer">
                            <p class="p-mt-2">Suggestions</p>
                            <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                                <li>At least one lowercase</li>
                                <li>At least one uppercase</li>
                                <li>At least one numeric</li>
                                <!-- <li>At least one special character like !@$%&?* </li> -->
                                <li>Minimum 8 characters</li>
                            </ul>
                        </ng-template>
                    </p-password>
                    <f-error [err]="newpassword.required('confirmPassword')" msg="Confirm New Password is required ">
                    </f-error>
                    <f-error [err]="newpassword.hasError('notSame')"
                        msg="Your password and your confirm password do not match. Please correct and resubmit">
                    </f-error>
                    <f-error [err]="newpassword.minlength('confirmPassword')" msg="Minumum 8 characters are required.">
                    </f-error>
                    <f-error [err]="newpassword.maxlength('password')"
                        msg=" Maximum 15 characters are allowed."></f-error>

                    <f-error [err]="newpassword.pattern('confirmPassword')"
                        msg="At least one lowercase, uppercase and numeric  are required.">
                    </f-error>


                </div>
                <div class="formgrid text-center p-6">
                    <button pButton pRipple type="button" label="Cancel" icon="pi pi-times"
                        class="p-button-outlined p-button-info" (click)="cancel()"></button>

                    <button pButton pRipple type="button" label=" Submit" icon="pi pi-check"
                        class="p-button-raised p-button-success" (click)="submit()">

                        <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                    </button>

                </div>
            </div>

            <div class="formgrid text-center p-2">
                <label> Has your password changed?
                    If you no longer use the Password associated
                    with your eClarity account, you may
                    contact
                    <a href="mailto:hello@ecscribe.com">hello@ecscribe.com
                    </a> for help restoring access to your account.
                </label>

            </div>



        </form>

    </div>

</div>