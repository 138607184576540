<p-toolbar>
  <ng-template pTemplate="left">
    <f-nav first="/state" label="State" [model]="state"> </f-nav>
  </ng-template>
  <ng-template pTemplate="right">
    <button pButton pRipple type="button" label="" icon="pi pi-trash"
      class="p-button-outlined p-button-rounded p-button-danger" (click)="deleteState(state.id)"
      *ngIf="state.isEdit()"></button>
  </ng-template>
</p-toolbar>

<form [formGroup]="state.form">
  <div *ngIf="state.isform || state.isView()" class="card">
    <div class="grid fluid">
      <div class="field col-12 md:col-12 lg:col-6">
        <label for="name">Name :*</label>
        <input type="text" pInputText id="name" formControlName="name" required autofocus />
        <f-error [err]="state.required('name')" msg="Name is required"></f-error>
      </div>
      <div class="field col-12 md:col-12 lg:col-6">
        <label for="countryId">Country :</label>
        <p-autoComplete forceSelection="true" formControlName="countryId" [suggestions]="countryLookup"
          (completeMethod)="filterCountry($event)" field="name" ariaLabel="Country" [dropdown]="true"
          [autoHighlight]="true" showEmptyMessage="true" delay="400">
        </p-autoComplete>
      </div>
      <div class="field col-12 md:col-12 lg:col-6">
        <label for="code">Code :</label>
        <textarea id="code" pInputTextarea formControlName="code" required rows="3" cols="20"></textarea>
        <f-error [err]="state.required('code')" msg="Code is required"></f-error>
      </div>
      <div class="field col-12 md:col-12 lg:col-6">
        <label for="taxCode">Tax Code :</label>
        <p-inputNumber id="taxCode" formControlName="taxCode"></p-inputNumber>
      </div>
    </div>
    <div class="formgrid text-center bc">
      <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" class="p-button-text p-button-rounded"
        (click)="listView(state.isEmptyList());"></button>
      <button pButton pRipple type="button" label=" Save" icon="pi pi-check" class="p-button-success p-button-rounded"
        (click)="saveState()" [disabled]="sysService.loading" *ngIf="state.isForm()">
        <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
      </button>
      <button pButton pRipple type="button" label=" Save & Add" icon="pi pi-check"
        class="p-button-secondry p-button-rounded p-button-outlined" (click)="saveState(true)"
        [disabled]="sysService.loading" *ngIf="state.isNew()">
        <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
      </button>
    </div>
  </div>
</form>

<div [style.display]="state.islist ? 'block':'none'" class="card-list">
  <p-table #dt [value]="state.list" [totalRecords]="state.total" [scrollable]="true" scrollHeight="65vh" [lazy]="true"
    (onLazyLoad)="listStateLazy($event)" selectionMode="multiple" dataKey="id" [(selection)]="state.selection"
    [loading]="sysService.loading" filterDelay="400" currentPageReportTemplate="{first} to {last} of {totalRecords}"
    [rowsPerPageOptions]="[25,50,]" [rowHover]="true" styleClass="p-datatable-sm" [rows]="25" [paginator]="true"
    [showCurrentPageReport]="true" alwaysShow="false" paginatorPosition="bottom"
    styleClass="p-datatable-sm p-datatable-gridlines" [globalFilterFields]="['name','code','taxCode','countryId.name']">

    <ng-template pTemplate="caption">
      <div class="grid">
        <div class="col-6 md:col-4 lg:col-2">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input pInputText type="text" maxlength="150" [(ngModel)]="state.searchTxt"
              (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
          </span>
        </div>
        <div class="col-6 md:col-4 lg:col-10 text-left">
          <span>
            <button pButton pRipple type="button" label="Add" icon="pi pi-plus" *ngIf="state.isList()"
              (click)="add()"></button>
          </span>
        </div>
        <!-- <div class="col-4 md:col-4 lg:col-6 text-right">
          <t-export [header]="stateExport" [rows]="state.list" file="State"></t-export>
        </div> -->
      </div>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
      <button pButton pRipple type="button" label="" icon="pi pi-trash"
        class="p-button-outlined p-button-rounded p-button-danger" (click)="deleteStates()"
        [disabled]="state.selection.length < 1" *ngIf="!state.isEmptyList()"></button>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="code">Code <p-sortIcon field="code"></p-sortIcon>
        </th>
        <th pSortableColumn="taxCode">Tax Code <p-sortIcon field="taxCode"></p-sortIcon>
        </th>
        <th pSortableColumn="countryId.name">Country <p-sortIcon field="countryId.name"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-state>
      <tr [ngClass]="{'ui-state-highlight': chk.checked}">
        <td>
          <p-tableCheckbox #chk [value]="state"></p-tableCheckbox>
        </td>
        <td class="link" (click)="edit(state.id)">{{state.name}}</td>
        <td>{{state.code}}</td>
        <td>{{state.taxCode}}</td>
        <td>{{state.countryId?.name}}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td class="norecord" [attr.colspan]="5">No records found</td>
      </tr>
    </ng-template>
  </p-table>
</div>