<div class="grid">
  <div class="col-12 right-button">
    <p> Need help...? &nbsp;<a href="https://www.ecscribe.com" target="_blank" class="link">Click here
      </a>
    </p>
  </div>
</div>

<form [formGroup]="radiologist.form">
  <div class="card">
    <div class="grid">
      <div class="field col-12 md:col-6 lg:col-4">
        <label>Prefix</label>
        <input pInputText maxlength="12" formControlName="prefix" type="text" pAutoFocus autofocus="true">
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <label>Full Name <span class="validation-star">*</span></label>
        <input pInputText maxlength="50" formControlName="name" type="text">
        <f-error [err]="radiologist.required('name')" msg="Full Name is
            required"></f-error>
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <label>Suffix</label>
        <input pInputText maxlength="100" formControlName="suffix" type="text">
      </div>
      <div class="field col-12 md:col-6 lg:col-4">

        <label for="EmailAddress">Email Address<span class="validation-star">*</span> &nbsp;(This will also be
          your Username) </label>
        <input id="EmailAddress" type="text" maxlength="100" [readOnly]="true" pInputText formControlName="email"
          required />
        <f-error [err]="radiologist.required('email')" msg="Email Address is required "></f-error>
        <f-error [err]="radiologist.pattern('email')"
          msg="This e-mail address is not valid. Valid records include to user@somehost.somedomain. And emails only accept lowercase letters.">
        </f-error>
      </div>

      <div class="field col-12 md:col-6  lg:col-4">
        <label>Phone Number <span class="validation-star">*</span></label>
        <input pInputText maxlength="18" formControlName="phoneNo" type="text">
        <f-error [err]="radiologist.required('phoneNo')" msg="Phone Number is required"></f-error>
        <f-error [err]="radiologist.pattern('phoneNo')" msg="Numbers only required"></f-error>
        <f-error [err]="radiologist.minlength('phoneNo')" msg="Enter a minimum of 7 digits"></f-error>
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <label>Date of Birth <span class="validation-star">*</span></label>

        <p-calendar [showIcon]="true" [defaultDate]="maxDate" [maxDate]="maxDate" formControlName="dob"
          dateFormat="dd-mm-yy" placeholder="DD-MM-YYYY" dataType="string" [showIcon]="true"
          class="widthClass"></p-calendar>
        <f-error [err]="radiologist.required('dob')" msg="Date of Birth  is required"></f-error>
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <label>Qualification <span class="validation-star">*</span></label>

        <input pInputText maxlength="100" formControlName="qualifications" type="email">
        <f-error [err]="radiologist.required('qualifications')" msg="Qualification is required"></f-error>
      </div>
      <div class="field col-12 md:col-6  lg:col-4">
        <label>Designation </label>
        <input pInputText maxlength="50" formControlName="designation" type="text">

      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <label>Register No <span class="validation-star">*</span></label>
        <input pInputText maxlength="50" formControlName="registrationNo" type="text">
        <f-error [err]="radiologist.required('registrationNo')" msg="Register No is required"></f-error>
      </div>

      <div class="field col-12 md:col-6 lg:col-4">
        <label>Experience after basic radiology training </label>
        <input pInputText maxlength="495" formControlName="experience" type="text">
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <label>Additional Qualification</label>
        <input pInputText maxlength="240" formControlName="additionalQualifications" type="text">

      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <label>Additional Training </label>
        <input pInputText maxlength="254" formControlName="additionalTraining" type="text">
      </div>


      <div class="field col-12 md:col-6 lg:col-4">

        <label>Country <span class="validation-star">*</span></label>
        <p-autoComplete formControlName="countryId" [suggestions]="countryLookup" (onSelect)="stateAreaOnChange($event)"
          forceSelection="true" maxlength="60" (completeMethod)="filterCountry($event)" field="name" ariaLabel="Country"
          [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400">
        </p-autoComplete>
        <f-error [err]="radiologist.required('countryId')" msg="Country is required"></f-error>
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <label>State </label>
        <p-autoComplete formControlName="stateId" [suggestions]="stateLookup" (completeMethod)="filterState($event)"
          maxlength="60" forceSelection="true" field="name" ariaLabel="State" [dropdown]="true" [autoHighlight]="true"
          showEmptyMessage="true" delay="400">
        </p-autoComplete>
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <label>Address <span class="validation-star">*</span></label><textarea class="p-inputtextarea
            p-inputtext p-component p-element" [rows]="3" [cols]="40" maxlength="480" formControlName="address"
          pInputTextarea></textarea>
        <f-error [err]="radiologist.required('address')" msg="Address is required"></f-error>
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <label>Zip Code <span class="validation-star">*</span> </label>
        <input pInputText maxlength="12" formControlName="zip" type="text">
        <f-error [err]="radiologist.required('zip')" msg="Zip Code is required"></f-error>
        <f-error [err]="radiologist.pattern('zip')" msg=" Only alphanumeric characters are accepted"></f-error>
      </div>
      <div class="field col-12 md:col-6 lg:col-4 mt-3">
        <!-- <div class="grid">
          <div class="col-6">
            <label>Upload Your signature</label> &nbsp;<i class="pi pi-info-circle link" pTooltip="Instructions"
              (click)="sigRoles()"></i><br>
            <input type="file" #fileInput id="document" formControlName="docFile1"
              (change)="selectFile($event.target.files, 'signature')" accept=".jpg,.jpeg,.png" />
            {{ getFilename(radiologist?.view?.signature) }}
          </div>
          <div class="col-6 text-center base64img">
            <div class="image-container">
              <p-image [src]="!hasSignature ? imageurl : signature"></p-image>
              <span class="close-icon" *ngIf="hasSignature || signature ===null" (click)="closesig(radiologist)"
                [style.display]="buttonVisible1 ? 'block' : 'none'">&#10006;</span>
            </div>
          </div>
        </div> -->

        <div class="grid stamp-pos">

          <div class="col-12 lg:col-4">
            <!-- <p>{{radiologist.form.value | json}}</p> -->
            <div class="py-3"> <label>Upload Your signature</label>&nbsp;&nbsp;<i class="pi pi-info-circle link"
                pTooltip="Instructions" (click)="sigRoles()"></i></div>
            <div class="file-upload-sec"><input type="file" #fileInput id="document" formControlName="docFile1"
                (change)="selectFile($event.target.files,'signature')" accept=".jpg,.jpeg,.png" />
            </div>

          </div>


          <div class="col-6 lg:col-3 text-center ">
            <div class="ml-2 stamp"> <p-image [src]="!hasSignature ? imageurl : signature"></p-image>
              <span class="close-icon btn-tr" *ngIf="hasSignature && this.radiologist.get('signature')"
                (click)="closeSig(radiologist)" [style.display]="buttonVisible1 ? 'block' : 'none'">&#10006;</span>
              <div class="pt-2 ">{{ getFilename(radiologist?.view?.signature)
                }}</div>
            </div>


          </div>

        </div>
      </div>
      <div class="field col-12 md:col-6 lg:col-4 mt-3">
        <div class="grid stamp-pos">

          <div class="col-12 lg:col-4">

            <div class="py-3"> <label>Upload Your Stamp</label>&nbsp;&nbsp;<i class="pi pi-info-circle link"
                pTooltip="Instructions" (click)="stampRoles()"></i></div>
            <div class="file-upload-sec">
              <input type="file" #fileInput1 id="document" formControlName="docFile2"
                (change)="selectFile1($event.target.files,'stamp')" accept=".jpg,.jpeg,.png" />
            </div>

          </div>


          <div class="col-6 lg:col-3 text-center ">
            <div class="ml-2 stamp"> <p-image [src]="!hasStamp ? imageurl : stamp"></p-image>
              <span class="close-icon btn-tr" *ngIf="hasStamp && this.radiologist.get('stamp')"
                (click)="closeStam(radiologist)" [style.display]="buttonVisible ? 'block' : 'none'">&#10006;</span>
              <div class="pt-2">{{ getFilename(radiologist?.view?.stamp) }}</div>
            </div>

          </div>

        </div>
      </div>
    </div>

    <div class="grid justify-content-center p-2">

      <button pButton pRipple type="button" label=" Deactivate" icon="pi pi-user-minus"
        class="p-button-raised p-button-secondary m-1" (click)="radiologistDeactivte()" [disabled]="sysService.loading">

        <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
      </button>

      <button pButton pRipple type="button" label="Delete My Account" (click)="deteleRadiologist()" icon=" pi pi-trash"
        class="p-button-raised p-button-danger m-1">
        <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i></button>
      <button pButton pRipple type="button" label="Update" (click)="saveradiologist()" icon=" pi pi-check"
        class="p-button-raised p-button-success m-1" [disabled]="sysService.loading">
        <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i></button>

    </div>
  </div>

</form>
<p-dialog header=" Instructions " [dismissableMask]="true" [(visible)]="sigRole" [modal]="true">
  <label>"Signature should be in 275x150 width and height,<br> and the size should be 200KB."</label>
</p-dialog>
<p-dialog header=" Instructions " [dismissableMask]="true" [(visible)]="stampRole" [modal]="true">
  <label>"Stamp should be in 275x150 width and height,<br> and the size should be 200KB."</label>
</p-dialog>