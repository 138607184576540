import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StaticService, SysService, UserService, ViewFactory } from '@app/_common';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-sys-rad-redirect',
  templateUrl: './viewer-redirect.component.html',
  styleUrls: ['./viewer-redirect.component.scss']
})
export class ViewerRedirectComponent implements OnInit {
  constructor(
    public sysService: SysService,
    public userService: UserService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private router: Router,
    private viewFactory: ViewFactory
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const token = params.token;
      this.userService.saveToken(token);
      this.userService.saveToken(environment.backend.spring ? token: token);(token);

      const queryParams = { ...params };
      delete queryParams.token;
      this.router.navigate(['/ecscribeviewer'], { queryParams });

    });
  }
}
