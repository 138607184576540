import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
  FormDefault,
  Model,
  StaticService,
  SysService,
  View,
  ViewFactory,
} from "@app/_common";
import { environment } from "@environments/environment";
import { Console } from "console";
@Component({
  selector: "app-turn-around-time",
  templateUrl: "./turn-around-time.component.html",
  styleUrls: ["./turn-around-time.component.css"],
  providers: [DatePipe],
})
export class TurnAroundTimeComponent implements OnInit {
  @ViewChild("calendarRef") calendar: any;
  TurnAroundTimeExport = ["name", "taxCode", "code", "countryId.name"]; // for export header fields
  private TurnAroundTimeView: View = this.viewFactory.newView();
  modalityLookup: any[];
  radLookup: any[];
  cenLookup: any[];

  modalityDataAxis: any;
  showRange: number = 5;
  radilogistaxis: any;
  rejectedStudies: any;
  rejectedStudiesAxis: any;
  revenueDataAxis: any;
  revenueData: any;
  tatData: any;
  totalRevenueData: any;
  tatDataAxis: any;
  allStudiesData: any;
  allStudiesDataAxies: any;
  calendarSelected = false;
  currentDate: Date;
  selectedRange: Date[];
  data: any;
  dataSet2: any;
  options: any;
  optionsSet: any;
  piechartStudies: any;
  piechartMonth: any;
  TATvisible: boolean = false;
  TopupTat: boolean = false;
  tatRevenueVisible: boolean = false;
  rejectReportTat: boolean = false;
  clickedMonth: any;
  clickedYear: any;
  chartnameset: any;
  chartname: any;
  visible: boolean = false;
  balanceData: any[] = [];
  filterByStudyStatusCode: any = 1;
  constructor(
    public sysService: SysService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public staticService: StaticService,
    private viewFactory: ViewFactory,
    private datePipe: DatePipe
  ) { }
  ngOnInit() {
    this.TurnAroundTimeView.of(this.TurnAroundTimeModel());
    this.TurnAroundTimeData();
    this.filterMod(Event);
    this.filterRad(Event);
    this.filterCen(Event);
    this.showRange = environment.showRange;
  }
  get TurnAroundTime() {
    return this.TurnAroundTimeView.model;
  }
  private TurnAroundTimeModel() {
    const formDefault = new FormDefault(["studyIuid"], []);
    return new Model(
      this.formBuilder.group({
        id: [],
        modality: [],
        radId: [],
        cenId: [],
        date: [""],
      }),
      this.sysService,
      formDefault,
      ""
    );
  }
  turnTime() {
    const createdDate = String(this.TurnAroundTime.get("date"));
    const startDate = new Date(createdDate.split(",")[0]);
    const endDate = new Date(createdDate.split(",")[1]);
    const formattedStartDate = `${startDate
      .getDate()
      .toString()
      .padStart(2, "0")}/${(startDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${startDate.getFullYear()}`;
    const formattedEndDate = `${endDate
      .getDate()
      .toString()
      .padStart(2, "0")}/${(endDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${endDate.getFullYear()}`;
    const rangeDate = `${formattedStartDate}-${formattedEndDate}`;
    if (!this.TurnAroundTime.isEmpty("date")) {
      this.TurnAroundTimeView.setParam("date", rangeDate);
    }
    this.TurnAroundTimeView.setParam("radId", this.TurnAroundTime.get("radId"));
    this.TurnAroundTimeView.setParam(
      "modality",
      this.TurnAroundTime.get("modality")
    );
    this.TurnAroundTimeView.setParam("cenId", this.TurnAroundTime.get("cenId"));
    this.TurnAroundTimeData();
  }
  filterMod(event: any) {
    this.TurnAroundTimeView.getData(`/modality/modality-lookup?filter=`).then(
      (data) => {
        this.modalityLookup = data;
      }
    );
  }
  filterRad(event: any) {
    this.TurnAroundTimeView.getData(
      `/radiologist/radiologist-lookup?filter=`
    ).then((data) => {
      this.radLookup = data;
    });
  }
  filterCen(event: any) {
    this.TurnAroundTimeView.getData(`/centre/centre-lookup?filter=`).then(
      (data) => {
        this.cenLookup = data;
      }
    );
  }

  reset() {
    this.TurnAroundTime.reset();
    this.TurnAroundTimeData();
  }

  TurnAroundTimeData() {
    this.studiesByInsight() //ALL STUDIES
    this.tatByAverageTime() //AVERAGE TURN AROUND TIME
    this.tatByRevenue() // TOTAL REVENUE
    this.tatByRejectStudies() //REJECTED REPORTS
  }


  // ------------------------------  ALL STUDIES --------------------------------//
  studiesByInsight() {
    this.TurnAroundTimeView.getData("/turnaroundtime/studies-tat").then(
      (res) => {
        this.allStudiesData = {
          labels: res.month, // Assuming res.month contains an array of months
          datasets: [
            // {
            //   label: "Studies Per Month", // Changing the label to represent the Y-axis
            //   data: res.studiesPerMonth, // Assuming res.studiesPerMonth contains the studies per month data
            //   backgroundColor: "#BF7C1A",
            //   color: "#fff",
            // },
            {
              label: "Completed Studies Per Month",
              backgroundColor: "#40e82a",
              data: res.completedStudiesPerMonth,
            },
          ],
        };

        this.allStudiesDataAxies = {
          scales: {
            x: {
              title: {
                display: true,
                text: "Month",
                color: "#fff",
                style: {
                  color: "#fff",
                },
              },
              grid: {
                color: "rgba(0, 0, 0, 0.1)",
              },
            },
            y: {
              title: {
                display: true,
                text: "Studies Per Month",
                color: "#fff",
                style: {
                  color: "#fff",
                },
              },
              grid: {
                color: "rgba(0, 0, 0, 0.1)",
              },
            },
          },
          onClick: (event, elements) => {
            if (elements.length > 0) {
              const elementIndex = elements[0].index;
              const datasetIndex = elements[0].datasetIndex;
              this.clickedMonth = res.yearAndMonth[elementIndex].month;
              this.clickedYear = res.yearAndMonth[elementIndex].year;
              this.studyWisePopup(
                this.clickedMonth,
                this.clickedYear,
                datasetIndex
              );
            }
          },
        };
      }
    );

  }
  studyWisePopupCentreTable: any; // number = 1
  studyWisePopupRadiologistTable: any; // number = 2  
  studyWisePopupRejectedTable: any; // number = 3
  studyWisePopupAssignRejectedTable: any; // number = 4



  studyWisePopup(month: string, year: string, datasetIndex: number): void {
    this.TATvisible = false;
    if (!this.sysService.isAdmin()) {
      this.TurnAroundTimeView.getData(
        `/turnaroundtime/studies-tat-for-rad?month=${month}&year=${year}`
      ).then((data) => {
        this.studyWisePopupRadiologistTable = data
        this.chartStudyForRad(data, datasetIndex);

      });
    }
    this.TurnAroundTimeView.getData(
      `/turnaroundtime/studies-tat-for-cen?month=${month}&year=${year}`
    ).then((data) => {
      this.studyWisePopupCentreTable = data
      this.chartStudyForCen(data, datasetIndex);
    });
    this.TATvisible = true;
  }

  chartStudyForRad(data: any, datasetIndex: number) {
    this.chartname = datasetIndex === 0 ? "Studies Per Month" : "Completed Studies";
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");

    this.data = {
      labels: data.userName.slice(0, this.showRange),
      datasets: [
        {
          data: data.completedStudiesPerMonth.slice(0, this.showRange),
          backgroundColor: [
            documentStyle.getPropertyValue("--blue-500"),
            documentStyle.getPropertyValue("--yellow-500"),
            documentStyle.getPropertyValue("--green-500"),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue("--blue-400"),
            documentStyle.getPropertyValue("--yellow-400"),
            documentStyle.getPropertyValue("--green-400"),
          ],
        },
      ],
    };

    this.options = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
            color: textColor || "white", // Use the extracted textColor or default to white
          },
          position: "right", // Change legend position
        },
      },

    };
  }


  chartStudyForCen(data: any, datasetIndex: number) {

    this.chartname = datasetIndex === 0 ? "All Studies " : "Completed Studies ";
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");

    this.dataSet2 = {
      labels: data.userName.slice(0, this.showRange),
      datasets: [
        {
          // data:
          //   datasetIndex === 0
          //     ? data.studiesPerMonth
          //     : data.completedStudiesPerMonth,
          data: data.completedStudiesPerMonth.slice(0, this.showRange),
          backgroundColor: [
            documentStyle.getPropertyValue("--blue-500"),
            documentStyle.getPropertyValue("--yellow-500"),
            documentStyle.getPropertyValue("--green-500"),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue("--blue-400"),
            documentStyle.getPropertyValue("--yellow-400"),
            documentStyle.getPropertyValue("--green-400"),
          ],
        },
      ],
    };

    this.optionsSet = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
            color: "white",
          },
          position: "right",
        },
      },

    };
  }


  //----------------------------------End-----------------------------------------------

  //----------------------------- AVERAGE TURN AROUND TIME ------------------------------


  tatByAverageTime() {
    this.TurnAroundTimeView.getData("/turnaroundtime/assign-complete-tat").then(
      (res) => {
        this.tatData = {
          labels: res.month,
          datasets: [
            {
              label: "Average Time",
              data: res.averageHour,
              backgroundColor: "#BF7C1A",
              color: "#fff",
            },
          ],
        };

        this.tatDataAxis = {
          scales: {
            x: {
              title: {
                display: true,
                text: "Month",
                color: "#fff",
                style: {
                  color: "#fff",
                },
              },
              grid: {
                color: "rgba(0, 0, 0, 0.1)",
              },
            },
            y: {
              title: {
                display: true,
                text: "Average Day",
                color: "#fff",
                style: {
                  color: "#fff",
                },
              },
              grid: {
                color: "rgba(0, 0, 0, 0.1)",
              },
            },
          },
          onClick: (event, elements) => {
            console.log(elements);
            if (elements.length > 0) {
              const elementIndex = elements[0].index;
              const datasetIndex = elements[0].datasetIndex;
              this.clickedMonth = res.yearMonthCountDto[elementIndex].month;
              this.clickedYear = res.yearMonthCountDto[elementIndex].year;
              this.handleTATMonthClick(
                this.clickedMonth,
                this.clickedYear,
                datasetIndex
              );
            }
          },
        };
      }
    );

  }

  chartAverageCenTime(data: any, datasetIndex: number) {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    this.data = {
      labels: data.userNames.slice(0, this.showRange),
      datasets: [
        {
          data: data.avgTimes.slice(0, this.showRange),
          backgroundColor: [
            documentStyle.getPropertyValue("--blue-500"),
            documentStyle.getPropertyValue("--yellow-500"),
            documentStyle.getPropertyValue("--green-500"),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue("--blue-400"),
            documentStyle.getPropertyValue("--yellow-400"),
            documentStyle.getPropertyValue("--green-400"),
          ],
        },
      ],
    };

    this.options = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
            color: "white",
          },
          position: "right", // Change legend position
        },
      },
    };
  }
  chartAverageRadTime(data: any, datasetIndex: number) {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");

    this.dataSet2 = {
      labels: data.userNames.slice(0, this.showRange),
      datasets: [
        {
          data: data.avgTimes.slice(0, this.showRange),
          backgroundColor: [
            documentStyle.getPropertyValue("--blue-500"),
            documentStyle.getPropertyValue("--yellow-500"),
            documentStyle.getPropertyValue("--green-500"),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue("--blue-400"),
            documentStyle.getPropertyValue("--yellow-400"),
            documentStyle.getPropertyValue("--green-400"),
          ],
        },
      ],
    };

    this.optionsSet = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
            color: "white",
          },
          position: "right",
        },
      },
    };
  }




  handleTATMonthClick(month: string, year: string, datasetIndex: number): void {
    this.TopupTat = false;
    this.paraValues()
    this.TurnAroundTimeView.getData(
      `/turnaroundtime/complete-tat-cen?month=${month}&year=${year}`
    ).then((data) => {
      this.chartAverageCenTime(data, datasetIndex);
    });
    this.TurnAroundTimeView.getData(
      `/turnaroundtime/complete-tat-rad?month=${month}&year=${year}`
    ).then((data) => {
      this.chartAverageRadTime(data, datasetIndex);
    });
    this.TopupTat = true;
  }
  //-------------------------------end (AVERAGE TURN AROUND TIME)-------------------------------------------


  //----------------------------------------REJECTED REPORTS------------------------------------------------
  tatByRejectStudies() {
    this.TurnAroundTimeView.getData(
      "/turnaroundtime/rejected-reports-tat"
    ).then((res) => {
      this.rejectedStudies = {
        labels: res.month,
        datasets: [
          {
            label: "Reports Per Month",
            data: res.studiesPerMonth,
            backgroundColor: "#BF7C1A",
            color: "#fff",
          },
        ],
      };
      this.rejectedStudiesAxis = {
        scales: {
          x: {
            title: {
              display: true,
              text: "Month",
              color: "#fff",
              style: {
                color: "#fff",
              },
            },
            grid: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },
          y: {
            title: {
              display: true,
              text: "Reports Per Month",
              color: "#fff",
              style: {
                color: "#fff",
              },
            },
            grid: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },

        },
        onClick: (event, elements) => {
          console.log(elements);
          if (elements.length > 0) {
            const elementIndex = elements[0].index;
            const datasetIndex = elements[0].datasetIndex;
            this.clickedMonth = res.yearMonthCountDto[elementIndex].month;
            this.clickedYear = res.yearMonthCountDto[elementIndex].year;
            this.handleRejectMonthClick(
              this.clickedMonth,
              this.clickedYear,
              datasetIndex
            );
          }
        },
      };
    });

  }


  handleRejectMonthClick(month: string, year: string, datasetIndex: number): void {
    this.rejectReportTat = false;
    this.paraValues()
    this.TurnAroundTimeView.getData(
      `/turnaroundtime/rej-reports-my-tat-pie?month=${month}&year=${year}`
    ).then((data) => {
      this.studyWisePopupRejectedTable = data;
      this.chartRejectfun(data, datasetIndex);
    });
    this.TurnAroundTimeView.getData(
      `/turnaroundtime/rej-reports-assign-tat-pie?month=${month}&year=${year}`
    ).then((data) => {
      this.studyWisePopupAssignRejectedTable = data;
      this.chartAssignFun(data, datasetIndex);
    });
    this.rejectReportTat = true;

  }


  chartRejectfun(data: any, datasetIndex: number) {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");

    this.data = {
      labels: data.rejByUserName.slice(0, this.showRange),
      datasets: [
        {
          data: data.studiesPerUser.slice(0, this.showRange),
          backgroundColor: [
            documentStyle.getPropertyValue("--blue-500"),
            documentStyle.getPropertyValue("--yellow-500"),
            documentStyle.getPropertyValue("--green-500"),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue("--blue-400"),
            documentStyle.getPropertyValue("--yellow-400"),
            documentStyle.getPropertyValue("--green-400"),
          ],
        },
      ],
    };

    this.options = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
            color: "white",
          },
          position: "right", // Change legend position
        },
      },
    };

  }


  chartAssignFun(data: any, datasetIndex: number) {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");

    this.data = {
      labels: data.rejByUserName.slice(0, this.showRange),
      datasets: [
        {
          data: data.studiesPerUser.slice(0, this.showRange),
          backgroundColor: [
            documentStyle.getPropertyValue("--blue-500"),
            documentStyle.getPropertyValue("--yellow-500"),
            documentStyle.getPropertyValue("--green-500"),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue("--blue-400"),
            documentStyle.getPropertyValue("--yellow-400"),
            documentStyle.getPropertyValue("--green-400"),
          ],
        },
      ],
    };

    this.options = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
            color: "white",
          },
          position: "right", // Change legend position
        },
      },
    };
  }



  // ---------------------------------------TOTAL REVENUE-------------------------------------------------
  tatByRevenue() {
    if (!this.sysService.isCenter()) {
      this.TurnAroundTimeView.getData("/turnaroundtime/revenue-tat").then(
        (res) => {
          this.totalRevenueData = res.totalRevenue;
          this.revenueData = {
            labels: res.month,
            datasets: [
              {
                label: "Revenue",
                data: res.revenue,
                backgroundColor: "#BF7C1A",
                color: "#fff",
              },
            ],
          };

          this.revenueDataAxis = {
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Month",
                  color: "#fff",
                  style: {
                    color: "#fff",
                  },
                },
                grid: {
                  color: "rgba(0, 0, 0, 0.1)",
                },
              },
              y: {
                title: {
                  display: true,
                  text: "Revenue",
                  color: "#fff",
                  style: {
                    color: "#fff",
                  },
                },
                grid: {
                  color: "rgba(0, 0, 0, 0.1)",
                },
              },
            },

            onClick: (event, elements) => {
              if (elements.length > 0) {
                const elementIndex = elements[0].index;
                const Payables = res.payables[elementIndex];
                const Receivables = res.receivables[elementIndex];
                this.revenuechart(Payables, Receivables);
              }
            },
          };
        }
      );
    }
    if (this.sysService.isCenter()) {
      this.TurnAroundTimeView.getData("/turnaroundtime/revenue-tat").then(
        (res) => {
          this.totalRevenueData = res.totalRevenue;
          this.revenueData = {
            labels: res.month,
            datasets: [
              {
                label: "Payables",
                backgroundColor: "#40e82a",
                data: res.payables,
              },
            ],
          };

          this.revenueDataAxis = {
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Month",
                  color: "#fff",
                  style: {
                    color: "#fff",
                  },
                },
                grid: {
                  color: "rgba(0, 0, 0, 0.1)",
                },
              },
              y: {
                title: {
                  display: true,
                  text: "Revenue",
                  color: "#fff",
                  style: {
                    color: "#fff",
                  },
                },
                grid: {
                  color: "rgba(0, 0, 0, 0.1)",
                },
              },
            },
          };
        }
      );
    }

  }


  revenuechart(payables: number, receivables: number) {
    this.tatRevenueVisible = false;
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    this.data = {
      labels: [" Recievables", "Payables"],
      datasets: [
        {
          data: [receivables, payables],
          backgroundColor: [
            documentStyle.getPropertyValue("--orange-500"),
            documentStyle.getPropertyValue("--green-500"),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue("--orange-400"),
            documentStyle.getPropertyValue("--green-400"),
          ],
        },
      ],
    };

    this.options = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
            color: textColor,
          },
        },
      },
    };
    this.tatRevenueVisible = true;

  }



  // ------------------------------------Table List-----------------------------------

  showTableList(number: any) {
    let data;
    let dataLabel
    let secondaryData;
    if (number == 1) {
      data = this.studyWisePopupCentreTable
      dataLabel = this.studyWisePopupCentreTable.userName
      secondaryData = this.studyWisePopupCentreTable.completedStudiesPerMonth
      this.visible = true;
    }
    if (number == 2) {
      data = this.studyWisePopupRadiologistTable
      dataLabel = this.studyWisePopupRadiologistTable.userName
      secondaryData = this.studyWisePopupRadiologistTable.completedStudiesPerMonth
      this.visible = true;
    }
    if (number == 3) {
      data = this.studyWisePopupRejectedTable
      dataLabel = this.studyWisePopupRejectedTable.rejByUserName
      secondaryData = this.studyWisePopupRejectedTable.studiesPerUser

      this.visible = true;
    }
    if (number == 4) {
      data = this.studyWisePopupAssignRejectedTable
      dataLabel = this.studyWisePopupAssignRejectedTable.rejByUserName
      secondaryData = this.studyWisePopupAssignRejectedTable.studiesPerUser

      this.visible = true;
    }

    const formattedData = dataLabel.map((name, index) => ({
      id: index + 1,
      userName: name.trim(), // Trimming whitespace
      value: secondaryData[index].toString()
    }))
    console.log(formattedData)
    this.balanceData = formattedData;
  }

  //-------------------------------------End---------------------------------------











  paraValues() {
    this.TurnAroundTimeView.setParam("radId", this.TurnAroundTime.get("radId"));
    this.TurnAroundTimeView.setParam(
      "modality",
      this.TurnAroundTime.get("modality")
    );
    this.TurnAroundTimeView.setParam("cenId", this.TurnAroundTime.get("cenId"));
  }


}
