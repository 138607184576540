<!-- menutree -->
<div *ngIf="!sysService.isPermission()">
    <not-found></not-found>
</div>
<div *ngIf="sysService.isPermission()">
    <p-toolbar>
        <div class="p-toolbar-group-start">
            <f-nav first="/ticket" label="Tickets" [model]="ticket"> </f-nav>
        </div>
        <div class="p-toolbar-group-end">
            <button pButton pRipple type="button" label="Delete" icon="pi pi-trash" class="p-button-outlined
        p-button-outlined p-button-danger" (click)="deleteTicket(ticket.id)" *ngIf="ticket.isEdit()"></button>
        </div>
    </p-toolbar>
    <!--/menutree -->

    <!-- adding ticket form -->
    <form [formGroup]="ticket.form">
        <div *ngIf="ticket.isform || ticket.isView()" class="card">
            <div class="grid fluid">
                <div class="field col-12 md:col-12 lg:col-6">
                    <label for="name"> Subject <span class="validation-star">*</span></label>
                    <p-dropdown formControlName="subject" [readonly]="!ticket.isNew()" inputId="subject"
                        placeholder="Select" [options]="staticService.subjectTicket">
                    </p-dropdown>
                    <div *ngIf="errFlag1 && !ticket.isEdit() && ticket.isEmpty('subject')">
                        <small class="p-error">Subject is required</small>
                    </div>
                </div>

                <div *ngIf="ticket.isEdit()" class="field col-12 md:col-12 lg:col-6">
                    <label for="name">Number</label>
                    <input type="text" pInputText id="Number" formControlName="ticketNumber" required autofocus
                        [readOnly]="true" autocomplete="off" />
                </div>
                <div *ngIf="ticket.isEdit()" class="col-12 md:col-6 lg:col-6">
                    <label>Progress Status</label>
                    <p-dropdown formControlName="progresStatus" inputId="status" placeholder="Select"
                        [options]="staticService.progresStatus">
                    </p-dropdown>
                </div>
                <div *ngIf="ticket.isEdit()" class="col-12 md:col-6 lg:col-6">
                    <label>Priority Status </label>
                    <p-dropdown formControlName="priorityStatus" inputId="priorityStatus" placeholder="Select"
                        [options]="staticService.priorityStatus">
                    </p-dropdown>
                    <f-error [err]="ticket.required('priorityStatus')" msg="Priority Status is required"></f-error>
                </div>
                <div *ngIf="sysService.isAdmin()" class="col-12 md:col-12 lg:col-6">
                    <label>Status </label>
                    <p-dropdown formControlName="status" inputId="status" placeholder="Select"
                        [options]="staticService.ticketStatus" required="true">
                    </p-dropdown>
                </div>
                <div class="field col-12 md:col-12 lg:col-12">
                    <label style="font-size: 20px;">Send A Message <span class="validation-star">*</span></label>
                    <textarea [rows]="5" [cols]="40" class="p-inputtextarea p-inputtext p-component p-element"
                        formControlName="message" pInputTextarea maxlength="500"></textarea>
                    <div *ngIf="errFlag&& ticket.isEmpty('message')">
                        <small class="p-error">Send A Message is required</small>
                    </div>
                </div>
                <div class="flex justify-content-center flex-wrap card-container butt">
                    <button pButton pRipple type="button" label="Cancel" [disabled]="sysService.loading"
                        icon="pi pi-times" class="p-button-outlined p-button-info"
                        (click)="listView(ticket.isEmptyList());dt.reset()">
                        <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i></button>
                    <button pButton pRipple type="button" label=" Send" icon="pi pi-check"
                        class="p-button-raised p-button-success" (click)="saveTicket()" [disabled]="sysService.loading"
                        *ngIf="ticket.isForm()">
                        <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                    </button>
                </div>
                <div *ngIf="ticket.isEdit()" class="field col-12 md:col-12 lg:col-12">
                    <label style="font-size: 20px;">Correspondence </label>
                    <textarea [rows]="13" [cols]="40"
                        style=" background-color: rgb(235, 231, 198);color:rgb(233, 24, 24)"
                        class="p-inputtextarea p-inputtext p-component p-element" [readOnly]="true"
                        formControlName="correspondance" pInputTextarea autoResize="autoResize"></textarea>
                </div>
            </div>
        </div>
    </form>
    <!--/adding ticket form -->

    <!-- filter a created ticket data from a form -->
    <div class="card grayCard" *ngIf=" ticket.isList()">
        <form [formGroup]="ticketSearch.form" class="fullform mt-3">
            <div class="grid ">
                <div class=" field col-12 md:col-3 lg:col-2 m-2">
                    <span class="p-float-label">
                        <p-dropdown formControlName="progresStatus" inputId="status" placeholder="Select"
                            [options]="staticService.progresStatus">
                        </p-dropdown>
                        <label for="float-input">Progress Status</label>
                    </span>
                </div>
                <div class=" field col-12 md:col-3 lg:col-2 m-2">
                    <span class="p-float-label">
                        <p-dropdown formControlName="priorityStatus" inputId="priorityStatus" placeholder="Select"
                            [options]="staticService.priorityStatus">
                        </p-dropdown>
                        <label for="float-input">Priority Status</label>
                    </span>
                </div>
                <div class=" field col-12 md:col-3 lg:col-2 m-2 " *ngIf="sysService.isAdmin()">
                    <span class="p-float-label">
                        <p-autoComplete forceSelection="true" formControlName="UserType" field="name"
                            [suggestions]="UserTypeLookup" (completeMethod)="filterUserTypeLookup($event)"
                            ariaLabel="UserType" [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true"
                            delay="400" maxlength="50">
                        </p-autoComplete>
                        <label for="float-input">User </label>
                    </span>
                </div>
                <div class=" field col-12 md:col-3 lg:col-2 m-2">
                    <span class="p-float-label">
                        <p-dropdown formControlName="subject" inputId="subject" placeholder="Select"
                            [options]="staticService.subjectTicket">
                        </p-dropdown>
                        <label for="float-input">Subject </label>
                    </span>
                </div>

                <div class=" field col-12 md:col-3 lg:col-2 m-2">
                    <span class="p-float-label">
                        <p-calendar #calendarRef [showIcon]="false" dateFormat="dd/mm/yy" [numberOfMonths]="1"
                            placeholder="DD/MM/YYYY-DD/MM/YYYY" class="widthClass" selectionMode="range"
                            [readonlyInput]="true" inputId="createdDate" formControlName="createdDate"
                            [(ngModel)]="selectedRange" (onSelect)="calendarSelected = true">
                            <ng-template pTemplate="footer">
                                <div class="grid justify-content-center">
                                    <p-button label="Clear" *ngIf="calendarSelected"
                                        (click)="changesCalendarClear(dt);dt.reset()"
                                        styleClass="p-button-raised p-button-text"></p-button>&nbsp;
                                    <button pButton pRipple type="button" *ngIf="calendarSelected"
                                        (click)="calenedarChangeDate($event,dt);dt.reset()" label=" Search"
                                        class="p-button-raised p-button-text"></button>
                                </div>
                            </ng-template>
                        </p-calendar>

                        <label for="float-input">Date Range</label>
                    </span>
                </div>

                <div class=" field col-12 md:col-3 lg:col-2 m-2">
                    <span class="p-float-label">
                        <p-dropdown formControlName="Status" inputId="status" placeholder="Select"
                            [options]="staticService.ticketStatus" required="true">
                        </p-dropdown>
                        <label for="float-input">Status</label>
                    </span>
                </div>


                <div class="field mt-4">
                    <button pButton pRipple type="button" (click)="searchTicketSearch();dt.reset()" label=" Search"
                        icon="pi pi-search" class="p-button-raised p-button-info" [disabled]="sysService.loading">
                        <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                    </button>
                    <button pButton pRipple type="button" (click)="resetTicketSearch();dt.reset()" label=" Reset"
                        icon="pi pi-undo" class="p-button-raised p-button-text" [disabled]="sysService.loading">
                        <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                    </button>
                </div>
            </div>
        </form>
    </div>
    <!-- /filter a created ticket data from a form -->

    <!-- listing created ticket in this table -->

    <div class="table-responsive" [style.display]="ticket.islist ? 'block':'none'">
        <p-table #dt [value]="ticket.list" class="study-list-class" [scrollHeight]="heightTable"
            [totalRecords]="ticket.total" [lazy]="true" selectionMode="multiple" (onLazyLoad)="listTicketLazy($event)"
            dataKey="id" [(selection)]="ticket.selection" [loading]="sysService.loading" filterDelay="400"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" [rowsPerPageOptions]="[25,50,]"
            [rowHover]="true" styleClass="p-datatable-sm" [rows]="25" scrollable="true" [paginator]="true"
            [showCurrentPageReport]="true" alwaysShow="false" paginatorPosition="bottom"
            styleClass="p-datatable-sm p-datatable-gridlines"
            [globalFilterFields]="['o.ticket_number','o.subject','name','o.last_person_to_intervene','o.created_at','o.updated_at','o.priority_status','o.progres_status']">
            <ng-template pTemplate="caption">
                <div class="grid">
                    <div class="ml-2">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" maxlength="150" [(ngModel)]="ticket.searchTxt"
                                (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..."
                                pAutoFocus [autofocus]="true" />
                        </span>
                    </div>
                    <div class="mt-2 ml-2">
                        <span *ngIf="!sysService.isAdmin()">
                            <button pButton pRipple type="button" label="Add Ticket" icon="pi pi-plus"
                                class="p-button-raised" *ngIf="ticket.isList()" (click)="add()"></button>
                        </span>
                    </div>
                    <div class="mt-2">
                        <button pButton pRipple type="button" label="Delete" icon="pi pi-trash"
                            class="p-button-outlined p-button-danger" (click)="deleteTickets()" *ngIf="!ticket.isEmptyList() &&
    ticket.selection.length> 0"></button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="paginatorleft">
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th pSortableColumn="o.ticket_number" style="min-width:117px;">Ticket Number <p-sortIcon
                            field="o.ticket_number"></p-sortIcon>
                    </th>
                    <th pSortableColumn="o.subject">Subject<p-sortIcon field="o.subject"></p-sortIcon>
                    </th>
                    <th pSortableColumn="u.name">Created by<p-sortIcon field="u.name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="o.last_person_to_intervene">Last person to
                        intervene<p-sortIcon field="o.last_person_to_intervene"></p-sortIcon>
                    </th>
                    <th pSortableColumn="o.created_at">Creation Date<p-sortIcon field="o.created_at"></p-sortIcon>
                    </th>
                    <th pSortableColumn="o.updated_at">Last Updated<p-sortIcon field="o.updated_at"></p-sortIcon>
                    </th>
                    <th pSortableColumn="o.priority_status">
                        Priority Status <p-sortIcon field="o.priority_status"></p-sortIcon>
                    </th>
                    <th pSortableColumn="o.progres_status">
                        Progress Status <p-sortIcon field="o.progres_status"></p-sortIcon>
                    </th>
                    <th pSortableColumn="status">Status<p-sortIcon field="status"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-ticket>
                <tr [ngClass]="{'ui-state-highlight': chk.checked}">
                    <td>
                        <p-tableCheckbox #chk [value]="ticket"></p-tableCheckbox>
                    </td>
                    <td><i class="pi pi-file-edit link-study" (click)="edit(ticket.id)" title="edit"></i>
                        &nbsp;{{ticket.ticketNumber}}</td>
                    <td>
                        <span *ngIf="ticket?.subject ==='0' ">Application related</span>
                        <span *ngIf="ticket?.subject === '1'">Editor related</span>
                        <span *ngIf="ticket?.subject === '2' ">Others</span>
                        <span *ngIf="ticket?.subject === '3'">Payment related</span>
                        <span *ngIf="ticket?.subject === '4'">Viewer related</span>
                        <span *ngIf="ticket?.subject === '5'">Wallet related</span>

                    </td>
                    <td> {{ticket?.createdBy}}</td>
                    <td>{{ticket?.lastPersonToIntervene}}</td>
                    <td>{{ (ticket?.createdAt ? (sysService.toDate(ticket?.createdAt) | date: 'dd-MM-yyyy hh:mm a') :
                        '') }}</td>
                    <td>{{ (ticket?.updatedAt ? (sysService.toDate(ticket?.updatedAt) | date: 'dd-MM-yyyy hh:mm a') :
                        '') }}</td>


                    <td>
                        <span *ngIf="ticket?.priorityStatus === 1" class="text-danger">High</span>
                        <span *ngIf="ticket?.priorityStatus === 3" class="text-secondary">Medium</span>
                        <span *ngIf="ticket?.priorityStatus === 2 " class="text-success">Low</span>
                    </td>
                    <td>
                        <span *ngIf="ticket?.progresStatus === 1" class="text-success">Completed</span>
                        <span *ngIf="ticket?.progresStatus === 2" class="text-danger">Failed</span>
                        <span *ngIf="ticket?.progresStatus === 3" class="text-secondary">Ongoing</span>
                        <span *ngIf="ticket?.progresStatus === 4" class="text-assent">Pending</span>
                        <span *ngIf="ticket?.progresStatus === 5" class="text-default">Rescheduled</span>
                    </td>

                    <td *ngIf="ticket?.status ===1" class="text-success">Active</td>
                    <td *ngIf="ticket?.status ===0" class="text-danger">Closed</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td class="norecord" [attr.colspan]="10">No records found</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <!-- listing created ticket in this table -->
</div>