import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model } from '@app/_common';
import { ConfirmationService } from 'primeng/api';
@Component({
  selector: 'app-user-setting',
  templateUrl: './user-setting.component.html',
  styleUrls: ['./user-setting.component.css']
})
export class UserSettingComponent {
  userSettingListExport = ['name', 'taxCode', 'code', 'countryId.name']; //for export header fields
  private userSettingView: View = this.viewFactory.newView();
  disclaimerValue: string = 'The radiology report is not absolute and has to be interpreted in the proper clinical settings. We encourage reinterpretation of report if required or suggested by treating doctor.'
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory) {
  }
  disclaimerText: boolean = false;

  ngOnInit() {
    this.userSettingView.of(this.userSettingListModel())
    this.userDetailSetting()
  }
  get userSetting() {
    return this.userSettingView.model;
  }
  private userSettingListModel() {
    const formDefault = new FormDefault(["showAutoApproval", 'editableByAdmin', 'disclaimer'], [1, 0, this.disclaimerValue]);
    return new Model(this.formBuilder.group({

      id: [''],
      showCreatedDate: [''],
      userId: [],
      disclaimer: [''],
      showPatId: [''],
      showPatSex: [''],
      showSeriesInstanceCount: [''],
      showStatus: [''],
      editableByAdmin: [],
      defaultDisclaimer: [],
      bonus: [],
      showAutoApproval: [''],
      downloadDoc: [],
      isAutoRefresh: [],
    }), this.sysService, formDefault, '');
  }

  add() { }
  edit() { }
  list() { }
  listView() { }
  view() { }

  userSettings() {
    if (this.disclaimerValue !== this.userSetting.get("disclaimer")) {
      this.userSetting.set("defaultDisclaimer", false)
    } else {
      this.userSetting.set("defaultDisclaimer", true)

    }
    if (this.userSetting.get("downloadDoc") === true) {
      this.userSetting.set('downloadDoc', 1)
    }
    else {
      this.userSetting.set('downloadDoc', 0)
    }
    this.userSettingView.toSave("/usersettings", "id", " User Setting updated successfully").then(data => {
      if (data.downloadDoc === 1) {
        this.userSetting.set('downloadDoc', true)
      }
      else {
        this.userSetting.set('downloadDoc', false)
      }
    })
  }
  checkDisclaimer(event) {
    // console.log(event)
  }

  toggleCheckboxValue(event) {
    if (event?.checked) {

      this.userSetting.set("disclaimer", this.disclaimerValue);
    } else {
      this.userSetting.set("disclaimer", null);

    }

  }
  oncahngeAlert(event) {
    this.sysService.clear()
    if (event.value !== 0) {
      this.confirmationService.confirm({
        message: 'I understand the risks associated with given the option to edit my report by a second party.I assume all liabilities for the edit report.eSCribe is not responsible for any consequences to such edit',
        header: 'Disclaimer',
        acceptLabel: ' I Agree',
        rejectLabel: 'I Disagree',
        accept: () => {
          this.userSetting.set('editableByAdmin', event.value)
        },
        reject: () => {
          this.userSetting.set('editableByAdmin', 0)
        }
      });
    }
  }
  userDetailSetting() {
    this.userSettingView.getData("/usersettings/custom-details").then(data => {
      this.userSetting.form.patchValue(data);
      if (this.disclaimerValue !== this.userSetting.get("disclaimer")) {
        this.userSetting.set("defaultDisclaimer", false)
      } else {
        this.userSetting.set("defaultDisclaimer", true)

      }
      if (data.downloadDoc === 1) {
        this.userSetting.set('downloadDoc', true)
      }
      else {
        this.userSetting.set('downloadDoc', false)
      }

    });
  }
}
