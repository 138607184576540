import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model, UserService } from '@app/_common';
import { environment } from '@environments/environment';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
@Component({
  selector: 'app-shared-viewerr',
  templateUrl: './shared-viewer.component.html',
  styleUrls: ['./shared-viewer.component.css']
})
export class SharedViewerComponent {
  private SharedviewerView: View = this.viewFactory.newView();
  countryLookup: any[];
  siuid: any;
  sharingId: any;
  constructor(
    public sysService: SysService,
    private userService: UserService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private router: Router,
    public config: DynamicDialogConfig,
    private viewFactory: ViewFactory) {
  }
  ngOnInit() {
    this.SharedviewerView.of(this.viewerModel(), false);
    this.route.queryParams.subscribe(params => {
      const viewerId = params.tokenValue
      this.sharingId = atob(viewerId)
      // console.log(this.sharingId)
      this.viewerDetails()
    });
  }
  get viewer() {
    return this.SharedviewerView.model;
  } private viewerModel() {
    const formDefault = new FormDefault([], []);
    return new Model(this.formBuilder.group({
    }), this.sysService, formDefault, '');
  }

  shareViewerBoolean: boolean
  message: any;
  rejectBack() {

  }
  stopLoading: boolean = false;
  viewerDetails() {
    this.SharedviewerView.getData(`/sharelink/code/${this.sharingId}`).then((data) => {
      this.siuid = btoa(data?.studyIuid);
      const token = data?.token;
      if (token) {
        this.login(data);
      }
    }).catch((error) => {
      this.stopLoading = true
    });
  }


  async login(data: any) {
    this.userService.saveToken(data.token);
    this.userService.saveUser(data);
    this.sysService.appInit();
    // this.sysService.navigate([`/radiologistviewer?siuid: ${this.siuid}`]);
    const url = `/radiologistviewer?siuid=${this.siuid}`
    if (this.sysService.isGuest()) {
      localStorage.setItem("shareView", url)
    }
    window.location.href = url;

  }

  viewerUrl() {
    let url = '';
    if (environment.ohif) {
      url = `${environment.viewer.ohif}/viewer?StudyInstanceUIDs=${this.siuid}`;
      // window.open(url)
    } else if (environment.meddream) {
      url = `${environment.viewer.meddream}/?study=${this.siuid}`;
      return url;
    }
  }
}
