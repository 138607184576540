import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormDefault, Model, StaticService, SysService, View, ViewFactory } from '@app/_common';
import { environment } from '@environments/environment';
import { LazyLoadEvent } from 'primeng/api';
@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
})
export class GroupComponent implements OnInit {
  // ViewChild for getting a reference to the contactslist table
  @ViewChild('dt3') dt3: any;
  private groupView: View = this.viewFactory.newView(); // View for managing group data
  private contactsSearchView: View = this.viewFactory.newView(); // View for searching contacts
  contactslist: any[]; // List of Contacts List that belong to a user
  groupDetails: any; // Details of a group
  isAdminLoggedIn: any; // Whether an admin is logged in or not
  dataGroup: any;
  contactsListDialog: boolean = false;
  Contacts: any;
  eventData: any;
  saveButtonLabel: string = 'Save';
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private router: Router,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory) { }
  ngOnInit() {

    this.scrollHeight()
    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 400));
    this.detectZoomChange()

    // Initialize the views
    this.groupView.of(this.groupModel()).switchAction('/groups', this.route, this);
    this.contactsSearchView.of(this.contactsSearchModel(), false);
  }
  get group() {
    // Return the model property of the groupView object
    return this.groupView.model;
  }
  get contactsSearch() {
    // Return the model property of the contactsSearchView object
    return this.contactsSearchView.model;
  }
  // Model for group data

  isScrollTable = window.innerWidth > environment.devices.tablet ? true : false;
  heightDisplay = (window.innerHeight - 280) + 'px';
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isScrollTable = window.innerWidth > environment.devices.tablet ? true : false;
    this.heightDisplay = (window.innerHeight - 280) + 'px';
  }

  private groupModel() {
    const formDefault = new FormDefault(['status', 'assignmentRule', 'approvalRule', 'visibilityRule', 'hideCentreName', 'shareable'],
      [1, 2, 2, 1, 0, 1]); // Create a formDefault object with default values for specific form fields
    return new Model(this.formBuilder.group({
      id: [],
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      status: [''],
      comments: [],
      pending: [],
      assignmentRule: [],
      approvalRule: [],
      adminId: [],
      adminType: [],
      // contactList: [],
      isAdminLoggedIn: [],
      visibilityRule: [[Validators.required]],
      hideCentreName: [[Validators.required]],
      shareable: [[Validators.required]],
    }), this.sysService, formDefault, '');
  }
  // Model for the contacts search
  private contactsSearchModel() {
    const formDefault = new FormDefault([], []);
    return new Model(this.formBuilder.group({
      filter: ['', [Validators.required]],
      contactList: []
    }), this.sysService, formDefault, '');
  }
  // Add a new group
  add() {
    this.groupView.toNew();
    this.saveButtonLabel = "Save";
  }
  // Edit an existing group
  edit(id: any) {
    this.groupView.toEditFetch(`/groups/${id}`, id).then(data => {
      this.contactsSearch.list = data.contactList;
      this.groupDetails = data;
    });
    this.saveButtonLabel = "Update";
  }
  // List all groups
  list(id: any) {
    this.listView(false);
  }
  // Leave current group for member
  async leaveGroup() {
    const groupId = this.group.id;
    // Perform the deletion operation
    await this.groupView.toDeleteList(`/groups/leave-group?groupId=${groupId}`, groupId, groupId, { confirm: `Are you sure you want to Leave ...?`, success: 'Successfully Leave group' });
    // Remove the deleted group from the list
    this.group.list = this.group.list.filter(item => item.id !== groupId);
  }
  // List groups with lazy loading and search
  async listgroupLazy(event?: LazyLoadEvent, search: boolean = false) {
    this.eventData = event
    this.groupView.toListLazyFetch(`/groups`, search, event);
  }
  // View all groups in list
  listView(search: boolean = false) {
    this.groupView.toListLazyView(`/groups`, search);
  }
  // View a specific group
  view(id: any) {
    this.groupView.toViewFetch(`/groups/${id}`, id);
  }
  // Save a new or edited group
  savegroup() {
    const isNew = this.group.isNew();
    this.groupView.toSave("/groups", "id", this.group.isEdit() ? "Successfully Updated." : "Successfully Saved.")
      .then(data => {

        if (isNew) this.showMembers(data.id);
      });
    this.group.set("isAdminLoggedIn", 1);
  }
  // add members into group
  showMembers(id: any) {
    this.contactsSearchView.getData(`/contacts/groupContacts?groupId=${this.group.id}`).then(data => {
      this.contactslist =
        data;
    });
    this.contactsListDialog = true;
  }
  // Check if user is a group admin
  isGroupAdmin() {
    return this.group.get("isAdminLoggedIn", 0) === 1;
  }
  // Check if user is a group agent
  isGroupAgent() {
    return this.group.get("isAdminLoggedIn", 1) === 0;
  }
  // Add members to a group
  addMembers(Contacts: any) {
    this.groupView.toSave(`/groupsdetails/addmember?contactIds=${Contacts.contactId}&groupId=${this.group.id}`, "id", `${Contacts.name}  added to team  `).
      then(data => {
        this.contactslist = data;
        data.forEach(element => {
          if (element.id && element.contactId === Contacts.contactId) {
            this.contactsSearch.list.push(element)
          }
        });
      });
  }
  // Make a group member an admin
  makeAdmin(Contacts: any) {
    this.groupView.toSave(`/groups/changeadmin?id=${Contacts.id}&groupId=${this.group.id}`, "id").then(data => {
      this.contactsSearch.list = data.contactList
    });
  }
  // Delete a member from the group
  deteleMember(Contacts: any) {
    this.contactsSearchView.toDeleteList(`/groupsdetails/${Contacts.id}`, "id", Contacts.id, { confirm: `Are you sure  want to remove ${Contacts.name}...? `, success: `Successfully Removed ${Contacts.name}` },);
  }
  // Delete selected groups
  deletegroups() {
    this.groupView.toDeleteSelection(`/groups`, {
      onSuccess: () => {
        this.groupView.toListLazyFetch(`/groups`, true, this.eventData);
      }
    });

  }
  // Delete a from List
  deletegroup(id: any) {
    this.groupView.toDeleteList(`/groups/${id}`, "id", id);
  }
  // Close the contact dialogue box and clear search filter
  closeContactDiagloue() {
    this.contactsListDialog = false;
    const isNew = this.group.isNew();
    this.sysService.clear();
    this.group.searchTxt = '';
    this.dt3.filterGlobal('', 'contains');
    this.edit(this.group.get("id"))
  }

  heightTable: any;
  debounceTimeout: any;
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 280) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 280) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  detectZoomChange() {
    this.scrollHeight();
  }

}