<div *ngIf="!sysService.isPermission()">
  <not-found></not-found>
</div>
<div *ngIf="sysService.isPermission()">
  <p-toolbar>
    <div class="p-toolbar-group-start">
      <f-nav first="/install" label="Installation Request" [model]="Installation">
      </f-nav>
    </div>
    <div class="p-toolbar-group-end">
      <button pButton pRipple type="button" label="Delete" icon="pi pi-trash"
        class="p-button-outlined p-button-outlined p-button-danger" (click)="deleteInstallation(Installation.id)"
        *ngIf="Installation.isEdit()"></button>
    </div>
  </p-toolbar>
  <form [formGroup]="Installation.form">
    <div *ngIf="Installation.isform || Installation.isView()" class="card">
      <div class="grid">
        <div class="col-12 md:col-6 lg:col-6">
          <label>Centre Name <span class="validation-star">*</span></label>
          <input pInputText maxlength="150" formControlName="centreName" type="text" pAutoFocus [autofocus]="true" />
          <f-error [err]="Installation.required('centreName')" msg="Centre Name is
          required"></f-error>
        </div>
        <div class="col-12 md:col-6 lg:col-6">
          <label>Contact Person <span class="validation-star">*</span></label>
          <input pInputText maxlength="150" formControlName="contactPerson" type="text" />
          <f-error [err]="Installation.required('contactPerson')" msg="Contact
          Person is required"></f-error>
        </div>

        <div class="col-12 md:col-6 lg:col-6">

          <label for="EmailAddress">Email Address<span class="validation-star">*</span> </label>
          <input id="EmailAddress" type="text" maxlength="100" pInputText formControlName="email" required />
          <f-error [err]="Installation.required('email')" msg="Email Address is required "></f-error>
          <f-error [err]="Installation.pattern('email')"
            msg="This e-mail address is not valid. Valid records include to user@somehost.somedomain. And emails only accept lowercase letters.">
          </f-error>

        </div>
        <div class="field col-12 md:col-6 lg:col-6">
          <label>Contact Number <span class="validation-star">*</span></label>
          <input pInputText maxlength="18" formControlName="contactNumber" type="text" />
          <f-error [err]="Installation.required('contactNumber')" msg="Contact Number  is required"></f-error>
          <f-error [err]="Installation.pattern('contactNumber')"
            msg="Only numerical values are accepted and ten numbers are required "></f-error>
        </div>
        <div class="col-12 md:col-6 lg:col-6">
          <label>Reference Person </label>
          <input pInputText maxlength="150" formControlName="referencePerson" type="text" />
          <f-error [err]="Installation.required('referencePerson')" msg="Reference
          Person is required"></f-error>
        </div>
        <div class="col-12 md:col-6 lg:col-6">
          <label>Reference No </label>
          <input pInputText maxlength="50" formControlName="referenceNumber" type="text" />
          <f-error [err]="Installation.required('referenceNumber')" msg="Reference
          No is required"></f-error>
        </div>
        <div class="col-12 md:col-6 lg:col-6">
          <label>Preferred Time to Contact
            <span class="validation-star">*</span></label>
          <p-dropdown formControlName="preferredTimeToContact" placeholder="Select" [options]="staticService.time"
            required="true">
          </p-dropdown>
          <f-error [err]="Installation.required('preferredTimeToContact')" msg="Preferred Time To Contact  is required">
          </f-error>
        </div>
        <div *ngIf="sysService.isAdmin()" class="col-12 md:col-6 lg:col-6">
          <label>Status </label>
          <p-dropdown formControlName="status" inputId="status" placeholder="Select" [options]="staticService.status"
            required="true">
          </p-dropdown>
        </div>

        <div *ngIf="sysService.isAdmin()" class="col-12 md:col-6 lg:col-6">
          <label>Progress Status <span class="validation-star">*</span> </label>
          <p-dropdown formControlName="progresStatus" inputId="status" placeholder="Select"
            [options]="staticService.progresStatus">
          </p-dropdown>
          <f-error [err]="Installation.required('progresStatus')" msg=" Progress Status is required  
        "></f-error>
        </div>
        <div class="col-12 md:col-6 lg:col-6">
          <label>Priority Status <span class="validation-star">*</span></label>
          <p-dropdown formControlName="priorityStatus" inputId="priorityStatus" placeholder="Select"
            [options]="staticService.priorityStatus">
          </p-dropdown>
          <f-error [err]="Installation.required('priorityStatus')" msg="Priority Status is required"></f-error>
        </div>

        <div class="col-12 md:col-12 lg:col-12">
          <label> Comments </label>
          <textarea [rows]="3" [cols]="39" maxlength="480" class="p-inputtextarea p-inputtext p-component p-element"
            formControlName="comments" pInputTextarea></textarea>
        </div>
      </div>
      <div class="formgrid text-center bc">
        <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" class="p-button-outlined p-button-info"
          (click)="listView(Installation.isEmptyList())"></button>&nbsp;
        <button pButton pRipple type="button" [label]="saveButtonLabel " icon="pi pi-check"
          class="p-button-raised p-button-success" (click)="saveInstallation()" [disabled]="sysService.loading"
          *ngIf="Installation.isForm()">
          <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i></button>&nbsp;
        <button pButton pRipple type="button" label=" Save & Add" icon="pi pi-plus"
          class="p-button-raised p-button-info" (click)="saveInstallation(true)" [disabled]="sysService.loading"
          *ngIf="Installation.isNew()">
          <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i></button>&nbsp;
      </div>
    </div>
  </form>

  <div [style.display]="Installation.islist ? 'block' : 'none'" class="table-responsive">

    <p-table #dt [value]="Installation.list" [totalRecords]="Installation.total" [lazy]="true"
      (onLazyLoad)="listInstallationLazy($event)" scrollable="true" dataKey="id" [(selection)]="Installation.selection"
      class="study-list-class" [scrollHeight]="heightTable" [loading]="sysService.loading" filterDelay="400"
      currentPageReportTemplate="{first} to {last} of {totalRecords}" [rows]="25" [rowsPerPageOptions]="[25,50]"
      [rowHover]="true" styleClass="p-datatable-sm" [rows]="sysService.rows" [paginator]="true"
      [showCurrentPageReport]="true" alwaysShow="false" paginatorPosition="bottom"
      styleClass="p-datatable-sm p-datatable-gridlines"
      [globalFilterFields]="['displayname', 'modality', 'description','email']">
      <ng-template pTemplate="caption">
        <div class="grid">
          <div class="ml-2">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" maxlength="150" [(ngModel)]="Installation.searchTxt"
                (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." pAutoFocus
                [autofocus]="true" />
            </span>
          </div>

          <div class="ml-2 mt-2">
            <button pButton pRipple type="button" label="Add Installation Request" icon="pi pi-plus"
              class="p-button-raised add-inst-text" *ngIf="Installation.isList()" (click)="add()"></button>
          </div>
          <div class="mt-2">
            <button pButton pRipple type="button" label="Delete" icon="pi pi-trash"
              class="p-button-outlined p-button-danger" (click)="deleteInstallations()"
              *ngIf="!Installation.isEmptyList() && Installation.selection.length > 0"></button>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="paginatorleft">

      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 3rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th pSortableColumn="centreName">
            Centre Name<p-sortIcon field="centreName"></p-sortIcon>
          </th>

          <th pSortableColumn="contactNumber">
            Contact Number <p-sortIcon field="contactNumber"></p-sortIcon>
          </th>

          <th pSortableColumn="email">
            Email Address <p-sortIcon field="email"></p-sortIcon>
          </th>
          <th pSortableColumn="preferredTimeToContact">
            Preferred Time To Contact
            <p-sortIcon field="preferredTimeToContact"></p-sortIcon>
          </th>

          <th pSortableColumn="priorityStatus">
            Priority Status <p-sortIcon field="priorityStatus"></p-sortIcon>
          </th>
          <th pSortableColumn="progresStatus">
            Progress Status <p-sortIcon field="progresStatus"></p-sortIcon>
          </th>
          <th pSortableColumn="status">
            Status <p-sortIcon field="status"></p-sortIcon>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-Installation>
        <tr [ngClass]="{'ui-state-highlight': chk.checked}">
          <td>
            <p-tableCheckbox #chk [value]="Installation"></p-tableCheckbox>
          </td>
          <td>
            {{ Installation.centreName }}
            <i class="pi pi-file-edit link-study" (click)="edit(Installation.id)" title="edit"></i>


          </td>
          <td>
            <span class="p-column-title"><b>Contact No :</b></span>
            {{ Installation.contactNumber }}
          </td>
          <td class="bdy-part">
            <span class="p-column-title"><b>Email Address :</b></span>
            {{ Installation.email }}
          </td>
          <td>
            <span *ngIf="Installation.preferredTimeToContact === 1">6:00 AM - 9:00 AM</span>
            <span *ngIf="Installation.preferredTimeToContact === 2">9:00 AM - 12:00 PM</span>
            <span *ngIf="Installation.preferredTimeToContact === 3">12:00 PM - 3:00 PM</span>
            <span *ngIf="Installation.preferredTimeToContact === 4">3:00 PM - 6:00 PM</span>
            <span *ngIf="Installation.preferredTimeToContact === 5">6:00 PM - 9:00 PM</span>
            <span *ngIf="Installation.preferredTimeToContact === 6">9:00 PM - 12:00 AM</span>
          </td>

          <td>
            <span *ngIf="Installation.priorityStatus === 1" class="text-danger">High</span>
            <span *ngIf="Installation.priorityStatus === 3" class="text-secondary">Medium</span>
            <span *ngIf="
              Installation.priorityStatus === 2 ||
              Installation.priorityStatus === null
            " class="text-success">Low</span>
          </td>
          <td>
            <span *ngIf="Installation.progresStatus === 1" class="text-success">Completed</span>
            <span *ngIf="Installation.progresStatus === 2" class="text-danger">Failed</span>
            <span *ngIf="Installation.progresStatus === 3" class="text-secondary">Ongoing</span>
            <span *ngIf="Installation.progresStatus === 4" class="text-dark">Pending</span>
            <span *ngIf="Installation.progresStatus === 5" class="text-default">Rescheduled</span>
          </td>

          <td>
            <span *ngIf="Installation.status === 0 || Installation.status === null" class="text-danger">Inactive</span>
            <span *ngIf="Installation.status === 1" class="text-success">Active</span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td class="norecordstudy" [attr.colspan]="9">No records found</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>