<header class="header" *ngIf="!sysService.isLoggedIn()">
    <div class="wrap">
        <div class="right">
            <div class="rgtBdr ">
                <a (click)="navHome()"> <img class="web" src="../../../assets/images/eclogoo.png"></a>
            </div>
        </div>
    </div>
</header>
<div class="card">
    <form [formGroup]="newPassword.form">
        <h4>Enter your Password associated with your eCScribe account.</h4>
        <div class="grid">
            <div class="col-12 md:col-6 lg:col-6 login-password">
                <label for="Password">Old Password <span class="validation-star">*</span></label>
                <p-password class="pt-2" type="password" formControlName="oldPassword" id="Password" required
                    autocomplete="off" [toggleMask]="true" maxlength="15" [feedback]="false" autocomplete="false"
                    pAutoFocus [autofocus]="true" autofocus></p-password>
                <f-error [err]="newPassword.required('oldPassword')" msg=" Old Password is required "></f-error>
                <f-error [err]="newPassword.maxlength('oldPassword')" msg=" Maximum 15 characters are allowed.">
                </f-error>
            </div>
            <div class="col-12 md:col-6 lg:col-6 login-password">
                <label class="pt-2" for="Password">New Password <span class="validation-star">*</span></label>
                <p-password formControlName="password" [toggleMask]="true" id="Password" required autocomplete="off">
                    <ng-template pTemplate="footer">
                        <p class="p-mt-2">Suggestions</p>
                        <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                            <li>At least one lowercase</li>
                            <li>At least one uppercase</li>
                            <li>At least one numeric</li>
                            <li>Minimum 8 characters</li>
                        </ul>
                    </ng-template>
                </p-password>
                <f-error [err]="newPassword.required('password')" msg=" New Password  is  required "></f-error>
                <f-error [err]="newPassword.minlength('password')" msg="Minumum 8 characters are required.">
                </f-error>
                <f-error [err]="newPassword.pattern('password')"
                    msg="At least one lowercase, uppercase and numeric  are required."></f-error>
                <f-error [err]="newPassword.maxlength('password')" msg=" Maximum 15 characters are allowed."></f-error>
            </div>
            <div class="col-12 md:col-6 lg:col-6 login-password">
                <label class="pt-2" for="Password">Confirm New Password <span class="validation-star">*</span></label>
                <p-password formControlName="confirmPassword" [toggleMask]="true" id="Password" [maxlength]="15"
                    required autocomplete="off">
                    <ng-template pTemplate="footer">
                        <p class="p-mt-2">Suggestions</p>
                        <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                            <li>At least one lowercase</li>
                            <li>At least one uppercase</li>
                            <li>At least one numeric</li>
                            <li>Minimum 8 characters</li>
                        </ul>
                    </ng-template>
                </p-password>
                <f-error [err]="newPassword.required('confirmPassword')" msg=" Confirm New Password is  required ">
                </f-error>
                <f-error [err]="newPassword.minlength('confirmPassword')" msg="Minumum 8 characters are required.">
                </f-error>
                <f-error [err]="newPassword.pattern('confirmPassword')"
                    msg="At least one lowercase, uppercase and numeric  are required."></f-error>
                <f-error [err]="newPassword.maxlength('confirmPassword')" msg=" Maximum 15 characters are allowed.">
                </f-error>
                <f-error [err]="newPassword.hasError('notSame')"
                    msg="Your password and your confirm password do not match. Please correct and resubmit"></f-error>
            </div>

        </div>
        <div class="grid justify-content-center p-3">

            <button pButton pRipple type="button" label="Update" (click)="submit()" icon=" pi pi-check"
                class="p-button-raised p-button-success btnEclMin" [disabled]="sysService.loading"> <i
                    *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i></button>
        </div>
    </form>

</div>