import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormDefault, Model, StaticService, SysService, View, ViewFactory } from '@app/_common';
import { environment } from '@environments/environment';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-primary-secondary-management',
  templateUrl: './primary-secondary-management.component.html',
  styleUrls: ['./primary-secondary-management.component.css']
})
export class PrimarySecondaryManagementComponent implements OnInit {
  private primaryView: View = this.viewFactory.newView();
  isScrollTable = window.innerWidth > environment.devices.tablet ? true : false;
  heightDisplay = (window.innerHeight - 380) + 'px';
  constructor(public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: UntypedFormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory,
   ) { }
    @HostListener('window:resize', ['$event']) onResize(event: any) {
      this.isScrollTable = window.innerWidth > environment.devices.tablet ? true : false;
      this.heightDisplay = (window.innerHeight - 380) + 'px';
    }
  ngOnInit(): void {
    this.primaryView.of(this.primaryModel()).list('assets/data/prm.json');

  }
  private primaryModel() {
    const formDefault = new FormDefault([], []);

    return new Model(this.formBuilder.group({
      id: ['',],
      Title: ['', [Validators.required]],
      Description: ['', [Validators.required]],
      UnitRate: ['', [Validators.required]],
    },
      {
        validators: []
      }), this.sysService, formDefault, '');

  }
  get primary() {
    return this.primaryView.model;
  }
  change(){

  }
}
