import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model } from '@app/_common';
import { environment } from '@environments/environment';
import { LazyLoadEvent } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';



@Component({
  selector: 'ohif-Viewertab',
  templateUrl: './viewer-tab.component.html',
  styleUrls: ['./viewer-tab.component.css']
})
export class ViewerTabComponent {
  private ViewerTabView: View = this.viewFactory.newView();
  countryLookup: any[];
  siuid: '';
  Report: boolean;
  ViewerTabTab: boolean
  reportViewOnly: boolean
  ViewerTabOnly: boolean
  gowtham: boolean;
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public config: DynamicDialogConfig,
    private sanitizer: DomSanitizer,
    private viewFactory: ViewFactory) {
  }




  ngOnInit() {
    this.ViewerTabView.of(this.ViewerTabModel(), false);
    if (this.config?.data?.popUp) {
      this.siuid = this.config?.data?.siuid
      this.Report = this.config?.data?.Report
      this.reportViewOnly = this.config?.data?.reportViewOnly
      this.ViewerTabTab = this.config?.data?.ViewerTabTab
    }


    if (!this.config?.data?.popUp) {
      this.route.queryParams.subscribe(params => {
        this.siuid = params.siuid;
        this.Report = params.Report === 'true';
        this.ViewerTabTab = params.ViewerTab === 'true';



      });
    }

  }


  get ViewerTab() {
    return this.ViewerTabView.model;
  }



  private ViewerTabModel() {
    const formDefault = new FormDefault([], []);
    return new Model(this.formBuilder.group({

    }), this.sysService, formDefault, '');
  }

  viewerUrl() {
    // let url = `${environment.viewer.ohif}/viewer?StudyInstanceUIDs=${this.siuid}`
    let url = `${environment.viewer.meddream}/?study=${this.siuid}`
    return url;


  }

  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
