import { NotificationService, } from './notification.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RxStompService } from './rxstomp.service';
import { Message } from '@stomp/stompjs';
import { NotifyConfig } from './rxstomp.config';
import { SysService } from '../services/sys.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  receivedMessages: string[] = [];
   constructor(
    private notificationService: NotificationService,
    public rxStompService: RxStompService,
    public userService: UserService,
  ) {
    this.rxStompService.configure(NotifyConfig);
  }




  // ngOnDestroy() {
  //   this.topicSubscription.unsubscribe();
  // }

  // onSendMessage() {
  //   const message = `Message generated at ${new Date()}`;
  //   this.rxStompService.publish({ destination: '/topic/demo', body: message });
  // }

  // connectClicked() {
  //   if (!this.client || !this.client.connected) {
  //     this.client = new RxStomp();
  //     this.client.configure({
  //       webSocketFactory: () => new SockJS(`${environment.appApiSock}/notifications`)
  //       //debug: (msg: string) => //console.debug(msg)
  //     });
  //     this.client.activate();
  //     this.watchForNotifications();
  //   }
  // }

  // private watchForNotifications() {
  //   this.client.watch('/user/notification/item')
  //     .pipe(
  //       map((response) => {
  //         
  //         const text: string = JSON.parse(response.body).text;
  //         //console.debug('Got ' + text);
  //         return text;
  //       }))
  //     .subscribe((gotNotification: string) => {
  //       
  //       if (gotNotification != null) {
  //         this.notification = gotNotification;
  //         const item = {
  //           'title': 'eClarity Notification',
  //           'alertContent': gotNotification
  //         }
  //         this.notificationService.generateSingleNotification(item);
  //       } else {
  //         this.notification = '';
  //       }
  //     });
  // }

  // disconnectClicked() {
  //   if (this.client && this.client.connected()) {
  //     this.client.deactivate();
  //   }
  // }

  watch(path: string) {
    this.start();
  

    return this.rxStompService.watch(path).subscribe(
      (message: Message) => {
        console.log(message)
        this.handleMessage(message.body);
      },
      (error) => {
        console.error('Error subscribing to path:', error);
      }
    );

   
  }


  

  
  handleMessage(messageBody: string) {
    try {
      const parsedMessage = JSON.parse(messageBody);
      const m = parsedMessage?.text;
  
      if (m) {
        this.receivedMessages.push(m);
        const item = {
          'title': 'eCScribe Notification',
          'alertContent': m
        };
        this.notificationService.generateSingleNotification(item, window);
      } else {
        console.error('Message text is undefined or null');
      }
    } catch (error) {
      console.error('Error parsing message body or generating notification:', error);
    }
  }

  // simulateNotification() {
  //   // Simulated message body
  //   const simulatedMessageBody = JSON.stringify({
  //     text: 'This is a test notification message'
  //   });
  
  //   // Handle the simulated message
  //   this.handleMessage(simulatedMessageBody);
  // }
  
  
  
  active(){
    return this.rxStompService.active;
  }
  connected(){
    return this.rxStompService.connected();
  }

  restart(){
    return (!this.active() || !this.connected()) 
  }

  start() {
    if (!this.active()){
      this.rxStompService.activate();
      this.rxStompService.publish({ destination: '/app/start', body: `${this.userService.getToken()}`});
    }   
  }

  stop() {
    this.receivedMessages = [];
    if (this.rxStompService.connected()) {
      this.rxStompService.publish({ destination: '/app/stop' });
    }
    this.rxStompService.deactivate();
  }
}
