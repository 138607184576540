import { HTTP_INTERCEPTORS, HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { UserService } from '@app/_common';
@Injectable()
//@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(public userService: UserService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = this.userService.getToken();
    //TODO to control based on urls
    if (token != null) {
      authReq = req.clone({ headers: req.headers.set(this.getTokenHeader(), this.getTokenBearer() + token) });
    }
    return next.handle(authReq);
  }
  private getTokenHeader() {
    return (environment.backend.spring || environment.backend.php || environment.backend.springjwt) ? 'Authorization' : environment.backend.node ? 'x-access-token' : '';
  }
  private getTokenBearer() {
    return (environment.backend.spring || environment.backend.php || environment.backend.springjwt) ? 'Bearer ' : environment.backend.node ? '' : '';
  }
}



export const AuthInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];