<!-- <p-toolbar>
<ng-template>  -->

<a [routerLink]="['/home']" *ngIf="home"><span class="head "><i class="pi pi-home"
      style="font-size: 1.1rem"></i></span></a>
<span class="head" *ngIf="home">></span>
<span class="head"><a [href]="first">{{label}}</a></span>
<span class="head" *ngIf="label">></span>
<span *ngIf="model.isList()" class="head">{{list}}</span>
<span *ngIf="model.isNew()" class="head">{{new}}</span>
<span *ngIf="model.isEdit()" class="head">{{edit}}</span>
<span *ngIf="model.isView()" class="head">{{read}}</span>
<!-- </ng-template> 
</p-toolbar> -->