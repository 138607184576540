<page-loading></page-loading>
<form [formGroup]="technician.form">
  <div class="grid " *ngIf="reportView==='0'">
    <div class="flex col-12 md:col-6 lg:col-3 ">
      <label>Patient Id :</label>
      <span class="pl-2">{{ reportDetails?.patId }}</span>
    </div>
    <div class="flex col-12 md:col-6 lg:col-3">
      <label>Patient Name :</label>
      <span class="pl-2">{{ reportDetails?.patName }}</span>
    </div>
    <div class="flex col-12 md:col-6 lg:col-3">
      <label>Study Date :</label>
      <span class="pl-2">{{ sysService.toDate( reportDetails?.studyDate ) | date: 'dd-MM-yyyy hh:mm a ' }}</span>
    </div>
    <div class="flex col-12 md:col-6 lg:col-3">

      <span><label>Study Description :</label>&nbsp;&nbsp;{{ reportDetails?.studyDesc }}&nbsp;&nbsp;<span
          *ngIf="reportDetails?.studyDescModified">({{reportDetails?.studyDescModified }})</span></span>

    </div>
    <div class="flex col-12 md:col-6 lg:col-3">
      <label>Modality :</label>
      <span class="pl-2">{{ reportDetails?.modality }}</span>
    </div>
  </div>
  <!-- <div *ngIf="reportView==='0'"> -->
  <!-- <div class="text-right pb-5" *ngIf="reportView==='0'">
    <button pButton pRipple type="button" label="New" icon="pi pi-plus" class="p-button-secondary p-button-info"
      (click)="add()"></button>
  </div> -->
  <div class="table-responsive">
    <p-table #dt [value]="technician?.list"  [showCurrentPageReport]="true"
      dataKey="id" [totalRecords]="technician?.list?.length" [(selection)]="technician.selection"
      [scrollHeight]="heightTable" [loading]="sysService.loading"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowHover]="true"
      [rowsPerPageOptions]="[25,50,]" styleClass="  border-bottom-2 p-datatable-sm p-datatable-striped" [rows]="25"
      [paginator]="true" alwaysShow="false" styleClass="p-datatable-sm p-datatable-gridlines"
      [globalFilterFields]="['comments', 'userId.name', ]">
      <ng-template pTemplate="caption">
        <div class="grid">
          <div class="ml-1 p-2">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" maxlength="150" [(ngModel)]="technician.searchTxt" pAutoFocus
                [autofocus]="true" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
            </span>
          </div>
          <div class="mt-2 ml-2 p-2" *ngIf="reportView==='0' ">
            <button pButton pRipple type="button" label="Add"
              *ngIf=" sysService.getUser().userSettings?.isTechEditableByRad  " icon="pi pi-plus"
              class="p-button-secondary p-button-info" (click)="add()"></button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="paginatorleft">
        <button pButton pRipple type="button" label="Delete" icon="pi pi-trash"
          class="p-button-outlined p-button-danger" (click)="deletetechnician(technician.id)"
          *ngIf="!technician.isEmptyList() && technician.selection.length > 0"></button>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="comments" pResizableColumn class="comments" style="min-width:117px;">Comments
          </th>
          <th pSortableColumn="commentCreatedAt">Created On</th>
          <th pSortableColumn="commentCreatedAt">Updated On</th>

          <th pResizableColumn class="comments">File</th>
          <th pSortableColumn="userId?.name" pResizableColumn>Name
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-technician>
        <tr>
          <td *ngIf="technician?.isEditable === true  " >
            <i class="pi pi-file-edit link-study"  (click)="edit(technician)"
              title="edit"></i> &nbsp;
            <span class="p-column-title"><b>comments :</b></span>
            <span class="comments-text">{{ technician?.comments }}</span>
          </td>
          <td class="align" *ngIf="technician?.isEditable === false ">
            <span class="p-column-title"><b>comments :</b></span>
            <span class="comments-text">{{ technician?.comments }}</span>
          </td>
          <td class="align">
            <span class="p-column-title"><b>Created ON :</b></span>
            <span class="comments-text">{{ technician?.commentCreatedAt }}</span>
          </td>
          <td class="align">
            <span class="p-column-title"><b>Updated On :</b></span>
            <span class="comments-text">{{ technician?.commentUpdateAt ? (technician?.commentUpdateAt ) : '' }}</span>
          </td>
          <td>
            <div *ngIf="technician?.documents && technician?.documents?.length > 0; else noDocuments">
              <div *ngFor="let item of technician?.documents" class="col-md-3">
                <a (click)="downloadFile(item)">
                  <i class="pi pi-download" style="color: #117a07"></i> &nbsp;
                  {{ getName(item) }}
                </a>
                <p-button
                  *ngIf="technician?.isEditable === true && sysService.getUser().userSettings?.isTechEditableByRad"
                  icon="pi pi-times-circle" title="Delete" (click)="removeFile(technician, technician?.id, item)"
                  styleClass="p-button-rounded p-button-danger p-button-text"></p-button>
              </div>
            </div>
            <ng-template #noDocuments>
              <p>No Document</p>
            </ng-template>
          </td>
          <td *ngIf="!sysService.isRadSub() && !sysService.isRad() && !sysService.isAdmin()"><span
              class="p-column-title"><b>name: </b></span>{{ technician?.userId?.name }}</td>
          <td *ngIf="sysService.isRadSub() || sysService.isRad() || sysService.isAdmin()"><span
              class="p-column-title"><b>name: </b></span>{{ technician?.createdByName }}</td>
          <!-- <td *ngIf="technician?.documents.length === 0" class="p-column-title danger">No Document </td> -->
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td class="norecordstudy" [attr.colspan]="8">No records found</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="grid" *ngIf="technician.isNew() || editUsers">
    &nbsp;&nbsp; <div class="mt-4">
      <p> <b>Note:</b>(The attachment size limit per file is 10 MB.)
      </p>
      &nbsp; <label for="document">
        File </label>&nbsp;
      <input type="file" id="document" formControlName="docFile" (change)="selectFile($event.target.files,'docFile')"
        accept=".pdf,doc,.docx,.jpg,.jpeg,.png," multiple />
    </div>
    <div *ngIf="reportView==='0'" class="flex col-9 md:col-8 lg:col-8 pt-5 ">
      <div *ngIf="technician?.view?.documents && technician?.view?.documents.length > 0;">
        <span *ngFor="let item of technician.view.documents" class="col-md-3">
          <span class="link" (click)="downloadFile(item)">
            {{getName(item)}}
          </span>
          <p-button *ngIf="technician?.view?.documents" icon="pi pi-times-circle" title="Delete"
            (click)="removeFile(technician.view, technician?.view.id, item)"
            styleClass="p-button-rounded p-button-danger p-button-text"></p-button>
          <!-- <a (click)="removeFile(technician?.view,technician?.id, item)" pTooltip="Delete">X</a> -->
          <!-- <a (click)="removeFile(technician?.view,technician?.id, item)" pTooltip="Delete">X</a> -->
        </span>
      </div>
    </div>
    <div class="field col-12 md:col-12 lg:col-12" id="commentsSection" #commentsSectionRef>
      <label>Comments :<span class="validation-star">*</span></label>
      <textarea [rows]="4" [cols]="40" class="p-inputtextarea p-inputtext p-component p-element" [readonly]="!editUsers"
        formControlName="comments" pInputTextarea maxlength="500" required pAutoFocus [autofocus]="true"></textarea>
      <f-error [err]="technician.required('comments')" msg="Comments is required"></f-error>
      <f-error [err]="technician.pattern('comments')" msg=" white space not allowed on begining"></f-error>
    </div>
    <div class="flex justify-content-center flex-wrap card-container butt">
      <button pButton pRipple *ngIf="reportView==='0'&& editUsers" type="button" label=" Save" icon="pi pi-check"
        class="p-button-raised p-button-success" (click)="savetechnician()" [disabled]="sysService.loading">
        <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
      </button>
    </div>
  </div>
  <!-- </div> -->
</form>
<!-- 
  <p-table #dt2 [value]="technician?.list" [totalRecords]="technician?.list?.length" dataKey="id"
    [tableStyle]="{'min-width': '50rem'}" [(selection)]="technician.selection" [loading]="sysService.loading"
    filterDelay="400" currentPageReportTemplate="{first} to {last} of {totalRecords}" [rowHover]="true"
    styleClass="p-datatable-sm" [rows]="sysService.rows" [paginator]="true" [showCurrentPageReport]="true"
    alwaysShow="false" paginatorPosition="bottom" styleClass="p-datatable-sm p-datatable-gridlines" [globalFilterFields]="['name', 'code', 'taxCode', 'countryId.name']">
    <ng-template pTemplate="paginatorleft">
      <button pButton pRipple type="button" label="Delete" icon="pi pi-trash" class="p-button-outlined p-button-danger"
        (click)="deletetechnician(technician.id)"
        *ngIf="!technician.isEmptyList() && technician.selection.length > 0"></button>
    </ng-template>
    <ng-template pTemplate="header">
      <tr> -->
<!-- <th pSortableColumn="comments" class="comments">
          Comments<p-sortIcon field="comments"></p-sortIcon>
        </th> -->
<!-- <th pSortableColumn="comments" pResizableColumn class="comments">Comments
          <p-sortIcon field="comments"></p-sortIcon>
      </th>
      <th pSortableColumn="userId" pResizableColumn >Name
        <p-sortIcon field="userId"></p-sortIcon>
    </th>
    <th pSortableColumn="comments" pResizableColumn >File
      <p-sortIcon field="comments"></p-sortIcon>
  </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-technician>
      <tr> -->
<!-- <td *ngIf="reportView==='1'">
          {{ technician?.comments | slice:0:50}}
          <span *ngIf="technician.comments.length > 50">...</span>
        </td> -->
<!-- <td *ngIf="reportView==='1'"><span class="p-column-title"><b>comments :</b></span>  {{ technician?.comments | slice:0:50}}
          <span *ngIf="technician.comments.length > 50">...</span>
        </td>
        <td *ngIf="reportView==='0'"  class="link" (click)="edit(technician)"><span class="p-column-title"><b>comments :</b></span>  {{ technician?.comments | slice:0:50}}
          <span *ngIf="technician.comments.length > 50">...</span>
        </td> -->
<!-- <td *ngIf="reportView==='0'" class="link" (click)="edit(technician)">
          {{ technician?.comments | slice:0:50 }}
          <span *ngIf="technician.comments.length > 50">...</span>
        </td> -->
<!-- <td>{{ technician?.userId?.name }}</td>
        <td class="link" (click)="pdfDownload(technician?.document)">
          {{ getName(technician?.document) }}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="3">No records found</td>
      </tr>
    </ng-template>
  </p-table> -->