import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  View,
  SysService,
  StaticService,
  ViewFactory,
  FormDefault,
  Model,
  noWhitespaceValidator,
} from "@app/_common";
import { AppConfig, AppConstants } from "@app/_config";
import { LazyLoadEvent } from "primeng/api";

@Component({
  selector: "app-Radiologist",
  templateUrl: "./radiologist-profile.component.html",
  styleUrls: ["./radiologist-profile.component.scss"],
})
export class RadiologistComponent implements OnInit {
  @Input() activeIndex: number = 0;
  @ViewChild("fileInput") fileInput: ElementRef;
  @ViewChild("fileInput1") fileInput1: ElementRef;

  private radiologistView: View = this.viewFactory.newView();
  buttonVisible1 = true;
  buttonVisible = true;
  countryLookup: any[];
  stateLookup: any[];
  uploadedFiles: any[] = [];
  maxDate: Date;
  flag: boolean;
  hasSignature: boolean = false;
  hasStamp: boolean = false;
  signature: any;
  stamp: any;
  imageurl = "/assets/images/thumbnailnew.png";
  sigRole: boolean;
  stampRole: boolean;
  stateLookupAll: any[];
  constructor(
    public sysService: SysService,
    public staticService: StaticService,

    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private router: Router,

    private viewFactory: ViewFactory
  ) { }
  ngOnInit() {
    this.radiologistView.of(this.radiologistModel());
    this.userDetails();

    this.maxDate = new Date();
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
  }

  get radiologist() {
    return this.radiologistView.model;
  }

  private radiologistModel() {
    const formDefault = new FormDefault([], []);
    return new Model(
      this.formBuilder.group({
        id: [],
        name: ["", [Validators.required, noWhitespaceValidator]],
        email: [
          "",
          [Validators.required, Validators.pattern(AppConstants.regexEmail)],
        ],
        phoneNo: [
          "",
          [
            Validators.required,
            Validators.pattern(AppConstants.regexPhonenumber),
            Validators.minLength(7),
          ],
        ],
        dob: ["", [Validators.required]],
        qualifications: ["", [Validators.required, noWhitespaceValidator]],
        registrationNo: ["", [Validators.required, noWhitespaceValidator]],
        zip: ["", [Validators.required, Validators.pattern(/^[a-zA-Z0-9]*$/)]],

        experience: [""],
        additionalQualifications: [""],
        additionalTraining: [""],
        countryId: [[Validators.required]],
        stateId: [""],
        address: ["", [Validators.required, noWhitespaceValidator]],
        designation: [""],
        signature: [""],
        fileuplaod: [""],
        status: [],
        suffix: [""],
        prefix: [""],
        stamp: [""],
        docFile1: [""],
        docFile2: [""],
        certificate: [""],
        signatureContent: [""],
        certificateContentType: [""],
        signatureContentType: [""],
      }),
      this.sysService,
      formDefault,
      ""
    );
  }

  saveradiologist() {
    if (this.radiologistView.isValid()) {
      this.radiologist.setMultipart();
      this.radiologistView
        .toSave("/users/radupdate", "id", "Profile Successfully Updated.")
        .then((data) => {
          this.userDetails();
          this.sysService.loadUserName();
          this.radiologist.clearMultipart();
          this.sysService.success("Profile Successfully Updated");
          this.buttonVisible1 = true;
          this.buttonVisible = true;
          this.radiologist.set("docFile2", null);
          this.radiologist.set("docFile1", null);
          setTimeout(() => {
            this.sysService.clear();
          }, 3000);
        });
    }
  }

  // async saveradiologist() {
  //   this.radiologist.setMultipart();

  //   await this.radiologistView.toSave("/users/radupdate", "id", "Profile Successfully Updated.").then(data => {
  //     this.radiologistView.toEditFetch('/users/userprofile', 'id').then(
  //       data => {
  //         this.radiologistView.image(`/users/rad-signature`)
  //           .subscribe(
  //             byte => {
  //               this.hasSignature = byte.byteLength > 0;
  //               this.signature = this.sysService.arrayBufferToBase64ImageSource(byte, data.signatureExt);

  //             }
  //           );
  //         this.radiologistView.image(`/users/rad-stamp`)
  //           .subscribe(
  //             byte => {
  //               this.hasStamp = byte.byteLength > 0;
  //               this.stamp = this.sysService.arrayBufferToBase64ImageSource(byte, data.signatureExt);

  //             }

  //           );

  //       }
  //     )
  //   })
  //   this.sysService.success('Profile changed Successfully');

  //   this.sysService.loadUserName();
  //   this.radiologist.clearMultipart()

  // }

  userDetails() {
    this.radiologistView
      .toEditFetch("/users/userprofile", "id")
      .then((data) => {
        this.radiologistView.image(`/users/rad-signature`).subscribe((byte) => {
          this.hasSignature = byte.byteLength > 0;
          this.signature = this.sysService.arrayBufferToBase64ImageSource(
            byte,
            data.signatureExt
          );
        });
        this.radiologistView.image(`/users/rad-stamp`).subscribe((byte) => {
          this.hasStamp = byte.byteLength > 0;
          this.stamp = this.sysService.arrayBufferToBase64ImageSource(
            byte,
            data.signatureExt
          );
        });
      });
  }

  radiologistDeactivte() {
    this.radiologist.set("status", 0);
    this.radiologistView
      .toSave(`/users/rad-deactivate/${this.radiologist?.view?.id}`, "id")
      .then((onSuccess) => {
        this.sysService.signOut();
        window.alert("Account deactivated successfully");
      })
      .catch((onError) => {
      });
  }
  closeSig(row: any) {

    this.radiologist.set("signature", null);
    this.signature = null;
    this.resetFileInput()
    this.radiologist.view.signature = null;
    this.signature = this.imageurl;
    this.buttonVisible1 = false;
  }

  closeStam(row: any) {
    this.radiologist.set("stamp", null);
    this.stamp = null;
    this.stamp = this.imageurl;
    this.resetFileInput1()
    this.radiologist.view.stamp = null;
    // this.hasSignature = true

    this.buttonVisible = false;
  }

  selectFile(file, name) {
    this.sysService.clear();
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target) {
        const img = new Image();
        img.src = e.target.result as string;

        img.onload = () => {
          const width = img.width;
          const height = img.height;


          if (file[0].size <= 200 * 1024) {
            if (width <= 275 && height <= 150) {
              this.radiologist.setFile(file, name);
              this.radiologist.view.signature = null;
              this.signature = img.src;
              this.hasSignature = true;
              this.buttonVisible1 = true;
            } else {
              this.sysService.error(
                " Please choose a smaller file with dimensions 275x150."
              );
              this.resetFileInput();
              this.radiologist.view.signature = null;
            }
          } else {
            this.sysService.error(
              "File size exceeds 200kb limit or incorrect dimensions. Please choose a smaller file with dimensions 275x150."
            );
            this.resetFileInput();
            this.radiologist.view.signature = null;
          }
        };
      }
    };

    // Read the file as a data URL
    reader.readAsDataURL(file[0]);
  }

  selectFile1(file, name) {
    this.sysService.clear();
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target) {
        const img = new Image();
        img.src = e.target.result as string;

        img.onload = () => {
          const width = img.width;
          const height = img.height;


          if (file[0].size <= 200 * 1024) {
            if (width <= 275 && height <= 150) {
              this.radiologist.setFile(file, name);
              this.radiologist.view.stamp = null;
              this.stamp = img.src;
              this.hasStamp = true;
              this.buttonVisible1 = true;
            } else {
              this.sysService.error(
                " Please choose a smaller file with dimensions 275x150."
              );
              this.radiologist.view.stamp = null;
              this.resetFileInput1();
            }
          } else {
            this.sysService.error(
              "File size exceeds 200kb limit or incorrect dimensions. Please choose a smaller file with dimensions 275x150."
            );
            this.radiologist.view.stamp = null;
            this.resetFileInput1();
          }
        };
      }
    };

    // Read the file as a data URL
    reader.readAsDataURL(file[0]);
  }

  resetFileInput() {
    // Reset the value of the input file
    this.fileInput.nativeElement.value = "";
  }
  resetFileInput1() {
    // Reset the value of the input file
    this.fileInput1.nativeElement.value = "";
  }

  // selectFile1(file, name) {

  //   this.radiologist.setFile(file, name);
  //   this.radiologist.view.stamp = null;

  // }

  getFilename(name) {
    if (name) {
      const filename = name.substring(name.indexOf("_") + 1, name.length);
      return filename.length > 15 ? filename.substring(0, 15) : filename;
    }
    return "";
  }


  onCountryChange(event) { }

  filterCountry(event: any) {
    this.sysService.clear();
    this.radiologistView
      .getData(`/country/lookup?filter=${event.query}`)
      .then((data) => {
        this.countryLookup = data;
      });
  }

  deteleRadiologist() {
    this.radiologistView.toDeleteNew("/radiologist/delete", "id", "id", {
      onSuccess: () => {
        this.sysService.signOut();
        window.alert("Your Account deleted Successfully.");
      },
      confirm:
        "Deleting your profile will permanently remove all of your data and content from our platform. Do you want to continue?",
    });
  }

  filterState(event: any) {
    // check if stateLookupAll is already loaded
    if (this.stateLookupAll) {
      // if loaded, filter the results
      this.stateLookup = this.stateLookupAll.filter(
        (x: any) =>
          x.name.toLowerCase().indexOf(event.query.toLowerCase()) === 0
      );
    } else {
      // if not loaded, get countryId from billing view and load states for that country
      let countryId = this.radiologist?.view?.countryId?.id;
      if (countryId === undefined) {
        this.sysService.error("Please select a country");
      } else {
        this.radiologistView
          .getData(`/state/lookupbycountry?countryId=${countryId}`)
          .then((data: any) => {
            // store the loaded states and set the filtered results
            this.stateLookup = data;
            this.stateLookupAll = data;
          });
      }
    }
  }

  loadState(countryId) {
    // load states for the given countryId
    this.radiologistView
      .getData(`/state/lookupbycountry?countryId=${countryId}`)
      .then((data) => {
        // store the loaded states and set the filtered results
        this.stateLookupAll = data;
        this.stateLookup = data;
      });
  }

  stateAreaOnChange(event: any) {
    // load states for the selected country
    this.loadState(event.id);
    // reset stateId
    this.radiologist.set("stateId", null);
  }
  removeFile(signatureContentType) {
    this.radiologist.set(signatureContentType, "");
    this.sysService.info("Save to remove the document");
  }


  sigRoles() {
    this.sigRole = true;
  }
  stampRoles() {
    this.stampRole = true;
  }
}
