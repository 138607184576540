import { Component, Input } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { View, SysService, StaticService, ViewFactory, FormDefault, Model, } from "@app/_common";
import { AppConstants } from "@app/_config";
import { loadStripe } from "@stripe/stripe-js/pure";
import { environment } from "src/environments/environment";
import { DialogService } from "primeng/dynamicdialog";
import { LazyLoadEvent } from "primeng/api";
import { load } from "@cashfreepayments/cashfree-js";

@Component({
  selector: "app-payment-recharge",
  templateUrl: "./payment-recharge.component.html",
  styleUrls: ["./payment-recharge.component.css"],
})
export class PaymentRechargeComponent {
  @Input() errFlag: boolean;
  showButtons: boolean = false;
  countryLookup: any[];
  stateLookup: any[];
  stateLookupAll: any[];
  paymentStage: number = 1;
  currency: string = "";
  billingDetailsData: any;
  editable: boolean = true;
  modalityPriceTable: boolean;
  payWithUPI: Boolean;
  customAmount: number;
  cashfree: any;
  private billinginfoView: View = this.viewFactory.newView();
  private billingDetailsView: View = this.viewFactory.newView();
  private billingInforView: View = this.viewFactory.newView();
  private modalityRateView: View = this.viewFactory.newView();
  private stripePromise;
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory,
    public dialogService: DialogService
  ) { }

  ngOnInit() {
    loadStripe.setLoadParameters({ advancedFraudSignals: false });
    this.stripePromise = loadStripe(environment.stripe);
    this.billinginfoView.of(this.billinginfoModel());
    this.billingDetailsView.of(this.billingDetailsModel());
    this.billingInforView.of(this.billingInforModel());
    this.modalityRateView.of(this.modalityRateModel()).list('/modality/rate-list');
    this.currency = environment.currency;
    this.payWithUPI = environment?.cashFree
    this.userDetails();
    this.initializeSDK();
  }

  get billinginfo() {
    return this.billinginfoView.model;
  }
  get billinginfor() {
    return this.billingInforView.model;
  }
  get billingDetails() {
    return this.billingDetailsView.model;
  }
  get modalityRates() {
    return this.modalityRateView.model;
  }
  private billingInforModel() {
    const formDefault = new FormDefault([]);
    return new Model(
      this.formBuilder.group({
        checkProgress: [],
        customerName: [''],
        email: [''],
        phone: [],
        address: [],
        company: [""],
        taxNo: [],
        amount: [],
        region: [],
        city: [],
        zip: [""],
        countryId: [],
        stateId: [],
        customerId: [],
        currency: [],
      }),
      this.sysService,
      formDefault, ""
    );
  }
  private modalityRateModel() {
    const formDefault = new FormDefault([]);
    return new Model(
      this.formBuilder.group({
        customerRate: [, [Validators.required]],
      }),
      this.sysService,
      formDefault,
      ""
    );
  }
  private billinginfoModel() {
    const formDefault = new FormDefault([]);
    return new Model(
      this.formBuilder.group({
        id: [],
        name: ["", [Validators.required]],
        email: ['', [Validators.required, Validators.pattern(AppConstants.regexEmail)]],
        phone: ["", [Validators.required, Validators.pattern(AppConstants.regexPhonenumber), Validators.minLength(7)]],
        address: [],
        company: [""],
        taxNo: [],
        region: [],
        city: [],
        zip: ["", [Validators.required, Validators.pattern(/^[a-zA-Z0-9]*$/)]],
        countryId: [, [Validators.required]],
        stateId: [],
        sessionId: [],
      }),
      this.sysService,
      formDefault,
      ""
    );
  }
  private billingDetailsModel() {
    const formDefault = new FormDefault([]);
    return new Model(
      this.formBuilder.group({}),
      this.sysService,
      formDefault,
      ""
    );
  }


  userDetails() {
    this.billinginfoView.getFormData("/billing/info?");
  }
  toggleEditable() {
    this.editable = false;
  }


  // Function to change the payment stage
  changeNextpage(amount: number) {
    this.billingDetailsView
      .getData(`/payment?amount=${amount}`)
      .then((data) => {
        this.billingDetailsData = data;
        this.paymentStage = 2;
      });
  }

  customerRateValue() {
    if (this.modalityRateView.isValid()) {
      const amount = this.modalityRates.get('customerRate')
      this.changeNextpage(amount)
    }
  }
  payToBack() {
    this.billinginfor.set("checkProgress", null);
    this.paymentStage = 1;
    this.errFlag = false;
    this.sysService.clear();
  }

  //cash free payment method 

  async initializeSDK() {
    this.cashfree = await load({
      mode: "sandbox"
    });
  }

  saveBilling(showMessage) {
    if (this.billinginfoView.isValid()) {
      this.billinginfoView.toSave("/billing", "id", showMessage ? '' : 'Billing Information details changed successfully')
        .then((data) => {
          this.paymentDetails();
          this.editable = true;
        })

    }
  }


  checkBoxValue(value: any) {
    if (this.billinginfor.isEmpty(value)) {
      this.errFlag = true;
      return false;
    } else {
      this.errFlag = false;
      return true;
    }
  }


  async payToProgress(): Promise<void> {
    const stripe = await this.stripePromise;
    if (this.checkBoxValue('checkProgress')) {
      this.billingInforView
        .toSave(
          `/payment/initiate?amount=${this.billingDetailsData.amount}`,
          "sessionId",
          "You will be redirected in a few seconds....Please wait ",
          "The request made on your behalf has failed"
        )
        .then((data: any) => {
          if (stripe) {
            stripe.redirectToCheckout({
              sessionId: data.sessionId,
            })
              .then((result) => {
              })
          }
        });
    }
  }
  filterState(event: any) {
    if (this.stateLookupAll) {
      this.stateLookup = this.stateLookupAll.filter(
        (x) => x.name.toLowerCase().indexOf(event.query.toLowerCase()) === 0
      );
    } else {
      let countryId = this.billinginfo?.view?.countryId?.id;
      this.billinginfoView
        .getData(`/state/lookupbycountry?countryId=${countryId}`)
        .then((data) => {
          this.stateLookup = data;
          this.stateLookupAll = data;
        });
    }
  }

  loadState(countryId) {
    this.billinginfoView
      .getData(`/state/lookupbycountry?countryId=${countryId}`)
      .then((data) => {
        this.stateLookupAll = data;
        this.stateLookup = data;
      });
  }


  stateAreaOnChange(event: any) {
    this.loadState(event.id);
    this.billinginfo.set("stateId", null);
  }
  filterCountry(event: any) {
    this.billinginfoView.getData(`/country/lookup?filter=${event.query}`).then((data) => { this.countryLookup = data; });
  }

  termsConditionsPdf() {
    const pdfUrl = '/assets/pdf/termsconditions.pdf';
    window.open(pdfUrl, '_blank');
  }

  modalityPriceList() {
    this.modalityPriceTable = true;
  }
  add() {
    this.modalityRateView.toNew();
  }

  edit(id: any) {
    this.modalityRateView.toEditFetch(`/modality/rate-list/${id}`, id);
  }

  list(id: any) {
    this.listView(false);
  }

  async listmodalityRatesLazy(event?: LazyLoadEvent, search: boolean = false) {
    this.modalityRateView.toListLazyFetch(`/modality/rate-list`, search, event);
  }

  listView(search: boolean = false) {
    this.modalityRateView.toListLazyView(`/modality/rate-list`, search);
  }

  view(id: any) {
    this.modalityRateView.toViewFetch(`/modality/rate-list/${id}`, id);
  }


  paymentDetails() {
    this.billinginfor.set('customerId', this.billinginfo.get("id"));
    this.billinginfor.set('customerName', this.billinginfo.get("name"));
    this.billinginfor.set('email', this.billinginfo.get("email"));
    this.billinginfor.set('address', this.billinginfo.get("address"));
    this.billinginfor.set('taxNo', this.billinginfo.get("taxNo"));
    this.billinginfor.set('countryId', this.billinginfo.get("countryId"));
    this.billinginfor.set('phone', this.billinginfo.get("phone"));
    this.billinginfor.set('amount', this.billingDetailsData.amount);
    this.billinginfor.set('currency', environment.currency);
  }


  async payWithCard(png: number) {
    this.saveBilling(true);
    if (this.checkBoxValue('checkProgress') && this.billinginfoView.isValid()) {
      this.paymentDetails()
      this.billingInforView.toSave(
        `/v2/payment/orders`,
        "sessionId",
        "You will be redirected in a few seconds....Please wait ",
        "The request made on your behalf has failed"
      ).then((data: any) => {
        let checkoutOptions = {
          paymentSessionId: data.payment_session_id,
          redirectTarget: "_self"
        };
        this.cashfree.checkout(checkoutOptions);
      }).catch((error: any) => {
        this.sysService.error(error.error.message)
      });
    } else {
    }
  }
}

