import { Component, HostListener } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model } from '@app/_common';
import { AppConstants } from '@app/_config';
import { environment } from '@environments/environment';
import { LazyLoadEvent } from 'primeng/api';
@Component({
  selector: 'app-centre-users',
  templateUrl: './centre-users.component.html',
  styleUrls: ['./centre-users.component.css']
})
export class CentreUsersComponent {
  private centreUsersView: View = this.viewFactory.newView();
  saveButtonLabel: string = 'Save';
  countryLookup: any[];
  eventData: any;
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory
  ) { }
  ngOnInit() {
    this.scrollHeight()
    this.detectZoomChange()

    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 400));

    this.centreUsersView.of(this.centreUsersModel()).switchAction("/users/centre-users", this.route, this);
  }
  get centreUsers() {
    return this.centreUsersView.model;
  }
  private centreUsersModel() {
    const formDefault = new FormDefault(["status"], [1]);
    return new Model(
      this.formBuilder.group({
        id: [],
        name: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.pattern(AppConstants.regexEmail)]],
        phoneNo: ['', [Validators.required, Validators.pattern(AppConstants.regexPhonenumber), Validators.minLength(7)]],
        userChildRole: [, [Validators.required]],
        status: ['']
      }),
      this.sysService,
      formDefault,
      ""
    );
  }
  add() {
    this.centreUsersView.toNew();
    this.saveButtonLabel = "Save";
  }
  edit(id: any) {
    this.centreUsersView.toEditFetch(`/users/${id}`, id);
    this.saveButtonLabel = "Update";
  }
  list(id: any) {
    this.listView(false);
  }
  heightTable: any;
  debounceTimeout: any;
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 300) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 300) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  detectZoomChange() {
    this.scrollHeight();
  }
  async listCentreUsersLazy(event?: LazyLoadEvent, search: boolean = false) {

    this.eventData = event
    this.centreUsersView.toListLazyFetch(`/users/centre-users`, search, event);
  }
  listView(search: boolean = false) {
    this.centreUsersView.toListLazyView(`/users/centre-users`, search);
  }
  view(id: any) {
    this.centreUsersView.toViewFetch(`/users/centre-users/${id}`, id);
  }
  deleteCentreUserss() {
    this.centreUsersView.toDeleteSelection(`/users`, {
      onSuccess: () => {
        this.centreUsersView.toListLazyFetch(`/users/centre-users`, true, this.eventData);
      }
    });

  }
  deleteCentreUsers(id: any) {
    this.centreUsersView.toDeleteList(`/users/${id}`, "id", id);
  }
  saveCentreUsers(add: boolean = false) {
    if (add) {
      this.centreUsersView.toSaveNewFetch("/users", "id");
      this.centreUsers.searchTxt;
    } else {
      this.centreUsersView.toSaveListFetch("/users", "id", this.centreUsers.isEdit() ? "User Updated Successfully ." : " User Saved Successfully .");
      this.centreUsers.searchTxt;
    }
  }
  filterCountry(event: any) {
    this.centreUsersView.getData(`/country/lookup?filter=${event.query}`).then((data) => {
      this.countryLookup = data;
    });
  }
}
