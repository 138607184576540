<div class="loading-container" *ngIf="sysService.loading ||loading ">
  <div class="loading-blur"></div>
  <div class="loading-spinner">
    <img src="../../assets/images/logo.png" alt="Logo" width="80" class="img1">
    <div class="center">
      <ul>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>

    </div>

  </div>




</div>