import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { View } from '@app/_common';

@Component({
  selector: 'f-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
 
  @Input() home: boolean=true; 
  @Input() model: any; 
  @Input() first: string; 
  @Input() label: string; 
  @Input() list: string = "List"; 
  @Input() new: string = "Add"; 
  @Input() edit: string = "Edit";  
  @Input() read: string = "View"; 
  constructor(
  ) { }

  ngOnInit(): void {
    // console.log(this.first)
  }

}