import { Component, OnInit, ViewChild } from '@angular/core';
// import { cashfreeSandbox} from 'cashfree-pg-sdk-javascript';
import { ExportService, FormDefault, StaticService, SysService, ViewAction, ViewFactory } from '@app/_common';
import { Model, View } from '@app/_common/';
import { ActivatedRoute } from '@angular/router';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
@Component({
  selector: 'payment-sandbox-checkout',
  templateUrl: './checkout-sandbox.component.html',
  styleUrls: ['./checkout-sandbox.component.css']
})
export class CheckoutSandboxComponent implements OnInit {

  private stateView: View = this.viewFactory.newView();

  constructor(
    public sysService: SysService,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory) {
  }

  ngOnInit() {
    // this.stateView.of(this.stateModel()).switchAction('/state', this.route, this);
  }

   render() {
    //Call order api that will return session id
  //   let paymentSessionId = "session_-bmEAQtO0wcNXwRQ3jh3GuJc7QkB0s9DPbnprmQU4jJQQ8JpJpeWSe2KDKWGsNko3a93DmFO-wruWqXfAjrItj0HSH9a3cTV3hADLHNhcKLs";
  //   if (paymentSessionId == "") {
  //     this.sysService.error("No session_id specified");
  //     return
  //   };
  // const cf = new cashfreeSandbox.Cashfree(paymentSessionId);
  //   cf.redirect();
    }
}