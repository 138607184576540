<header class="header" *ngIf="!sysService.isLoggedIn()">
    <div class="wrap">
        <div class="right">
            <div class="rgtBdr ">
                <a (click)="navHome()"> <img class="web" src="../../../assets/images/eclogoo.png"></a>
            </div>
        </div>
    </div>
</header>
<div class="card try">
    <h1>Radiologist Registration </h1>
    <form [formGroup]="RadiologistRegistration.form">
        <div class="grid">
            <div class="field col-12 md:col-6 lg:col-4">
                <label>Prefix</label>
                <input pInputText maxlength="12" formControlName="prefix" type="text" pAutoFocus autofocus="true">
            </div>
            <div class="field col-12 md:col-6 lg:col-4">
                <label>Full Name <span class="validation-star">*</span></label>
                <input pInputText maxlength="50" formControlName="name" type="text">
                <f-error [err]="RadiologistRegistration.required('name')" msg="Full Name is
                    required"></f-error>
            </div>
            <div class="field col-12 md:col-6 lg:col-4">
                <label>Suffix</label>
                <input pInputText maxlength="100" formControlName="suffix" type="text">
            </div>
            <div class="field col-12 md:col-6 lg:col-4">
                <label for="EmailAddress">Email Address <span class="validation-star">*</span>(This will also be your
                    Username) </label>
                <input id="EmailAddress" maxlength="100" type="text" pInputText formControlName="email" required
                    autocomplete="off" />
                <f-error [err]="RadiologistRegistration.required('email')" msg="Email Address is required "></f-error>
                <f-error [err]="RadiologistRegistration.pattern('email')"
                    msg="This e-mail address is not valid. Valid records include to user@somehost.somedomain. And emails only accept lowercase letters.">
                </f-error>
            </div>
            <div class="field col-12 md:col-6  lg:col-4">
                <label>Phone Number <span class="validation-star">*</span></label>
                <input pInputText maxlength="18" formControlName="phoneNo" type="text">
                <f-error [err]="RadiologistRegistration.required('phoneNo')" msg="Phone Number is required"></f-error>
                <f-error [err]="RadiologistRegistration.pattern('phoneNo')" msg="Numbers only required"></f-error>
                <f-error [err]="RadiologistRegistration.minlength('phoneNo')"
                    msg="Enter a minimum of 7 digits"></f-error>
            </div>
            <div class="field col-12 md:col-6 lg:col-4">
                <label>Date of Birth <span class="validation-star">*</span></label>
                <p-calendar [showIcon]="true" [defaultDate]="maxDate" [maxDate]="maxDate" formControlName="dob"
                    dateFormat="dd-mm-yy" placeholder="DD-MM-YYYY" dataType="string" [showIcon]="true"
                    class="widthClass"></p-calendar>
                <f-error [err]="RadiologistRegistration.required('dob')" msg="Date of Birth  is required"></f-error>
            </div>
            <div class="field col-12 md:col-6 lg:col-4">
                <label>Qualification <span class="validation-star">*</span></label>
                <input pInputText maxlength="100" formControlName="qualifications" type="email">
                <f-error [err]="RadiologistRegistration.required('qualifications')"
                    msg="Qualification is required"></f-error>
            </div>
            <div class="field col-12 md:col-6 lg:col-4">
                <label>Register No <span class="validation-star">*</span></label>
                <input pInputText maxlength="50" formControlName="registrationNo" type="text">
                <f-error [err]="RadiologistRegistration.required('registrationNo')"
                    msg="Register No is required"></f-error>
            </div>
            <div class="field col-12 md:col-6  lg:col-4">
                <label>Designation </label>
                <input pInputText maxlength="50" formControlName="designation" type="text">
            </div>
            <div class="field col-12 md:col-6 lg:col-4">
                <label>Experience after basic radiology training </label>
                <input pInputText maxlength="495" formControlName="experience" type="text">
            </div>
            <div class="field col-12 md:col-6 lg:col-4">
                <label>Additional Qualification</label>
                <input pInputText maxlength="240" formControlName="additionalQualifications" type="text">
            </div>
            <div class="field col-12 md:col-6 lg:col-4">
                <label>Additional Training </label>
                <input pInputText maxlength="254" formControlName="additionalTraining" type="text">
            </div>
            <div class="field col-12 md:col-6 lg:col-4">
                <label>Country <span class="validation-star">*</span></label>
                <p-autoComplete formControlName="countryId" [suggestions]="countryLookup"
                    (onSelect)="stateAreaOnChange($event)" forceSelection="true" maxlength="60"
                    (completeMethod)="filterCountry($event)" field="name" ariaLabel="Country" [dropdown]="true"
                    [autoHighlight]="true" showEmptyMessage="true" delay="400">
                </p-autoComplete>
                <f-error [err]="RadiologistRegistration.required('countryId')" msg="Country is required"></f-error>
            </div>
            <div class="field col-12 md:col-6 lg:col-4">
                <label>State </label>
                <p-autoComplete formControlName="stateId" [suggestions]="stateLookup"
                    (completeMethod)="filterState($event)" maxlength="60" forceSelection="true" field="name"
                    ariaLabel="State" [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400">
                </p-autoComplete>
            </div>
            <div class="field col-12 md:col-6 lg:col-4">
                <label>Address <span class="validation-star">*</span></label><textarea class="p-inputtextarea
                    p-inputtext p-component p-element" [rows]="3" [cols]="40" maxlength="480" formControlName="address"
                    pInputTextarea></textarea>
                <f-error [err]="RadiologistRegistration.required('address')" msg="Address is
                    required"></f-error>
            </div>
            <div class="field col-12 md:col-6 lg:col-4">
                <label>Zip Code <span class="validation-star">*</span> </label>
                <input pInputText maxlength="12" formControlName="zip" type="text">
                <f-error [err]="RadiologistRegistration.required('zip')" msg="Zip Code is required"></f-error>
                <f-error [err]="RadiologistRegistration.pattern('zip')"
                    msg=" Only alphanumeric characters are accepted"></f-error>
            </div>
            <div class="field col-12 md:col-6 lg:col-4 login-password">
                <label for="password">Password <span class="validation-star">*</span></label>
                <p-password [toggleMask]="true" formControlName="password" id="password" required autocomplete="off"
                    maxlength="15">
                    <ng-template pTemplate="footer">
                        <p class="p-mt-2">Suggestions</p>
                        <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                            <li>At least one lowercase</li>
                            <li>At least one uppercase</li>
                            <li>At least one numeric</li>
                            <li>Minimum 8 characters</li>
                        </ul>
                    </ng-template>
                </p-password>
                <f-error [err]="RadiologistRegistration.required('password')" msg="Password is  required"></f-error>
                <f-error [err]="RadiologistRegistration.minlength('password')"
                    msg="Minumum 8 characters are required."></f-error>
                <f-error [err]="RadiologistRegistration.maxlength('password')"
                    msg=" Maximum 15 characters are allowed."></f-error>
                <f-error [err]="RadiologistRegistration.pattern('password')"
                    msg="At least one lowercase, uppercase and numeric  are required."></f-error>
            </div>
            <div class="field col-12 md:col-6 lg:col-4 login-password">
                <label for="confirmPassword">Confirm Password <span class="validation-star">*</span></label>
                <p-password [toggleMask]="true" formControlName="confirmPassword" id="confirmPassword" required
                    autocomplete="off" maxlength="15">
                    <ng-template pTemplate="footer">
                        <p class="p-mt-2">Suggestions</p>
                        <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                            <li>At least one lowercase</li>
                            <li>At least one uppercase</li>
                            <li>At least one numeric</li>
                            <li>Minimum 8 characters</li>
                        </ul>
                    </ng-template>
                </p-password>
                <f-error [err]="RadiologistRegistration.required('confirmPassword')" msg="Confirm Password is required">
                </f-error>
                <f-error [err]="RadiologistRegistration.hasError('notSame')"
                    msg="Your password and your confirm password do not match. Please correct and resubmit">
                </f-error>
                <f-error [err]="RadiologistRegistration.maxlength('confirmPassword')"
                    msg=" Maximum 15 characters are allowed."></f-error>
                <f-error [err]="RadiologistRegistration.minlength('confirmPassword')"
                    msg="Minumum 8 characters are required.">
                </f-error>
                <f-error [err]="RadiologistRegistration.pattern('confirmPassword')"
                    msg="At least one lowercase, uppercase and numeric  are required."></f-error>
            </div>
            <div class="field col-12 md:col-6 lg:col-4 mt-5">
                <div class="flex align-items-center gap-1">
                    <p-checkbox [binary]="true" inputId="binary" formControlName="teleradiologyCoordinator">
                    </p-checkbox> Teleradiology Coordinator
                    &nbsp;
                </div>
                <label *ngIf=" RadiologistRegistration.get('teleradiologyCoordinator')===true" for="ny">
                    Will not be in charge of report generation</label>
            </div>
        </div>
    </form>
    <div class="grid justify-content-center p-2">
        <button type="button" pButton pRipple label="Back" icon=" pi pi-arrow-left" (click)="back()"
            class="p-button-outlined p-button-info"></button>&nbsp;
        <button pButton pRipple type="button" label="Submit" (click)="saveRadiologistRegistration()" icon=" pi pi-check"
            class="p-button-raised p-button-success " [disabled]="sysService.loading || !captcha">
            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
        </button>
    </div>
</div>