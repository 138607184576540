import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormDefault, Model, StaticService, SysService, View, ViewFactory } from '@app/_common';
import { AppConstants } from '@app/_config';
import { environment } from '@environments/environment';
import { LazyLoadEvent, } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
@Component({
  selector: 'app-rate-card',
  templateUrl: './rate-card.component.html',
  styleUrls: ['./rate-card.component.scss'],
})
export class RateCardComponent implements OnInit {
  rateCardListExport = ['name', 'taxCode', 'code', 'countryId.name']; //for export header fields
  private rateCardListView: View = this.viewFactory.newView();
  changeRate: boolean = false;
  countryLookup: any[];
  radLookup: any[];
  cenLookup: any[];
  modLookup: any[];
  studyDesc: any[];
  userLookup: any[];
  eventData: any;
  radId: any;
  popUp: boolean; // this flag is used for dynamical popup
  buttonHide: boolean;
  contactRadi: boolean;
  search: boolean = false;
  @Input() rateUpate: boolean = false;
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig, // used for dynamical popup
    public route: ActivatedRoute,
    private viewFactory: ViewFactory) {
  }
  ngOnInit() {
    this.scrollHeight()
    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 400));
    this.detectZoomChange()

    this.rateCardListView.of(this.rateCardListModel());
    this.radId = this.config?.data?.radId;
    this.popUp = this.config?.data?.popUp
    this.buttonHide = this.config?.data?.popUp
    this.contactRadi = this.config?.data?.contactRadi
    if (this.popUp) {

    }
  }
  get rateCardList() {
    return this.rateCardListView.model;
  }
  private rateCardListModel() {
    const formDefault = new FormDefault(['status', 'reportTemplateId', 'radiologistName'], [1, 11, this.radId]);
    return new Model(this.formBuilder.group({
      id: [],
      centreId: [''],
      modalityCode: [],
      userId: [],
      modality: [''],
      studyDesc: [''],
      rate: [, Validators.pattern(AppConstants.regexZipCpde)],
      centreName: [''],
      radUserId: [],
      reportTemplateId: [],
      contacts: [],
      radiologistName: [],
    }), this.sysService, formDefault, '');
  }
  add() {
    this.rateCardListView.toNew();
  }
  list(id: any) {
    this.listView(false);
  }
  isScrollTable = window.innerWidth > environment.devices.tablet ? true : false;
  heightDisplay = window.innerHeight - 480 + "px";
  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.isScrollTable =
      window.innerWidth > environment.devices.tablet ? true : false;
    this.heightDisplay = window.innerHeight - 480 + "px";
  }
  async listrateCardListLazy(event?: LazyLoadEvent, search: boolean = false) {
    this.eventData = event
    this.rateCardList.setParam("userId", this.rateCardList.get("userId")?.id);
    this.rateCardList.setParam("cenId", this.rateCardList.get("centreName")?.id);
    this.rateCardList.setParam("modality", this.rateCardList.get("modality")?.name);
    this.rateCardList.setParam("studyDesc", this.rateCardList.get("studyDesc")?.name);
    if (!this.popUp) {
      this.rateCardList.setParam("radId", this.rateCardList.get("radiologistName")?.id);
    } else {
      this.rateCardList.setParam("radId", this.radId);
      this.rateCardList.setParam("contacts", true)
    }
    this.rateCardListView.toListLazyFetch(`/raddetails/rates`, this.search, event);
    this.search = false;
  }
  searchrateCardList() {
    this.search = true;
  }
  listView(search: boolean = false) {
    this.rateCardListView.toListLazyView(`/raddetails`, search);
  }
  view(id: any) {
    this.rateCardListView.toViewFetch(`/raddetails/${id}`, id);
  }
  deleterateCardLists() {
    this.rateCardListView.toDeleteSelection(`/raddetails`, {
      onSuccess: () => {
        this.rateCardListView.toListLazyFetch(`/raddetails/rates`, true, this.eventData);
      }
    });

  }
  deleterateCardList(id: any) {
    this.rateCardListView.toDeleteList(`/raddetails/${id}`, "id", id);
  }
  edit(id: any) {
    this.rateCardList.reset()

    this.rateCardListView.toEditFetch(`/raddetails/${id}`, id);
    this.changeRate = true;
  }
  saverateCardList() {
    this.rateCardListView.toSaveList("/raddetails/update-rate", "id", 'Rate changed successfully').then(data => {
      this.changeRate = false;
    }
    )
  }
  resetRateCardList() {
    this.rateCardList.reset();
    this.search = true;
  }
  filterRad(event: any) {
    this.rateCardListView.getData(`/radiologist/radiologist-lookup?filter=${event.query}`).then(data => {
      this.radLookup =
        data;
    });
  }
  filterCen(event: any) {
    this.rateCardListView.getData(`/centre/centre-lookup?filter=${event.query}`).then(data => {
      this.cenLookup =
        data;
    });
  }
  filterUser(event: any) {
    this.rateCardListView.getData(`/users/associated-lookup?filter=${event.query}`).then(data => {
      this.userLookup =
        data;
    });
  }
  filterStudyDesc(event: any) {
    const baseUrl = this.popUp
      ? '/studydescription/studydesc-contact-lookup?filter='
      : '/raddetails/studydesc-lookup?filter=';

    this.rateCardListView.getData(`${baseUrl}${event.query}`).then(data => {
      this.studyDesc =
        data;
    });
  }
  filterMod(event: any) {
    const baseUrl = this.popUp
      ? '/modality/modality-contact-lookup?filter='
      : '/modality/modality-lookup?filter=';

    this.rateCardListView.getData(`${baseUrl}${event.query}`).then(data => {
      this.modLookup =
        data;
    });
  }
  searchratecardContact(radId: any) {
    this.radId = this.rateCardList.get("radiologistName")?.id;
    this.rateCardListView.getDataList(`/raddetails/rates?radId=${radId}`,
    );
  }
  cancelRateChange() {
    this.list(true)
    this.changeRate = false;
    this.search = true;
  }
  heightTable: any;
  debounceTimeout: any;
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 450) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 450) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  detectZoomChange() {
    this.scrollHeight();
  }
}