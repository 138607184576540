import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    public permission: Permission;

    constructor() {
        this.permission = this.isSupported() ? 'default' : 'denied';
    }

    public isSupported(): boolean {
        return 'Notification' in window;
    }

    requestPermission(): void {
        let self = this;
        if ('Notification' in window) {
            Notification.requestPermission(function (status) {
                return self.permission === status;
            });
        }
    }

    create(title: string, options?: PushNotification): any {
        let self = this;
        return new Observable(function (obs) {
            if (!('Notification' in window)) {
                console.log('Notifications are not available in this environment');
                obs.complete();
            }
            if (self.permission !== 'granted') {
                console.log("The user hasn't granted permission to send push notifications");
                obs.complete();
            }
            let _notify = new Notification(title, options);
            _notify.onshow = function (e) {
                return obs.next({
                    notification: _notify,
                    event: e
                });
            };
            _notify.onclick = function (e) {
                e.preventDefault(); // prevent the browser from focusing the Notification's tab
            window.open(environment.app + '/studies',"eclarity-refresh");
                return obs.next({
                    notification: _notify,
                    event: e
                });
            };
            _notify.onerror = function (e) {
                return obs.error({
                    notification: _notify,
                    event: e
                });
            };
            _notify.onclose = function () {
                return obs.complete();
            };
        });
    }

    // generateNotification(source: Array<any>): void {
    //     let self = this;
    //     source.forEach((item) => {
    //         let options = {
    //             body: item.alertContent,
    //             icon: "../assets/images/note.png"
    //         };
    //         let notify = self.create(item.title, options).subscribe();
    //     })
    // }

    generateSingleNotification(item: any, window: any): void {
        let self = this;
        let options = {
            body: item.alertContent,
            tag:"eclarity-unique-tag-id",
            icon: "../assets/images/logo.png",  
            silent: false,
            vibrate: [200, 100, 200],
        };
  
        let notify = self.create(item.title, options).subscribe();
    }
}

export declare type Permission = 'denied' | 'granted' | 'default';

export interface PushNotification {
    body?: string;
    icon?: string;
    tag?: string;
    data?: any;
    renotify?: boolean;
    silent?: boolean;
    sound?: string;
    noscreen?: boolean;
    sticky?: boolean;
    dir?: 'auto' | 'ltr' | 'rtl';
    lang?: string;
    vibrate?: number[];
}
