import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model } from '@app/_common';
import { AppConstants } from '@app/_config';

import { HttpClient } from '@angular/common/http';
import { loadStripe } from '@stripe/stripe-js/pure';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.css']
})
export class PaymentInfoComponent {
  private billinginfoView: View = this.viewFactory.newView();

  countryLookup: any[];
  stateLookup: any[];
  stateLookupAll: any[];

  // We load Stripe
  stripePromise;

  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory,
    private http: HttpClient
  ) { }

  ngOnInit() {
    loadStripe.setLoadParameters({ advancedFraudSignals: false });
    this.stripePromise = loadStripe(environment.stripe);
    this.billinginfoView.of(this.billinginfoModel())
  }

  get billinginfo() {
    return this.billinginfoView.model;
  }

  changeNextpage(amount: number) {
    const url = `payment?amount=${amount}`;

    this.billinginfoView.getData(url);
  }

  private billinginfoModel() {
    const formDefault = new FormDefault([]);
    return new Model(this.formBuilder.group({
      id: [],
      name: [, [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern(AppConstants.regexPhonenumber)]],
      address: [],
      company: [''],
      taxNo: [],
      region: [],
      city: [],
      zip: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(AppConstants.regexEmail)]],
      countryId: [, [Validators.required]],
      stateId: [],
    }), this.sysService, formDefault, '');
  }


  async pay(): Promise<void> {
    const textbox = document.getElementById('amountBox') as HTMLInputElement;
    let amount: any = textbox.value;

    const payment = {
      name: 'eCScribe',
      currency: 'usd',
      amount: amount * 100,
      quantity: '1',
    };

    const stripe = await this.stripePromise;

    // Make a POST request to your backend API to create a payment session
    this.http.post(`${environment.appApi}/payment`, payment).subscribe((data: any) => {
      localStorage.setItem('paymentId', data.id);

      if (stripe) {
        stripe.redirectToCheckout({
          sessionId: data.id,
        }).then(result => {
        }).catch(error => {
        });
      }
    });
  }
}
