<div *ngIf="!sysService.isPermission()">
  <not-found></not-found>
</div>
<div *ngIf="sysService.isPermission()">
  <p-toolbar>
    <div class="p-toolbar-group-start">
      <f-nav first="/Radiologistlist" label="Radiologists" [model]="radiologist">
      </f-nav>
    </div>
    <div class="p-toolbar-group-end">
      <button pButton pRipple type="button" label="Delete" icon="pi pi-trash" class="p-button-outlined
      p-button-outlined p-button-danger" (click)="deleteradiologist(radiologist.id)"
        *ngIf="radiologist.isEdit() && sysService.isAdmin() "></button>
    </div>
  </p-toolbar>
  <div class="table-responsive" [style.display]="radiologist.islist? 'block':'none'">
    {{radiologist.total}}




    <p-table #dt [value]="radiologist.list" class="study-list-class" [scrollable]="true" [scrollHeight]="heightTable"
      [totalRecords]="radiologist.total" [lazy]="true" (onLazyLoad)="listradiologistLazy($event)" dataKey="id"
      [(selection)]="radiologist.selection" selectionMode="multiple" [loading]="sysService.loading" filterDelay="400"
      styleClass="p-datatable-sm p-datatable-gridlines" currentPageReportTemplate="{first} to {last} of {totalRecords}"
      [rowsPerPageOptions]="[25, 50]" [rowHover]="true" [rows]="25" [paginator]="true" [showCurrentPageReport]="true"
      alwaysShow="false" paginatorPosition="bottom" [globalFilterFields]="['name','email','phoneNo','status']">



      <ng-template pTemplate="caption">

        <div class="grid">
          <div class="ml-2">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" maxlength="150" [(ngModel)]="radiologist.searchTxt"
                (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." pAutoFocus
                [autofocus]="true" pattern="^[a-zA-Z0-9 ]*$" title="Special characters are not allowed" />
            </span>
          </div>
          <div class="ml-2 mt-2">
            <span>
              <button pButton pRipple type="button" label="Add Radiologist" icon="pi pi-plus" class="p-button-raised"
                *ngIf="radiologist.isList() && sysService.isAdmin()" (click)="add()"></button>
            </span>
          </div>
          <div class="mt-2">
            <button pButton pRipple type="button" label="Delete" icon="pi pi-trash"
              class="p-button-outlined p-button-danger" (click)="deleteradiologists()" *ngIf="!radiologist.isEmptyList() &&
          radiologist.selection.length> 0 && sysService.isAdmin()"></button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="paginatorleft">
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 3rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th pSortableColumn="name" style="min-width:117px;"> Radiologist Name <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="qualifications">Qualification <p-sortIcon field="qualifications"></p-sortIcon>
          </th>
          <th pSortableColumn="email">Email Address <p-sortIcon field="email"></p-sortIcon>
          </th>
          <th pSortableColumn="phone_no">Phone Number <p-sortIcon field="phone_no"></p-sortIcon>
          </th>
          <th pSortableColumn="createdDate">Registration Date <p-sortIcon field="createdDate"></p-sortIcon>
          </th>
          <th pSortableColumn="teleradiology_coordinator">Radiologist Type <p-sortIcon
              field="teleradiology_coordinator"></p-sortIcon>
          </th>
          <th pSortableColumn="walletBalance" *ngIf="sysService.isAdmin()">Wallet Balance <p-sortIcon
              field="walletBalance">
            </p-sortIcon>
          </th>
          <th *ngIf="sysService.isAdmin()" pSortableColumn="status">Status<p-sortIcon field="status">
            </p-sortIcon>
          </th>
          <th *ngIf="sysService.isAdmin()">Action </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-radiologist>
        <tr [ngClass]="{'ui-state-highlight': chk.checked}">
          <td>
            <p-tableCheckbox #chk [value]="radiologist"></p-tableCheckbox>
          </td>
          <td> <i class="pi pi-file-edit link-study" (click)="edit(radiologist.id)" title="edit"> </i> &nbsp;
            {{radiologist.fullName}}

          </td>
          <td>{{radiologist?.qualifications}}</td>
          <td class="bdy-part">{{radiologist?.email}}</td>
          <td>{{radiologist?.phoneNo}}</td>
          <td>{{ sysService.toDate( radiologist?.createdDate ) | date: 'dd-MM-yyyy hh:mm a ' }}</td>
          <td>
            <span class="text-success" *ngIf="radiologist.teleradiologyCoordinator=== true  ">Teleradiology Company
            </span>
            <span class="text-accent" *ngIf="radiologist.teleradiologyCoordinator===false  "> Radiologist</span>
          </td>
          <td *ngIf="sysService.isAdmin()">{{radiologist.walletBalance}} </td>
          <td *ngIf="sysService.isAdmin()">
            <span class="text-danger" *ngIf="radiologist.status ===2 || radiologist.status ===0 ">Inactive </span>
            <span class="text-success" *ngIf=" radiologist.status ===1"> Active</span>
          </td>
          <td *ngIf="sysService.isAdmin()">
            <i class="pi pi-user-plus m-2" title="Approve"
              style="color: rgb(135, 7, 133); font-size: x-large; cursor: pointer;" *ngIf="radiologist.status ===2 "
              (click)="activateButton(radiologist)"></i>
            <i class="pi pi-times-circle m-2" title="Deactivate"
              style="color: rgb(204, 10, 10); font-size: x-large; cursor: pointer;" *ngIf="radiologist.status ===1 "
              (click)="activateButton(radiologist)"></i>
            <i class="pi pi-check-circle m-2" title="Activate"
              style="color: rgb(17, 60, 7);font-size: x-large; cursor: pointer;" *ngIf="radiologist.status === 0 "
              (click)="activateButton(radiologist)"></i>
            <i class="pi pi-users m-2" title="Associate"
              style="color: rgb(90, 8, 105); font-size: x-large; cursor: pointer;" *ngIf="radiologist.status === 1 "
              (click)="associateUser(radiologist)"></i>
            <i class="pi pi-wallet m-2" title="Wallet Update"
              style="color: rgb(10, 26, 204); font-size: x-large; cursor: pointer;" *ngIf="radiologist.status === 1 "
              (click)="transactionAmount(radiologist)"></i>
            <i class="pi pi-sign-in m-2" title=" Log in to their account"
              style="color: rgb(175, 1, 244); font-size: x-large; cursor: pointer;"
              *ngIf="radiologist.status === 1 && sysService.isAdmin() " (click)="loginToSysadmin(radiologist)"></i>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td class="norecordstudy" [attr.colspan]="10">No records found</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <form [formGroup]="radiologist.form">
    <div *ngIf="radiologist.isform || radiologist.isView()" class="card">
      <div class="grid">
        <div class="field col-12 md:col-6  lg:col-4"> <label>Prefix</label>
          <input pInputText maxlength="12" [readOnly]="flag" formControlName="prefix" type="text" pAutoFocus
            autofocus="true">
        </div>
        <div class="field col-12 md:col-6  lg:col-4">
          <label>Full Name <span class="validation-star">*</span></label>
          <input pInputText maxlength="50" [readOnly]="flag" formControlName="name" type="text" pAutoFocus>
          <f-error [err]="radiologist.required('name')" msg="Full Name  is required"></f-error>
        </div>
        <div class="field col-12 md:col-6  lg:col-4"> <label>Suffix</label>
          <input pInputText maxlength="100" [readOnly]="flag" formControlName="suffix" type="text" pAutoFocus>
        </div>
        <div class="field col-12 md:col-6  lg:col-4">
          <label>Email Address <span class="validation-star">*</span></label>
          <input pInputText maxlength="100" [readOnly]="flag" formControlName="email" type="email">
          <f-error [err]="radiologist.required('email')" msg="Email Address is required "></f-error>
          <f-error [err]="radiologist.pattern('email')"
            msg="This e-mail address is not valid. Valid records include to user@somehost.somedomain. And emails only accept lowercase letters.">
          </f-error>
        </div>
        <div class="field col-12 md:col-6  lg:col-4">
          <label>Phone Number <span class="validation-star">*</span></label>
          <input pInputText maxlength="18" [readOnly]="flag" formControlName="phoneNo" type="text">
          <f-error [err]="radiologist.required('phoneNo')" msg="Phone Number is required"></f-error>
          <f-error [err]="radiologist.pattern('phoneNo')" msg="Only numerical values are accepted"></f-error>
          <f-error [err]="radiologist.minlength('phoneNo')" msg="Enter a minimum of 7 digits"></f-error>
        </div>
        <div class="field col-12 md:col-6  lg:col-4">
          <label>Date of Birth <span class="validation-star">*</span></label>
          <input pInputText maxlength="18" [readOnly]="true" *ngIf="flag" formControlName="dob" type="text">
          <p-calendar [showIcon]="true" *ngIf="!flag" [defaultDate]="maxDate" [maxDate]="maxDate" formControlName="dob"
            dateFormat="dd-mm-yy" dataType="string" disabled="true" placeholder="DD-MM-YYYY" class="widthClass"
            [showIcon]="true"></p-calendar>
          <f-error [err]="radiologist.required('dob')" msg="Date of Birth is required"></f-error>
        </div>
        <div class="field col-12 md:col-6 lg:col-4">
          <label>Qualification <span class="validation-star">*</span></label>
          <input pInputText maxlength="100" [readOnly]="flag" formControlName="qualifications" type="email">
          <f-error [err]="radiologist.required('qualifications')" msg="Qualification is required"></f-error>
        </div>
        <div class="field col-12 md:col-6  lg:col-4">
          <label>Register No <span class="validation-star">*</span></label>
          <input pInputText maxlength="50" [readOnly]="flag" formControlName="registrationNo" type="text">
          <f-error [err]="radiologist.required('registrationNo')" msg="Register No is required"></f-error>
        </div>
        <div class="field col-12 md:col-6  lg:col-4">
          <label>Designation </label>
          <input pInputText maxlength="50" [readOnly]="flag" formControlName="designation" type="text">
        </div>
        <div class="field col-12 md:col-6 lg:col-4">
          <label>Experience after basic radiology training </label>
          <input pInputText maxlength="495" [readOnly]="flag" formControlName="experience" type="text">
        </div>
        <div class="field col-12 md:col-6 lg:col-4">
          <label>Additional Qualification</label>
          <input pInputText maxlength="240" [readOnly]="flag" formControlName="additionalQualifications" type="text">
        </div>
        <div class="field col-12 md:col-6 lg:col-4">
          <label>Additional Training </label>
          <input pInputText maxlength="254" [readOnly]="flag" formControlName="additionalTraining" type="text">
        </div>
        <div class="field col-12 md:col-6 lg:col-4">
          <label>Country <span class="validation-star">*</span></label>
          <p-autoComplete formControlName="countryId" maxlength="60" [suggestions]="countryLookup"
            (onSelect)="stateAreaOnChange($event)" (completeMethod)="filterCountry($event)" field="name"
            ariaLabel="Country" [dropdown]="true" *ngIf="!flag" [autoHighlight]="true" forceSelection="true"
            showEmptyMessage="true" delay="400">
          </p-autoComplete>
          <input pInputText maxlength="495" [readOnly]="flag" *ngIf="flag"
            value="{{radiologist?.get('countryId').name}}" type="text">
          <f-error [err]="radiologist.required('countryId')" msg="Country is required"></f-error>
        </div>
        <div class="field col-12 md:col-6 lg:col-4">
          <label>State </label>
          <p-autoComplete formControlName="stateId" *ngIf="!flag" [suggestions]="stateLookup" F
            (completeMethod)="filterState($event)" maxlength="60" forceSelection="true" field="name" ariaLabel="State"
            [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400">
          </p-autoComplete>
          <input pInputText maxlength="495" [readOnly]="flag" *ngIf="flag"
            value="{{radiologist?.get('countryId').name}}" type="text">
        </div>
        <div class="field col-12 md:col-6  lg:col-4">
          <label>Address <span class="validation-star">*</span></label><textarea
            class="p-inputtextarea p-inputtext p-component p-element" [readOnly]="flag" [rows]="3" [cols]="40"
            maxlength="480" formControlName="address" pInputTextarea></textarea>
          <f-error [err]="radiologist.required('address')" msg="Address is required"></f-error>
        </div>
        <div class="field col-12 md:col-6 lg:col-4">
          <label>Zip Code <span class="validation-star">*</span> </label>
          <input pInputText maxlength="12" [readOnly]="flag" formControlName="zip" type="text">
          <f-error [err]="radiologist.required('zip')" msg="Zip Code is required"></f-error>
          <f-error [err]="radiologist.pattern('zip')" msg=" Only alphanumeric characters are accepted"></f-error>
        </div>
        <div *ngIf="sysService.isAdmin() && radiologist.get('status') !== 2" class="field col-12 md:col-6 lg:col-4">
          <label>Status<span class="validation-star">*</span></label>
          <p-dropdown formControlName="status" inputId="status" placeholder="Select" [options]="staticService.status"
            required="true">
          </p-dropdown>
          <f-error [err]="radiologist.required('status')" msg="Status is required"></f-error>
        </div>
        <div *ngIf="sysService.isAdmin()" class="field col-12 md:col-6 lg:col-4 mt-5">
          <div class="flex align-items-center gap-1">
            <p-checkbox [binary]="true" inputId="binary" formControlName="teleradiologyCoordinator">
            </p-checkbox> Teleradiology Company
            &nbsp;
          </div>
          <label *ngIf=" radiologist.get('teleradiologyCoordinator')===true" for="ny">
            Will not be in charge of report generation</label>
        </div>
        <div class="field col-12 md:col-6 lg:col-4 mt-3"
          *ngIf="this.radiologist.isEdit() && hasSignature || sysService.isAdmin() ">

          <div class="grid sig-pos" *ngIf="this.radiologist.isEdit()">
            <div class="col-6">
              <label> {{ sysService.isAdmin() ? 'Radiologist Upload Signature' : 'Radiologist Signature'
                }}</label><br><br>
              <input type="file" *ngIf="sysService.isAdmin()" id="document" formControlName="docFile1"
                (change)="selectFile($event.target.files, 'signature')" accept=".jpg,.jpeg,.png" />

            </div>
            <div class="col-6 lg:col-4 text-center base64img">
              <div class="image-container">
                <span class="close-icon " *ngIf="hasSignature && sysService.isAdmin()" (click)="closesig(radiologist)"
                  [style.display]="buttonVisible1 ? 'block' : 'none'">&#10006;</span>
                <p-image [src]="!hasSignature ? imageurl : signature"></p-image>
                <div class="pt-2" *ngIf="buttonVisible1">{{ getFilename(radiologist?.view?.signature)}}</div>

              </div>
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-6 lg:col-4 mt-3"
          *ngIf="this.radiologist.isEdit() && hasStamp || sysService.isAdmin()">
          <div class="grid sig-pos" *ngIf="this.radiologist.isEdit()">
            <div class="col-3 lg:col-4">
              <label>
                {{ sysService.isAdmin() ? 'Radiologist Upload Stamp' : 'Radiologist Stamp' }}
              </label><br><br>
              <input type="file" id="document" *ngIf="sysService.isAdmin()" formControlName="docFile2"
                (change)="selectFile1($event.target.files,'stamp')" accept=".jpg,.jpeg,.png" />

            </div>
            <div class="col-6  text-center base64img">
              <span class="close-icon" *ngIf="hasStamp && sysService.isAdmin()" (click)="closestam(radiologist)"
                [style.display]="buttonVisible ? 'block' : 'none'">&#10006;</span>
              <p-image [src]="!hasStamp ? imageurl : stamp"></p-image>
              <div class="pt-2" *ngIf="buttonVisible">{{ getFilename(radiologist?.view?.stamp) }}</div>

            </div>
          </div>
        </div>

        <div *ngIf="!sysService.isAdmin() " class="field col-12 md:col-6 lg:col-4">
          <label>Radiologist Type :</label>
          <p-dropdown formControlName="teleradiologyCoordinator" inputId="status" readonly="true" placeholder="Select"
            [options]="staticService.teleradiologyCoordinator" required="true">
          </p-dropdown>
        </div>
        <!-- <div class="lg:col-4">
          <p>{{radiologist.form.value | json}}</p>
        </div> -->
      </div>
      <div class="grid justify-content-center p-5">
        <button pButton pRipple type="button" label="Back" icon=" pi pi-arrow-left"
          (click)="listView(radiologist.isEmptyList())" class="p-button-outlined p-button-info"></button>&nbsp;
        <button pButton pRipple type="button" [label]="saveButtonLabel" *ngIf="sysService.isAdmin()"
          (click)="saveradiologist(radiologist)" icon=" pi pi-check" class="p-button-raised p-button-success "
          [disabled]="sysService.loading"> <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i></button>
      </div>
    </div>
  </form>

  <p-dialog header="Associate User" [(visible)]="associateUserList" (onHide)="rejectBack()" [style]="{width: '60vw'}"
    [breakpoints]="{ '991px': '75vw', '575px': '95vw' }">
    <div class="table-responsive">
      <!-- <p-table #dt [value]="associateUsers" [totalRecords]="associateUsers?.length" [lazy]="true" dataKey="id"
        [rowsPerPageOptions]="[10, 25, 50]" [loading]="sysService.loading" filterDelay="400"
        currentPageReportTemplate="{first} to {last} of {totalRecords}" [rowHover]="true" styleClass="p-datatable-sm"
        [rows]="sysService.rows" [paginator]="true" [showCurrentPageReport]="true" alwaysShow="false"
        paginatorPosition="bottom" styleClass="p-datatable-sm p-datatable-gridlines"
        [globalFilterFields]="['name', 'rate', 'description']"> -->

      <p-table #dt [value]="associateUsers" [scrollable]="true" scrollHeight="65vh" dataKey="id"
        class="study-list-class" [scrollHeight]="heightTable" [rows]="sysService.rows" [showCurrentPageReport]="true"
        styleClass="p-datatable-sm p-datatable-gridlines" paginatorPosition="bottom" [loading]="sysService.loading"
        [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['name', 'rate', 'description']">
        <ng-template pTemplate="paginatorleft">

        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th id="name" scope="col">Name</th>
            <th id="email" scope="col">Email</th>
            <th id="contactNo" scope="col">Contact No</th>
            <th id="userRole" scope="col">User Role</th>
            <th id="status" scope="col">Status</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-studyDetails>
          <tr>
            <td>{{ studyDetails.name }}</td>
            <td>{{ studyDetails.email }}</td>
            <td>{{ studyDetails.contactNo }}</td>
            <td>{{ studyDetails.userRole }}</td>
            <td> <span class="text-success" *ngIf="studyDetails.status ===3 ">Accept </span>
              <span class="text-success" *ngIf="studyDetails.status ===2 ">Active </span>
              <span class="text-success" *ngIf="studyDetails.status ===1 ">Pending </span>
              <span class="text-danger" *ngIf=" studyDetails.status ===0"> Blocked</span>
            </td>

          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td class="norecordstudy" [attr.colspan]="7">No records found</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-dialog>

  <p-dialog header=" Wallet Update " [dismissableMask]="true" [(visible)]="transactionamount" [modal]="true"
    (onHide)="rejectBack()" [style]="{ width: '300px' }" [draggable]="false" [resizable]="false">
    <form [formGroup]="transactionAmountdetails.form">
      <div class="grid">
        <div class="col-12 ">
          <p-dropdown class="rate" inputId="status" (onChange)="bonusTypeAmount($event)" placeholder="Bonus Type "
            formControlName="bonusType" [options]="staticService.bonusType">
          </p-dropdown>
          <f-error [err]="transactionAmountdetails.required('bonusType')" msg="Bonus Type is required"></f-error>
        </div>
        <div class="col-12 ">
          <p-inputNumber class="inputwidth" formControlName="amountPaid" placeholder="Amount"
            [readonly]="transactionAmountdetails.get('bonusType') === 1"
            pTooltip="{{ transactionAmountdetails.get('bonusType') === 1 ? '' : 'The maximum allowable amount for the update is thirty thousand.' }}"
            inputId="minmax" mode="decimal" [min]="1" [max]="30000">
          </p-inputNumber>
          <f-error [err]="transactionAmountdetails.required('amountPaid')" msg="Amount is required"></f-error>
          <f-error [err]="transactionAmountdetails.pattern('amountPaid')" msg="Only numerical values are accepted">
          </f-error>
        </div>
        <div class="col-12 ">
          <textarea rows="4" cols="30" formControlName="comments"
            class="p-inputtextarea p-inputtext p-component p-element" pInputTextarea placeholder="Comments"></textarea>
          <f-error [err]="transactionAmountdetails.required('comments')" msg="comments is required"></f-error>

        </div>
      </div>
      <div class="text-center mt-3">
        <button pButton pRipple type="button" label="Back" icon=" pi pi-arrow-left"
          (click)="cancelTransactionAmount();dt.reset()" class="p-button-outlined p-button-info"></button>&nbsp;
        <button pButton pRipple type="button" label="Save" icon=" pi pi-check" (click)="walletUpdate()"
          class="p-button-outlined p-button-success"></button>&nbsp;
      </div>
    </form>
  </p-dialog>
</div>