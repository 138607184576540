<div *ngIf="!sysService.isPermission()">
   <not-found></not-found>
</div>
<div *ngIf="sysService.isPermission()">
   <p-toolbar>
      <div class="p-toolbar-group-start">
         <f-nav first="/short-code" label="Short Code" [model]="shortCode"> </f-nav>
      </div>
      <div class="p-toolbar-group-end">
         <button pButton pRipple type="button" label="Delete" icon="pi pi-trash" class="p-button-outlined
      p-button-outlined p-button-danger" (click)="deleteshortCode(shortCode.id)" *ngIf="shortCode.isEdit()"></button>
      </div>
   </p-toolbar>
   <!-- ----------------------------------Add form -------------------- -->
   <form [formGroup]="shortCode.form">
      <div *ngIf="shortCode.isform || shortCode.isView()" class="card">
         <div class="grid justify-content-center">
            <div class="field col-12 md:col-6 lg:col-6">
               <label>Short Code <span class="validation-star">*</span></label>
               <input type="text" maxlength="25" formControlName="code" aria-describedby="username1-help" pInputText
                  required pAutoFocus [autofocus]="true">
               <f-error [err]="shortCode.required('code')" msg="Short Code is required"></f-error>
               <f-error [err]="shortCode.pattern('code')" msg=" white space not allowed on begining"></f-error>

            </div>
            <div class="field col-12 md:col-6 lg:col-6">
               <label> Code Description <span class="validation-star">*</span></label>

               <textarea [rows]="3" [cols]="39" maxlength="480"
                  class="p-inputtextarea p-inputtext p-component p-element" formControlName="codeDescription"
                  pInputTextarea></textarea>
               <f-error [err]="shortCode.required('codeDescription')" msg="Code Description is required"></f-error>

            </div>

            <div class="formgrid text-center bc">
               <button pButton pRipple type="button" label="Cancel" icon="pi pi-times"
                  class="p-button-outlined p-button-info" (click)="listView(shortCode.isEmptyList());"></button>
               <button pButton pRipple type="button" [label]="saveButtonLabel " icon="pi pi-check"
                  class="p-button-raised p-button-success" (click)="saveshortCode()" [disabled]="sysService.loading"
                  *ngIf="shortCode.isForm()">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
               </button>
               <button pButton pRipple type="button" label=" Save & Add" icon="pi pi-plus"
                  class="p-button-raised p-button-info" (click)="saveshortCode(true)" [disabled]="sysService.loading"
                  *ngIf="shortCode.isNew()">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
               </button>
            </div>
         </div>
      </div>
   </form>
   <!---------------------------------------------- table list --------------------------------------------- -->
   <div class="table-responsive" [style.display]="shortCode.islist? 'block':'none'">


      <p-table #dt [value]="shortCode.list" [totalRecords]="shortCode.total" [lazy]="true"
         (onLazyLoad)="listshortCodeLazy($event)" dataKey="id" [(selection)]="shortCode.selection"
         selectionMode="multiple" [loading]="sysService.loading" filterDelay="400" class="study-list-class"
         [scrollHeight]="heightTable" currentPageReportTemplate="{first} to {last} of {totalRecords}"
         [rowsPerPageOptions]="[ 25, 50]" [rowHover]="true" styleClass="p-datatable-sm" [rows]="sysService.rows"
         [paginator]="true" [showCurrentPageReport]="true" alwaysShow="false" paginatorPosition="bottom"
         styleClass="p-datatable-sm p-datatable-gridlines" [globalFilterFields]="['code','codeDescription']">

         <ng-template pTemplate="caption">
            <div class="grid">
               <div class="ml-2">
                  <span class="p-input-icon-left">
                     <i class="pi pi-search"></i>
                     <input pInputText type="text" maxlength="150" [(ngModel)]="shortCode.searchTxt" pAutoFocus
                        [autofocus]="true" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Search..." />
                  </span>
               </div>
               <div class=" mt-2 ml-2">
                  <span>
                     <button pButton pRipple type="button" label="Add Short Code" icon="pi pi-plus"
                        class="p-button-raised" *ngIf="shortCode.isList()" (click)="add()"></button>
                  </span>
               </div>
               <div class="mt-2">
                  <button pButton pRipple type="button" label="Delete" icon="pi pi-trash"
                     class="p-button-outlined p-button-danger" (click)="deleteshortCodes()"
                     *ngIf="!shortCode.isEmptyList() && shortCode.selection.length > 0"></button>
               </div>
            </div>
         </ng-template>
         <ng-template pTemplate="paginatorleft">

         </ng-template>
         <ng-template pTemplate="header">
            <tr>
               <th style="width: 3rem">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
               </th>

               <th pSortableColumn="code">
                  Short Code
                  <p-sortIcon field="code"></p-sortIcon>
               </th>
               <th pSortableColumn="codeDescription">
                  Code Description
                  <p-sortIcon field="codeDescription"></p-sortIcon>
               </th>

            </tr>
         </ng-template>
         <ng-template pTemplate="body" let-shortCode>
            <tr [ngClass]="{'ui-state-highlight': chk.checked}">
               <td>
                  <p-tableCheckbox #chk [value]="shortCode"></p-tableCheckbox>
               </td>

               <td><i class="pi pi-file-edit link-study" (click)="edit(shortCode.id)" title="edit"></i>
                  &nbsp;{{shortCode.code}}
               </td>
               <td class="bdy-part">{{shortCode.codeDescription}}</td>

            </tr>
         </ng-template>
         <ng-template pTemplate="emptymessage" let-columns>
            <tr>
               <td class="norecordstudy" [attr.colspan]="7">No records found</td>
            </tr>
         </ng-template>
      </p-table>
   </div>
</div>