import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/_common';
import { CheckoutSandboxComponent } from './checkout-sandbox.component';
import { CheckoutComponent } from './checkout.component';


const routes: Routes = [
  {
    path: 'sandbox-checkout',
    children: [
      { path: '', component: CheckoutSandboxComponent , canActivate: [AuthGuard] },
    ]
  },  
  {
    path: 'checkout',
    children: [
      { path: '', component: CheckoutComponent , canActivate: [AuthGuard] },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)
  ]
})
export class PaymentRoutingModule { }