import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormDefault, Model, StaticService, SysService, View, ViewFactory } from '@app/_common';

@Component({
  selector: 'payment-cancel',
  templateUrl: './payment-cancel.component.html',
  styleUrls: ['./payment-cancel.component.css']
})

export class PaymentCancelComponent {
  private PaymentCancelView: View = this.viewFactory.newView();
  paymentStatus: boolean
  message = '';

  transactionId: any;
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private router: Router,

    private formBuilder: FormBuilder,
    public route: ActivatedRoute,

    private viewFactory: ViewFactory) {
  }

  ngOnInit() {
    this.PaymentCancelView.of(this.PaymentCancelModel(), false);
    this.route.queryParams.subscribe((params) => {
      this.transactionId = params.transactionId;
      this.PaymentCancelView.getData(`/payment/cancel?transactionId=${this.transactionId}`)
        .then((response) => {
          this.paymentStatus = true;
          this.sysService.loadWalletBalance()
          this.message = response?.message;


        })
        .catch((error) => {
        });
    });

  }

  get ratecardList() {
    return this.PaymentCancelView.model;
  }

  private PaymentCancelModel() {
    const formDefault = new FormDefault(['status'], [1]);
    return new Model(this.formBuilder.group({

    }), this.sysService, formDefault, '');
  }

  add() {
    this.PaymentCancelView.toNew();

  }

  redirectPayment() {
    if (this.sysService.isCenter()) {
      this.router.navigateByUrl('/billing-dashboard?activeIndex=4')
    } else {
      this.router.navigateByUrl('/billing-dashboard?activeIndex=5')
    }
  }
  backToRecharge() {
    this.router.navigateByUrl('/billing-dashboard?activeIndex=0')
  }
}