import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { ToolbarModule } from 'primeng/toolbar';
import { DropdownModule } from 'primeng/dropdown';
import { ReportComponent } from './report.component';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { SharedModule } from '@app/_common';
import { FileUploadModule } from 'primeng/fileupload';
import { PasswordModule } from "primeng/password";
import { CardModule } from 'primeng/card';
import { ImageModule } from 'primeng/image';
import { AvatarModule } from "primeng/avatar";
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ChipModule } from 'primeng/chip';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ReportRoutingModule } from './report-routing.module';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CKEditorModule } from 'ckeditor4-angular';
import { EditorModule } from 'primeng/editor';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SpeedDialModule } from 'primeng/speeddial';
@NgModule({
  declarations: [
    ReportComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    InputTextModule,
    DialogModule,
    ToolbarModule,
    ConfirmDialogModule,
    RatingModule,
    InputNumberModule,
    InputTextareaModule,
    RadioButtonModule,
    ButtonModule,
    DropdownModule,
    ReportRoutingModule,
    SharedModule,
    CheckboxModule,
    TabViewModule,
    FileUploadModule,
    PasswordModule,
    CardModule,
    PanelModule ,
    ImageModule,
    AvatarModule,
    MessagesModule,
    MessageModule,
    ChipModule,
    ToggleButtonModule,
    AutoCompleteModule,
    CKEditorModule,
    EditorModule,
    OverlayPanelModule,
    SplitButtonModule,
    SpeedDialModule
  ],
  exports: [ReportComponent],
  providers: []
})
export class ReportModule { }