import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/_common';
import { ReportComponent } from './report.component';


const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', component: ReportComponent, canActivate: [AuthGuard] },
      // { path: 'new', component: ReportComponent, canActivate: [AuthGuard] },
      // { path: 'view/:id', component: ReportComponent, canActivate: [AuthGuard] }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)
  ]
})
export class ReportRoutingModule { }