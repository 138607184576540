<div *ngIf="!sysService.isPermission()">
  <not-found></not-found>
</div>
<div *ngIf="sysService.isPermission()">
  <form [formGroup]="reportTemplate.form">
    <div *ngIf="reportTemplate.isform || reportTemplate.isView()" class="card">
      <div class="grid">
        <div class="field col-12 md:col-12 lg:col-6">
          <label>Modality <span class="validation-star">*</span></label>
          <input id="username" type="text" *ngIf="!reportTemplate?.view?.editable && !reportTemplate.isNew()"
            readonly="true" maxlength="25" value="{{reportTemplate?.get('modalityId')?.code}}"
            aria-describedby="username1-help" pInputText required pAutoFocus [autofocus]="true">
          <p-autoComplete forceSelection="true" *ngIf="reportTemplate?.view?.editable || reportTemplate.isNew()"
            formControlName="modalityId" [suggestions]="modalityLookup" (completeMethod)="filterModality($event)"
            field="code" ariaLabel="Modality" [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true"
            delay="400" maxlength="7">
          </p-autoComplete>
          <f-error [err]="reportTemplate.required('modalityId')" msg="Modality  is required"></f-error>
        </div>
        <div class="field col-12 md:col-6 lg:col-6">
          <label>Body Part <span class="validation-star">*</span> </label>
          <input id="username" type="text" *ngIf="!reportTemplate?.view?.editable && !reportTemplate.isNew()"
            readonly="true" maxlength="25" value="{{reportTemplate?.get('bodyPartId')?.name}}"
            aria-describedby="username1-help" pInputText required>
          <p-autoComplete forceSelection="true" *ngIf="reportTemplate?.view?.editable || reportTemplate.isNew()"
            formControlName="bodyPartId" [suggestions]="bodyPartLookup" (completeMethod)="filterbodyPart($event)"
            field="name" ariaLabel="bodyPart" [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true"
            delay="400" maxlength="50">
          </p-autoComplete>
          <f-error [err]="reportTemplate.required('bodyPartId')" msg="Body Part is required"></f-error>
        </div>
        <div class="field col-12 md:col-6 lg:col-6">
          <label>Template Name <span class="validation-star">*</span></label>
          <input pInputText formControlName="name"
            [readonly]="!reportTemplate?.view?.editable  && !reportTemplate.isNew() " maxlength="100" type="text">
          <f-error [err]="reportTemplate.required('name')" msg="Template Name is required"></f-error>
        </div>
        <div class="field col-12 md:col-6 lg:col-6" *ngIf="!sysService.isAdmin()">
          <label>Template Sharing Scope <span class="validation-star">*</span></label>
          <p-dropdown formControlName="shareable"
            [readonly]="!reportTemplate?.view?.editable && !reportTemplate.isNew()" inputId="shareable"
            placeholder="Select" [options]="staticService.sharingScope" required="true"></p-dropdown>
          <f-error [err]="reportTemplate.required('shareable')" msg="Template Sharing Scope is required"></f-error>
        </div>
        <div class="field col-12 md:col-6 lg:col-6"
          *ngIf="sysService.isAdmin() && reportTemplate.get('userName')!==null">
          <label>Created By </label>
          <input pInputText formControlName="userName" [readonly]="true " maxlength="100" type="text">
        </div>
        <div class="field col-12 md:col-6 lg:col-6">
          <label>Status </label>
          <p-dropdown formControlName="status" [readonly]="!reportTemplate?.view?.editable && !reportTemplate.isNew()"
            inputId="status" placeholder="Select" [options]="staticService.status" required="true"></p-dropdown>
        </div>
        <div class="field col-12 md:col-12 lg:col-12">
          <div class="grid">
            <div class="col-fixed" style="width:180px"> <button pButton pRipple type="button" label=" Preview"
                iconpos="right" icon="pi pi-print" class="p-button-outlined p-button-help" (click)="printReport(editor)"
                [disabled]="sysService.loading || reportTemplate.get('report')==null">
                <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
              </button>
            </div>
            <div class="text-center col-12 md:col" *ngIf="sysService.getUser().userSettings.isTemplateEditable">
              <button pButton pRipple type="button" label="Close" icon="pi pi-times"
                class="p-button-outlined p-button-info m-2" (click)="listView(reportTemplate.isEmptyList());"></button>
              <button pButton pRipple type="button" [label]="saveButtonLabel" icon="pi pi-check"
                class="p-button-raised p-button-success m-2" (click)="savereportTemplate()"
                [disabled]="sysService.loading "
                *ngIf="reportTemplate.isForm() && reportTemplate?.view?.editable || reportTemplate.isNew() ">
                <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
              </button>
              <button pButton pRipple type="button" label=" Clone" iconpos="right" icon="pi pi-clone"
                *ngIf="reportTemplate.isEdit()" class="p-button-outlined p-button-help m-2" (click)="cloneTemplate()"
                [disabled]="sysService.loading || reportTemplate.get('id')==null">
                <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
              </button>
              <button pButton pRipple type="button" label=" Save & Add" icon="pi pi-plus"
                class="p-button-raised p-button-info m-2" (click)="savereportTemplate(true)"
                [disabled]="sysService.loading" *ngIf="reportTemplate.isNew() ">
                <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
              </button>
            </div>
          </div>
          <label>Template Content <span class="validation-star">*</span>
            <p-button icon="pi pi-question-circle" title="Keyboard Shortcuts" (click)="keyboardShortcuts()"
              styleClass="p-button-rounded p-button-info p-button-text m-2"></p-button>
          </label>
          <f-error [err]="reportTemplate.required('report')" msg="Template Content  is required"></f-error>
          <ckeditor id="report" (ready)="onNamespaceLoaded($event)" debounce="500"
            placeholder="Enter your Template Content" formControlName="report" #editor [config]="configuration"
            [(data)]="editorData" [disabled]="true"></ckeditor>
          <f-error [err]="reportTemplate.required('report')" msg="Template Content  is required"></f-error>
        </div>
      </div>
      <div class="field col-12 md:col-12 lg:col-12">
        <div class="grid">
          <div class="col-fixed" style="width:180px"> <button pButton pRipple type="button" label="Preview"
              iconpos="right" icon="pi pi-print" class="p-button-outlined p-button-help" (click)="printReport(editor)"
              [disabled]="sysService.loading || reportTemplate.get('report')==null">
              <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
            </button>
          </div>
          <div class="text-center col-12 md:col" *ngIf="sysService.getUser().userSettings.isTemplateEditable">
            <button pButton pRipple type="button" label="Close" icon="pi pi-times"
              class="p-button-outlined p-button-info m-2" (click)="listView(true);"></button>
            <button pButton pRipple type="button" [label]="saveButtonLabel" icon="pi pi-check"
              class="p-button-raised p-button-success m-2" (click)="savereportTemplate()"
              [disabled]="sysService.loading "
              *ngIf="reportTemplate.isForm() && reportTemplate?.view?.editable || reportTemplate.isNew() ">
              <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
            </button>
            <button pButton pRipple type="button" label=" Clone" iconpos="right" icon="pi pi-clone"
              *ngIf="reportTemplate.isEdit()" class="p-button-outlined p-button-help m-2" (click)="cloneTemplate()"
              [disabled]="sysService.loading || reportTemplate.get('id')==null">
              <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
            </button>
            <button pButton pRipple type="button" label=" Save & Add" icon="pi pi-plus"
              class="p-button-raised p-button-info m-2" (click)="savereportTemplate(true)"
              [disabled]="sysService.loading" *ngIf="reportTemplate.isNew()">
              <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="card grayCard" *ngIf="reportTemplate.isList()">
    <form [formGroup]="reportTemplateListSearch.form" class="fullform mt-3">
      <div class="grid">
        <div class=" field col-12 md:col-3 lg:col-2 m-2">
          <span class="p-float-label">
            <p-autoComplete formControlName="Modality" forceSelection="true" [suggestions]="modalitySearchLookup"
              (completeMethod)="filterModalitySearch($event)" field="code" ariaLabel="Modality" [dropdown]="true"
              forceSelection="true" [autoHighlight]="true" showEmptyMessage="true" delay="400" required maxlength="7">
            </p-autoComplete>
            <label for="float-input">Modality</label>
          </span>
        </div>
        <div class=" field col-12 md:col-3 lg:col-2 m-2">
          <span class="p-float-label">
            <p-dropdown formControlName="templateType" inputId="templateType" placeholder="Select"
              [options]="staticService.templateType" required="true"></p-dropdown>
            <label for="float-input">Template Type</label>
          </span>
        </div>
        <div class=" field col-12 md:col-3 lg:col-2 m-2">
          <span class="p-float-label">
            <p-autoComplete forceSelection="true" formControlName="BodyPart" field="name"
              [suggestions]="bodyPartSearchLookup" (completeMethod)="filterBodyPart($event)" ariaLabel="BodyPart"
              [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400" maxlength="50">
            </p-autoComplete>
            <label for="float-input">Body Part</label>
          </span>
        </div>
        <div class=" field col-12 md:col-3 lg:col-2 m-2">
          <span class="p-float-label">
            <p-dropdown formControlName="status" inputId="status" placeholder="Select" [options]="staticService.status"
              required="true"></p-dropdown>
            <label for="float-input">Status</label>
          </span>
        </div>
        <div class=" field col-12 md:col-3 lg:col-2 m-2">
          <span class="p-float-label">
            <p-dropdown formControlName="sharingScope" inputId="sharingScope" placeholder="Select"
              [options]="staticService.sharingScope" required="true"></p-dropdown>
            <label for="float-input">Template Sharing Scope </label>
          </span>
        </div>
        <div *ngIf="sysService.isAdmin()" class=" field col-12 md:col-3 lg:col-2 m-2">
          <span class="p-float-label">
            <p-autoComplete forceSelection="true" formControlName="userName" field="name"
              [suggestions]="createdBySearchLookup" (completeMethod)="filterCreatedBy($event)" ariaLabel="BodyPart"
              [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400" maxlength="50">
            </p-autoComplete>
            <label for="float-input">Created By </label>
          </span>
        </div>
        <div class="field mt-4">
          <button pButton pRipple type="button" (click)="searchtemplateName();dt.reset()" label=" Search"
            class="p-button-raised p-button-info" [disabled]="sysService.loading">
            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
          </button>
          <button pButton pRipple type="button" (click)="resetTemplateList();dt.reset()" label=" Reset"
            class="p-button-raised p-button-text" [disabled]="sysService.loading">
            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
  <div [style.display]="reportTemplate.islist ? 'block':'none'" class="table-responsive">



    <p-table #dt [value]="reportTemplate.list" [totalRecords]="reportTemplate.total" [lazy]="true"
      (onLazyLoad)="listreportTemplateLazy($event)" dataKey="id" [(selection)]="reportTemplate.selection"
      selectionMode="multiple" [loading]="sysService.loading" filterDelay="400" class="study-list-class"
      [scrollHeight]="heightTable" currentPageReportTemplate="{first} to {last} of {totalRecords}"
      [rowsPerPageOptions]="[ 25, 50]" [rowHover]="true" styleClass="p-datatable-sm" [rows]="sysService.rows"
      [paginator]="true" [showCurrentPageReport]="true" alwaysShow="false" paginatorPosition="bottom"
      styleClass="p-datatable-sm p-datatable-gridlines"
      [globalFilterFields]="['templateName','modalityId.code','bodyPartId']">


      <ng-template pTemplate="caption">
        <div class="grid">
          <div class="ml-2">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" maxlength="150" [(ngModel)]="reportTemplate.searchTxt"
                (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." pAutoFocus
                [autofocus]="true" />
            </span>
          </div>
          <div class="mt-2 ml-2">
            <span *ngIf="sysService.getUser().userSettings.isTemplateEditable">
              <button pButton pRipple type="button" label="Add Report Template" icon="pi pi-plus"
                class="p-button-raised" *ngIf="reportTemplate.isList()" (click)="add()"></button>
            </span>
          </div>
          <div class="mt-2">
            <button pButton pRipple type="button" label="Delete" icon="pi pi-trash"
              class="p-button-outlined p-button-danger" (click)="deletereportTemplates()"
              *ngIf="!reportTemplate.isEmptyList() && reportTemplate.selection.length > 0"></button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="paginatorleft">
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 3rem">
            <p-tableHeaderCheckbox *ngIf="sysService.isAdmin()"></p-tableHeaderCheckbox>
          </th>
          <th pSortableColumn="u.name" style="min-width:117px;">Template Name <p-sortIcon field="u.name"></p-sortIcon>
          </th>
          <th pSortableColumn="m.code">Modality<p-sortIcon field="m.code"></p-sortIcon>
          </th>
          <th pSortableColumn="b.bodypart  ">Body Part <p-sortIcon field="b.bodypart  ">
            </p-sortIcon>
          </th>
          <th *ngIf="sysService.isAdmin()" pSortableColumn="u.email">Created By <p-sortIcon
              field="u.email"></p-sortIcon>
          </th>
          <th pSortableColumn="editable">Template Type <p-sortIcon field="editable">
            </p-sortIcon>
          </th>
          <th pSortableColumn="o.shareable">Template Sharing Scope<p-sortIcon field="o.shareable">
            </p-sortIcon>
          </th>
          <th pSortableColumn="status">Status <p-sortIcon field="status">
            </p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-reportTemplate>
        <tr [ngClass]="{'ui-state-highlight': chk.checked}">
          <td>
            <p-tableCheckbox #chk [value]="reportTemplate"
              [class.hidden]="!sysService.isAdmin() && sysService.isRad() && reportTemplate.editable === 1">
            </p-tableCheckbox>
          </td>
          <td><i class="pi pi-file-edit link-study" (click)="edit(reportTemplate.id)" title="edit"> </i> &nbsp;
            {{reportTemplate.name}}

          </td>
          <td> {{reportTemplate?.code}} </td>
          <td> {{reportTemplate?.bodyPart}}</td>
          <td *ngIf="sysService.isAdmin()" class="bdy-part"> {{reportTemplate?.userName}} </td>
          <td *ngIf="reportTemplate.editable ===0 ||reportTemplate.editable ===null" class="text-accent">Radiologist
            Template</td>
          <td *ngIf="reportTemplate.editable ===1" class="text-danger">eCScribe Template</td>
          <td *ngIf="reportTemplate.shareable ===0 || reportTemplate.shareable ===null" class="text-danger">
            Non-Shareable
          </td>
          <td *ngIf="reportTemplate.shareable ===1" class="text-success">Shareable</td>
          <td *ngIf="reportTemplate.status ===0 ||reportTemplate.status ===null" class="text-danger">Inactive </td>
          <td *ngIf="reportTemplate.status ===1" class="text-success">Active</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td class="norecord" [attr.colspan]="8">No records found</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <p-dialog header="Keyboard Shortcuts" [(visible)]="visible">
    <h3 id="navigation"><a class="headerlink" href="#navigation">#</a> Navigation</h3>
    <ul>
      <li><button>Home</button> – Jumps to the beginning of the line.</li>
      <li><button>Ctrl+Home</button> – Jumps to the beginning of the document.</li>
      <li><button>End</button> – Jumps to the end of the line.</li>
      <li><button>Ctrl+End</button> – Jumps to the end of the document.</li>
      <li><button>PgDn</button> – Scrolls down the document, approximately by the length of the editing area.</li>
      <li><button>PgUp</button> – Scrolls up the document, approximately by the length of the editing area.</li>
    </ul>
    <h3 id="writing"><a class="headerlink" href="#writing">#</a> Writing</h3>
    <ul>
      <li><button>Enter</button> (<button>Return</button>) – Ends a paragraph and starts a new one.</li>
      <li><button>Shift+Enter</button> – Adds a line break.</li>
      <li><button>ctrl + Right click </button> – Spell checker.</li>
      <!-- <li><button>Shift+Ctrl+3</button> – Enables entering content (by adding a new paragraph) before a problematic element such as an image, table or <code>&lt;div&gt;</code> element that starts or ends a document, list, or even adjacent horizontal lines.</li> -->
      <li><button>Shift+Ctrl+4</button> – Enables entering content (by adding a new paragraph) after a problematic
        element
        such as an image, table or <code>&lt;div&gt;</code> element that starts or ends a document, list, or even
        adjacent
        horizontal lines.</li>
      <li><button>Backspace</button>, <button>Del</button> – Deletes a character.</li>
      <li><button>Ctrl+Backspace</button>, <button>Ctrl+Del</button> – Deletes a word.</li>
    </ul>
    <h3 id="undo-and-redo"><a class="headerlink" href="#undo-and-redo">#</a> Undo and Redo</h3>
    <ul>
      <li><button>Ctrl+Z</button> – Performs the undo operation.</li>
      <li><button>Ctrl+Y</button>, <button>Shift+Ctrl+Z</button> – Performs the redo operation.</li>
    </ul>
    <h3 id="cut-copy-and-paste"><a class="headerlink" href="#cut-copy-and-paste">#</a> Cut, Copy and Paste</h3>
    <ul>
      <li><button>Ctrl+X</button>, <button>Shift+Del</button> – Cuts a text fragment to clipboard.</li>
      <li><button>Ctrl+C</button> – Copies a text fragment to clipboard.</li>
      <li><button>Ctrl+V</button>, <button>Shift+Insert</button> – Pastes a text fragment from clipboard.</li>
      <li><button>Shift+Ctrl+V</button> – Pastes content from clipboard as plain text.</li>
    </ul>
    <h3 id="text-selection"><a class="headerlink" href="#text-selection">#</a> Text Selection</h3>
    <ul>
      <li><button>Ctrl+A</button> – Selects all document contents.</li>
      <li><button>Shift+Arrow</button> – Selects a text fragment by letters.</li>
      <li><button>Ctrl+Shift+Arrow</button> – Selects a text fragment by words.</li>
      <li><button>Shift+Home</button> – Selects a text fragment from the cursor to the beginning of the line.</li>
      <li><button>Shift+End</button> – Selects a text fragment from the cursor to the end of the line.</li>
      <li><button>Ctrl+Shift+Home</button> – Selects a text fragment from the cursor to the beginning of the document.
      </li>
      <li><button>Ctrl+Shift+End</button> – Selects a text fragment from the cursor to the end of the document.</li>
      <li><button>Shift+PgDn</button> – Selects a text fragment of approximately the length of the editing area starting
        from the cursor<br>
        and going down.</li>
      <li><button>Shift+PgUp</button> – Selects a text fragment of approximately the length of the editing area starting
        from the cursor<br>
        and going up.</li>
    </ul>
    <h3 id="widget-selection"><a class="headerlink" href="#widget-selection">#</a> Widget Selection</h3>
    <ul>
      <li><button>Enter</button> – Opens the widget dialog.</li>
      <li><button>Shift+Enter</button> – Adds an empty paragraph after the selected widget.</li>
      <li><button>Shift+Alt+Enter</button> – Adds an empty paragraph before the selected widget.</li>
      <li><button>Left Arrow</button> or <button>Right Arrow</button> – Moves the selection before or after the widget.
      </li>
    </ul>
    <h2 id="text-styling"><a class="headerlink" href="#text-styling">#</a> Text Styling</h2>
    <ul>
      <li><button>Ctrl+B</button> – Applies <strong>bold</strong> formatting to a text fragment.</li>
      <li><button>Ctrl+I</button> – Applies <em>italics</em> formatting to a text fragment.</li>
      <li><button>Ctrl+U</button> – Applies <span style="text-decoration: underline;">underline</span> formatting to a
        text fragment.</li>
    </ul>
    <h3 id="copy-formatting"><a class="headerlink" href="#copy-formatting">#</a> Copy Formatting</h3>
    <ul>
      <li><button>Ctrl+Shift+C</button> – Copies the inline formatting from a text fragment and enables the sticky mode.
      </li>
      <li><button>Ctrl+Shift+V</button> – Applies the previously copied inline formatting to a text fragment.</li>
    </ul>
    <h2 id="rich-text"><a class="headerlink" href="#rich-text">#</a> Rich Text</h2>
    <ul>
      <li><button>Ctrl+L</button>, <button>Ctrl+K</button> – Opens the <strong>Link</strong> dialog window.</li>
      <li><button>Tab</button> – Indents a list.</li>
      <li><button>Shift+Tab</button> – Outdents a list.</li>
    </ul>
    <h2 id="accessibility"><a class="headerlink" href="#accessibility">#</a> Accessibility</h2>
    <ul>
      <li><button>Alt+0</button> – Opens the <strong>Accessibility Instructions</strong> dialog window.</li>
      <li><button>Alt+F10</button> – Enters the toolbar or the tab list of the currently open dialog window.</li>
      <li><button>Alt+F11</button> – Enters the elements path.</li>
      <li><button>Tab</button> – Moves to the next toolbar button group, context menu suboption, elements path
        element,<br>
        dialog window element, or dialog window tab while in the tab list.</li>
      <li><button>Right Arrow</button> – Moves to the next toolbar button within the group, context menu suboption,<br>
        elements path element, dialog window element, or dialog window tab while in the tab list.</li>
      <li><button>Tab</button> or <button>Down Arrow</button> – Moves to the next drop-down list or context menu option.
      </li>
      <li><button>Shift+Tab</button> – Moves to the previous toolbar button group, context menu parent option,<br>
        elements path element, dialog window element, or dialog window tab while in the tab list.</li>
      <li><button>Left Arrow</button> – Moves to the previous toolbar button within the group, context menu<br>
        parent option, elements path element, dialog window element, or dialog window tab while in the tab list.</li>
      <li><button>Shift+Tab</button> or <button>Up Arrow</button> – Moves to the previous drop-down list or context menu
        option.</li>
      <li><button>Space</button> or <button>Enter</button> – Activates a toolbar button, a context menu<br>
        option, a drop-down list option, an elements path element, or a dialog window tab once selected.<br>
        Also enters a context menu submenu, if it is available.</li>
    </ul>
  </p-dialog>
</div>