import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { View, SysService, ViewFactory, FormDefault, Model, StaticService, noWhitespaceValidator } from '@app/_common';
import { AppConstants } from '@app/_config/app.constants';
import { environment } from '@environments/environment';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
@Component({
  selector: 'app-radiologist-list',
  templateUrl: './radiologist-list.component.html',
  styleUrls: ['./radiologist-list.component.css']
})
export class RadiologistListComponent {
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('fileInput1') fileInput1: ElementRef;
  radiologistExport = ['name', 'taxCode', 'code', 'countryId.name']; //for export header fields
  private radiologistView: View = this.viewFactory.newView();
  private transactionAmountDetailsView: View = this.viewFactory.newView();
  associateUsers: any[]
  buttonVisible1 = true;
  buttonVisible = true;
  associateUserList: Boolean
  transactionamount: boolean
  isEdit: boolean = false;
  saveButtonLabel: string = 'Save';
  maxDate: Date;
  flag: boolean;
  eventData: any;
  currency: string = '';
  showNote: boolean = false;
  hasSignature: boolean = false;
  hasStamp: boolean = false;
  signature: any;
  stamp: any;
  imageurl = '/assets/images/thumbnailnew.png'
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private viewFactory: ViewFactory) {
  }
  ngOnInit() {
    this.scrollHeight()
    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 250));
    this.detectZoomChange()

    this.radiologistView.of(this.radiologistModel()).switchAction('/radiologist', this.route, this);
    this.maxDate = new Date();
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
    this.transactionAmountDetailsView.of(this.transactionModel(), false);
  }
  countryLookup: any[];
  stateLookup: any[];
  stateLookupAll: any[];
  modalityLookup: any[];
  get transactionAmountdetails() {
    return this.transactionAmountDetailsView.model;
  }
  get radiologist() {
    return this.radiologistView.model;
  }
  private radiologistModel() {
    const formDefault = new FormDefault(['status'], [1]);
    return new Model(this.formBuilder.group({
      id: [],
      name: ['', [Validators.required, noWhitespaceValidator]],
      email: ['', [Validators.required, Validators.pattern(AppConstants.regexEmail)]],
      phoneNo: ['', [Validators.required, Validators.pattern(AppConstants.regexPhonenumber), Validators.minLength(7)]],
      dob: ['', [Validators.required]],
      qualifications: ['', [Validators.required, noWhitespaceValidator]],
      registrationNo: ['', [Validators.required, noWhitespaceValidator]],
      prefix: [''],
      docFile2: [],
      docFile1: [],
      experience: [''],
      additionalQualifications: [''],
      additionalTraining: [''],
      countryId: [, [Validators.required]],
      stateId: [''],
      address: ['', [Validators.required, noWhitespaceValidator]],
      designation: [''],
      signature: [''],
      stamp: [''],
      fileuplaod: [''],
      suffix: [''],
      status: [],
      teleradiologyCoordinator: [],
      zip: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]*$/)]],
    }), this.sysService, formDefault, '');
  }
  private transactionModel() {
    const formDefault = new FormDefault(['status'], [1]);
    return new Model(this.formBuilder.group({
      cenOrRadId: [],
      amountPaid: [, [Validators.required, Validators.pattern(AppConstants.regexPhonenumber)]],
      comments: ['', Validators.required],
      bonusType: [, [Validators.required]],
      status: [],
    }), this.sysService, formDefault, '');
  }
  add() {
    this.radiologistView.toNew();
    this.flag = false;
    this.saveButtonLabel = "Save";
  }
  edit(id: any) {
    this.radiologistView.toEditFetch(`/radiologist/${id}`, id).then(data => {
      this.signatureStamp(data)
      this.buttonVisible = true
      this.loadState(data.countryId.id)
      if (this.sysService.isAdmin()) {
        this.flag = false;
      } else {
        this.flag = true;
      }
    })
    this.saveButtonLabel = "Update";
  }
  list(id: any) {
    this.listView(true);
  }
  isScrollTable = window.innerWidth > environment.devices.tablet ? true : false;
  heightDisplay = (window.innerHeight - 280) + 'px';
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isScrollTable = window.innerWidth > environment.devices.tablet ? true : false;
    this.heightDisplay = (window.innerHeight - 280) + 'px';
  }
  async listradiologistLazy(event?: LazyLoadEvent, search: boolean = false) {
    // if (this.sysService.isAdmin()) {
    this.eventData = event
    this.radiologistView.toListLazyFetch(`/users/users-for-admin?isRad=true`, search, event);
    // }
    // else {
    //   this.radiologistView.toListLazyFetch(`/users/users-for-admin?isRad=true`, search, event);
    // }
  }
  listView(search: boolean = false) {
    // if (this.sysService.isAdmin()) {
    this.radiologistView.toListLazyView(`/users/users-for-admin?isRad=true`, search);
    // }
    // else {
    //   this.radiologistView.toListLazyView(`/users/users-for-admin?isRad=true`, search);
    // }
    this.buttonVisible = true
    this.buttonVisible1 = true

  }
  view(id: any) {
    this.radiologistView.toViewFetch(`/radiologist/${id}`, id);
  }
  deleteradiologists() {
    this.radiologistView.toDeleteSelection(`/radiologist`, {
      onSuccess: () => {
        this.radiologistView.toListLazyFetch(`/users/users-for-admin?isRad=true`, true, this.eventData);
      }
    });

  }
  deleteradiologist(id: any) {
    this.radiologistView.toDeleteList(`/radiologist/${id}`, "id", id);
  }
  associateUser(radiologist: any) {
    this.associateUserList = true
    this.radiologistView.getData(`/contacts/find-contacts?userId=${radiologist.id}&cen=false&rad=true `).then((data) => { this.associateUsers = data.list; })
  }
  // saveradiologist(add: boolean = false) {
  //   if (add) {
  //     this.radiologistView.toSaveNewFetch("/radiologist", "id", `Thanks for adding new user. We have shared a verification link to ${this.radiologist.get('email')} mail ID. Kindly check user mail (or spam / junk folder) and click on the link to activate and proceed for logging in to the ecscribe..`, "Your registration has failed.");
  //   } else {
  //     this.radiologistView.toSaveListFetch("/radiologist", "id", `Thanks for adding new user. We have shared a verification link to ${this.radiologist.get('email')} mail ID. Kindly check user mail (or spam / junk folder) and click on the link to activate and proceed for logging in to the ecscribe..`, "Your registration has failed.");
  //   }
  // }
  saveradiologist(radiologist: any) {
    if (this.radiologist.get("status") === 2) {
      this.sysService.error("Editing is only allowed after the account has been approved. Please ensure that the account is approved before attempting to make any updates.")
    } else {
      if (this.radiologistView.isValid()) {
        if (this.radiologist.isEdit()) {
          this.radiologist.setMultipart();
        }
        this.radiologistView.toSaveListFetch("/radiologist", "id", this.radiologist.isEdit() ? "Radiologist Successfully updated." : `Thanks for adding new user. We have shared a verification link to ${this.radiologist.get('email')} mail ID. Kindly check user mail (or spam / junk folder) and click on the link to activate and proceed for logging in to the eCScribe..`, "Your registration has failed.").then(data => {
          this.signatureStamp(data)
          this.buttonVisible = true
          this.buttonVisible1 = true


        })
      }
      this.listradiologistLazy()
      this.radiologist.clearMultipart()
    }
  }
  deactivateButton(deactivateButton) {
  }
  // onUpload(event) {
  //   for (let file of event.files) {
  //     this.uploadedFiles.push(file);
  //   }
  // }
  // fileChangeEvent(fileInput: any) {
  //   if (fileInput.target.files && fileInput.target.files[0]) {
  //     const file: File = fileInput.target.files[0];
  //     if (file) {
  //       this.myfilename = file.name;
  //       this.formData = new FormData();
  //       this.formData.set("file", file);
  //     } else {
  //       this.myfilename = 'Select File';
  //     }
  //   }
  // }
  uploadFile(files: FileList) {
    this.radiologist.setFile(files, "cerificate");
  }
  countryOnchange(event: any) {
    this.radiologistView.getData(`/country/lookup${event.value}`, "")
  }
  onCountryChange(event) {
  }
  filterCountry(event: any) {
    this.sysService.clear();
    this.radiologistView.getData(`/country/lookup?filter=${event.query}`).then(data => { this.countryLookup = data; });
  }
  filterState(event: any) {
    // check if stateLookupAll is already loaded
    if (this.stateLookupAll) {
      // if loaded, filter the results
      this.stateLookup = this.stateLookupAll.filter((x: any) =>
        x.name.toLowerCase().indexOf(event.query.toLowerCase()) === 0
      );
    } else {
      // if not loaded, get countryId from billing view and load states for that country
      let countryId = this.radiologist?.view?.countryId?.id;
      if (countryId === undefined) {
        this.sysService.error("Please select a country");
      } else {
        this.radiologistView
          .getData(`/state/lookupbycountry?countryId=${countryId}`)
          .then((data: any) => {
            // store the loaded states and set the filtered results
            this.stateLookup = data;
            this.stateLookupAll = data;
          });
      }
    }
  }
  loadState(countryId) {
    // load states for the given countryId
    this.radiologistView.getData(`/state/lookupbycountry?countryId=${countryId}`).then(data => {
      // store the loaded states and set the filtered results
      this.stateLookupAll = data;
      this.stateLookup = data;
    });
  }
  stateAreaOnChange(event: any) {
    // load states for the selected country
    this.loadState(event.id);
    // reset stateId
    this.radiologist.set("stateId", null);
  }
  activateButton(radiologist: any) {
    let messageValue = ['activate', 'deactivate', 'send activation mail'][radiologist.status];
    let id = radiologist.id
    this.confirmationService.confirm({
      header: 'Confirmation',
      message: `Are you sure  want to ${messageValue} ?`,
      accept: () => {
        const status = radiologist.status;
        const message = {
          1: "Account has been deactivated successfully.",
          0: "Account has been activated successfully.",
          2: `Activation mail sent to ${radiologist?.email}`
        }[status];
        this.radiologistView.getData(`/users/activate?radId=${radiologist?.id}&&status=${status}`).then(data => {
          this.radiologist?.list?.forEach((x) => {
            // let statusString = data.status;
            if (x.id === id) {
              // let statusInt = parseInt(statusString.replace(/^"(.*)"$/, '$1'));
              x.status = data.status;
            }
          });
          this.sysService.success(message);
        });
      }
    });
  }
  selectFile(file, name) {
    this.sysService.clear()
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target) {
        const img = new Image();
        img.src = e.target.result as string;
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          // console.log("Image dimensions:", width, "x", height);
          if (file[0].size <= 200 * 1024) {
            // console.log("File size:", file[0].size, "bytes");
            if (width <= 275 && height <= 150) {

              this.radiologist.setFile(file, name);
              this.radiologist.view.signature = null;
              this.signature = img.src
              this.hasSignature = true

              this.buttonVisible1 = false;
            } else {
              this.sysService.error(" Please choose a smaller file with dimensions 275x150.");
              this.resetFileInput()
              this.radiologist.view.signature = null;
            }
          } else {
            this.sysService.error("File size exceeds 200kb limit or incorrect dimensions. Please choose a smaller file with dimensions 275x150.");
            this.resetFileInput()
            this.radiologist.view.signature = null;
          }
        };
      }
    };
    // Read the file as a data URL
    reader.readAsDataURL(file[0]);
  }
  selectFile1(file, name) {
    this.sysService.clear()
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target) {
        const img = new Image();
        img.src = e.target.result as string;
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          // console.log("Image dimensions:", width, "x", height);
          if (file[0].size <= 200 * 1024) {
            // console.log("File size:", file[0].size, "bytes");
            if (width <= 275 && height <= 150) {
              this.radiologist.setFile(file, name);
              this.radiologist.view.stamp = null;
              this.stamp = img.src
              this.hasStamp = true

              this.buttonVisible = false;
            } else {
              this.sysService.error(" Please choose a smaller file with dimensions 275x150");
              this.radiologist.view.stamp = null;
              this.resetFileInput1()
            }
          } else {
            this.sysService.error("File size exceeds 200kb limit or incorrect dimensions. Please choose a smaller file with dimensions 275x150.");
            this.radiologist.view.stamp = null;
            this.resetFileInput1()
          }
        };
      }
    };
    // Read the file as a data URL
    reader.readAsDataURL(file[0]);
  }
  resetFileInput() {
    // Reset the value of the input file
    this.fileInput.nativeElement.value = '';
  }
  resetFileInput1() {
    // Reset the value of the input file
    this.fileInput1.nativeElement.value = '';
  }
  getFilename(name) {
    if (name) {
      const filename = name.substring(name.indexOf("_") + 1, name.length);
      return filename.length > 15 ? filename.substring(0, 15) : filename;
    }
    return "";
  }

  closesig(row: any) {
    this.radiologist.set('signature', null)
    this.signature = this.imageurl;
    this.buttonVisible1 = false;
  }
  closestam(row: any) {
    this.radiologist.set('stamp', null)
    this.stamp = this.imageurl;
    this.buttonVisible = false;
  }
  signatureStamp(data) {
    this.radiologistView.image(`/users/rad-signature?radId=${data?.id}&isUserId=false`)
      .subscribe(
        byte => {
          this.hasSignature = byte.byteLength > 0;
          this.signature = this.sysService.arrayBufferToBase64ImageSource(byte, data?.signatureExt);
        }
      );
    this.radiologistView.image(`/users/rad-stamp?radId=${data?.id}&isUserId=false`)
      .subscribe(
        byte => {
          this.hasStamp = byte.byteLength > 0;
          this.stamp = this.sysService.arrayBufferToBase64ImageSource(byte, data?.stampExt);
        })
  }
  transactionAmount(radiologist: any) {
    this.radiologist.set("id", radiologist.id)
    this.transactionAmountdetails.reset();
    this.transactionAmountdetails.set("cenOrRadId", radiologist.id)
    this.transactionAmountdetails.set("status", radiologist.status)
    this.transactionamount = true
  }
  bonusTypeAmount(event: any) {
    this.transactionAmountdetails.set("amountPaid", null)
    if (event.value === 1) {
      this.transactionAmountDetailsView.getData("/usersettings/custom-details").then((data) => {
        this.transactionAmountdetails.set("amountPaid", data.bonus)
      }
      )
    }
  }
  walletUpdate(search: boolean = false, event?: LazyLoadEvent) {
    const id = this.radiologist.get("id")
    if (this.transactionAmountdetails.get("status") !== 1) {
      this.sysService.errorAdd("Wallet updates are only accessible for active accounts.")
      this.transactionamount = false;
    } else {
      let id = this.transactionAmountdetails.get("cenOrRadId")
      let adminTransactionType = this.transactionAmountdetails.get("bonusType")
      if (this.transactionAmountDetailsView.isValid()) {
        let amountValue = this.transactionAmountdetails.get("amountPaid")
        if (this.checkAmountRange(amountValue)) {
          this.transactionAmountDetailsView.toSave(`/transaction?isFree=true&cenOrRadId=${id}&isRad=true&adminTransactionType=${adminTransactionType}`, '', 'to send radiologist s wallet').then((data) => {
            if (data.message === 'Success') {
              this.radiologist?.list?.forEach((x) => {
                if (x.id === id) {
                  x.walletBalance = data.walletBalance;
                }
              });
              this.sysService.handleSuccessMessage(data.message, adminTransactionType, 2);
            } else {
              this.sysService.error(`${data.message}`);
            }
            this.transactionamount = false;
          });
        }
        else {
          window.alert("The amount is either greater than 30000 or equal to 0.")
          this.transactionAmountdetails.set("amountPaid", null)
        }
      }
    }
  }
  checkAmountRange(value: number): boolean {
    return value >= 1 && value <= 30000;
  }
  cancelTransactionAmount() {
    this.sysService.clear()
    this.transactionamount = false
  }
  rejectBack() {
    this.transactionamount = false;
    this.transactionAmountdetails.reset();
    setTimeout(() => {
      this.sysService.clear();
    }, 800);
  }
  loginToSysadmin(radi: any) {
    if (this.sysService.isAdmin()) {
      const name = radi.name;
      const base64Id = btoa(String(radi.id));
      const url2 = `/sys-rad-login?loginValue=${base64Id}&&name=${name}`;
      const url = `${environment.app}${url2}`;
      this.confirmationService.confirm({
        message: `Do you want to login as ${radi.name}?`,
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        header: 'Confirmation',
        accept: () => {
          // window.location.href = url;
          window.open(url, '_blank');
        },
        reject: () => {
        }
      });
    }
  }
  heightTable: any;
  debounceTimeout: any;
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 280) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 280) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  detectZoomChange() {
    this.scrollHeight();
  }
}
