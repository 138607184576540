import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ApiService, SysService, View } from '@app/_common';
import { FormDefault, Model } from './model.view';
import { ConfirmationService } from 'primeng/api';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
@Injectable()
export class ViewFactory {

  constructor(private router: Router, private location: Location, public sysService: SysService, private apiService: ApiService, private confirmationService: ConfirmationService, private formBuilder: UntypedFormBuilder) {
  }

  newView() {
    const v = new View(this.router, this.location, this.sysService, this.apiService, this.confirmationService);
    v.model= this.emptyForm();
    return v;
  }
  
   newViewPopup() {
    const v = new View(this.router, this.location, this.sysService, this.apiService, this.confirmationService);
    v.setPopup(true);
    v.model= this.emptyForm();
    return v;
  }

  private emptyForm() {
    return new Model(this.formBuilder.group({
      id: [],
      noformdefined: []
    }), this.sysService, new FormDefault(), '');
  }
}

