import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model, passwordMatch } from '@app/_common';
import { AppConstants } from '@app/_config';

@Component({
  selector: 'app-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.css']
})
export class ChangePassComponent {
  private newPasswordView: View = this.viewFactory.newView();
  regexPassword: any;
  constructor(public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: UntypedFormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory,
  ) { }
  ngOnInit(): void {
    this.newPasswordView.of(this.newPassModel())
    this.route.queryParams.subscribe(params => {
      const uniqueId = params.uniqueId;
      this.newPassword.set('uniqueId', uniqueId);
    });
  }
  get newPassword() {
    return this.newPasswordView.model;
  }
  private newPassModel() {
    const formDefault = new FormDefault([], []);
    return new Model(this.formBuilder.group({
      uniqueId: [''],
      oldPassword: ['', [Validators.required, Validators.maxLength(15)]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern(AppConstants.regexPassword)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern(AppConstants.regexPassword)]],
    },
      { validators: [passwordMatch] }), this.sysService, formDefault, '');

  }
  submit() {
    if (this.newPasswordView.isValid()) {
      this.newPasswordView.toSaveNav('/users/resetpwd', "", "/profileview", "Your Password has been changed.");
    }
  }
  cancel() {
    this.sysService.navigate(['/']);
  }
  navHome() {
    this.sysService.navigate(['/']);
  }
}
