import { Component } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
  View,
  SysService,
  StaticService,
  ViewFactory,
  FormDefault,
  Model,
} from "@app/_common";
import { environment } from "@environments/environment";
import { LazyLoadEvent } from "primeng/api";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
@Component({
  selector: "ohif-viewer",
  templateUrl: "./viewer.component.html",
  styleUrls: ["./viewer.component.scss"],
})
export class ViewerComponent {
  private viewerView: View = this.viewFactory.newView();
  countryLookup: any[];
  siuid: any;
  radi: boolean;
  reportRadiologist: any;
  viewerSize: any = [70, 30];
  Report: boolean;
  reportRadilogist: boolean;
  viewerTab: boolean;
  reportViewOnly: boolean;
  viewerOnly: boolean;
  showEclarityReport: boolean = true;

  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public config: DynamicDialogConfig,
    private viewFactory: ViewFactory
  ) {}
  ngOnInit() {
    this.viewerView.of(this.viewerModel(), false);
    if (this.config?.data?.popUp) {
      this.siuid = this.config?.data?.siuid;
      this.Report = this.config?.data?.Report;
      this.reportViewOnly = this.config?.data?.reportViewOnly;
      this.viewerTab = this.config?.data?.viewerTab;
    }
    if (!this.config?.data?.popUp) {
      this.route.queryParams.subscribe((params) => {
        const parasiuid = params.siuid;
        this.siuid = atob(parasiuid);
        const paraReport = params.i;
        this.reportRadiologist = atob(paraReport);
        this.viewerTab = params.viewer === "true";
      });
    }
    if (location.pathname === "/radiologistviewer") {
      this.reportRadilogist = false;
    }
  }
  get viewer() {
    return this.viewerView.model;
  }

  toggleEclarityReport() {
    this.showEclarityReport = this.showEclarityReport;
  }
  private viewerModel() {
    const formDefault = new FormDefault([], []);
    return new Model(
      this.formBuilder.group({}),
      this.sysService,
      formDefault,
      ""
    );
  }
  resizeManagerMouseDown(event) {
    document.getElementById("ech-viewer")?.classList.remove("pointer-normal");
    document.getElementById("ech-viewer")?.classList.add("pointer-none");
  }
  resizeManagerMouseUp(event) {
    document.getElementById("ech-viewer")?.classList.add("pointer-normal");
    document.getElementById("ech-viewer")?.classList.remove("pointer-none");
  }
  viewerUrl() {
    let url = "";
    if (environment.ohif) {
      url = `${environment.viewer.ohif}/viewer?StudyInstanceUIDs=${this.siuid}`;
      return url;
    } else {
      this.sysService.error(
        "Viewer URL not found. Please contact the support team or use the secondary viewer."
      );
    }
  }
}
