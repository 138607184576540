import { DatePipe } from '@angular/common';
import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model } from '@app/_common';
import { AppConstants } from '@app/_config';
import { environment } from '@environments/environment';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css'],
  providers: [DatePipe],

})
export class TicketComponent {
  @ViewChild("calendarRef") calendar: any;

  @Input() err: string;
  @Input() errFlag: boolean;
  @Input() errFlag1: boolean;
  smartFilter: any;
  flag: boolean
  selectedRange: Date[];
  search: boolean = false;
  private ticketView: View = this.viewFactory.newView();
  private ticketSearchView: View = this.viewFactory.newView();
  modalityLookup: any[];
  dataTicket: any[]
  eventData: any;
  ticketNumberLookup: any[];
  UserTypeLookup: any[]
  statusLookup: any[]
  constructor(
    public sysService: SysService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public staticService: StaticService,
    private viewFactory: ViewFactory,
    private datePipe: DatePipe,

  ) {
  }
  ngOnInit() {
    this.ticketView.of(this.ticketModel()).switchAction('/ticket/ticket-search', this.route, this);
    this.ticketSearchView.of(this.ticketSearchModel());
    this.scrollHeight()
    this.detectZoomChange()

    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 400));

  }
  get ticket() {
    return this.ticketView.model;
  }
  get ticketSearch() {
    return this.ticketSearchView.model;
  }
  private ticketSearchModel() {
    const formDefault = new FormDefault(['progresStatus',], [4.]);
    return new Model(this.formBuilder.group({
      progresStatus: [],
      priorityStatus: [],
      UserType: [],
      Status: [],
      createdDate: [],
      subject: [],
    }), this.sysService, formDefault, '');
  }
  private ticketModel() {
    const formDefault = new FormDefault(['status', 'priorityStatus', 'progresStatus'], [1, 3, 4]);
    return new Model(this.formBuilder.group({
      id: [],
      ticketNumber: [''],
      subject: [''],
      lastPersonToIntervene: [''],
      correspondance: [''],
      message: [''],
      status: [],
      ticketDetails: [''],
      ticketId: [],
      createdAt: [],
      updatedAt: [],
      progresStatus: [],
      priorityStatus: [''],

    }), this.sysService, formDefault, '');
  }
  add() {
    this.ticketView.toNew();
    this.flag = false;

  }
  edit(id: any) {
    this.ticketView.toEditFetch(`/ticket/${id}`, id);
    this.flag = true;
  }

  list(id: any) {
    this.listView(true);
  }
  reset() {
    this.list(true)
    this.errFlag = false;
    this.errFlag1 = false;


  }
  isScrollTable = window.innerWidth > environment.devices.tablet ? true : false;
  heightDisplay = window.innerHeight - 450 + "px";
  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.isScrollTable =
      window.innerWidth > environment.devices.tablet ? true : false;
    this.heightDisplay = window.innerHeight - 450 + "px";
    //    document.documentElement.style.setProperty('--height-table', this.heightDisplay);

  }

  searchTicketSearch() {
    this.search = true;
  }
  async listTicketLazy(event?: LazyLoadEvent) {

    this.eventData = event

    const createdDate = String(this.ticketSearch.get("createdDate"));
    const startDate = new Date(createdDate.split(",")[0]);
    const endDate = new Date(createdDate.split(",")[1]);
    const formattedStartDate = `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() +
      1).toString().padStart(2, '0')}/${startDate.getFullYear()}`;
    const formattedEndDate = `${endDate.getDate().toString().padStart(2, '0')}/${(endDate.getMonth() +
      1).toString().padStart(2, '0')}/${endDate.getFullYear()}`;
    const rangeDate = `${formattedStartDate}-${formattedEndDate}`;
    this.ticketView.setParam("createdDate", rangeDate);
    this.ticketView.setParam("priorityStatus", this.ticketSearch.get("priorityStatus"));
    this.ticketView.setParam("progresStatus", this.ticketSearch.get("progresStatus"));
    this.ticketView.setParam("userId", this.ticketSearch.get("UserType")?.id);
    this.ticketView.setParam("status", this.ticketSearch.get("Status"));
    this.ticketView.setParam("subject", this.ticketSearch.get("subject"));
    this.ticketView.toListLazyFetch(`/ticket/ticket-search`, this.search, event);
    this.search = false;
  }
  listView(search: boolean = false) {
    this.ticketView.toListLazyView(`/ticket/ticket-search`, search);
    this.errFlag = false;
    this.errFlag1 = false;
  }
  view(id: any) {
    this.ticketView.toViewFetch(`/ticket/${id}`, id);
  }
  deleteTickets() {
    this.ticketView.toDeleteSelection(`/ticket`, {
      onSuccess: () => {
        this.ticketView.toListLazyFetch(`/ticket/ticket-search`, true, this.eventData);
      }
    });

  }
  deleteTicket(id: any) {
    this.ticketView.toDeleteList(`/ticket/${id}`, "id", id);
  }
  saveTicket() {
    if (this.ticketView.isValid()) {
      if (this.ticket.isEmpty("message") || this.ticket.isEmpty("subject")) {
        this.errFlag = true;
        this.errFlag1 = true;

        this.sysService.error(AppConstants.validationFailed);
      } else {
        this.ticketView.toSave(`/ticket`, "id", "Message was sent successfully")
          .then(data => {
            this.errFlag = false;
            this.errFlag1 = false;
            setTimeout(() => {
              this.sysService.clear();
            }, 1000);
          });
      }
    }
  }

  resetTicketSearch() {
    this.ticketSearch.reset()
    this.search = true;
  }
  filterSubject(event: any) {
    this.ticketSearchView.getData(`/ticket/lookup?filter=${event.query}`).then((data) => {
      this.dataTicket = data;
    });
  }
  filterTicketNumber(event: any) {
    this.ticketView.getData(`/ticket/ticketnumber-lookup?filter=${event.query}`).then(data => {
      this.ticketNumberLookup =
        data;
    });
  }
  filterUserTypeLookup(event: any) {
    this.ticketSearchView.getData(`/ticket/user-lookup?filter=${event.query}`).then(data => {
      this.UserTypeLookup =
        data;
    });
  }
  filterStatus(event: any) {
    this.ticketSearchView.getData(`/ticket/status-lookup?filter=${event.query}`).then(data => {
      this.statusLookup =
        data;
    });
  }
  changesCalendarClear(dt) {
    this.calendarSelected = false;

    this.ticketSearch.reset()
    this.search = true;
    this.calendar.hideOverlay();
  }
  dateRange;
  calendarSelected = false;
  calenedarChangeDate(event, dt) {
    this.dateRange = "";
    if (this.selectedRange) {

      const startDate = this.datePipe.transform(
        this.selectedRange[0],
        "dd/MM/yyyy"
      );
      const endDate = this.datePipe.transform(
        this.selectedRange[1],
        "dd/MM/yyyy"
      );
      this.dateRange = `${startDate}-${endDate}`;
      this.listTicketLazy(dt);
    }
    this.calendar.hideOverlay();
  }
  heightTable: any;
  debounceTimeout: any;
  scrollHeight() {
    var bodyHeight = document.body.offsetHeight;
    var heightTable: string;
    if (this.sysService.isAdmin()) {
      if (bodyHeight < 732) {
        heightTable = ((bodyHeight - 420) / bodyHeight * 100) + 'vh'
      } else {
        heightTable = ((bodyHeight - 420) / bodyHeight * 100) + 'vh'
      }
      this.heightTable = heightTable;
      document.documentElement.style.setProperty('--height-table', heightTable);
    }
    else {
      if (bodyHeight < 732) {
        heightTable = ((bodyHeight - 350) / bodyHeight * 100) + 'vh'
      } else {
        heightTable = ((bodyHeight - 350) / bodyHeight * 100) + 'vh'
      }
      this.heightTable = heightTable;
      document.documentElement.style.setProperty('--height-table', heightTable);
    }
  }

  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  detectZoomChange() {
    this.scrollHeight();
  }
}