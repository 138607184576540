import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { StaticService } from './data/static.service';
import { FieldErrorComponent } from './components/field-error/field-error.component';
import { TableExportComponent } from './table-export/table-export.component';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AppHeaderComponent } from './header/header.component';
import { AvatarModule } from 'primeng/avatar';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { MessageService } from 'primeng/api';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { BadgeModule } from 'primeng/badge';
import { NavComponent } from './components/breadcrumb/nav.component';
import { ToolbarModule } from 'primeng/toolbar';
import { RxStompService } from './notification/rxstomp.service';
import { NotificationComponent } from './notification/notification.component';
import { NotifyService } from './notification/notify.service';
import { SafePipe } from '@app/_common/directives/safe.pipe';
import { PageLoadingComponent } from './components/page-loading/page-loading.component';
import { CheckboxModule } from 'primeng/checkbox';
import { NonFoundComponent } from './components/not-found/not-found.component';
import { RoutingService } from './services/routing.service';
@NgModule({
  imports: [CommonModule, ButtonModule, DropdownModule, AvatarModule, FormsModule, ToolbarModule, MenuModule, OverlayPanelModule, DialogModule, ReactiveFormsModule, MessagesModule, TieredMenuModule, BadgeModule, CheckboxModule],
  declarations: [SafePipe, AppHeaderComponent, PageLoadingComponent, NonFoundComponent,FieldErrorComponent, NavComponent, TableExportComponent, NonFoundComponent,NotificationComponent],
  exports: [SafePipe, AppHeaderComponent, PageLoadingComponent, FieldErrorComponent, NavComponent, TableExportComponent,NonFoundComponent,
    CommonModule, FormsModule, ToolbarModule, DialogModule, ConfirmDialogModule, NotificationComponent],
  providers: [NotifyService, RxStompService, StaticService, MessageService,RoutingService],
})
export class SharedModule { }
