import { Component, OnDestroy, OnInit } from '@angular/core';
import { Message } from '@stomp/stompjs';
import { Subscription } from 'rxjs';
import { NotificationService } from './notification.service';
import { SysService } from '../services/sys.service';
import { RxStompService } from './rxstomp.service';
import { NotifyService } from './notify.service';

@Component({
  selector: 'app-messages',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  // @ts-ignore, to suppress warning related to being undefined
  private topicSubscription: Subscription;

  constructor(public notifyService: NotifyService,
    public sysService: SysService,
  ) { }

  ngOnInit() {
    if (!this.notifyService.active()) {
      this.topicSubscription?.unsubscribe();
      this.topicSubscription = this.notifyService.watch('/user/topic/notification');
    }
  }
  messages() {
    return this.notifyService.receivedMessages;
  }

  ngOnDestroy() {
    this.topicSubscription?.unsubscribe();
  }

  //   onSendMessage() {
  //     const message = `Message generated at ${new Date()}`;
  //     this.rxStompService.publish({ destination: '/topic/demo', body: message });
  //   }
}