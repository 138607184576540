import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model, ViewAction } from '@app/_common';
import { environment } from '@environments/environment';
import { LazyLoadEvent } from 'primeng/api';
@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.css']
})
export class TransactionHistoryComponent implements OnInit, ViewAction {
  transactionListExport = ['name', 'taxCode', 'code', 'countryId.name']; //for export header fields
  private transactionListView: View = this.viewFactory.newView();
  countryLookup: any[];
  flag: boolean;
  eventData: any;
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory) {
  }
  ngOnInit() {
    this.scrollHeight()
    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 400));
    this.detectZoomChange()

    this.transactionListView.of(this.transactionListModel(), false);
  }
  get transactionList() {
    return this.transactionListView.model;
  }
  private transactionListModel() {
    const formDefault = new FormDefault(['status'], [1]);
    return new Model(this.formBuilder.group({
      id: [],
      validFrom: ['', [Validators.required]],
      validTill: ['', [Validators.required]],
      transactionId: [''],
      transactionType: [, [Validators.required]],
      comments: [''],
      amountPaid: [, [Validators.required]],
      payStatus: [, [Validators.required]],
    }), this.sysService, formDefault, '');
  }
  isScrollTable = window.innerWidth > environment.devices.tablet ? true : false;
  heightDisplay = window.innerHeight - 280 + "px";
  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.isScrollTable =
      window.innerWidth > environment.devices.tablet ? true : false;
    this.heightDisplay = window.innerHeight - 280 + "px";
  }
  add() {
    this.transactionListView.toNew();
  }
  edit(id: any) {
    this.transactionListView.toEditFetch(`/transaction/${id}`, id);
  }
  list(id: any) {
    this.listView(false);
  }
  async listTransactionListLazy(event?: LazyLoadEvent, search: boolean = false) {
    this.eventData = event
    this.transactionListView.toListLazyFetch(`/transaction`, search, event);
  }
  listView(search: boolean = false) {
    this.transactionListView.toListLazyView(`/transaction`, search);
  }
  view(id: any) {
    this.transactionListView.toViewFetch(`/transaction/${id}`, id);
  }
  deleteTransactionLists() {
    this.transactionListView.toDeleteSelection(`/transaction`, {
      onSuccess: () => {
        this.transactionListView.toListLazyFetch(`/transaction`, true, this.eventData);
      }
    });

  }
  deleteTransactionList(id: any) {
    this.transactionListView.toDeleteList(`/transaction/${id}`, "id", id);
  }
  saveTransactionList() {
    this.transactionListView.toSaveList("/transaction", "id");
  }
  pdfDownload(transactionList: any) {
    this.sysService.clear()
    if (transactionList.payStatus === 3) {
      const pdfFilename = `Invoice-${transactionList.invoice}.pdf`;
      this.transactionListView.download(`/transaction/downloadpdf/${transactionList.transactionId}`, pdfFilename);
    }
    else {
      this.sysService.error('After a successful payment, You should be able to download an invoice ')
      setTimeout(() => {
        this.sysService.clear();
      }, 5000);
    }
  }
  downloadExcel(transactionList: any) {
    const pdfFilename = !this.transactionListView.isEmptyList('date') ? `Transaction.xls` : 'Transaction.xls';
    this.transactionListView.download(`/transaction/download-transaction-excel?`, pdfFilename);
  }
  heightTable: any;
  debounceTimeout: any;
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 300) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 300) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  detectZoomChange() {
    this.scrollHeight();
  }
}