// routing.service.ts
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  currentRoute: string;

  constructor(private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      // Split the URL by slashes and take the first segment
      const segments = this.router.url.split('/');
      this.currentRoute = segments[1]; 
    });
  }
}
