import { Injectable } from '@angular/core';
import { SysService } from './sys.service';
import { jsPDF } from "jspdf";
import { asBlob } from 'html-docx-js-typescript';
import { saveAs } from 'file-saver';
import Docxtemplater from 'docxtemplater';
import PizZipUtils from 'pizzip/utils/index.js';
import * as PizZip from 'pizzip';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    public sysService: SysService,
  ) {
    const currentDate = new Date();
    this.currentDateTime = currentDate.toLocaleString();
  }
  imageUrl: any
  viewSize: any = [70, 30];
  timeHold: any;

  currentDateTime: string; // Variable to store current date and time

  viewerSizeFunction() {
    this.viewSize = [100, 0]
  }

  printReport(editor: any, report: any, signature: any, stamp: any, centerHeader: any, centerFooter: any) {


    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    // if (!editor)
    //   return;
    report.exportPdf_options = {
      header_html: '<div class="styled">Header content</div>',
      footer_html:
        '<div class="styled-counter"><span class="pageNumber"></span></div>',
      header_and_footer_css:
        ".styled { font-weight: bold; padding: 10px; } .styled-counter { font-size: 1em; color: hsl(0, 0%, 60%); }",
      margin_top: "2cm",
      margin_bottom: "2cm",
    };
    const element =
      document.getElementById("cke_1_contents") ||
      document.createElement("div");
    var printContents = element.innerHTML.replace("Press ALT 0 for help", "");

    let popupWin: Window | null = null;

    if (isSafari) {
      popupWin = window.open('', '_blank');
    } else {
      popupWin = window.open();

    }


    let status = report.status;
    let studyStatus = report.studyStatus;
    let watermark = ''
    let fileName = `${report?.patName}/ ${report?.patId ?? ""}/${this.sysService.toDate1(report?.studyDate) ?? ""}`;
    fileName = fileName.replace(/\./g, "");
    if (status === 0 || status === null) {
      if (studyStatus == 1) {
        watermark = "Pending Report";

      } else if (studyStatus == 2) {
        watermark = "Drafted Report";
      } else {
        watermark = " Rejected Report";

      }
    } else if (status === 1) {
      watermark = " Drafted Report";
    } else if (status === 3) {
      watermark = " Unapproved Report";
    }
    else if (status === 4) {
      watermark = " Unapproved";
    }
    else if (status === 100) {
      watermark = "  Report Template ";
    }

    const name = report.patName ?? "";
    let capitalizedFirstName;
    if (name) {
      capitalizedFirstName = name.charAt(0).toUpperCase() + name.slice(1);
    }

    let disclaimer = '';

    if (report?.disclaimer) {
      disclaimer = `<tr class="b-t" >
        <td colspan="4">
        <p ><i>${report?.disclaimer ? 'Disclaimer: ' + report?.disclaimer : ''}<i></p>
        </td>
      </tr>`
    }
    let footerPat = '';
    footerPat = `${report?.patName} / ${report?.patId ?? ""}`
    footerPat += ` (Printed: ${this.sysService.formatDateTime() ?? ""})`
    if (popupWin) {
      popupWin.document.write(`
      <!DOCTYPE html>
      <html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>${fileName}</title>
    <style>
      @page {
        size: auto;
        counter-increment: page;
        ${(report.isHeaderPresent) ? 0 :
          (report.centreId.isReportType === true) ?
            `margin: ${report.centreId.marginTop}px 
           ${report.centreId.marginLeft}px 
           ${report.centreId.marginBottom}px 
           ${report.centreId.marginRight}px;` :
            `margin: 100px ${report.centreId.marginLeft}px
           ${report.centreId.marginBottom}px 
           ${report.centreId.marginRight}px;`}
      }
      body {
        margin: 0;
        padding: 0;
        line-height: 1.5; /* Adjust this value to fit 38 lines per page */
      }
      .content {
        height: calc(38 * 1.5em); /* 38 lines * line-height */
      }
      .mb-2 {
        border-bottom: 2px solid #000;
        font-size: 14px;
        padding: 8px 0px;
      }
      table {
        width: 98%;
        text-align: left;
        border-collapse: collapse;
      }
      table img {
        max-width: 100%;
        height: auto !important;
      }
      .watermark {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-40deg);
        opacity: 0.2;
        color: #808080;
        height: 50px;
        width: 100%;
        font-size: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      thead {
        display: table-header-group;
      }
      ${(report.centreId.isPatientDetailsAllPage === true) ? `
      thead.noRepeat tr:first-child th {
        padding-top: ${report.centreId.marginTop >= 100 ? report.centreId.marginTop - 100 : report.centreId.marginTop}px;
      }` : ''}
      .details {
        display: none;
      }
      .b-t {
        border-top: 2px solid gray;
      }
      thead tr th {
        padding-right: 10px;
        height: 20px;
        font-weight: normal;
        font-size: 14px;
        padding: 8px 0px;
      }
      .page-number {
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        text-align: right;
      }
      .page-number::after {
        position: absolute;
        right: 0px;
        left: 0px;
        margin: auto;
        bottom: 20px;
        font-size: 14px;
        content: counter(page);
      }
      tfoot {
        display: table-footer-group;
      }
      tfoot tr td {
        text-align: center;
      }
      table,
      td,
      th {
        border-collapse: collapse;
      }
      .text-right {
        text-align: right;
      }
      .stamp {
        
        
      }
        .signature{
       
        }
      .hthead {
        width: auto;
      }
      .headertable {
        width: 100%;
        border-top: 1px solid black;
        border-right: 1px solid black;
        border-left: 1px solid black;
        border-bottom: 1px solid black;
      }
      .headertable td {
        padding: 5px;
      }
      .phead {
        width: 180px;
        padding: 4px;
        font-weight: bold;
        white-space: nowrap;
      }
      .phead2 {
        width: 98%;
        padding: 4px;
      }
      .phead3 {
        width: 160px;
        padding: 4px;
        white-space: nowrap;
      }
      .cenhead {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
      }
      .bold {
        font-weight: bold;
        white-space: nowrap;
      }
    </style>
  </head>
  <body>
    <div class="watermark">
      ${watermark}
    </div>
    <div class="mainWrap">
      <table>
        <thead class="${report.centreId.isReportType === true ? 'hthead' : 'hthead noRepeat'}">

         ${report.centreId.isFooterHeaderAllPage && report.isHeaderPresent && centerHeader ? `
           <tr>
            <th colspan="4">
              <img src="${centerHeader}" class="cenhead"  width="100%" />
            </th>
           </tr>` : ''}
           
          <tr>
            <td colspan="4">
              <table class="headertable">
                <tr>
                  <td class="phead">PATIENT NAME</td>
                  <td class="phead2" colspan="3">: ${capitalizedFirstName ?? ""}</td>
                </tr>
                <tr>
                  <td class="phead">PATIENT ID</td>
                  <td class="phead2">: ${report.patId ?? ""}</td>
                  <td class="phead3 bold">AGE/SEX</td>
                  <td class="phead3">: ${report.patAge ? report.patSex ? `${report.patAge}/${report.patSex}` : report.patAge : report.patSex ?? ""}</td>
                </tr>
                <tr>
                  <td class="phead">REFERRED BY</td>
                  <td class="phead2">: ${report.refPhysicianName ?? ""}</td>
                  <td class="phead3 bold">DATE</td>
                  <td class="phead3">: ${this.sysService.toDate1(report.studyDate) ?? ""}</td>
                </tr>
              </table>
            </td>
          </tr>
        </thead>
        <tbody>
            <tr>        
                  <td colspan="4" >
                  ${editor?.replaceAll("text-align:justify; ", "").replaceAll(/\\r\\n/g, "<br/>").replaceAll(/\\n/g, "<br/>")} <br/>  
                   </td>
                </tr> 
          <tr>
            <td colspan="4" class="text-right">
              ${signature ? `<img src="${signature}" alt="sign" class="signature"/>` : ''}
              <p style="margin: 0px; padding: 0px;">${report.reportingRadUserId?.radiologistId?.prefix ?? ""}&nbsp;${report.reportingRadUserId?.radiologistId?.name ?? ""}&nbsp;${report.reportingRadUserId?.radiologistId?.suffix ?? ""}</p>
              <p style="margin: 0px; padding: 0px;">${report.reportingRadUserId?.radiologistId?.qualifications ?? ""}</p>
              <p style="margin: 0px; padding: 0px;">${report.reportingRadUserId?.radiologistId?.designation ?? ""}</p>
              <p style="margin: 0px; padding: 0px;">${report.reportingRadUserId?.radiologistId?.registrationNo ?? ""}</p>
              ${stamp ? `<img src="${stamp}" alt="stamp" class="stamp"/>` : ''}
            </td>
          </tr>
          ${disclaimer}
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4" style="text-align: left;">
              ${report.centreId.isFooterHeaderAllPage && report.isFooterPresent && centerFooter ? `<img src="${centerFooter}"  width="100%" />` : `${footerPat}`}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <script>
      const totalPages = document.querySelectorAll('.mainWrap').length;
      const pageNumbers = document.querySelectorAll('.page-num');
      const totalPageElements = document.querySelectorAll('.total-pages');
      for (let i = 0; i < pageNumbers.length; i++) {
        pageNumbers[i].textContent = i + 1;
        totalPageElements[i].textContent = totalPages;
      }
      var is_chrome = function () {
        return Boolean(window.chrome);
      };
      if (is_chrome()) {
        setTimeout(function () {
          window.print();
        }, 100);
        setTimeout(function () {
          window.close();
        }, 200); // Give them 100 milliseconds (0.1 second) to print, then close
      } else {
        window.print();
        window.close();
      }
    </script>
  </body>
</html>

      `
      );
      popupWin.document.close();
    }
  }


  public editorData;
  configuration = {
    // delayIfDetached: true,
    toolbarGroups: [{ groups: ['undo'] },

    { name: 'editing', groups: ['find', 'selection'] },//'spellchecker'

    { name: 'insert' },
    { name: 'document', groups: ['mode', 'document', 'doctools'] },
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
    { name: 'tools', groups: ['maximize'] },
    { name: 'styles' },
    { name: 'colors' },

    ],
    resize_enabled: true,
    pasteFromWord: false,
    versionCheck: false,
    // readOnly: reportTemplate?.view?.editable === true, // Set readOnly to true if reportTemplate.view.editable is true
    removePlugins: 'elementspath,save,magicline,image,sourcearea',
    // extraPlugins: 'divarea,smiley,justify,indentblock,easyimage,maximize,uploadwidget,image2,link,placeholder,colordialog,clipboard,widget,dialog,font,preview ,autogrow,colorbutton,font,tableresize,liststyle,pagebreak,pastefromgdocs'  ,
    extraPlugins:
      "image2,dialogui,dialog,a11yhelp,basicstyles,bidi,blockquote,clipboard,placeholder,widget,lineutils," +
      "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
      "contextmenu,dialogadvtab,enterkey,entities,popup," +
      "filebrowser,find,fakeobjects,floatingspace,listblock,richcombo," +
      "font,format,horizontalrule,htmlwriter,indent," +
      "indentblock,indentlist,justify,list,liststyle,magicline," +
      "maximize,pagebreak," +
      "removeformat,resize,save,menubutton,selectall,showblocks," +
      "showborders,specialchar,stylescombo,tab,table," +
      "tabletools,toolbar,undo,wysiwygarea," +
      "autocomplete,textmatch,link",
    pasteFromWordRemoveFontStyles: true,
    allowedContent: true,
    height: '842px',
    removeDialogTabs: 'image:advanced;link:advanced',
    format_tags: 'p;h1;h2;h3;pre;div',

    bodyClass: 'document-editor',
    contentsCss: ['https://cdn.ckeditor.com/4.8.0/full-all/contents.css', 'mystyles.css'],

    stylesSet: [
      // / Inline Styles /
      { name: 'Marker', element: 'span', attributes: { 'class': 'marker' } },
      { name: 'Cited Work', element: 'cite' },
      { name: 'Inline Quotation', element: 'q' },

      // / Object Styles /
      {
        name: 'Special Container',
        element: 'div',
        styles: {
          padding: '5px 10px',
          background: '#eee',
          border: '1px solid #ccc'
        }
      },
      {
        name: 'Compact table',
        element: 'table',
        attributes: {
          cellpadding: '5',
          cellspacing: '0',
          border: '1',
          bordercolor: '#ccc'
        },
        styles: {
          'border-collapse': 'collapse'
        }
      },
      { name: 'Borderless Table', element: 'table', styles: { 'border-style': 'hidden', 'background-color': '#E6E6FA' } },
      { name: 'Square Bulleted List', element: 'ul', styles: { 'list-style-type': 'square' } }
    ]
  }


  downloadJspdf(editor: any, report: any, signature: any, stamp: any, downloadReport: any, CenterHeader: any, CenterFooter: any) {

    const name = report.patName ?? "";
    let capitalizedFirstName = '';
    if (name) {
      capitalizedFirstName = name.charAt(0).toUpperCase() + name.slice(1);
    }
    let fileName = `${report?.patName}/ ${report?.patId ?? ""}/${this.sysService.toDate1(report?.studyDate) ?? ""}`;
    fileName = fileName.replace(/\./g, "");
    let l = `${report?.centreId.marginLeft}`;
    let r = `${report?.centreId.marginRight}`;
    let left = l ? parseInt(l) : 0;
    let right = r ? parseInt(r) : 0;

    let reduce = (left + right);
    let width = 810;

    if (reduce <= 10)
      width -= reduce + 22
    else if (reduce <= 20)
      width -= (reduce * 2) + (reduce / 2)
    else if (reduce <= 60)
      width -= (reduce * 2) - (reduce / (reduce / 10))
    else if (reduce <= 80)
      width -= (reduce * 2) - (reduce / 3)
    else if (reduce <= 100)
      width -= (reduce * 2) - (reduce / 2)
    else if (reduce <= 200)
      width -= (reduce * 2) - (reduce / 2)

    let footerPat = '';
    footerPat = `${report?.patName ?? ""} / ${report?.patId ?? ""}`;

    footerPat += ` (Printed: ${this.sysService.formatDateTime() ?? ""})`
    // }

    let status = report.status;
    let studyStatus = report.studyStatus;
    let watermark = '';

    if (status === 0 || status === null) {
      if (studyStatus == 1) {
        watermark = "Pending Report";

      } else if (studyStatus == 2) {
        watermark = "Drafted Report";
      } else {
        watermark = " Rejected Report";

      }
    } else if (status === 1) {
      watermark = " Drafted Report ";
    } else if (status === 3) {
      watermark = " Unapproved Report ";
    }
    else if (status === 4) {
      watermark = " Unapproved ";
    }
    else if (status === 100) {
      watermark = " Report Template ";
    }


    let disclaimer = '';

    if (report?.disclaimer) {
      disclaimer = `<tr class="b-t">
        <td colspan="4">
          <p><i>${report?.disclaimer ? 'Disclaimer: ' + report?.disclaimer : ''}</i></p>
        </td>
      </tr>`;
    }


    document.write(
      `<!DOCTYPE html><html lang="en">
         <head>
          <meta charset="UTF-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>${fileName}</title>
        </head>
       
      </div>
        <style>    
        .watermark {
          position: fixed;
          top: 50%;
          left: 0%;
          transform: translate(-50%, -50%) rotate(-40deg);
          opacity: 0.2;
          color: #808080;
          height: 50px;
          width: ${width}px;
          font-size: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
       }    
          body {          
            margin: 0;
            padding: 0;
          }
        .mb-2{
            border-bottom:2px solid #000;
            height: 20px;
            padding:8px 0px;
        }
          table {        
            width: ${width}px;
            text-align: left;        
          }
          table img {
            max-width: 100%;
            height: auto !important;
          }

         thead {
            display: table-header-group;
            margin-bottom: 20px;
            
          }
            thead.noRepeat{
              display: table-row-group;
          }                   
         
          
          .b-t {     
            border-top: 2px solid gray;
          }
          .page-number{
            position: fixed;
            margin-top:10px
            bottom: 0px;
            left: 0px;
            width: 100%;
           

            text-align: right;
          }
        .page-number:: after {
          position: absolute;
          right: 0px;
          left: 0px;
          margin:auto;
          bottom:20px;
          font-size:14px;
          content: counter(page);
        }
          thead tr th {
            padding-right: 10px;
            height: 20px;
            font-weight: normal;
            font-size:14px;
            padding:8px 0px;
          }        
          tfoot {
            display: table-footer-group;
          }        
          tfoot tr td {
            text-align: center;
          }        
          table,
          td,
          th {
            border-collapse: collapse;        
          }        
          .text-right {
            text-align: right;
          }        
          .stamp {
            width: 100%;
            max-width: 150px;
            height: auto;
          }    
          .hthead {
            border-top: 1px solid black;
            border-right: 1px solid black;
            border-left: 1px solid black;
            border-bottom: 1px solid black;           
          }       
          .phead{
            width:180px;
            padding:4px;
            font-weight: bold;
            white-space: no-wrap;
          }  
          .phead2{
            width:${width - 180}px;
            padding:4px;
          }   
          .phead3{
            width:60px;    
            padding:4px;     
            white-space: no-wrap; 
          }   
          .phead4{
            width:120px;    
            padding:4px;     
            white-space: no-wrap; 
          }   
          .bold{
            font-weight: bold;
            white-space: no-wrap;
          }  
          .centre-header{         
            margin-left:-68px;   
           
            
          }
        </style>        
        <body>
        <div class="watermark">
        ${watermark}

      </div>
      

          <div class="mainWrap">
            <table style="width:${width}px">
              <thead class="${report.centreId.isReportType === true ? 'hthead' : 'hthead noRepeat'}" >              
              <tr>
<td class="phead">PATIENT&nbsp; NAME</td><td class="phead2" colspan="3">: ${capitalizedFirstName ?? ""}</td>            </tr>
            <tr>
              <td class="phead">PATIENT &nbsp;  ID</td><td class="phead2" >: ${report.patId ?? ""}</td>
              <td class="phead3 bold">AGE/SEX</td>
              <td class="phead3">
                : ${report.patAge ? report.patSex ? `${report.patAge}/${report.patSex}` : report.patAge : report.patSex ?? ""}
              </td>
                          </tr>
            <tr>
              <td class="phead">REFERRED BY</td><td class="phead2">: ${report.refPhysicianName ?? ""}</td>
              <td class="phead3 bold">DATE</td><td class="phead4">: ${this.sysService.toDate1(report.studyDate) ?? ""}</td>             
            </tr>           
              </thead>
              <tbody style="">      
                <tr>        
                  <td colspan="4" >
                  ${editor?.replaceAll("text-align:justify; ", "").replaceAll(/\\r\\n/g, "<br/>").replaceAll(/\\n/g, "<br/>")} <br/>  
                   </td>
                </tr>        
                <tr>
                  <td colspan="4" class="text-right">
                    ${signature ? ` <img src="${signature}" alt="sign"/>` : ''} 
                    <p style="margin:0px;margin-top:2px;padding:0px;">${report.reportingRadUserId?.radiologistId?.prefix ?? ""}&nbsp;${report.reportingRadUserId?.radiologistId?.name ?? ""}&nbsp;${report.reportingRadUserId?.radiologistId?.suffix ?? ""}</p>
                    <p style="margin:0px;padding:0px;">${report.reportingRadUserId?.radiologistId?.qualifications ?? ""}</p>
                    <p style="margin:0px;padding:0px;">${report.reportingRadUserId?.radiologistId?.designation ?? ""}</p>
                    <p style="margin:0px;padding:0px;">${report.reportingRadUserId?.radiologistId?.registrationNo ?? ""}</p>        
                    ${stamp ? ` <img src="${stamp}" alt="stamp" />` : ''}
                  </td>
                </tr>
                ${disclaimer}  
                          
              </tbody>
              <tfoot >             
                <tr>
                <td colspan="4">

                <div class="page-number">                
                   <span ${report?.isPatientDetailsAllPage === true ? '' : 'class="deatils"'}>${footerPat}</span>
                    Page: <span class="page-num"></span> of <span class="total-pages"></span>
                </div>
            </td>
             
                </tr>
                
              </tfoot>
               
            </table>
                   
          </div>  
              
        </body> 
        
        </html>
    <script>
    // const disclaimerContent = document.querySelector('#disclaimerRow p').textContent.trim();
    // if (disclaimerContent === '') {
    //   document.querySelector('#disclaimerRow').style.display = 'none';
    // }              
    
    // const totalPages = document.querySelectorAll('.mainWrap').length;
    // const pageNumbers = document.querySelectorAll('.page-num');
    // const totalPageElements = document.querySelectorAll('.total-pages');
  
    // for (let i = 0; i < pageNumbers.length; i++) {
    //   pageNumbers[i].textContent = i + 1;
    //   totalPageElements[i].textContent = totalPages;
    // }
    // var is_chrome = function () {
    //     return Boolean(window.chrome);
    // };

    // if (is_chrome()) {
    //   setTimeout(function () {
    //     window.print();
    // }, 100);
     
    //     setTimeout(function () {
    //         window.close();
    //     }, 200); // Give them 100 milliseconds (0.1 second) to print, then close
    // } else {
    //     window.print();
    //     window.close();
    // }
</script>`


    );
    let jsPdf = new jsPDF('p', 'pt', 'a4');
    let htmlElement = document.body;
    // you need to load html2canvas (and dompurify if you pass a string to html)
    let h = `${report.centreId.marginTop}`;
    let f = `${report.centreId.marginBottom}`;
    let header = h ? parseInt(h) : 0;
    let footer = f ? parseInt(f) : 0;

    jsPdf.html(htmlElement, {
      callback: function (jsPdf) {
        const pageCount = jsPdf.internal.pages.length - 1;

        for (let i = 1; i <= pageCount; i++) {
          jsPdf.setPage(i);
          jsPdf.setFontSize(64);
          jsPdf.saveGraphicsState();
          jsPdf.setGState(jsPdf.GState({ opacity: 0.2 }));
          jsPdf.setTextColor(150);
          jsPdf.text(watermark, 100, jsPdf.internal.pageSize.height - 250, { angle: 40 });
          jsPdf.restoreGraphicsState();
          let h = jsPdf.internal.pageSize.height; // 840
          jsPdf.setFontSize(9);

          if (report?.centreId?.isFooterHeaderAllPage) {
            if (report.isFooterPresent && report.isHeaderPresent && report?.isShowHeaderFooter) {
              jsPdf.addImage(CenterFooter, report.footerExt, 0, 794, 600, 49);
              jsPdf.addImage(CenterHeader, report.headerExt, 0, 0, 600, 60);
            } else if (!report.isFooterPresent && report.isHeaderPresent && report?.isShowHeaderFooter) {
              jsPdf.addImage(CenterHeader, report.headerExt, 0, 1, 600, 60);
            } else if (report.isFooterPresent && !report.isHeaderPresent && report?.isShowHeaderFooter) {
              jsPdf.addImage(CenterFooter, report.footerExt, 0, 794, 600, 49);
            }
          } else {
            if (i === 1 && report.isHeaderPresent && report?.isShowHeaderFooter) {
              jsPdf.addImage(CenterHeader, report.headerExt, 0, 1, 600, 60);
            }
            if (i === pageCount && report.isFooterPresent && report?.isShowHeaderFooter) {
              jsPdf.addImage(CenterFooter, report.footerExt, 0, 794, 600, 49);
            }
            if (i !== 1 && i !== pageCount) {
              jsPdf.text(footerPat, left, h - (footer - 20));
              jsPdf.text('(Page ' + String(i) + ' of ' + String(pageCount) + ')', 500 - 10, h - (footer - 20));
            }
          }
        }

        if (downloadReport === 'true') {
          this.timeHold = 200;
          jsPdf.save(fileName); //to download 
        } else {
          this.timeHold = 3000;
          window.open(URL.createObjectURL(jsPdf.output("blob")))


        }
        setTimeout(function () {
          window.close();
        }, this.timeHold);

      },
      margin: [report.isHeaderPresent && report.isShowHeaderFooter ? 70 : header, right, footer, left],
      autoPaging: 'text',
      html2canvas: {
        allowTaint: true,
        letterRendering: true,
        logging: false,
        scale: .75,
      }
    });


  }

  loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
  }

  exportCKEditorContentToWord(editor: any, report: any, signature: any, stamp: any) {
    let fileName = `${report?.patName}/ ${report?.patId ?? ""}/${this.sysService.toDate1(report?.studyDate) ?? ""}`;
    fileName = fileName.replace(/\./g, "");
    let l = `${report?.centreId.marginLeft}`;
    let r = `${report?.centreId.marginRight}`;
    let left = l ? parseInt(l) : 0;
    let right = r ? parseInt(r) : 0;

    let reduce = (left + right);
    let width = 810;

    if (reduce <= 10)
      width -= reduce + 22
    else if (reduce <= 20)
      width -= (reduce * 2) + (reduce / 2)
    else if (reduce <= 60)
      width -= (reduce * 2) - (reduce / (reduce / 10))
    else if (reduce <= 80)
      width -= (reduce * 2) - (reduce / 3)
    else if (reduce <= 100)
      width -= (reduce * 2) - (reduce / 2)
    else if (reduce <= 200)
      width -= (reduce * 2) - (reduce / 2)
    let footerPat = '';
    footerPat = `${report?.patName} / ${report?.patId ?? ""}`
    footerPat += ` (Printed: ${this.sysService.formatDateTime() ?? ""})`
    // }



    let status = report.status;
    let studyStatus = report.studyStatus;
    let watermark = '';

    if (status === 0 || status === null) {


      if (studyStatus == 1) {
        watermark = "Pending Report";

      } else if (studyStatus == 2) {
        watermark = "Drafted Report";
      } else {
        watermark = " Rejected Report";

      }



      watermark = " Rejected Report ";
    } else if (status === 1) {
      watermark = " Drafted Report ";
    } else if (status === 3) {
      watermark = " Unapproved Report ";
    }
    else if (status === 4) {
      watermark = " Unapproved ";
    }
    else if (status === 100) {
      watermark = " Report Template ";
    }


    let disclaimer = '';

    if (report?.disclaimer) {
      disclaimer = `<p><i>Disclaimer: ${report?.disclaimer}</i></p>`
    }
    const name = report.patName ?? "";
    let capitalizedFirstName = '';
    if (name) {
      capitalizedFirstName = name.charAt(0).toUpperCase() + name.slice(1);
    }

    const sourceHTML = this.contentValue({
      fileName,
      width,
      report,
      watermark,
      signature,
      stamp,
      disclaimer,
      editor,
      capitalizedFirstName,
      footerPat
    });


    asBlob(`${sourceHTML}`, {
      orientation: 'portrait',
      margins: {
        top: 1000
      }
    }).then((data: Blob) => {


      // const link = document.createElement('a');
      // link.href = URL.createObjectURL(data);
      // link.download = `${fileName}.doc`;
      // link.click();
      // link.remove();
      saveAs(data, `${fileName}.docx`);
    });

    /* 
        // this.loadFile('/assets/templates/template.docx', (error: Error | null, content: string) => {
        //   if (error) {
        //     throw error;
        //   }
        //   const zip = new PizZip(content);
        //   const doc = new Docxtemplater(zip, {
        //     paragraphLoop: true,
        //     linebreaks: true,
        //   });
        //   // doc.setData({
        //   //   // first_name: 'John',
        //   //   // last_name: 'Doe',
        //   //   // phone: '0652455478',
        //   //   // description: 'New Website',
        //   // });
        //   try {
        //     // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
        //     console.log({ sourceHTML });
    
        //     doc.render({
        //       html: sourceHTML
        //     });
        //   } catch (error) {
        //     // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
        //     function replaceErrors(key, value) {
        //       if (value instanceof Error) {
        //         return Object.getOwnPropertyNames(value).reduce(function (
        //           error,
        //           key
        //         ) {
        //           error[key] = value[key];
        //           return error;
        //         }, {});
        //       }
        //       return value;
        //     }
        //     console.log(JSON.stringify({ error: error }, replaceErrors));
    
        //     if (error.properties && error.properties.errors instanceof Array) {
        //       const errorMessages = error.properties.errors
        //         .map(function (error) {
        //           return error.properties.explanation;
        //         })
        //         .join('\n');
        //       console.log('errorMessages', errorMessages);
        //       // errorMessages is a humanly readable message looking like this :
        //       // 'The tag beginning with "foobar" is unopened'
        //     }
        //     throw error;
        //   }
        //   const out = doc.getZip().generate({
        //     type: 'blob',
        //     mimeType:
        //       'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        //   });
        //   // Output the document using Data-URI
        //   saveAs(out, `${fileName}.docx`);
        // }
        // );
     */


    // const blob = new Blob([sourceHTML], { type: 'application/msword' });
    // const link = document.createElement('a');
    // link.href = URL.createObjectURL(blob);
    // link.download = `${fileName}.doc`;
    // link.click();
  }

  contentValue(params: {
    fileName: string;
    width: any;
    report: any;
    watermark: any;
    signature: any;
    stamp: any;
    disclaimer: any;
    editor: any;
    capitalizedFirstName: any;
    footerPat: any;
  }) {


    let fileName = params.fileName,
      width = params.width,
      report = params.report,
      watermark = params.watermark,
      signature = params.signature,
      stamp = params.stamp,
      disclaimer = params.disclaimer,
      editor = params.editor,
      capitalizedFirstName = params.capitalizedFirstName,
      footerPat = params.footerPat;


    // font-family: "Times New Roman", Times, serif;
    const content = `
    <!DOCTYPE html>
    <html lang="en">

    <head>
    <meta charset="UTF-8">
      <title>${fileName}</title>
    </head>

    <body>
      <table style="width: 100%;">
        <tbody style="font-family: Arial, Helvetica, sans-serif; padding: 0px; margin: 0px; vertical-align: top;">
          <tr>
            <td align="top" style="vertical-align: top; margin: 0px; padding: 8px 2px 0 2px; border-top: 1px solid #000; border-left: 1px solid #000; width: 20%;">
              <b style="margin: 0px;">PATIENT NAME</b>
            </td>
            <td align="top" style="vertical-align: top; margin: 0px; padding: 8px 2px 0 2px; border-top: 1px solid #000; border-right: 1px solid #000;" colspan="3"><span style="margin: 0px;">: ${capitalizedFirstName ?? ""}</span></td>
          </tr>
          <tr>
            <td align="top" style="vertical-align: top; margin: 0px; padding: 2px 2px 0 2px; border-left: 1px solid #000; width: 20%;"><b style="margin: 0px;">PATIENT ID</b></td>
            <td align="top" style="vertical-align: top; margin: 0px; padding: 2px 2px 0 2px; padding-bottom: 0px;"><span style="margin: 0px;">: ${report.patId ?? ""}</span></td>
            <td align="top" style="vertical-align: top; margin: 0px; padding: 2px 2px 0 2px; padding-bottom: 0px; text-align: left; width: 10%;"><b>AGE/SEX</b></td>
            <td align="top" style="vertical-align: top; margin: 0px; padding: 2px 2px 0 2px; border-right: 1px solid #000;">: ${report.patAge ? report.patSex ? `${report.patAge}/${report.patSex}` : report.patAge : report.patSex ?? ""}</td>
          </tr>
          <tr>
            <td align="top" style="vertical-align: top; margin: 0px; padding: 2px 2px 0 2px; border-left: 1px solid #000; border-bottom: 1px solid #000; padding-bottom: 0px; width: 20%;">
              <b>REFERRED BY</b>
            </td>
            <td align="top" style="vertical-align: top; margin: 0px; padding: 2px 2px 0 2px; border-bottom: 1px solid #000; padding-bottom: 0px;">: ${report.refPhysicianName ?? ""}</td>
            <td align="top" style="vertical-align: top; margin: 0px; padding: 2px 2px 0 2px; border-bottom: 1px solid #000; padding-bottom: 0px; text-align: left;"><b>DATE</b></td>
            <td align="top" style="vertical-align: top; margin: 0px; padding: 2px 2px 0 2px; border-bottom: 1px solid #000; border-right: 1px solid #000; padding-bottom: 0px; width: 20%;">: ${this.sysService.toDate1(report.studyDate)}</td>
          </tr> 
        </tbody>
      </table>
      ${report.reportContent}
     <br />

      <table style="width: 100%;">
        <tbody style="font-family: Arial, Helvetica, sans-serif; padding: 0px; margin: 0px; vertical-align: top;">
          <tr>
            <td colspan="4" class="text-right" style="text-align: right;">
              ${signature ? `<img src="${signature}" style="width: 100px; max-width: 100px; height: auto;" />` : ''}
              <p style="margin: 0px; margin-top: 2px; padding: 2px;">${report.reportingRadUserId?.radiologistId?.prefix ?? ""}&nbsp;${report.reportingRadUserId?.radiologistId?.name ?? ""}
              ${report.reportingRadUserId?.radiologistId?.suffix ? `&nbsp;${report.reportingRadUserId?.radiologistId?.suffix}` : ""}</p>
              <p style="margin: 0px; padding: 2px;">${report.reportingRadUserId?.radiologistId?.qualifications ?? ""}</p>
              <p style="margin: 0px; padding: 2px;">${report.reportingRadUserId?.radiologistId?.designation ?? ""}</p>
              <p style="margin: 0px;  margin-bottom: 2px;  padding: 2px;">${report.reportingRadUserId?.radiologistId?.registrationNo ?? ""}</p><br>
              ${stamp ? `<img src="${stamp}" style="width: 100px; max-width: 100px; height: auto;" />` : ''}
            </td>
          </tr>
        
          ${disclaimer ? `<tr class="b-t">
            <td colspan="4" style="border-top: 1px solid #000; padding-top: 10px;">
              ${disclaimer}
            </td>
          </tr>`: ``}
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4">
              <div class="page-number" style="position: fixed; bottom: 0px; left: 0px; width: 100%; text-align: center;">
                <span ${report?.isPatientDetailsAllPage === true ? '' : 'class="deatils"'}> ${footerPat} </span>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </body>

    </html>
    `;

    return content;
  }

  convertImagesToBase64(contentDocument: string | Document) {

    if (typeof contentDocument === "string") {
      contentDocument = new DOMParser()?.parseFromString(contentDocument, 'text/html');
    }
    var regularImages = contentDocument.querySelectorAll("img");
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    if (ctx) {
      [].forEach.call(regularImages, function (imgElement) {
        // preparing canvas for drawing
        ctx?.clearRect(0, 0, canvas.width, canvas.height);
        canvas.width = imgElement.width;
        canvas.height = imgElement.height;

        ctx?.drawImage(imgElement, 0, 0);
        // by default toDataURL() produces png image, but you can also export to jpeg
        // checkout function's documentation for more details
        var dataURL = canvas.toDataURL();
        imgElement.setAttribute('src', dataURL);
      })
    }
    canvas.remove();

    return contentDocument;
  }


  exportCKEditorContentToWordForPhone(editor: any, report: any, signature: any, stamp: any) {
    let fileName = `${report?.patName}/ ${report?.patId ?? ""}/${this.sysService.toDate1(report?.studyDate) ?? ""}`;
    fileName = fileName.replace(/\./g, "");
    let l = `${report?.centreId.marginLeft}`;
    let r = `${report?.centreId.marginRight}`;
    let left = l ? parseInt(l) : 0;
    let right = r ? parseInt(r) : 0;

    let reduce = (left + right);
    let width = 810;

    if (reduce <= 10)
      width -= reduce + 22
    else if (reduce <= 20)
      width -= (reduce * 2) + (reduce / 2)
    else if (reduce <= 60)
      width -= (reduce * 2) - (reduce / (reduce / 10))
    else if (reduce <= 80)
      width -= (reduce * 2) - (reduce / 3)
    else if (reduce <= 100)
      width -= (reduce * 2) - (reduce / 2)
    else if (reduce <= 200)
      width -= (reduce * 2) - (reduce / 2)



    let footerPat = '';
    footerPat = `${report?.patName} / ${report?.patId ?? ""}`
    footerPat += ` (Printed: ${this.sysService.formatDateTime() ?? ""})`
    // }



    let status = report.status;
    let studyStatus = report.studyStatus;
    let watermark = '';

    if (status === 0 || status === null) {

      if (studyStatus == 1) {
        watermark = "Pending Report";

      } else if (studyStatus == 2) {
        watermark = "Drafted Report";
      } else {
        watermark = " Rejected Report";

      }


      watermark = " Rejected Report ";
    } else if (status === 1) {
      watermark = " Drafted Report ";
    } else if (status === 3) {
      watermark = " Unapproved Report ";
    }
    else if (status === 4) {
      watermark = " Unapproved ";
    }
    else if (status === 100) {
      watermark = " Report Template ";
    }


    let disclaimer = '';

    if (report?.disclaimer) {
      disclaimer = `<tr class="b-t"  >
        <td colspan="4" style="border-top:1px solid #000; padding-top:10px;" >
          <p ><i>${report?.disclaimer ? 'Disclaimer: ' + report?.disclaimer : ''}<i></p>
        </td>
      </tr>`
    }
    const name = report.patName ?? "";
    let capitalizedFirstName = '';
    if (name) {
      capitalizedFirstName = name.charAt(0).toUpperCase() + name.slice(1);
    }


    const sourceHTML = this.contentValue({
      fileName,
      width,
      report,
      watermark,
      signature,
      stamp,
      disclaimer,
      editor,
      capitalizedFirstName,
      footerPat
    });

    const blob = new Blob([sourceHTML], { type: 'application/msword' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.doc`;
    link.click();
  }


}
