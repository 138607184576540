import { Component, Input } from '@angular/core';
import { SysService } from '@app/_common/services/sys.service';


@Component({
  selector: 'page-loading',
  templateUrl: './page-loading.component.html',
  styleUrls: ['./page-loading.component.scss']
})
export class PageLoadingComponent {
  @Input() loading: boolean;
  constructor(
    public sysService: SysService
  ) { 
   
  }
}
