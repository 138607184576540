import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SysService, StaticService, ViewFactory } from '@app/_common';
@Component({
  selector: 'app-template-dashboard',
  templateUrl: './template-dashboard.component.html',
  styleUrls: ['./template-dashboard.component.css']
})

export class TemplateDashboard1Component {
  activeTabIndex: number = 0;
  onTabChange(event: any) {
    this.activeTabIndex = event.index;
  }
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory) {
  }
}
