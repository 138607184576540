<div class="table-responsive">
    <form [formGroup]="WalletDetalis.form" class="fullform mt-3 ml-2">
        <div class="grid">

            <div class="  col-12 md:col-3 lg:col-2 m-2">
                <span class="p-float-label">
                    <p-dropdown formControlName="mon" inputId="month" placeholder="Select"
                        [options]="staticService.month" (onChange)="onDataSelectionChange($event)">
                    </p-dropdown>

                    <label for="float-input">Month </label>
                </span>
                <f-error [err]="WalletDetalis.required('mon')" msg="Month is required"></f-error>
            </div>
            <div class=" col-12 md:col-3 lg:col-2 m-2">
                <span class="p-float-label">
                    <p-dropdown id="year" formControlName="year" (onChange)="onDataSelectionChange($event)"
                        inputId="year" [options]="WalletDetailsLookup" placeholder="Select" optionLabel="year"
                        optionValue="year">

                    </p-dropdown>
                    <label for="float-input">Year </label>

                </span>
                <f-error [err]="WalletDetalis.required('year')" msg="Year is required"></f-error>
            </div>

            <div class=" col-12 md:col-3 lg:col-2 m-2">

                <span>
                    <span class="p-float-label">

                        <p-calendar #calendarRef [showIcon]="false" dateFormat="dd/mm/yy" formControlName="date"
                            placeholder="DD/MM/YYYY-DD/MM/YYYY" [numberOfMonths]="1" class="widthClass"
                            selectionMode="range" [readonlyInput]="true" inputId="range" [(ngModel)]="selectedRange"
                            (onSelect)="calendarSelected = true" (onSelect)="onDateSelect($event)">
                            <ng-template pTemplate="footer">
                                <div class="grid justify-content-center">
                                    <p-button label="Clear" *ngIf="calendarSelected"
                                        (click)="changesCalendarClear(dt);dt.reset()"
                                        styleClass="p-button-raised p-button-text"></p-button>&nbsp;
                                    <button pButton pRipple type="button" *ngIf="calendarSelected"
                                        (click)="calenedarChangeDate($event,dt);dt.reset()" label=" Search"
                                        class="p-button-raised p-button-text"></button>
                                </div>
                            </ng-template>
                        </p-calendar>

                        <label for="float-input">Date Range </label>

                    </span>
                </span>
   

            </div>


            <div class="col-12 md:col-3 lg:col-2 m-2">
                
                    <span class="p-float-label">
                        <p-autoComplete forceSelection="true" formControlName="modality" field="name" 
                            [suggestions]="modLookup" (completeMethod)="filterMod($event)" ariaLabel="bodyPart"
                            [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400" maxlength="50">
                            
                        </p-autoComplete>
                        <label for="float-input">Modality</label>
                    </span>
                
            </div>



            <div class=" field col-6 md:col-3 lg:col-2 m-2" *ngIf="sysService.isAdmin()">
                <span class="p-float-label">
                    <p-autoComplete forceSelection="true" formControlName="userId" field="name"
                        [suggestions]="userLookup" (completeMethod)="filterUser($event)" ariaLabel="bodyPart"
                        [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400" maxlength="50">
                    </p-autoComplete>
                    <label for="float-input">User</label>
                </span>

            </div>

            <div class="field mt-4">
                <button pButton pRipple type="button" (click)="searchWalletDetails();dt.reset()" label=" Search"
                    class="p-button-raised p-button-info" [disabled]="sysService.loading">
                    <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                </button>
                <button pButton pRipple type="button" (click)="resetWalletDetails();dt.reset()" label=" Reset"
                    class="p-button-raised p-button-text" [disabled]="sysService.loading">
                    <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>

                </button>
            </div>

        </div>
        <div class="text-right mr-2">
            <label class="text-2xl"><b>Opening Balance : </b> <span>
                    {{openingBalance || '0.00' }} </span></label>

        </div>
    </form>

</div>
<div class="table-responsive">



    <p-table #dt [value]="WalletDetalis.list" class="study-list-class" [scrollable]="true" [scrollHeight]="heightTable"
        [totalRecords]="WalletDetalis.total" [lazy]="true" (onLazyLoad)="listWalletDetailsListLazy($event)"
        dataKey="studyId" [(selection)]="WalletDetalis.selection" selectionMode="multiple"
        [loading]="sysService.loading" filterDelay="400" styleClass="p-datatable-sm p-datatable-gridlines"
        currentPageReportTemplate="{first} to {last} of {totalRecords}" [rowsPerPageOptions]="[25,50]" [rowHover]="true"
        [rows]="25" [paginator]="true" [showCurrentPageReport]="true" alwaysShow="false" paginatorPosition="bottom"
        [globalFilterFields]="['createdAt','transactionId','narration','credit','modality','debit','charge','studyDescModified','studyDesc']">



        <ng-template pTemplate="caption">

            <div class="flex justify-content-between flex-wrap card-container purple-container pb-3">
                <div class="flex align-items-center justify-content-center ">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" maxlength="150" (input)="dt.filterGlobal($event.target.value,
                                          'contains')" placeholder="Search..." pAutoFocus [autofocus]="true" />
                    </span>
                    &nbsp;&nbsp; <button pButton pRipple type="button" label="Export Excel" icon=" pi pi-download"
                        (click)="downloadExcel(WalletDetalis)" [disabled]="sysService.loading "
                        class="p-button-raised p-button-secondary">
                        <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>

                    </button>
                </div>

                <label class="text-2xl"><b>Balance :</b> <span> {{closingBalance || '0.00' }} </span></label>

            </div>

        </ng-template>

        <ng-template pTemplate="paginatorleft">

        </ng-template>
        <ng-template pTemplate="header">
            <tr>
               
                <th pSortableColumn="patName">Patient Name
                    <p-sortIcon field="patName"></p-sortIcon>
                </th>

                <th pSortableColumn="modality">Modality
                    <p-sortIcon field="modality"></p-sortIcon>
                </th>

                <th pSortableColumn="studyDesc">Study Description
                    <p-sortIcon field="studyDesc"></p-sortIcon>
                </th>

                <th pSortableColumn="userName">User
                    <p-sortIcon field="userName"></p-sortIcon>
                </th>

                <th pSortableColumn="narration">
                    Narration <p-sortIcon field="narration"></p-sortIcon>
                </th>

                <th pSortableColumn="createdAt">Date
                    <p-sortIcon field="createdAt"></p-sortIcon>
                </th>

                <th pSortableColumn="credit">Credit<p-sortIcon field="credit"></p-sortIcon>
                </th>
                <th pSortableColumn="debit">Debit<p-sortIcon field="debit"></p-sortIcon>
                </th>

                <th pSortableColumn="studyStatus">Study Status<p-sortIcon field="studyStatus"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-WalletDetalis>
            <tr>
              
                <td>{{WalletDetalis.patName}}</td>
                <td>{{WalletDetalis.modality}}</td>
                <td>{{WalletDetalis.studyDesc}}</td>
                <td>{{WalletDetalis.userName }}</td>

                <td pTooltip="{{WalletDetalis?.narration}}">
                    {{ WalletDetalis?.narration?.length > 25 ? WalletDetalis?.narration?.slice(0, 25) + '...' :
                    WalletDetalis?.narration }}
                </td>

                <td>{{ WalletDetalis.createdAt | date: 'dd-MM-yyyy hh:mm a ' }} </td>

                <td>{{WalletDetalis.credit || '0'}}</td>
                <td>{{WalletDetalis.debit || '0'}}</td>

                <td>
                    <span *ngIf="WalletDetalis.studyStatus===0"> Unassigned</span>
                    <span *ngIf="WalletDetalis.studyStatus===1"> Pending</span>
                    <span *ngIf="WalletDetalis.studyStatus===2"> Drafted</span>
                    <span *ngIf="WalletDetalis.studyStatus===3"> Unapproved</span>
                    <span *ngIf="WalletDetalis.studyStatus===4"> Completed</span>
                    <span *ngIf="WalletDetalis.studyStatus===5"> Emergency</span>
                    <span *ngIf="WalletDetalis.studyStatus===6"> Rejected </span>
                    <span *ngIf="WalletDetalis.studyStatus===7"> Unapproved </span>
                    <span *ngIf="WalletDetalis.studyStatus===8"> Unapproved </span>

                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td class="norecordstudy" [attr.colspan]="10">No records found</td>
            </tr>
        </ng-template>
    </p-table>
</div>