<div class="grid">
    <div class="col-12 right-button">
        <p> Need help...? &nbsp;<a href="https://www.ecscribe.com" target="_blank" class="link">Click here
            </a>
        </p>
    </div>
</div>
<form [formGroup]="UserRoleDetails.form">
    <div class="card">
        <div class="grid">

            <div class="field col-12 md:col-6 lg:col-6">
                <label>Name <span class="validation-star">*</span></label>
                <input pInputText maxlength="150" formControlName="name" type="text" pAutoFocus autofocus="true">
                <f-error [err]="UserRoleDetails.required('name')" msg=" Name is
                    required"></f-error>
                <f-error [err]="UserRoleDetails.pattern('name')" msg=" white space not allowed on begining"></f-error>

            </div>
            <div class="field col-12 md:col-6 lg:col-6">
                <label for="email">Email Address <span class="validation-star">*</span></label>
                <input id="email" type="text" [readOnly]="true" maxlength="100" pInputText formControlName="email"
                    required />
                <f-error [err]="UserRoleDetails.required('email')" msg="Email Address is required "></f-error>
                <f-error [err]="UserRoleDetails.pattern('email')"
                    msg="This e-mail address is not valid. Valid records include to user@somehost.somedomain. And emails only accept lowercase letters.">
                </f-error>
            </div>
            <div class="field col-12 md:col-6  lg:col-6">
                <label>Phone Number <span class="validation-star">*</span></label>
                <input pInputText maxlength="18" formControlName="phoneNo" type="text">
                <f-error [err]="UserRoleDetails.required('phoneNo')" msg="Phone Number is required"></f-error>
                <f-error [err]="UserRoleDetails.pattern('phoneNo')" msg="Only numerical values are accepted "></f-error>
                <f-error [err]="UserRoleDetails.minlength('phoneNo')" msg="Enter a minimum of 7 digits"></f-error>

            </div>
        </div>
        <div class="formgrid text-center bc">

            <button pButton pRipple type="button" label=" Update" icon="pi pi-check"
                class="p-button-raised p-button-success" (click)="saveUserRole()" [disabled]="sysService.loading">

                <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
            </button>
        </div>
    </div>
</form>