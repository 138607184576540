<form [formGroup]="billing.form">
  <div class="card">
    <div class="grid fluid">
      <div class="field col-12 md:col-12 lg:col-4">
        <label for="Title"> Billing Name <span class="validation-star">*</span> </label>
        <input type="text" pInputText id="Title" maxlength="150" formControlName="name" pAutoFocus [autofocus]="true"
          autofocus />
        <f-error [err]="billing.required('name')" msg="Billing Name is required"></f-error>
      </div>
      <div class="field col-12 md:col-6  lg:col-4">
        <label>Phone Number <span class="validation-star">*</span></label>
        <input pInputText maxlength="18" formControlName="phone" type="text">
        <f-error [err]="billing.required('phone')" msg="Phone Number is required"></f-error>
        <f-error [err]="billing.pattern('phone')" msg="Only numerical values are accepted "></f-error>
        <f-error [err]="billing.minlength('phone')" msg="Enter a minimum of 7 digits"></f-error>
      </div>
      <div class="field col-12 md:col-12 lg:col-4">
        <label for="Title">Company </label>
        <input type="text" pInputText id="Title" maxlength="50" formControlName="company" autofocus />
      </div>
      <div class="field col-12 md:col-12 lg:col-4">
        <label for="Title">Tax No </label>
        <input type="text" pInputText id="Title" maxlength="50" formControlName="taxNo" autofocus />
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <label>Country <span class="validation-star">*</span></label>
        <p-autoComplete formControlName="countryId" [suggestions]="countryLookup" (onSelect)="stateAreaOnChange($event)"
          (completeMethod)="filterCountry($event)" forceSelection="true" field="name" ariaLabel="Country"
          [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400">
        </p-autoComplete>
        <f-error [err]="billing.required('countryId')" msg="Country is required"></f-error>
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <label>State </label>
        <p-autoComplete formControlName="stateId" [suggestions]="stateLookup" (completeMethod)="filterState($event)"
          forceSelection="true" field="name" ariaLabel="Country" [dropdown]="true" [autoHighlight]="true"
          showEmptyMessage="true" delay="400">
        </p-autoComplete>
      </div>
      <div class="field col-12 md:col-12 lg:col-4">
        <label for="Title">City </label>
        <input type="text" pInputText id="Title" maxlength="50" formControlName="city" autofocus />
      </div>
      <div class="field col-12 md:col-12 lg:col-4">
        <label for="Title">Zip <span class="validation-star">*</span> </label>
        <input type="text" pInputText id="Title" maxlength="12" formControlName="zip" autofocus />
        <f-error [err]="billing.required('zip')" msg="Zip is required"></f-error>
        <f-error [err]="billing.pattern('zip')" msg=" Only alphanumeric characters are accepted"></f-error>
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <label for="email">Email Address <span class="validation-star">*</span></label>
        <input id="email" type="text" maxlength="100" pInputText formControlName="email" required />
        <f-error [err]="billing.required('email')" msg="Email Address is required "></f-error>
        <f-error [err]="billing.pattern('email')"
          msg="This e-mail address is not valid. Valid records include to user@somehost.somedomain. And emails only accept lowercase letters.">
        </f-error>
      </div>
      <div class="field col-12 md:col-12 lg:col-4">
        <label for="Title">Address </label>
        <textarea [rows]="3" [cols]="39" maxlength="500" class="p-inputtextarea p-inputtext
          p-component p-element" formControlName="address" pInputTextarea></textarea>
      </div>
    </div>
    <div class="formgrid text-center bc">
      <button pButton pRipple type="button" label="Update" icon="pi pi-check" class="p-button-raised p-button-success"
        (click)="save();"></button>
    </div>
  </div>
</form>