import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormDefault, Model, SysService, View, ViewFactory } from '@app/_common';
@Component({
  selector: 'app-desktop-client',
  templateUrl: './desktop-clint.component.html',
  styleUrls: ['./desktop-clint.component.css']
})
export class DesktopClientComponent {
  private desktopClientView: View = this.viewFactory.newView();
  // Inject the necessary services and dependencies in the constructor
  constructor(
    public sysService: SysService, // make sure to import SysService from the correct location
    private formBuilder: UntypedFormBuilder,
    private viewFactory: ViewFactory
  ) { }
  ngOnInit(): void {
    this.scrollHeight()
    this.detectZoomChange()

    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 400));

    // Call the list method of the desktopClientView to load the data from the specified JSON file
    this.desktopClientView.of(this.desktopClientModel()).list('assets/data/desktop-client.json');
  }
  add() {
    this.desktopClientView.toNew()
  }
  // Getter method to access the desktopClientView's model
  get desktop() {
    return this.desktopClientView.model;
  }
  // Create and return a new Model instance with the necessary form fields and validations
  private desktopClientModel() {
    const formDefault = new FormDefault([], []); // Define the default form values and error messages and Create a new Model instance with the form group and validation rules
    return new Model(this.formBuilder.group({
      id: [],
      system: [''],
      architecture: [''],
      package: [''],
      size: [''],
      installer: [''],
      url: [''],
      comments: ['']
    },
      {
        validators: [] // Add any custom validators here
      }), this.sysService, formDefault, ''); // Pass in the necessary services and dependencies
  }
  heightTable: any;
  debounceTimeout: any;
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 300) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 300) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  detectZoomChange() {
    this.scrollHeight();
  }
}