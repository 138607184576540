
import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model } from '@app/_common';
import { environment } from '@environments/environment';
import { LazyLoadEvent } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';


@Component({
  selector: 'app-report-viewer',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.css']
})
export class ReportViewerComponent {
  private viewerView: View = this.viewFactory.newView();
  countryLookup: any[];
  siuid: any;
  reportData: any;



  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public config: DynamicDialogConfig,
    private viewFactory: ViewFactory) {
  }




  ngOnInit() {
    this.viewerView.of(this.viewerModel(), false);

    if (this.config?.data?.popUp) {
      this.siuid = this.config?.data?.siuid;
      this.reportData = this.config?.data?.report;


    }

    if (!this.config?.data?.popUp) {
      this.route.queryParams.subscribe(params => {
        this.siuid = params.siuid;
        this.reportData = this.config?.data?.report;

      });
    }

  }



  get viewer() {
    return this.viewerView.model;
  }



  private viewerModel() {
    const formDefault = new FormDefault([], []);
    return new Model(this.formBuilder.group({

    }), this.sysService, formDefault, '');
  }

}
