import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { ToolbarModule } from 'primeng/toolbar';
import { DropdownModule } from 'primeng/dropdown';
import { StateRoutingModule } from './state-routing.module';
import { StateComponent } from './state.component';
import { SharedModule } from '../_common/shared.module';
import {AutoCompleteModule} from 'primeng/autocomplete';
@NgModule({
  declarations: [
    StateComponent
  ],
  imports: [
    CommonModule,
    FormsModule, 
    ReactiveFormsModule ,
    TableModule,
    InputTextModule,
    DialogModule,
    ToolbarModule,
    ConfirmDialogModule,
    InputNumberModule,
    InputTextareaModule,
    RadioButtonModule,
    DropdownModule,
    AutoCompleteModule,
    StateRoutingModule,
    SharedModule
  ],
   providers: [ ]
})
export class StateModule { }
