import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Validators, FormBuilder } from "@angular/forms";
import { StaticService, SysService, FormDefault, ViewFactory, ViewAction } from "@app/_common";
import { Model, View } from "@app/_common/";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationService, LazyLoadEvent, MenuItem } from "primeng/api";
import { AppConstants } from "@app/_config";
import { DatePipe } from "@angular/common";
import { Hl7Service } from "@app/_common/services/hl7.service";
import { CANCELLED } from "dns";
import { environment } from "@environments/environment";
@Component({

  selector: 'app-hl7',
  templateUrl: './hl7.component.html',
  styleUrls: ['./hl7.component.scss'],
  providers: [DatePipe]
})
export class Hl7Component implements OnInit, ViewAction {
  @Input() errFlag: boolean = false;
  @ViewChild('calendarRef') calendar: any;

  private hl7View: View = this.viewFactory.newView();
  private hisHl7View: View = this.viewFactory.newView();
  smartFilter: any;
  heightTable: any;
  debounceTimeout: any;
  filterSearch = false;
  items: MenuItem[];
  hl7Data: any
  dateRange;
  loadingData: boolean = false;
  filterByStudyStatusCode: any = 1;
  StudyCountDetails: any;
  calendarSelected = false;
  selectedRange: Date[];
  saveButtonLabel: string = 'Save';
  countryLookup: any[];
  visible: boolean;
  hisDetails: boolean;
  hl7Show: any;
  modalityLookup: any[];
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    public hl7Service: Hl7Service,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory,
    private datePipe: DatePipe,

    private confirmationService: ConfirmationService,

  ) { }
  ngOnInit() {
    this.hl7View.of(this.hl7Model());
    this.hisHl7View.of(this.hisHl7Model());
    this.smartFilter = 2;
    this.hl7Show = environment.Hl7;
    this.scrollHeight()
    this.detectZoomChange()

    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 200));

  }
  detectZoomChange() {
    this.scrollHeight();
  }

  get hl7() {
    return this.hl7View.model;
  }
  get hisHl7() {
    return this.hisHl7View.model;

  }

  private hl7Model() {
    const formDefault = new FormDefault(["status"], [1]);
    return new Model(
      this.formBuilder.group({
        id: [''],
        mrn: [''],
        patName: [''],
        age: [''],
        sex: [''],
        type: [''],
        procedureCode: [''],
        procedureName: [''],
        orderedDate: [''],
        status: [''],
        refPhysician: [''],
        actions: [''],
        dob: [''],
        visitNo: [''],
        visitDate: [''],
        consultingDoc: [''],
        refDoc: [''],
        speciality: [''],
        ward: [''],
        bedNo: [''],
        priority: [''],
        clinicalInfo: [''],
        diagnosis: [''],
        allergy: [''],
        orderId: [],
        equipmentId: [],
        modalityId: [],
        hisOrderId: [],
        ageSex: [],
        EquipmentId: [],
        smartFilter: [],
        modalityCode: [],
        cancelRemarks: [''],
        equipment: [''],
        accessionNo: ['']
      }),
      this.sysService,
      formDefault,
      ""
    );
  }
  private hisHl7Model() {
    const formDefault = new FormDefault(["status"], [1]);
    return new Model(
      this.formBuilder.group({
        equipmentId: [],
        hisOrderId: [],
        orderId: [],
        cancelRemarks: [''],
        status: ['']
      }),
      this.sysService,
      formDefault,
      ""
    );
  }
  add() {

    this.hl7View.toNew();

  }



  edit(id: any) {
    this.hl7View.getData(`/his/${id}`).then(data => {
      this.hl7Data = data[0]

      this.hl7.set("patName", data[0].patName)
      if (data[0].age && data[0].sex) {
        this.hl7.set("ageSex", data[0].age + "/" + data[0].sex);
      } else if (data[0].age) {
        this.hl7.set("ageSex", data[0].age);
      } else if (data[0].sex) {
        this.hl7.set("ageSex", data[0].sex);
      } else {
        // Handle the case where both age and sex are not present
        this.hl7.set("ageSex", "N/A"); // or any other default value
      }
      this.hl7.set("visitDate", data[0].visitDate)
      this.hl7.set("refDoc", data[0].refDoc)
      this.hl7.set("allergy", data[0].allergy)
      this.hl7.set("modalityId", data[0].modalityId)
      this.hl7.set("EquipmentId", data[0].EquipmentId)
      this.hl7.set("mrn", data[0].mrn)
      this.hl7.set("bedNo", data[0].bedNo)
      this.hl7.set("dob", data[0].dob)
      this.hl7.set("type", data[0].type)
      this.hl7.set("visitDate", data[0].visitDate)
      this.hl7.set("consultingDoc", data[0].consultingDoc)
      this.hl7.set("refDoc", data[0].refDoc)
      this.hl7.set("visitNo", data[0].visitNo)
      this.hl7.set("procedureName", data[0].procedureName)
      this.hl7.set("speciality", data[0].speciality)
      this.hl7.set("ward", data[0].ward)
      this.hl7.set("priority", data[0].priority)
      this.hl7.set("allergy", data[0].allergy)
      this.hl7.set("clinicalInfo", data[0].clinicalInfo)
      this.hl7.set("diagnosis", data[0].diagnosis)
      this.hl7.set("modalityCode", data[0].modalityCode)
      this.hl7.set("cancelRemarks", data[0].cancelRemarks)
      this.hl7.set("equipment", data[0].equipment)
      this.hl7.set("status", data[0].status)


      this.visible = true

    })

  }

  list(id: any) {
    this.listView(false);
  }
  async listhl7Lazy(event?: LazyLoadEvent, search: boolean = false) {

    this.hl7.setParam("mrn", event?.filters?.mrn?.value);
    this.hl7.setParam("patName", event?.filters?.patName?.value);
    this.hl7.setParam("sex", event?.filters?.sex?.value);
    this.hl7.setParam("type", event?.filters?.type?.value);
    this.hl7.setParam("status", event?.filters?.status?.value);
    this.hl7.setParam("accessionNo", event?.filters?.accessionNo?.value);
    this.hl7.setParam("refPhysician", event?.filters?.refPhysician?.value);

    if (this.smartFilter === null) {
      this.hl7.setParam("date", this.dateRange)



    } else {
      this.hl7.setParam("smartFilter", this.smartFilter);


    }

    this.hl7View.toListLazyFetch(`/his`, search, event);
  }
  listView(search: boolean = false) {
    this.hl7View.toListLazyView(`/his`, search);
  }
  view(id: any) {
    this.hl7View.toViewFetch(`/his/${id}`, id);
  }

  hldialogue() {

    this.visible = true;

  }
  statusSchedular: boolean = true
  schedulerData(desktop: any, schedulerCancel) {
    this.hl7.reset()
    this.errFlag = false;

    if (schedulerCancel) {
      this.statusSchedular = true
    } else {
      this.statusSchedular = false

    }
    this.hl7Data = desktop

    this.hl7.set("patName", desktop.patName)
    if (desktop.age && desktop.sex) {
      this.hl7.set("ageSex", desktop.age + "/" + desktop.sex);
    } else if (desktop.age) {
      this.hl7.set("ageSex", desktop.age);
    } else if (desktop.sex) {
      this.hl7.set("ageSex", desktop.sex);
    } else {
      this.hl7.set("ageSex", "N/A");
    }
    this.hl7.set("orderedDate", desktop.orderedDate)
    this.hl7.set("refPhysician", desktop.refPhysician)
    this.hl7.set("allergy", desktop.allergy)
    this.hl7.set("modalityId", desktop.modalityId)
    this.hl7.set("EquipmentId", desktop.EquipmentId)
    this.hl7.set("procedureName", desktop.procedureName)
    this.hisHl7.set("orderId", desktop.id)
    this.hl7.set("modalityCode", desktop.modalityCode)

    this.hl7.set("cancelRemarks", null)
    this.hisDetails = true;

  }

  filterModality(event: any) {
    this.errFlag = false;
    this.sysService.clear()
    let id = this.hl7.get("modalityId");
    if (!id) {
      id = '';
    }
    this.hl7View.getData(`/equipment/lookup?filter=${event.query}&modalityId=${id}`).then(data => {
      this.modalityLookup =
        data;
    });
  }
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 300) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 300) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }
  hisSave() {
    this.errFlag = false;
    let id = this.hisHl7.get("orderId");

    if (this.statusSchedular) {
      if (!this.hl7.isEmpty("EquipmentId")) {
        let EquipmentId = this.hl7.get("EquipmentId")?.id;
        this.hisHl7.set("equipmentId", EquipmentId);
        this.hisHl7View.toSave('/his/schedule', 'id').then(data => {
          if (data.status === "FAILED") {
            this.sysService.error(data.message);
          } else {
            this.sysService.success(data.message);
          }
          this.hl7?.list?.forEach((x) => {
            if (x.id === id) {
              x.status = 6;
            }
          });
          this.hisDetails = false;
        });
      } else {
        this.errFlag = true;
        this.sysService.error(AppConstants.validationFailed);
      }
    } else {
      if (!this.hl7.isEmpty("cancelRemarks")) {
        let cancelRemarks = this.hl7.get("cancelRemarks");
        this.hisHl7.set("cancelRemarks", cancelRemarks);
        this.hisHl7.set("status", "CANCELLED")
        this.hisHl7View.toSave('/his/scheduler/cancel-order', 'id').then(data => {
          if (data.status === "FAILED") {
            this.sysService.error(data.message);
          } else {
            this.sysService.success(data.message);
          }
          this.hl7?.list?.forEach((x) => {
            if (x.id === id) {
              x.status = 12;
            }
          });
          this.hisDetails = false;
        });
      } else {
        this.errFlag = true;
        this.sysService.error(AppConstants.validationFailed);
      }
    }
  }

  hisCancel() {
    this.hisDetails = false;
  }
  dayByFilter(number: number, dt, event?: LazyLoadEvent) {
    this.sysService.clear()
    this.dateRange = null;
    this.smartFilter = number;
    this.changesCalendarClear(dt);
    this.listhl7Lazy(dt);
  }
  changesCalendarClear(dt) {

    this.selectedRange = [];
    this.calendarSelected = false;
    this.dateRange = null;
    this.hl7.setParam('date', null)
    this.listhl7Lazy(dt);


  }
  removeMessage(event: any) {
    this.errFlag = false
    this.sysService.clear();
  }
  hisReload(dt) {
    this.selectedRange = [];
    this.calendarSelected = false;
    this.smartFilter = 2
    this.hl7.setParam('date', null)
    this.listhl7Lazy(dt)
  }
  calenedarChangeDate(event, dt) {
    this.dateRange = '';
    if (this.selectedRange) {
      if (this.selectedRange) {
        this.smartFilter = null;
      }
      const startDate = this.datePipe.transform(this.selectedRange[0], 'dd/MM/yyyy');
      const endDate = this.datePipe.transform(this.selectedRange[1], 'dd/MM/yyyy');
      this.dateRange = `${startDate}-${endDate}`;
      this.listhl7Lazy(dt);
    }
    this.calendar.hideOverlay();
  }
  reload(dt) {
    this.calendarSelected
    // this.sysService.loadWalletBalance()
    if (this.selectedRange?.length > 0) {
      this.smartFilter = null;
    } else {
      this.smartFilter = this.smartFilter;
    }
    this.listhl7Lazy(dt);

  }
  // filterByStudyStatus(number, dt, search: boolean = false, event?: LazyLoadEvent) {
  //   this.sysService.clear();
  //   this.filterSearch = true;
  //   this.listhl7Lazy(dt);
  // }
}