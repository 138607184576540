<div *ngIf="!sysService.isPermission()">
    <not-found></not-found>
</div>
<div *ngIf="sysService.isPermission()">
    <p-toolbar>
        <div class="p-toolbar-group-start">
            <f-nav first="/centre-user" label="Secondary Users" [model]="centreUsers"> </f-nav>
        </div>
    </p-toolbar>
    <!-- ----------------------------------Add Table -------------------- -->
    <form [formGroup]="centreUsers.form">
        <div *ngIf="centreUsers.isform || centreUsers.isView()" class="card">
            <div class="grid">
                <div class="field col-12 md:col-6 lg:col-6">
                    <label>Name <span class="validation-star">*</span></label>
                    <input pInputText maxlength="150" formControlName="name" type="text" pAutoFocus autofocus="true">
                    <f-error [err]="centreUsers.required('name')" msg=" Name is
                    required"></f-error>
                </div>
                <div class="field col-12 md:col-6 lg:col-6">
                    <label for="EmailAddress">Email Address <span class="validation-star">*</span></label>
                    <input id="EmailAddress" maxlength="100" type="text" pInputText formControlName="email" required
                        autocomplete="off" />
                    <f-error [err]="centreUsers.required('email')" msg="Email Address is required "></f-error>
                    <f-error [err]="centreUsers.pattern('email')"
                        msg="This e-mail address is not valid. Valid records include to user@somehost.somedomain. And emails only accept lowercase letters.">
                    </f-error>
                </div>
                <div class="field col-12 md:col-6 lg:col-6">
                    <label>User Role<span class="validation-star">*</span></label>
                    <p-dropdown inputId="userChildRole" placeholder="Select" formControlName="userChildRole"
                        [options]="staticService.centerUser" required="true" [readonly]="this.centreUsers.isEdit()">
                    </p-dropdown>
                    <f-error [err]="centreUsers.required('userChildRole')" msg="User Role is required"></f-error>
                </div>
                <div class="field col-12 md:col-6 lg:col-6">
                    <label>Phone Number <span class="validation-star">*</span></label>
                    <input pInputText maxlength="18" formControlName="phoneNo" type="text">
                    <f-error [err]="centreUsers.required('phoneNo')" msg="Phone Number is required"></f-error>
                    <f-error [err]="centreUsers.pattern('phoneNo')" msg="Numbers only required"></f-error>
                    <f-error [err]="centreUsers.minlength('phoneNo')" msg="Enter a minimum of 7 digits"></f-error>
                </div>
                <div class="field col-12 md:col-6 lg:col-6">
                    <label>Status </label>
                    <p-dropdown formControlName="status" inputId="status" placeholder="Select"
                        [options]="staticService.status" required="true">
                    </p-dropdown>
                </div>
            </div>
            <div class="formgrid text-center bc">
                <button pButton pRipple type="button" label="Cancel" icon="pi pi-times"
                    class="p-button-outlined p-button-info" (click)="listView(centreUsers.isEmptyList());"></button>
                <button pButton pRipple type="button" [label]="saveButtonLabel " icon="pi pi-check"
                    class="p-button-raised p-button-success" (click)="saveCentreUsers()" [disabled]="sysService.loading"
                    *ngIf="centreUsers.isForm()">
                    <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                </button>
                <button pButton pRipple type="button" label=" Save & Add" icon="pi pi-plus"
                    class="p-button-raised p-button-info" (click)="saveCentreUsers(true)"
                    [disabled]="sysService.loading" *ngIf="centreUsers.isNew()">
                    <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                </button>
            </div>
        </div>
    </form>
    <!---------------------------------------------- table list --------------------------------------------- -->
    <div class="table-responsive" [style.display]="centreUsers.islist? 'block':'none'">
        <p-table #dt [value]="centreUsers.list" [totalRecords]="centreUsers.total" [lazy]="true"
            class="study-list-class" [scrollHeight]="heightTable" (onLazyLoad)="listCentreUsersLazy($event)"
            dataKey="id" [(selection)]="centreUsers.selection" selectionMode="multiple" [loading]="sysService.loading"
            filterDelay="400" currentPageReportTemplate="{first} to {last} of {totalRecords}"
            [rowsPerPageOptions]="[25, 50]" [rowHover]="true" styleClass="p-datatable-sm" [rows]="sysService.rows"
            [rows]="25" [paginator]="true" [showCurrentPageReport]="true" alwaysShow="false" paginatorPosition="bottom"
            styleClass="p-datatable-sm p-datatable-gridlines"
            [globalFilterFields]="['name','email','phoneNo','userChildRole','status']">
            <ng-template pTemplate="caption">
                <div class="grid">
                    <div class="ml-2">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" maxlength="150" [(ngModel)]="centreUsers.searchTxt" pAutoFocus
                                [autofocus]="true" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search..." />
                        </span>
                    </div>

                    &nbsp;&nbsp;&nbsp; <div class="mt-2">
                        <button pButton pRipple type="button" label="Delete" icon="pi pi-trash"
                            class="p-button-outlined p-button-danger" (click)="deleteCentreUserss()"
                            *ngIf="!centreUsers.isEmptyList() && centreUsers.selection.length > 0"></button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="paginatorleft">
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th pSortableColumn="name" style="min-width:117px;">
                        Name
                        <p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="email">
                        Email Address
                        <p-sortIcon field="email"></p-sortIcon>
                    </th>
                    <th pSortableColumn="phoneNo">
                        Phone Number
                        <p-sortIcon field="phoneNo"></p-sortIcon>
                    </th>
                    <th pSortableColumn="userChildRole">
                        User Role
                        <p-sortIcon field="userChildRole"></p-sortIcon>
                    </th>
                    <th pSortableColumn="status">
                        Status
                        <p-sortIcon field="status"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-centreUsers>
                <tr [ngClass]="{'ui-state-highlight': chk.checked}">
                    <td>
                        <p-tableCheckbox #chk [value]="centreUsers"></p-tableCheckbox>
                    </td>
                    <td><i class="pi pi-file-edit link-study" (click)="edit(centreUsers.id)" title="edit"> </i>
                        {{centreUsers.name}}
                    </td>
                    <td class="bdy-part">{{centreUsers.email}}</td>
                    <td>{{centreUsers.phoneNo}}</td>
                    <td><span *ngIf="centreUsers.userChildRole ===1">Assigner</span>
                        <span *ngIf="centreUsers.userChildRole ===2">Approver </span>
                        <span *ngIf="centreUsers.userChildRole ===3">Technician </span>
                        <span *ngIf="centreUsers.userChildRole ===4">Physician </span>
                    </td>
                    <td><span *ngIf="centreUsers.status ===0" class="text-danger">Inactive </span>
                        <span *ngIf="centreUsers.status ===1" class="text-success">Active </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td class="norecordstudy" [attr.colspan]="6">No records found</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>