import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model, ViewAction } from '@app/_common';
import { AppConstants } from '@app/_config';
import { LazyLoadEvent } from 'primeng/api';
@Component({
  selector: 'app-revenue',
  templateUrl: './revenue.component.html',
  styleUrls: ['./revenue.component.css']
})
// *********************************************This  payables for center for more details check billing component *************************************************
// ***************************************************************************************************************************************************************
export class RevenueComponent implements OnInit, ViewAction {
  private revenueView: View = this.viewFactory.newView();
  private dataView: View = this.viewFactory.newView();
  revenueYearList: any[];
  radLookup: any[];
  cenLookup: any[];
  modLookup: any[];
  userLookup: any[];
  totalAmount: any;
  search: boolean;
  totalCharge: any;
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory) {
  }
  ngOnInit() {
    this.revenueView.of(this.revenueModel());
    this.revenueView.of(this.revenueModel()).list('/revenue/radiologist-payables');
    this.revenueYear()
  }
  get revenue() { return this.revenueView.model; }
  private revenueModel() {
    const formDefault = new FormDefault([], []);
    return new Model(this.formBuilder.group({
      month: [],
      year: [],
      name: [''],
      radiologistName: [''],
      centreName: [''],
      modality: [''],
      cenId: [],
      userId: [],
      radId: [],
      paymentStatus: [],
    }), this.sysService, formDefault, '');
  }
  add() {
    this.revenueView.toNew();
  }
  edit(id: any) {
    this.revenueView.toEditFetch(`/revenue/radiologist-payables/${id}`, id);
  }
  list(id: any) {
    this.listView(false);
  }
  async listratecardListLazy(event?: LazyLoadEvent, search: boolean = false) {
    this.revenue.setParam("cenId", this.revenue.get("centreName")?.name)
    this.revenue.setParam("modality", this.revenue.get("modality")?.name)
    this.revenue.setParam("month", this.revenue.get("month"))
    this.revenue.setParam("year", this.revenue.get("year"))
    this.revenue.setParam("radId", this.revenue.get('radiologistName')?.id)
    this.revenue.setParam("paymentStatus", this.revenue.get('paymentStatus'))
    this.revenue.setParam("userId", this.revenue.get('userId')?.id)
    this.revenueView.toListLazyFetch(`/revenue/radiologist-payables`, this.search, event).then(data => {
      this.totalAmount = data?.totalAmount
    })
    this.search = false;
  }
  filteruser(event: any) {
    this.revenueView.getData(`/radiologist/radiologist-lookup?filter=${event.query}`).then(data => {
      this.userLookup =
        data;
    });
  }
  listView(search: boolean = false) {
    this.revenueView.toListLazyView(`/revenue/radiologist-payables`, search);
  }
  view(id: any) {
    this.revenueView.toViewFetch(`/revenue/radiologist-payables/${id}`, id);
  }
  searchRevenue() {

    this.search = true;
  }
  export(revenue: any) {
    this.searchRevenue()
    const rangeDate = String(this.revenue.get("date"));
    const startDate = new Date(rangeDate.split(",")[0]);
    const endDate = new Date(rangeDate.split(",")[1]);
    const formattedStartDate = `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() +
      1).toString().padStart(2, '0')}/${startDate.getFullYear()}`;
    const formattedEndDate = `${endDate.getDate().toString().padStart(2, '0')}/${(endDate.getMonth() +
      1).toString().padStart(2, '0')}/${endDate.getFullYear()}`;
    const date = `${formattedStartDate}-${formattedEndDate}`;
    if (!this.revenueView.isEmptyList('date')) {
      this.revenue.setParam("date", date)
    }
    this.revenue.setParam("centreName", this.revenue.get("centreName")?.id)
    this.revenue.setParam("userId", this.revenue.get("userId")?.id)
    this.revenue.setParam("modality", this.revenue.get("modality")?.name)
    this.revenue.setParam("month", this.revenue.get("month"))
    this.revenue.setParam("year", this.revenue.get("year"))
    const pdfFilename = !this.revenueView.isEmptyList('date') ? `revenue-${date}.pdf` : 'revenue.pdf';
    this.revenueView.download(`/revenue/download-revenue-pdf?&isReceivable=false`, pdfFilename);
  }
  revenueYear() {
    this.revenueView.getData(`/reports/year`).then(data => { this.revenueYearList = data; })
  }
  searchStudy() {
  }
  filterRad(event: any) {
    this.revenueView.getData(`/radiologist/radiologist-lookup?filter=${event.query}`).then(data => {
      this.radLookup =
        data;
    });
  }
  filterCen(event: any) {
    this.revenueView.getData(`/centre/centre-lookup?filter=${event.query}`).then(data => {
      this.cenLookup =
        data;
    });
  }
  filterMod(event: any) {
    this.revenueView.getData(`/modality/modality-lookup?filter=${event.query}`).then(data => {
      this.modLookup =
        data;
    });
  }
  resetRevenue() {
    this.revenue.reset();
    this.sysService.clear();
    this.search = false
  }
  downloadExcel(revenue: any) {
    this.searchRevenue()
    const rangeDate = String(this.revenue.get("date"));
    const startDate = new Date(rangeDate.split(",")[0]);
    const endDate = new Date(rangeDate.split(",")[1]);
    const formattedStartDate = `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() +
      1).toString().padStart(2, '0')}/${startDate.getFullYear()}`;
    const formattedEndDate = `${endDate.getDate().toString().padStart(2, '0')}/${(endDate.getMonth() +
      1).toString().padStart(2, '0')}/${endDate.getFullYear()}`;
    const date = `${formattedStartDate}-${formattedEndDate}`;
    this.revenue.setParam("date", date)
    // }
    this.revenue.setParam("centreName", this.revenue.get("centreName")?.id)
    this.revenue.setParam("userId", this.revenue.get("userId")?.id)
    this.revenue.setParam("radId", this.revenue.get("radId")?.id)
    this.revenue.setParam("modality", this.revenue.get("modality")?.name)
    this.revenue.setParam("month", this.revenue.get("month"))
    this.revenue.setParam("year", this.revenue.get("year"))
    const pdfFilename = !this.revenueView.isEmptyList('date') ? `Payable-${date}.xls` : 'Payable.xls';
    this.revenueView.download(`/revenue/download-revenue-excel?isReceivable=false`, pdfFilename);
  }
  selectedValue() {
    let totalCharge = 0;
    for (const item of this.revenue.selection) {
      totalCharge += item.charge;
    }
    this.totalCharge = totalCharge;
  }
}