import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model } from '@app/_common';
import { AppConstants } from '@app/_config/app.constants';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  private forgetView: View = this.viewFactory.newView();
  constructor(public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: UntypedFormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory,
    private router: Router,
  ) { }
  ngOnInit(): void {
    this.forgetView.of(this.forgetModel());
  }
  get forget() {
    return this.forgetView.model;
  }
  private forgetModel() {
    const formDefault = new FormDefault([], []);
    return new Model(this.formBuilder.group({
      id: [],
      email: ['', [Validators.required, Validators.pattern(AppConstants.regexEmail)]],
    },
      {
        validators: []
      }), this.sysService, formDefault, '');
  }
  submit() {
    if (this.forgetView.isValid()) {
      this.forgetView.setParam("email", this.forget.get('email'))

      this.forgetView.toSaveNav('/mail/forgotpassword', "id", "login", "Your Account reset link has been sent to your registered email.", "Your registration has failed.");
    }
  }
  backSign() {
    this.router.navigateByUrl('/');
  }
  navHome() {
    this.sysService.navigate(['/']);
  }
}
