import { AbstractControl, FormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';


export const passwordMatch: any = (group: UntypedFormGroup) => {
  const pass = group.controls.password.value;
  const confirmPass = group.controls.confirmPassword.value;
  return pass === confirmPass ? null : { notSame: true };
}

export const minValue: any = (group: UntypedFormGroup) => {
  const val = group.controls.AmountToTransfer.value;
  return val > 0.00 ? null : { minValue: true };
}

export const miniValue: any = (group: UntypedFormGroup) => {
  const val = group.controls.Amount.value;
  return val > 0.00 ? null : { miniValue: true };
}

export const noWhitespaceValidator: any = (control: FormControl) => {
  // console.log((control.value || '').trim());
  return (control.value || '').trim().length? null : { whitespace: true };  

}


export function noPlusSignValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value && (control.value as string).includes('+')) {
      return { 'noPlusSign': true };
    }
    return null;
  };
}




