<page-loading [loading]="!ckeditorLoading"></page-loading>
<div class="buttonClass" *ngIf="splitter?.viewerSize[0] !== 70 && rightEditor">
   <button
      *ngIf="sysService.isRad() && rightEditor && splitter?.viewerSize[0] !== 70 && splitter?.viewerSize[0] !== -60"
      pButton pRipple type="button" icon="pi pi-window-minimize" (click)="toggleViewer(report)"
      [title]="'Minimize Viewer'" class="p-button-raised p-button-text p-button-info p-button-lg icon-move">
   </button>
   <div *ngIf="!viewerSize" class="viwesize">
      <p>
         {{ report?.view?.patName ?? '' }}
         &nbsp;{{ report?.view?.patId ?? '' }}&nbsp;
         {{ sysService.toDate(report?.view?.studyDate) | date:'dd/MM/yyyy HH:mm a' || '' }}
      </p>
   </div>
</div>
<!-- <p-toolbar>
      <ng-template pTemplate="left">
         <f-nav [home]="false" first="/Report-user" [edit]="capitalizeFirstLetter(report?.view?.patName ?? '') +' '+
                 (report?.view?.patId ? ' [' + report.view.patId + '] ' : '') +
                 ((sysService.toDate(report?.view?.studyDate) | date:'dd/MM/yyyy HH:mm a') ?? '')" [model]="report">
         </f-nav>

      </ng-template>
      <ng-template pTemplate="right">
      </ng-template>
   </p-toolbar> -->
<form [formGroup]="report.form" *ngIf="viewerSize">
   <div class="table-responsive">





      <ng-template #tooltipContent>
         <div class="flex align-items-center">
            <img src="https://primefaces.org/cdn/primeng/images/primeng.svg" height="20" class="mr-2" />
            <span>
               <b>PrimeNG</b> rocks!
            </span>
         </div>
      </ng-template>
      <!-- ------------------------end -------------------------  -->
      <!-- <p-panel #panelRef   [toggleable]="true"  iconPos="start"  [collapsed]="true">
   <ng-template pTemplate="header">
      <div class=" flex justify-content-between ">
          <p-avatar  [pTooltip]="tooltipContent" 
              image="../../assets/images/userlogo.png" 
              size="large" 
              shape="circle" />&nbsp; 
          <span class="font-bold"> 
           <p> {{ report?.view?.patName }}</p>
         </span>
      </div>
  </ng-template>

  <ng-template pTemplate="icons">

  
 </ng-template> -->



      <p-panel header="{{report?.view?.patName}}" [collapsed]="true" [toggleable]="true">

         <!-- ---------------------------------combintion tab -------------------------------------------- -->
         <div class="grid px-3 line-class" *ngIf="!rightEditor">
            <div class="flex col-12 md:col-6 lg:col-4">
               <p class="letter-Case">patient name: {{ report?.view?.patName }}</p>
            </div>
            <div class="flex col-12 md:col-6 lg:col-4">
               <p class="letter-Case">
                  modality / study desc: {{ report?.view?.modality }} / {{ report?.view?.studyDesc }}
                  <a *ngIf="report.get('studyDescModified')">({{ report?.view?.studyDescModified }})</a>
               </p>
            </div>
            <div class="flex col-12 md:col-6 lg:col-4">
               <p class="letter-Case" *ngIf="report?.view?.status === 2">
                  confirmed at: {{ sysService.toDate(report?.view?.reportDate) | date: 'dd-MM-yyyy hh:mm a' }}
               </p>
               <p class="letter-Case" *ngIf="report?.view?.status !== 2">
                  study date: {{ report?.view?.studyDate | date: 'dd/MM/yyyy HH:mm a' }}
               </p>
            </div>
            <div class="flex col-12 md:col-6 lg:col-4">
               <p class="letter-Case">patient Id: {{ report?.view?.patId }}</p>
            </div>
            <div class="flex col-12 md:col-6 lg:col-4">
               <p class="letter-Case">
                  age/sex: {{ report?.view?.patAge }}
                  {{ report?.view?.patAge && report?.view?.patSex ? ' / ' : '' }}
                  {{ report?.view?.patSex }}
               </p>
            </div>
            <div class="flex col-12 md:col-6 lg:col-4" *ngIf="report?.view?.status === 2">
               <p class="letter-Case" *ngIf="report?.view?.status !== 2">
                  confirmed at: {{ sysService.toDate(report?.view?.reportDate) | date: 'dd-MM-yyyy hh:mm a' }}
               </p>
               <p class="letter-Case" *ngIf="report?.view?.status === 2">
                  study date: {{ report?.view?.studyDate | date: 'dd/MM/yyyy HH:mm a' }}
               </p>
            </div>
         </div>
         <!-- ---------------------------------combintion end -------------------------------------------- -->
         <div class="grid px-3 line-class" *ngIf="rightEditor">
            <div class="flex col-12 md:col-6 lg:col-6">
               <p class="letter-Case">patient name: {{ report?.view?.patName }}</p>
            </div>
            <div class="flex col-12 md:col-6 lg:col-6">
               <p class="letter-Case">
                  modality / study desc: {{ report?.view?.modality }} / {{ report?.view?.studyDesc }}
                  <a *ngIf="report.get('studyDescModified')">({{ report?.view?.studyDescModified }})</a>
               </p>
            </div>
            <div class="flex col-12 md:col-6 lg:col-6">
               <p class="letter-Case" *ngIf="report?.view?.status === 2">
                  confirmed at: {{ sysService.toDate(report?.view?.reportDate) | date: 'dd-MM-yyyy hh:mm a' }}
               </p>
               <p class="letter-Case" *ngIf="report?.view?.status !== 2">
                  study date: {{ report?.view?.studyDate | date: 'dd/MM/yyyy HH:mm a' }}
               </p>
            </div>
            <div class="flex col-12 md:col-6 lg:col-6">
               <p class="letter-Case">patient Id: {{ report?.view?.patId }}</p>
            </div>
            <div class="flex col-12 md:col-6 lg:col-6">
               <p class="letter-Case">
                  age/sex: {{ report?.view?.patAge }}
                  {{ report?.view?.patAge && report?.view?.patSex ? ' / ' : '' }}
                  {{ report?.view?.patSex }}
               </p>
            </div>
            <div class="flex col-12 md:col-6 lg:col-6" *ngIf="report?.view?.status === 2">
               <p class="letter-Case" *ngIf="report?.view?.status !== 2">
                  confirmed at: {{ sysService.toDate(report?.view?.reportDate) | date: 'dd-MM-yyyy hh:mm a' }}
               </p>
               <p class="letter-Case" *ngIf="report?.view?.status === 2">
                  study date: {{ report?.view?.studyDate | date: 'dd/MM/yyyy HH:mm a' }}
               </p>
            </div>

            <div class="flex col-12 md:col-6 lg:col-6">
               <p *ngIf="sysService.getUser().userSettings.isTemplateDropdown " class="letter-Case">
                  Template : {{ report?.view?.reportTemplateId?.name ?? 'N/A' }}
               </p>
            </div>
            <div class="flex col-12 md:col-6 lg:col-6">
               <p class="letter-Case"> Rate : {{ report?.view?.charge ?? 'N/A' }} </p>
            </div>
            <div class="flex col-12 md:col-6 lg:col-6" *ngIf="report?.view?.paymentNote">
               <p class="letter-Case">
                  payment note :
                  <ng-container *ngIf="report?.view?.paymentNote?.length > 10">
                     {{ report.view.paymentNote.substring(0, 10) }}<i class="pi pi-eye payment"
                        [pTooltip]="report.view.paymentNote" tooltipPosition="left"></i>
                  </ng-container>
                  <ng-container *ngIf="!(report?.view?.paymentNote?.length > 10)">
                     {{ report?.view?.paymentNote || 'N/A' }}
                  </ng-container>
               </p>
            </div>


         </div>

      </p-panel>
      <!-- ------------------------------  right  button without combination  ------------------------------ -->

      <div class="grid p-2 pt-2" *ngIf="rightEditor && ckeditorLoading">



         <div
            *ngIf="report?.view?.reportingRadiologist && sysService.getUser().userSettings.isTemplateDropdown && report.get('isEditable' ,false)===true"
            class="field mb-0 col-12 md:col-3 lg:col-3 ">
            <span class="p-float-label">
               <p-autoComplete forceSelection="true" formControlName="reportTemplateId"
                  [suggestions]="reportTemplateLookup" (completeMethod)="filterReport($event)"
                  (onSelect)="templateId($event)" field="name" ariaLabel="bodyPart" [dropdown]="true"
                  [autoHighlight]="true" showEmptyMessage="true" delay="400" maxlength="50">
               </p-autoComplete>
               <label class="letter-Case">Template </label>
            </span>
         </div>
         <div class="flex col-12 md:col-6 lg:col-8 pt-0"
            *ngIf="report.get('isEditable') && report?.view?.reportingRadiologist">
            <div class="flex col-4 md:col-4 lg:col-3">
               <span class="p-float-label">
                  <input id="unitRate" type="text" formControlName="charge" [readonly]=" !report.get('customRate') "
                     size="10" maxlength="7" aria-describedby="rate-help" pInputText pattern="[0-9]+">
                  <label class="letter-Case">Rate </label>
               </span> &nbsp;
            </div>
            <div class="flex col-4 md:col-6 lg:col-4"
               *ngIf="report.get('customRate')==true && report?.view?.reportingRadiologist">
               <span class="p-float-label">
                  <textarea [rows]="1" [cols]="40" [readonly]="report.get('isEditable' ,true)===false"
                     class="p-inputtextarea p-inputtext p-component p-element" formControlName="paymentNote"
                     pInputTextarea maxlength="200" pInputTextarea></textarea>
                  <label class="letter-Case">Payment Note </label>
                  <div *ngIf="errFlag1 && report.isEmpty('paymentNote')">
                     <small class="p-error">Enter the payment note</small>
                  </div>
               </span>

            </div>

            <div class="flex col-4 md:col-4 lg:col-4"> <p-checkbox formControlName="customRate" [binary]="true"
                  (click)="customreset()" label="Custom Rate" [readonly]=" report.get('isEditable' ,true)===false"
                  inputId="binary" inputId="ny"> </p-checkbox> </div>
         </div>
         <!-- --------------------------------------end ------------------------------------------------- -->

         <div class=" py-0 col-12 md:col-4 lg:col-12 poss">
            <div class="left-button">
               <button pButton pRipple type="button"
                  *ngIf="(sysService.isRad() || sysService.isRadSub()) && report?.view?.technicianCount > 0 && report.get('status')===1"
                  class="p-button-secondary p-button-text p-0" pTooltip="Technician Comment" tooltipPosition="left"
                  (click)="technicianComment(report)" [disabled]="sysService.loading">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                  <img src="../../../assets/images/Open comment.png" alt="Weasis Icon"
                     style="width: 43px; height: 30px; "> <!-- Loading spinner -->
               </button>
               <p-splitButton class="rm-mr" pTooltip="Click dropdown for more options"
                  *ngIf="sysService.getUser().userSettings.isDownloadPdf"
                  [disabled]="sysService.loading || report.get('reportContent')==null"
                  [label]="report?.view?.isDownloadPdf && report?.view?.showDownload ? 'PDF' : 'Preview'"
                  [model]="items"
                  [icon]="report?.view?.isDownloadPdf && report?.view?.showDownload ? 'pi pi-download' : 'pi pi-eye'"
                  (onClick)="reportDownloadItextPreview(report)" [styleClass]="report?.view?.isDownloadPdf && report?.view?.showDownload
                   ? 'p-button-text p-button-raised p-button-success ' 
                   : 'p-button-text p-button-raised p-button-help '" />
               <button pButton pRipple type="button" icon="pi pi-download" title="Word Download" iconPos="right"
                  *ngIf="report?.view?.showDownload === true && report?.view?.isDownloadWord"
                  [label]="rightEditor ? '' : '&nbsp; Word '" class="p-button-success " (click)="wordDownloadBe(report)"
                  [disabled]="sysService.loading">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
               </button>&nbsp;

               <button pButton pRipple type="button" label="Re-edit " class="p-button-warning m-1 "
                  (click)="reEdit(report)" *ngIf="report?.view?.showEdit === true" icon="pi pi-pencil"
                  [disabled]="sysService.loading">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i> <!-- Loading spinner -->
               </button>
               <button pButton pRipple type="button" label="Edit " class="p-button-warning m-1"
                  *ngIf="report.get('showSaveForAdmin',false)===true && !editSave" (click)="adminForSave(true)"
                  icon="pi pi-pencil" [disabled]="sysService.loading">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i> <!-- Loading spinner -->
               </button>
               <button pButton pRipple type="button" label="Save " class="p-button-outlined p-button-info m-1 "
                  (click)="saveReport(report, 1)" *ngIf="editSave" [disabled]="sysService.loading">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i> <!-- Loading spinner -->
               </button>
               <!-- Button to save report,showConfirm is true only show  -->
               <button pButton pRipple type="button" label="Save " class="p-button-outlined p-button-info m-1 "
                  (click)="saveReport(report, 1)" [disabled]="sysService.loading"
                  *ngIf="report.get('showConfirm' ,false)===true ">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i> <!-- Loading spinner -->
               </button>
               <button pButton pRipple type="button" label="Confirm" icon="pi pi-check"
                  *ngIf="report.get('showConfirm' ,false)===true" class="p-button-raised p-button-success m-1 "
                  (click)="saveReport(report, 2)" [disabled]="sysService.loading">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
               </button>

               <button pButton pRipple type="button" [label]="rightEditor ? '' : 'Logs'" tooltipPosition="left"
                  pTooltip="Report Logs " icon="pi pi-list" *ngIf="report?.view?.isReportLog"
                  class="p-button-primary m-1" (click)="reportHist(report)" [disabled]="sysService.loading">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i> <!-- Loading spinner -->
               </button>

               <!-- <button pButton pRipple type="button" icon="pi pi-download" title="Word Download" iconPos="right"
                     *ngIf="report?.view?.showDownload === true && report?.view?.downloadDoc === 1"
                     [label]="rightEditor ? '' : '&nbsp; Download Word'" iconpos="right"
                     class="p-button-success webDwd m-1" (click)="wordDownload(editor)" [disabled]="sysService.loading">
                     <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                  </button>&nbsp; -->
               <!-- <button pButton pRipple type="button" icon="pi pi-download" title="Word Download" iconPos="right"
                     *ngIf="report?.view?.showDownload === true && report?.view?.downloadDoc === 1"
                     [label]="rightEditor ? '' : '&nbsp; Download Word '" iconpos="right"
                     class="p-button-success mobDwd m-1" (click)="wordDownloadForPhone(editor)"
                     [disabled]="sysService.loading">
                     <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                  </button> -->
               <!-- <button pButton pRipple type="button" icon="pi pi-download" title="PDF Download" iconPos="right"
                  *ngIf="report?.view?.isDownloadPdf=== true" label="PDF" iconpos="right" class="p-button-success m-1"
                  (click)="reportDownloadItextPreview(report)" [disabled]="sysService.loading">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
               </button> -->
               <!-- Button to approve report, shown only if configured to showApprovalBtn is true   -->
               <button pButton pRipple type="button" *ngIf="report.get('showApprovalBtn' ,false)===true" label="Approve"
                  class="p-button-raised p-button-help m-1 " (click)="saveReport(report, 6)"
                  [disabled]="sysService.loading">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i> <!-- Loading spinner -->
               </button>
               <!-- Button to reject report, shown only if configured to showApprovalBtn is true   -->
               <!-- Button to confirm the report, shownConfirm is true only show -->
               <button pButton pRipple type="button" *ngIf="report.get('showReject' ,false)===true " label="Reject"
                  class="p-button-raised p-button-danger m-1" (click)="rejectDialog()" [disabled]="sysService.loading">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i> <!-- Loading spinner -->
               </button>
               <!-- Button to add a technician comment, shown only for radiologists with technicians comment count > 0  -->
               <button pButton pRipple type="button"
                  *ngIf="(sysService.isRad() || sysService.isRadSub()) && report?.view?.technicianCount > 0 && report.get('status')!==1"
                  Tooltip="Technician Comment" tooltipPosition="left" class="p-button-secondary p-button-text p-0"
                  (click)="technicianComment(report)" [disabled]="sysService.loading">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                  <img src="../../../assets/images/Open comment.png" alt="Weasis Icon"
                     style="width: 43px; height: 30px; "> <!-- Loading spinner -->
               </button>
               <button pButton pRipple type="button" title="Technician Comment"
                  *ngIf="(sysService.isRad() || sysService.isRadSub()) && report?.view?.technicianCount === 0"
                  class=" p-button-secondary p-button-text button-padding">
                  <img src="../../../assets/images/No comments.png" alt="No comments.png"
                     style="width: 40px; height: 30px;">
               </button>
               <button *ngIf="(sysService.isRad() || sysService.isRadSub()) && rightEditor" pButton pRipple
                  type="button" (click)="reportTab(report, splitter?.viewerSize[0] === -60 ? false : true)"
                  [title]="splitter?.viewerSize[0] === -60 ? ' Minimize text editor' : '  Maximize text editor'"
                  class=" p-button-help p-button-text p-1">
                  <img style="width: 23px; height: 30px;"
                     [src]="splitter?.viewerSize[0] === -60 ? '../../../assets/images/max_icon.png' : '../../../assets/images/min_icon.png'"
                     alt="{{ splitter?.viewerSize[0] === -60 ? 'Minimize' : 'Maximize' }}">
               </button>
               <button *ngIf="(sysService.isRad() || sysService.isRadSub()) && rightEditor" pButton pRipple
                  type="button" (click)="toggleViewer(report)"
                  [title]="splitter?.viewerSize[0] === 70 ? ' Maximize Viewer' : '  Viewer'"
                  class=" p-button-help p-button-text p-1">
                  <img style="width: 32px; height: 30px;"
                     [src]="splitter?.viewerSize[0] === 70 ? '../../../assets/images/monitor_view.png' : '../../../assets/images/monitor_view.png'"
                     alt="{{ splitter?.viewerSize[0] === 70 ? 'Maximize' : 'Minimize' }}">
               </button>

            </div>

         </div>

      </div>
      <div class="grid p-2 pt-2" *ngIf="!rightEditor && ckeditorLoading">

         <div class="flex col-12 md:col-4 lg:col-4 pl -4 justify-content-between"
            *ngIf="!report.get('isEditable') && report?.view?.reportingRadiologist">
            <div class="flex col-6 md:col-4 lg:col-2">
               <p class="letter-Case"> Rate : {{ report?.view?.charge ?? 'N/A' }} </p>
            </div>
            <div class="flex col-12 md:col-8 lg:col-6" *ngIf="report?.view?.paymentNote">
               <p class="letter-Case">
                  payment note :
                  <ng-container *ngIf="report?.view?.paymentNote?.length > 10">
                     {{ report.view.paymentNote.substring(0, 10) }}<i class="pi pi-eye payment"
                        [pTooltip]="report.view.paymentNote" tooltipPosition="left"></i>
                  </ng-container>
                  <ng-container *ngIf="!(report?.view?.paymentNote?.length > 10)">
                     {{ report?.view?.paymentNote || 'N/A' }}
                  </ng-container>
               </p>

            </div>
            <div class="flex col-6 md:col-6 lg:col-6"
               *ngIf="sysService.getUser().userSettings.isTemplateDropdown && report?.view?.reportTemplateId?.name ">
               <p class="letter-Case">
                  Template : {{ report?.view?.reportTemplateId?.name ?? 'N/A' }}
               </p>
            </div>


         </div>

         <div
            *ngIf="report?.view?.reportingRadiologist && sysService.getUser().userSettings.isTemplateDropdown && report.get('isEditable' ,false)===true"
            class="field mb-0 col-12 md:col-3 lg:col-3 ">
            <span class="p-float-label">
               <p-autoComplete forceSelection="true" formControlName="reportTemplateId"
                  [suggestions]="reportTemplateLookup" (completeMethod)="filterReport($event)"
                  (onSelect)="templateId($event)" field="name" ariaLabel="bodyPart" [dropdown]="true"
                  [autoHighlight]="true" showEmptyMessage="true" delay="400" maxlength="50">
               </p-autoComplete>
               <label class="letter-Case">Template </label>
            </span>
         </div>
         <div class="flex col-12 md:col-6 lg:col-4 pt-0"
            *ngIf="report.get('isEditable') && report?.view?.reportingRadiologist">
            <div class="flex col-4 md:col-4 lg:col-3">
               <span class="p-float-label">
                  <input id="unitRate" type="text" formControlName="charge" [readonly]=" !report.get('customRate') "
                     size="10" maxlength="7" aria-describedby="rate-help" pInputText pattern="[0-9]+">
                  <label class="letter-Case">Rate </label>
               </span>
            </div>
            <div class="flex col-4 md:col-6 lg:col-4"
               *ngIf="report.get('customRate') == true && report?.view?.reportingRadiologist">
               <span class="p-float-label">
                  <textarea [rows]="1" [cols]="40" [readonly]="report.get('isEditable', true) === false"
                     class="p-inputtextarea p-inputtext p-component p-element" formControlName="paymentNote"
                     pInputTextarea maxlength="200"></textarea>
                  <label class="letter-Case">Payment Note</label>
                  <div *ngIf="errFlag1 && report.isEmpty('paymentNote')">
                     <small class="p-error">Enter the payment note</small>
                  </div>
               </span>
            </div>

            <div class="flex col-4 md:col-4 lg:col-4">
               <p-checkbox formControlName="customRate" [binary]="true" label="Custom Rate" (click)="customreset()"
                  [readonly]=" report.get('isEditable' ,true)===false" inputId="binary" inputId="ny"> </p-checkbox>
            </div>
         </div>
         <!-- --------------------------------------end ------------------------------------------------- -->

         <div class=" py-0 col-12 md:col-4 lg:col-4 poss">
            <div class="right-button">




               <button pButton pRipple type="button"
                  *ngIf="(sysService.isRad() || sysService.isRadSub()) && report?.view?.technicianCount > 0 && report.get('status')===1"
                  Tooltip="Technician Comment" tooltipPosition="left" class="p-button-secondary p-button-text p-0"
                  (click)="technicianComment(report)" [disabled]="sysService.loading">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                  <img src="../../../assets/images/Open comment.png" alt="Open comment"
                     style="width: 43px; height: 30px; "> <!-- Loading spinner -->
               </button>

               <p-splitButton class="rm-mr" pTooltip="Click dropdown for more options"
                  *ngIf="sysService.getUser().userSettings.isDownloadPdf && (report.get('reportContent')!==null && report.get('reportContent')!=='')"
                  [label]="report?.view?.isDownloadPdf && report?.view?.showDownload ? 'PDF' : 'Preview'"
                  [model]="items"
                  [icon]="report?.view?.isDownloadPdf && report?.view?.showDownload ? 'pi pi-download' : 'pi pi-eye'"
                  (onClick)="reportDownloadItextPreview(report)" [styleClass]="report?.view?.isDownloadPdf && report?.view?.showDownload
                   ? 'p-button-text p-button-raised p-button-success' 
                   : 'p-button-text p-button-raised p-button-help '" />
               <button pButton pRipple type="button" icon="pi pi-download" title="Word Download" iconPos="right"
                  *ngIf="report?.view?.showDownload === true && report?.view?.isDownloadWord"
                  [label]="rightEditor ? '' : '&nbsp; Word '" class="p-button-success " (click)="wordDownloadBe(report)"
                  [disabled]="sysService.loading">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
               </button>

               <button pButton pRipple type="button" label="Re-edit " class="p-button-warning  "
                  (click)="reEdit(report)" *ngIf="report?.view?.showEdit === true" icon="pi pi-pencil"
                  [disabled]="sysService.loading">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i> <!-- Loading spinner -->
               </button>
               <button pButton pRipple type="button" label="Edit " class="p-button-warning "
                  *ngIf="report.get('showSaveForAdmin',false)===true && !editSave" (click)="adminForSave(true)"
                  icon="pi pi-pencil" [disabled]="sysService.loading">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i> <!-- Loading spinner -->
               </button>
               <button pButton pRipple type="button" label="Save " class="p-button-outlined p-button-info m-1 "
                  (click)="saveReport(report, 1)" *ngIf="editSave" [disabled]="sysService.loading">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i> <!-- Loading spinner -->
               </button>
               <!-- Button to save report,showConfirm is true only show  -->
               <button pButton pRipple type="button" label="Save " class="p-button-outlined p-button-info m-1 "
                  (click)="saveReport(report, 1)" [disabled]="sysService.loading"
                  *ngIf="report.get('showConfirm' ,false)===true ">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i> <!-- Loading spinner -->
               </button>
               <button pButton pRipple type="button" label="Confirm" icon="pi pi-check"
                  *ngIf="report.get('showConfirm' ,false)===true" class="p-button-raised p-button-success m-1 "
                  (click)="saveReport(report, 2)" [disabled]="sysService.loading">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
               </button>

               <button pButton pRipple type="button" [label]="rightEditor ? '' : 'Logs'" tooltipPosition="left"
                  pTooltip="Report Logs " icon="pi pi-list" *ngIf="report?.view?.isReportLog"
                  class="p-button-primary m-1" (click)="reportHist(report)" [disabled]="sysService.loading">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i> <!-- Loading spinner -->
               </button>

               <!-- <button pButton pRipple type="button" icon="pi pi-download" title="Word Download" iconPos="right"
                     *ngIf="report?.view?.showDownload === true && report?.view?.downloadDoc === 1"
                     [label]="rightEditor ? '' : '&nbsp; Download Word'" iconpos="right"
                     class="p-button-success webDwd m-1" (click)="wordDownload(editor)" [disabled]="sysService.loading">
                     <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                  </button>&nbsp; -->
               <!-- <button pButton pRipple type="button" icon="pi pi-download" title="Word Download" iconPos="right"
                     *ngIf="report?.view?.showDownload === true && report?.view?.downloadDoc === 1"
                     [label]="rightEditor ? '' : '&nbsp; Download Word '" iconpos="right"
                     class="p-button-success mobDwd m-1" (click)="wordDownloadForPhone(editor)"
                     [disabled]="sysService.loading">
                     <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                  </button> -->
               <!-- <button pButton pRipple type="button" icon="pi pi-download" title="PDF Download" iconPos="right"
                  *ngIf="report?.view?.isDownloadPdf=== true" label="PDF" iconpos="right" class="p-button-success m-1"
                  (click)="reportDownloadItextPreview(report)" [disabled]="sysService.loading">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
               </button> -->
               <!-- Button to approve report, shown only if configured to showApprovalBtn is true   -->
               <button pButton pRipple type="button" *ngIf="report.get('showApprovalBtn' ,false)===true" label="Approve"
                  class="p-button-raised p-button-help m-1 " (click)="saveReport(report, 6)"
                  [disabled]="sysService.loading">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i> <!-- Loading spinner -->
               </button>
               <!-- Button to reject report, shown only if configured to showApprovalBtn is true   -->
               <!-- Button to confirm the report, shownConfirm is true only show -->
               <button pButton pRipple type="button" *ngIf="report.get('showReject' ,false)===true " label="Reject"
                  class="p-button-raised p-button-danger m-1" (click)="rejectDialog()" [disabled]="sysService.loading">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i> <!-- Loading spinner -->
               </button>
               <!-- Button to add a technician comment, shown only for radiologists with technicians comment count > 0  -->
               <button pButton pRipple type="button"
                  *ngIf="(sysService.isRad() || sysService.isRadSub()) && report?.view?.technicianCount > 0 && report.get('status')!==1"
                  Tooltip="Technician Comment" tooltipPosition="left" class="p-button-secondary p-button-text p-0"
                  (click)="technicianComment(report)" [disabled]="sysService.loading">
                  <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                  <img src="../../../assets/images/Open comment.png" alt="Weasis Icon"
                     style="width: 43px; height: 30px; "> <!-- Loading spinner -->
               </button>
               <button pButton pRipple type="button" title="Technician Comment"
                  *ngIf="(sysService.isRad() || sysService.isRadSub()) && report?.view?.technicianCount === 0"
                  class=" p-button-secondary p-button-text button-padding">
                  <img src="../../../assets/images/No comments.png" alt="No comments.png"
                     style="width: 42px; height: 30px;">
               </button>
               <button *ngIf="sysService.isRad() && rightEditor" pButton pRipple type="button"
                  (click)="reportTab(report, splitter?.viewerSize[0] === -60 ? false : true)"
                  [title]="splitter?.viewerSize[0] === -60 ? ' Minimize text editor' : '  Maximize text editor'"
                  class=" p-button-help p-button-text p-1">
                  <img style="width: 23px; height: 30px;"
                     [src]="splitter?.viewerSize[0] === -60 ? '../../../assets/images/max_icon.png' : '../../../assets/images/min_icon.png'"
                     alt="{{ splitter?.viewerSize[0] === -60 ? 'Minimize' : 'Maximize' }}">
               </button>
               <button *ngIf="sysService.isRad() && rightEditor" pButton pRipple type="button"
                  (click)="toggleViewer(report)"
                  [title]="splitter?.viewerSize[0] === 70 ? ' Maximize Viewer' : '  Viewer'"
                  class=" p-button-help p-button-text p-1">
                  <img style="width: 32px; height: 30px;"
                     [src]="splitter?.viewerSize[0] === 70 ? '../../../assets/images/monitor_view.png' : '../../../assets/images/monitor_view.png'"
                     alt="{{ splitter?.viewerSize[0] === 70 ? 'Maximize' : 'Minimize' }}">
               </button>

            </div>

         </div>

      </div>

      <div class="field col-12 md:col-12 lg:col-12">
         <i class="fa-light fa-chevron-down "></i>
         <p-button *ngIf="!sysService.loading && ckeditorLoading && !rightEditor " icon="pi pi-info-circle"
            class="ckeditor-help  " label="Shortcuts" title="Keyboard Shortcuts" (click)="keyboardShortcuts()"
            styleClass="p-button-rounded p-button-info p-button-text"> </p-button>


         <ckeditor id="report" (ready)="onNamespaceLoaded($event)" debounce="500"
            placeholder="Enter your Report Content" formControlName="reportContent" #editor [config]="configuration"
            [(data)]="editorData" [disabled]="true"></ckeditor>


      </div>
      <!-- ----------------------------------------------------------------------->
      <!------------------------------------- bottom button  ------------------ -->

   </div>
   <!-- --------------------------------------------help dialog----------------------------------->
   <p-dialog header="Keyboard Shortcuts" [(visible)]="helpDigoluebox">
      <h3 id="navigation"><a class="headerlink" href="#navigation">#</a> Navigation</h3>
      <ul>
         <li><button>Home</button> – Jumps to the beginning of the line.</li>
         <li><button>Ctrl+Home</button> – Jumps to the beginning of the document.</li>
         <li><button>End</button> – Jumps to the end of the line.</li>
         <li><button>Ctrl+End</button> – Jumps to the end of the document.</li>
         <li><button>PgDn</button> – Scrolls down the document, approximately by the length of the editing area.</li>
         <li><button>PgUp</button> – Scrolls up the document, approximately by the length of the editing area.</li>
      </ul>
      <h3 id="writing"><a class="headerlink" href="#writing">#</a> Writing</h3>
      <ul>
         <li><button>Enter</button> (<button>Return</button>) – Ends a paragraph and starts a new one.</li>
         <li><button>Shift+Enter</button> – Adds a line break.</li>
         <li><button>ctrl + Right click </button> – Spell checker.</li>
         <li><button>Shift+Ctrl+4</button> – Enables entering content (by adding a new paragraph) after a problematic
            element such as an image, table or <code>&lt;div&gt;</code> element that starts or ends a document, list,
            or
            even adjacent horizontal lines.</li>
         <li><button>Backspace</button>, <button>Del</button> – Deletes a character.</li>
         <li><button>Ctrl+Backspace</button>, <button>Ctrl+Del</button> – Deletes a word.</li>
      </ul>
      <h3 id="undo-and-redo"><a class="headerlink" href="#undo-and-redo">#</a> Undo and Redo</h3>
      <ul>
         <li><button>Ctrl+Z</button> – Performs the undo operation.</li>
         <li><button>Ctrl+Y</button>, <button>Shift+Ctrl+Z</button> – Performs the redo operation.</li>
      </ul>
      <h3 id="cut-copy-and-paste"><a class="headerlink" href="#cut-copy-and-paste">#</a> Cut, Copy and Paste</h3>
      <ul>
         <li><button>Ctrl+X</button>, <button>Shift+Del</button> – Cuts a text fragment to clipboard.</li>
         <li><button>Ctrl+C</button> – Copies a text fragment to clipboard.</li>
         <li><button>Ctrl+V</button>, <button>Shift+Insert</button> – Pastes a text fragment from clipboard.</li>
         <li><button>Shift+Ctrl+V</button> – Pastes content from clipboard as plain text.</li>
      </ul>
      <h3 id="text-selection"><a class="headerlink" href="#text-selection">#</a> Text Selection</h3>
      <ul>
         <li><button>Ctrl+A</button> – Selects all document contents.</li>
         <li><button>Shift+Arrow</button> – Selects a text fragment by letters.</li>
         <li><button>Ctrl+Shift+Arrow</button> – Selects a text fragment by words.</li>
         <li><button>Shift+Home</button> – Selects a text fragment from the cursor to the beginning of the line.</li>
         <li><button>Shift+End</button> – Selects a text fragment from the cursor to the end of the line.</li>
         <li><button>Ctrl+Shift+Home</button> – Selects a text fragment from the cursor to the beginning of the
            document.
         </li>
         <li><button>Ctrl+Shift+End</button> – Selects a text fragment from the cursor to the end of the document.
         </li>
         <li><button>Shift+PgDn</button> – Selects a text fragment of approximately the length of the editing area
            starting
            from the cursor<br>
            and going down.</li>
         <li><button>Shift+PgUp</button> – Selects a text fragment of approximately the length of the editing area
            starting
            from the cursor<br>
            and going up.</li>
      </ul>
      <h3 id="widget-selection"><a class="headerlink" href="#widget-selection">#</a> Widget Selection</h3>
      <ul>
         <li><button>Enter</button> – Opens the widget dialog.</li>
         <li><button>Shift+Enter</button> – Adds an empty paragraph after the selected widget.</li>
         <li><button>Shift+Alt+Enter</button> – Adds an empty paragraph before the selected widget.</li>
         <li><button>Left Arrow</button> or <button>Right Arrow</button> – Moves the selection before or after the
            widget.
         </li>
      </ul>
      <h2 id="text-styling"><a class="headerlink" href="#text-styling">#</a> Text Styling</h2>
      <ul>
         <li><button>Ctrl+B</button> – Applies <strong>bold</strong> formatting to a text fragment.</li>
         <li><button>Ctrl+I</button> – Applies <em>italics</em> formatting to a text fragment.</li>
         <li><button>Ctrl+U</button> – Applies <span style="text-decoration: underline;">underline</span> formatting
            to
            a
            text fragment.</li>
      </ul>
      <h3 id="copy-formatting"><a class="headerlink" href="#copy-formatting">#</a> Copy Formatting</h3>
      <ul>
         <li><button>Ctrl+Shift+C</button> – Copies the inline formatting from a text fragment and enables the sticky
            mode.
         </li>
         <li><button>Ctrl+Shift+V</button> – Applies the previously copied inline formatting to a text fragment.</li>
      </ul>
      <h2 id="rich-text"><a class="headerlink" href="#rich-text">#</a> Rich Text</h2>
      <ul>
         <li><button>Ctrl+L</button>, <button>Ctrl+K</button> – Opens the <strong>Link</strong> dialog window.</li>
         <li><button>Tab</button> – Indents a list.</li>
         <li><button>Shift+Tab</button> – Outdents a list.</li>
      </ul>
      <h2 id="accessibility"><a class="headerlink" href="#accessibility">#</a> Accessibility</h2>
      <ul>
         <li><button>Alt+0</button> – Opens the <strong>Accessibility Instructions</strong> dialog window.</li>
         <li><button>Alt+F10</button> – Enters the toolbar or the tab list of the currently open dialog window.</li>
         <li><button>Alt+F11</button> – Enters the elements path.</li>
         <li><button>Tab</button> – Moves to the next toolbar button group, context menu suboption, elements path
            element,<br>
            dialog window element, or dialog window tab while in the tab list.</li>
         <li><button>Right Arrow</button> – Moves to the next toolbar button within the group, context menu
            suboption,<br>
            elements path element, dialog window element, or dialog window tab while in the tab list.</li>
         <li><button>Tab</button> or <button>Down Arrow</button> – Moves to the next drop-down list or context menu
            option.
         </li>
         <li><button>Shift+Tab</button> – Moves to the previous toolbar button group, context menu parent option,<br>
            elements path element, dialog window element, or dialog window tab while in the tab list.</li>
         <li><button>Left Arrow</button> – Moves to the previous toolbar button within the group, context menu<br>
            parent option, elements path element, dialog window element, or dialog window tab while in the tab list.
         </li>
         <li><button>Shift+Tab</button> or <button>Up Arrow</button> – Moves to the previous drop-down list or
            context
            menu
            option.</li>
         <li><button>Space</button> or <button>Enter</button> – Activates a toolbar button, a context menu<br>
            option, a drop-down list option, an elements path element, or a dialog window tab once selected.<br>
            Also enters a context menu submenu, if it is available.</li>
      </ul>
   </p-dialog>
   <!-- -----------------------------------------reject dialog------------------------------------------- -->
   <p-dialog header="Reason for Rejection" [dismissableMask]="true" [(visible)]="rejectReason" (onHide)="rejectBack()"
      [modal]="true" [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '35vw' }" [draggable]="false"
      [resizable]="false">
      <p-messages key="popmsgs"></p-messages>
      <div class="grid">
         <div class="col-4 md:col-4 lg:col-12">
            <label><b>Reason</b></label>
         </div>
         <div class="col-12 md:col-4 lg:col-12">
            <textarea class="p-inputtextarea" [rows]="2" [cols]="40" maxlength="480" formControlName="rejectionReason"
               pInputTextarea></textarea>
         </div>
         <div class="p-error">{{messageReason}}
         </div>
         <div class="col-12 lg:col-12 flex justify-content-center">
            <a><button pButton pRipple label="Cancel" icon=" pi pi-times" class="p-button-outlined p-button-info"
                  (click)="rejectBack()"></button></a>&nbsp;
            <button pButton pRipple type="button" label="Submit" (click)="saveReport(report, 0)" icon=" pi pi-check"
               class="p-button-raised p-button-success" [disabled]="sysService.loading">
               <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
            </button>
         </div>
         <ng-template pTemplate="footer"> </ng-template>
      </div>
   </p-dialog>










   <p-dialog [(visible)]="rateDiagloue" [dismissableMask]="true" [modal]="true" class="closelabel"
      [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '25vw' }">
      <ng-template pTemplate="header">
         <div class="inline-flex align-items-center justify-content-center gap-2 ng-star-inserted">
            <p-avatar image="../../assets/images/logo.png" shape="circle"></p-avatar>
            <span class="font-bold white-space-nowrap letter-Case ">Report Rate</span>
         </div>
      </ng-template>
      <div *ngIf="report?.view?.reportingRadiologist" class="field col-12 md:col-12 lg:col-12">

         <label class="letter-Case">Rate </label>
         <input id="unitRate" type="text" formControlName="charge" [readonly]="report.get('isEditable' ,true)===false"
            size="10" maxlength="7" aria-describedby="rate-help" pInputText pattern="[0-9]+">


         <f-error [err]="report.required('charge')" msg="Rate is required"></f-error>
      </div>
      <div *ngIf="report?.view?.reportingRadiologist" class="flex col-12 md:col-12 lg:col-12">

         <p-checkbox formControlName="customRate" [binary]="true" [readonly]=" report.get('isEditable' ,true)===false"
            inputId="binary" inputId="ny"></p-checkbox>&nbsp;
         &nbsp;<label class="letter-Case" for="ny">Custom Rate </label>
      </div>
      <div class="field col-12 md:col-12 lg:col-12"
         *ngIf="report.get('customRate')==true && report?.view?.reportingRadiologist">
         <label class="letter-Case">Payment Note </label>

         <textarea [rows]="2" [cols]="40" [readonly]="report.get('isEditable' ,true)===false"
            class="p-inputtextarea p-inputtext p-component p-element" formControlName="paymentNote" pInputTextarea
            maxlength="200"></textarea>

         <div *ngIf="errFlag1 && report.isEmpty('paymentNote')">
            <small class="p-error">Enter the payment note</small>
         </div>

      </div>



      <div *ngIf="report?.view?.reportingRadiologist" class="text-center ">
         <button pButton pRipple type="button" label="Reset" (click)="resetRate()"
            class="p-button-raised p-button-danger">
            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
         </button> &nbsp;
         <button pButton pRipple type="button" label="Set Rate" (click)="setRate()"
            class="p-button-raised p-button-success ">
            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
         </button>



      </div>


   </p-dialog>



</form>
<i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
<p-dialog header="Report History " [(visible)]="reportLog" [maximizable]="true" (onHide)="reportClose()"
   [style]="{width: '90vw'}">
   <p-table #dt [value]="reportHistoryList" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[5, 10, 20]"
      styleClass="p-datatable-sm p-datatable-gridlines"
      [globalFilterFields]="['receivebles','centreName','patName','reportDate','modality','studyDesc','charge','paymentNote','charge']">
      <ng-template pTemplate="paginatorleft">
      </ng-template>
      <ng-template pTemplate="header">
         <tr>
            <th pSortableColumn="createdByName"> Updated by

            </th>
            <th pSortableColumn="createdByDate">Updated Date

            </th>
            <th pSortableColumn="status">Status

            </th>
         </tr>
      </ng-template>
      <ng-template pTemplate="body" let-reportHistory>
         <tr>
            <td>
               <a (click)="editReportlog(reportHistory)"> {{reportHistory.createdByName}}
                  <i class="pi pi-eye link-study"> </i> </a>

            </td>
            <td>
               {{reportHistory.createdByDate | date:'dd/MM/yyyy hh:mm a' || '' }}
            </td>
            <td *ngIf="reportHistory.status == 0">
               Rejected
            </td>
            <td *ngIf="reportHistory.status == 1">
               Draft
            </td>
            <td *ngIf="reportHistory.status == 2">
               Completed
            </td>
            <td *ngIf="reportHistory.status == 3">
               Unapproved
            </td>
         </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
         <tr>
            <td class="norecord" [attr.colspan]="4">No records found</td>
         </tr>
      </ng-template>
   </p-table>
   <span *ngIf="reportLogDetailsView">
      <h3>Report on {{ sysService.toDate(reportLogData.createdByDate) | date:'dd/MM/yyyy hh:mm a' || '' }} </h3>
      <div class="card">
         <p-editor [(ngModel)]="reportHislog" [style]="{ height: '320px' ,width:'82vw' }" [readonly]="true">
            <ng-template pTemplate="header">
               <span class="ql-formats">
               </span>
            </ng-template>
         </p-editor>
         <h4>Status :<span *ngIf="reportLogData.status == 0" class="text-danger"> Rejected</span>
            <span *ngIf="reportLogData.status == 1">
               Draft</span>
            <span *ngIf="reportLogData.status == 2">
               Completed</span>
            <span *ngIf="reportLogData.status == 3">
               Unapproved</span> &nbsp; Updated By : {{reportLogData?.createdByName}}
         </h4>
         &nbsp;
         <button pButton pRipple type="button" label="Close Editor" (click)="closeEditor(false)" icon=" pi pi-times"
            class="p-button-raised p-button-danger" [disabled]="sysService.loading">
            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
         </button>
      </div>
   </span>
</p-dialog>