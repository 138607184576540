import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormDefault, Model, StaticService, SysService, View, ViewFactory } from '@app/_common';
import { AppConstants } from '@app/_config';
import { ConfirmationService } from 'primeng/api';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-rad-role',
  templateUrl: './rad-role.component.html',
  styleUrls: ['./rad-role.component.css']
})

export class RadiRoleComponent {
  private RadiRoleView: View = this.viewFactory.newView();
  private UserSetingView: View = this.viewFactory.newView();

  saveButtonLabel: string = 'Save';
  eventData :any ;
  constructor(
    public sysService: SysService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public staticService: StaticService,
    private viewFactory: ViewFactory,
    public config: DynamicDialogConfig,  // used for dynamical popup
    private confirmationService: ConfirmationService,


  ) {
  }
  flag: boolean

  ngOnInit() {
    this.RadiRoleView.of(this.RadiRoleModel());
    this.UserSetingView.of(this.UserSetingModel());

  }
  get RadiRole() {
    return this.RadiRoleView.model;
  }
  get userSetting() {
    return this.UserSetingView.model;
  }

  private RadiRoleModel() {
    const formDefault = new FormDefault(['status', 'userChildRole'], [1, 5]);
    return new Model(this.formBuilder.group({
      id: [''],
      name: ['', [Validators.required, Validators.pattern(/^(?! ).*/)]],
      email: ['', [Validators.required, Validators.pattern(AppConstants.regexEmail)]],
      phoneNo: ['', [Validators.required, Validators.pattern(AppConstants.regexPhonenumber), Validators.minLength(7)]],
      userChildRole: [, [Validators.required]],
      status: [],
      userId:[''],
      showCreatedDate:[''],
      showPatId:[''],
      showPatSex:[''],
      showSeriesInstanceCount:[''],
      showStatus:[''],
       showAutoApproval:[''],
       disclaimer:[''],
       createdAt:[''],
       editableByAdmin:[''],
       downloadDoc:[''],
      isTechEditableByRad:[''],
       bonus:[''],
      isBilledRateList:[''],
      isRadRateList:[''],
      isRejectList:[''],
      isReportList:[''],
      isStudyLog:[''],
      isRejectedList:[''],
      isEditReport:[''],
      isSaveReport:[''],
      isApproveReport:[''],
      isRejectReport:[''],
      isConfirmReport:[''],
      isDownloadPdf:[''],
      isDownloadWord:[''],
      isReportRateInside:[''],
      isBalanceDisplay:[''],
      isPatDetailsEditable:[''],
      isTemplateEditable:[''],
      isRejectedStudies:[''],
      isAssignedRejReport:[''],
      isRejectedReport:[''],
      isCode:[''],
      isDemographicLog:[''],
      isSource:[''],

    }), this.sysService, formDefault, '');
  }

  private UserSetingModel() {
    const formDefault = new FormDefault(['status', 'userChildRole'], [1, 5]);
    return new Model(this.formBuilder.group({
   
      id:[''],
      userId:[''],
     showCreatedDate:[''],
     showPatId:[''],
     showPatSex:[''],
     showSeriesInstanceCount:[''],
     showStatus:[''],
      showAutoApproval:[''],
      disclaimer:[''],
      createdAt:[''],
      editableByAdmin:[''],
      downloadDoc:[''],
     isTechEditableByRad:[''],
      bonus:[''],
     isBilledRateList:[''],
     isRadRateList:[''],
     isRejectList:[''],
     isReportList:[''],
     isStudyLog:[''],
     isRejectedList:[''],
     isEditReport:[''],
     isSaveReport:[''],
     isApproveReport:[''],
     isRejectReport:[''],
     isConfirmReport:[''],
     isDownloadPdf:[''],
     isDownloadWord:[''],
     isReportRateInside:[''],
     isBalanceDisplay:[''],
     isPatDetailsEditable:[''],
     isTemplateEditable:[''],
     isRejectedStudies:[''],
     isAssignedRejReport:[''],
     isRejectedReport:[''],
     isCode:[''],
     isDemographicLog:[''],
     isSource:[''],
      


    }), this.sysService, formDefault, '');
  }
  

  add() {

    this.RadiRoleView.toNew();
    this.saveButtonLabel = "Save";


  }
  edit(user: any) {
    this.RadiRoleView.toEditFetch(`/users/${user}`, user);
    this.saveButtonLabel = "Update";

  }
  list(id: any) {
    this.listView(false);
  }
  async listRadiRoleLazy(event?: LazyLoadEvent, search: boolean = false) {
    this.eventData = event
    this.RadiRoleView.toListLazyFetch(`/users`, search, event);
  }
  listView(search: boolean = false) {
    this.RadiRoleView.toListLazyView(`/users`, search);
  }
  view(id: any) {
    this.RadiRoleView.toViewFetch(`/users/${id}`, id);
  }
  deleteRadiRoles() {
    this.RadiRoleView.toDeleteSelection(`/users`, {
      onSuccess: () => {
        this.RadiRoleView.toListLazyFetch(`/users`, true, this.eventData);       
      }
    });

  }
  deleteRadiRole(id: any) {
    this.RadiRoleView.toDeleteList(`/users/${id}`, "id", id);
  }





  saveRadiRole() {

      this.RadiRoleView.toSaveListFetch(
        "/users",
        "id",
        this.RadiRole.isEdit() ? "User Successfully updated." : "User Successfully saved."
      );

      this.RadiRole.searchTxt;
    }
  

}



