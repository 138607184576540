<div id="wrapper" [ngClass]="{
  'login-wrapper': !sysService.isLoggedIn(),
  'layout-wrapper': sysService.isLoggedIn() ,
  'viewer-wrapper': !sysService.viewerCondition(),
}">
  <f-header></f-header>
  <div class="{{sysService.isLoggedIn() ? 'mc1':''}}">
    <!-- <p-messages key="msgs" class="{{sysService.isLoggedIn() ? '':'mess'}}"></p-messages> -->
    <p-toast   key="msgs" class="{{sysService.isLoggedIn() ? '':'mess'}}" ></p-toast>

    <!-- <p-messages key="popmsgs"></p-messages> -->
    <p-toast  key="popmsgs"></p-toast>
        <router-outlet>
      <!-- <page-loading></page-loading> -->
    </router-outlet>

  </div>
</div>
<footer class="footer">
  <div
    class="flex justify-content-center footerWraper md:justify-content-between  flex-wrap card-container purple-container">
    <div class="flex align-items-center justify-content-center footWrap">
      <p class="p-mt-2"><a href="https://www.ecscribe.com/" target="_blank" style="color: aliceblue;"
          class="p-link p-text">&nbsp;Copyright © 2024 eClarity/eCScribe. All Rights Reserved.&nbsp;</a></p>
    </div>
    <div class="footNote">
      <div class="flex align-items-center justify-content-center">
        <p class="p-mt-5"><a href="/contact-us" style="color: rgb(243, 241, 239);" class="p-link p-text">Contact Us</a>
        </p>
      </div>
    </div>
  </div>
</footer>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>