<header *ngIf="!sysService.isLoggedIn()" >
    <app-messages></app-messages>
    <div class="wrap text-left">
        <div class="hl7Wrap">
            <div class="right">
           
                <div>
                    <a ><img class="web" src="../../../assets/images/eclogoo.png"></a>
                </div>
            </div>
    
            <a ><img class="mob" src="../../../assets/images/logo.png"></a>
    
            <div class="welcome  text-center">
                <span >HL7 Interface
                </span>
      
               
             
           
      
              </div>

        </div>
      

    </div>
</header>
<div class="table-responsive">

    <p-table #dt [value]="hl7.list" [totalRecords]="hl7.total"  [scrollable]="true" scrollHeight="65vh" [lazy]="true" (onLazyLoad)="listhl7Lazy($event)"
        dataKey="id" [(selection)]="hl7.selection" [loading]="sysService.loading" filterDelay="400" [rowHover]="true"
        styleClass="p-datatable-sm" [rows]="sysService.rows" [paginator]="true" alwaysShow="false"
        styleClass="p-datatable-sm p-datatable-gridlines"
        [globalFilterFields]="['mrn','patName','age','studyDate','reportingRad','consultingDoc','studyStatus']">

        <ng-template pTemplate="paginatorleft">
        </ng-template>
        <ng-template pTemplate="caption">
            <div class="grid">
                <div class="ml-2">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" maxlength="150" [(ngModel)]="hl7.searchTxt" pAutoFocus
                            [autofocus]="true" (input)="dt.filterGlobal($event.target.value, 'contains')"
                            placeholder="Search..." />
                    </span>
                </div>


            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th id="" scope="col" pSortableColumn="id" style="width: 8rem; ">
                    Id
                    <p-sortIcon></p-sortIcon>
                </th>
                <th id="" scope="col" pSortableColumn="mrn" style="width: 8rem; ">
                    MRN
                    <p-sortIcon></p-sortIcon>
                </th>
                <th id="" scope="col" pSortableColumn="patName">
                    Patient Name
                    <p-sortIcon></p-sortIcon>
                </th>
                <th id="" scope="col" pSortableColumn="age">
                    Age
                    <p-sortIcon></p-sortIcon>
                </th>
                <th id="" scope="col" pSortableColumn="sex">
                    Sex
                    <p-sortIcon></p-sortIcon>
                </th>
                <th id="" scope="col" pSortableColumn="studyDate" style="width: 9rem; ">
                    Study Date
                    <p-sortIcon></p-sortIcon>
                </th>
                <th id="" scope="col" pSortableColumn="modality" style="width: 8rem; ">
                    Modality
                    <p-sortIcon></p-sortIcon>
                </th>
                <th id="" scope="col" pSortableColumn="studyDesc">
                    Study Description <p-sortIcon></p-sortIcon>
                </th>
                <th id="" scope="col" pSortableColumn="reportingRad">
                    Reporting Radiologist <p-sortIcon></p-sortIcon>
                </th>
                <th id="" scope="col" pSortableColumn="consultingDoc">
                    Consulting Doctor <p-sortIcon></p-sortIcon>
                </th>
                <th id="" scope="col" pSortableColumn="studyStatus">
                    Status <p-sortIcon></p-sortIcon>
                </th>



                <th>
                    Action
                </th>

            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-hl7Interface>
            <tr>
                <td>{{ hl7Interface?.id || 'N/A' }}</td>
                <td>{{ hl7Interface?.mrn || 'N/A' }}</td>
                <td class="link" (click)="ohifViewer(hl7Interface)">{{ hl7Interface?.patName || 'N/A' }}</td>

                <td>{{ hl7Interface?.age || 'N/A' }}</td>
                <td>{{ hl7Interface?.sex || 'N/A' }}</td>
                <td>{{ hl7Interface?.studyDate || 'N/A' }}</td>

                <td>{{ hl7Interface?.modality || 'N/A' }}</td>
                <td>{{ hl7Interface?.studyDesc || 'N/A' }}</td>
                <td>{{ hl7Interface?.reportingRad || 'N/A' }}</td>
                <td>{{ hl7Interface?.consultingDoc || 'N/A' }}</td>
                <td>
                    <span *ngIf="hl7Interface.studyStatus === 0"> Unassigned</span>
                    <span *ngIf="hl7Interface.studyStatus === 1"> Pending</span>
                    <span *ngIf="hl7Interface.studyStatus === 2"> Draft</span>
                    <span *ngIf="hl7Interface.studyStatus === 4"> Completed</span>
                    <span *ngIf="hl7Interface.studyStatus === 5"> Emergency </span>
                    <span *ngIf="hl7Interface.studyStatus === 6"> Rejected </span>
                    <span *ngIf="hl7Interface.studyStatus === 7"> Unapproved </span>
                    <span *ngIf="hl7Interface.studyStatus === 8"> Unapproved By eclarity </span>
                    <span *ngIf="hl7Interface.studyStatus === 9"> Rejected By radiologist </span>
                    <span *ngIf="hl7Interface.studyStatus === 10"> Rejected By Center </span>

                </td>


                <td style="width: 9rem;">
                    <button pButton pRipple type="button" title="Open with Weasis Viewer" (click)="openWeasis(hl7Interface)"
                        class="p-button-rounded p-button-secondary p-button-text button-padding">
                        <img src="../../../assets/images/viewer.png" alt="Weasis Icon" class="iconSize">
                    </button>



                    <button pButton pRipple type="button" *ngIf="hl7Interface.studyStatus === 4" (click)="report(hl7Interface)"
                        title="Report Completed" type="button"
                        class="p-button-rounded p-button-help p-button-text button-padding">
                        <img src="../../../assets/images/report completed.png" alt="Report Completed"
                            class="iconSize"></button>


                </td>

            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td class="norecord" [attr.colspan]="16">No records found</td>
            </tr>
        </ng-template>
    </p-table>
</div>