import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { ConfirmationService } from "primeng/api";
import { DialogModule } from "primeng/dialog";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { PanelMenuModule } from "primeng/panelmenu";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { MessageService, PrimeNGConfig } from "primeng/api";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import {
  AuthInterceptorProviders,
  ErrorInterceptorProviders,
  SharedModule,
  ViewFactory,
} from "@app/_common";
import { SysService } from "@app/_common";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { CardModule } from "primeng/card";
import { ToastModule } from "primeng/toast";
// import { Card1Component } from './card1/card1.component';
import { TableModule } from "primeng/table";
import { TieredMenuModule } from "primeng/tieredmenu";
import { ButtonModule } from "primeng/button";
import { MenuModule } from "primeng/menu";
import { GroupComponent } from "./group/group.component";
import { ImageModule } from "primeng/image";
import { TooltipModule } from "primeng/tooltip";
import { ToolbarModule } from "primeng/toolbar";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { InputTextModule } from "primeng/inputtext";
import { ToggleButtonModule } from "primeng/togglebutton";
import { DividerModule } from "primeng/divider";
import { EquipmentComponent } from "./equipment/equipment.component";
import { StudyDescriptionComponent } from "./study-description/study-description.component";
import { ProceduresComponent } from "./procedures/procedures.component";
import { BadgeModule } from "primeng/badge";
import { ChipModule } from "primeng/chip";
import { DropdownModule } from "primeng/dropdown";
import { MultiSelectModule } from "primeng/multiselect";

import { PasswordModule } from "primeng/password";
import { AutoFocusModule } from "primeng/autofocus";
import { OrderListModule } from "primeng/orderlist";
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { InputNumberModule } from "primeng/inputnumber";
import { RadiologistRegistrationComponent } from "./radiologist-registration/radiologist-registration.component";
import { FileUploadModule } from "primeng/fileupload";
import { CenterComponent } from "./center/center.component";
import { CheckboxModule } from "primeng/checkbox";
import { CalendarModule } from "primeng/calendar";
import { PrimarySecondaryManagementComponent } from "./primary-secondary-management/primary-secondary-management.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { NewPasswordComponent } from "./forgot-password/new-password/new-password.component";
import { ContactusComponent } from "./contactus/contactus.component";
// import { CaptchaModule } from "primeng/captcha";
import { InputSwitchModule } from 'primeng/inputswitch';
import { TabMenuModule } from "primeng/tabmenu";
import { MenuItem } from "primeng/api";
import { TabViewModule } from "primeng/tabview";
import { StateModule } from "./state/state.module";
import { InstallRequestComponent } from "./install-request/install-request.component";
import { StudyListComponent } from "./home/study-list/study-list.component";
import { TurnAroundTimeComponent } from "./turn-around-time/turn-around-time.component";
import { ChartModule } from "primeng/chart";
import { BillingComponent } from "./my-profile/billing/billing.component";

import { AutoCompleteModule } from "primeng/autocomplete";
import { RadiologistComponent } from "./my-profile/radiologist-profile/radiologist-profile.component";
import { InputMaskModule } from "primeng/inputmask";
import { RadiologistListComponent } from "./radiologist-list/radiologist-list.component";
import { SplitButtonModule } from 'primeng/splitbutton';
import { TicketComponent } from "./ticket/ticket.component";
import { UpiComponent } from "./upi-redirect/upi-redirect.component";
import { TransactionHistoryComponent } from "./Component-Dashboard/transaction-history/transaction-history.component";
import { MyProfileComponent } from "./my-profile/my-profile.component";
import { MyProfileModule } from "./my-profile/my-profile.module";
import { ChangePassComponent } from "./my-profile/change-pass/change-pass.component";
import { CentreProfileComponent } from "./my-profile/centre-profile/centre-profile.component";
import { AdminProfileComponent } from "./my-profile/admin-profile/admin-profile.component";
import { MyStudyRatesComponent } from "./my-study-rates/my-study-rates.component";
import { RevenueComponent } from "./Component-Dashboard/revenue/revenue.component";

import { TechnicianCommentsComponent } from "./technician-comments/technician-comments.component";
import { SplitterModule } from "primeng/splitter";
import { ViewerComponent } from "./viewer/viewer.component";
import { ReportModule } from "./report/report.module";
import { EclarityRevenueComponent } from "./Component-Dashboard/eclarity-revenue/eclarity-revenue.component";
import { PaymentModule } from "./payment/payment.module";
import { PrivacyComponent } from "./privacy/privacy.component";
import { SafePipe } from "./_common/directives/safe.pipe";
import { AboutUsComponent } from "./about-us/about-us.component";
import { CancellationComponent } from "./cancellation/cancellation.component";
import { PaymentInfoComponent } from "./payment-info/payment-info.component";
import { RechargeComponent } from "./Component-Dashboard/recharge/recharge.component";
// import { ReportTemplateComponent } from '@app/report-template/report-template.component';

import { SidebarModule } from "primeng/sidebar";
import { PaymentRechargeComponent } from "./Component-Dashboard/payment-recharge/payment-recharge.component";
import { PaymentRedirectComponent } from "./payment-redirect/payment-redirect.component";
import { PaymentCancelComponent } from "./payment-cancel/payment-cancel.component";
import { PayableComponent } from "./Component-Dashboard/payable/payable.component";
import { WalletDetalisComponent } from "./wallet-details/wallet-details.component";


import { PanelModule } from "primeng/panel";
import { TemplateDashboard1Component } from './template-dashboard/template-dashboard.component';
import { ReportTemplateComponent } from "./report-template/report-template.component";
import { ReportTemplateModule } from "./report-template/report-template.module";
import { DesktopClientComponent } from './desktop-clint/desktop-clint.component';
import { ReceivableComponent } from './Component-Dashboard/receivable/receivable.component';

import { NotifyService } from "./_common/notification/notify.service";
import { UserRoleComponent } from './user-role/user-role.component';
import { UserRoleProfileComponent } from "./my-profile/user-role-profile/user-role-profile.component";
import { RoleTechnicianComponent } from "./home/role-technician/role-technician.component";
import { BillingDashboardComponent } from "./biiling-dashboard/billing-dashboard.component";
import { RateCardComponent } from "./biiling-dashboard/rate-card/rate-card.component";
import { ReportViewerComponent } from './report-viewer/report-viewer.component';
import { BodypartComponent } from './bodypart/bodypart.component';
import { ShortCodeComponent } from './short-code/short-code.component';
import { UserSettingComponent } from './user-setting/user-setting.component';
import { ReportRadiologistViewerComponent } from './report-radiologist-viewer/report-radiologist-viewer.component';
import { CentreUsersComponent } from './centre-users/centre-users.component';
import { Hl7Component } from './hl7/hl7.component';
import { AssignRejectReportComponent } from './assign-reject-report/assign-reject-report.component';
import { SysRadLoginComponent } from './sys-rad-login/sys-rad-login.component';
import { ViewerRedirectComponent } from './viewer-redirect/viewer-redirect.component';
import { AvatarModule } from "primeng/avatar";
import { RadiRoleComponent } from "./rad-role/rad-role.component";
import { SharedViewerComponent } from "./shared-viewer/shared-viewer.component";
import { Hl7InterfaceComponent } from "./hl7-inferface/hl7-interface.component";
import { SlideMenuModule } from "primeng/slidemenu";


@NgModule({
  declarations: [
    // SafePipe,
    AppComponent,
    // Card1Component/,
    GroupComponent,
    EquipmentComponent,
    StudyDescriptionComponent,
    ProceduresComponent,

    RadiologistRegistrationComponent,
    RadiologistComponent,
    CenterComponent,
    PrimarySecondaryManagementComponent,
    ForgotPasswordComponent,
    NewPasswordComponent,
    ContactusComponent,
    RateCardComponent,
    SharedViewerComponent,

    InstallRequestComponent,
    TurnAroundTimeComponent,
    BillingComponent,
    RadiologistListComponent,
    TicketComponent,
    ViewerComponent,
    UpiComponent,
    TransactionHistoryComponent,
    PaymentRechargeComponent,
    MyProfileComponent,
    ChangePassComponent,
    CentreProfileComponent,
    AdminProfileComponent,
    MyStudyRatesComponent,
    RevenueComponent,
    PaymentCancelComponent,
    PayableComponent,
    TechnicianCommentsComponent,
    EclarityRevenueComponent,
    PrivacyComponent,
    PaymentRedirectComponent,
    AboutUsComponent,
    CancellationComponent,
    PaymentInfoComponent,
    RechargeComponent,
    WalletDetalisComponent,
    UserRoleProfileComponent,


    BillingDashboardComponent,
    TemplateDashboard1Component,
    DesktopClientComponent,
    ReceivableComponent,
    UserRoleComponent,
    ReportViewerComponent,
    BodypartComponent,
    ShortCodeComponent,
    UserSettingComponent,
    ReportRadiologistViewerComponent,
    CentreUsersComponent,
    Hl7Component,
    AssignRejectReportComponent,
    SysRadLoginComponent,
    ViewerRedirectComponent,
    RadiRoleComponent,
    Hl7InterfaceComponent,

  ],
  providers: [
    AuthInterceptorProviders,
    ErrorInterceptorProviders,
    ConfirmationService,
    MessageService,
    DialogService,
    PrimeNGConfig,
    SysService,
    ViewFactory,
    NotifyService,
    DynamicDialogConfig,
    DynamicDialogRef,

  ],
  bootstrap: [AppComponent],
  imports: [
    TriStateCheckboxModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MessagesModule,
    MessageModule,
    PanelMenuModule,
    SharedModule,
    DialogModule,
    ConfirmDialogModule,
    AppRoutingModule,
    CardModule,
    SplitButtonModule,
    ToastModule,
    TableModule,
    TieredMenuModule,
    ButtonModule,
    MenuModule,
    ImageModule,
    TooltipModule,
    ToolbarModule,
    InputTextModule,
    ProgressSpinnerModule,
    ToggleButtonModule,
    DividerModule,
    BadgeModule,
    SlideMenuModule,
    PanelModule,
    ChipModule,
    SidebarModule,
    DropdownModule,
    MultiSelectModule,
    PasswordModule,
    InputNumberModule,
    FileUploadModule,
    CheckboxModule,
    CalendarModule,
    // CaptchaModule,
    TabMenuModule,
    TabViewModule,
    ChartModule,
    StateModule,
    OrderListModule,
    AutoCompleteModule,
    SplitterModule,
    CalendarModule,
    InputMaskModule,
    AutoFocusModule,
    // AppSharedModule
    //    DropdownModule
    MyProfileModule,
    ReportModule,
    PaymentModule,
    ReportTemplateModule,
    InputSwitchModule,
    AvatarModule
  ],
})
export class AppModule { }
