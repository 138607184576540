import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, SysService, UserService, View, ViewFactory } from '@app/_common';
import { environment } from '@environments/environment';

import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-sys-rad-login',
  templateUrl: './sys-rad-login.component.html',
  styleUrls: ['./sys-rad-login.component.css']
})
export class SysRadLoginComponent implements OnInit {

  private SysRadLoginView: View = this.viewFactory.newView();
  inital: boolean;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private viewFactory: ViewFactory,
    public sysService: SysService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    //   this.route.queryParams.subscribe(params => {
    //     const token = params.token;
    //     if (token) {
    //       this.userService.saveToken(token);
    //       this.SysRadLoginView.getData(`/getuser?t=${token}`).then(data => {
    //         this.userService.saveUser(data);
    //         this.sysService.appRefresh();
    //         this.sysService.navigate(['/home']);
    //       });
    //     } else {
    //       if (this.userService.isLoggedIn()) {
    //         this.sysService.navigate(['/home']);
    //       }
    //     }
    //   });
    // }
    if (this.sysService.isAdmin()) {
      this.route.queryParams.subscribe(params => {
        const Radi = params.loginValue;
        const name = params.name;        
        const decodedValue = atob(Radi);
        if (Radi) {
          const Radi = params.loginValue;
          const decodedValue = atob(Radi);
          this.SysRadLoginView.toSave(`/admin/login?radId=${decodedValue}`,decodedValue , '' ,'')
            .then(data => {
              // this.userService.signOut()
              this.userService.saveUser(data);
              this.userService.saveToken(environment.backend.spring ? data.access_token : data.token);(data);
              this.sysService.appRefresh();
              const url = `${environment.app}/home?fromsysadmin=${name}`;  
              window.location.href = url;
            })
            .catch(error => {
              this.showDialog(name)
            });
        } else {
          if (this.userService.isLoggedIn()) {
            this.sysService.navigate(['/home']);
          }
        }
      });
    }

  }

  showDialog(name:any) {
    this.confirmationService.confirm({
      message: `You cannot log in to ${name}. Please try again later.`,
      acceptLabel: 'Try Again',
      rejectLabel: 'Back to  List',
      acceptIcon: 'pi pi-undo',
      rejectIcon: 'pi pi-arrow-left',
      closeOnEscape: false,
      accept: () => {
        window.location.reload();
      },
      reject: () => {
        if(this.sysService.isAdmin()){
          window.close();
                }else{
          this.router.navigate(['/']);

        }
      }
    });
  }
}


