import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ExportService, FormDefault, StaticService, SysService, ViewAction, ViewFactory } from '@app/_common';
import { Model, View } from '@app/_common/';
import { ActivatedRoute } from '@angular/router';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
@Component({
  selector: 'app-root',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.css']
})
export class StateComponent implements OnInit, ViewAction {

  stateExport = ['name', 'taxCode', 'code', 'countryId.name']; //for export header fields

  private stateView: View = this.viewFactory.newView();
  countryLookup: any[];

  constructor(
    public sysService: SysService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory) {
  }

  ngOnInit() {
    this.stateView.of(this.stateModel()).switchAction('/state', this.route, this);
  }

  get state() {
    return this.stateView.model;
  }

  private stateModel() {
    const formDefault = new FormDefault([], []);
    return new Model(this.formBuilder.group({
      id: [],
      name: ['', [Validators.required]],
      code: ['', [Validators.required]],
      taxCode: [],
      countryId: []
    }), this.sysService, formDefault, '');
  }

  add() {
    this.stateView.toNew();
  }

  edit(id: any) {
    this.stateView.toEditFetch(`/state/${id}`, id);
  }

  list(id: any) {
    this.listView(false);    
  }

  async listStateLazy(event?: LazyLoadEvent, search: boolean = false) {
    this.stateView.toListLazyFetch(`/state`, search, event);
  }

  listView(search: boolean = false) {
    this.stateView.toListLazyView(`/state`, search);
  }

  view(id: any) {
    this.stateView.toViewFetch(`/state/${id}`, id);
  }

  deleteStates() {
    this.stateView.toDeleteSelection(`/state`).then((data) => {
  
      this.listView(false) 
 
     })
  }

  deleteState(id: any) {
    this.stateView.toDeleteList(`/state/${id}`, "id", id);
  }

  saveState(add: boolean = false) {
    if (add) {
      this.stateView.toSaveNewFetch("/state", "id");
    } else {
      this.stateView.toSaveListFetch("/state", "id");
    }
  }

  filterCountry(event: any) {
    this.stateView.getData(`/country/lookup?filter=${event.query}`).then(data => { this.countryLookup = data; });
  }
}