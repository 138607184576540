import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SysService } from '@app/_common';
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
  export class MyProfileComponent implements OnInit {
    activeIndex: number = 0;  
    constructor(
      public sysService: SysService,
      private route: ActivatedRoute
    ) {}  
    ngOnInit() {
      this.route.queryParams.subscribe(params => {
        this.activeIndex = +params['activeIndex'] || 0;   //redirecting url params
      });
    }  
    handleChange(e) {
      this.sysService.clear();
      this.sysService.clearUnload();
      this.sysService.appRefresh();
    }
  }
  
