import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model } from '@app/_common';
import { AppConstants } from '@app/_config';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-short-code',
  templateUrl: './short-code.component.html',
  styleUrls: ['./short-code.component.css']
})
export class ShortCodeComponent {

  private shortCodeView: View = this.viewFactory.newView();
  saveButtonLabel: string = 'Save';
  countryLookup: any[];
  eventData: any;
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory
  ) { }
  ngOnInit() {
    this.scrollHeight()
    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 400));
    this.detectZoomChange()

    this.shortCodeView.of(this.shortCodeModel()).switchAction("/shortcode", this.route, this);
  }
  get shortCode() {
    return this.shortCodeView.model;
  }
  private shortCodeModel() {
    const formDefault = new FormDefault(["status"], [1]);
    return new Model(
      this.formBuilder.group({
        id: [],
        codeDescription: ["", [Validators.required,]],
        code: ["", [Validators.required, Validators.pattern(/^(?! ).*/)]],
        radiologistId: []


      }),
      this.sysService,
      formDefault,
      ""
    );
  }

  add() {

    this.shortCodeView.toNew();
    this.saveButtonLabel = "Save";


  }
  edit(id: any) {
    this.shortCodeView.toEditFetch(`/shortcode/${id}`, id);
    this.saveButtonLabel = "Update";

  }
  list(id: any) {
    this.listView(false);
  }
  async listshortCodeLazy(event?: LazyLoadEvent, search: boolean = false) {
    this.eventData = event
    this.shortCodeView.toListLazyFetch(`/shortcode`, search, event);
  }
  listView(search: boolean = false) {
    this.shortCodeView.toListLazyView(`/shorcCode`, search);
  }
  view(id: any) {
    this.shortCodeView.toViewFetch(`/shorcode/${id}`, id);
  }
  deleteshortCodes() {
    this.shortCodeView.toDeleteSelection(`/shortcode`, {
      onSuccess: () => {
        this.shortCodeView.toListLazyFetch(`/shortcode`, true, this.eventData);
      }
    });

  }
  deleteshortCode(id: any) {
    this.shortCodeView.toDeleteList(`/shortcode/${id}`, "id", id);
  }
  saveshortCode(add: boolean = false) {
    if (add) {
      this.shortCodeView.toSaveNewFetch("/shortcode", "id");
      this.shortCode.searchTxt;
    } else {
      // this.shortCodeView.toSaveListFetch("/shortCode", "id");
      this.shortCodeView.toSaveListFetch("/shortcode", "id", this.shortCode.isEdit() ? "Short Code Successfully updated." : "Short Code Successfully saved.");

      this.shortCode.searchTxt;
    }
  }
  filterCountry(event: any) {
    this.shortCodeView.getData(`/country/lookup?filter=${event.query}`).then((data) => {
      this.countryLookup = data;
    });
  }
  heightTable: any;
  debounceTimeout: any;
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 280) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 280) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  detectZoomChange() {
    this.scrollHeight();
  }
}
