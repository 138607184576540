import { Component, HostListener, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { FormDefault, Model, StaticService, SysService, View, ViewFactory, } from "@app/_common";
import { AppConstants } from "@app/_config";
import { environment } from "@environments/environment";
import { LazyLoadEvent } from "primeng/api";

@Component({
  selector: "app-install-request",
  templateUrl: "./install-request.component.html",
  styleUrls: ["./install-request.component.scss"],
})
export class InstallRequestComponent implements OnInit {
  stateExport = ["name", "taxCode", "code", "countryId.name"]; //for export header fields
  private InstallationView: View = this.viewFactory.newView();
  countryLookup: any[];
  preferredTimeToContact: Date;
  disabled: boolean = true;
  eventData: any;
  isEdit: boolean = false;
  saveButtonLabel: string = 'Save';
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory
  ) { }
  isScrollTable = window.innerWidth > environment.devices.tablet ? true : false;
  heightDisplay = window.innerHeight - 260 + "px";
  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.isScrollTable =
      window.innerWidth > environment.devices.tablet ? true : false;
    this.heightDisplay = window.innerHeight - 280 + "px";
  }
  ngOnInit() {
    this.scrollHeight()
    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 400));
    this.detectZoomChange()

    this.InstallationView.of(this.InstallationModel()).switchAction(
      "/installationrequest?",
      this.route,
      this
    );

  }
  get Installation() {
    return this.InstallationView.model;
  }

  private InstallationModel() {
    const formDefault = new FormDefault(['status', 'progresStatus', 'priorityStatus'], [1, 4, 3]);
    return new Model(
      this.formBuilder.group({
        id: [],
        centreName: ["", [Validators.required]],
        contactPerson: ["", [Validators.required]],
        createdAt: [""],
        contactNumber: ["", [Validators.required, Validators.pattern(AppConstants.regexPhonenumber),],],
        referencePerson: [""],
        referenceNumber: [""],
        preferredTimeToContact: [, [Validators.required]],
        status: ["", [Validators.required]],
        comments: [""],
        progresStatus: [this.sysService.isAdmin() ? '' : Validators.required],
        priorityStatus: [this.sysService.isAdmin() ? '' : Validators.required],
        email: ["", [Validators.required, Validators.pattern(AppConstants.regexEmail),],],

      }),
      this.sysService,
      formDefault,
      ""
    );
  }
  add() {
    this.InstallationView.toNew();
    this.saveButtonLabel = "Save";
    if (this.sysService.isCenter()) {
      this.InstallationView.getFormData('/installationrequest/auto-fill')
    }
  }
  edit(id: any) {
    this.InstallationView.toEditFetch(`/installationrequest/${id}`, id);
    this.saveButtonLabel = "Update";
  }
  list(id: any) {
    this.listView(false);
  }
  async listInstallationLazy(event?: LazyLoadEvent, search: boolean = false) {
    this.eventData = event
    this.InstallationView.toListLazyFetch(`/installationrequest`, search, event);
  }
  listView(search: boolean = false) {
    this.InstallationView.toListLazyView(`/installationrequest`, search);
  }
  view(id: any) {
    this.InstallationView.toViewFetch(`/installationrequest/${id}`, id);
  }
  deleteInstallations() {
    this.InstallationView.toDeleteSelection(`/installationrequest`, {
      onSuccess: () => {
        this.InstallationView.toListLazyFetch(
          `/installationrequest`, true, this.eventData);
      }
    });

  }
  deleteInstallation(id: any) {
    this.InstallationView.toDeleteList(`/installationrequest/${id}`, "id", id);
  }
  saveInstallation(add: boolean = false) {
    if (this.sysService.isAdmin()) {
      if (add) {
        this.InstallationView.toSaveNewFetch("/installationrequest", "id");
      } else {
        this.InstallationView.toSaveListFetch("/installationrequest", "id", this.Installation.isEdit() ? "Installation Request Successfully updated." : "Installation Request Successfully saved.");

      }
    } else {

      this.Installation.isEmpty('progresStatus')
      this.Installation.isEmpty('priorityStatus')
      if (add) {
        this.InstallationView.toSaveNewFetch("/installationrequest", "id");
      } else {
        this.InstallationView.toSaveListFetch("/installationrequest", "id", this.Installation.isEdit() ? "Installation Request Successfully updated." : "Installation Request Successfully saved.");

      }
    }
  }
  filterCountry(event: any) {
    this.InstallationView.getData(`/country/lookup?filter=${event.query}`).then(
      (data) => {
        this.countryLookup = data;
      }
    );
  }
  heightTable: any;
  debounceTimeout: any;
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 280) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 280) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  detectZoomChange() {
    this.scrollHeight();
  }
}
