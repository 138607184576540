<div *ngIf="!sysService.isPermission()">
    <not-found></not-found>
</div>

<div *ngIf="sysService.isPermission()">
    <p-toolbar>

        <div class="p-toolbar-group-start">
            <f-nav first="/equipment" label="Equipments" [model]="equipment">
            </f-nav>
        </div>
        <div class="p-toolbar-group-end">
            <button pButton pRipple type="button" label="Delete" icon="pi pi-trash" class="p-button-outlined
      p-button-outlined p-button-danger" (click)="deleteEquipment(equipment.id)" *ngIf="equipment.isEdit()"></button>
        </div>
    </p-toolbar>
    <form [formGroup]="equipment.form">
        <div *ngIf="equipment.isform || equipment.isView()" class="card">
            <div class="grid">
                <div class="field col-12 md:col-6 lg:col-6">
                    <label>Equipment Name <span class="validation-star">*</span></label>
                    <input pInputText maxlength="20" formControlName="name" type="text" pAutoFocus [autofocus]="true">
                    <f-error [err]="equipment.required('name')" msg="Equipment Name
                    is required"></f-error>
                    <f-error [err]="equipment.pattern('name')" msg="White space not accepted"></f-error>
                </div>
                <div class="field col-12 md:col-12 lg:col-6">
                    <label for="modalityId">Modality <span class="validation-star">*</span></label>
                    <p-autoComplete formControlName="modalityId" [suggestions]="modalityLookup"
                        (completeMethod)="filterModality($event)" field="code" ariaLabel="Modality" [dropdown]="true"
                        forceSelection="true" [autoHighlight]="true" showEmptyMessage="true" delay="400" required
                        maxlength="7">
                    </p-autoComplete>
                    <f-error [err]="equipment.required('modalityId')" msg="Modality is required"></f-error>
                </div>
                <div class="field col-12 md:col-6 lg:col-6">
                    <label>Station Name <span class="validation-star">*</span></label>
                    <input pInputText maxlength="25" formControlName="stationName" type="text">
                    <f-error [err]="equipment.required('stationName')" msg="Station
                    Name is required"></f-error>
                    <f-error [err]="equipment.pattern('stationName')" msg="White space not accepted"></f-error>

                </div>
                <div class="field col-12 md:col-6 lg:col-6">
                    <label>Station AET <span class="validation-star">*</span></label>
                    <input pInputText maxlength="48" formControlName="stationAet" type="text">
                    <f-error [err]="equipment.required('stationAet')" msg="Station
                    AET is required"></f-error>
                    <f-error [err]="equipment.pattern('stationAet')" msg="Station AET should not contain white space ">
                    </f-error>
                </div>
                <div class="field col-12 md:col-6 lg:col-6">
                    <label>Status </label>
                    <p-dropdown formControlName="status" inputId="status" placeholder="Select"
                        [options]="staticService.status" required="true">
                    </p-dropdown>
                    <f-error [err]="equipment.required('status')" msg="Status is
                    required"></f-error>
                </div>
                <div class="field col-12 md:col-6 lg:col-12">
                    <label>Description </label><textarea [rows]="5" [cols]="40"
                        class="p-inputtextarea p-inputtext p-component p-element" maxlength="150"
                        formControlName="description" pInputTextarea></textarea>
                </div>
            </div>
            <div class="formgrid text-center bc">
                <button pButton pRipple type="button" label="Cancel" icon="pi pi-times"
                    class="p-button-outlined p-button-info" (click)="listView(equipment.isEmptyList());"></button>
                <button pButton pRipple type="button" [label]="saveButtonLabel " icon="pi pi-check"
                    class="p-button-raised p-button-success" (click)="saveEquipment()" [disabled]="sysService.loading"
                    *ngIf="equipment.isForm()">
                    <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                </button>
                <button pButton pRipple type="button" label=" Save & Add" icon="pi pi-plus"
                    class="p-button-raised p-button-info" (click)="saveEquipment(true)" [disabled]="sysService.loading"
                    *ngIf="equipment.isNew()">
                    <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                </button>
            </div>
        </div>
    </form>
    <div class="table-responsive" [style.display]="equipment.islist ? 'block':'none'">
        <p-table #dt [value]="equipment.list" scrollHeight="65vh" [totalRecords]="equipment.total"
            class="study-list-class" [scrollHeight]="heightTable" [lazy]="true" selectionMode="multiple"
            (onLazyLoad)="listequipmentLazy($event);" dataKey="id" [(selection)]="equipment.selection"
            [loading]="sysService.loading" filterDelay="400"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" [rowHover]="true"
            styleClass="p-datatable-sm" [rows]="25" [paginator]="true" [rowsPerPageOptions]="[25,50,]"
            [showCurrentPageReport]="true" alwaysShow="false" paginatorPosition="bottom"
            styleClass="p-datatable-sm p-datatable-gridlines"
            [globalFilterFields]="['Equipmentname','description','modality','status','stationName','stationAet']">
            <ng-template pTemplate="caption">
                <div class="grid">
                    <div class="ml-2">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" maxlength="150" [(ngModel)]="equipment.searchTxt" (input)="dt.filterGlobal($event.target.value,
                            'contains')" placeholder="Search..." pAutoFocus [autofocus]="true" />
                        </span>
                    </div>
                    <div class="mt-2 ml-2">
                        <span>
                            <button pButton pRipple type="button" label="Add Equipment" icon="pi pi-plus"
                                class="p-button-raised" *ngIf="equipment.isList()" (click)="add()"></button>
                        </span>
                    </div>
                    <div class="mt-2">
                        <button pButton pRipple type="button" label="Delete" icon="pi pi-trash"
                            class="p-button-outlined p-button-danger" (click)="deleteEquipments()" *ngIf="!equipment.isEmptyList() &&
        equipment.selection.length> 0"></button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="paginatorleft">
                <!-- <button pButton pRipple type="button"  label="" icon="pi pi-trash"
                class="p-button-outlined p-button-rounded p-button-danger"
                (click)="deleteEquipments()"
                [disabled]="equipment.selection.length <1"
                *ngIf="!equipment.isEmptyList()"></button> -->
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th pSortableColumn="name" style="min-width:117px;">Name <p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="stationName">Station Name <p-sortIcon field="stationName">
                        </p-sortIcon>
                    </th>
                    <th pSortableColumn="stationAet">Station AET <p-sortIcon field="stationAet">
                        </p-sortIcon>
                    </th>
                    <th pSortableColumn="modalityId.code">Modality<p-sortIcon field="modalityId.code">
                        </p-sortIcon>
                    </th>
                    <th pSortableColumn="status">Status<p-sortIcon field="status">
                        </p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-equipment>
                <tr [ngClass]="{'ui-state-highlight': chk.checked}">
                    <td>
                        <p-tableCheckbox #chk [value]="equipment"></p-tableCheckbox>
                    </td>
                    <td><i class="pi pi-file-edit link-study" (click)="edit(equipment.id)" title="edit"> </i>&nbsp;
                        {{equipment?.name}}

                    </td>
                    <td><span class="p-column-title"><b>station Name</b></span>
                        {{equipment?.stationName}}</td>
                    <td><span class="p-column-title"><b>statio nAet</b></span>
                        {{equipment?.stationAet}}</td>
                    <td><span class="p-column-title"><b>modality</b></span>
                        {{equipment?.modalityId?.code}} </td>
                    <td *ngIf="equipment?.status ===0 ||equipment?.status == null " class="text-danger">Inactive
                    </td>
                    <td *ngIf="equipment?.status ===1" class="text-success">Active</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td class="norecordstudy" [attr.colspan]="8">No records found</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</div>