<div *ngIf="!sysService.isGuest()">
  <p-tabView [activeIndex]="activeIndex" (onChange)="handleChange($event)">
  <p-tabPanel header="My Profile">
    <app-Radiologist [activeIndex]="activeIndex" *ngIf="sysService.isRad()"></app-Radiologist>
    <app-centre-profile *ngIf="sysService.isCenter()"></app-centre-profile>
    <app-admin-profile *ngIf="sysService.isAdmin()"></app-admin-profile>
    <app-user-role-profile *ngIf="sysService.isApprover()"></app-user-role-profile>
    <app-user-role-profile *ngIf="sysService.isAssigner()"></app-user-role-profile>
    <app-user-role-profile *ngIf="sysService.isTechical()"></app-user-role-profile>
    <app-user-role-profile *ngIf="sysService.isPhysician() || sysService.isRadSub()"></app-user-role-profile>

  </p-tabPanel>
  <p-tabPanel *ngIf="sysService.isCenter() || sysService.isRad()" header="Billing Information ">
    <app-billing></app-billing>
  </p-tabPanel>
  <p-tabPanel header="Change Password">
    <app-change-pass></app-change-pass>
  </p-tabPanel>
  <p-tabPanel header="User Settings">
    <app-user-setting></app-user-setting>
  </p-tabPanel>
</p-tabView>
</div>

<div *ngIf="sysService.isGuest()">
  <not-found></not-found>

</div>