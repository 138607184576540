import { HttpHeaders } from "@angular/common/http";
import { environment } from "@environments/environment";

//Configuration paths
export class AppHeader {

  static springLogin = new HttpHeaders()
    .set('Authorization', 'Basic ' + btoa(environment.auth.clientId + ':' + environment.auth.clientSecret))
    .set('Content-Type', 'application/x-www-form-urlencoded');

  constructor() { }

  public static getLoginHeader() {
    if (environment.backend.spring)
      return this.springLogin;
    else if (environment.backend.node || environment.backend.php || environment.backend.springjwt)
      return this.getHeader();
    else
      return this.getHeader();
  }
  public static getHeader(contentType:string | null = 'application/json') {
    if(contentType)
    return new HttpHeaders({ 'Content-Type': contentType });
    else
    return new HttpHeaders();
  }
}

