import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model, ViewAction } from '@app/_common';
import { AppConstants } from '@app/_config';
import { environment } from '@environments/environment';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
@Component({
  selector: 'app-receivable',
  templateUrl: './receivable.component.html',
  styleUrls: ['./receivable.component.scss'],
  providers: [DatePipe],

})
export class ReceivableComponent implements OnInit {
  @ViewChild("calendarRef") calendar: any;

  private receivableView: View = this.viewFactory.newView();
  private rateCardView: View = this.viewFactory.newView();
  selectedRange: Date[];
  changeRate: boolean = false;
  receivableYearList: any[];
  radLookup: any[];
  cenLookup: any[];
  modLookup: any[];
  destUserList: any[]
  userLookup: any[];
  totalamount: any;
  charge: any;
  search: boolean = false;
  totalCharge: any;
  openingBalance: any;
  totals: any;
  totalCharges: any;
  checked: boolean = false;
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private datePipe: DatePipe,
    private confirmationService: ConfirmationService,
    private viewFactory: ViewFactory) {
  }
  ngOnInit() {
    this.receivableView.of(this.receivableModel());
    this.rateCardView.of(this.rateCardModel());
    this.receivable.set('isReportDate', false)
    this.receivableYear()
    this.scrollHeight()
    this.detectZoomChange()

    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 400));

  }
  get receivable() { return this.receivableView.model; }
  get cardRate() { return this.rateCardView.model; }
  private rateCardModel() {
    const formDefault = new FormDefault(['isReportDate'], [true]);
    return new Model(this.formBuilder.group({
      isCharge: [],
      reportId: [],
      isReportDate: [''],
      isMyRate: [],
      paymentDetails: [''],
      isPaymentNote: [],
      amount: [Validators.required, Validators.pattern(AppConstants.regexZipCpde)],


    }), this.sysService, formDefault, '');
  }
  private receivableModel() {
    const formDefault = new FormDefault(['isReportDate'], [true]);
    return new Model(this.formBuilder.group({
      id: [],
      reportId: [],
      month: new FormControl(new Date().getMonth() + 1),
      year: new FormControl(new Date().getFullYear()),
      name: [],
      radId: [],
      cenId: [],
      modality: [],
      isPaymentNote: [],
      date: [],
      userId: [],
      paymentStatus: [],
      charge: [],
      isCharge: [],
      isMyRate: [],
      reportIds: [],
      isReportDate: [''],
      paymentDetails: [''],
      destUserId: [],
      time: [''],
      toTime: [
        "",
        [
          Validators.required,
          Validators.pattern("^(?:[01]\\d|2[0-3]):(?:[0-5]\\d)$"),
        ],
      ],

      FromTime: [
        "",
        [
          Validators.required,
          Validators.pattern("^(?:[01]\\d|2[0-3]):(?:[0-5]\\d)$"),
        ],
      ],
      nightTime: [],
      amount: [, Validators.pattern(AppConstants.regexZipCpde)],
    }), this.sysService, formDefault, '');
  }
  add() {
    this.receivableView.toNew();
  }
  edit(id: any) {
    this.receivableView.toNew();
  }
  list(id: any) {
    this.listView(false)
  }
  listView(search: boolean = false) {
    this.receivableView.toListLazyView(`/revenue/radiologist-revenue`, search);
  }
  async listRateCardListLazy(event?: LazyLoadEvent, search: boolean = false) {

    this.setParaFunction()
    // if (this.checkDateCondition()) {
    const startFromTime = String(this.receivable.get('FromTime'));
    const startEndTime = String(this.receivable.get('toTime'));

    if (startFromTime && !startEndTime) {
      this.sysService.error('Please select To time');
      return;
    } else if (!startFromTime && startEndTime) {
      this.sysService.error('Please select From time');
      return;
    }

    if (startFromTime && startEndTime) {
      const time = `${startFromTime}-${startEndTime}`;
      this.receivable.setParam("nightTime", time);
    } else {
      this.receivable.setParam("nightTime", null);
    }
    if ((startFromTime && startEndTime) || (!startFromTime && !startEndTime)) {
      this.receivableView.toListLazyFetch(`/revenue/radiologist-revenue`, this.search, event).then(data => {
        this.totalamount = data.totalAmount
        this.charge = data.charge
        this.openingBalance = data.openingBalance

      })
    }
    // }
    this.search = false;
  }
  selectedValue() {
    let totalCharge = 0;
    for (const item of this.receivable.selection) {
      totalCharge += item.charge;
    }
    this.totalCharge = totalCharge;
  }
  view(id: any) {
    this.receivableView.toViewFetch(`/revenue/radiologist-revenue/${id}`, id);
  }
  onDataSelectionChange(selectedMonth: any) {
    this.receivable.set('date', '')
  }
  onDateSelect(selectedDate: any) {
    this.receivable.set('month', null)
    this.receivable.set('year', null)
  }
  searchreceivable() {
    this.search = true;
    this.receivable.selection = [];
    this.selectedValue()
  }
  isScrollTable = window.innerWidth > environment.devices.tablet ? true : false;
  heightDisplay = window.innerHeight - 450 + "px";
  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.isScrollTable =
      window.innerWidth > environment.devices.tablet ? true : false;
    this.heightDisplay = window.innerHeight - 450 + "px";
  }
  receivableYear() {
    this.receivableView.getData(`/reports/year`).then(data => { this.receivableYearList = data; })
  }
  searchStudy() {
  }
  filterRad(event: any) {
    this.receivableView.getData(`/radiologist/radiologist-lookup?filter=${event.query}`).then(data => {
      this.radLookup =
        data;
    });
  }
  filteruser(event: any) {
    this.receivableView.getData(`/users/associated-lookup?filter=${event.query}`).then(data => {
      this.userLookup =
        data;
    });
  }
  filterCen(event: any) {
    this.receivableView.getData(`/centre/centre-lookup?filter=${event.query}`).then(data => {
      this.cenLookup =
        data;
    });
  }
  filterMod(event: any) {
    this.receivableView.getData(`/modality/modality-lookup?filter=${event.query}`).then(data => {
      this.modLookup =
        data;
    });
  }


  filterDestinationRadiologist(event: any) {
    this.receivableView.getData(`/radiologist/radiologist-lookup?filter=${event.query}`).then(data => {
      this.destUserList =
        data;
    });
  }
  resetReceivable() {
    this.receivable.reset();
    this.search = true;
    let month = new Date().getMonth() + 1
    let year = new Date().getFullYear()
    this.receivable.set('month', month);
    this.receivable.set('year', year);
    this.receivable.set('isReportDate', false)
    this.receivable.selection = [];
    this.totalCharge = ''
    let fromTime = this.receivable.get('FromTime');
    let toTime = this.receivable.get('toTime');
    this.receivable.set('FromTime', fromTime === null ? '' : fromTime);
    this.receivable.set('toTime', toTime === null ? '' : toTime);
  }
  setParaFunction() {
    const rangeDate = String(this.receivable.get("date"));
    const startDate = new Date(rangeDate.split(",")[0]);
    const endDate = new Date(rangeDate.split(",")[1]);
    const formattedStartDate = `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() +
      1).toString().padStart(2, '0')}/${startDate.getFullYear()}`;
    const formattedEndDate = `${endDate.getDate().toString().padStart(2, '0')}/${(endDate.getMonth() +
      1).toString().padStart(2, '0')}/${endDate.getFullYear()}`;
    const date = `${formattedStartDate}-${formattedEndDate}`;
    this.receivable.setParam("date", date)

    if (!this.receivable.isEmpty("date")) {
      this.receivable.setParam("isReportDate", false);
    }
    this.receivable.setParam("cenId", this.receivable.get("cenId")?.id)
    this.receivable.setParam("userId", this.receivable.get("userId")?.id)
    this.receivable.setParam("paymentStatus", this.receivable.get("paymentStatus"))
    this.receivable.setParam("radId", this.receivable.get("radId")?.id)
    this.receivable.setParam("isReportDate", this.receivable.get("isReportDate"))
    this.receivable.setParam("modality", this.receivable.get("modality")?.name)
    this.receivable.setParam("month", this.receivable.get("month"))
    this.receivable.setParam("year", this.receivable.get("year"))
    this.receivable.setParam("destUserId", this.receivable.get("destUserId")?.id)

  }

  export(receivable: number) {
    // if (this.checkDateCondition()) {
    const fileName = this.exportFunction(receivable);
    if (receivable === 1) {
      this.receivableView.download(`/revenue/download-revenue-pdf?&isReceivable=true`, fileName)
    }
    else {
      this.receivableView.download(`/revenue/download-revenue-excel?isReceivable=true`, fileName)
    }
    // }

  }

  exportFunction(receivable) {
    this.setParaFunction()
    if (this.receivable.selection.length > 0) {
      const ids = this.receivable.selection.map(item => item.id);
      this.receivable.setParam("reportIds", ids)
    } else {
      const ids = this.receivable.selection = [];
      this.receivable.setParam("reportIds", ids)
      this.receivable.setParam("paymentStatus", this.receivable.get("paymentStatus"))
    }
    if (receivable === 1) {
      const pdfFilename = 'receivable.pdf';
      return pdfFilename;
    } else {
      const excelName = 'receivable.xls';
      return excelName
    }
  }
  checkDateCondition() {
    this.sysService.clear();
    const toTime: Date = this.receivable.get("toTime") || new Date();
    const fromTime: Date = this.receivable.get("fromTime") || new Date();
    const fromTimeFormatted: string = this.sysService.getFormattedTime(fromTime, 'hh:mm');

    const toTimeFormatted: string = this.sysService.getFormattedTime(toTime, 'hh:mm');
    const timeRange: string = ` ${fromTimeFormatted}-${toTimeFormatted}`;

    if (this.receivable.isEmpty("toTime") && !this.receivable.isEmpty("fromTime")) {
      this.receivable.setParam("nightTime", timeRange);
      this.sysService.error("Please select To time");
      return false;
    }

    if (!this.receivable.isEmpty("toTime") && this.receivable.isEmpty("fromTime")) {
      this.receivable.setParam("nightTime", timeRange);
      this.sysService.error("Please select From time");
      return false;
    }

    if (!this.receivable.isEmpty("toTime") && !this.receivable.isEmpty("fromTime")) {
      this.receivable.setParam("nightTime", timeRange);
      return true;
    }

    if (this.receivable.isEmpty("toTime") && this.receivable.isEmpty("fromTime")) {

      return true;
    }

    return false; // Add a default return statement if necessary
  }
  statusChange(receivable) {

    this.confirmationService.confirm({
      header: `Payment Status `,
      message: `Do you want to change the status`,
      acceptLabel: 'Confirm',
      rejectLabel: 'Cancel',
      accept: () => {
        let changeStatus
        if (receivable.paymentStatus === 0) {
          changeStatus = 1
        } else {
          changeStatus = 0
        }
        this.receivable.set("reportId", receivable.id);
        this.receivableView.getData(`/reports/update-payment-status?reportIds=${receivable.id}`, 'reportId')
          .then(data => {
            this.sysService.success('Payment Status Changed Successfully');
            this.receivable?.list?.forEach((x) => {
              if (x.id === receivable.id) {
                x.paymentStatus = changeStatus;
              }
            });
          })
          .catch(error => {
            // Handle the error here, you might want to show an error message to the user
            this.sysService.error('An error occurred while changing payment status');
          });
      }
    });
  }
  allStatus(receivable: any, number: number) {
    const selectedIds: any[] = []; // Use a specific type if possible

    if (!receivable.isEmptyList(receivable.selection)) {
      for (const k of receivable.selection) {
        selectedIds.push(k.id);
      }
    }

    receivable.selection.forEach((item: any) => {
      // Assuming that the 'paymentStatus' property exists in your item object
      item.paymentStatus = number;
    });

    this.receivableView.getData(`/reports/update-payment-status?reportIds=${selectedIds.join(',')}&status=${number}`,
      'reportId')
      .then(data => {
        this.sysService.success('Payment Status Changed Successfully');
      });
    setTimeout(() => {
      this.sysService.clear();
    }, 2000);
  }

  rateChange(receivable: any) {
    this.cardRate.reset()
    this.sysService.clear()
    this.cardRate.set("amount", receivable.charge)
    this.cardRate.set("reportId", receivable.id)
    this.cardRate.set("isCharge", receivable.isCharge)
    this.cardRate.set("isMyRate", receivable.isMyRate)
    this.cardRate.set("paymentDetails", receivable.paymentNote)
    this.cardRate.set("isPaymentNote", receivable.isPaymentNote)
    this.changeRate = true;

  }
  rateSubmit() {
    if (this.cardRate.get('isMyRate') === null) {
      this.cardRate.set("isMyRate", false);
    }
    const id = this.cardRate.get("reportId")
    let paymentNoteValue = this.cardRate.get('paymentDetails')


    if (this.rateCardView.isValid()) {

      this.rateCardView.toSave(`/revenue/update-charge?reportId=${this.cardRate.get("reportId")}&amount=${this.cardRate.get("amount")}&isCharge=${this.cardRate.get("isCharge")}&isMyRate=${this.cardRate.get("isMyRate")}&paymentNote=${paymentNoteValue}`,
        "reportId", 'Rate Changed Successfully')
        .then(data => {
          this.sysService.success('Rate Changed Successfully ');
          this.changeRate = false;
          this.receivable?.list?.forEach((x) => {
            if (x.id === id) {
              x.charge = data.amount;
              this.totalamount = data.totalAmount;
              x.paymentNote = paymentNoteValue;


            }
          });

        });
    }
  }

  cancelRateChange() {
    this.changeRate = false;
    this.list(true);
  }

  markAsDuplicate(receivable: any) {
    if (this.receivable.selection.length > 0) {
      const ids = this.receivable.selection.map(item => item.id);
      this.receivable.setParam("ids", ids);

      this.receivableView.getData("/revenue/mark-as-duplicate").then(data => {
        this.receivable?.list?.forEach((x) => {
          if (ids.includes(x.id)) {
            x.isDuplicate = false;
            x.isMarkedDuplicate = true;
          }
        });
        this.sysService.success("Successfully marked as duplicate");

      })
    }
  }
  unMarkAsDuplicate(receivable: any) {
    if (this.receivable.selection.length > 0) {
      const ids = this.receivable.selection.map(item => item.id);
      this.receivable.setParam("ids", ids);

      this.receivableView.getData("/revenue/unmark-as-duplicate").then(data => {
        this.receivable?.list?.forEach((x) => {
          if (ids.includes(x.id)) {
            x.isDuplicate = false;
            x.isMarkedDuplicate = false;
          }
        });
        this.sysService.success("Successfully un-marked as duplicate");

      })
    }

  }
  changesCalendarClear(dt) {
    this.selectedRange = [];
    this.calendarSelected = false;
    this.receivable.reset();
    this.sysService.clear();
    this.receivable.set('isReportDate', false)
    this.search = false
    let month = new Date().getMonth() + 1
    let year = new Date().getFullYear()
    this.receivable.set('month', month);
    this.receivable.set('year', year);
    this.receivable.selection = [];
    this.totalCharge = ''
    this.calendar.hideOverlay();
  }
  dateRange;
  calendarSelected = false;
  calenedarChangeDate(event, dt) {
    this.dateRange = "";
    if (this.selectedRange) {

      const startDate = this.datePipe.transform(
        this.selectedRange[0],
        "dd/MM/yyyy"
      );
      const endDate = this.datePipe.transform(
        this.selectedRange[1],
        "dd/MM/yyyy"
      );
      this.dateRange = `${startDate}-${endDate}`;
      this.listRateCardListLazy(dt);
    }
    this.calendar.hideOverlay();
  }

  formatTimeInput(event: any) {
    const input = event.target;
    let value = input.value?.replace(/\D/g, ""); // Remove non-digit characters

    if (value.length > 0) {
      // Insert ":" character between hours and minutes if necessary
      if (value.length > 2) {
        value = value.slice(0, 2) + ":" + value.slice(2);
      }
      // Ensure hours do not exceed 23
      const hours = parseInt(value.substring(0, 2), 10);
      if (hours > 23) {
        value = "23" + value.slice(2);
      }
      // Ensure minutes do not exceed 59
      const minutes = parseInt(value.substring(3, 5), 10);
      if (minutes > 59) {
        value = value.substring(0, 3) + "59";
      }
    }

    input.value = value;
  }
  heightTable: any;
  debounceTimeout: any;
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 500) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 500) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  detectZoomChange() {
    this.scrollHeight();
  }
}