import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { UserService, SysService } from '@app/_common';
import { AppConstants } from '@app/_config';
import { HTTP_INTERCEPTORS, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private sysService: SysService,
    private userService: UserService,
    private http: HttpClient
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        // retry(1),
        catchError((err: HttpErrorResponse) => {
          // if (request.url.match(environment.logApi)) {
          //   // special handling here
          //   return throwError(() => null);
          // } else 
          if (err.status === 401) {
            this.sysService.error(AppConstants.err401);
            this.sysService.signOut();
          } else if (err.status === 403) {
            this.sysService.error(AppConstants.err403);
          } else if (err.status === 500) {
            this.sysService.error(AppConstants.err500);
          } else if (err.status === 422 || err.status === 409 || err.status === 404 || err.status === 406) {
            this.sysService.handleError(err);
          // } else if (err.status === 0) {
          //   this.sysService.log(environment.logApi, err);
          //   this.sysService.error(AppConstants.errUnknow);
          } else {
            // this.sysService.log(environment.logApi, err);
            this.sysService.error(AppConstants.errUnknow);
          }
          return throwError(() => err);
        })

      )
  }
}

export const ErrorInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
];