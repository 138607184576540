import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model, ViewAction } from '@app/_common';
import { environment } from '@environments/environment';
import { LazyLoadEvent } from 'primeng/api';
@Component({
  selector: 'app-payable',
  templateUrl: './payable.component.html',
  styleUrls: ['./payable.component.scss'],
  providers: [DatePipe],
})

export class PayableComponent implements OnInit, ViewAction {
  @ViewChild("calendarRef") calendar: any;

  private payableView: View = this.viewFactory.newView();
  private dataView: View = this.viewFactory.newView();
  selectedRange: Date[];
  userLookup: any[];
  PayableYearList: any[];
  radLookup: any[];
  cenLookup: any[];
  modLookup: any[];
  totalCharge: any;
  totalamount: any;
  search: boolean = false;
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private datePipe: DatePipe,
    private viewFactory: ViewFactory) {
  }
  ngOnInit() {
    this.scrollHeight()
    this.detectZoomChange()

    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 400));

    this.payableView.of(this.payableModel(), false);
    this.payableView.of(this.payableModel()).list('/revenue/radiologist-payables');
    this.Payable.set('isReportDate', false)
    this.PayableYear()
  }
  get Payable() { return this.payableView.model; }
  private payableModel() {
    const formDefault = new FormDefault(['isReportDate'], [false]);
    return new Model(this.formBuilder.group({
      month: new FormControl(new Date().getMonth() + 1),
      year: new FormControl(new Date().getFullYear()),
      name: [''],
      radiologistName: [''],
      centreName: [''],
      modality: [''],
      date: [''],
      time: [''],
      userId: [''],
      radId: [],
      reportIds: [],
      paymentStatus: [],
      isReportDate: [''],
      toTime: [
        "",
        [
          Validators.required,
          Validators.pattern("^(?:[01]\\d|2[0-3]):(?:[0-5]\\d)$"),
        ],
      ],

      FromTime: [
        "",
        [
          Validators.required,
          Validators.pattern("^(?:[01]\\d|2[0-3]):(?:[0-5]\\d)$"),
        ],
      ],
      nightTime: [],
    }), this.sysService, formDefault, '');
  }
  add() {
    this.payableView.toNew();
  }
  edit(id: any) {
    this.payableView.toNew();
  }
  list(id: any) {
    this.listView(false);
  }
  listView(search: boolean = false) {
    this.payableView.toListLazyView(`/revenue/radiologist-payables`, search);
  }
  async listratecardListLazy(event?: LazyLoadEvent, search: boolean = false) {
    this.Payable.selection = [];
    const rangeDate = String(this.Payable.get("date"));
    const startDate = new Date(rangeDate.split(",")[0]);
    const endDate = new Date(rangeDate.split(",")[1]);
    const formattedStartDate = `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() +
      1).toString().padStart(2, '0')}/${startDate.getFullYear()}`;
    const formattedEndDate = `${endDate.getDate().toString().padStart(2, '0')}/${(endDate.getMonth() +
      1).toString().padStart(2, '0')}/${endDate.getFullYear()}`;
    const date = `${formattedStartDate}-${formattedEndDate}`;
    this.Payable.setParam("date", date);

    const startFromTime = String(this.Payable.get('FromTime'));
    const startEndTime = String(this.Payable.get('toTime'));

    if (startFromTime && !startEndTime) {
      this.sysService.error('Please select To time');
      return;
    } else if (!startFromTime && startEndTime) {
      this.sysService.error('Please select From time');
      return;
    }

    if (startFromTime && startEndTime) {
      const time = `${startFromTime}-${startEndTime}`;
      this.Payable.setParam("nightTime", time);
    } else {
      this.Payable.setParam("nightTime", null);
    }

    this.Payable.setParam("centreName", this.Payable.get("centreName")?.id);
    this.Payable.setParam("userId", this.Payable.get("userId")?.id);
    this.Payable.setParam("paymentStatus", this.Payable.get("paymentStatus"));
    this.Payable.setParam("modality", this.Payable.get("modality")?.name);
    this.Payable.setParam("month", this.Payable.get("month"));
    this.Payable.setParam("year", this.Payable.get("year"));
    this.Payable.setParam("isReportDate", this.Payable.get("isReportDate"));
    this.Payable.setParam("radiologistName", this.Payable.get('radiologistName')?.id);
    this.Payable.setParam("radId", this.Payable.get("radId")?.id);

    if ((startFromTime && startEndTime) || (!startFromTime && !startEndTime)) {
      this.payableView.toListLazyFetch(`/revenue/radiologist-payables`, this.search, event).then(data => {
        this.totalamount = data.totalAmount;
      });
      this.search = false;
    }
  }



  formatTimeInput(event: any) {
    const input = event.target;
    let value = input.value?.replace(/\D/g, ""); // Remove non-digit characters

    if (value.length > 0) {
      // Insert ":" character between hours and minutes if necessary
      if (value.length > 2) {
        value = value.slice(0, 2) + ":" + value.slice(2);
      }
      // Ensure hours do not exceed 23
      const hours = parseInt(value.substring(0, 2), 10);
      if (hours > 23) {
        value = "23" + value.slice(2);
      }
      // Ensure minutes do not exceed 59
      const minutes = parseInt(value.substring(3, 5), 10);
      if (minutes > 59) {
        value = value.substring(0, 3) + "59";
      }
    }

    input.value = value;
  }
  markAsDuplicate(Payable: any) {
    if (this.Payable.selection.length > 0) {
      const ids = this.Payable.selection.map(item => item.id);
      this.Payable.setParam("ids", ids);

      this.payableView.getData("/revenue/mark-as-duplicate").then(data => {
        this.Payable?.list?.forEach((x) => {
          if (ids.includes(x.id)) {
            x.isDuplicate = false;
            x.isMarkedDuplicate = true;
          }
        });
        this.sysService.success("Successfully marked as duplicate");

      })
    }
  }


  unMarkAsDuplicate(Payable: any) {
    if (this.Payable.selection.length > 0) {
      const ids = this.Payable.selection.map(item => item.id);
      this.Payable.setParam("ids", ids);

      this.payableView.getData("/revenue/unmark-as-duplicate").then(data => {
        this.Payable?.list?.forEach((x) => {
          if (ids.includes(x.id)) {
            x.isDuplicate = false;
            x.isMarkedDuplicate = false;
          }
        });
        this.sysService.success("Successfully un-marked as duplicate");

      })
    }
  }





  checkDateCondition() {
    this.sysService.clear();
    // const toTime: Date = this.Payable.get("toTime") || new Date();
    // const fromTime: Date = this.Payable.get("fromTime") || new Date();
    // const fromTimeFormatted: string = this.sysService.getFormattedTime(fromTime, 'hh:mm');

    // const toTimeFormatted: string = this.sysService.getFormattedTime(toTime, 'hh:mm');
    // const timeRange: string = ` ${fromTimeFormatted}-${toTimeFormatted}`;

    // if (this.Payable.isEmpty("toTime") && !this.Payable.isEmpty("fromTime")) {
    //   this.Payable.setParam("nightTime", timeRange);
    //   this.sysService.error("Please select To time");
    //   return false;
    // }

    // if (!this.Payable.isEmpty("toTime") && this.Payable.isEmpty("fromTime")) {
    //   this.Payable.setParam("nightTime", timeRange);
    //   this.sysService.error("Please select From time");
    //   return false;
    // }

    // if (!this.Payable.isEmpty("toTime") && !this.Payable.isEmpty("fromTime")) {
    //   this.Payable.setParam("nightTime", timeRange);
    //   return true;
    // }

    // if (this.Payable.isEmpty("toTime") && this.Payable.isEmpty("fromTime")) {

    //   return true;
    // }

    return true; // Add a default return statement if necessary
  }

  view(id: any) {
    this.payableView.toViewFetch(`/revenue/radiologist-payables/${id}`, id);
  }
  onDataSelectionChange(selectedMonth: any) {
    this.Payable.set('date', '')
  }
  onDateSelect(selectedDate: any) {
    this.Payable.set('month', null)
    this.Payable.set('year', null)
  }
  searchPayable() {
    this.search = true;
    this.totalCharge = ''
  }
  PayableYear() {
    this.payableView.getData(`/reports/year`).then(data => { this.PayableYearList = data; })
  }
  filterRad(event: any) {
    this.payableView.getData(`/radiologist/radiologist-lookup?filter=${event.query}`).then(data => {
      this.radLookup =
        data;
    });
  }
  filterCen(event: any) {
    this.payableView.getData(`/centre/centre-lookup?filter=${event.query}`).then(data => {
      this.cenLookup =
        data;
    });
  }
  filterMod(event: any) {
    this.payableView.getData(`/modality/modality-lookup?filter=${event.query}`).then(data => {
      this.modLookup =
        data;
    });
  }

  resetPayable() {
    this.Payable.reset();
    this.sysService.clear();
    this.Payable.set('isReportDate', false);
    this.search = false;

    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    this.Payable.set('month', month);
    this.Payable.set('year', year);

    this.Payable.selection = [];
    this.totalCharge = '';
    let fromTime = this.Payable.get('FromTime');
    let toTime = this.Payable.get('toTime');
    this.Payable.set('FromTime', fromTime === null ? '' : fromTime);
    this.Payable.set('toTime', toTime === null ? '' : toTime);
  }

  export(Payable: any) {
    this.searchPayable()
    const rangeDate = String(this.Payable.get("date"));
    const startDate = new Date(rangeDate.split(",")[0]);
    const endDate = new Date(rangeDate.split(",")[1]);
    const formattedStartDate = `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() +
      1).toString().padStart(2, '0')}/${startDate.getFullYear()}`;
    const formattedEndDate = `${endDate.getDate().toString().padStart(2, '0')}/${(endDate.getMonth() +
      1).toString().padStart(2, '0')}/${endDate.getFullYear()}`;
    const date = `${formattedStartDate}-${formattedEndDate}`;
    this.Payable.setParam("date", date)
    if (!this.Payable.isEmpty("date")) {
      this.Payable.setParam("isReportDate", false)
    }
    this.Payable.setParam("centreName", this.Payable.get("centreName")?.id)
    this.Payable.setParam("userId", this.Payable.get("userId")?.id)
    this.Payable.setParam("radId", this.Payable.get("radId")?.id)
    this.Payable.setParam("modality", this.Payable.get("modality")?.name)
    this.Payable.setParam("month", this.Payable.get("month"))
    this.Payable.setParam("year", this.Payable.get("year"))
    this.Payable.setParam("paymentStatus", this.Payable.get("paymentStatus"))
    this.Payable.setParam("isReportDate", this.Payable.get("isReportDate"))
    if (this.Payable.selection.length > 0) {
      const ids = this.Payable.selection.map(item => item.id);
      this.Payable.setParam("reportIds", ids)
    } else {
      const ids = this.Payable.selection = [];
      this.Payable.setParam("reportIds", ids)
    }
    if (this.checkDateCondition()) {
      const pdfFilename = !this.payableView.isEmptyList('date') ? `Payable-${date}.pdf` : 'Payable.pdf';
      this.payableView.download(`/revenue/download-revenue-pdf?&isReceivable=false`, pdfFilename);
      this.selectedValue()
    }

  }
  downloadExcel(Payable: any) {
    this.searchPayable()
    const rangeDate = String(this.Payable.get("date"));
    const startDate = new Date(rangeDate.split(",")[0]);
    const endDate = new Date(rangeDate.split(",")[1]);
    const formattedStartDate = `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() +
      1).toString().padStart(2, '0')}/${startDate.getFullYear()}`;
    const formattedEndDate = `${endDate.getDate().toString().padStart(2, '0')}/${(endDate.getMonth() +
      1).toString().padStart(2, '0')}/${endDate.getFullYear()}`;
    const date = `${formattedStartDate}-${formattedEndDate}`;
    this.Payable.setParam("date", date)
    if (!this.Payable.isEmpty("date")) {
      this.Payable.setParam("isReportDate", false)
    }
    this.Payable.setParam("centreName", this.Payable.get("centreName")?.id)
    this.Payable.setParam("userId", this.Payable.get("userId")?.id)
    this.Payable.setParam("radId", this.Payable.get("radId")?.id)
    this.Payable.setParam("modality", this.Payable.get("modality")?.name)
    this.Payable.setParam("month", this.Payable.get("month"))
    this.Payable.setParam("paymentStatus", this.Payable.get("paymentStatus"))
    this.Payable.setParam("isReportDate", this.Payable.get("isReportDate"))
    this.Payable.setParam("year", this.Payable.get("year"))
    if (this.Payable.selection.length > 0) {
      const ids = this.Payable.selection.map(item => item.id);
      this.Payable.setParam("reportIds", ids)
    } else {
      const ids = this.Payable.selection = [];
      this.Payable.setParam("reportIds", ids)
    }
    if (this.checkDateCondition()) {
      const Excelname = !this.payableView.isEmptyList('date') ? `Payable-${date}.xls` : 'Payable.xls';
      this.payableView.download(`/revenue/download-revenue-excel?isReceivable=false`, Excelname);
      this.selectedValue()
    }

  }
  selectedValue() {
    let totalCharge = 0;
    for (const item of this.Payable.selection) {
      totalCharge += item.charge;
    }
    this.totalCharge = totalCharge;
  }
  changesCalendarClear(dt) {
    this.selectedRange = [];
    this.calendarSelected = false;
    this.Payable.reset();
    this.sysService.clear();
    this.Payable.set('isReportDate', false)
    this.search = false
    let month = new Date().getMonth() + 1
    let year = new Date().getFullYear()
    this.Payable.set('month', month);
    this.Payable.set('year', year);
    this.Payable.selection = [];
    this.totalCharge = ''
    this.calendar.hideOverlay();
  }
  dateRange;
  calendarSelected = false;
  calenedarChangeDate(event, dt) {
    this.dateRange = "";
    if (this.selectedRange) {

      const startDate = this.datePipe.transform(
        this.selectedRange[0],
        "dd/MM/yyyy"
      );
      const endDate = this.datePipe.transform(
        this.selectedRange[1],
        "dd/MM/yyyy"
      );
      this.dateRange = `${startDate}-${endDate}`;
      this.listratecardListLazy(dt);
    }
    this.calendar.hideOverlay();
  }
  heightTable: any;
  debounceTimeout: any;
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 450) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 450) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  detectZoomChange() {
    this.scrollHeight();
  }
}