<header class="header" *ngIf="!sysService.isLoggedIn()">
    <div class="wrap">
        <div class="right">
            <div class="rgtBdr ">
                <a (click)="navHome()"> <img class="web" src="../../../assets/images/eclogoo.png"></a>
            </div>
        </div>
    </div>
</header>
<div class="card">
    <div class="grid">
        <div class=" field col-12 md:col-6 lg:col-12 text-xl">

            <h1>Privacy Policy</h1>
            At eClarity Health, we are committed to protecting the privacy of our users and ensuring the security of
            their medical information. This Privacy Policy outlines our practices regarding the collection, use, and
            protection of personal and medical information.<br>

            Collection of Personal Information<br>

            We collect personal information, such as names, email addresses, and contact information, from users who
            sign up for our teleradiology services. We may also collect information related to payment and billing.<br>

            Use of Personal Information<br>

            We use personal information to provide our services, process payments, and communicate with users about
            their accounts. We may also use personal information to improve our platform and provide personalized
            experiences for our users.<br>

            Protection of Medical Information<br>

            We understand the sensitive nature of medical information and take the security of this information very
            seriously. Our platform is designed to comply with industry standards for medical data protection, including
            HIPAA compliance. We use encryption, firewalls, and other security measures to protect medical information
            from unauthorized access or theft.<br>

            Sharing of Personal and Medical Information<br>

            We do not sell, rent, or share personal or medical information with third parties unless required by law or
            with the user's consent. We may share personal and medical information with our partners and service
            providers who help us provide our services. In these cases, we take appropriate measures to ensure that
            these partners and service providers maintain the confidentiality and security of this information.<br>

            Accessing and Updating Personal Information<br>

            Users have the right to access and update their personal information at any time. To request access or make
            changes to your personal information, please contact us at <a
                href="mailto:hello@ecscribe.com">hello@ecscribe.com</a> <br>

            Changes to this Privacy Policy<br>

            We may update this Privacy Policy from time to time to reflect changes in our practices or legal
            requirements. We will notify users of any changes by posting the updated policy on our website.<br>

            Contact Us<br>

            If you have any questions or concerns about our Privacy Policy, please contact us at
            <a href="mailto:hello@ecscribe.com">hello@ecscribe.com</a> We are committed to protecting the
            privacy and security of our users and are here
            to help.<br>
        </div>
    </div>
</div>