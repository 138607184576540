import { Component, HostListener } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model } from '@app/_common';
import { AppConstants } from '@app/_config';
import { environment } from '@environments/environment';
import { LazyLoadEvent } from 'primeng/api';
@Component({
  selector: 'app-bodypart',
  templateUrl: './bodypart.component.html',
  styleUrls: ['./bodypart.component.css']
})
export class BodypartComponent {
  private bodyPartView: View = this.viewFactory.newView();
  saveButtonLabel: string = 'Save';
  countryLookup: any[];
  eventData: any;
  isScrollTable = window.innerWidth > environment.devices.tablet ? true : false;
  heightDisplay = (window.innerHeight - 280) + 'px';
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory
  ) { }
  ngOnInit() {
    this.scrollHeight()
    this.detectZoomChange()

    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 400));

    this.bodyPartView.of(this.bodyPartModel()).switchAction("/bodypart", this.route, this);
  }
  get bodypart() {
    return this.bodyPartView.model;
  }
  private bodyPartModel() {
    const formDefault = new FormDefault(["status"], [1]);
    return new Model(
      this.formBuilder.group({
        id: [],
        name: ["", [Validators.required, Validators.pattern(AppConstants.regexName)]],
        bodypart: ["", [Validators.required]],
        description: ["", [Validators.required]],
        status: ["", [Validators.required]],
      }),
      this.sysService,
      formDefault,
      ""
    );
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isScrollTable = window.innerWidth > environment.devices.tablet ? true : false;
    this.heightDisplay = (window.innerHeight - 280) + 'px';
  }
  add() {
    this.bodyPartView.toNew();
    this.saveButtonLabel = "Save";
  }
  edit(id: any) {
    this.bodyPartView.toEditFetch(`/bodypart/${id}`, id);
    this.saveButtonLabel = "Update";
  }
  list(id: any) {
    this.listView(false);
  }
  async listBodyPartLazy(event?: LazyLoadEvent, search: boolean = false) {
    this.eventData = event
    this.bodyPartView.toListLazyFetch(`/bodypart`, search, event);
  }
  listView(search: boolean = false) {
    this.bodyPartView.toListLazyView(`/bodypart`, search);
  }
  view(id: any) {
    this.bodyPartView.toViewFetch(`/bodypart/${id}`, id);
  }
  deleteBodyParts() {
    this.bodyPartView.toDeleteSelection(`/bodypart`, {
      onSuccess: () => {
        this.bodyPartView.toListLazyFetch(`/bodypart`, true, this.eventData);

      }
    });

  }
  deleteBodyPart(id: any) {
    this.bodyPartView.toDeleteList(`/bodypart/${id}`, "id", id);
  }
  saveBodyPart(add: boolean = false) {
    if (add) {
      this.bodyPartView.toSaveNewFetch("/bodypart", "id");
      this.bodypart.searchTxt;
    } else {
      // this.bodyPartView.toSaveListFetch("/bodypart", "id");
      this.bodyPartView.toSaveListFetch("/bodypart", "id", this.bodypart.isEdit() ? "Body Part Updated Successfully ." : "Body Part Saved Successfully .");
      this.bodypart.searchTxt;
    }
  }
  filterCountry(event: any) {
    this.bodyPartView.getData(`/country/lookup?filter=${event.query}`).then((data) => {
      this.countryLookup = data;
    });
  }
  heightTable: any;
  debounceTimeout: any;
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 280) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 280) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  detectZoomChange() {
    this.scrollHeight();
  }
}
