import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { FormDefault, Model, StaticService, SysService, View, ViewFactory, ViewAction, passwordMatch, noWhitespaceValidator } from '@app/_common';
import { AppConstants } from '@app/_config/app.constants';
import { environment } from '@environments/environment';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-radiologist-registration',
  templateUrl: './radiologist-registration.component.html',
  styleUrls: ['./radiologist-registration.component.scss']
})
export class RadiologistRegistrationComponent implements OnInit {
  captcha = true;
  captchaSiteKey = environment.captchaSiteKey;
  private RadiologistRegistrationView: View = this.viewFactory.newView();
  display: boolean = false;
  countryLookup: any[];
  stateLookup: any[];
  modalityLookup: any[];
  maxDate: Date;
  stateLookupAll: any[];
  uploadedFiles: any[] = [];
  regexPassword: any;
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: UntypedFormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory,
    public router: Router,
  ) { this.captcha = true; }
  ngOnInit(): void {
    this.RadiologistRegistrationView.of(this.RadiologistRegistrationModel());
    this.maxDate = new Date();
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
  }
  get RadiologistRegistration() {
    return this.RadiologistRegistrationView.model;
  }
  private RadiologistRegistrationModel() {
    const formDefault = new FormDefault(['countryId'], [92,]);
    return new Model(this.formBuilder.group({
      id: [],
      name: ['', [Validators.required, noWhitespaceValidator]],
      email: ['', [Validators.required, Validators.pattern(AppConstants.regexEmail)]],
      phoneNo: ['', [Validators.required, Validators.pattern(AppConstants.regexPhonenumber), Validators.minLength(7)]],
      dob: ['', [Validators.required]],
      qualifications: ['', [Validators.required, noWhitespaceValidator]],
      registrationNo: ['', [Validators.required, noWhitespaceValidator]],
      experience: [''],
      designation: [''],
      suffix: [''],
      prefix: [''],
      additionalQualifications: [''],
      additionalTraining: [''],
      countryId: [, [Validators.required]],
      stateId: [],
      teleradiologyCoordinator: [],
      address: ['', [Validators.required, noWhitespaceValidator]],
      zip: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]*$/)]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern(AppConstants.regexPassword)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern(AppConstants.regexPassword)]],
    },
      { validators: [passwordMatch] }), this.sysService, formDefault, '');
  }
  showDialog() {
    this.display = true;
  }
  onUpload(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
    this.sysService.info('File Uploaded');
  }
  email: any;
  saveRadiologistRegistration() {
    if (this.RadiologistRegistrationView.isValid()) {
      this.RadiologistRegistrationView.toSaveNav('/auth/radiologist', "id", "login", `Thank you for registration with eCScribe portal. Registration request is being processed. You will receive an email shortly once the registration is approved.`, "Your registration has failed.");
    }
  }
  cancelRadiologistRegistration() {
    this.RadiologistRegistration.reset();
  }
  onCountryChange(event) {
  }
  filterCountry(event: any) {
    this.RadiologistRegistrationView.getData(`/country/lookup?filter=${event.query}`).then(data => { this.countryLookup = data; });
  }
  // countryOnchange(event:any) {
  //   this.RadiologistRegistrationView.getData(`/country/lookup${event.value}`, "")
  // }
  // stateAreaOnChange(event: any) {
  //   this.loadState(event.id)
  //   this.RadiologistRegistration.set("stateId",null)
  // }
  // filterState(event: any) {
  //   this.stateLookup = this.stateLookupAll?.filter(x => x.name.toLowerCase().indexOf(event.query.toLowerCase()) === 0);
  // }
  // loadState(countryId) {
  //   this.RadiologistRegistrationView.getData(`/state/lookupbycountry?countryId=${countryId}`).then(data => {
  //     this.stateLookupAll = data;
  //     this.stateLookup = data;
  //   });
  // }
  ModalitiesLookup() {
    this.RadiologistRegistrationView.getData(`/modality/lookup`).then(data => { this.modalityLookup = data; });
  }
  back() {
    this.sysService.navigate(['/registration']);
  }
  navHome() {
    this.sysService.navigate(['/']);
  }
  showResponse(event) {
    this.captcha = false;
  }
  filterState(event: any) {
    // check if stateLookupAll is already loaded
    if (this.stateLookupAll) {
      // if loaded, filter the results
      this.stateLookup = this.stateLookupAll.filter((x: any) =>
        x.name.toLowerCase().indexOf(event.query.toLowerCase()) === 0
      );
    } else {
      // if not loaded, get countryId from billing view and load states for that country
      let countryId = this.RadiologistRegistration?.view?.countryId?.id;
      if (countryId === undefined) {
        this.sysService.error("Please select a country");
      } else
        this.RadiologistRegistrationView
          .getData(`/state/lookupbycountry?countryId=${countryId}`)
          .then((data: any) => {
            // store the loaded states and set the filtered results
            this.stateLookup = data;
            this.stateLookupAll = data;
          });
    }
  }
  loadState(countryId) {
    // load states for the given countryId
    this.RadiologistRegistrationView.getData(`/state/lookupbycountry?countryId=${countryId}`).then(data => {
      // store the loaded states and set the filtered results
      this.stateLookupAll = data;
      this.stateLookup = data;
    });
  }
  stateAreaOnChange(event: any) {
    // load states for the selected country
    this.loadState(event.id);
    // reset stateId
    this.RadiologistRegistration.set("stateId", null);
  }
  showNote: boolean = false;
}