import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SysService, StaticService, ViewFactory, View, FormDefault, Model } from '@app/_common';
import { AppConstants } from '@app/_config/app.constants';
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  private contactusView: View = this.viewFactory.newView();
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: UntypedFormBuilder,
    public route: ActivatedRoute,
    private viewFactory: ViewFactory,
    private router: Router,
  ) { }
  userDetails: any;
  ngOnInit(): void {
    this.contactusView.of(this.contactsModel());
    if (this.sysService.isLoggedIn() && !this.sysService.isGuest()) {
      this.contactusView.getData('/users/contactus').then(data => { this.userDetails = data; });
    }
  }
  get contactus() {
    return this.contactusView.model;
  }
  private contactsModel() {
    const formDefault = new FormDefault(['status'], [1]);
    const isLoggedIn = this.sysService.isLoggedIn() && !this.sysService.isGuest();
    const nameValidators = isLoggedIn ? [] : [Validators.required];
    const emailValidators = isLoggedIn ? [] : [Validators.required, Validators.pattern(AppConstants.regexEmail)];
    const contactNoValidators = isLoggedIn ? [] : [Validators.required, Validators.pattern(AppConstants.regexPhonenumber)];
    return new Model(this.formBuilder.group({
      id: [''],
      name: ['', nameValidators],
      email: ['', emailValidators],
      contactNo: ['', contactNoValidators],
      subject: ['', [Validators.required]],
      help: ['', [Validators.required]],
      userRole: [''],
      isPrimary: [],
      status: []
    }, { validators: [] }), this.sysService, formDefault, '');
  }
  saveContacts() {
    if (this.sysService.isLoggedIn() && !this.sysService.isGuest()) {
      this.contactusView.toSave('/mail/contactus-for-user', "id", "Thank you for your message. It has been sent.", "Your message has failed.");
    }
    if (this.sysService.isLoggedIn() && this.sysService.isGuest()) {
      this.contactusView.toSave('/mail/contactus', "id", "Thank you for your message. It has been sent.", "Your message has failed.");


    }

    else {
      this.contactusView.toSaveNav('/mail/contactus', "id", "login", "Thank you for your message. It has been sent.", "Your message has failed.");
    }
  }
  navHome() {
    this.router.navigateByUrl('/');
  }
}