import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { View, SysService, StaticService, ViewFactory, FormDefault, Model } from '@app/_common';
import { AppConstants } from '@app/_config/app.constants';
import { environment } from '@environments/environment';
import { validateHeaderValue } from 'http';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
@Component({
  selector: 'app-center',
  templateUrl: './center.component.html',
  styleUrls: ['./center.component.scss']
})
export class CenterComponent implements OnInit {
  centerListExport = ['name', 'taxCode', 'code', 'countryId.name']; //for export header fields
  private centerView: View = this.viewFactory.newView();
  private transactionAmountDetailsView: View = this.viewFactory.newView();
  flag: boolean
  maxDate: Date;
  message: string;
  @Input() err: string;
  @ViewChild("fileInput") fileInput: ElementRef;

  @Input() errFlagContent: boolean;
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private viewFactory: ViewFactory) {
  }
  ngOnInit() {

    this.scrollHeight()
    this.detectZoomChange()

    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 400));

    this.centerView.of(this.centerListModel()).switchAction('/centre', this.route, this);
    this.maxDate = new Date();
    // this.userDetails()
    this.transactionAmountDetailsView.of(this.transactionModel(), false);

  }
  imageurl = "/assets/images/thumbnailnew.png";

  associateUsers: any[]
  associateUserList: Boolean
  countryLookup: any[];
  stateLookup: any[];
  eventData: any;
  stateLookupAll: any[];
  isEdit: boolean = false;
  saveButtonLabel: string = 'Save';
  modalityLookup: any[];
  transactionamount: boolean
  get center() {
    return this.centerView.model;
  }
  get transactionAmountdetails() {
    return this.transactionAmountDetailsView.model;
  }
  private centerListModel() {
    const formDefault = new FormDefault(['status'], [1,]);
    return new Model(this.formBuilder.group({
      id: [],
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(AppConstants.regexEmail)]],
      address: ['', [Validators.required]],
      phoneNo: ['', [Validators.required, Validators.pattern(AppConstants.regexPhonenumber), Validators.minLength(7)]],
      stateId: [''],
      countryId: ['', [Validators.required]],
      aeTitle: [''],
      status: [, [Validators.required]],
      zip: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]*$/)]],
      startDate: ['', [Validators.required]],
      strictDeduction: [],
      footer: [],
      header: [],
      docFile: [],
      docFile1: [],
      isFooterHeaderAllPage: [],

    }), this.sysService, formDefault, '');
  }
  private transactionModel() {
    const formDefault = new FormDefault(['status'], [1]);
    return new Model(this.formBuilder.group({
      cenOrRadId: [],
      amountPaid: [, [Validators.required, Validators.pattern(AppConstants.regexPhonenumber)]],
      comments: ['', Validators.required],
      bonusType: [, [Validators.required]],
      status: [],
    }), this.sysService, formDefault, '');
  }
  add() {
    this.centerView.toNew();
    this.flag = false;
    this.saveButtonLabel = 'Save';
  }
  associateUser(radiologist: any) {
    this.associateUserList = true
    this.centerView.getData(`/contacts/find-contacts?userId=${radiologist.id}&cen=true&rad=false`)
      .then((data) => { this.associateUsers = data.list; })
  }
  isScrollTable = window.innerWidth > environment.devices.tablet ? true : false;
  heightDisplay = (window.innerHeight - 280) + 'px';
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isScrollTable = window.innerWidth > environment.devices.tablet ? true : false;
    this.heightDisplay = (window.innerHeight - 280) + 'px';
  }
  activateButton(center: any) {
    let messageValue = ['activate', 'deactivate', 'send activation mail'][center.status];
    let id = center.id

    this.confirmationService.confirm({
      header: 'Confirmation',
      message: `Are you sure  want to ${messageValue} ?`,
      accept: () => {
        const status = center.status;
        const message = {
          1: "Account has been deactivated successfully.",
          0: "Account has been activated successfully.",
          2: `Activation mail sent to ${center?.email}`
        }[status];

        this.centerView.getData(`/users/activate?centreId=${center.id}&&status=${center.status}`).then(data => {
          this.center?.list?.forEach((x) => {

            if (x.id === id) {

              x.status = data.status
            }
          });
          this.sysService.success(message);
        });
      }
    });
  }

  edit(id: any) {
    this.centerView.toEditFetch(`/centre/${id}`, id).then(data => {
      this.loadState(data.countryId.id)
      this.centerView.image(`/users/cen-header?cenId=${data.id}&isUserId=false`).subscribe((byte) => {
        this.hasHeader = byte.byteLength > 0;
        this.header = this.sysService.arrayBufferToBase64ImageSource(
          byte,
          data.signatureExt
        );
      });
      this.centerView.image(`/users/cen-footer?cenId=${data.id}&isUserId=false`).subscribe((byte) => {
        this.hasFooter = byte.byteLength > 0;
        this.footer = this.sysService.arrayBufferToBase64ImageSource(
          byte,
          data.signatureExt
        );
      });
      if (this.sysService.isAdmin()) {
        this.flag = false;
      } else {
        this.flag = true;
      }

      this.saveButtonLabel = 'Update';
    })
  }
  list(id: any) {
    this.listView(false);
  }
  async listcenterLazy(event?: LazyLoadEvent, search: boolean = false) {
    // if (this.sysService.isAdmin()) {
    this.eventData = event
    this.centerView.toListLazyFetch(`/users/users-for-admin?isRad=false`, search, event);
    // }
    // else {
    //   this.centerView.toListLazyFetch(`/users/users-for-admin?order=asc&page=0&size=10&isRad=false`, search, event);
    // }
  }
  listView(search: boolean = false) {
    // if (this.sysService.isAdmin()) {
    this.centerView.toListLazyView(`/users/users-for-admin?isRad=false`, search);
    // } else {
    //   this.centerView.toListLazyView(`/users/users-for-admin?order=asc&page=0&size=10&isRad=false`, search);
    // }
  }
  view(id: any) {
    this.centerView.toViewFetch(`/centre/${id}`, id);
  }
  deletecenters() {
    this.centerView.toDeleteSelection(`/centre`, {
      onSuccess: () => {
        this.centerView.toListLazyFetch(`/users/users-for-admin?isRad=false`, true, this.eventData);
      }
    });

  }
  deletecenter(id: any) {
    this.centerView.toDeleteList(`/centre/${id}`, "id", id);
  }
  savecenter(add: boolean = false) {
    if (this.center.isEdit()) {
      this.center.setMultipart();
    }

    if (this.center.get("status") === 2) {
      this.sysService.error("Editing is only allowed after the account has been approved. Please ensure that the account is approved before attempting to make any updates.")
    } else {
      if (add) {
        this.centerView.toSaveNewFetch("/centre", "id");
      } else {
        this.centerView.toSaveListFetch("/centre", "id", this.center.isEdit() ? "Centre Details Updated Successfully." : `Thanks
for adding new user. We have shared a verification link to ${this.center.get('email')} mail ID. Kindly check user mail
(or spam / junk folder) and click on the link to activate and proceed for logging in to the ecscribe..`, "Your registration has failed.");
        this.center.clearMultipart();
      }
    }
    this.center.set("docFile", null);
    this.center.set("docFile1", null);
  }
  modalitiesLookup() {
    this.centerView.getData(`/modality/lookup`).then(data => { this.modalityLookup = data; });
  }
  callApi(event: string) {
    this.centerView.getData(`/centre/title-checker?filter=${event}`)
      .then((response: any) => {
        if (this.center?.form?.value.aeTitle?.trim() === '') {
          this.message = '';
        } else if (response?.message.trim() === "not exists") {
          this.message = '';
        } else if (response?.message.trim() === "already exists") {
          this.message = 'This AE Title already exists.';
        }
      });
  }
  filterState(event: any) {
    // check if stateLookupAll is already loaded
    if (this.stateLookupAll) {
      // if loaded, filter the results
      this.stateLookup = this.stateLookupAll.filter((x: any) =>
        x.name.toLowerCase().indexOf(event.query.toLowerCase()) === 0
      );
    } else {
      // if not loaded, get countryId from billing view and load states for that country
      let countryId = this.center?.view?.countryId?.id;
      if (countryId === undefined) {
        this.sysService.error("Please select a country");
      } else {
        this.centerView
          .getData(`/state/lookupbycountry?countryId=${countryId}`)
          .then((data: any) => {
            // store the loaded states and set the filtered results
            this.stateLookup = data;
            this.stateLookupAll = data;
          });
      }
    }
  }
  loadState(countryId) {
    this.centerView.getData(`/state/lookupbycountry?countryId=${countryId}`).then(data => {
      this.stateLookupAll = data;
      this.stateLookup = data;
    });
  }
  stateAreaOnChange(event: any) {
    this.loadState(event.id)
    this.center.set("stateId", null)
  }
  onCountryChange(event) {
  }
  filterCountry(event: any) {
    this.centerView.getData(`/country/lookup?filter=${event.query}`).then(data => { this.countryLookup = data; });
  }
  transactionAmount(radiologist: any) {
    this.transactionAmountdetails.reset();
    this.transactionAmountdetails.set("cenOrRadId", radiologist.id)
    this.transactionAmountdetails.set("status", radiologist.status)
    this.transactionamount = true
  }
  bonusTypeAmount(event: any) {
    this.transactionAmountdetails.set("amountPaid", null)
    if (event.value === 1) {
      this.transactionAmountDetailsView.getData("/usersettings/custom-details").then((data) => {
        this.transactionAmountdetails.set("amountPaid", data.bonus)
      }
      )
    }
  }
  walletUpdate(search: boolean = false, event?: LazyLoadEvent) {
    const id = this.center.get("id")
    if (this.transactionAmountdetails.get("status") !== 1) {
      this.sysService.errorAdd("Wallet updates are only accessible for active accounts.")
      this.transactionamount = false;
    } else {
      let id = this.transactionAmountdetails.get("cenOrRadId")
      let adminTransactionType = this.transactionAmountdetails.get("bonusType")
      let amountValue = this.transactionAmountdetails.get("amountPaid")

      if (this.transactionAmountDetailsView.isValid()) {
        if (this.checkAmountRange(amountValue)) {
          this.transactionAmountDetailsView.toSave(`/transaction?isFree=true&cenOrRadId=${id}&isRad=false&adminTransactionType=${adminTransactionType}`,
            '').then((data) => {
              if (data.message === 'Success') {
                this.center?.list?.forEach((x) => {
                  if (x.id === id) {
                    x.walletBalance = data.walletBalance;
                  }
                });
                this.sysService.handleSuccessMessage(data.message, adminTransactionType, 1);
              } else {
                this.sysService.error(`${data.message}`);
              }

              this.transactionamount = false;
            });
        }
        else {
          window.alert("The amount is either greater than 30000 or equal to 0.")

          this.transactionAmountdetails.set("amountPaid", null)

        }

      }

    }


  }




  checkAmountRange(value: number): boolean {
    return value >= 1 && value <= 30000;
  }


  cancelTransactionAmount() {
    this.sysService.clear()
    this.transactionamount = false
  }
  rejectBack() {
    this.transactionamount = false;
    this.transactionAmountdetails.reset();
    setTimeout(() => {
      this.sysService.clear();
    }, 800);
  }

  selectFile(file, name) {
    this.sysService.clear();

    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target) {
        const img = new Image();
        img.src = e.target.result as string;

        img.onload = () => {
          const width = img.width;
          const height = img.height;


          if (file[0].size <= 200 * 1024) {
            if (width >= 1500 && width <= 1699 && height >= 150 && height <= 250) {
              this.center.setFile(file, name);
              this.center.view.header = null;
              this.header = img.src;
              this.hasHeader = true

            } else {
              this.sysService.error(
                "Please choose a file with dimensions between 1500 x 150 and 1699 x 250."
              );
              this.resetFileInput();
              this.center.view.header = null;
            }
          } else {
            this.sysService.error(
              "File size exceeds 200kb limit or incorrect dimensions. Please choose a file with dimensions between 1500 x 150 and 1699 x 250.."
            );
            this.resetFileInput();
            this.center.view.header = null;
          }
        };
      }
    };
    // Read the file as a data URL
    reader.readAsDataURL(file[0]);
  }


  selectFile1(file, name) {
    this.sysService.clear();
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target) {
        const img = new Image();
        img.src = e.target.result as string;

        img.onload = () => {
          const width = img.width;
          const height = img.height;


          if (file[0].size <= 200 * 1024) {
            if (width >= 1500 && width <= 1699 && height >= 120 && height <= 250) {
              this.center.setFile(file, name);
              this.center.view.footer = null;
              this.footer = img.src;
              this.hasFooter = true
            } else {
              this.sysService.error(
                "Please choose a file with dimensions between 1500 x 120 and 1699 x 250."
              );
              this.resetFileInput1();
              this.center.view.footer = null;
            }
          } else {
            this.sysService.error(
              "File size exceeds 200kb limit or incorrect dimensions. Please choose a file with dimensions between 1500 x 120 and 1699 x 250.."
            );
            this.resetFileInput1();
            this.center.view.header = null;
          }
        };
      }
    };
    // Read the file as a data URL
    reader.readAsDataURL(file[0]);
  }

  resetFileInput() {
    // Reset the value of the input file
    this.fileInput.nativeElement.value = "";
  }
  resetFileInput1() {
    // Reset the value of the input file
    this.fileInput.nativeElement.value = "";
  }
  hasHeader: boolean = false;
  hasFooter: boolean = false;

  footer: any
  header: any

  getFilename(name) {
    if (name) {
      const filename = name.substring(name.indexOf("_") + 1, name.length);
      return filename.length > 15 ? filename.substring(0, 15) : filename;
    }
    return "";
  }


  closeheader(row: any) {

    this.center.set("header", null);
    this.header = null;
    this.resetFileInput()
    this.center.view.header = null;
    this.header = this.imageurl;
  }

  closefooter(row: any) {

    this.center.set("footer", null);
    this.footer = null;
    this.resetFileInput1()
    this.center.view.footer = null;
    this.footer = this.imageurl;
  }



  userDetails() {
    let id = this.center.get("id")
    this.centerView
      .toEditFetch("/users/userprofile", "id")
      .then((data) => {
        this.centerView.image(`/users/cen-header?cenId=${data.id}&isUserId=false`).subscribe((byte) => {
          this.hasHeader = byte.byteLength > 0;
          this.header = this.sysService.arrayBufferToBase64ImageSource(
            byte,
            data.signatureExt
          );
        });
        this.centerView.image(`/users/cen-footer?cenId=${data.id}&isUserId=false`).subscribe((byte) => {
          this.hasFooter = byte.byteLength > 0;
          this.footer = this.sysService.arrayBufferToBase64ImageSource(
            byte,
            data.signatureExt
          );
        });
      });
  }

  heightTable: any;
  debounceTimeout: any;
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 280) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 280) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  detectZoomChange() {
    this.scrollHeight();
  }

}