import { Component, HostListener, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CKEditor4 } from 'ckeditor4-angular/ckeditor';
import { FormDefault, Model, noWhitespaceValidator, StaticService, SysService, View, ViewFactory } from '@app/_common';
import { AppConstants } from '@app/_config';
import { environment } from '@environments/environment';
import { LazyLoadEvent } from 'primeng/api';
import { ReportService } from '@app/_common/services/report.service';
declare const CKEDITOR;
@Component({
  selector: 'app-report-template',
  templateUrl: './report-template.component.html',
  styleUrls: ['./report-template.component.scss'],
})
export class ReportTemplateComponent implements OnInit {
  ratecardListExport = ['name', 'taxCode', 'code', 'countryId.name']; //for export header fields
  private reportTemplateView: View = this.viewFactory.newView();
  private reportTemplateSearchView: View = this.viewFactory.newView();
  modalityLookup: any[];
  bodyPartLookup: any[];
  modalitySearchLookup: any[];
  templateNameLookup: any[];
  bodyPartSearchLookup: any[];
  createdBySearchLookup: any[];
  codeShortList: any;
  eventData: any;
  reportReportSample: any;
  isEdit: boolean = false;
  search: boolean = false;
  visible: boolean;
  saveButtonLabel: string = 'Save';
  header: any;
  footer: any;
  imageUrl: string = '/assets/images/signature.png';
  stamp: string = '/assets/images/stamp.png';
  constructor(
    public sysService: SysService,
    public staticService: StaticService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private reportService: ReportService,
    private viewFactory: ViewFactory) {
  }
  ngOnInit() {
    this.scrollHeight()
    window.addEventListener('resize', this.debounce(this.detectZoomChange.bind(this), 400));
    this.detectZoomChange()

    this.reportTemplateSearchView.of(this.reportTemplateListSearchModel());
    this.reportTemplateView.of(this.reportTemplateListModel());
  }
  get reportTemplate() {
    return this.reportTemplateView.model;
  }
  get reportTemplateListSearch() {
    return this.reportTemplateSearchView.model;
  }
  private reportTemplateListModel() {
    const formDefault = new FormDefault(['status', 'sharingScope'], [1, 0]);
    return new Model(this.formBuilder.group({
      id: [],
      name: ['', [Validators.required]],
      modalityId: [, [Validators.required]],
      report: ['', [Validators.required, noWhitespaceValidator]],
      status: [, [Validators.required]],
      bodyPartId: [, [Validators.required]],
      patName: [''],
      editable: [],
      shareable: [],
      templateType: [],
      sharingScope: [],
      userId: ['']
    }), this.sysService, formDefault, '');
  }
  private reportTemplateListSearchModel() {
    const formDefault = new FormDefault(['status'], [1]);
    return new Model(this.formBuilder.group({
      BodyPart: [''],
      templateType: [''],
      Modality: [''],
      global: [''],
      status: [],
      sharingScope: [],
      userName: ['']
    }), this.sysService, formDefault, '');
  }
  add() {
    this.reportTemplateView.toNew();
    this.saveButtonLabel = 'Save';
  }
  edit(id: any) {
    this.reportTemplate.reset()
    this.reportTemplateView.toEditFetch(`/reporttemplate/${id}`, id).then(data => {
      this.isEdit = true;
      this.saveButtonLabel = 'Update';
    });
  }
  heightTable: any;
  debounceTimeout: any;
  scrollHeight() {
    const bodyHeight = document.body.offsetHeight;
    let heightTable: string;
    if (bodyHeight < 732) {
      heightTable = ((bodyHeight - 380) / bodyHeight * 100) + 'vh'
    } else {
      heightTable = ((bodyHeight - 380) / bodyHeight * 100) + 'vh'
    }
    this.heightTable = heightTable;
    document.documentElement.style.setProperty('--height-table', heightTable);
  }
  debounce(func: Function, wait: number) {
    return (...args: any[]) => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  detectZoomChange() {
    this.scrollHeight();
  }
  list(id: any) {
    this.listView(false);
  }
  cloneTemplate() {
    this.reportTemplate.set('userName', null);
    const templateName = this.reportTemplate.get("name");
    this.reportTemplate.set('id', null);
    this.reportTemplate.set('name', null);
    this.reportTemplate.view.editable = true; // Set the value of editable
    if (templateName !== null) {
      this.sysService.info(`${templateName} has been cloned successfully.`);
      setTimeout(() => {
        this.sysService.info('');
      }, 3000);
    }
    this.saveButtonLabel = 'Save';
  }
  searchtemplateName() {
    this.search = true;
  }
  async listreportTemplateLazy(event?: LazyLoadEvent, search: boolean = false) {
    this.eventData = event
    this.reportTemplate.setParam('templateType', this.reportTemplateListSearch.get("templateType"))
    this.reportTemplate.setParam('modalityId', this.reportTemplateListSearch.get("Modality")?.id)
    this.reportTemplate.setParam('bodyPartId', this.reportTemplateListSearch.get("BodyPart")?.id)
    this.reportTemplate.setParam('sharingScope', this.reportTemplateListSearch.get("sharingScope"))
    this.reportTemplate.setParam('status', this.reportTemplateListSearch.get("status"))
    this.reportTemplate.setParam('userId', this.reportTemplateListSearch.get("userName")?.id)

    this.reportTemplateView.toListLazyFetch(`/reporttemplate/report-template-search`, this.search, event);
    this.search = false;
  }
  listView(search: boolean = true) {
    this.reportTemplateView.toListLazyView(`/reporttemplate/report-template-search`, search);
  }
  view(id: any) {
    this.reportTemplateView.toViewFetch(`/reporttemplate/${id}`, id);
  }
  deletereportTemplates() {
    this.reportTemplateView.toDeleteSelection(`/reporttemplate`, {
      onSuccess: () => {
        this.reportTemplateView.toListLazyFetch(`/reporttemplate/report-template-search`, true, this.eventData);
      }
    });

  }
  deletereportTemplate(id: any) {
    this.reportTemplateView.toDeleteList(`/reporttemplate/${id}`, "id", id);
  }
  savereportTemplate(add: boolean = false) {
    if (add) {
      this.reportTemplateView.toSaveNewFetch("/reporttemplate", "id");
    } else {
      if (this.reportTemplate.get("id") === null) {
        this.reportTemplateView.toSaveFetch("/reporttemplate", "id", "Report Template Saved Successfully .").then(data => {
          if (this.reportTemplate.isEdit()) {
            this.saveButtonLabel = 'Update';
          }
        })
      }
      else
        this.reportTemplateView.toSaveFetch("/reporttemplate", "id", "Report Template Updated Successfully .");
    }
  }
  filterModality(event: any) {
    this.reportTemplateView.getData(`/modality/lookup?filter=${event.query}`).then(data => { this.modalityLookup = data; });
  }
  filterbodyPart(event: any) {
    this.reportTemplateView.getData(`/bodypart/lookup?filter=${event.query}`).then(data => { this.bodyPartLookup = data; });
  }
  filterModalitySearch(event: any) {
    this.reportTemplateSearchView.getData(`/modality/lookup?filter=${event.query}`).then(data => {
      this.modalitySearchLookup =
        data;
    });
  }
  filterTemplateName(event: any) {
    this.reportTemplateSearchView.getData(`/reporttemplate/templateName-lookup?filter=${event.query}`).then(data => {
      this.templateNameLookup = data;
    });
  }
  filterBodyPart(event: any) {
    this.reportTemplateSearchView.getData(`/reporttemplate/bodypart-lookup?filter=${event.query}`).then(data => {
      this.bodyPartSearchLookup = data;
    });
  }
  filterCreatedBy(event: any) {
    this.reportTemplateSearchView.getData(`/users/email-lookup?filter=${event.query}`).then(data => {

      this.createdBySearchLookup = data;
    });
  }
  resetTemplateList() {
    this.reportTemplateListSearch.reset();
    this.search = true;
  }
  keyboardShortcuts() {
    this.visible = true;
  }
  async conet() {
    this.reportTemplateView.getData('/shortcode/report-code').then(
      (data => {
        this.codeShortList = data.list;
      })
    )
    // Move the code that depends on codeShortList here
  }
  onNamespaceLoaded(event: CKEditor4.EventInfo) {
    if (this.sysService.isRad()) {
      this.reportTemplateView.getData('/shortcode/report-code').then(
        (data => {
          this.loadAutoComplete(event, data);
        })
      )
    }
  }
  loadAutoComplete(evt, placeHolderObj) {
    let itemTemplate =
      '<li data-id="{id}" style="color: #000000;">' +
      '<div><strong>{code}</strong> </div>' +
      '<div>{codeDescription}</div>' +
      '</li>',
      outputTemplate = '{codeDescription} <span>&nbsp;</span>';
    //Make api call from backend or get this from a static file
    function textTestCallback(range) {
      if (!range.collapsed) {
        return null;
      }
      return CKEDITOR.plugins.textMatch.match(range, matchCallback);
    };
    function matchCallback(text, offset) {
      const pattern = /\#{1}([A-z]|)*$/;
      const match = text.slice(0, offset).match(pattern);
      if (!match) {
        return null;
      }
      return { start: match.index, end: offset };
    };
    function dataCallback(matchInfo, callback) {
      const query = matchInfo.query.substring(1).toLowerCase();
      const data = placeHolderObj.filter(function (item) {
        const title = item.code.toLowerCase();
        if (title.startsWith(query)) {
          return true;
        } else {
          const desc = item.codeDescription.toLowerCase();
          return title.includes(query) || desc.includes(query);
        }
      });
      callback(data);
    }
    const autocomplete = new CKEDITOR.plugins.autocomplete(evt.editor, {
      textTestCallback: textTestCallback,
      dataCallback: dataCallback,
      itemTemplate: itemTemplate,
      outputTemplate: outputTemplate
    });
    autocomplete.getHtmlToInsert = function (item) {
      return this.outputTemplate.output(item);
    };
  }
  public editorData;
  configuration = {
    // delayIfDetached: true,
    // toolbarGroups: [
    //   { groups: ["undo"] },
    //   { name: "editing", groups: ["find", "selection"] }, //'spellchecker'
    //   { name: "insert" },
    //   { name: "document", groups: ["mode", "document", "doctools"] },
    //   { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
    //   { name: "paragraph", groups: ["list", "indent", "blocks", "align"] },
    //   { name: "tools", groups: ["maximize"] },
    //   { name: "styles" },
    //   { name: "colors" },
    // ],

    toolbarGroups: [{ name: 'clipboard', groups: ['undo', 'clipboard'] },
    { name: 'editing', groups: ['find', 'selection'] },
    { name: 'links' }, { name: 'insert' },
    { name: 'document', groups: ['mode', 'document', 'doctools'] },
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },

    { name: 'styles' },
    { name: 'colors' },
    { name: "tools", groups: ["maximize"] },],
    resize_enabled: true,
    versionCheck: false,
    pasteFromWord: false,
    disableNativeSpellChecker: false,
    // Set readOnly to true if this.reportTemplate.view.editable is true
    removePlugins: "elementspath,save,magicline,image,sourcearea",
    // extraPlugins: 'divarea,smiley,justify,indentblock,easyimage,maximize,uploadwidget,image2,link,placeholder,colordialog,clipboard,widget,dialog,font,preview ,autogrow,colorbutton,font,tableresize,liststyle,pagebreak,pastefromgdocs'  ,
    extraPlugins:
      "image2,dialogui,dialog,a11yhelp,basicstyles,bidi,blockquote,clipboard,placeholder,widget,lineutils," +
      "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
      "dialogadvtab,enterkey,entities,popup," +
      "filebrowser,contextmenu,find,fakeobjects,floatingspace,listblock,richcombo," +
      "font,format,horizontalrule,htmlwriter,indent," +
      "indentblock,indentlist,justify,list,liststyle,magicline," +
      "maximize,pagebreak," +
      "removeformat,resize,save,menubutton,selectall,showblocks," +
      "showborders,specialchar,stylescombo,tab,table," +
      "tabletools,toolbar,undo,wysiwygarea," +
      "autocomplete,textmatch,link,smiley",

    pasteFromWordRemoveFontStyles: true,
    allowedContent: true,
    forcePasteAsPlainText: false,
    //   disallowedContent: 'img{width,height,float}',
    // extraAllowedContent: 'img[width,height,align]',
    // customConfig: '',
    // scayt_autoStartup: true, scayt
    height: "842px",
    skin: 'kama',
    removeDialogTabs: "image:advanced;link:advanced",
    format_tags: "p;h1;h2;h3;pre;div",
    bodyClass: "document-editor",
    contentsCss: ["./assets/ckeditor.css"],
    stylesSet: [
      /* Inline Styles */
      { name: "Marker", element: "span", attributes: { class: "marker" } },
      { name: "Cited Work", element: "cite" },
      { name: "Inline Quotation", element: "q" },
      /* Object Styles */
      {
        name: "Special Container",
        element: "div",
        styles: {
          padding: "5px 10px",
          background: "#eee",
          border: "1px solid #ccc",
        },
      },
      {
        name: "Compact table",
        element: "table",
        attributes: {
          cellpadding: "5",
          cellspacing: "0",
          border: "1",
          bordercolor: "#ccc",
        },
        styles: {
          "border-collapse": "collapse",
        },
      },
      {
        name: "Borderless Table",
        element: "table",
        styles: { "border-style": "hidden", "background-color": "#E6E6FA" },
      },
      {
        name: "Square Bulleted List",
        element: "ul",
        styles: { "list-style-type": "square" },
      },
    ],
  };
  header1: 1000;
  printReport(editor: any) {
    this.reportTemplateView.getData('/reports/dummy-reports').then(data => {


      this.reportService.printReport(editor._data, data, this.imageUrl, this.stamp, this.header, this.footer);
    })
  }
}