<header class="header" *ngIf="!sysService.isLoggedIn()">
    <div class="wrap">
        <div class="right">
            <div class="rgtBdr ">
                <a (click)="navHome()"> <img class="web" src="../../../assets/images/eclogoo.png"></a>
            </div>
        </div>
    </div>
</header>
<div class="grid justify-content-center">
    <form [formGroup]="forget.form">
        <div class="card ">
            <div class="grid ">
                <div class="col-12 flex justify-content-center  ">
                    <div class="forget ">
                        <h2>Forgot Password </h2>
                        <p>Enter the email address associated with your
                            eCScribe account.</p>
                        <label><b>Email Address :</b></label>
                        <div class="p-3 email">
                            <input id="EmailAddress" type="text" pInputText formControlName="email" maxlength="80"
                                required pAutoFocus autofocus="true" />
                            <f-error [err]="forget.required('email')" msg="Email Address is required "></f-error>
                            <f-error [err]="forget.pattern('email')"
                                msg="This e-mail address is not valid. Valid records include to user@somehost.somedomain. And emails only accept lowercase letters."></f-error>
                        </div>
                        <div class="Submit p-3">
                            <button pButton pRipple type="button" label="Cancel" icon="pi pi-times"
                                class="p-button-outlined p-button-info" (click)="backSign()"></button>
                            <button pButton pRipple type="button" label=" Submit" icon="pi pi-check"
                                class="p-button-raised p-button-success" (click)="submit()">
                                <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div id="borderTop"></div>
                <div id="borderBottom"></div>
                <div class="p-3">
                    <label> Has your email address changed?
                        If you no longer use the e-mail address associated
                        with your eCScribe account, you may
                        contact
                        <a href="mailto:hello@ecscribe.com">hello@ecscribe.com
                        </a> for help restoring access to your account.
                    </label>
                </div>
            </div>
        </div>
    </form>
</div>